<div class="card">
  <div class="card-header">
    <h3 class="card-title MoodysTittleColor" >Latest Portal Users</h3>

    <div class="card-tools">
  
      <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i>
      </button>
      <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i>
      </button>
    </div>
  </div>

  <div class="card-body p-0">
    <ul class="users-list clearfix">
      <li *ngFor="let name of username">
        
        <a class="users-list-name" href="#">{{name.username}}</a>

      </li>
    </ul>
    
  </div>

  <div class="card-footer text-center">
    <a routerLink="/usermanagement" *ngIf="View_User" href="javascript::" class="btn btn-sm btn-secondary float-right">View All Users</a>
  </div>

</div>