<!-- Accounts Summary Dashboard -->
<section class="content mt-4" *ngIf="showDashboard">
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor" style="vertical-align: middle;">Account Summary (Past 24 Hours)</h3>
        <div class="float-right d-flex">
          <h3 class="MoodysTittleColor mx-2 my-1" style="font-size: medium; font-weight: bold;">Account Type </h3>
          <select [(ngModel)] = "SelectedAccountTypeValue" (change) = "AccountTypeChange()">
            <option *ngFor = "let option of AccountTypesMapped" [value] = "option.accountTypeName">{{option.accountTypeName}}</option>
          </select>
        </div>
      </div>
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-6 d-flex justify-content-center align-items-center">
            <div class="col-md-8 p-2 mx-1">
              <div class="d-flex flex-row widget summary">
                  <div class="d-flex flex-column justify-content-center p-5">
                    <i class="bi bi-check-circle fa-lg" style="color: white;"></i>
                  </div>
                  <div class="d-flex flex-column m-auto py-3">
                    <button style="text-decoration: none; border: none; background-color: #f4f6f7;" (click) = "ShowAccountsFromLast24Hours()">
                      <span class="lead">Accounts Uploaded</span>
                      <h4 class="display-6 fw-bold">{{DashboardNoOfAccountsUploaded}}</h4>
                    </button>
                  </div>
              </div>
            </div>
            <div class="col-md-8 p-2 mx-1">
                <div class="d-flex flex-row widget summary">
                    <div class="d-flex flex-column justify-content-center p-5">
                      <i class="bi bi-arrow-up-circle fa-lg" style="color: white;"></i>
                    </div>
                    <div class="d-flex flex-column m-auto py-3">
                      <button style="text-decoration: none; border: none; background-color: #f4f6f7;" (click) = "ShowShippedAccountsData()">
                        <span class="lead">Accounts Shipped</span>
                        <h4 class="display-6 fw-bold">{{DashboardNoOfAccountsShipped}}</h4>
                      </button>
                    </div>
                </div>
            </div>
            <div class="col-md-8 p-2 mx-1">
              <div class="d-flex flex-row widget summary">
                  <div class="d-flex flex-column justify-content-center p-5">
                    <i class="bi bi-dash-circle fa-lg" style="color: white;"></i>
                  </div>
                  <div class="d-flex flex-column m-auto py-3">
                    <button style="text-decoration: none; border: none; background-color: #f4f6f7;" (click) = "ShowAccountsWithQueryData()">
                      <span class="lead">Accounts in Query</span>
                      <h4 class="display-6 fw-bold">{{DashboardNoOfAccountsInQuery}}</h4>
                    </button>
                  </div>
              </div>
            </div>
        </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center mt-4">
          <div class="col-6 d-flex justify-content-center align-items-center">
            <button style="text-decoration: none; border: none; background-color: inherit;" (click) = "ShowAccountsInProcess()">
              <h4>Accounts Processing : {{DashboardNoOfInProcessAccounts}}</h4>
            </button>
          </div>
          <div class="col-6 d-flex justify-content-center align-items-center">
            <button style="text-decoration: none; border: none; background-color: inherit;" (click) = "ShowAccountsWithQueryData()">
              <h4>Queries Summary</h4>
            </button>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-6 m-2">
              <div class="mt-3">
                <apx-chart
                  [series]="BarChartOptions.series"
                  [chart]="BarChartOptions.chart"
                  [xaxis]="BarChartOptions.xaxis"
                  [plotOptions] = "BarChartOptions.plotOptions"
                  [tooltip] = "BarChartOptions.tooltip">
                </apx-chart>
              </div>
            </div>
            <div class="col-5 m-2">
              <div class="mt-4">
                <apx-chart
                  [series]="PieChartSeries" 
                  [chart]="PieChartDetails" 
                  [labels]="PieChartLabels" 
                  [dataLabels]="PieChartDataLabels">
                </apx-chart>
              </div>
            </div>
          </div>
      </div>
    </div>
</section>

<section class="content">
    <div class="container-fluid" style="padding: 0 !important;">
      <div class="card card-default">
        <div class="card-header">
          <div class="table-responsive text-nowrap table-wrapper " >
            <table id="dataTable" class="table table-hover table-bordered font14 noPad" *ngIf="AccountsDataForTable" id="tabledetail">
              <thead>
                <tr>
                  <th>#</th>
                  <th class="th-sm" style="padding-left: 32px!important; cursor: pointer;"
                    *ngFor="let items of dynFieldheader | slice:10;  let i = index">
                    {{ items.charAt(0).toUpperCase() + items.slice(1) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of AccountsDataForTable; let i = index" id="tr_{{i}}" class="RmsTableRow ">

                  <td>{{convertToInt(pageStart) + i + 1}}</td>
                  <td *ngFor="let column of dynFieldheader | slice:10" class="set">
                    <div
                      *ngIf="column != 'status'&& column !='upload Date' && column !='expected Shipment' && column != 'actual Shipment'
                      && column!='special Instructions'&& column!='comments' && column != 'incept Date' && column != 'expiry Date' && column != 'file Name' && column != 'original TIV' && column != 'cleaned TIV' 
                      && column != 'query Opened On' && column != 'query Last Modified On' && column != 'query Closed On' ">
                      <span>{{ item[column] }}</span>
                    </div>
                    <div *ngIf="column == 'status'">

                      <span Class="badge  badge-settings" [ngClass]="item['statusClass']">{{ item[column] }}</span>

                    </div>
                    <div
                      *ngIf="column == 'upload Date' ||  column == 'expected Shipment' || column == 'actual Shipment' || column == 'query Opened On' || column == 'query Last Modified On' || column == 'query Closed On' ">

                      <span>{{ item[column]|date:'dd-MMM-yyyy hh:mm a' }}</span>

                    </div>

                    <div *ngIf="column == 'expiry Date'  || column == 'incept Date'">

                      <span>{{ item[column]|date:'dd-MMM-yyyy' }}</span>

                    </div>
                    <div title="View Instructions" *ngIf="column == 'special Instructions'" style="cursor: pointer;"
                      >

                      <span>{{ item[column]}}</span>

                    </div>
                    <div title="View Comments" *ngIf="column == 'comments'" style="cursor: pointer;">

                      <span>{{ item[column]|slice:0:20}}</span>

                    </div>
                    <div title="Full file name" *ngIf="column == 'file Name'" style="cursor: pointer;">

                      <span>{{ item[column]|slice:0:30}}<span *ngIf='item[column].length > 30'>....</span></span>

                    </div>
                    <div *ngIf="column == 'original TIV' ">
                      <span>{{ item[column]| number }}</span>
                    </div>
                    <div *ngIf="column == 'cleaned TIV' ">
                      <span>{{ item[column]| number }}</span>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>