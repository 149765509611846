import { Component, OnInit } from '@angular/core';
import { DashboardServiceService } from 'src/services/DashboardService/dashboardService.service';
import { AlertService } from 'src/services/Common/alert.service';
import { ErrorHandler } from 'src/common/error-handler';
import { MenuList } from 'src/common/menuList.enum';
import { CommonMethod } from 'src/common/CommonMethod';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { FeatureMaster } from 'src/common/feature-master';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  errorHandler = new ErrorHandler(this.alertService);
  clientList: any;
  selectDashboard: any;
  isAdmin: boolean;
  isProjectManager: boolean;
  isAccountManager: boolean;
  isClientManager: boolean;
  hasUploadAccess: boolean;
  RaiseReply_Query: boolean;
  View_Message_Center: boolean;
  View_User: boolean;
  View_Client_lead_dashboard:boolean
  obj: CommonMethod = new CommonMethod();
  constructor(private DashboardService: DashboardServiceService,
    private alertService: AlertService, private userInfo: AddEditUserSerivceService) {
    this.obj.checkAccess(MenuList.Dashboard);
  }

  ngOnInit() {
    this.CheckAuthorization();
    this.getData();
  }
  getData() {
    let formData: FormData = new FormData();
    this.DashboardService.getDAshboardData(formData).subscribe(
      result => {
        if (result['tblDashboardData'] != undefined && result['tblDashboardData'] != '' && result['tblDashboardData'] != null) {
          this.clientList = result['tblDashboardData'];
        }

        // alert(1)

        // this.selectDashboard = result['tblDashboardData'][0]['featureid']
      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }
  CheckAuthorization() {
    this.userInfo.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      if (res != null) {
        let obj: CommonMethod = new CommonMethod();
        // debugger;
        this.isAdmin = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.View_Admin_Dashboard);
        this.isProjectManager = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.View_Project_Manager_Dashboard);
        this.isAccountManager = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.View_Account_Manager_Dashboard);
        this.isClientManager = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.View_Client_Manager_Dashboard);
        this.hasUploadAccess = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.Upload_SOV);
        this.RaiseReply_Query = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.RaiseReply_Query);
        this.View_Message_Center = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.View_Message_Center);
        this.View_User = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.View_User);
        this.View_Client_lead_dashboard=obj.CheckFeatureAccess(res["roleFeatureMapping"],FeatureMaster.View_Client_lead_dashboard)
      }
    })

  }

}
