import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadRoutingModule } from './upload-routing.module';
import { UploadAccountComponent } from './upload-account/upload-account.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UploadDynamicFormComponent } from './upload-dynamic-form/upload-dynamic-form.component';
import { DynamicElementsComponent } from '../Common/dynamic-elements/dynamic-elements.component';
import { DndDirective } from '../Directives/dnd.directive';
import { SsUploadComponent } from './ss-upload/ss-upload.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [UploadAccountComponent,SsUploadComponent, UploadDynamicFormComponent,DndDirective,DynamicElementsComponent],
  imports: [
    CommonModule,
    UploadRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule
            
  ],
  exports:[DynamicElementsComponent,DndDirective]
})

export class UploadModule { }
