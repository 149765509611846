import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewClientComponent } from '../client/view-client/view-client.component';
import { ContractClientComponent } from './contract-client/contract-client.component';


const routes: Routes = [
  {
    path: '',
    component: ViewClientComponent

  },
  
  {
    path: 'clientmanagement',
    component: ViewClientComponent
  },

  {
    path: 'contract',
    component: ContractClientComponent
  }
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
