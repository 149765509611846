import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewUserComponent } from './view-user/view-user.component';
import { ProfileUserComponent } from './profile-user/profile-user.component';
import { SettingUserComponent } from './setting-user/setting-user.component';
// import { NotificationComponent } from './notification/notification.component';
// import { UserProfileComponent } from './user-profile/user-profile.component';
// import { UserSettingComponent } from './user-setting/user-setting.component';

const routes: Routes = [
  {
    path: '',
    component: ViewUserComponent

  },
  {
    path: 'user',
    component: ViewUserComponent
  },
  {
    path:'userprofile',component:ProfileUserComponent
  },
  {
    path:'usersetting',component:SettingUserComponent
  }

];


export const ModuleRouting: ModuleWithProviders = RouterModule.forChild(routes);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
