import { Injectable,ElementRef } from '@angular/core';
import Swal from 'sweetalert2'
import { HttpClient } from '@angular/common/http';
import { relativeLength } from 'highcharts';

@Injectable({ providedIn: 'root' })
export class PopupService {
 
  constructor(private httpClient: HttpClient) { }

  public Confirmation(msgTitle: string, msgBody: string = "", btnYesTxt: string = 'Yes', btnNoTxt: string = 'No') {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn RMSPopUpButtonConfirm',
        cancelButton: 'btn RMSPopUpButtonCancel'
      },
      buttonsStyling: false
    })
    return swalWithBootstrapButtons.fire({
      title: msgTitle,
      text: msgBody,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: btnYesTxt,
      cancelButtonText: btnNoTxt,
      reverseButtons: false
    })

  }

  public Rating() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn RMSPopUpButtonConfirm',
        cancelButton: 'btn RMSPopUpButtonCancel'
      },
      buttonsStyling: false
    })

    return swalWithBootstrapButtons.fire({
      title: 'How was  your experience?',
      html:'<div><lable>Rate us:</label></div>'+
      '<div class="rate"><input type="radio" id="star5" name="rate" value="5" />'+
      '<label for="star5" title="text">5 stars</label>'+
      '<input type="radio" id="star4" name="rate" value="4" />'+
      '<label for="star4" title="text">4 stars</label>'+
      '<input type="radio" id="star3" name="rate" value="3" />'+
      '<label for="star3" title="text">3 stars</label>'+
      '<input type="radio" id="star2" name="rate" value="2" />'+
      '<label for="star2" title="text">2 stars</label>'+
      '<input type="radio" id="star1" name="rate" value="1" />'+
      '<label for="star1" title="text">1 star</label></div>',
      showCloseButton: true,      
      showCancelButton: true,
      focusConfirm: true,
      reverseButtons: false,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Great!',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: 'Thumbs down',
      preConfirm: () => {
        return [
          document.getElementsByName('rate')          
        ]
      }
                
    })
  } 
  
  public Feedback()
{
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn RMSPopUpButtonConfirm',
      cancelButton: 'btn RMSPopUpButtonCancel'
    },
    buttonsStyling: false
  })
  // return async (result) => {      
    // if (result.dismiss === Swal.DismissReason.cancel /* Read more about handling dismissals below */) {
      return swalWithBootstrapButtons.fire({
        title: "Have some suggestion?",
      //   html:'<label class="labelCategory">Category:</label>'+
      //   '<select class="drpDwnCategory" id="category">'+
      //   '<option value="Generic">Generic</option>'+
      //   '<option value="UploadAccount">Upload Account</option>'+
      //   '<option value="ProcessTracking">Process Tracking</option>'+
      //   '<option value="UserManagement">User Management</option>'+
      //   '<option value="ClientManagement">Client Management</option>'+
      //   '<option value="MessageCenter">Message Center</option>'+
      // '</select>',
        input: 'textarea',
        inputPlaceholder: 'Type your suggestion here...',
        inputAttributes: {
          'aria-label': 'Type your suggestion here',
        },
        showCancelButton: true,
        preConfirm: () => {
          return [
            document.getElementById('category'),
            document.getElementsByClassName('swal2-textarea')          
          ]
        }
      })
    
       //return text;
    // }
  // }
}
}

