import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { CommonMethod } from 'src/common/CommonMethod';
import { ClientElementModel } from 'src/model/ClientManagement/ClientElementmappsing';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { ClientelementmappingserviceService } from 'src/services/ClientElementMappingService/clientelementmappingservice.service';
import { AlertService } from 'src/services/Common/alert.service';

@Component({
  selector: 'app-clientelementsetting',
  templateUrl: './clientelementsetting.component.html',
  styleUrls: ['./clientelementsetting.component.css']
})
export class ClientelementsettingComponent implements OnInit, OnChanges {

  constructor(private Service: ClientelementmappingserviceService, private formBuilder: FormBuilder, private alertservic: AlertService,) { }
  _listclient: any;
  _listelement: any[];
  _clientID;
  _selectelement: any;
  _clientelementarray: any[] = [];
  clientManagementForm: FormGroup
  _validationMaster: any[] = [];
  _AddValidation: any[] = [];
  // _distinctValidation: any[] = [];
  _validationvalues: any;
  validationreq: boolean = false;
  checkerrorforvalidation: boolean = false;
  checkerror: boolean;
  _buttonTittle: string = "Add Element";
  _secondButtonTittle: string = "Save";
  _uniqueId: string;
  IsEdit: boolean = false;
  @Input() clientId: number;
  @Output() public closetag = new EventEmitter();
  ngOnInit(): void {
    this._clientelementarray = [];
    this.clientManagementForm = this.formBuilder.group({
      Elementname: ['', [Validators.required]],
      MappedName: [, [Validators.maxLength(250)]],
      DefaultValue: [, [Validators.maxLength(250)]],
      ValidationReq: [, [Validators.required]],
      selectvalidator: [, [Validators.required]],
      IsMandatory: ['', [Validators.required]],
      IsActive: [, Validators.required],
      IsEditable: [, Validators.required],
      MasterDataQuery: [,]
    });
    console.log(this.clientId);
    this.Service.GetClientElementList(AppSettings.API_ENDPOINT + AppRelativeUrl.GETCLIENTTELEMENTLIST).subscribe(res => {
      // console.log(res);  id: 1, elementName: "Account Name" 
      this._listclient = res['clientList'];
      this._listelement = res['elementList'];
      this._validationMaster = res['validationMaster']
      // console.log(this._validationMaster);

    })
  }
  ngOnChanges() {
    this._clientelementarray = [];
    this.Service.GetClientElementMapping(AppSettings.API_ENDPOINT + AppRelativeUrl.GETCLIENTELEMENTMAP, this.clientId).subscribe(res => {
      // console.log(res);
      if (res != undefined) {
        let arr: any = res;
        arr.forEach(element => {
          let clientelementlist = new ClientElementModel;
          clientelementlist.Id = element.id;
          if (element.clientId != null) {
            clientelementlist.ClientId = this.clientId;
          } else {
            clientelementlist.ClientId = null;
          }
          clientelementlist.GUID = CommonMethod.GenrateUniqueIdentity();
          clientelementlist.ElementId = element.elementId;
          clientelementlist.ElementName = element.elementName;
          clientelementlist.MappedName = element.mappedName;
          clientelementlist.DefaultValue = element.defaultValue;
          clientelementlist.IsMandatory = Boolean(element.isMandatory);
          clientelementlist.IsActive = Boolean(element.isActive);
          clientelementlist.IsEditable = Boolean(element.isEditable);
          clientelementlist.ValidationReq = Boolean(element.validationReq);
          // clientelementlist.ElementSeq = element.elementSeq;
          clientelementlist.MasterDataQuery = element.masterDataQuery
          clientelementlist.Validation = element.validator;
          this._clientelementarray.push(clientelementlist);
          //  console.log(this._clientelementarray);
          clientelementlist = null;
        });
      }
    })
    this.ngOnInit();
  }
  GetClientInfo(value) {
    this._clientID = value;
  }
  GetElementID(value) {
    this._selectelement = this._listelement.find(obj => obj.id == value);
  }
  AddToElementList() {
    this.checkerror = true;
    if (this.CheckValidation()) {
      if (this.IsEdit) {
        let index = this._clientelementarray.findIndex(item => item.GUID === this._uniqueId);
        this._clientelementarray[index].ClientId = this.clientId;
        this._clientelementarray[index].ElementId = this._selectelement.id;
        this._clientelementarray[index].ElementName = this._selectelement.elementName;
        if (this.clientManagementForm.value.MappedName == '' && this.clientManagementForm.value.MappedName == null) {
          this._clientelementarray[index].MappedName = null;
        }
        else {
          this._clientelementarray[index].MappedName = this.clientManagementForm.value.MappedName;
        }
        if (this.clientManagementForm.value.DefaultValue == '' && this.clientManagementForm.value.DefaultValue == null && this.clientManagementForm.value.DefaultValue == undefined) {
          this._clientelementarray[index].DefaultValue = null;
        } else {
          this._clientelementarray[index].DefaultValue = this.clientManagementForm.value.DefaultValue;
        }

        this._clientelementarray[index].IsMandatory = Boolean(this.clientManagementForm.value.IsMandatory === 'true' || this.clientManagementForm.value.IsMandatory == true);
        this._clientelementarray[index].IsActive = Boolean(this.clientManagementForm.value.IsActive === 'true' || this.clientManagementForm.value.IsActive == true);
        this._clientelementarray[index].IsEditable = Boolean(this.clientManagementForm.value.IsEditable === 'true' || this.clientManagementForm.value.IsEditable == true);
        this._clientelementarray[index].ValidationReq = Boolean(this.clientManagementForm.value.ValidationReq === 'true' || this.clientManagementForm.value.ValidationReq == true);
        this._clientelementarray[index].MasterDataQuery = this.clientManagementForm.value.MasterDataQuery
        this.checkerror = false;

      }
      else {
        let iselementexist = this._clientelementarray.some(obj => obj.ElementId === this._selectelement.id);
        if (!iselementexist) {
          let clientelementlist = new ClientElementModel;
          clientelementlist.GUID = CommonMethod.GenrateUniqueIdentity();
          clientelementlist.Id = 0;
          clientelementlist.ClientId = this.clientId;
          clientelementlist.ElementId = this._selectelement.id;
          clientelementlist.ElementName = this._selectelement.elementName;
          if (this.clientManagementForm.value.MappedName == null && this.clientManagementForm.value.MappedName == '' && this.clientManagementForm.value.MappedName == undefined) {
            clientelementlist.MappedName = null;
          } else {
            clientelementlist.MappedName = this.clientManagementForm.value.MappedName;
          }
          if (this.clientManagementForm.value.DefaultValue == '' && this.clientManagementForm.value.DefaultValue == null && this.clientManagementForm.value.DefaultValue == undefined) {
            clientelementlist.DefaultValue = null;
          } else {
            clientelementlist.DefaultValue = this.clientManagementForm.value.DefaultValue;
          }
          clientelementlist.IsMandatory = Boolean(this.clientManagementForm.value.IsMandatory === 'true' || this.clientManagementForm.value.IsMandatory == true);
          clientelementlist.IsActive = Boolean(this.clientManagementForm.value.IsActive === 'true' || this.clientManagementForm.value.IsActive == true);
          clientelementlist.IsEditable = Boolean(this.clientManagementForm.value.IsEditable === 'true' || this.clientManagementForm.value.IsEditable == true);
          clientelementlist.ValidationReq = Boolean(this.clientManagementForm.value.ValidationReq === 'true' || this.clientManagementForm.value.ValidationReq == true);
          clientelementlist.MasterDataQuery = this.clientManagementForm.value.MasterDataQuery
          this._clientelementarray.push(clientelementlist);
          clientelementlist = null;
          this.checkerror = false;
        }
        else {
          this.alertservic.warn("this element is already added");
        }
      }
      // }
      // }

    } else {
      // this.alertservic.warn("validation failed");
    }
    this.IsEdit = false;
    this._buttonTittle = "Add Element";
    this.clientManagementForm.reset()
    this._selectelement = null;
    this._AddValidation = null;

    //  console.log(this._clientelementarray);
  }
  SaveClientElementMapping() {
    // //  console.log(this._clientelementarray);
     if (this._clientelementarray == null  && this._clientelementarray == undefined)
      this.alertservic.warn("No Element in queue,please add element");

    this.Service.SaveClientElementMapping(AppSettings.API_ENDPOINT + AppRelativeUrl.ADDEDITCLIENTELEMENT, JSON.stringify(this._clientelementarray), this.IsEdit).subscribe(res => {
      if (res) {
        this.alertservic.success("Element have been saved successfully");
        this.ngOnChanges();
      } else {
        this.alertservic.success("Somthing went worng")
      }
    }, err => {
      // this.errorhandler.ErrorsHandler(err);
    })
  }
  EditElement(value) {
    //  console.log(value);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    this._buttonTittle = "Edit Element";
    this.IsEdit = true;
    this._uniqueId = value.GUID;
    this._selectelement = this._listelement.find(obj => obj.id == value.ElementId);

    this.clientManagementForm.patchValue({
      Elementname: value.ElementId,
      MappedName: value.MappedName,
      DefaultValue: value.DefaultValue,
      selectvalidator: value.ElementId,
      IsMandatory: value.IsMandatory,
      IsActive: value.IsActive,
      IsEditable: value.IsEditable,
      MasterDataQuery: value.MasterDataQuery,
      ValidationReq: value.ValidationReq
    });
    this._AddValidation = value.validation;
    this.IsEdit = true;
    this._secondButtonTittle = "Save Changes";
  }
  DeleteElement(item) {
    let index = this._clientelementarray.indexOf(item);
    this._clientelementarray.splice(index, 1);
  }
  close() {
    this.closetag.emit(0);
  }
  CheckValidation(): boolean {
    if (this.clientManagementForm.controls['Elementname'].invalid || this.clientManagementForm.controls['IsMandatory'].invalid
      || this.clientManagementForm.controls['IsActive'].invalid || this.clientManagementForm.controls['IsEditable'].invalid
      || this.clientManagementForm.controls['ValidationReq'].invalid) {
      // || this.clientManagementForm.controls['ElementSeq'].invalid) {
      return false
    }
    return true;
  }
  get GetFormControls() {
    return this.clientManagementForm.controls;
  }

  GetValidationValues(value) {
    // //  console.log(value);
  }
  AddVaidation() {
    if (this._AddValidation.some(obj => { Number(obj['Validator'].id) === Number(this.clientManagementForm.controls['selectvalidator'].value) })) {
      this.alertservic.warn('Validator alredy exits');
      return;
    }
    this.checkerrorforvalidation = true;
    if (this.clientManagementForm.controls['selectvalidator'].invalid
      || this.clientManagementForm.controls['isignorable'].invalid) {
      return;
    }
    this._AddValidation.push({
      Validator: this._validationMaster.find(obj => obj.id == Number(this.clientManagementForm.controls['selectvalidator'].value))
      , isignorable: this.clientManagementForm.controls['isignorable'].invalid
    })
    // ValidationId int,
    // IsIgnorable	bit
    this.clientManagementForm.controls['selectvalidator'].reset();
    this.clientManagementForm.controls['isignorable'].reset();
    this.checkerrorforvalidation = false;
  }
  ifrequred(value) {
    if (value == 'true') {
      this.validationreq = true;
    } else { this.validationreq = false; }
  }
  CancelEdit() {
    this.IsEdit = false;
    this._buttonTittle = "Add Element";
    this.clientManagementForm.reset()
    this._selectelement = null;
    this._AddValidation = null;
  }

}
