import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertService } from 'src/services/Common/alert.service';
import { MasterservcieService } from 'src/services/MasterService/masterservcie.service';

@Component({
  selector: 'app-manage-location',
  templateUrl: './manage-location.component.html',
  styleUrls: ['./manage-location.component.css']
})
export class ManageLocationComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder, private Service: MasterservcieService, private alertService: AlertService) { }
  _formLocationTimezoneForm: UntypedFormGroup;
  checkerror: boolean;
  tbllocation: any;
  tblTomeZone: any;
  searchInput: any = '';
  showSearchValidationError: boolean;
  showForm: boolean;
  isEdit: boolean;
  editedLocationId: number;
  errorhandlerService = new ErrorHandler(this.alertService);
  buttonTittle: string = "Add";
  dynmaicFieldHeader: any = [];
  checkModelError: boolean;
  _formdeativateLocation: UntypedFormGroup;
  LocationName: string;
  IsActive: boolean;
  statusButtonTittle: string;
  @ViewChild('closebutton', { static: false }) closebutton;


  ngOnInit(): void {
    this._formLocationTimezoneForm = this.formBuilder.group({
      city: ['', [Validators.required, Validators.maxLength(50)]],
      timezone: [Validators.required],
      comment: ['', [Validators.required, Validators.maxLength(500)]],
      requestedby: ['', [Validators.required, Validators.maxLength(50)]]
    })
    this.GetLocationTimeZone('');
    this._formdeativateLocation = this.formBuilder.group({
      reasoncomment: ['', [Validators.required, Validators.maxLength(500)]]
    })
  }
  GetLocationTimeZone(searchInput: string) {
    this.dynmaicFieldHeader = [];
    this.tbllocation = '';
    let formdata = new FormData();
    formdata.append('searchInput', searchInput);
    this.Service.GetMastersData(AppSettings.API_ENDPOINT + AppRelativeUrl.GETLOCATIONTIMEZONEINFO, formdata).subscribe(res => {
      this.tbllocation = res['tblLocation'];
      this.dynmaicFieldHeader = this.dynmaicFieldHeader.concat(Object.keys(this.tbllocation[0]));
      this.tblTomeZone = res['tblTimeZone']
    }, err => {
      this.errorhandlerService.ErrorsHandler(err);
    })
  }
  get GetFormControls() {
    return this._formLocationTimezoneForm.controls;
  }
  Add() {
    this.checkerror = true
    if (this._formLocationTimezoneForm.controls['city'].invalid || this._formLocationTimezoneForm.controls['timezone'].invalid) {
      return
    } else {
      let formdata = new FormData();
      if (this.isEdit) {
        formdata.append('LocationId', String(this.editedLocationId));
      }
      else {
        formdata.append('LocationId', '0')
      }
      formdata.append('CityName', this._formLocationTimezoneForm.value.city)
      formdata.append('TimeZoneId', this._formLocationTimezoneForm.value.timezone)
      formdata.append('RequestedBy', this._formLocationTimezoneForm.value.requestedby)
      formdata.append('Comment', this._formLocationTimezoneForm.value.comment)
      formdata.append('UserId', "0")
      this.Service.AddEditMaster(AppSettings.API_ENDPOINT + AppRelativeUrl.ADDEDITLOCATION, formdata).subscribe(res => {
        if (res) {
          this.alertService.success("Location has been successfully")
          this._formLocationTimezoneForm.reset();
          this.isEdit = false;
          this.checkerror = false;
          this.buttonTittle = "Add";
          this.ngOnInit();
        } else {
          this.alertService.warn('This Location is allready exists');
        }
      },
        err => {
          this.errorhandlerService.ErrorsHandler(err);
        })
    }
  }
  DeleteLocation(item) {
    this.editedLocationId = item.id;
    this.LocationName = item.name;
    if (item.isActive) {
      this.statusButtonTittle = 'Deactivate';
    }
    else {
      this.statusButtonTittle = "Activate";
    }
    this.IsActive = item.isActive;
  }
  EditLocation(item) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    this.showForm = true;
    this.isEdit = true;
    this.buttonTittle = "Edit";
    this.editedLocationId = item.id
    this._formLocationTimezoneForm.patchValue({
      city: item.city,
      timezone: item.timeZoneId,
      comment: item.comment ,
      requestedby:item.requestedBy
    });
  }
  SearchItem() {
    this.GetLocationTimeZone(this.searchInput);
  }
  resetRecords() {
    this.searchInput = ''
    this.GetLocationTimeZone(this.searchInput);
  }
  checkSearch() {

  }
  AddLocation() {
    this.showForm = true;
  }
  close() {
    this.buttonTittle="Add"
    this.showForm = false;
    this.checkerror = false;
    this._formLocationTimezoneForm.reset();
  }
  ShowModelError() {
    this.checkModelError = true;
  }
  CloseModel() {
    this._formdeativateLocation.reset();
    this.LocationName = null;
    this.checkModelError = false;
  }
  ActivateDeativateLocation() {
    this.checkModelError = true;
    if (this._formdeativateLocation.controls['reasoncomment'].invalid) {
      return;
    } else {
      let postData = new FormData();
      postData.append("LocationId", String(this.editedLocationId));
      postData.append("Comment", this._formdeativateLocation.value.reasoncomment);
      postData.append("IsActive", String(!this.IsActive));
      this.Service.AddEditMaster(AppSettings.API_ENDPOINT + AppRelativeUrl.DEACTIVATELOCATION, postData).subscribe(
        res => {
          if (res) {
            this.closebutton.nativeElement.click();
            this.alertService.success('Business Unit details has been saved successfully');           
            this.ngOnInit();
          } else {
            this.alertService.error("SomeThing went wrong");
          }
        },
        err => {
          this.closebutton.nativeElement.click();
          this.errorhandlerService.ErrorsHandler(err);
        }
      )
    }
  }
  get ModelGetFormControls() {
    return this._formdeativateLocation.controls
  }
}
