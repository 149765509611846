<hr style="margin-top: 0px !important;" />
<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor" style=" margin-right: 10px;">
          Recent Jobs
        </h3>
        <div class="card-tools" style="margin-left: 20px;">
          <div class="input-group input-group-sm">
            <button type="button" class="btn btn-default btn-block btn-sm d-none d-sm-block" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" style="width: 100px;">
              Export
            </button>
            <div class="input-group-append">
              <button type="button" class="btn btn-default btn-block btn-sm MoodysExportButton" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" >
                <i class="fa fa-file-excel-o RMSSearchButtonIcon"></i>
              </button>
              <!--begin::Dropdown Menu-->
              <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <button class="dropdown-item btn btn-block btn-sm " (click)="downloadExel()">
                  <i class="fa fa-file-excel-o mr-3 MoodysDownloadExcel" ></i>
                  Excel
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item btn btn-block btn-sm" (click)="downloadCsv()">
                  <i class="fa fa-file-code-o mr-3 MoodysDownloadExcel" ></i>
                  CSV
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item btn btn-block btn-sm" (click)='downloadPdf()'>
                  <i class="fa fa-file-pdf-o mr-3 MoodysDownloadPDF"></i>PDF
                </button>
                <!-- <div class="dropdown-divider"></div>
                              <a class="dropdown-item dropdown-footer"></a> -->
              </div>
              <!--End::Dropdown Menu-->
            </div>
          </div>
        </div>
        <div class="card-tools" style="margin-left: 20px;">
          <div class="input-group input-group-sm" style="width: 150px;">
            <!-- <button type="button" title="Add New Client" class="btn btn-default btn-sm" data-toggle="collapse"
              data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" style="width: 120px;"
              (click)="AddClient()">
              Add Client
            </button> -->
            <div class="input-group-append">
            </div>
          </div>
        </div>
        <div class="card-tools ml-2">
          <div class="input-group input-group-sm" style="width: 180px;">
            <input type="text" #search name="table_search" [(ngModel)]="searchInput" (keyup)="checkSearch()"
              (keydown.enter)="searchJobs()" class="form-control float-" placeholder="Search" />
            <div class="input-group-append">
              <button type="submit" class="MoodysSearchButton" (click)="searchJobs()">
                <i class="fas fa-search RMSSearchButtonIcon"></i>
              </button>
              <button class="MoodysSearchButton" style="margin-left: 1px;" type="submit" data-toggle="tooltip"
                data-placement="top" title="Refresh" id="basic-text1" (click)="resetRecords()">
                <i class="fas fa-history text-grey" style="color: #ffffff;" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <!-- <div class="col-lg-12 ErrorMessage NoPadding" *ngIf="showSearchValidationError"
            style=" padding-top:10px; color: #c42032; font-size: 0.8rem;">
            Special characters are not allowed
          </div> -->
        </div>
        <!-- 
        <div class="card-tools">
          <div class="input-group input-group-sm">
            <h3 class="card-title selector" style="font-size: small;">
              Status
              <select class="selectstatus" (change)="checkclientstatus($event.target.value)">
                <option value="all">All</option>
                <option selected value="active">Active</option>
                <option value="deactive">Deactive</option>
              </select>
            </h3>
          </div>
        </div> -->
      </div>
    </div>
    <!-- /.card -->
  </div>
  <!-- /.container-fluid -->
</section>


<section class="content">

  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default">
      <div class="card-header">
        <h5 *ngIf="!isData">
          {{gridMessage}}
        </h5>
        <div class="card-body table-responsive p-0 text-nowrap" *ngIf="isData">
          <table class="table table-bordered table-hover font14 noPad" id="tabledetail">
            <thead>
              <tr class="RMSTableHeader">

                <th *ngFor="let header of fieldHeader |slice:1 let i=index" (click)="selectColumn(i,header)"
                  style="text-align: center; width: 20px; cursor: pointer;" class="text-center">
                  {{ header | titlecase }}
                  <div class="btn-group-vertical">
                    <i class="fas fa-sort-amount-up-alt" *ngIf="i===columSelected && sortUP"></i>
                    <i class="fas fa-sort-amount-down-alt" *ngIf="i===columSelected && sortDesc"></i>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="RMSTableContent">
              <tr class="RmsTableRow" *ngFor="let item of data let i = index" id="tr_{{i}}"
                (click)="selectRow('tr_' + i)" class="RmsTableRow" style="height: 35px;">
                <td *ngFor="let header of fieldHeader | slice:1" class="text-center">
                  <div *ngIf="header != 'start Date'&& header !='end Date' && header!='status' ">
                    <span>{{item[header]}}</span>
                  </div>
                  <div *ngIf=" header == 'start Date' || header == 'end Date'">

                    <span>{{ item[header]|date:'dd-MMM-yyyy hh:mm a' }}</span>

                  </div>
                  <div *ngIf="header == 'status'">

                    <span Class="badge  badge-settings" [ngClass]="item['statusSign']">{{ item[header] }}</span>

                  </div>

                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <!--end .section-body -->
      </div>
      <div class="row" style="margin-top: 10px;" *ngIf="isData">
        <div class="col-sm-4" style="align-items: center; text-align: center;">
          <label style="
              font-weight: normal;
              float: left;
              font-size: smaller;
              margin-right: 5px;
              margin-left: 10px;
            ">Show</label>
          <select title="Records to show" class="form-control" style="
              width: 2rem;
              height: 22px;
              padding: 0px;
              padding-left: 2px;
              font-size: smaller;
              float: left;
              text-align: center;
            " (change)="Records($event.target.value)">
            <!-- <option value="null">--Records--</option> -->
            <!-- <option value="1" > 1 </option> -->
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100" selected="true">100</option>
            <option value="500">500</option>
            <option value="all">All</option>
          </select>
          <label style="
              font-weight: normal;
              float: left;
              font-size: smaller;
              margin-left: 5px;
            ">Records</label>
        </div>
        <label class="col-sm-4" style="font-size: smaller; text-align: center; font-weight: normal;">Showing
          {{ pageStart + 1 }} to {{ pageStart + rowCount }} of
          {{ totalCount }} Records</label>
        <div class="col-sm-4">
          <nav aria-label="Page navigation" class="float-right">
            <ul class="pagination float-right">
              <li class="page-item">
                <a class="RMSButtonPageNavigation page-link" (click)="PageChange(1)" aria-label="First">
                  <label class="fa fa-angle-double-left" style="
                      margin-left: -4px;
                      font-size: small !important;
                      font-weight: bold !important;
                    "></label>
                  <!-- <span class="sr-only">Previous</span> -->
                </a>
              </li>
              <li class="page-item">
                <a (click)="PageChange(currentPage - 1)" class="RMSButtonPageNavigation page-link"
                  aria-label="Previous">
                  <label class="fa fa-angle-left" style="
                      font-size: small !important;
                      font-weight: bold !important;
                    "></label>
                </a>
              </li>
              <li class="page-item" *ngIf="previousDotShow">
                <label class="RMSButtonPageNavigation" style="font-weight: bold !important; padding-top: 9px;">...</label>
              </li>
              <li *ngFor="let i of item">
                <a (click)="PageChange(i)" [ngClass]="{'selectedPage':i===selectedPageNo}"
                  class="RMSButtonPageNavigation page-link">
                  <label style="
                      vertical-align: sub !important;
                      font-size: small;
                      margin-left: -4px;
                    ">{{ i }}</label>
                </a>
              </li>
              <li class="page-item" *ngIf="nextDotShow">
                <!-- <a   class="RMSButtonPageNavigation page-link text-info" >                   -->
                <label class="RMSButtonPageNavigation" style="font-weight: bold !important; padding-top: 9px;">...</label>
                <!-- </a> -->
              </li>
              <li class="page-item">
                <a (click)="PageChange(currentPage + 1)" class="RMSButtonPageNavigation page-link"
                  aria-label="Next">
                  <label class="fa fa-angle-right" style="
                      font-size: small !important;
                      font-weight: bold !important;
                    "></label>
                </a>
              </li>
              <li class="page-item">
                <a (click)="PageChange(pageCount)" class="RMSButtonPageNavigation page-link"
                  aria-label="Last">
                  <label class="fa fa-angle-double-right" style="
                      margin-left: -4px;
                      font-size: small !important;
                      font-weight: bold !important;
                    "></label>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!-- /.card -->
  </div>
  <!-- /.container-fluid -->
</section>

<!-- /.content -->