<hr style="margin-top: 0px !important;" />
<section *ngIf="notfound || notification" class="content">
  <div class="container-fluid">
    <div class="card card-default">
      <div class="card-header">
        <div *ngIf="notfound" class="col-lg-12 alert alert-danger text-center">
          Old password is not matched.
        </div>
        <div class="alert alert-success alert-dismissible" *ngIf="notification">
          Password has been changed successfully!
          <p class="lead">
            Please <a routerLink="/login">Click Here</a> to login again.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="template" class="content">
  <div class="container-fluid">
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor">Change Password</h3>
      </div>
    </div>
  </div>
</section>
<section *ngIf="template" class="content">
  <div class="container-fluid">
    <div class="card card-default">
      <div class="card-header">

        <form [formGroup]="FormData" autocomplete="off"
          (ngSubmit)="ChangePassword(oldpasssword.value, newpassword.value)">
          <div>
            <div class='row'>
              <div class='col-lg-2'>Old Password</div>
              <div class='col-lg-4'>
                <div class="input-group">
                  <!-- <span class="input-group-addon" style="border-radius: 0px; background-color: #c42032;">
                    <i class="fas fa-key"></i></span> -->
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="height: 100%;"><i class="fas fa-key"></i></span>
                    </div>
                  <input type="password" autocomplete="off" class="form-control inputClass" #oldpasssword
                    (ngModelChange)="ShowOldPassword()" formControlName="Old_Password" placeholder="Old password"
                    style="border-radius: 4px; max-width: 100%; font-weight: 200;" />
                </div>
              </div>
              <div class='col-lg-4'>
                <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                  <div *ngIf="f.Old_Password.errors && ShowOldPasswordErrors">
                    <div *ngIf="f.Old_Password.errors.required">
                      Old Password is required.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class='row' style=" padding-top: 20px;">
              <div class='col-lg-2'>New Password </div>
              <div class='col-lg-4'>
                <div class="input-group">
                  <!-- <span class="input-group-addon" style="
                                font-size: 14.5px;
                                border-radius: 0px;
                                background-color: #c42032;
                              "><i class="fas fa-key" style="font-size: 14.5px;"></i></span> -->
                              <div class="input-group-prepend">
                                <span class="input-group-text" style="height: 100%;"><i class="fas fa-key"></i></span>
                              </div>
                  <input type="password" class="form-control inputClass" autocomplete="off"
                    (ngModelChange)="ShowNewPasswordError()" #newpassword placeholder="New password"
                    formControlName="New_Password" style="border-radius: 4px; max-width: 100%; font-weight: 200;" />
                  <div *ngIf="ShowNewPasswordErrors && f.New_Password.errors" class="tooltip"
                    style="display: contents;">
                    <i class="fa fa-exclamation-circle" style="
                                  font-size: 18.5px;
                                  color: #c42032;
                                  margin-top: 8px;
                                  position: absolute;
                                  right: 2%;
                                ">
                    </i>
                    <span class="tooltiptext">
                      <b>*</b> Password minimum length :10<br />
                      <b>*</b> Password maximum length :16<br />
                      <b>*</b> Minimum required numeric number :1<br />
                      <b>*</b> Minimum required lower-case letters :1<br />
                      <b>*</b> Minimum required upper-case letters :1<br />
                      <b>*</b> Minimum required special characters :1<br />
                      <b>*</b> Allowable special characters :!&#64;#$%*()_+^&&#125;{{"{"}}:;?."<br />
                    </span>
                  </div>
                </div>
              </div>
              <div class='col-lg-4'>
                <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                  <div *ngIf="ShowNewPasswordErrors && f.New_Password.errors">
                    <div *ngIf="f.New_Password.errors.required">
                      New Password is required
                    </div>
                    <div *ngIf="f.New_Password.errors.minlength">
                      New Password is too short
                    </div>
                    <div *ngIf="f.New_Password.errors.maxlength">
                      New Password is too long
                    </div>
                    <div *ngIf="f.New_Password.errors.pattern">
                      New Password is invalid
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="padding-top: 20px;">
            <div class='row'>
              <div class='col-lg-2'>Confirm Password </div>
              <div class='col-lg-4'>
                <div class="input-group">
                  <!-- <span class="input-group-addon" style="border-radius: 0px; background-color: #c42032;">
                    <i class="fas fa-key"></i></span> -->
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="height: 100%;"><i class="fas fa-key"></i></span>
                    </div>
                  <input (capsLock)="capsOn=$event" type="password" autocomplete="off" class="form-control inputClass"
                    (ngModelChange)="ShowConfirmPasswordError()" formControlName="Confirm_Password"
                    placeholder="Confirm password" style="border-radius: 4px; max-width: 100%; font-weight: 200;" />
                </div>
              </div>
              <div class='col-lg-4'>
                <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                  <div *ngIf="f.Confirm_Password.errors && ShowConfirmPasswordErrors">
                    <div *ngIf="f.Confirm_Password.errors.required">
                      Confirm Password is required.
                    </div>
                    <div *ngIf="f.Confirm_Password.errors.mustMatch">
                      Confirm Passwords must match
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="padding-top: 10px;">
            <div class='row'>
              <div class="col-lg-2" style="float: right;"></div>
              <div class="col-lg-4 ErrorMessage  NoPadding" style="height: 1rem !important;float: right;">
                <div *ngIf="capsOn">
                  <div style="background: #fffb0733;">
                    Warning: Caps Lock is ON
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div style="padding-top: 20px;">
            <!-- <button class="btn btn-success" >Submit</button> -->
            <div class="row" style="margin-left: 15px; float: left;">
              <button class="btn btn-primary RMSSubmitButton"
                style="margin-bottom:10px; margin-right: 10px;">Submit</button>
              <a routerLink='/userprofile'> <button type="button" class="btn btn-primary RMSButtonCancel"
                  style="margin-bottom:10px;">Back</button></a>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</section>