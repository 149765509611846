import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MenuInfo } from '../../model/Menu/MenuInfo';

@Injectable({
  providedIn: 'root'
})
export class GetMenuService {

  constructor(private http: HttpClient) { }

  GetMenuListByUser(url: string){
    
    return this.http.post<MenuInfo[]>(url, null).pipe(map(
      res => {
        let data = res;
          return data;
      },
      err => {
        let data = err.json()
        console.error(err)
        return data;
      }
    ));
  }
}