import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicElementBase } from 'src/common/DynamicElementBase';
import { UploadService } from 'src/services/Upload/Upload.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { DataShareService } from 'src/services/Common/dataShare.service';
import { DatePipe } from '@angular/common'




@Component({
  selector: 'app-dynamic-elements',
  templateUrl: './dynamic-elements.component.html',
  styleUrls: ['./dynamic-elements.component.css']
})
export class DynamicElementsComponent implements OnInit, OnDestroy {
  @Input() element: DynamicElementBase<string>;
  @Input() OriginalAccountList: any[];
  @Input() form: UntypedFormGroup;
  @Input() showError: boolean = false;
  @Input() private refresh: EventEmitter<boolean>;
  @Output() getRecentUpload: EventEmitter<string> = new EventEmitter();
  @Output() SetPackageType:EventEmitter<string>=new EventEmitter();
  @Output() SetOriginalAccount: EventEmitter<any[]> = new EventEmitter();  
  WorkBUMapping: any[] = [];
  defaultVal: any[] = [];
  PackageTypeMaster: any;
  get isValid() { return this.form.controls[this.element.key].valid; }
  constructor(private service: UploadService, private dataShare: DataShareService, public datepipe: DatePipe) {

  }
  ngOnDestroy(): void {
    this.dataShare.ShareAccountType('');
  }

  ngOnInit(): void {

    if (this.element.key == "Original Account") {
      this.element.errorMsg = "Original Account is required";
    }

    if (this.element.key == "Account Type") {
      this.onChange(this.element.value);

    }
    if (this.refresh) {
      this.refresh.subscribe(data => {
        this.onChange(this.form.get("Account Type").value);
      });
    }
  }
  // private formatDate(date, addYear: number) {
  //   const d = new Date(date);
  //   let month = '' + (d.getMonth() + 1);
  //   let day = '' + d.getDate();
  //   let year = d.getFullYear();
  //   if (addYear > 0)
  //     year = year + addYear;
  //   if (month.length < 2) month = '0' + month;
  //   if (day.length < 2) day = '0' + day;
  //   return [year, month, day].join('-');
  // }


  //   onAccountTypeChanged(val:any)
  //   {
  // console.log(val);
  // this.form.controls["WorkType"];
  //   } 
  get isTouched() {
    if (this.form.controls[this.element.key].errors['required']) {
      //console.log(this.element.key+" is required");
      this.element.errorMsg = this.element.key + " is required";
    }
    if (this.form.controls[this.element.key].errors['max']) {
      //console.log(this.element.key+" is required");
      this.element.errorMsg = "Value Provided can't be grater than " + this.form.controls[this.element.key].errors['max']['max'] + '.';
    }
    if (this.form.controls[this.element.key].errors['min']) {
      //console.log(this.element.key+" is required");
      this.element.errorMsg = "Value Provided can't be less than 0.";
    }
    if (this.form.controls[this.element.key].errors['maxlength'] && this.element.key.includes('No. of Locs')) {
      this.element.errorMsg = 'Only ' + this.form.controls[this.element.key].errors['maxlength']['requiredLength'] + ' digits are allowed';
    }
    else if (this.form.controls[this.element.key].errors['maxlength']) {
      this.element.errorMsg = "More than " + this.form.controls[this.element.key].errors['maxlength']['requiredLength'] + ' characters not allowed';
    }
    if (this.form.controls[this.element.key].errors['pattern']) {
      let characters: string = this.form.controls[this.element.key].errors['pattern']['requiredPattern'];
      if (characters.substring(1, characters.length - 2).includes("@"))
        this.element.errorMsg = "Couldn't start with characters like = + @ -";
      else if (characters.substring(1, characters.length - 2).includes("<>"))
        this.element.errorMsg = "Characters like * . % ? < > , | : ; = + ! # ` \" ~ ^ / \ ()[ ]{ } are not allowed";
      else if (characters.substring(1, characters.length - 2).includes("\d"))
        this.element.errorMsg = "Only numeric values allowed";
      else
        this.element.errorMsg = "Values provided are invalid";
    }
    return this.form.controls[this.element.key].dirty;
  }

  get isWarning() {
    this.element.warningMsg = '';
    if (this.element.validations != undefined && this.element.validations.length > 0) {
      this.element.validations.forEach(validation => {
        let value: any = this.form.controls[this.element.key].value;
        if (validation['isIgnorable'] && (value != undefined || value != null || value != '')) {
          if (validation['validationType'] === 'MaxLength' && value.length > validation['validationValue']) {
            this.element.warningMsg = this.element.key + " should not be longer than " + validation['validationValue'] + ' characters';

          }

          if (validation['validationType'] == 'SpecialCharacterAtStart') {
            var t = new RegExp('^' + validation['validationValue'] + '$', 'im');
            if (!t.test(value)) {
              this.element.warningMsg = this.element.key + "should not start with characters like = + @ -";

            }
          } else if (validation['validationType'] == 'SpecialCharacter') {
            var t = new RegExp('^' + validation['validationValue'] + '$', 'im');
            if (!t.test(value)) {
              this.element.warningMsg = this.element.key + "should not contain characters like = + @ -";

            }
          } else if (validation['validationType'] == 'NumericCheck') {
            var t = new RegExp('^' + validation['validationValue'] + '$', 'im');
            if (!t.test(value)) {
              this.element.warningMsg = this.element.key + " should have numeric values only";

            }
          }
        }
      });
    }
    return this.form.controls[this.element.key].dirty;
  }
  TextInput(value: string) {
    if (this.element.key.toUpperCase() === "POLICY REF") {
      // console.log(this.element.key.toUpperCase() + " :" + value);
      this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.ISSHOWRECENTACCOUNT, '').subscribe(result => {
        if (result) {
          this.getRecentUpload.emit(value);
        }
      });
    }
    if (this.element.key.toUpperCase() === "ACCOUNT NAME") {
      let formData = new FormData();
      formData.append('AccountName', value);
      this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.ISALREADYUPLOADEDACCOUNT, formData).subscribe(result => {
        //console.log("Account uploaded already :"+result);
        if (result==1) {
          this.form.get(this.element.key).setErrors({
            notUnique: true
          });
          this.element.errorMsg = "Account uploaded already";
        }

        if (result==2) {
          this.form.get(this.element.key).setErrors({
            notUnique: true
          });
          this.element.errorMsg = "Account already exists, please try adding suffix e.g. _1, _2";
        }

      });
    }
  }
  onChange(value: any) {    
    if (value == undefined || value == '')
      return;
    if (this.element.key.toUpperCase() === "ACCOUNT TYPE") {

      // let AccountType:string;
      // this.dataShare.AccountType$.subscribe(accounttype=>AccountType=accounttype);     
      this.dataShare.ShareAccountType(value);      
      let formData = new FormData();

      formData.append('AccountType', value);
      
      this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETWORKTYPEONACCOUNTTYPE, formData).subscribe(result => {

        this.SetOriginalAccount.emit(result['tblOriginalAccounts']);
        this.WorkBUMapping = result['tblBUWorkType'];
        this.defaultVal = result["tblDefaultVal"];        


        //this.dataShare.GetWorkTypeBUMapping(this.WorkBUMapping, this.defaultVal);
        let UserField8Data = result['tblUserFieldData'];
        let ClientSpecificElement = result['tblClientSpecificElement'];

        let workTypeToShow: string = '<option value="">---Select---</option>';
        let businessUnitToShow = '<option value="">---Select---</option>';
        let BUCount: number = 0;
        this.WorkBUMapping.forEach(element => {
          if (!workTypeToShow.includes(element["workType"]))
            workTypeToShow = workTypeToShow + "<option>" + element["workType"] + "</option>";
          if (!businessUnitToShow.includes("<option>"+element["businessUnit"]+ "</option>")) {
            businessUnitToShow = businessUnitToShow + "<option>" + element["businessUnit"] + "</option>"
            BUCount++;
          }
        });
        document.getElementById("Business Unit").innerHTML = businessUnitToShow;
        //set default business unit 
        if (this.WorkBUMapping.filter(t => t["businessUnit"] == this.defaultVal[0]['defaultBusinessUnit']).length > 0)
          this.form.get('Business Unit').setValue(this.defaultVal[0]['defaultBusinessUnit']);
        else
          this.form.get('Business Unit').setValue('');
        if (BUCount == 1)
          this.form.get('Business Unit').setValue(this.WorkBUMapping[0]["businessUnit"]);

        

        if (ClientSpecificElement != undefined && ClientSpecificElement.length > 0) {
          ClientSpecificElement.forEach(element => {
            let UserFieldVal: string = '<option value="">---Select---</option>';
            let valCount: number = 0;
            let filedValue: string = "";
            if (UserField8Data != undefined && UserField8Data.length > 0) {
              UserField8Data.forEach(elementData => {
                if (this.form.get('Business Unit').value != '' && this.form.get('Business Unit').value != undefined) {
                  if (!UserFieldVal.includes(elementData["className"]) && elementData["businessUnit"] == this.form.get('Business Unit').value) {
                    UserFieldVal = UserFieldVal + "<option>" + elementData["className"] + "</option>";
                    filedValue = elementData["className"];
                    valCount++;
                  }
                }
                // else {
                //   UserFieldVal = UserFieldVal + "<option>" + elementData["className"] + "</option>";
                // }
              });
            }
            if (document.getElementById(element["elementName"]) != null) {
              document.getElementById(element["elementName"]).innerHTML = UserFieldVal;
              if (valCount == 1)
                this.form.get(element["elementName"]).setValue(filedValue);
            }

          });
        }
        this.RefreshUserField10Combo(result);
        document.getElementById("Work Type").innerHTML = workTypeToShow;
        //set default values for business unit and work type
        if (this.WorkBUMapping.filter(t => t["workType"] == this.defaultVal[0]['defaultWorkType']).length > 0)
          this.form.get('Work Type').setValue(this.defaultVal[0]['defaultWorkType']);
        else
          this.form.get('Work Type').setValue('');
          // let dBu=this.defaultVal[0]['defaultBusinessUnit']
          // let dWt=this.defaultVal[0]['defaultWorkType']
          // console.log(dBu,dWt)

          // console.log(this.form.get('Business Unit').value)
          // console.log(this.form.get('Work Type').value)
          this.GetPackageMaster(value,this.form.get('Business Unit').value,this.form.get('Work Type').value)

      });
    }
    else if (this.element.key.toUpperCase() === "BUSINESS UNIT") {
      let AccountType: string;
      this.dataShare.AccountType$.subscribe(accounttype => AccountType = accounttype);
      let formData = new FormData();
      formData.append('AccountType', AccountType);
      // alert("businessunit")
      this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETWORKTYPEONACCOUNTTYPE, formData).subscribe(result => {
        // console.log(result)
        this.WorkBUMapping = result['tblBUWorkType'];
        this.defaultVal = result["tblDefaultVal"];
        let UserField8Data = result['tblUserFieldData'];
        let ClientSpecificElement = result['tblClientSpecificElement'];

        if (ClientSpecificElement != undefined && ClientSpecificElement.length > 0) {
          ClientSpecificElement.forEach(element => {
            let UserFieldVal: string = '<option value="">---Select---</option>';
            let valCount: number = 0;
            let filedValue: string = "";
            // console.log(this.form.get('Business Unit').value)
            if (UserField8Data != undefined && UserField8Data.length > 0) {
              UserField8Data.forEach(elementData => {
                if (this.form.get('Business Unit').value != '' && this.form.get('Business Unit').value != undefined) {
                  if (!UserFieldVal.includes(elementData["className"]) && elementData["businessUnit"] == this.form.get('Business Unit').value) {
                    UserFieldVal = UserFieldVal + "<option>" + elementData["className"] + "</option>";
                    filedValue = elementData["className"];
                    valCount++;
                  }
                }
                // else {
                //   UserFieldVal = UserFieldVal + "<option>" + elementData["className"] + "</option>";
                // }
              });
            }
            if (document.getElementById(element["elementName"]) != null) {
            document.getElementById(element["elementName"]).innerHTML = UserFieldVal;
            if (valCount == 1)
                this.form.get(element["elementName"]).setValue(filedValue);
            }
              
          });
        }

        this.RefreshUserField10Combo(result);
        let workTypeToShow: string = '<option value="">---Select---</option>';
        let worktypeCount: number = 0;
        if (this.WorkBUMapping != undefined && this.WorkBUMapping.length > 0) {
          this.WorkBUMapping.forEach(element => {
            if (this.form.get('Business Unit').value != '' && this.form.get('Business Unit').value != undefined) {
              if (!workTypeToShow.includes(element["workType"]) && element["businessUnit"] == this.form.get('Business Unit').value) {
                workTypeToShow = workTypeToShow + "<option>" + element["workType"] + "</option>";
                worktypeCount++;
              }
            } else {
              workTypeToShow = workTypeToShow + "<option>" + element["workType"] + "</option>";
            }
          });
        }
        document.getElementById("Work Type").innerHTML = workTypeToShow;
        if (worktypeCount == 1)
          this.form.get('Work Type').setValue(this.WorkBUMapping["workType"]);
        //select default work type
        if (this.WorkBUMapping.filter(t => t["workType"] == this.defaultVal[0]['defaultWorkType']).length > 0)
          this.form.get('Work Type').setValue(this.defaultVal[0]['defaultWorkType']);
        else
          this.form.get('Work Type').setValue('');

          // console.log('default work type ', this.defaultVal[0]['defaultWorkType']);
          // console.log("def type",this.WorkBUMapping["workType"])
          // console.log(this.WorkBUMapping)
          // console.log(this.form.get('Business Unit').value)
          // console.log(this.form.get('Work Type').value)
          this.GetPackageMaster(AccountType,this.form.get('Business Unit').value,this.form.get('Work Type').value)
      });
      //this.dataShare.workTypeBUMapping$.subscribe(workBU => this.WorkBUMapping = workBU);
      //this.dataShare.workTypeBUDefault$.subscribe(defaultBUWork => this.defaultVal = defaultBUWork);
      // console.log(this.defaultVal,this.WorkBUMapping);     
    
    }
    if (this.element.key.toUpperCase() === "PACKAGE TYPE"){
      this.SetPackageType.emit(value)
    }
    if (this.element.key.toUpperCase() === "WORK TYPE"){
    
     this.GetPackageMaster(this.form.get("Account Type").value,this.form.get('Business Unit').value,this.form.get('Work Type').value)
    }
  }

  RefreshUserField10Combo(result: object) {
    let UserField10Data = result['tblUserField10Data'];
    let ClientSpecificElement10 = result['tblClientSpecificElement10'];


    if (ClientSpecificElement10 != undefined && ClientSpecificElement10.length > 0) {

      ClientSpecificElement10.forEach(element => {
        let UserFieldVal: string = '<option value="">---Select---</option>';
        let valCount: number = 0;
        let filedValue: string = "";
        if (UserField10Data != undefined && UserField10Data.length > 0) {
          UserField10Data.forEach(elementData => {
            if (this.form.get('Business Unit').value != '' && this.form.get('Business Unit').value != undefined) {
              if (!UserFieldVal.includes(elementData["fieldValue"]) && elementData["businessUnit"] == this.form.get('Business Unit').value) {
                UserFieldVal = UserFieldVal + "<option>" + elementData["fieldValue"] + "</option>";
                filedValue = elementData["fieldValue"];
                valCount++;
              }
            }
          });
        }
        if (document.getElementById(element["elementName"]) != null) {
          document.getElementById(element["elementName"]).innerHTML = UserFieldVal;
          if (valCount == 1)
            this.form.get(element["elementName"]).setValue(filedValue);
        }
      });
    }

  }

  GetPackageMaster(AccountTypeId: string, BusinessUnitId: string, WorkType: string) {
    if (AccountTypeId != undefined && BusinessUnitId != undefined) {
      let formData = new FormData();
      formData.append('AccountTypeId', AccountTypeId);
      formData.append('BusinessUnitId', BusinessUnitId);
      formData.append('WorkType', WorkType);
      
      this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETPACKAGEMASTER, formData).subscribe(
        res => {
          if (res != null) {
            this.PackageTypeMaster = res;
            let SetPackage:string;          
        let PackageTypeToShow = '<option value="">---Select---</option>';
        let BUCount: number = 0;
       this.PackageTypeMaster.forEach(element=>{
         {
          PackageTypeToShow = PackageTypeToShow + "<option>" + element["packageTypeName"] + "</option>"
          BUCount++;
         }

         if (document.getElementById("Package Type") != null) {
           document.getElementById("Package Type").innerHTML = PackageTypeToShow;
         }
       })

            if (this.form.get('Package Type') != null) {

              this.form.get('Package Type').setValue(this.PackageTypeMaster[0]['packageTypeName']);
            }
         
            // this.IsPackageFeature = true;
            // console.log(res)
          }else{
            // this.IsPackageFeature = false;
          }
        }, err => {
          // console.log(err);
        }
      )
    }
  }









  dateSelected() {

    //check if due date is smaller than the current date and set error message
    if (this.element.key.toUpperCase() == 'DUE DATE') {
      let dueDate = this.datepipe.transform(new Date(this.form.get('Due Date').value), 'yyyy-MM-dd');
      let currentDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      if (dueDate < currentDate) {
        this.element.errorMsg = "Due date can't be smaller than current date";
        this.form.controls[this.element.key].setErrors({ 'InvalidValue': true });
      } else {
        if(document.getElementById('errorDue Date'))
        document.getElementById('errorDue Date').innerHTML = "";
        this.form.controls['Due Date'].setErrors({});
        this.form.controls['Due Date'].updateValueAndValidity();
      }
    }

    if (this.element.key.toUpperCase() == 'INCEPT DATE' || this.element.key.toUpperCase() == 'EXPIRY DATE') {
 
      let inceptDate = this.datepipe.transform(new Date(this.form.get('Incept Date').value), 'yyyy-MM-dd');
      let expiryDate = this.datepipe.transform(new Date(this.form.get('Expiry Date').value), 'yyyy-MM-dd');
      if (inceptDate > expiryDate) {
        this.element.errorMsg = "Incept date can't be greater than expiry date";
        this.form.controls[this.element.key].setErrors({ 'InvalidValue': true });
      } else {
        if(document.getElementById('errorIncept Date'))
        document.getElementById('errorIncept Date').innerHTML = "";
        if(document.getElementById('errorExpiry Date'))
        document.getElementById('errorExpiry Date').innerHTML = "";
        this.form.controls['Incept Date'].setErrors({});
        this.form.controls['Incept Date'].updateValueAndValidity();
        this.form.controls['Expiry Date'].setErrors({});
        this.form.controls['Expiry Date'].updateValueAndValidity();
      }
    }
  }

  OriginalAccountSelected(event: any)
  {
    if (this.element.key.toUpperCase() == 'ORIGINAL ACCOUNT')
    {
      let OriginalAccount = this.form.get('Original Account');
      if (event != undefined)
      {
        OriginalAccount.setValue(event.value);
      }
      else
      {
        OriginalAccount.setValue('');
      }
    }
  }
}
