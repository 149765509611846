<section class="content">

  <div class="error-page">
    <h2 class="headline text-red">500</h2>

    <div class="error-content">
      <h3><i class="fa fa-warning text-red"></i> Oops! Something went wrong.</h3>

      <p>
        We will work on fixing that right away.
        Meanwhile, you may return to Home 
      </p>

      <form class="search-form">
        <div class="error-actions">
          <a routerLink="/home" class="btn btn-primary btn-lg MoodybackgroudColor" ><span
              class="glyphicon glyphicon-home"></span>
            Go To Home </a>

        </div>
        <!-- /.input-group -->
      </form>
    </div>
  </div>
  <!-- /.error-page -->

</section>