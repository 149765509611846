import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { GetUserClientInfo } from 'src/model/AccessManagement/GetUserClientInfo';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AlertService } from 'src/services/Common/alert.service';
import { AlertMessages } from 'src/common/alert-messages';
import { ErrorHandler } from 'src/common/error-handler';
import { numberFormat, objectEach } from 'highcharts';
import { PopupService } from 'src/services/Common/popUp.service';
import { CommonMethod } from 'src/common/CommonMethod';
import { combineAll, distinct } from 'rxjs/operators';
import { FeatureMaster } from 'src/common/feature-master';
import { jsonpFactory } from '@angular/http/src/http_module';


@Component({
  selector: 'app-addedituser',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit, OnChanges {
  action: string;
  @Output() cancel = new EventEmitter();
  clientUser: boolean;
  internalUser: boolean;
  editCheck = true;
  _editUser: GetUserClientInfo;
  checkerror: boolean = false;

  @Input() set editUser(value: GetUserClientInfo) {
    this._editUser = value;
  }
  constructor(private formbuilder: UntypedFormBuilder,
    private addedituserservice: AddEditUserSerivceService, private alertService: AlertService, private popServic: PopupService) { }
  addeditformData: UntypedFormGroup;
  listworkType: any[] = [];
  listBU: any[] = [];
  listaccType: any[] = [];
  listDefaultAccTypeList: any;
  listclient: any[];
  listLocation: any[] = [];
  selectedbu: any[] = [];
  userBusinessTypeList: any;
  DefaultAccountTypeList: any;
  selectedclientmapping: string[] = [];
  selectedaccounttypemapping: string[] = [];
  selectedapplications:string[]=[];
  selectedLocation: string;
  allowAction: boolean = false;
  listclientForMapping: any = [];
  listApplications:any=[];
  addBusinessUnit: any[] = [];
  addWorkType: any[] = [];
  listRole: [];
  errorHandle = new ErrorHandler(this.alertService);
  ngOnInit() {
    this.addeditformData = this.formbuilder.group({
      clientNameselector: [, [Validators.required]],
      usernameText: ['', [Validators.required, Validators.maxLength(50)]],
      emailaddresstext: ['', [Validators.required, Validators.email]],
      extenText: ['', [ Validators.maxLength(10)]],
      BUmappingSelector: [, [Validators.required]],
      locationSelector: [, [Validators.required]],
      roleSelector: [, [Validators.required]],
      phone: ['', [ Validators.maxLength(50)]],
      accounttypemappingSelector: [, [Validators.required]],
      clientmappingSelector: ['', [Validators.required]],
      applications:['',[Validators.required]],
      defaultaccountypeSelector: [, [Validators.required]],
      defaultbusinessunitSelector: [, [Validators.required]],
      defaultworktypeSelector: [,]
    })
  }
  ngOnChanges() {
    this.listBU = [];
    this.listaccType = [];
    this.listclient = [];
    this.listLocation = [];
    this.selectedbu = [];
    this.selectedclientmapping = [];
    this.selectedapplications=[];
    this.selectedaccounttypemapping = [];
    this.selectedLocation = null;
    this.listRole = [];
    this.CheckAction(this._editUser);
    this.CheckAuthorized();
  }
  get GetFormControls() {
    return this.addeditformData.controls;
  }
  CheckAction(passeditUser: GetUserClientInfo) {
    if (passeditUser != null && passeditUser != undefined) {
      //-----------------------for edit user------------
      ////---------------------set disabled client Name drop down-----------------//////     
      (document.getElementById('inputClientName') as HTMLButtonElement).disabled = true;
      this.action = 'Update';
      this.checkuserType(String(passeditUser.clientId));
      let formdata = new FormData();
      formdata.append('clientId', String(passeditUser.clientId));
      formdata.append('userId', String(passeditUser.userID));
      this.addedituserservice.GetClientDetail(formdata, AppSettings.API_ENDPOINT + AppRelativeUrl.GETSPECIFICCLIENTDETAIL).subscribe(response => {
        let responsedata = response;
        this.addBusinessUnit = responsedata['tblclientbusinessmappingList']
        this.addWorkType = response['tbleworktypeList'];
        this.listaccType = responsedata['tblclientaccountypemappingList'];
        let ListAccountType = responsedata['tbluseraccounttypeList'];
        for (let i = 0; i < ListAccountType.length; i++) {
          this.selectedaccounttypemapping.push(ListAccountType[i]['accountTypeID']);
        }

        this.FillDefaultAccountType();

        let BUList = responsedata['tbluserbusinessunitList'];
        for (let i = 0; i < BUList.length; i++) {
          if (!this.selectedbu.includes(BUList[i]['businessUnitID']))
            this.selectedbu.push(BUList[i].businessUnitID);
        }
        this.FillDefaultBusinessUnit();
        this.listRole = responsedata['tblroleList']
        this.listclientForMapping = responsedata['tblclientList'];   
        this.listApplications=responsedata['tblApplicationMaster'];
        this.listLocation = responsedata['tbllocationList'];
        this.listclient = responsedata['table9']
        this.selectedLocation = passeditUser.locationId;
        let clientlistId: any[] = responsedata['tblmappedclientList'];
        for (let i = 0; i < clientlistId.length; i++) {
          this.selectedclientmapping.push(clientlistId[i]['clientID']);
        }
        let mappedApplicationss=responsedata['tblmappedApplicstionsList'];
        for (let i = 0; i < mappedApplicationss.length; i++) {
          this.selectedapplications.push(mappedApplicationss[i]['applicationId']);
        }
        this.addeditformData.patchValue({
          BUmappingSelector: this.selectedbu,
          accounttypemappingSelector: this.selectedaccounttypemapping,
          clientmappingSelector: this.selectedclientmapping,
          usernameText: passeditUser["user Name"],
          emailaddresstext: passeditUser["email Address"],
          extenText: passeditUser.extension,
          locationSelector: passeditUser.locationId,
          roleSelector: passeditUser.roleId,
          phone: passeditUser.phone,
          clientNameselector: passeditUser.clientId,
          defaultbusinessunitSelector: passeditUser.defaultBusinessUnitId,
          defaultaccountypeSelector: passeditUser.defaultAccounttypeId,
          applications: this.selectedapplications
        });
        if (passeditUser.defaultWorkType != 0) {
          this.addeditformData.patchValue({
            defaultworktypeSelector: passeditUser.defaultWorkType
          })
        }
        else {
          this.addeditformData.patchValue({
            defaultworktypeSelector: null
          })
        }
      },
        error => {
          this.errorHandle.ErrorsHandler(error);
        });

    }
    else {
      this.action = 'Submit';
      let formdata = new FormData();
      formdata.append('clientId', '0');
      formdata.append('userId', '0');
      this.addedituserservice.GetClientDetail(formdata, AppSettings.API_ENDPOINT + AppRelativeUrl.GETSPECIFICCLIENTDETAIL).subscribe(response => {
        this.listclient = response['tblclientList'];
      },
        error => {
          this.errorHandle.ErrorsHandler(error);
        })
    }
  }
  checkuserType(clientid: string) {
    if (Number(clientid) > 2) {
      this.clientUser = true;
      this.internalUser = false;
    }
    else {
      this.internalUser = true;
      this.clientUser = false;
    }
  }
  GetClientInfo(clientid: string) {
    this.checkuserType(clientid);
    let formdata = new FormData();
    formdata.append('clientId', clientid);
    this.addedituserservice.GetClientDetail(formdata, AppSettings.API_ENDPOINT + AppRelativeUrl.GETSPECIFICCLIENTDETAIL).subscribe(response => {
      let responsedata = response;
      this.addBusinessUnit = responsedata['tblclientbusinessmappingList'];
      this.listaccType = responsedata['tblclientaccountypemappingList'];
      this.listRole = responsedata['tblroleList']
      this.listclientForMapping = responsedata['tblclientList'];
      this.listApplications=responsedata['tblApplicationMaster'];
      this.listLocation = responsedata['tbllocationList'];
      this.addWorkType = response['tbleworktypeList'];
    },
      error => {
        this.errorHandle.ErrorsHandler(error);
      })
  }
  CheckValidation(userId: string) {
    if (this.internalUser) {
      if (this.ValidationForInternalUser()) {
        this.FormSubmission(userId);
      }
      else {
        return;
      }
    }
    else {
      if (this.ValidationForClientUser()) {
        this.FormSubmission(userId);
      }
      else {
        return;
      }
    }
  }
  FormSubmit() {
    this.checkerror = true;
    if (this.allowAction) {
      if (this.action == 'Submit') {
        this.CheckValidation('0');
      }
      else {
        this.CheckValidation(String(this._editUser.userID));
      }
    }
    else {
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
    }
  }
  FormSubmission(userId: string) {
    let formData = new FormData();
    formData.append('userName', this.addeditformData.value.usernameText);
    formData.append('EmailId', this.addeditformData.value.emailaddresstext);
    if (this.addeditformData.value.phone != null && this.addeditformData.value.phone != undefined &&
      this.addeditformData.value.phone != '') {
      formData.append('Phone', this.addeditformData.value.phone);
    }
    if (this.addeditformData.value.extenText != null && this.addeditformData.value.extenText != undefined &&
      this.addeditformData.value.extenText != '') {
      formData.append('Extension', this.addeditformData.value.extenText);
    }
    formData.append('LocationID', this.addeditformData.value.locationSelector);
    formData.append('RoleID', this.addeditformData.value.roleSelector);
    if (this.addeditformData.value.defaultbusinessunitSelector != null) {
      formData.append('DefaultBU', this.addeditformData.value.defaultbusinessunitSelector)
    }
    formData.append('UserID', userId);
    formData.append('ClientID', this.addeditformData.value.clientNameselector);
    formData.append('DesUser_client', this.addeditformData.value.clientmappingSelector);
    if (this.addeditformData.value.defaultaccountypeSelector != '' && this.addeditformData.value.defaultaccountypeSelector != null) {
      formData.append('DefAccTypeID', this.addeditformData.value.defaultaccountypeSelector);
    }
    formData.append('AccountType', this.addeditformData.value.accounttypemappingSelector);
    formData.append('BUID', this.addeditformData.value.BUmappingSelector);
    if (this.addeditformData.value.defaultworktypeSelector != null
      && this.addeditformData.value.defaultworktypeSelector != ' '
      && this.addeditformData.value.defaultworktypeSelector != ''
    ) {
      formData.append('WorkType', this.addeditformData.value.defaultworktypeSelector);
    }
    else {
      formData.append('WorkType', '0');
    }
    formData.append('Applications',this.addeditformData.value.applications);
    this.AddEditServiceCall(formData)
  }
  ValidationForInternalUser(): boolean {
    if (
      this.addeditformData.controls['clientNameselector'].invalid ||
      this.addeditformData.controls['usernameText'].invalid ||
      this.addeditformData.controls['emailaddresstext'].invalid ||
      this.addeditformData.controls['clientmappingSelector'].invalid ||
      this.addeditformData.controls['roleSelector'].invalid ||
      this.addeditformData.controls['phone'].invalid ||
      this.addeditformData.controls['extenText'].invalid ||
      this.addeditformData.controls['applications'].invalid
    ) {
      return false;
    }
    else {
      return true;
    }
  }
  ValidationForClientUser(): boolean {
    if (
      this.addeditformData.controls['clientNameselector'].invalid ||
      this.addeditformData.controls['usernameText'].invalid ||
      this.addeditformData.controls['emailaddresstext'].invalid ||
      this.addeditformData.controls['BUmappingSelector'].invalid ||
      this.addeditformData.controls['accounttypemappingSelector'].invalid ||
      this.addeditformData.controls['roleSelector'].invalid ||
      this.addeditformData.controls['phone'].invalid ||
      this.addeditformData.controls['extenText'].invalid||
      this.addeditformData.controls['applications'].invalid
    ) {
      return false;
    }
    else {
      return true;
    }
  }
  AddEditServiceCall(formData: FormData) {
    let Data: any;
    Data = formData;
    this.addedituserservice.AddEditUser(formData, AppSettings.API_ENDPOINT + AppRelativeUrl.ADDEDITUSER).subscribe(
      response => {
        switch (response) {
          case 0: this.alertService.warn(AlertMessages.ALERTMESSAGE12); break;
          case 1: if (this._editUser) {
            this.alertService.success(AlertMessages.ALERTMESSAGE9)
          }
          else {
            this.alertService.success(AlertMessages.ALERTMESSAGE8)
          } break;
          case 2: this.alertService.warn(AlertMessages.ALERTMESSAGE14); break;
        }
        this.addeditformData.reset();
        this.cancel.emit(0);
      },
      error => {
        this.errorHandle.ErrorsHandler(error);
      }
    )
  }
  Cancel(): void {
    this.cancel.emit(0);
  }
  FillDefaultBusinessUnit() {
    this.listworkType = [];
    let workIds = [];
    this.selectedbu.forEach(selecBuItem => {
      this.addBusinessUnit.forEach(BUitem => {
        if (selecBuItem == BUitem.businessUnitID) {
          if (!workIds.includes(BUitem.workTypeId)) {
            workIds.push(BUitem.workTypeId)
          }
        }
      })
    })
    workIds.forEach(item => {
      if (item == 1) {
        this.listworkType.push(this.addWorkType[0])
      }
      if (item == 2) {
        this.listworkType.push(this.addWorkType[1])
      }
      if (item == 3) {
        this.listworkType.push(this.addWorkType[2])
      }
    })
    this.userBusinessTypeList = [];
    this.addeditformData.controls['defaultbusinessunitSelector'].reset();
    for (let i = 0; i < this.selectedbu.length; i++) {
      for (let j = 0; j < this.listBU.length; j++) {
        if (Number(this.selectedbu[i]) == Number(this.listBU[j]['businessUnitID'])) {
          this.userBusinessTypeList.push(this.listBU[j]);
          break;
        }
      }
    }
  }
  FillDefaultAccountType() {
    this.listBU = [];
    this.selectedbu = [];
    this.addeditformData.controls['defaultaccountypeSelector'].reset();
    this.addeditformData.controls['defaultbusinessunitSelector'].reset();
    this.userBusinessTypeList = [];
    this.DefaultAccountTypeList = [];
    this.listworkType = [];
    this.addeditformData.patchValue({
      defaultworktypeSelector: null
    })
    for (let j = 0; j < this.selectedaccounttypemapping.length; j++) {
      for (let i = 0; i < this.addBusinessUnit.length; i++) {
        if (this.selectedaccounttypemapping[j] == this.addBusinessUnit[i]['accountTypeId']) {
          let objBU: any =
          {
            "businessUnitID": this.addBusinessUnit[i]['businessUnitID']
            , "businessUnitDescription": this.addBusinessUnit[i]['businessUnitDescription']
            , "workTypeId": this.addBusinessUnit[i]['workTypeId']
          }
          if (!this.listBU.some(item => item.businessUnitID === objBU.businessUnitID))
            this.listBU.push(objBU);

        }
      }
    }

    for (let i = 0; i < this.selectedaccounttypemapping.length; i++) {
      for (let j = 0; j < this.listaccType.length; j++) {
        if (this.selectedaccounttypemapping[i] == this.listaccType[j]['accountTypeID'])
          this.DefaultAccountTypeList.push(this.listaccType[j]);
      }
    }
  }
  CheckAuthorized() {
    this.addedituserservice.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      if (res != null) {
        var obj: CommonMethod = new CommonMethod();
        this.allowAction = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.AddEdit_User);

        ////---------------------set disabled client Name drop down-----------------//////
        // (document.getElementById('inputClientName') as HTMLButtonElement).disabled = !this.allowAction;
      }
    },
      err => {
        this.errorHandle.ErrorsHandler(err);
      })
  }
  ShowError() {
    this.checkerror = true;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

}

