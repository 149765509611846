import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecentJobsRoutingModule } from './recent-jobs-routing.module';
import { RecentJobsComponent } from './recent-jobs/recent-jobs.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    RecentJobsComponent
  ],
  imports: [
    CommonModule,
    RecentJobsRoutingModule,
    FormsModule
  ]
})
export class RecentJobsModule { }
