<div class="container-fluid">
    <div class="container-fluid" role="document">
        <div>
            <div class="container-fluid mt-3">
                <form [formGroup]="AddRoleForm">
                    <div class="form-group row" style="margin-top: 10px;">
                        <div class="col-sm-2 col-form-label">
                            <b>
                                <h6 for="recipient-name" class="MoodysTittleColor">Role Name:</h6>
                            </b>
                        </div>
                        <div class="col-sm-4">
                            <input #myInput type="text" formControlName="RoleName" class="form-control input-sm-4"
                                id="recipient-name" autofocus>
                        </div>
                    </div>
                    <hr>
                    <div class="form-group" style=" overflow-y: auto; overflow-x: hidden;">
                        <b>
                            <h6 for="message-text" class="MoodysTittleColor">Permission:</h6>
                        </b>
                        <div *ngFor="let menu of MenuListData; let g=index">
                               <div class="row container-fluid">                                                     
                                <label  style="font-weight:bold;font-size: 14px;" (click)="OnClick(menu)">  
                                    <input type="checkbox" id="menu{{menu['id']}}" style="margin-left: 20px;">                                  
                                    {{ menu.menuName | titlecase}} 
                                </label>
                                <!-- <label  style="font-weight:normal; margin-left: 20px;" >Select All</label> -->
                               
                            </div>    
                                <div class="row" style="margin-left: 20px;"> 
                                <div *ngFor="let feature of FeatureListData; let i=index" formArrayName="FeatureList">
                                    <label *ngIf="feature.menuId===menu.id" class="label" style="font-weight: normal;margin-left: 20px;">
                                        <input class="toggleCheckbox" type="checkbox" [formControlName]="i" id="feature{{feature['id']}}" style="margin-right: 5px;"><label class="toggle" for="feature{{feature['id']}}"></label>
                                         {{ feature.featureName | titlecase}}</label>                                        
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-check row">
                            <label class="label col-sm-3" formArrayName="FeatureList" style="font-weight: normal;"
                                *ngFor="let feature of AddRoleForm.get('FeatureList')['controls']; let g=index">
                                <input type="checkbox" [formControlName]="g">
                                <!-- (change)="onPermissionCheckBoxClick($event)" -->
                        <!-- {{ FeatureListData[g].featureName | titlecase}} -->
                        <!-- </label> -->
                        <!-- </div> -->
                        <hr>
                    </div>
                </form>
            </div>
            <!-- <div class="row"> -->
            <div class="row mb-4" style="margin-left: 15px; float: left;">
                <button type="button" class="btn btn-primary RMSSubmitButton"
                    style="margin-bottom:10px; margin-right: 10px;" (click)="SaveNewRoleClick()">{{modalTitle}}</button>
                <button type="button" class="btn btn-primary RMSButtonCancel" style="margin-bottom:10px;"
                    (click)="close()">Cancel</button>
            </div>
            <!-- </div>
            <hr> -->
        </div>
    </div>
</div>