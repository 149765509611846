import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetClientDetailsServiceService {

  private ClientId = new BehaviorSubject(0);
  private IsEditContract = new BehaviorSubject(0);
  private ClientName = new BehaviorSubject("");
  ClientId$ = this.ClientId.asObservable();
  IsEditContract$ = this.IsEditContract.asObservable();
  ClientName$ = this.ClientName.asObservable();
  constructor(private httpClient: HttpClient) { }
  GetClientDetails(FormValue: any) {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETCLIENTDETAILS, FormValue).pipe(map(response => {
      let result = response;

      return result;

    },
      error => {

        return error;
      }
    ))
  }
  AddEditClient(formData: any) {



    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.SAVECLIENTINFO, formData).pipe(map(
      response => {
        return response;
      },
      err => {
        let error = err.json();
        return error;
      }
    ))
  }
  PostRequest(Path: string, FormVal: any) {
    return this.httpClient.post(Path, FormVal).pipe(map(response => {
      let result = response;
      return result;
    },
      error => {
        let err = error.json();
        return err;
      }))
  }
  GetData() {

    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETBUSSINESSDATA, '').pipe(map(response => {
      let result = response;

      return result;

    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
  GetEditData(clientId: number) {

    let formData = new FormData();
    formData.append('clientId', String(clientId))
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.EDITCLIENTDETAILS, formData).pipe(map(response => {
      let result = response;

      return result;


    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
  GetContractDetails(clientId: number) {
    let formData = new FormData();
    formData.append('clientId', String(clientId))
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETCONTRACTDETAILS, formData).pipe(map(response => {
      let result = response;

      return result;

    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }

  addEditContract(formData: any) {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.SAVECONTRACTDETAILS,
      formData).pipe(map(
        response => {
          return response;
        },
        err => {
          let error = err.json();
          return error;
        }
      ))
  }

  deleteClientDetails(ClientID: number, IsActive: boolean) {

    let formData = new FormData();
    formData.append('ClientID', String(ClientID));
    formData.append('IsActive', String(!IsActive))
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.DELETECLIENTDETAILS, formData).pipe(map(response => {
      let result = response;

      return result;

    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
  saveAsContractDetail(formData: any) {



    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.SAVEASCONTRACT, formData).pipe(map(
      response => {
        return response;
      },
      err => {
        let error = err.json();
        return error;
      }
    ))
  }
  ExportData(totalCount: string, selectStatus: string) {
    let formData: FormData = new FormData();
    formData.append('FilterData', '');
    formData.append('StartRow', '0');
    formData.append('RowLength', totalCount);
    formData.append('OrderFilter', '');
    formData.append('ExecType', '1');
    formData.append('SelectedStatus', selectStatus);
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETCLIENTDETAILS, formData).pipe(map(response => {

      let list: any = response['table'];
      for (let i = 0; i < list.length; i++) {
        delete list[i]['clientID'];
      }
      for (let i = 0; i < list.length; i++) {
        Object.keys(list[i]).forEach(key => {
          list[i][key.toUpperCase()] = list[i][key];
          delete list[i][key];
        })
      }

      return list;

    }))
  }
  SetClientId(clientId: number, clientName: string) {
    this.ClientId.next(clientId);
    this.ClientName.next(clientName);
  }
  SetIsEditContract(isAllowed: number) {
    this.IsEditContract.next(isAllowed);
  }
  RequestNewUser(url: string, postData: FormData) {
    return this.httpClient.post(url, postData).pipe(map(res => {
      return res;
    }, err => {
      return err.json();
    }))
  }
}
