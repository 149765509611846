import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { ProcessTrackingService } from 'src/services/Process-TrackingServices/process-tracking.service';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertService } from '../../../services/Common/alert.service';
import { PreparePillsModel } from '../../../model/AdvancedFilter/FilterPills';
import { PopupService } from 'src/services/Common/popUp.service';
import { DatePipe } from '@angular/common';
import { DataShareService } from 'src/services/Common/dataShare.service';

@Component({
  selector: 'app-advanced-filter-new',
  templateUrl: './advanced-filter-new.component.html',
  styleUrls: ['./advanced-filter-new.component.css']
})
export class AdvancedFilterNewComponent implements OnInit {
  @Output() public found = new EventEmitter<any>();
  errorHandler = new ErrorHandler(this.alertService);
  //selectedconditionValues: any;
  columnData: any;
  normalColumnData: any;
  //showConditions = true;
  selectedColumn: string = '';
  selectedCondition: string = 'select';
  selectedValue: string = '';
  conditionValues: [string, string][];
  stringCondition: [string, string][];
  numberCondition: [string, string][];
  dateCondition: [string, string][];
  //bitCondition: [string, string][];
  //valueType: number = 1;
  columnType: string = "TextBox";
  displayCondition: boolean = false;
  //filter: string = '';
  filterSQL: string = '';
  operatorType = '';
  filterName: string = '';
  filterToShow: string = '';
  // saved filter variables
  savedFilter: any;
  currentFilterSettingId: number = 0;
  // Column value, Condition Value, Input value, Type, id
  selectedArray: [columnName: string, operator: string, value: string, columnType: string, pillId: number][] = [];
  deleteFilterPill: [string, string, string, number] = null
  //showMultiCondition = false;
  multiSelectData: any;
  multiSelectColumnData = [];
  // for custom default value select filter
  selectedMulti = [];
  defaultMultiVals = [];
  pillIdToEdit: number = 0;
  glDistinctMultiCols = [];
  displaySavedFilter = false;
  selectedDateTo = '';
  selectedDateFrom = '';
  defaultbuttonTitle = "Add Filter";
  buttonTitle = this.defaultbuttonTitle;

  constructor(private processTrackingService: ProcessTrackingService, private alertService: AlertService, private popService: PopupService, private datePipe: DatePipe,
    private ShareData: DataShareService) {
    this.stringCondition = [['Contains', 'contains'], ['Equals to', '='], ['Not equals to', '!='], ['Starts With', 'start'], ['Ends With', 'end']];
    this.numberCondition = [['Equals to', '='], ['Not equals to', '!='], ['Greater than', '>'], ['Less than', '<']];
    this.dateCondition = [['One week', '1w'], ['One month', '1m'], ['One year', '1y'], ['Equal to', '='], ['Not equal to', '!=']];
    //this.bitCondition = [['Equals to', '='], ['Not equal to', '!='], ['is null', 'null']];
  }

  ngOnInit() {
    this.getColumnsToFilter();
    this.getSavedFilter('1', '0');
    this.getMultiFilterValues();
    this.ShareData.advanceFilterPill$.subscribe(filter => this.selectedArray = filter);
  }

  public getColumnsToFilter() {
    let formData: FormData = new FormData();
    formData.append('moduleName', 'process-tracking');
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETADVANCEDFILTERCOLUMNS, formData).subscribe(
      result => {
        if (result == null || result == 'undefined')
          this.normalColumnData = null;
        else {
          this.normalColumnData = result;
          this.columnData = this.normalColumnData;
        }
      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }

  public getMultiFilterValues() {
    let formData: FormData = new FormData();
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETMULTISELECTCOLUMNS, null).subscribe(
      result => {
        this.multiSelectData = result;
        /// get unique values fom multi select data
        let distinctColumn =
          this.multiSelectData.map(item => item['col'])
            .filter((value, index, self) => self.indexOf(value) === index)
        for (let i = 0; i < distinctColumn.length; i++) {
          let multiColArr = {};
          multiColArr['columnName'] = distinctColumn[i];
          multiColArr['columnType'] = 'custom';
          multiColArr['id'] = i + 1;
          multiColArr['isActive'] = true;
          this.multiSelectColumnData.push(multiColArr);
        }
        this.glDistinctMultiCols = distinctColumn;
      }
      , error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }

  public getSavedFilter(execType: string, filterSettingId: string) {
    let formData: FormData = new FormData();
    formData.append('execType', execType);
    formData.append('filterSettingId', filterSettingId);
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETSAVEDFILTERVALUES, formData).subscribe(
      result => {
        if (result == null || result == 'undefined')
          this.savedFilter = null;
        else {
          this.savedFilter = result;
        }
      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }

  public getPillsValueByFilter(filterSettingId: string) {
    let formData: FormData = new FormData();
    formData.append('execType', '2');
    formData.append('filterSettingId', filterSettingId);
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETSAVEDFILTERVALUES, formData).subscribe(
      (result: any[]) => {
        this.selectedArray = [];
        if (result != null && result.length > 0) {
          for (let i = 0; i < result.length; i++) {
            this.selectedArray.push([result[i]["condition"], result[i]["operators"], result[i]["value"], result[i]["columnType"], i + 1]);
          }
          this.ShareData.AdvanceFilterPill(this.selectedArray);
        }
      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }

  private fillComboboxValues(columnName: string) {
    this.selectedMulti = [];
    let distinctValues = this.multiSelectData.filter(x => x.col.trim().toLowerCase() === columnName.trim().toLowerCase())
      .map(item => item['val']);
    this.defaultMultiVals = [];
    distinctValues.forEach(element => {
      let val = {};
      val['id'] = 0;
      val['name'] = element;
      this.defaultMultiVals.push(val);
    });
  }

  public setConditionToSelect(item: any) {
    let argType = typeof item;
    let obj: any;
    if (argType === "string")
      obj = JSON.parse(item);
    else {
      obj = obj;
      if (obj === "" || obj == null) {
        return;
      }
    }
    this.selectedColumn = obj.columnName;
    this.columnType = obj.columnType;
    this.setConditionOperatorValues(obj.columnType, obj.columnName);
  }

  private setConditionOperatorValues(columnType: string, columnName: string) {
    if (columnType.toLowerCase() === 'combobox') {
      this.fillComboboxValues(columnName);
    }
    else if (columnType.toLowerCase() === 'textbox') {
      this.conditionValues = this.stringCondition;
    }
    else if (columnType.toLowerCase() === 'numberbox') {
      this.conditionValues = this.numberCondition;
    }
    else if (columnType.toLowerCase() === 'datepicker') {
      this.conditionValues = this.dateCondition;
    }
  }

  public addConditions() {
    let condition: string = '';
    if (this.selectedColumn == undefined || this.selectedColumn.trim() === '') {
      this.alertService.error('Please select column');
      return;
    }
    if (this.columnType.toLowerCase() == 'combobox') {
      if (this.selectedMulti.length == 0) {
        this.alertService.error('Please select value(s)');
        return;
      }
      condition = this.setMutliSelectCondition(this.selectedMulti, this.selectedColumn);
    }
    else if (this.columnType.toLowerCase() == 'textbox' || this.columnType.toLowerCase() == 'numberbox') {
      if (this.selectedCondition == undefined || this.selectedCondition.trim() === '' || this.selectedCondition.trim() == 'select') {
        this.alertService.error('Please select filter');
        return;
      }
      if (this.selectedValue == undefined || this.selectedValue === '') {
        this.alertService.error('Please select value');
        return;
      }
      if (this.columnType.toLowerCase() == 'numberbox')
        condition = this.setNumberCondition(this.selectedColumn, this.selectedCondition, this.selectedValue);
      else
        condition = this.setStringCondition(this.selectedColumn, this.selectedCondition, this.selectedValue);
    }
    else if (this.columnType.toLowerCase() == 'datepicker') {
      if (this.selectedDateFrom == undefined || this.selectedDateFrom === '') {
        this.alertService.error('Please select "From" date');
        return;
      }
      if (this.selectedDateTo == undefined || this.selectedDateTo.trim() === '') {
        this.alertService.error('Please select "To" date');
        return;
      }
      let fromDate: Date = new Date(this.selectedDateFrom);
      let toDate: Date = new Date(this.selectedDateTo);
      if (toDate < fromDate) {
        this.alertService.error('"To" date can not be less than "From" date');
        return;
      }
      condition = this.setDateCondition(this.selectedColumn, fromDate, toDate);
      this.selectedValue = this.getDateValue(fromDate, toDate, true);
      this.selectedCondition = 'Between';
    } else {
      this.alertService.error('Not a valid ColumnType');
      return;
    }
    this.preparePillArrary(condition, this.selectedColumn, this.columnType);
    this.filterSQL = this.prepareFilerSQL();
    this.resetAdvancedFilter();
    this.buttonTitle = this.defaultbuttonTitle;
  }

  private setMutliSelectCondition(selectedMulti: any[], selectedColumn: string): string {
    let multiSel: string = "";
    for (let i = 0; i < selectedMulti.length; i++) {
      if (i == 0)
        multiSel = '\'' + selectedMulti[i] + '\'';
      else
        multiSel += ', \'' + selectedMulti[i] + '\'';
    }
    let condition: string = '';
    condition = 'Lower([' + selectedColumn + ']) in (' + multiSel.trim() + ')';
    /// set varibale for further wokring
    this.selectedCondition = 'in';
    this.selectedValue = multiSel.trim();
    return condition;
  }

  private setComoboboxCondition(columnName: string, oprator: string, value: string): string {
    let condition: string = '';
    condition = 'Lower([' + columnName + ']) in (' + value.trim() + ')';
    return condition;
  }

  private setDateConditionByValue(columnName: string, oprator: string, value: string) {
    let condition = '';
    condition = 'Convert(date, [' + columnName + '])' + ' between ' + value;
    return condition;
  }

  private setDateCondition(columnName: string, fromDate: Date, toDate: Date) {
    let condition = '';
    condition = 'Convert(date, [' + columnName + '])' + ' between ' + this.getDateValue(fromDate, toDate);
    return condition;
  }

  private setStringCondition(columnName: string, oprator: string, value: string): string {
    let condition = '';
    if (oprator.toLowerCase() === 'contains') {
      condition = 'Lower([' + columnName + '])' + ' like  \'%' + value.toLowerCase() + '%\'';
    }
    else if (oprator.toLowerCase() === '=') {
      condition = 'Lower([' + columnName + '])' + ' =  \'' + value.toLowerCase() + '\'';
    }
    else if (oprator.toLowerCase() === '!=') {
      condition = 'Lower([' + columnName + '])' + ' <>  \'' + value.toLowerCase() + '\'';
    }
    else if (oprator.toLowerCase() === 'end') {
      condition = 'Lower([' + columnName + '])' + ' like  \'%' + value.toLowerCase() + '\'';
    }
    else if (oprator.toLowerCase() === 'start') {
      condition = 'Lower([' + columnName + '])' + ' like  \'' + value.toLowerCase() + '%\'';
    }
    return condition;
  }

  private setNumberCondition(columnName: string, oprator: string, value: string): string {
    let condition = '';
    if (oprator === '=') {
      condition = 'Lower([' + columnName + '])' + ' =  \'' + value + '\'';
    }
    else if (oprator === '!=') {
      condition = 'Lower([' + columnName + '])' + ' <>  \'' + value + '\'';
    }
    else if (oprator === '>') {
      condition = 'Lower([' + columnName + '])' + ' >  \'' + value + '\'';
    }
    else if (oprator === '<') {
      condition = 'Lower([' + columnName + '])' + '   \'' + value + '\'';
    }
    return condition;
  }

  private setDateFormat(dateValue: Date): string {
    return dateValue.getFullYear() + '-' + (dateValue.getMonth() + 1).toString().padStart(2, '0') + '-' + dateValue.getDate().toString().padStart(2, '0');
  }

  private getDateValue(fromDate: Date, toDate: Date, ifUserFormat = false): string {
    let val = '';
    if (!ifUserFormat)
      val = ' \'' + this.setDateFormat(fromDate) + '\''
        + ' and \'' + this.setDateFormat(toDate) + '\' ';
    else
      val = ' \'' + this.datePipe.transform(this.setDateFormat(fromDate), 'dd-MMM-yyyy') + '\''
        + ' and \'' + this.datePipe.transform(this.setDateFormat(toDate), 'dd-MMM-yyyy') + '\' ';
    return val;
  }

  private prepareFilerSQL(): string {
    let filerConditionArrary: [ColumnName: string, ColumnType: string, Condition: string][] = [];
    let columnType: string = '', columnName: string = '', operator: string = '', value: string = '';
    let result: string = '';
    this.selectedArray.forEach(element => {
      columnName = element[0];
      operator = element[1];
      value = element[2];
      columnType = element[3];
      if (columnType.toLocaleLowerCase() == 'textbox') {
        if (filerConditionArrary.length == 0) {
          filerConditionArrary.push([columnName, columnType, this.setStringCondition(columnName, operator, value)]);
        } else {
          let counter = filerConditionArrary.length;
          let editFlag: boolean = false;
          for (let index = 0; index < counter; index++) {
            const element = filerConditionArrary[index];
            if (element[0].toLocaleLowerCase() == columnName.toLocaleLowerCase() && element[1].toLocaleLowerCase() == columnType.toLocaleLowerCase()) {
              element[2] = '(' + element[2] + ' OR ' + this.setStringCondition(columnName, operator, value) + ')'
              editFlag = true;
              break;
            }
          }
          if (!editFlag) {
            filerConditionArrary.push([columnName, columnType, this.setStringCondition(columnName, operator, value)]);
          }
        }
      }
      else if (columnType.toLocaleLowerCase() == 'numberbox') {
        if (filerConditionArrary.length == 0) {
          filerConditionArrary.push([columnName, columnType, this.setNumberCondition(columnName, operator, value)]);
        } else {
          let counter = filerConditionArrary.length;
          let editFlag: boolean = false;
          for (let index = 0; index < counter; index++) {
            const element = filerConditionArrary[index];
            if (element[0].toLocaleLowerCase() == columnName.toLocaleLowerCase() && element[1].toLocaleLowerCase() == columnType.toLocaleLowerCase()) {
              element[2] = '(' + element[2] + ' OR ' + this.setNumberCondition(columnName, operator, value) + ')'
              editFlag = true;
              break;
            }
          }
          if (!editFlag) {
            filerConditionArrary.push([columnName, columnType, this.setNumberCondition(columnName, operator, value)]);
          }
        }
      }
      else if (columnType.toLocaleLowerCase() == 'datepicker') {
        if (filerConditionArrary.length == 0) {
          filerConditionArrary.push([columnName, columnType, this.setDateConditionByValue(columnName, operator, value)]);
        } else {
          let counter = filerConditionArrary.length;
          let editFlag: boolean = false;
          for (let index = 0; index < counter; index++) {
            const element = filerConditionArrary[index];
            if (element[0].toLocaleLowerCase() == columnName.toLocaleLowerCase() && element[1].toLocaleLowerCase() == columnType.toLocaleLowerCase()) {
              element[2] = '(' + element[2] + ' OR ' + this.setDateConditionByValue(columnName, operator, value) + ')'
              editFlag = true;
              break;
            }
          }
          if (!editFlag) {
            filerConditionArrary.push([columnName, columnType, this.setDateConditionByValue(columnName, operator, value)]);
          }
        }
      }
      else if (columnType.toLocaleLowerCase() == 'combobox') {
        if (filerConditionArrary.length == 0) {
          filerConditionArrary.push([columnName, columnType, this.setComoboboxCondition(columnName, operator, value)]);
        } else {
          let counter = filerConditionArrary.length;
          let editFlag: boolean = false;
          for (let index = 0; index < counter; index++) {
            const element = filerConditionArrary[index];
            if (element[0].toLocaleLowerCase() == columnName.toLocaleLowerCase() && element[1].toLocaleLowerCase() == columnType.toLocaleLowerCase()) {
              element[2] = '(' + element[2] + ' OR ' + this.setComoboboxCondition(columnName, operator, value) + ')'
              editFlag = true;
              break;
            }
          }
          if (!editFlag) {
            filerConditionArrary.push([columnName, columnType, this.setComoboboxCondition(columnName, operator, value)]);
          }
        }
      }
    });
    filerConditionArrary.forEach(element => {
      if (result == '')
        result = element[2];
      else
        result = result + ' AND ' + element[2];
    });
    return result;
  }

  private preparePillArrary(value: string, columnName: string, columnType: string) {
    if (value.trim() === '') {
      this.alertService.warn('This condition cannot be added');
      return;
    }
    // if (this.filter.trim().includes(value.trim())) {
    //   this.alertService.warn('This condition already exists');
    //   return;
    // }
    // if (this.operatorType.trim() === '')
    //   this.operatorType = 'and';
    // if (this.filter === '')
    //   this.filter = value
    // else {
    //   if (value.trim() != '') {
    //     columnName = columnName.trim();
    //     let splitArr = this.filter.split('and');
    //     let checkColumnVal: string = 'Lower([' + columnName + '])';
    //     /// check if same column condition already exists
    //     let ifConditionAdded = false;
    //     for (let i = 0; i < splitArr.length; i++) {
    //       if (splitArr[i].trim().toLowerCase().indexOf(checkColumnVal.toLowerCase()) > -1) {
    //         let foundCondition = splitArr[i].trim();
    //         foundCondition = splitArr[i].trim().replace(/^\(+|\)$/g, '');
    //         let validateString = foundCondition.substring(
    //           foundCondition.lastIndexOf("]) ") + 2,
    //           foundCondition.length
    //         );
    //         if (validateString.indexOf(' in ') > -1 && validateString.indexOf('(') > -1 && validateString.indexOf(')') == -1)
    //           foundCondition += ')';
    //         // check if previous condition is to added in found condition
    //         if (this.pillIdToEdit) {
    //           let selPill: [string, string, string, string, number];
    //           for (let i = 0; i < this.selectedArray.length; i++) {
    //             if (this.selectedArray[i][4] === this.pillIdToEdit) {
    //               selPill = this.selectedArray[i];
    //               break;
    //             }
    //           }
    //           let prevCondition = this.remakeConditionTodelete(selPill);
    //           foundCondition = foundCondition.replace(prevCondition, value);
    //           if (foundCondition.indexOf(' or ') == -1) {
    //             splitArr[i] = foundCondition;
    //           }
    //           else
    //             splitArr[i] = "(" + foundCondition + ' ) '
    //         }
    //         else
    //           splitArr[i] = "(" + foundCondition + ' or ' + value + ' ) ';
    //         ifConditionAdded = true;
    //         break;
    //       }
    //     }
    //     if (!ifConditionAdded) {
    //       splitArr.push(value);
    //     }
    //     this.filter = splitArr.join(' and ');
    //   }
    // }
    // if (this.filter.trimRight().endsWith('and')) {
    //   this.filter = this.filter.trimRight().substring(0, this.filter.length - 3);
    // }
    // if (this.filter.trimRight().endsWith('or')) {
    //   this.filter = this.filter.trimRight().substring(0, this.filter.length - 2);
    // }
    if (this.pillIdToEdit > 0 && this.selectedArray.length > 0) {
      for (let i = 0; i < this.selectedArray.length; i++) {
        if (this.selectedArray[i][4] === this.pillIdToEdit) {
          this.selectedArray[i][0] = columnName;
          this.selectedArray[i][1] = this.selectedCondition;
          this.selectedArray[i][2] = this.selectedValue;
          this.selectedArray[i][3] = columnType;
        }
      }
      this.pillIdToEdit = 0;
    }
    else {
      this.selectedArray.push([columnName, this.selectedCondition, this.selectedValue, columnType, (this.selectedArray.length + 1)]);
      this.ShareData.AdvanceFilterPill(this.selectedArray);
    }
  }

  // private remakeConditionTodelete(pill: [string, string, string, string, number]): string {
  //   let columnType: string = pill[3];
  //   let condition: string = "";
  //   if (columnType === 'textbox') {
  //     condition = this.setStringCondition(pill[0], pill[1], pill[2]);
  //   }
  //   else if (columnType === 'numberbox') {
  //     condition = this.setNumberCondition(pill[0], pill[1], pill[2]);
  //   }
  //   else if (columnType === 'combobox') {
  //     let arrMultiSelect = pill[2].split(',');
  //     let tmpArrSelectedValue = [];
  //     for (let i = 0; i < arrMultiSelect.length; i++) {
  //       tmpArrSelectedValue.push(arrMultiSelect[i].trim().replace(/^\'+|\'$/g, ''));
  //     }
  //   }
  //   return condition;
  // }

  deleteConditon(pill: [string, string, string, string, number]) {
    this.buttonTitle = this.defaultbuttonTitle;
    this.selectedArray = this.selectedArray.filter(function (ele) {
      return ele != pill;
    });
this.ShareData.AdvanceFilterPill(this.selectedArray);
    //this.filter = this.selectedArray;

    // let newSelArray: [string, string, string, string, number][] = [];
    // for (let i = 0; i < this.selectedArray.length; i++) {
    //   if (this.selectedArray[i][0].trim().toLowerCase() === pill[0].trim().toLowerCase() && this.selectedArray[i][1].trim().toLowerCase() === pill[1].trim().toLowerCase()
    //     && this.selectedArray[i][2].trim().toLowerCase() === pill[2].trim().toLowerCase() && this.selectedArray[i][3].toLowerCase() == pill[3].toLowerCase()) {
    //     continue;
    //   }
    //   else
    //     newSelArray.push(this.selectedArray[i]);
    // }
    // this.selectedArray = [];
    // this.selectedArray = newSelArray

    // let condition = this.remakeConditionTodelete(pill);
    // if (condition.trim() === '') {
    //   this.filter = '';
    //   this.filterToShow = '';
    // }
    // else {
    //   let splitArr = this.filter.split('and');
    //   let newFilter: string[] = [];
    //   for (let i = 0; i < splitArr.length; i++) {
    //     if (splitArr[i].trim().indexOf('or') > -1) {
    //       let splitArrForOr = splitArr[i].trim().replace(/^\(+|\)$/g, '').trim().split('or');
    //       let addArrForOr = [];
    //       for (let j = 0; j < splitArrForOr.length; j++) {
    //         if (splitArrForOr[j].trim() != condition.trim()) {
    //           addArrForOr.push(splitArrForOr[j]);
    //         }
    //       }  /// or loop finish
    //       let newOrString = '';
    //       if (addArrForOr != undefined) {
    //         newOrString = addArrForOr.join(' or ');
    //         if (addArrForOr.length > 1) {
    //           newOrString = '(' + newOrString + ')';
    //         }
    //       }
    //       newFilter.push(newOrString);
    //     }
    //     else if (splitArr[i].trim() != condition)
    //       newFilter.push(splitArr[i].trim());
    //   }
    //   this.filter = newFilter.join(' and ');
    // }
    // if (this.selectedArray.length == 0) {
    //   this.filterName = '';
    // }
  }

  makeFirstCharCapital(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  setElement(id: string, name: string, obj: any = null) {
    setTimeout(function () {
      let eleColumn = document.getElementById(id) as HTMLSelectElement;
      if (eleColumn == null || eleColumn == undefined) {
        return;
      }
      if (id === 'columnSelect') {
        for (let i = 0; i < obj.length; i++) {
          if (obj[i].columnName.toLocaleLowerCase() === name.toLocaleLowerCase()) {
            eleColumn.selectedIndex = i + 1
            break;
          }
        }
      }
      else if (id === 'filterSelect') {
        for (let i = 0; i < obj.length; i++) {
          if (obj[i][1].toLocaleLowerCase() === name.toLocaleLowerCase()) {
            eleColumn.selectedIndex = i + 1
            break;
          }
        }
      }
      else
        eleColumn.value = name;
    }, 500);
  }

  private parseDate(s) {
    var months = {
      jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
      jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11
    };
    var p = s.split('-');
    return new Date(p[2], months[p[1].toLowerCase()], p[0]);
  }

  editConditon(pill: [string, string, string, string, number]) {
    //pill - "Account Name", "contains", "Test", "TextBox", 1
    this.buttonTitle = "Update Filter";
    this.pillIdToEdit = pill[4];
    this.selectedCondition = pill[1];
    this.selectedColumn = pill[0];
    this.columnType = pill[3];
    this.setElement("columnSelect", pill[0], this.columnData);
    if (this.columnType.toLocaleLowerCase() == 'textbox') {
      this.setConditionOperatorValues(this.columnType, this.selectedColumn);
      //this.selectedconditionValues = this.selectedCondition;
      //this.setElement("filterSelect", this.selectedCondition, this.conditionValues);
      this.selectedValue = pill[2];
    }
    else if (this.columnType.toLocaleLowerCase() == 'numberbox') {
      this.setConditionOperatorValues(this.columnType, this.selectedColumn);
      //this.selectedconditionValues = this.selectedCondition;
      //this.setElement("filterSelect", this.selectedCondition, this.conditionValues);
      this.selectedValue = pill[2];
    }
    else if (this.columnType.toLocaleLowerCase() == 'combobox') {
      this.setConditionOperatorValues(this.columnType, this.selectedColumn);
      //this.fillComboboxValues(pill[0]);
      pill[2].split(',').forEach(element => {
        this.selectedMulti.push(element.trim().replace(/^\'+|\'$/g, ''));
      });
    }
    else if (this.columnType.toLocaleLowerCase() == 'datepicker') {
      let val = pill[2].split('and');
      let toDate = this.parseDate(val[0].replace(/'/g, '').trim());
      let fromDate = this.parseDate(val[1].replace(/'/g, '').trim());
      this.selectedDateFrom = this.setDateFormat(toDate);
      this.selectedDateTo = this.setDateFormat(fromDate);
    }
  }

  prepareFilterToSave() {
    let prepareFilter: PreparePillsModel[] = [];
    for (let i = 0; i < this.selectedArray.length; i++) {
      const test = {}
      let filter: PreparePillsModel = new PreparePillsModel();
      filter.prevId = 0;
      filter.userid = 0;
      filter.condition = this.selectedArray[i][0];
      filter.operators = this.selectedArray[i][1];
      filter.value = this.selectedArray[i][2];
      filter.filterSettingId = 0;
      prepareFilter.push(filter);
    }
    const data = {
      'filter': this.filterSQL, 'order': '', 'prevFilterValue': this.currentFilterSettingId
      , 'filterName': this.filterName, 'filterToShow': this.filterToShow, 'advancedFilterPills': prepareFilter
    }
    return data;
  }

  saveFilter() {
    this.filterSQL = this.prepareFilerSQL();
    if (this.filterSQL.trim() === '') {
      this.alertService.error('Empty filter cannot be saved.');
      return;
    }
    if (this.filterName.trim() === '') {
      this.alertService.error('Filter name cannot be empty.');
      return;
    }
    if (this.savedFilter.length > 0) {
      if (this.savedFilter.length >= 10) {
        this.alertService.error('Max 10 filters can be saved.');
        return;
      }
      if (this.currentFilterSettingId == 0) {
        let ifReturn = false;
        for (let i = 0; i < this.savedFilter.length; i++) {
          if (this.savedFilter[i].filterName != null && this.savedFilter[i].filterName.trim().toLowerCase() === this.filterName.trim().toLowerCase()) {
            ifReturn = true;
            break;
          }
        }
        if (ifReturn) {
          this.alertService.error('Filter is already saved with same name.');
          return;
        }
      }
    }

    this.processTrackingService.SendDataASBody(AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEADVANCEDFILTER, this.prepareFilterToSave()).subscribe(
      result => {
        this.resetFilter();
        this.getSavedFilter('1', '0');
        this.alertService.success('Filter has been saved successfully.');
        let closeModal = document.getElementById('btnCloseSaveFilterModal') as HTMLButtonElement;
        closeModal.click();
      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
        let closeModal = document.getElementById('btnCloseSaveFilterModal') as HTMLButtonElement;
        closeModal.click();
      });
  }

  public viewData(obj: any) {
    this.filterSQL = obj.filter;
    if (this.filterSQL.trim() === '') {
      this.alertService.error('Please create filter condition before search.');
      return;
    }
    this.found.emit(this.filterSQL);
  }

  fetchData() {
    this.filterSQL = this.prepareFilerSQL();
    if (this.filterSQL.trim() === '') {
      this.addConditions();
      this.filterSQL = this.prepareFilerSQL();
      if (this.filterSQL.trim() === '') {
        return;
      }
    }
    if (this.filterSQL.trim() === '') {
      this.alertService.error('Please create filter condition before search');
      return;
    }
    this.found.emit(this.filterSQL);
  }

  public editFilter(obj: any) {
    this.buttonTitle = this.defaultbuttonTitle;
    this.filterSQL = obj.filter;
    this.filterName = obj.filterName;
    this.currentFilterSettingId = obj.filterID;
    this.getPillsValueByFilter(obj.filterID);
  }

  public resetFilter() {
    this.ShareData.AdvanceFilterPill([]);
    this.ShareData.shareProcessTrackingAdvanceFilter('');
    this.buttonTitle = this.defaultbuttonTitle;
    this.resetAdvancedFilter();
    this.selectedArray = [];
    this.filterToShow = '';
    this.filterName = '';
    this.filterSQL = '';
    this.currentFilterSettingId = 0;
    this.found.emit('');
  }

  private resetAdvancedFilter() {
    this.setColumnDDToDefault("columnSelect");
    this.pillIdToEdit = 0;
    this.selectedColumn = "";
    this.selectedCondition = "select";
    this.selectedValue = "";
    this.conditionValues = [];
    this.selectedMulti = [];
    this.columnType = 'TextBox';
    this.selectedDateTo = '';
    this.selectedDateFrom = '';
  }

  deleteFilter(item: any) {
    this.popService.Confirmation('Are you sure?', 'You want to delete.', 'Yes, Delete', 'No, Cancel').then(
      result => {
        if (result.value) {
          this.processTrackingService.deleteFilter(item).subscribe(response => {
            this.getSavedFilter('1', '0');
            this.resetAdvancedFilter();
            this.savedFilter = '';
          },
            error => {
              this.errorHandler.ErrorsHandler(error);
            });
        }
      }
    )
  }

  setColumnDDToDefault(id: string) {
    let eleColumn = document.getElementById(id) as HTMLSelectElement;
    if (eleColumn != null || eleColumn != undefined) {
      eleColumn.selectedIndex = 0;
    }
  }
}
