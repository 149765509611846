import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';


@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private httpclient: HttpClient) { }

  ForgotPassword(userid: string) {
    const formdata = new FormData();
    formdata.append("emailId", userid);
    return this.httpclient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.FORGOT_PASSWORD, formdata).pipe(
      map(
        res => {
          // console.log(res);
          return res;
        },
        err => {
          let er = err.json();
          return er
        }
      )
    )
  }
  linkexpire(Guid: string) {
    const formdata2 = new FormData();
    formdata2.append("ResetPasswordToken", Guid);

    return this.httpclient
      .post(AppSettings.API_ENDPOINT + AppRelativeUrl.LINK_EXPIRE, formdata2).pipe(
        map(
          res => {
            // console.log(res);
            return res;
          },
          err => {
            let er = err.json();
            return er;
          }
        ));
  }
  UpdatePassword(usermailid: string, newpassword: string, passguid: string) {
    if (usermailid != null && newpassword != null && passguid != null) {
      const formdataupdatePassword: any = new FormData();
      formdataupdatePassword.append('EmailId', usermailid);
      formdataupdatePassword.append('NewPassword', newpassword);
      formdataupdatePassword.append('ResetPasswordToken', passguid);
      return this.httpclient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.RESET_PASSWORD, formdataupdatePassword).pipe(map(
        res => {
          return res;
        },
        err => {
          let error = err.json();
          return err;
        }
      ))
    }
  }
  contactSupport(formData: any) {
    console.log(formData)
    return this.httpclient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.CONTACTSUPPORT, formData).pipe(map(
      response => {
        return response;
      },
      err => {
        let error = err;
        return error;
      }
    ))

  }  
  contactSupportAfterLogin(formData: any) {
    console.log(formData)
    return this.httpclient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.CONTACTSUPPORTAFTERLOGIN, formData).pipe(map(
      response => {
        return response;
      },
      err => {
        let error = err;
        return error;
      }
    ))

  }
  
  CheckGeneratePasswordUrl(Guid: string) {
    const formdata2 = new FormData();
    formdata2.append("generatePasswordToken", Guid);
    return this.httpclient
      .post(AppSettings.API_ENDPOINT + AppRelativeUrl.VALIDATE_GENERATEPASSWORDURL, formdata2).pipe(
        map(
          res => {
            return res;
          },
          err => {
            let er = err.json();
            return er;
          }
        ));
  }
  GeneratePassword(usermailid: string, newpassword: string, passguid: string) {
    if (usermailid != null && newpassword != null && passguid != null) {
      const formdataupdatePassword: any = new FormData();
      formdataupdatePassword.append('EmailId', usermailid);
      formdataupdatePassword.append('NewPassword', newpassword);
      formdataupdatePassword.append('ResetPasswordToken', passguid);
      return this.httpclient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GENERATEPASSWORD, formdataupdatePassword).pipe(map(
        res => {
          return res;
        },
        err => {
          let error = err.json();
          return err;
        }
      ))
    }
  }
}
