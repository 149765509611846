import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { map, repeat } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MessageCenterserviceService {

  private dataFromDashboard = new BehaviorSubject('');
  openViewMessage$ = this.dataFromDashboard.asObservable();
  private raiseQueryFromDashboard = new BehaviorSubject('');
  raiseQueryFromDashboard$ = this.raiseQueryFromDashboard.asObservable();
  constructor(private httpClient: HttpClient, private datePipe: DatePipe) { }
  Getmessage(postData: FormData) {
    // let formData = new FormData();
    // formData.append('UserId', String(UserId))
    // formData.append('AccountId',AccountId);
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETMESSAGEDETAIL, postData).pipe(map(response => {
      let result = response;
      return result;
    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }

  markMessage(messageID: number, isstarmarked: boolean) {

    let formData = new FormData();
    formData.append('messageID', String(messageID));
    formData.append('isstarmarked', String(!isstarmarked))
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.MARKMESSAGE, formData).pipe(map(response => {
      let result = response;

      return result;

    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
  GetQueryDetail(Url: string, postData: FormData) {
    return this.httpClient.post(Url, postData).pipe(map(
      response => {
        return response;
      },
      error => {
        return error.json();
      }
    ))
  }
  ViewQuery(url: string, postData: FormData) {
    return this.httpClient.post(url, postData).pipe(map(
      response => {
        return response;
      },
      error => {
        return error.json();
      }
    ))
  }
  RaiseQuery(url: string, postData: FormData) {
    return this.httpClient.post(url, postData).pipe(map(
      response => {
        return response
      },
      err => {
        return err.json();
      }
    ))
  }
  ForwardQuery(url: string, postData: FormData) {
    return this.httpClient.post(url, postData).pipe(map(
      response => {
        return response
      },
      err => {
        return err.json();
      }
    ))
  }

  ExportData(UserId: string, searchnput: any,StartRow:string,RowLength:string,AccountId:string,OrderFilter:string) {
    let formData: FormData = new FormData();
    formData.append('StartRow', StartRow);
    formData.append('RowLength', RowLength);
    formData.append('OrderFilter', OrderFilter);
    formData.append('ExecType', '1');
    formData.append('UserId', '1');
    formData.append('AccountId',AccountId );
    formData.append('ItemSearch', String(searchnput));
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETMESSAGEDETAIL, formData).pipe(map(response => {


      let list: any = response['tblThread'];
      let regex = /(<([^>]+)>)/ig
      for (let i = 0; i < list.length; i++) {
        delete list[i]['accountID'];
        delete list[i]['clientID'];
        delete list[i]['initiator'];
        delete list[i]['messageID'];
        delete list[i]['messageTypeID'];
        delete list[i]['threadID'];
        delete list[i]['isstarmarked'];
        delete list[i]['threadStatus'];
        delete list[i]['empty'];
        delete list[i]['empty1'];
        delete list[i]['rowNumber'];
        delete list[i]['sendToUserName'];
        delete list[i]['sentTo'];
        delete list[i]['ccList'];
        delete list[i]['attachmentFileName'];
        delete list[i]['attachmentFilePath'];
        delete list[i]['reply'];
        delete list[i]['isReopen'];
        delete list[i]['fileSize'];
        list[i]['messagetext'] = list[i]['messagetext'].replace(regex, "")
        list[i]['closed On'] = this.datePipe.transform(list[i]['closed On'], 'dd-MMM-yyyy hh:mm aaa');
        list[i]['last Modified On'] = this.datePipe.transform(list[i]['last Modified On'], 'dd-MMM-yyyy hh:mm aaa');
        list[i]['opened On'] = this.datePipe.transform(list[i]['opened On'], 'dd-MMM-yyyy hh:mm aaa');
      }
      for (let i = 0; i < list.length; i++) {
        Object.keys(list[i]).forEach(key => {
          list[i][key.toUpperCase()] = list[i][key];
          delete list[i][key];
        })
      }

      return list;

    }))
  }
  GetUsersList(url: string, postData: FormData) {
    return this.httpClient.post(url, postData).pipe(map(response => {
      return response
    }, error => {
      return error.json();
    }

    ))
  }
  ReplyQuery(url: string, postData: FormData) {
    return this.httpClient.post(url, postData).pipe(map(response => {
      return response;
    },
      error => {
        return error.json();
      }))
  }
  RedirectToMesageCenter(value) {
    this.dataFromDashboard.next(value);
  }
  OpenRaiseQuery(value) {
    this.raiseQueryFromDashboard.next(value)
  }

  Post(url: string, formData: any) {
    return this.httpClient.post(url, formData).pipe
      (map(result => {
        return result;

      },
        errors => {
          console.log(errors);
        }))
  }
}
