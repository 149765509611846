<hr style="margin-top: 0px !important;" />

<section class="content">
  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor">Users Details</h3>
        <div class="card-tools" style="margin-left: 20px;">
          <div class="input-group input-group-sm">
            <button type="button" class="btn btn-default btn-block btn-sm d-none d-sm-block" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" style="width: 100px;">
              Export</button>
            <div class="input-group-append">
              <button type="button" class="btn btn-default btn-block btn-sm" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" style="background-color:#1D6F42">
                <i class="fa fa-file-excel-o RMSSearchButtonIcon"></i></button>
              <!--begin::Dropdown Menu-->
              <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <!-- <span class="dropdown-item dropdown-header"> Choose an option:</span> -->
                <!-- <div class="dropdown-divider"></div> -->
                <button class="dropdown-item btn  btn-block btn-sm" (click)="DownloadExcel()">
                  <i class="fa fa-file-excel-o mr-3" style="color:#1D6F42;"></i> Excel
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item btn btn-block btn-sm" (click)="DownloadCSV()">
                  <i class="fa fa-file-code-o mr-3" style="color:#1D6F42;"></i> CSV
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item btn  btn-block btn-sm" (click)="DownloadPDF()">
                  <i class="fa fa-file-pdf-o mr-3" style="color:rgb(196, 32, 50);"></i>PDF
                </button>
                <!-- <div class="dropdown-divider"></div>
                            <a class="dropdown-item dropdown-footer"></a> -->
              </div>
              <!--End::Dropdown Menu-->
            </div>

          </div>
        </div>
        <div *ngIf='allowaddeditAction' class="card-tools" style="margin-left:20px;">
          <div class="input-group input-group-sm" style="width: 150px;">
            <button type="button" class="btn btn-default btn-block btn-sm" data-toggle="collapse" title="Add New User"
              data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" style="width: 120px;"
              (click)="AddUser()">Add User</button>
            <div class="input-group-append">
              <button type="submit" class="btn btn-default btn-block btn-sm" data-toggle="collapse"
                data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                style="background-color:rgb(196, 32, 50)"><i class="fas fa-plus RMSSearchButtonIcon"></i></button>
            </div>
          </div>
        </div>
        <div class="card-tools">
          <div class="input-group input-group-sm" style="width: 180px;">
            <input type="text" [(ngModel)]="searchInput" (keyup)="checkSearch()" (keydown.enter)="SearchItem()"
              name="table_search" class="form-control float-" placeholder="Search">
            <div class="input-group-append">
              <button type="button" (click)=SearchItem() class="MoodysSearchButton">
                <i class="fas fa-search RMSSearchButtonIcon"></i>
              </button>
              <button class="MoodysSearchButton" style="margin-left: 1px;" type="submit" data-toggle="tooltip"
                data-placement="top" title="Reset" id="basic-text1" (click)="resetRecords()">
                <i class="fas fa-history text-grey" style="color: #ffffff;" aria-hidden="true"></i>
              </button>
            </div>
          </div>

          <div class="col-lg-12 ErrorMessage NoPadding" *ngIf="showSearchValidationError"
            style="padding-top:10px; color: #c42032; font-size: 0.8rem;">
            Special characters are not allowed
          </div>

        </div>

        <div *ngIf='CurrentUserClientId==2 || CurrentUserClientId==1' class="card-tools">
          <div class="input-group input-group-sm">
            <div class=row>
              <h3 class="card-title" style="font-size: small;margin-top: 9px;margin-right: 1px;">Client</h3>
              <ng-select [(ngModel)]="clientName" style="margin-right: 20px;width: 162px;" placeholder="Select Client"
                multiple="true" (change)="GetClientUser($event)">
                <ng-option *ngFor='let item of clientList' [value]="item.clientid">{{item.clientName}}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="card-tools">
          <div class="input-group input-group-sm">
            <h3 class="card-title Statusselector" style="font-size: small;">Status
              <select class="selectstatus" (change)="GetUserStatus($event.target.value)">
                <option value="all">All</option>
                <option selected value="active">Active</option>
                <option value="deactive">Deactive</option>
              </select>
            </h3>
            <div class="input-group-append">
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="addUser">
        <app-addedituser [editUser]="editUser" (cancel)="CancelButton($event)" *ngIf="addUser"></app-addedituser>
      </div> -->
    </div>
  </div>
</section>
<section>
  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default" *ngIf="addUser">
      <div class="card-header" *ngIf="!addEditHeader && allowaddeditAction">
        <h3 class="card-title MoodysEditButton" style="vertical-align: middle;">Add User</h3>
      </div>
      <div class="card-header" *ngIf="addEditHeader && allowaddeditAction">
        <h3 class="card-title MoodysEditButton" style="vertical-align: middle;">Edit User</h3>
      </div>
      <div class="card-header" *ngIf="!allowaddeditAction">
        <h3 class="card-title MoodysEditButton" style=" vertical-align: middle;">View User </h3>
      </div>

      <div>
        <app-addedituser [editUser]="editUser" (cancel)="CancelButton($event)" *ngIf="addUser"></app-addedituser>
      </div>
    </div>
  </div>
</section>
<section *ngIf="notification" class="content">
  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor">User Notification for {{editUser['user Name']}} </h3>
      </div>
      <div *ngIf="notification">
        <app-notification-user [userDetail]="editUser" (cancel)="CancelNotification($event)" *ngIf="notification">
        </app-notification-user>
      </div>
    </div>
  </div>
</section>
<section class="content">
  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default">
      <div class="card-header">
        <div class="card-body table-responsive p-0">

          <div *ngIf="noRecord">No Record found</div>
          <div *ngIf="!noRecord" class="table-responsive text-nowrap">
            <table id='tabledetail' class="table-hover table-bordered font14 noPad">
              <thead class="table-bordered">
                <tr class="th-sm RMSTableHeader">
                  <th class="text-center" style="width: 141px;">Actions</th>
                  <th scope="col " *ngFor="let headerItem of dynFieldheader|slice:13" class="tdalign ">
                    {{headerItem | titlecase}}
                  </th>
                </tr>
              </thead>
              <tbody class="RMSTableContent">
                <tr class="RmsTableRow" *ngFor="let col of userClientModal;let i=index;" id="tr_{{i}}"
                  (click)="selectRow('tr_' + i)">
                  <td class="text-center icon">
                    <button class="btn btn-tool" [disabled]="!col.status" (click)="EditUser(col)" type="button"
                      data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                      aria-controls="collapseExample">
                      <i *ngIf="allowaddeditAction" title="Edit User" class="fa fa-edit MoodysEditButton"
                        ></i>
                      <i *ngIf="!allowaddeditAction" title="View User" class="fa fa-eye" aria-hidden="true"></i>
                    </button>
                    <button *ngIf='allowactivateAction' type="button" (click)="Deactivate(col)"
                      class="btn btn-icon-toggle" data-toggle="tooltip" data-placement="top">
                      <i *ngIf="col['status']" title="Deactivate User" class="fa fa-trash"
                        style="color:rgb(196, 32, 50)"></i>
                      <i *ngIf="!col['status']" title="Activate User" class="fa fa-trash"
                        style="color:rgb(90, 89, 89)"></i>
                    </button>
                    <button [disabled]="!col.status" type="button" class="btn btn-icon-toggle" data-toggle="dropdown"
                      data-placement="top">
                      <i class="fa fa-cog " title="Action" style="color:gray;" data-original-title="other Acionts"
                        aria-hidden="true"></i>
                    </button>
                    <div class="shadow" id=#dropdown>
                      <div class="dropdown-menu ">
                        <ul>
                          <li class="li-header ">
                            Choose an action
                          </li>
                          <li class="Icon-desc">
                            <i class="fa fa-envelope text-primary " title="Notification" (click)="Notification(col)"
                              title="Notification" type="submit">
                              <span class="Icon-desc" style="color: black;"> Notification</span>
                            </i>
                          </li>
                          <li *ngIf="allowaddeditAction">
                            <i class="fa fa-edit text-danger " title="Reset Password" (click)="ResetPassword(col)"
                              type="submit">
                              <span class="Icon-desc" style="color: black;">Reset Password</span>
                            </i>
                          </li>
                          <li *ngIf="!col.isSentCredential && allowaddeditAction">
                            <i class="fa fa-flag MoodysEditButton " title="Send Credentials" (click)="SendMail(col)"
                              type="submit">
                              <span class="Icon-desc" style="color: black;">Send Credentials</span>
                            </i>
                          </li>
                          <li *ngIf="allowaddeditAction">
                            <i class="fa fa-copy MoodysEditButton" (click)="SaveCopyUser(col)" title="Save As"
                              data-toggle="modal" data-target="#myModal" type="submit">
                              <span class="Icon-desc" style="color: black;">Save As</span>
                            </i>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td *ngFor="let headerItem of dynFieldheader|slice:13" class="tdalign">
                    <span
                      *ngIf="headerItem!='status' && headerItem!='is Locked' && headerItem!='is FirstTime' 
                    && headerItem!='alert On Account'
                     && headerItem!='mapped Client' && headerItem!='isSentCredential' && headerItem!='created Date' && headerItem!='account Type Mapping'
                      && headerItem!='business Unit Mapping' && headerItem!='filter by(Account Type)'
                        && headerItem!='filter by(Business Unit)'">
                    {{col[headerItem] }}
                    </span>
                    <span *ngIf="headerItem=='status'">
                      <span *ngIf="col[headerItem]" class="badge badge-info list activeBadge">
                        Active</span>
                      <span *ngIf="!col[headerItem]" class="badge list deactiveBadge">
                        Deactive</span>
                    </span>
                    <span *ngIf="dynFieldheader.length==34"> <span *ngIf="headerItem=='is Locked'">
                        <i *ngIf="col[headerItem]" class="fa fa-check" style="color: rgb(84, 243, 84);">
                        </i>
                        <i *ngIf="!col[headerItem]" class="fa fa-times" style="color:red;">
                        </i>
                      </span>
                      <span *ngIf="headerItem=='is FirstTime'">
                        <i *ngIf="col[headerItem]" class="fa fa-check" style="color: rgb(84, 243, 84);">
                        </i>
                        <i *ngIf="!col[headerItem]" class="fa fa-times" style="color:red;">
                        </i>
                      </span>
                      <span *ngIf="headerItem=='alert On Account'">
                        <i *ngIf="col[headerItem]" class="fa fa-check" style="color: rgb(84, 243, 84);">
                        </i>
                        <i *ngIf="!col[headerItem]" class="fa fa-times" style="color:red;">
                        </i>
                      </span>
                      <span *ngIf="headerItem=='isSentCredential'">
                        <i *ngIf="col[headerItem]" class="fa fa-check" style="color: rgb(84, 243, 84);">
                        </i>
                        <i *ngIf="!col[headerItem]" class="fa fa-times" style="color:red;">
                        </i>
                      </span>

                      <span *ngIf="headerItem=='mapped Client'">
                        <span *ngFor="let ClientName of SeparateComma(col[headerItem]) " style=" margin-left: 2px;"
                          class="badge badge-info list ">
                          {{ClientName}}</span>
                      </span>
                      <span *ngIf="headerItem=='created Date'">
                        {{col[headerItem] | date:"dd-MMM-yyyy"}}
                      </span>
                      <span *ngIf="headerItem=='business Unit Mapping'">
                        <span *ngFor="let BusinessUnit of SeparateComma(col[headerItem]) " style=" margin-left: 2px;"
                          class="badge badge-info list ">
                          {{BusinessUnit}}</span>
                      </span>
                      <span *ngIf="headerItem=='account Type Mapping'">
                        <span *ngFor="let AccountType of SeparateComma(col[headerItem]) " style=" margin-left: 2px;"
                          class="badge badge-info list ">
                          {{AccountType}}</span>
                      </span>
                      <span *ngIf="headerItem=='filter by(Account Type)'">
                        <span *ngFor="let DaccountType of SeparateComma(col[headerItem]) " style=" margin-left: 2px;"
                          class="badge badge-info list ">
                          {{DaccountType}}</span>
                      </span>
                      <span *ngIf="headerItem=='filter by(Business Unit)'">
                        <span *ngFor="let DBusinessUnit of SeparateComma(col[headerItem]) " style=" margin-left: 2px;"
                          class="badge badge-info list ">
                         {{DBusinessUnit}}</span>
                      </span> </span>

                  </td>


                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top:10px;">
            <div class="col-sm-4" style="align-items: center;text-align: center;">
              <label style="font-weight: normal; float: left;font-size: smaller; margin-right: 5px;">Show</label>
              <select title="Records to show" class="form-control" style="width: 3rem; height: 22px;padding: 0px;padding-left: 2px;
                    font-size: smaller;float: left;text-align: center;" (change)="Records($event.target.value)">
                <option value="10">10</option>
                <option value="20" selected=true>20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="all">All</option>
              </select>
              <label style="font-weight: normal; float: left;font-size: smaller; margin-left: 5px;">Records</label>
            </div>
            <label class="col-sm-4" style="font-size: smaller;text-align: center;font-weight: normal;">Showing
              {{pageStart+1}} to {{pageStart+rowCount}} of {{totalCount}} Records</label>
            <div class="col-sm-4">
              <nav aria-label="Page navigation" class="float-right" id="navlist">
                <ul class="pagination float-right">
                  <li class="page-item">
                    <a class="RMSButtonPageNavigation page-link " (click)="PageChange(1)" aria-label="First">
                      <label class="fa fa-angle-double-left"
                        style=" margin-left: -4px; font-size: small !important; font-weight: bold !important;"></label>
                    </a>
                  </li>
                  <li class="page-item">
                    <a (click)="PageChange(currentPage-1)" class="RMSButtonPageNavigation page-link"
                      aria-label="Previous">
                      <label class="fa fa-angle-left"
                        style="font-size:small !important;font-weight:bold !important;"></label>
                    </a>
                  </li>
                  <li class="page-item" *ngIf=previousDotShow>
                    <label class="RMSButtonPageNavigation" style="font-weight:bold !important;padding-top: 9px;">...</label>
                  </li>
                  <li *ngFor="let i of item">
                    <a (click)="PageChange(i)" [ngClass]="{'selectedPage':i===selectedPageNo}"
                      class="RMSButtonPageNavigation page-link">
                      <label style="vertical-align: sub !important;font-size: small;margin-left: -4px; ">{{ i
                        }}</label>
                    </a>
                  </li>
                  <li class="page-item" *ngIf=nextDotShow>

                    <label class="RMSButtonPageNavigation" style="font-weight:bold !important;padding-top: 9px;">...</label>

                  </li>
                  <li class="page-item">
                    <a (click)="PageChange(currentPage+1)" class="RMSButtonPageNavigation page-link "
                      aria-label="Next">
                      <label class="fa fa-angle-right"
                        style="font-size:small !important;font-weight:bold !important;"></label>
                    </a>
                  </li>
                  <li class="page-item">
                    <a (click)="PageChange(pageCount)" class="RMSButtonPageNavigation page-link"
                      aria-label="Last">
                      <label class="fa fa-angle-double-right"
                        style="margin-left: -4px;font-size:small !important;font-weight:bold !important;"></label>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<!-- <nav aria-label="Page navigation" class="float-right">
    <ul class="pagination float-right">
      <li class="page-item">
        <a class="page-link text-info" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li *ngFor="let i of item">
        <input
          type="button"
          value=" {{ i }} "
          (click)="PageChange(i)"
          class="page-link text-info"
        />
      </li>
      <li class="page-item">
        <a class="page-link text-info" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </nav>
  <select
    class="form-control"
    style="width: 231px;"
    (change)="Records($event.target.value)"
  >
    <option value="5"> 5 </option>
    <option value="10">10</option>
    <option selected value="20">20</option>
    <option value="40">40</option>
    <option value="80">80</option>
    <option value="100">100</option>
    <option value="all">All</option>
  </select> -->

<!-- <div class="modal fade" id="myModal" role="dialog" style="top: 20% ;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Modal Header</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
         
        </div>
        
          <div class='col-lg-12'>
            <div class="row">
              <div class="col-lg-6">
                <div class='row'>  <div class='col-lg-6'><label>UserName</label></div>
                <div class='col-lg-6'><input class='form-control' type="text"></div></div> 
              </div>
               <div class='col-lg-6 '>
                <div class='row'>
                  <div class='col-lg-6'><label>UserName</label></div>
                  <div class='col-lg-6'><input class='form-control' type="text"></div>
                </div>           
            </div> 
            </div> 
            <div class="row">
              <div class="col-lg-6">
                <div class='row'>  <div class='col-lg-6'><label>Extention</label></div>
                <div class='col-lg-6'><input class='form-control' type="text"></div></div> 
              </div>
               <div class='col-lg-6 '>
                <div class='row'>
                  <div class='col-lg-6'><label>Phone</label></div>
                  <div class='col-lg-6'><input class='form-control' type="text"></div>
                </div>
            
            </div>
            
            </div>
          </div>
       
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div> -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 700px;">
    <div class="modal-content">
      <div class="modal-header">

        <h6 class="modal-title">Replicate User Setting </h6>
        <button type="button" (click)="Close()" #closebutton class="close" data-dismiss="modal">&times;</button>
      </div>
      <form [formGroup]='SaveAsForm' (ngSubmit)="SaveAsUser()">
        <div class="modal-body ">
          <div class='col-lg-12'>
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-5">
                    <div class="row">
                      <label style="font-size: small;" class="offset-2">User Name :</label>
                      <P style="color: red; margin-top: -3px; margin-bottom: 1rem;">*</P>
                    </div>
                  </div>
                  <div class="col-lg-7">
                    <input formControlName="UserName" placeholder="Enter User Name" type="text"
                      (ngModelChange)="ShowUserNameError();" class="form-control form-control-sm" />
                    <div class="ErrorMessage NoPadding" style="color: #c42032; font-size: small;">
                      <!-- <div
                        *ngIf="
                          GetFormControls.UserName.touched &&
                          GetFormControls.UserName.invalid
                        "
                      >
                        <div *ngIf="GetFormControls.UserName.errors.required">
                          User Name is required.
                        </div>
                        <div *ngIf="GetFormControls.UserName.errors.maxlength">
                          too long
                        </div>
                        <div *ngIf="GetFormControls.UserName.errors.minlength">
                          too short
                        </div>
                      </div> -->
                      <div *ngIf="GetFormControls.UserName.errors && showUserNameError">
                        <div *ngIf="GetFormControls.UserName.errors.required">User Name is required.</div>
                        <div *ngIf="GetFormControls.UserName.errors.maxlength">User Name is too long.</div>
                        <div *ngIf="GetFormControls.UserName.errors.minlength">User Name is short .</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="row">
                      <label style="font-size: small;" class="offset-2">Email Address :</label>
                      <P style="color: red; margin-top: -3px; margin-bottom: 1rem;">*</P>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <input (ngModelChange)="ShowEmailError();" formControlName="EmailAdddress"
                      placeholder="Enter Email Address" type="text" class="form-control form-control-sm" />
                    <div class="ErrorMessage NoPadding" style="color: #c42032; font-size: small;">
                      <div *ngIf="GetFormControls.EmailAdddress.errors && showemailError">
                        <div *ngIf="GetFormControls.EmailAdddress.errors.required">Email id is required.</div>
                        <div *ngIf="GetFormControls.EmailAdddress.errors.pattern">Please check the format of the email
                          address entered.</div>
                        <div *ngIf="GetFormControls.EmailAdddress.errors.maxlength">Email id is too long.</div>
                        <div *ngIf="GetFormControls.EmailAdddress.errors.minlength">Email id is to short.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div class="modal-body ">

          <div class='col-lg-12'>
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-5">
                    <label style="font-size: small;" class="offset-2">Phone Number:</label>
                  </div>
                  <div class="col-lg-7">
                    <input formControlName="PhoneNumber" placeholder="Enter Phone Number" type="number"
                      class="form-control form-control-sm" />
                    <!-- <div class="ErrorMessage NoPadding" style="color: #c42032;">
                        <div
                          *ngIf="
                            GetFormControls.usernameText.touched &&
                            GetFormControls.usernameText.invalid
                          "
                        >
                          <div *ngIf="GetFormControls.usernameText.errors.required">
                            User Name is required.
                          </div>
                          <div *ngIf="GetFormControls.usernameText.errors.maxlength">
                            too long
                          </div>
                          <div *ngIf="GetFormControls.usernameText.errors.minlength">
                            too short
                          </div>
                        </div>
                      </div> -->
                  </div>
                </div>

              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6">
                    <label style="font-size: small;" class="offset-2">Ext. Number :</label>
                  </div>
                  <div class="col-lg-6">
                    <input formControlName="ExtNumber" placeholder="Enter Ext Number" type="number"
                      class="form-control form-control-sm" />
                    <!-- <div class="ErrorMessage NoPadding" style="color: #c42032;">
                        <div
                          *ngIf="
                            GetFormControls.usernameText.touched &&
                            GetFormControls.usernameText.invalid
                          "
                        >
                          <div *ngIf="GetFormControls.usernameText.errors.required">
                            User Name is required.
                          </div>
                          <div *ngIf="GetFormControls.usernameText.errors.maxlength">
                            too long
                          </div>
                          <div *ngIf="GetFormControls.usernameText.errors.minlength">
                            too short
                          </div>
                        </div>
                      </div> -->
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <p class='container-fluid' style="font-size:small;">Enter new user's details above which will replicate the
          settings same as user: {{saveAsUserName}}</p>
        <div class="modal-footer">

          <button type="submit" class=" btn btn-secondary RMSSubmitButton">Save As</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </form>
    </div>
  </div>
</div>
