import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UserSignService } from 'src/services/LoginServices/user-sign.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

  constructor(private UserSignIn: UserSignService, private cookieService: CookieService, private router: Router) { }
  Year: string = Date();
  ngOnInit(): void {
    this.UserSignIn.CheckUnderMaitenance().subscribe(res => {
      if (res) {
        this.cookieService.delete('ApiQuote');
        this.cookieService.delete('Refresh');
        this.router.navigate(['maintenance']);
      } else {
        this.router.navigate(['login']);
      }
    }, error => { console.log(error); })
  }

}
