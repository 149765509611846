import { Pipe, PipeTransform } from '@angular/core'; 
import { MenuInfo } from '../model/Menu/MenuInfo';

@Pipe({ name: 'ChildMenuPipe' })
export class ChildMenuPipe implements PipeTransform {
    transform(menuDetails: any, menuId:string): MenuInfo[] {
      
        return this.getChildMenu(menuDetails, menuId);
    }



    public getChildMenu(menuDetails:MenuInfo, menuId: string): MenuInfo[] {
      let lstMenu: MenuInfo[] = [];
      let objMenu: MenuInfo;
      
      for (let menu in menuDetails) {
        objMenu = menuDetails[menu];
        if (objMenu.parentId != null && objMenu.parentId == menuId) {
          lstMenu.push(objMenu);
        }
      }

      if (lstMenu.length == 0)
        return null;
      else
        return lstMenu;
    }
  
    

}