import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { UserSignService } from 'src/services/LoginServices/user-sign.service';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AlertService } from 'src/services/Common/alert.service';
import { AlertMessages } from 'src/common/alert-messages';
import { ErrorHandler } from 'src/common/error-handler';
import { GetUserClientInfo } from 'src/model/AccessManagement/GetUserClientInfo';
import { DataShareService } from 'src/services/Common/dataShare.service';
@Component({
  selector: 'app-setting-user',
  templateUrl: './setting-user.component.html',
  styleUrls: ['./setting-user.component.css']
})
export class SettingUserComponent implements OnInit {
  formData: UntypedFormGroup;
  passUserdetailtoNOtificaitonPage: GetUserClientInfo;
  userDetail: any;
  clientList: any;
  acounttypeList; any;
  businessunitList: any;
  locationList: any;
  isClientUser: boolean;
  notificationList: [];
  checkedNofification: boolean;
  ListNotification: any[] = [];
  UserID: any;
  radioCheck = false;
  workTypeList: any;
  errorHandler = new ErrorHandler(this.alertService);
  notificationForm: UntypedFormGroup;
  constructor(private route: Router, private formBuilder: UntypedFormBuilder,
    private serive: UserSignService,
    private updateProifleServic: AddEditUserSerivceService, private alertService: AlertService,
    private dataShareService: DataShareService) {
    this.formData = this.formBuilder.group({
      clientnameTextBox: [],
      userTextBox: [, [Validators.required, Validators.maxLength(50)]],
      emailTextBox: [, [Validators.required]],
      locationText: [, [Validators.required]],
      timezoneSelector: [, [Validators.required]],
      phonenoTextBox: [, [Validators.pattern("^[0-9]+$"), Validators.maxLength(10)]],
      extensionTextBox: [, [Validators.pattern("^[0-9]+$"), Validators.maxLength(10)]],
      role: [],
      defacctype: [, [Validators.required]],
      default_Business_Unit: [, [Validators.required]],
      workType: []
    })
  }
  getParameter: any;
  ngOnInit() {
    this.GetUserDetails();
  }
  GetUserDetails() {
    //----Edit User Profile-----------------------------
    this.updateProifleServic.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      // this.passUserdetailtoNOtificaitonPage=res['userDetails'][0];
      this.userDetail = res['userDetails'][0];
      this.UserID = res['userDetails'][0]['userID']
      if (this.userDetail.clientID > 2) {
        this.isClientUser = true;
      }
      this.clientList = res['clientList'];
      this.locationList = res['clientLocation'];
      this.acounttypeList = res['accountType'];
      this.businessunitList = res['businessUnitType'];
      this.workTypeList = res['tblWorkTypeList'];
      this.formData.patchValue({
        clientnameTextBox: [this.userDetail['clientName']],
        userTextBox: [this.userDetail['userName'],],
        emailTextBox: [this.userDetail['emailID'],],
        locationText: [this.userDetail['locationID'],],
        timezoneSelector: [this.userDetail['timeZoneName'],],
        phonenoTextBox: [this.userDetail['phone'],],
        extensionTextBox: [this.userDetail['extension'],],
        role: [this.userDetail['roleName'],],
        defacctype: [this.userDetail['defaultAccountType'],],
        default_Business_Unit: [this.userDetail['defaultBusinessUnitId'],],
        workType: [this.userDetail['defaultWorkType']]
      })
    },
      err => {
        this.errorHandler.ErrorsHandler(err);
      }
    )
  }
  get GetFormControls() {
    return this.formData.controls;
  }
  FormSubmit() {
    // if (this.formData.controls['locationText'].invalid
    //   || this.formData.controls['extensionTextBox'].invalid
    //   || this.formData.controls['phonenoTextBox'].invalid) {
    //   this.alertService.info(AlertMessages.ALERTMESSAGE7)
    //   return;
    // }
    let postData = new FormData();
    postData.append('userId', this.UserID);
    postData.append("userName", this.formData.controls['userTextBox'].value);
    postData.append('phone', this.formData.value.phonenoTextBox)
    postData.append('extension', this.formData.value.extensionTextBox)
    postData.append('locationId', this.formData.value.locationText)
    // postData.append('defaultAccountTypeId', this.formData.value.defacctype)
    // if (this.formData.value.defacctype == null || this.formData.value.defacctype == '') {
    //   postData.append('defaultAccountTypeId', '0')
    // }
    // else {
    //   postData.append('defaultAccountTypeId', this.formData.value.defacctype)
    // }

    // if (this.formData.value.default_Business_Unit == null || this.formData.value.default_Business_Unit == '') {
    //   postData.append('defaultBusinessUnitId', '0')
    // }
    // else {
    //   postData.append('defaultBusinessUnitId', this.formData.value.default_Business_Unit)
    // }
    postData.append('defaultAccountTypeId', this.formData.value.defacctype)
    postData.append('defaultBusinessUnitId', this.formData.value.default_Business_Unit)
    postData.append('defaultWorktypeId', this.formData.controls['workType'].value)
    postData.append('currentUserID', this.UserID)
    this.updateProifleServic.UpdateUserProfile(postData, AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEUSERPROFILE).subscribe(response => {
      if (response) {
        this.alertService.success(AlertMessages.ALERTMESSAGE4);
        let userTimeZone = this.formData.controls['timezoneSelector'].value;
        this.dataShareService.setUserTimezone(userTimeZone);
        this.route.navigate(['/userprofile']);
      }
    }, err => {
      this.errorHandler.ErrorsHandler(err);
    })
  }
  BackAction(event) {
    this.route.navigate(['/userprofile']);
  }
  ChangeLocation(value) {
    let formdata = new FormData();
    formdata.append('LocationId', value);
    this.updateProifleServic.GetUserTimeZone(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERTIMEZONE, formdata).subscribe(res => {
      this.formData.patchValue({
        timezoneSelector: res
      })

    }, err => {
      this.errorHandler.ErrorsHandler(err);
    })
  }

}
