import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertService } from 'src/services/Common/alert.service';
import { DashboardServiceService } from 'src/services/DashboardService/dashboardService.service';

@Component({
  selector: 'app-client-lead-dashboard',
  templateUrl: './client-lead-dashboard.component.html',
  styleUrls: ['./client-lead-dashboard.component.css']
})
export class ClientLeadDashboardComponent implements OnInit, OnChanges {

  @Input() clientList: string;
  @Input() hasUploadAccess: boolean;
  @Input() View_Message_Center: boolean;
  @Input() RaiseReply_Query: boolean
  @Input() View_User: boolean;
  errorHandler = new ErrorHandler(this.alertService);

  uploadData: any;
  fieldHeader: any[] = [];
  userName: any;
  totalInventory: any;
  accountInQuery: any;
  accountInProgress: any;
  shippedAccount: any;
  downloadedAccount: any;
  accountProcessed: number;
  noOfActiveUser: any;
  locationProcessed: any;
  Role: any;
  isdiv = false;
  isData: boolean = false
  cancelledAccount: any;
  contractEndDate: any;
  UserList: any;
  messageContent: any;
  accountSummary: any;
  headerForAccount: any;
  Inventorydetail: boolean = false;
  constructor(private DashboardService: DashboardServiceService,
    private alertService: AlertService) { }

  ngOnInit() {
    
   // this.getdashboard(0);
  }
  ngOnChanges() {
    if (this.clientList != undefined) {
    this.getData(this.clientList[0]['clientid']);
    this.getdashboard(0);
    }
    
  }
  getData(clientId: number) {
    let formData: FormData = new FormData();
    formData.append('clientId', String(clientId))
    this.DashboardService.getUserForClientLead(formData).subscribe(
      result => {
        this.UserList = result['table'];
      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }
  getdashboard(UserId: number) {
    this.DashboardService.GetDashboardFromUSerId(this.clientList[0]['clientid'], UserId).subscribe(response => {
      if (response['tbldata'] != null && response['tbldata'] != undefined && response['tbldata'] != '') {
        this.isData = true
        this.uploadData = response['tbldata']
        this.fieldHeader = [];
        this.fieldHeader = this.fieldHeader.concat(Object.keys(response['tbldata'][0]));

      }
      else {
        this.isData = true;
      }

      // this.isdiv = true

      this.userName = response['tbluserName'];
      this.totalInventory = response['tbltotalinventory'][0]['totalinventory'];
      this.accountInQuery = response['tbltotalinqueue'][0]['totalinque'];
      this.accountInProgress = response['tbltotalinprogress'][0]['totalinprogress'];
      this.shippedAccount = response['tbltotalshipped'][0]['totalshipped'];
      this.downloadedAccount = response['tbltotaldownloaded'][0]['totaldownloaded'];
      this.noOfActiveUser = response['tblactiveuser'][0]['noofactiveusers'];
      this.locationProcessed = response['tbllocationprocessed'][0]['locationprocessed']
      this.accountProcessed = this.shippedAccount + this.downloadedAccount;
      this.cancelledAccount = response['table9'][0]['totalcancelled']
      if (response['table10'][0] != undefined) {
        this.contractEndDate = response['table10'][0]['dashContEnddate']
      }
      this.messageContent = response['table11'];
      if (response['table12'].length > 0) {
        this.Inventorydetail = true;
        this.accountSummary = response['table12'];
        this.headerForAccount = [];
        this.headerForAccount = this.headerForAccount.concat(Object.keys(response['table12'][0]));
      }
      else {
        this.Inventorydetail = false
      }




    },
      error => {
        this.errorHandler.ErrorsHandler(error);
      });

  }

}
