import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProcessTrackingRoutingModule } from './process-tracking-routing.module';

import { ViewProcessTrackingComponent } from './view-process-tracking/view-process-tracking.component';
import { EditProcessTrackingComponent } from './edit-process-tracking/edit-process-tracking.component'

import { UploadModule } from '../upload/upload.module';
import { AdvanceFilterComponent } from './advance-filter/advance-filter.component';
import {NgSelectModule} from '@ng-select/ng-select'
import { UploadAdditionalComponent } from './upload-additional/upload-additional.component';
import { AdvancedFilterNewComponent } from './advanced-filter-new/advanced-filter-new.component';
import { UploadAdditionalShipmentComponent } from './upload-additional-shipment/upload-additional-shipment.component';
import { EditPackageTypeComponentComponent } from './edit-packageType-component/edit-packageType-component.component';
import { SsAccountTrackerComponent } from './ss-account-tracker/ss-account-tracker.component';


@NgModule({
      declarations: [ViewProcessTrackingComponent, SsAccountTrackerComponent,EditProcessTrackingComponent, AdvanceFilterComponent, UploadAdditionalComponent, AdvancedFilterNewComponent,UploadAdditionalShipmentComponent,EditPackageTypeComponentComponent],
  imports: [
    CommonModule,
    ProcessTrackingRoutingModule,
    FormsModule, UploadModule, ReactiveFormsModule, NgSelectModule

  ]
})
export class ProcessTrackingModule {
  
}
