import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertService } from 'src/services/Common/alert.service';
import { PopupService } from 'src/services/Common/popUp.service';
import { MasterservcieService } from 'src/services/MasterService/masterservcie.service';

@Component({
  selector: 'app-manage-accounttype',
  templateUrl: './manage-accounttype.component.html',
  styleUrls: ['./manage-accounttype.component.css']
})
export class ManageAccounttypeComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder, private Service: MasterservcieService, private alertService: AlertService,
    private popupService: PopupService) { }
  AccountTypeform: UntypedFormGroup;
  checkerror: boolean;
  tblAccountType: any;
  searchInput: any = '';
  showSearchValidationError: boolean;
  showForm: boolean;
  isEdit: boolean;
  editedAccountTypeId: number;
  errorhandlerService = new ErrorHandler(this.alertService);
  buttonTittle: string = "Add";
  dynmaicFieldHeader: any = [];
  _formDeactivateAccountType: UntypedFormGroup;
  checkModelError: boolean;
  statusButtonTittle: string;
  accountTypeName: string;
  IsActive: boolean;
  @ViewChild('closebutton', { static: false }) closebutton;


  ngOnInit(): void {
    this.AccountTypeform = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      // isactive: ['', Validators.required],
      requestedby: ['', [Validators.required, Validators.maxLength(50)]],
      comment: ['', [Validators.required, Validators.maxLength(500)]]
    })
    this._formDeactivateAccountType = this.formBuilder.group({
      reasoncomment: ['', [Validators.required, Validators.maxLength(500)]]
    })
    this.GetAccountTypeDetail('');
  }
  GetAccountTypeDetail(searchInput: string) {
    this.tblAccountType = '';
    this.dynmaicFieldHeader = [];
    let formdata = new FormData();
    formdata.append('searchInput', searchInput);
    this.Service.GetMastersData(AppSettings.API_ENDPOINT + AppRelativeUrl.GETALLACCOUNTTYPE, formdata).subscribe(res => {
      this.tblAccountType = res;
      this.dynmaicFieldHeader = this.dynmaicFieldHeader.concat(Object.keys(this.tblAccountType[0]));
      console.log(this.dynmaicFieldHeader)
      console.log(this.tblAccountType);
    }, err => {
      console.log(err);
    })
  }
  get GetFormControls() {
    return this.AccountTypeform.controls;
  }
  Add() {
    this.checkerror = true
    if (this.AccountTypeform.controls['name'].invalid || this.AccountTypeform.controls['description'].invalid
      || this.AccountTypeform.controls['requestedby'].invalid || this.AccountTypeform.controls['comment'].invalid) {
      return
    } else {
      let formdata = new FormData();
      if (this.isEdit) {
        formdata.append('AccountTypeId', String(this.editedAccountTypeId));
      }
      else {
        formdata.append('AccountTypeId', '0')
      }
      formdata.append('AccountName', this.AccountTypeform.value.name)
      formdata.append('Description', this.AccountTypeform.value.description)
      // formdata.append('IsAcitve', this.AccountTypeform.value.isactive)
      formdata.append('RequestedBy', this.AccountTypeform.value.requestedby);
      formdata.append('Comment', this.AccountTypeform.value.comment);
      formdata.append('UserId', '0');
      this.Service.AddEditMaster(AppSettings.API_ENDPOINT + AppRelativeUrl.ADDEDITACCOUNTTYPE, formdata).subscribe(res => {
        if (res) {
          this.alertService.success("Account Type has been successfully")
          this.AccountTypeform.reset();
          this.isEdit = false;
          this.buttonTittle = "Add";
          this.checkerror = false;
          this.ngOnInit();
        } else {
          this.alertService.warn('This Account Type is allready exists');
        }
      },
        err => {
          this.errorhandlerService.ErrorsHandler(err);
        })
    }
  }
  DeleteAccountType(item) {
    this.editedAccountTypeId=item.accountTypeId;
    this.accountTypeName = item['accountType Name']
    if (item.isActive) {
      this.statusButtonTittle = 'Deactivate';
    }
    else {
      this.statusButtonTittle = "Activate";
    }
    this.IsActive = item.isActive;
  }
  EditAccountType(item) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    this.showForm = true;
    this.isEdit = true;
    this.buttonTittle = "Edit";
    this.editedAccountTypeId = item.accountTypeId
    this.AccountTypeform.patchValue({
      name: item['accountType Name'],
      description: item.description,
      isactive: item.isActive,
      comment: item.comment,
      requestedby: item.requestedBy
    });
  }
  SearchItem() {
    this.GetAccountTypeDetail(this.searchInput);
  }
  resetRecords() {
    this.searchInput = ''
    this.GetAccountTypeDetail(this.searchInput);
  }
  checkSearch() {

  }
  AddAccountType() {
    this.showForm = true;
  }
  close() {
    this.buttonTittle="Add"
    this.showForm = false;
    this.checkerror = false;
    this.AccountTypeform.reset();

  }
  ShowPasswordError() {
    this.checkModelError = true;
  }
  CloseModel() {
    this._formDeactivateAccountType.reset();
    this.accountTypeName = null;
    this.checkModelError = false;
  }
  ActivateDeativateAccountType() {
    this.checkModelError = true;
    if (this._formDeactivateAccountType.controls['reasoncomment'].invalid) {
      return;
    } else {
      let postData = new FormData();
      postData.append("AccountTypeId",String(this.editedAccountTypeId));
      postData.append("Comment", this._formDeactivateAccountType.value.reasoncomment);
      postData.append("IsActive", String(!this.IsActive));
      this.Service.AddEditMaster(AppSettings.API_ENDPOINT + AppRelativeUrl.DEACTIVATEMASTER, postData).subscribe(
        res => {
          if (res) {
            this.alertService.success('Account Details has been saved successfully');    
            this.closebutton.nativeElement.click();      
            this.ngOnInit();
          } else {
            this.alertService.error("SomeThing went wrong");
          }
        },
        err => {
          this.errorhandlerService.ErrorsHandler(err);
        }
      )
    }
  }
  get ModelGetFormControls() {
    return this._formDeactivateAccountType.controls
  }
}
