import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { GetMenuService } from '../LoginServices/get-menu.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService  {

  constructor(private getMenuService: GetMenuService,
    private router: Router) { }
  private data: any[] = [];
  // resolve(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ):Observable<any>|Promise<any>|any{
  //   return   this.getMenuService.GetMenuListByUser(AppSettings.API_ENDPOINT + AppRelativeUrl.GetMenuInfo).subscribe
  //   (result => {
  //     // this.menuDetails = result;
  //     // console.log(this.menuDetails);
  //     // let data = [];
  //     // data = result;
  //     // Authenticateuser.menuObj=data;
  //     // //console.log(result)
  //     // this.authenticationService.setUrl(data)
  //     let match =false;
  //     let compareUrl =result;
  //     for (let i = 0; i < compareUrl.length; i++) {
  //       if (compareUrl[i]["id"] == 7) {
  //         alert()
  //         match=true;
  //         break
  //        // this.router.navigate(["/login-test"]);
  //       }
  //     }
  //     if(!match){
  //       this.router.navigate(["/login-test"]);
  //     }
  //     // else{
  //     //   this.router.navigate(["/login-test"]);
  //     // }
     
  //   })

  // }

  setUrl(option) {
    this.data = option;
    //alert("set")
  }

  getUrl() {
   // alert("get")
   
    return this.data;
  }


}
