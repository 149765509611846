import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ErrorHandler } from 'src/common/error-handler';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddEditUserSerivceService {
  errorhandler: ErrorHandler
  constructor(private httpClient: HttpClient) { }
  GetClientDetail(formdata: FormData, url: string) {
    return this.CommonPostMethod(url, formdata);
  }
  AddEditUser(postData: FormData, url: string) {
    return this.CommonPostMethod(url, postData);
  }
  UpdateUserProfile(postData: FormData, url: string) {
    return this.CommonPostMethod(url, postData);
  }
  GetNotificationList(url: string, postData: FormData) {
    return this.CommonPostMethod(url, postData);
  }
  UpdateUserNotification(url: string, postData: FormData) {
    return this.CommonPostMethod(url, postData);
  }
  GetUserInfo(url: string) {
    return this.CommonGetMethod(url);
  }

  DeactivateUser(url: string, postData: FormData) {
    return this.CommonPostMethod(url, postData);
  }
  ResetUserPassword(url: string, postData: FormData) {
    return this.CommonPostMethod(url, postData);
  }

  SaveAsUser(url: string, postData: FormData) {
    return this.CommonPostMethod(url, postData);
  }
  public CommonPostMethod(url: string, postData: FormData) {
    return this.httpClient.post(url, postData).pipe(map(res => {
      return res;
    },
      err => {
        this.errorhandler.ErrorsHandler(err);
        return err.json();
      }))
  }
  private CommonGetMethod(url: string) {
    return this.httpClient.get(url).pipe(map(res => {
      return res;
    }, err => {
      return err.json();
    }
    ));
  }
  GetUserTimeZone(url: string, postData: FormData) {
    return this.httpClient.post(url, postData, { responseType: 'text' }).pipe(map(res => {
      return String(res);
    }, err => {
      return err.json();
    }))
  }
}
