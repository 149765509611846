import { Component, OnInit, Output, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { GetUserClientInfoService } from 'src/services/AccessManagement/Users/GetUserClientInfo';
import { GetUserClientInfo } from 'src/model/AccessManagement/GetUserClientInfo';
import { AppRelativeUrl } from '../../../common/AppRelativeUrl';
import { AppSettings } from '../../../common/AppSettings';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertService } from 'src/services/Common/alert.service';
import { AlertMessages } from 'src/common/alert-messages';
import { ExportService } from 'src/services/Common/export.service';
import { PopupService } from 'src/services/Common/popUp.service';
import { GetClientDetailsServiceService } from 'src/services/AccessManagement/get-client-details-service.service';
import { CommonMethod } from 'src/common/CommonMethod';
import { AuthenticationService } from 'src/services/Common/authentication.service';
import { MenuList } from 'src/common/menuList.enum';
import { Router } from '@angular/router';
import { FeatureMaster } from 'src/common/feature-master';

@Component({
  selector: 'app-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.css']
})
export class ViewUserComponent implements OnInit, AfterViewInit {
  exportData: any[] = [];
  userClientModal: GetUserClientInfo[];
  editUser: GetUserClientInfo;
  pageSize: number = 20;
  ASC: boolean = false;
  pageStart: number = 0;
  totalCount: number = 0;
  paginationHTML: string = '';
  item: number[] = [];
  addNewDivShow: boolean = false;
  addUser: boolean;
  userStatus: boolean;
  notification: boolean;
  dynFieldheader: string[] = [];
  allUsers: any;
  filter: string = '';
  class: string;
  noRecord: boolean = false;
  pageCount: number;
  currentPage: number = 0;
  nextDotShow: boolean;
  previousDotShow: boolean;
  rowCount: number = 0;
  SaveAsForm: UntypedFormGroup;
  // -----------------variables for popup--------------
  modaladduserForm: UntypedFormGroup;
  userNameErrors: boolean;
  modalclientName: string;
  modalaccountypemappingList: any;
  modalrolenameList: any
  modalbusinessUnitDescriptionList: any;
  modalclientMappingList: any;
  successMessage: boolean;
  errorHandler = new ErrorHandler(this.alertService);
  status: string = '1';
  searchInput: string = '';
  userToCopy: number;
  saveAsUserName: string;
  showUserNameError: boolean;
  showemailError: boolean;
  clientList: any;
  clientsId: string = '';
  allowaddeditAction: boolean;
  allowactivateAction: boolean;
  clientID: any = null;
  clientName: any[] = [];
  obj: CommonMethod = new CommonMethod();
  FeaturesList: any;
  addEditHeader: boolean = false
  selectedPageNo: any = 1;
  showSearchValidationError = false;
  CurrentUserClientId: number;

  @ViewChild('closebutton', { static: false }) closebutton;
  constructor(private getUserClientInfoService: GetUserClientInfoService,
    private formBuilder: UntypedFormBuilder, private addedituserService: AddEditUserSerivceService,
    private alertService: AlertService, private exportFile: ExportService,
    private popupService: PopupService,
    private passedClientId: GetClientDetailsServiceService
  ) {
    //this.obj.checkAccess(MenuList.ManageUser);
  }
  ngOnInit() {
    // this.compareUrl = this.authenticationservice.getUrl();    
    this.obj.checkAccess(MenuList.ManageUser);
    this.SaveCopyUser('');
    this.passedClientId.ClientId$.subscribe(clientId => this.clientID = clientId)
    if (this.clientID != 0)
      this.GetClientUser(this.clientID);
    //this.passedClientId.routingtoUser(0);
    this.CheckAuthorization();
    // if (CommonMethod.checkAccess(this.compareUrl ,MenuList.ManageUser)) {

    // }
    // else {
    //   //alert("no access")
    //   this.router.navigate(["/login-test"]);
    // }


  }

  ngAfterViewInit() {
    this.userStatus = true;
    this.GetUserClientData(1);
    this.GetTotalCount()
    // this.SetPagination(); 

  }
  public ToggleAddUser() {
    if (this.addNewDivShow)
      this.addNewDivShow = false;
    else
      this.addNewDivShow = true;
  }
  public SendMail(value) {
    // console.log(value);
    if (this.allowactivateAction) {
      this.popupService.Confirmation('Are you sure?', 'Send credential to ' + value['user Name'] + '!', 'Yes, Send', 'No, Cancel').then(
        result => {
          if (result.value) {
            let postData = new FormData();
            postData.append('userId', value.userID);
            postData.append('EmailId', value['email Address']);
            postData.append('userName', value['user Name']);
            this.addedituserService.DeactivateUser(AppSettings.API_ENDPOINT + AppRelativeUrl.SENTCREDENTIALS, postData).subscribe(
              res => {
                if (res) {
                  this.alertService.success('Credential has been sent to user')
                  this.ngAfterViewInit();
                }
              },
              err => {
                this.errorHandler.ErrorsHandler(err);
              }
            )
          }
        }
      )
    }
    else {
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
    }
  }
  public Deactivate(value: any) {
    let action: string;
    if (value.status) {
      action = "Deactivate";
    }
    else {
      action = "Activate";
    }
    this.popupService.Confirmation('Are you sure?', value['user Name'] + 'will be' + action + '!', 'Yes, ' + action, 'No, Cancel').then(result => {
      if (result.value) {
        let status: boolean = !value.status;
        let postData = new FormData();
        postData.append('isActive', String(status));
        postData.append('userId', value.userID);
        this.addedituserService.DeactivateUser(AppSettings.API_ENDPOINT + AppRelativeUrl.DEACTIVATEUSER, postData).subscribe(
          res => {
            if (res) {
              this.ngAfterViewInit();
              this.ngOnInit();
              this.alertService.success(AlertMessages.ALERTMESSAGE9)
            }
          }, err => {
            this.errorHandler.ErrorsHandler(err);
          }
        )
      }
      else {
        return;
      }
    })
  }
  public ResetPassword(value) {
    if (this.allowactivateAction) {
      this.popupService.Confirmation("Are you sure?", 'Reset Password for ' + value['user Name'] + '!', 'Yes, Reset', 'No, Cancel').then(result => {
        if (result.value) {
          let postData = new FormData();
          postData.append('UserID', value.userID);
          postData.append('userName', value['user Name']);
          postData.append('EmailId', value['email Address']);
          this.addedituserService.ResetUserPassword(AppSettings.API_ENDPOINT + AppRelativeUrl.RESETUSERPASSWORD, postData).subscribe(res => {
            if (res) {
              this.alertService.success('Password has been reset');
            }
          },
            err => {
              this.errorHandler.ErrorsHandler(err);
            })
        }
      })
    }
    else {
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
    }
  }


  public PageChange(pageNumber: number) {
    this.selectedPageNo = pageNumber;
    this.previousDotShow = false;
    this.nextDotShow = false;
    if (pageNumber === 0)
      pageNumber = 1;
    if (pageNumber > this.pageCount)
      pageNumber = this.pageCount;
    this.currentPage = pageNumber;
    if (pageNumber === this.item[this.item.length - 1] && this.pageCount > this.item[this.item.length - 1]) {
      this.item.shift();
      this.item.push(this.item[this.item.length - 1] + 1);
      this.previousDotShow = true;
      this.nextDotShow = true;
      if (this.item[this.item.length - 1] == this.pageCount)
        this.nextDotShow = false;
    }
    else if (pageNumber == this.pageCount) {
      this.item = [];
      if (this.pageCount > 5) {
        for (let j = this.pageCount - 4; j <= this.pageCount; j++) {
          this.item.push(j);
        }
        this.nextDotShow = false;
        this.previousDotShow = true;
      } else {
        for (let j = 1; j <= this.pageCount; j++) {
          this.item.push(j);
        }
      }
    }
    else if (pageNumber == this.item[0] && this.item[0] > 5) {
      this.previousDotShow = true;
      this.nextDotShow = true;
      this.item.pop();
      this.item.unshift(pageNumber - 1);
    }
    else if (pageNumber <= 5) {
      this.previousDotShow = false;
      if (this.pageCount > 5) {
        this.nextDotShow = true;
      }
      let x = this.item.length;
      this.item = [];
      for (let j = 1; j <= x; j++) {
        this.item.push(j);
      }
    } else {
      this.previousDotShow = true;
      // this.nextDotShow=true;
    }
    if (this.pageCount < 5) {
      this.nextDotShow = false;
      this.previousDotShow = false;
    }
    // this.GetAllRoles(pageNumber);

    if (pageNumber <= 1) {
      this.pageStart = 0;
    }
    else {
      this.pageStart = this.pageSize * (pageNumber - 1);
    }
    this.GetUserClientData(pageNumber);
  }

  private GetUserClientData(pageNumber: number) {
    if (pageNumber <= 1) {
      this.pageStart = 0;
    }
    else {
      this.pageStart = this.pageSize * (pageNumber - 1);
    }
    ////create form data
    let formdata: FormData = new FormData();
    formdata.append('filter', this.filter);
    formdata.append('startRow', (String(this.pageStart) === "NaN") ? '0' : String(this.pageStart));
    formdata.append('rowLength', String(this.pageSize));
    formdata.append('orderFilter', '');
    formdata.append('execType', '1');
    formdata.append('status', this.status);
    formdata.append('clientsid', this.clientsId);
    let len: any;
    this.getUserClientInfoService.GetUserClientInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.USER_CLIENT_INFO, formdata).subscribe(result => {
      // console.log('check result', result)
      // this.allowactivateAction = result['activateAction'][0]['allowactivate'];
      // this.allowaddeditAction = result['addEditAction'][0]['allowaddedit'];
      this.userClientModal = result["tbleUserList"];
      this.clientList = result["tbleClientList"];
      len = result["tbleUserList"].length;
      if (this.userClientModal[0] == null && this.userClientModal[0] == undefined) {
        this.noRecord = true;
      }
      else {
        this.rowCount = len;
        this.noRecord = false;
        this.dynFieldheader = [];
        this.dynFieldheader = this.dynFieldheader.concat(Object.keys(this.userClientModal[0]));
        console.log(this.dynFieldheader.length);
      }
     //----Call this method to hightlight the side menu 
    CommonMethod.selectMenuBYRouterLink('/usermanagement');
    },
      error => {
        this.errorHandler.ErrorsHandler(error)
      });
  }
  private GetTotalCount() {
    ////create form data
    let formdata: FormData = new FormData();
    // formdata.append('userId', '1');
    formdata.append('filter', this.filter);
    formdata.append('startRow', String(this.pageStart));
    formdata.append('rowLength', '1000');
    formdata.append('orderFilter', '');
    formdata.append('execType', '2');
    formdata.append('status', this.status);
    formdata.append('clientsid', this.clientsId);
    this.getUserClientInfoService.GetUserClientInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.USER_CLIENT_INFO, formdata).subscribe(result => {
      this.totalCount = result["tbleUserList"][0]['totalCount'];
      this.pageCount = (this.totalCount / this.pageSize);
      if (this.pageCount <= 1) { this.pageCount = 1 }
      else if ((this.pageCount % 1) != 0)
        this.pageCount = Math.floor(this.pageCount) + 1;
      this.pageCount = Number(this.pageCount.toFixed());
      this.item = [];
      for (let i = 1; i <= this.pageCount; i++) {
        if (i <= 5) {
          this.item.push(i);
        }
        else {
          this.nextDotShow = true;
        }
      }
    },
      error => {
        this.totalCount = -1;
        this.errorHandler.ErrorsHandler(error);
      });
  }
  Records(record: string) {
    this.previousDotShow = false;
    this.nextDotShow = false;

    if (record != null) {
      if (record == 'all') {
        this.pageSize = this.totalCount;
        this.item = [];
        this.GetUserClientData(1);
        this.GetTotalCount();
      }
      else {
        this.pageSize = Number(record);
        this.item = [];
        this.GetUserClientData(1);
        this.GetTotalCount();
      }
    }
  }
  AddUser() {
    this.addEditHeader = false;
    this.editUser = null;
    this.addUser = !this.addUser;
    this.notification = false;
  }
  EditUser(obj: GetUserClientInfo) {
    this.addEditHeader = true;
    this.notification = false;
    let newobj = new GetUserClientInfo();
    newobj = obj;
    this.editUser = newobj;
    this.addUser = true;
    this.notification = false;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
  CancelButton(value): void {
    this.addUser = false;
    this.notification = false;
    this.ngAfterViewInit();
  }
  Notification(obj: GetUserClientInfo) {
    this.addUser = false;
    let userNotification = new GetUserClientInfo();
    userNotification = obj;
    this.editUser = userNotification;
    // console.log(this.editUser);
    this.notification = true;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }
  CancelNotification(value) {
    this.CancelButton(value);
  }
  GetUserStatus(value) {
    switch (value) {
      case "all":
        this.status = '';
        break;
      case "active":
        this.status = '1'
        break;
      case "deactive":
        this.status = '0'
        break;
    }
    this.GetUserClientData(1);
    this.GetTotalCount();
  }
  SearchItem() {
    if (this.showSearchValidationError)
      return;
    if (this.searchInput != null || this.searchInput != '' || this.searchInput != undefined) {
      this.filter = this.searchInput;
      this.item = [];
      this.GetUserClientData(1);
      this.GetTotalCount();
    }
    return;
  }
  resetRecords() {
    this.showSearchValidationError = false;
    this.clientsId = '';
    this.searchInput = '';
    this.filter = '';
    this.GetUserClientData(1);
    this.GetTotalCount();
  }
  SaveCopyUser(value) {
    this.userToCopy = value.userID;
    this.saveAsUserName = value['user Name'];
    this.showUserNameError = false;
    this.showemailError = false;
    this.SaveAsForm = this.formBuilder.group({
      UserName: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(5)]],
      EmailAdddress: ['', Validators.compose([Validators.required, Validators.maxLength(50), Validators.minLength(5),
      Validators.pattern('^[A-Za-z0-9.\'_%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')])],
      ExtNumber: [''],
      PhoneNumber: ['', []]
    }
    )

  }
  SaveAsUser() {
    if (this.allowaddeditAction) {
      if (this.SaveAsForm.invalid) {
        this.showUserNameError = true;
        this.showemailError = true;
        return;
      }
      let postData = new FormData();
      postData.append('UserName', this.SaveAsForm.controls['UserName'].value);
      postData.append('EmailId', this.SaveAsForm.controls['EmailAdddress'].value);
      if (this.SaveAsForm.controls['ExtNumber'].value == '') {
        postData.append('ExtNumber', '0');
      }
      postData.append('ExtNumber', this.SaveAsForm.controls['ExtNumber'].value);
      postData.append('PhoneNumber', this.SaveAsForm.controls['PhoneNumber'].value);
      postData.append('UserIdToCopy', String(this.userToCopy))
      this.addedituserService.SaveAsUser(AppSettings.API_ENDPOINT + AppRelativeUrl.SAVEASUSER, postData).subscribe
        (
          res => {
            if (res) {
              this.alertService.success(AlertMessages.ALERTMESSAGE8);
              this.closebutton.nativeElement.click();
            }
          },
          err => {
            this.errorHandler.ErrorsHandler(err);
            //  console.log('display erro',err);
          }
        )
      this.closebutton.nativeElement.click();
    }
    else {
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
    }
  }
  get GetFormControls() {
    return this.SaveAsForm.controls;
  }
  ShowUserNameError() {
    this.showUserNameError = true;
  }
  ShowEmailError() {
    this.showemailError = true;
  }
  Close() {
    this.SaveAsForm.reset;
  }
  DownloadExcel() {
    this.getUserClientInfoService.ExportData(AppSettings.API_ENDPOINT + AppRelativeUrl.USER_CLIENT_INFO, String(this.totalCount), this.status, this.filter, this.clientsId).subscribe
      (
        result => {
          let data: any[] = result;
          let header = [];
          header = header.concat(Object.keys(data[0]));
          this.exportFile.generateExcel(header, data, "Users List");
        },
        err => {
          this.errorHandler.ErrorsHandler(err);
        }
      )
  }
  DownloadCSV() {
    this.getUserClientInfoService.ExportData(AppSettings.API_ENDPOINT + AppRelativeUrl.USER_CLIENT_INFO, String(this.totalCount), this.status, this.filter, this.clientsId).subscribe
      (
        result => {
          let header = [];
          header = header.concat(Object.keys(result[0]));
          this.exportFile.expotAsCSVFile(result, header, 'UserList');
        },
        err => {
          this.errorHandler.ErrorsHandler(err);
        }
      )
  }
  DownloadPDF() {
    this.getUserClientInfoService.ExportData(AppSettings.API_ENDPOINT + AppRelativeUrl.USER_CLIENT_INFO, String(this.totalCount), this.status, this.filter, this.clientsId).subscribe
      (
        result => {
          this.exportFile.generatePdf(result, 'User List')
        },
        err => {
          this.errorHandler.ErrorsHandler(err);
        }
      )
  }
  GetClientUser(val) {

    // console.log(String(val));
    this.clientsId = String(val);
    //console.log(this.clientsId);
    this.clientName.push(this.clientID);
    this.GetUserClientData(1);
    this.GetTotalCount();

  }
  selectRow(trId: string) {
    CommonMethod.selectRow(trId)
  }
  CheckAuthorization() {
    this.addedituserService.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      // console.log('********************',res)
      if (res != null && res != "undefined") {
        this.CurrentUserClientId = res['userDetails'][0]['clientID'];
        this.allowactivateAction = this.obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.Deactivate_User);
        this.allowaddeditAction = this.obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.AddEdit_User);
      }
    }
      , err => {
        this.errorHandler.ErrorsHandler(err);
      })

  }

  checkSearch() {
    this.showSearchValidationError = this.obj.validateSearch(this.searchInput);
  }
  SeparateComma(value: string): any {
    if(value==undefined || value==''){
      return null;
    }
    let arr = value.trim().split(',');
    return arr;
  }
}
