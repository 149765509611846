import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from 'src/services/Common/dataShare.service';

@Component({
  selector: 'app-total-account-status',
  templateUrl: './total-account-status.component.html',
  styleUrls: ['./total-account-status.component.css']
})
export class TotalAccountStatusComponent implements OnInit {
  @Input() totalInventory: any;
  @Input() accountInQuery: any;
  @Input() accountInProgress: any;
  @Input() shippedAccount: any;
  @Input() downloadedAccount: any;
  @Input() accountProcessed: number;
  @Input() noOfActiveUser: any;
  @Input() locationProcessed: any;
  @Input() cancelledAccount: any;

  constructor(private ShareData: DataShareService, private router: Router) { }

  ngOnInit() {
  }
  accountsInQuery(searchFilter: string) {
    this.ShareData.shareProcessTrackingFilter(searchFilter);
    this.router.navigate(['processtracking']);
    // alert()

  }

}
