export enum MenuList {
    Dashboard = 1,
    AccountManagement = 2,
    UploadAccount = 3,
    ProcessTracking = 4,
    MessageCenter = 5,
    AdminManagement = 6,
    ManageClient = 7,
    ManageUser = 8,
    ManageRole = 10,
    Report = 9
}
