<hr style="margin-top: 0px !important;" />
<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor" style=" margin-right: 10px;">
          Client Details
        </h3>
        <div class="card-tools" style="margin-left: 20px;">
          <div class="input-group input-group-sm">
            <button type="button" class="btn btn-default btn-block btn-sm d-none d-sm-block" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" style="width: 100px;">
              Export
            </button>
            <div class="input-group-append">
              <button type="button" class="btn btn-default btn-block btn-sm" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" style="background-color: #1d6f42;">
                <i class="fa fa-file-excel-o RMSSearchButtonIcon"></i>
              </button>
              <!--begin::Dropdown Menu-->
              <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <button class="dropdown-item btn btn-block btn-sm" (click)="downloadExel()">
                  <i class="fa fa-file-excel-o mr-3" style="color: #1d6f42;"></i>
                  Excel
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item btn btn-block btn-sm" (click)="downloadCsv()">
                  <i class="fa fa-file-code-o mr-3" style="color: #1d6f42;"></i>
                  CSV
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item btn btn-block btn-sm" (click)='downloadPdf()'>
                  <i class="fa fa-file-pdf-o mr-3" style="color: rgb(196, 32, 50);"></i>PDF
                </button>
                <!-- <div class="dropdown-divider"></div>
                              <a class="dropdown-item dropdown-footer"></a> -->
              </div>
              <!--End::Dropdown Menu-->
            </div>
          </div>
        </div>
        <div *ngIf="authorizedForAddEdit" class="card-tools" style="margin-left: 20px;">
          <div class="input-group input-group-sm" style="width: 150px;">
            <button type="button" title="Add New Client" class="btn btn-default btn-sm" data-toggle="collapse"
              data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" style="width: 120px;"
              (click)="AddClient()">
              Add Client
            </button>
            <div class="input-group-append">
              <button type="submit" class="btn btn-default btn-sm" data-toggle="collapse" data-target="#collapseExample"
                aria-expanded="false" aria-controls="collapseExample" style="background-color: rgb(196, 32, 50);">
                <i class="fas fa-plus RMSSearchButtonIcon"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card-tools ml-2">
          <div class="input-group input-group-sm" style="width: 180px;">
            <input type="text" #search name="table_search" [(ngModel)]="searchInput" (keyup)="checkSearch()"
              (keydown.enter)="searchClient()" class="form-control float-" placeholder="Search" />
            <div class="input-group-append">
              <button type="submit" class="MoodysSearchButton" (click)="searchClient()">
                <i class="fas fa-search RMSSearchButtonIcon"></i>
              </button>
              <button class="MoodysSearchButton" style="margin-left: 1px;" type="submit" data-toggle="tooltip"
                data-placement="top" title="Refresh" id="basic-text1" (click)="resetRecords()">
                <i class="fas fa-history text-grey" style="color: #ffffff;" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="col-lg-12 ErrorMessage NoPadding" *ngIf="showSearchValidationError"
            style=" padding-top:10px; color: #c42032; font-size: 0.8rem;">
            Special characters are not allowed
          </div>
        </div>

        <div class="card-tools">
          <div class="input-group input-group-sm">
            <h3 class="card-title selector" style="font-size: small;">
              Status
              <select class="selectstatus" (change)="checkclientstatus($event.target.value)">
                <option value="all">All</option>
                <option selected value="active">Active</option>
                <option value="deactive">Deactive</option>
              </select>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card -->
  </div>
  <!-- /.container-fluid -->
</section>
<section class="content" *ngIf="addclient">
  <div class="container-fluid">
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor" style="vertical-align: middle;">{{ AddEditTitle}}</h3>
      </div>
      <div class="card-body">
        <app-edit-client (RefreshGridData)="RefreshGridData($event)" (closeAddClient)="CloseAddClient($event)"
          [clientId]="clientId" [clientinfo]="clientinfo"></app-edit-client>
      </div>

    </div>
  </div>
</section>
<section class="content" *ngIf="clientElementMap">
  <div class="container-fluid">
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor" style="vertical-align: middle;">Client Element Mapping for
          {{passclientdetial['client Name']}}</h3>
      </div>
      <div class="card-body">
        <app-clientelementsetting [clientId]="clientId" (closetag)="Closeclientelement($event)">
        </app-clientelementsetting>

      </div>

    </div>
  </div>
</section>
<section class="content" *ngIf="clientElementvalidationMap">
  <div class="container-fluid">
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor" style="vertical-align: middle;">Client Element Validation
          Mapping for {{passclientdetial['client Name']}}</h3>
      </div>
      <div class="card-body">
        <app-elementvalidationsetting [clientId]="clientId" (closetag)="Closeclientelement($event)">
        </app-elementvalidationsetting>

      </div>

    </div>
  </div>
</section>
<section class="content">
  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default">
      <div class="card-header">
        <div class="card-body table-responsive p-0 text-nowrap">
          <table class="table table-bordered table-hover font14 noPad" id="tabledetail">
            <thead>
              <tr class="RMSTableHeader">
                <th class="text-center">Actions</th>

                <th *ngFor="let header of fieldHeader | slice: 1" class="text-left">
                  <div *ngIf="header!='account Manager'">{{ header | titlecase }}</div>
                  <div *ngIf="header=='account Manager'">RMS {{ header | titlecase }}</div>
                </th>
              </tr>
            </thead>
            <tbody class="RMSTableContent">
              <tr *ngFor="let item of data; let i = index" id="tr_{{i}}" (click)="selectRow('tr_' + i)"
                class="RmsTableRow">
                <td class="text-center">
                  <button class="btn btn-tool" type="button"
                    [disabled]="item['clientID']=='1' || item['clientID']=='2' ||item['is Active']==0 "
                    (click)="editClient(item)" data-toggle="collapse" data-target="#collapseExample"
                    aria-expanded="false" aria-controls="collapseExample">
                    <i *ngIf="authorizedForAddEdit" title="Edit Client" class="fa fa-edit MoodysEditButton"
                      ></i>
                    <i *ngIf="!authorizedForAddEdit" title="View Client" class="fa fa-eye" aria-hidden="true"></i>
                  </button>
                  <!-- <button *ngIf="authorizedForAddEdit" class="btn btn-tool" type="button"
                    (click)="EditClientElementmapping(item)" data-toggle="collapse" data-target="#collapseExample"
                    aria-expanded="false" aria-controls="collapseExample">
                    <i title="Edit Client Element" class="fa fa-tasks" style="color: rgb(0, 123, 151);"></i>
                  </button> -->

                  <!-- <button class="btn btn-tool" type="button" (click)="ElementValidationmapping(item)"
                    data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                    aria-controls="collapseExample">
                    <i title="Edit Element Validation" class="fa fa-tasks" style="color:rgb(196, 32, 50);"></i>
                  </button> -->
              
            
                  <!-- <button *ngIf="activateAccess=='1'" [disabled]= "item['clientID']=='1' || item['clientID']=='2'||item['is Active']==0" type="button" class="btn btn-icon-toggle" data-toggle="tooltip"
                    data-placement="top" (click)="
                      deactivateClient(
                        item,
                        item['is Active'],
                        item['client Name']
                      )
                    ">
                    <i *ngIf="!item['is Active']" title="Activate Client" class="fa fa-trash" style="color: gray;" ></i>
                    <i *ngIf="item['is Active']" title="Deactivate Client" class="fa fa-trash" style="color: rgb(196, 32, 50);" ></i>
                  </button> -->
                  <button *ngIf="authorizedForDeactive" [disabled]="item['clientID']=='1' || item['clientID']=='2'"
                    type="button" class="btn btn-icon-toggle" data-toggle="tooltip" data-placement="top" (click)="
                    deactivateClient(
                      item,
                      item['is Active'],
                      item['client Name']
                    )
                  ">
                    <i *ngIf="!item['is Active']" title="Activate Client" class="fa fa-trash" style="color: gray;"></i>
                    <i *ngIf="item['is Active']" title="Deactivate Client" class="fa fa-trash"
                      style="color: rgb(196, 32, 50);"></i>                    
                  </button>
                  <button type="button"
                    [disabled]="item['clientID']=='1' || item['clientID']=='2'||item['is Active']==0"
                    class="btn btn-icon-toggle" routerLink="contract" data-toggle="tooltip" data-placement="top"
                    (click)="ShareClientId(item,1)">
                    <!-- <a routerLink="/contract/{{ item.clientID }}"> -->
                    <!-- <i class="fa fa-file-contract" title="Contracts" style="color: rgb(196, 32, 50);"></i> -->
                    <i *ngIf="item['expired']==1;else other_content" class="fa fa-file-contract" title="Contracts" style="color: rgb(25, 150, 87)"></i>
                    <ng-template #other_content>
                      <i *ngIf="item['expired']==2;else other"  class="fa fa-file-contract" title="About to expired within 15 days" style="color:  #FFBF00;"></i>
                      <ng-template #other>
                        <i class="fa fa-file-contract" title="Contracts" style="color: rgb(196, 32, 50);"></i>
                      </ng-template>
                    </ng-template>
                  </button>
                  <!-- <button  [disabled]= "item['clientID']=='1' || item['clientID']=='2'||item['is Active']==0" type="button" class="btn btn-icon-toggle" routerLink="/usermanagement"
                    (click)="ShareClientId(item)"><i class="fas fa-user" title="View User" style="color: teal;"></i></button> -->
            
                      <button type="button" class="btn btn-tool" data-toggle="dropdown" aria-expanded="false"
                      aria-controls="collapseExample">
                        <i class="fa fa-cog " title="Action" style="color:gray;" data-original-title="other Acionts"
                          aria-hidden="true"></i>
                      </button>
                      <div class="shadow" id=#dropdown>
                        <div class="dropdown-menu ">
                          <ul>
                            <li class="li-header ">
                              Choose an action
                            </li>
                            <!-- <li class="Icon-desc">
                              <button *ngIf="authorizedForAddEdit" class="btn btn-tool" type="button"
                                (click)="EditClientElementmapping(item)" data-toggle="collapse"
                                data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                <i title="Edit Client Element" class="fa fa-tasks" style="color: rgb(0, 123, 151);"></i>
                                <span class="Icon-desc" style="color: black;"> Element Setting</span>
                              </button>
                            </li>
                            <li>
                              <button class="btn btn-tool" type="button" (click)="ElementValidationmapping(item)"
                                data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                                aria-controls="collapseExample">
                                <i title="Edit Element Validation" class="fa fa-tasks" style="color:rgb(196, 32, 50);"></i>
                                <span class="Icon-desc" style="color: black;"> Element Validation</span>
                              </button>
                              
                            </li> -->
                            <li>
                              <!-- <button [disabled]="item['is Active']==0" type="button" class="btn btn-icon-toggle"
                              (click)="ShareClientId(item,2)"><i class="fas fa-user" title="View User"
                                style="color: teal;"></i><span class="Icon-desc" style="color: black;"> View User</span></button> -->

                                <button [disabled]="item['is Active']==0" class="btn btn-tool" type="button" title="View User" (click)="ShareClientId(item,2)"
                                data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                                aria-controls="collapseExample">
                                <i class="fas fa-user MoodysTittleColor" title="View User"
                                ></i>
                                <span class="Icon-desc" style="color: black;"> View User </span>
                              </button>
                              
                            </li>
                            <li>
                              <!-- <button [disabled]="item['is Active']==0" type="button" class="btn btn-icon-toggle"
                              (click)="ShareClientId(item,2)"><i class="fas fa-user" title="View User"
                                style="color: teal;"></i><span class="Icon-desc" style="color: black;"> View User</span></button> -->

                                <button [disabled]="item['is Active']==0" class="btn btn-tool" type="button" title="Request New User" (click)="RequestNewUser(item)"
                                data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                                aria-controls="collapseExample">
                                <i class="fas fa-user-plus MoodysTittleColor" title="Request New User"
                                ></i>  <span class="Icon-desc" style="color: black;"> Request New User </span>
                              </button>                              
                            </li>
                          </ul>
                        </div>
                      </div>
                </td>

                <td>{{ item["client Name"] }}</td>
                <td>{{ item.syndicate }}</td>
                <td *ngIf="item['is Active']">
                  <span class="badge badge-info activeBadge">
                    Active
                  </span>
                </td>
                <td *ngIf="!item['is Active']">
                  <span class="badge deactiveBadge">
                    Deactive
                  </span>
                </td>

                <td>{{ item['account Manager'] }}</td>
                <td>{{ item["client Type"] }}</td>
                <td>{{ item["mapped Applications"] }}</td>
                <td>{{ item.location }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end .section-body -->
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="col-sm-4" style="align-items: center; text-align: center;">
          <label style="
              font-weight: normal;
              float: left;
              font-size: smaller;
              margin-right: 5px;
            ">Show</label>
          <select title="Records to show" class="form-control" style="
              width: 2rem;
              height: 22px;
              padding: 0px;
              padding-left: 2px;
              font-size: smaller;
              float: left;
              text-align: center;
            " (change)="Records($event.target.value)">
            <!-- <option value="null">--Records--</option> -->
            <!-- <option value="1" > 1 </option> -->
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100" selected="true">100</option>
            <option value="500">500</option>
            <option value="all">All</option>
          </select>
          <label style="
              font-weight: normal;
              float: left;
              font-size: smaller;
              margin-left: 5px;
            ">Records</label>
        </div>
        <label class="col-sm-4" style="font-size: smaller; text-align: center; font-weight: normal;">Showing
          {{ pageStart + 1 }} to {{ pageStart + rowCount }} of
          {{ totalCount }} Records</label>
        <div class="col-sm-4">
          <nav aria-label="Page navigation" class="float-right">
            <ul class="pagination float-right">
              <li class="page-item">
                <a class="RMSButtonPageNavigation page-link " (click)="PageChange(1)" aria-label="First">
                  <label class="fa fa-angle-double-left" style="
                      margin-left: -4px;
                      font-size: small !important;
                      font-weight: bold !important;
                    "></label>
                  <!-- <span class="sr-only">Previous</span> -->
                </a>
              </li>
              <li class="page-item">
                <a (click)="PageChange(currentPage - 1)" class="RMSButtonPageNavigation page-link"
                  aria-label="Previous">
                  <label class="fa fa-angle-left" style="
                      font-size: small !important;
                      font-weight: bold !important;
                    "></label>
                </a>
              </li>
              <li class="page-item" *ngIf="previousDotShow">
                <label  style="font-weight: bold !important; padding-top: 9px;">...</label>
              </li>
              <li *ngFor="let i of item">
                <a (click)="PageChange(i)" [ngClass]="{'selectedPage':i===selectedPageNo}"
                  class="RMSButtonPageNavigation page-link ">
                  <label style="
                      vertical-align: sub !important;
                      font-size: small;
                      margin-left: -4px;
                    ">{{ i }}</label>
                </a>
              </li>
              <li class="page-item" *ngIf="nextDotShow">
                <!-- <a   class="RMSButtonPageNavigation page-link text-info" >                   -->
                <label class="RMSButtonPageNavigation" style="font-weight: bold !important; padding-top: 9px;">...</label>
                <!-- </a> -->
              </li>
              <li class="page-item">
                <a (click)="PageChange(currentPage + 1)" class="RMSButtonPageNavigation page-link "
                  aria-label="Next">
                  <label class="fa fa-angle-right" style="
                      font-size: small !important;
                      font-weight: bold !important;
                    "></label>
                </a>
              </li>
              <li class="page-item">
                <a (click)="PageChange(pageCount)" class="RMSButtonPageNavigation page-link "
                  aria-label="Last">
                  <label class="fa fa-angle-double-right" style="
                      margin-left: -4px;
                      font-size: small !important;
                      font-weight: bold !important;
                    "></label>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <!-- /.card -->
  </div>
  <!-- /.container-fluid -->
</section>

<!-- /.content -->