import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { DataShareService } from 'src/services/Common/dataShare.service';
import { LogoutService } from 'src/services/LoginServices/logout.service';

@Component({
  selector: 'app-session-expire',
  templateUrl: './session-expire.component.html',
  styleUrls: ['./../login/login.component.css']
})
export class SessionExpireComponent implements OnInit {
  Year: string;
  constructor(private route: Router, private cookieService: CookieService, private logoutService: LogoutService, private ShareData: DataShareService) { }

  ngOnInit() {
    this.Year = Date();

    let token = this.cookieService.get('ApiQuote');
    // // console.log(token);
    if (token != null || token != '') {
      this.cookieService.delete('ApiQuote');
      this.cookieService.delete('Refresh');
      this.cookieService.delete('UserName');
    }
  }
}


