<hr style="margin-top: 0px !important;" />
<section class="content">
    <div class="container-fluid">
        <!-- SELECT2 EXAMPLE -->
        <div class="card card-default">
            <div class="card-header" style="padding-left: 10px;">
                <div class="card-tools" style="float: left;">

                </div>
                <h3 class="card-title MoodysTittleColor" >
                    Contact Support
                </h3>

            </div>

        </div>
    </div>
</section>
<section>
    <div class="container-fluid">
        <div class="card card-default">
            <div class="card-header" style="padding-left: 10px;">
                <div class="card-tools" style="float: left;">

                </div>

                <div class="row">

                    <div class="col-lg-8">
                        <form [formGroup]="contactForm" (ngSubmit)="SubmitForm();">
                            <table class="table" style="width:100%;">
                                <tr>
                                    <td style="border-top-style:none;">
                                        <div style="padding-top:2%" class="input-group">
                                            <span class="input-group-addon" id="sizing-addon1"
                                                style="border-radius:0px;background-color:#e9ecef;;width: 30px;">
                                                <i class="fa fa-envelope InputIcon" style="color: #495057;"></i></span>
                                            <input type="text" class="form-control inputClass"
                                                (ngModelChange)="ShowEmailError();" formControlName="cEmail"
                                                placeholder="Email Address" aria-describedby="sizing-addon1"
                                                style="border-radius:0px;max-width:100%;  font-weight: 200;" />
                                            <div class="col-lg-12 ErrorMessage NoPadding"
                                                style="color: #c42032; min-height: 1rem !important;">
                                                <div
                                                    *ngIf=" showFormError && GetFormControls.cEmail.errors && showEmailError ">
                                                    <div *ngIf="GetFormControls.cEmail.errors.required">Email is
                                                        required.</div>
                                                    <div *ngIf="GetFormControls.cEmail.errors.pattern">Please check the
                                                        format
                                                        of the email address
                                                        entered.</div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-top-style:none; padding-top:0px; padding-bottom:0px;">
                                        <div class="input-group">
                                            <span class="input-group-addon" id="sizing-addon1"
                                                style="border-radius:0px;background-color:#e9ecef;">
                                                <i class="fa fa-envelope InputIcon" style="color: #495057;"></i></span>
                                            <input type="text" class="form-control inputClass"
                                                (ngModelChange)="ShowSubjectError();" formControlName="cSubject"
                                                placeholder="Subject" aria-describedby="sizing-addon1"
                                                style="border-radius:0px;max-width:100%;  font-weight: 200;" />
                                            <div class="col-lg-12 ErrorMessage NoPadding"
                                                style="color: #c42032; min-height: 1rem !important;">
                                                <div *ngIf="GetFormControls.cSubject.errors && showcSubjectError ">
                                                    <div
                                                        *ngIf=" showFormError && GetFormControls.cSubject.errors.required">
                                                        Subject is
                                                        required.
                                                    </div>
                                                    <div *ngIf="GetFormControls.cSubject.errors.maxlength">Maximum 100
                                                        characters allowed.</div>
                                                    <div *ngIf="GetFormControls.cSubject.errors.minlength">Minimum 10
                                                        characters
                                                        allowed.</div>
                                                </div>
                                            </div>
                                        </div>

                                    </td>
                                </tr>
                                <tr>

                                    <td style="border-top-style:none; padding-top:0px; padding-bottom:0px;">
                                        <div class="input-group">
                                            <span class="input-group-addon" id="sizing-addon1"
                                                style="font-size: 14.5px;border-radius:0px;background-color:#e9ecef;"><i
                                                    class="fa fa-envelope InputIcon"
                                                    style="font-size: 14.5px;color: #495057;"></i></span>
                                            <textarea id="inputMessage" maxlength="1000"
                                                class="form-control inputClass inputClass1" #lengthOfText
                                                (ngModelChange)="ShowMessageError();" formControlName="cMsg"
                                                placeholder="Message" name="txtMessage" cols="30" rows="3"
                                                aria-describedby="sizing-addon1"
                                                style="border-radius:0px;max-width:100%; font-weight: 200;"></textarea>
                                            <div class="col-lg-12 ErrorMessage NoPadding"
                                                style="color: #c42032; height: 1rem !important;">
                                                <div *ngIf="GetFormControls.cMsg.errors && showcMsgError">
                                                    <div *ngIf="showFormError && GetFormControls.cMsg.errors.required">
                                                        Message is
                                                        required.</div>
                                                    <div *ngIf="GetFormControls.cMsg.errors.maxlength">Maximum 1000
                                                        characters
                                                        allowed</div>
                                                    <div *ngIf="GetFormControls.cMsg.errors.minlength">Minimum 20
                                                        characters allowed</div>
                                                </div>
                                                <div class="form-row__description" style="color: #db202f;"
                                                    *ngIf="isTyping">
                                                    {{1000- lengthOfText.value.length }} character remaining
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-top-style:none;">
                                        <div class="input-group captchaDiv" style="padding-bottom: 0 !important;">
                                            <!-- google recaptcha -->
                                            <!-- <re-captcha formControlName="recaptchaReactive"></re-captcha> -->
                                            <re-captcha formControlName="recaptchaReactive" class="gc-reset"
                                                (resolved)="resolved($event)"></re-captcha>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="border-top-style:none;">
                                        <button type="submit" class="btn btn-primary RMSSubmitButton">
                                            Submit
                                        </button>
                                        <!-- <a routerLink="/login" class="btn btn-success SignInBtn float-right"> Back</a> -->
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    </div>
</section>