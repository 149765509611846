import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { CommonMethod } from 'src/common/CommonMethod';
import { ElementValidationModel } from 'src/model/ClientManagement/ClientElementmappsing';
import { ClientelementmappingserviceService } from 'src/services/ClientElementMappingService/clientelementmappingservice.service';
import { AlertService } from 'src/services/Common/alert.service';

@Component({
  selector: 'app-elementvalidationsetting',
  templateUrl: './elementvalidationsetting.component.html',
  styleUrls: ['./elementvalidationsetting.component.css']
})
export class ElementvalidationsettingComponent implements OnInit, OnChanges {
  checkerror: boolean;
  clientManagementForm: UntypedFormGroup;
  ValidatorMasterData: any;
  elementvalidationList: any;
  _elementvalidationgridList: any[] = [];
  _elementList: any[] = [];
  _selectedElement: any;
  _selectedValidator: any;
  _isEdit: boolean;
  _buttonTittle: string;
  _UniqueId: string;
  @Input() clientId: number;
  @Output() closetag = new EventEmitter();
  constructor(private formbuilder: UntypedFormBuilder, private ClientEleService: ClientelementmappingserviceService
    , private alertService: AlertService) { }
  ngOnChanges() {
    this.GetMasterDate();
    this._elementvalidationgridList = [];
    this.ValidatorMasterData = [];
    this._elementList = [];
    this._buttonTittle = "Add"
    this.ngOnInit();
  }
  ngOnInit(): void {

    this.clientManagementForm = this.formbuilder.group({
      elementselector: ['', [Validators.required]],
      validator: ['', [Validators.required]],
      // isRequired: ['', [Validators.required]],
      isignorable: ['', [Validators.required]],
      isActive: ['', [Validators.required]]

    })
  }

  GetMasterDate() {
    this.ClientEleService.GETELEMENTVALIDATIONMASTERDATA(AppSettings.API_ENDPOINT + AppRelativeUrl.GETELEMENTVALIDATIONMASTERDATA, String(this.clientId)).subscribe(res => {
      this._elementList = res['elementList'];
      this.ValidatorMasterData = res['validationMaster'];
      this.elementvalidationList = res['elementValidationList']
      this.elementvalidationList.forEach(element => {
        let objModel = new ElementValidationModel;
        objModel.GUID = CommonMethod.GenrateUniqueIdentity();
        objModel.id = element.id
        objModel.ElementId = element.elementId;
        objModel.ElementName = element.elementName;
        objModel.clientId = element.clientId;
        objModel.ValidationId = element.validationId;
        objModel.validtionName = element.validationType;
        objModel.ValidatorValue = element.validationValue;
        objModel.IsIgnorable = element.isIgnorable;
        // objModel.IsRequired = element.validationReq;
        objModel.IsActive = element.isActive;
        this._elementvalidationgridList.push(objModel);
      });
      console.log(res)
    }, err => {
      console.log(err);
    })
  }
  AddToElementList() {
    this.checkerror = true;
    if (this.CheckFormValidation()) {
      if (this._isEdit) {
        let Index = this._elementvalidationgridList.findIndex(obj => obj.GUID === this._UniqueId)
        this._elementvalidationgridList[Index].ElementId = this._selectedElement.elementId;
        this._elementvalidationgridList[Index].ElementName = this._selectedElement.elementName;
        this._elementvalidationgridList[Index].clientId = this.clientId;
        this._elementvalidationgridList[Index].ValidationId = this._selectedValidator.id;
        this._elementvalidationgridList[Index].validtionName = this._selectedValidator.validationType;
        this._elementvalidationgridList[Index].ValidatorValue = this._selectedValidator.validationValue;
        this._elementvalidationgridList[Index].IsIgnorable = (this.clientManagementForm.value.isignorable == 'true' || this.clientManagementForm.value.isignorable == true);
        // this._elementvalidationgridList[Index].IsRequired = (this.clientManagementForm.value.isRequired == 'true' || this.clientManagementForm.value.isRequired == true);
        this._elementvalidationgridList[Index].IsActive = (this.clientManagementForm.value.isActive == 'true' || this.clientManagementForm.value.isActive == true);

      }
      else {
        let ifexists = this._elementvalidationgridList.some(obj => obj.ValidationId == this._selectedValidator.id && obj.ElementId == this._selectedElement.elementId);
        if (!ifexists) {
          let objModel = new ElementValidationModel;
          objModel.GUID = CommonMethod.GenrateUniqueIdentity();
          objModel.id = 0;
          objModel.ElementId = this._selectedElement.elementId;
          objModel.ElementName = this._selectedElement.elementName;
          objModel.clientId = this.clientId;
          objModel.ValidationId = this._selectedValidator.id;
          objModel.validtionName = this._selectedValidator.validationType;
          objModel.ValidatorValue = this._selectedValidator.validationValue;
          objModel.IsIgnorable = (this.clientManagementForm.value.isignorable == 'true' || this.clientManagementForm.value.isignorable == true);
          // objModel.IsRequired = (this.clientManagementForm.value.isRequired == 'true' || this.clientManagementForm.value.isRequired == true);
          objModel.IsActive = (this.clientManagementForm.value.isActive == 'true' || this.clientManagementForm.value.isActive == true);
          this._elementvalidationgridList.push(
            objModel
          )
        }
        else {
          this.alertService.warn("This Validation is already exists");
        }
      }
      this.checkerror = false;
      this._isEdit = false;
      this._buttonTittle = "Add";
      this.clientManagementForm.reset();
    }
    else {
      return;
    }
  }
  CheckFormValidation(): boolean {
    if (
      this.clientManagementForm.controls['elementselector'].invalid || this.clientManagementForm.controls['validator'].invalid
      || this.clientManagementForm.controls['isignorable'].invalid
      || this.clientManagementForm.controls['isActive'].invalid
      // || this.clientManagementForm.controls['isRequired'].invalid
    ) {
      return false
    }
    return true;
  }
  close() {
    this.closetag.emit(0);
  }
  get GetFormControls() {
    return this.clientManagementForm.controls;
  }
  GetElementID(itemID) {
    this._selectedElement = '';
    this._selectedElement = this._elementList.find(ele => ele.elementId == itemID)
  }
  GetValidatorId(itmeId) {
    this._selectedValidator = '';
    this._selectedValidator = this.ValidatorMasterData.find(ele => ele.id == itmeId);
  }
  EditValidation(item) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    this._isEdit = true;
    this._buttonTittle = "Edit";
    this._UniqueId = item.GUID;
    this._selectedElement = this._elementList.find(obj => obj.elementId == item.ElementId);
    this._selectedValidator = this.ValidatorMasterData.find(obj => obj.id == item.ValidationId);

    this.clientManagementForm.patchValue({
      elementselector: item.ElementId,
      validator: item.ValidationId,
      // isRequired: item.IsRequired,
      isignorable: item.IsIgnorable,
      isActive: item.IsActive
    });

  }
  DeleteValidation(item) {
    let index = this._elementvalidationgridList.indexOf(item);
    this._elementvalidationgridList.splice(index, 1);
  }
  SaveElementValidation() {
    this.ClientEleService.SaveElementValidationSettings(AppSettings.API_ENDPOINT + AppRelativeUrl.SAVEELEMENTVALIDATIONMAPPING, JSON.stringify(this._elementvalidationgridList)).subscribe(res => {
      if (res) {
        this.alertService.success("Validation has been saved successfully")
        this.ngOnChanges();
      } else {
        this.alertService.error("Something went wrong")
      }
    }, err => {
      console.log(err);
    })

  }
  Cancel(){
    this.checkerror = false;
    this._isEdit = false;
    this._buttonTittle = "Add";
    this.clientManagementForm.reset();
  }

}
