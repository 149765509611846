
<div class="container-fluid" style="margin-top: 10px;  padding-top:10px;">
  <div class="card card-default">
    <div class="card-header" id="target" style="background-color: #ffffff; " #target>
      <h3 class="card-title mt-1 MoodysTittleColor" >Process Tracker</h3>
      <div class="row float-right">
        <!-- Search -->
        <div>
          <div class="input-group input-group-sm">
            <input class="form-control font11" (keyup)="checkSearch()" (keydown.enter)="searchRecords()" type="text"
              placeholder="Search" [(ngModel)]="searchInput" aria-label="Search" />
            <div class="input-group-append">
              <button class="input-group-text bg-primary" data-toggle="tooltip" data-placement="top" type="submit"
                id="basic-text1" title="Search" (click)="searchRecords()" class="MoodysSearchButton">
                <i class="fas fa-search RMSSearchButtonIcon"></i>
              </button>
              <button class="MoodysSearchButton" type="submit" data-toggle="tooltip" data-placement="top"
                style="margin-left: 1px;" title="Refresh" id="basic-text1" (click)="resetRecords()">
                <i class="fa fa-refresh text-grey" style="color: #ffffff;" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="col-lg-12 ErrorMessage NoPadding" *ngIf="showSearchValidationError"
            style="color: #c42032; font-size: 0.8rem;">
            Special characters are not allowed
          </div>
        </div>
        <!-- Advanced Filter -->
        <!-- <div style="margin-left: 10px;">
          <div class="btn-group btn-group-sm" (click)="toggleAdvancedFilter()">
            <button class="btn btn-default" type="button">Filter</button>
            <button class="RMSSearchButton" aria-hidden="true">
              <i class="fa fa-filter text-white"></i>
            </button>
          </div>
        </div> -->
        <!-- Export -->
        <div style="margin-left: 10px;">
          <div class="input-group input-group-sm  mt-0">
            <button type="button" class="btn btn-default btn-block btn-sm d-none d-sm-block" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" style="width: 70px;" (click)="DownloadExcel()">
              Export
            </button>
            <div class="input-group-append">
              <button type="button" class="btn btn-default btn-block btn-sm" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" style="background-color:#1D6F42">
                <i class="fa fa-file-excel-o RMSSearchButtonIcon"></i>
              </button>
              <!-- <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <button class="dropdown-item btn  btn-block btn-sm" (click)="DownloadExcel()">
                  <i class="fa fa-file-excel-o mr-3" style="color:#1D6F42;"></i> Excel
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item btn btn-block btn-sm" (click)="DownloadCSV()">
                  <i class="fa fa-file-code-o mr-3" style="color:#1D6F42;"></i> CSV
                </button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item btn  btn-block btn-sm" (click)="DownloadPDF()">
                  <i class="fa fa-file-pdf-o mr-3" style="color:rgb(196, 32, 50);"></i>PDF
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- Download account list  -->

  <section class="content" id="showDownloadDiv" *ngIf="showDownload">
    <div class="">
      <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title MoodysTittleColor" >Download Queue</h3>
          <button type="button" (click)="closeDownloadProgress()" style="padding-top: 2px;
          padding-bottom: 2px;" class="float-right btn btn-primary RMSSubmitButton">
            Close
          </button>
        </div>
        <div class="card-body" id="Progress">
          <div class="table-responsive text-nowrap">
            <table class="table-hover table-bordered font14 noPad" style="width: 100%;">
              <thead>
                <tr class="th-sm RMSTableHeader">
                  <th>Account Name</th>
                  <th>File Name</th>
                  <th>Job Type</th>
                  <th>Account Type</th>
                  <th>Business Unit</th>
                  <th>Work Type</th>
                  <th>Progress</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                <tr class="RMSTableContent" *ngFor="let item of downloadFileProgress">
                  <ng-container>
                    <td>{{item.AccountName}}</td>
                    <td>{{item.FileName}}</td>
                    <td>{{item.JobType}}</td>
                    <td>{{item.AccountType}}</td>
                    <td>{{item.BusinessUnit}}</td>
                    <td>{{item.WorkType}}</td>

                    <td>

                      <div class="row" style="margin-left: inherit;">
                        <div class="Uploadprogress-cont col-sm-8" *ngIf="item.Progress > 0">
                          <div class="UploadProgress" [style.width]="item.Progress + '%'">
                            <label style="font-weight: normal;">{{item.Progress}}%</label>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{{item.Message}}</td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>





  <!-- Edit of Account -->
  <div class="card card-default" *ngIf="IsEditVisible" id="EditAccount">
    <div class="card-header">
      <h3 class="card-title MoodysTittleColor" style="vertical-align: middle;">
        Edit of Account
        {{EditAccountName}}
      </h3>
    </div>
    <div class="card-body">
      <app-edit-process-tracking (closeEdit)="CloseEdit($event)" (RefreshGridData)="RefreshGridData($event)"
        [editData]="editData"></app-edit-process-tracking>
    </div>
  </div>
  <!-- Upload Additional SOV -->
  <div class="card card-default" id="UploadAdditional" *ngIf="IsAdditionalUpload">
    <div class="card-header">
      <h3 class="card-title MoodysTittleColor" style="vertical-align: middle;">Upload Additional SOV</h3>
    </div>
    <div class="card-body">
      <app-upload-additional [AccountData]="editData" (closeAdditionalUpload)="closeAdditionalUpload($event)">
      </app-upload-additional>
    </div>
  </div>

  <!-- edit package type -->
  <div class="card card-default" id="EditPackage" *ngIf="IsEditPackageType">
    <div class="card-header">
      <h3 class="card-title MoodysTittleColor" style="vertical-align: middle;">Edit Package Type </h3>
    </div>
    <div class="card-body">
      <app-edit-packageType-component (CloseEditPackage)="CloseEditPackage($event)" [AccountData]="editData" (RefreshGridData)="RefreshGridData($event)">
      </app-edit-packageType-component>
    </div>
  </div>
  <!-- Upload Additional Shipment -->
  <div class="card card-default" id="AdditionalShipment" *ngIf="IsAdditionalShipmentUpload">
    <div class="card-header">
      <h3 class="card-title MoodysTittleColor" style="vertical-align: middle;">Upload Additional Shipment</h3>
      <div>
        <!-- <label style="color: gray;margin-left: 5px;font-weight: normal;font-size: small;"
          *ngIf="IsAuthorizeForRAShipment && IsRAApplicable">
          <input id="radioRAShip" style="margin-left: 10px;" [checked]='IsRAAdditionalShipment' name="uploadType"
            (change)="changeUploadType($event)" type="radio">
          RA Upload</label>
        <label style="color: gray;margin-left: 5px;font-weight: normal;font-size: small;"
          *ngIf="IsAuthorizeForDESShipment  && IsDESApplicable">
          <input id="radioDESShip" style="margin-left: 10px;" [checked]='!IsRAAdditionalShipment' name="uploadType"
            (change)="changeUploadType($event)" type="radio">
          DES Upload</label> -->
        <label style="color: gray;margin-left: 5px;font-weight: normal;font-size: small;"
          *ngIf="IsAuthorizeForDESShipment  && IsDESAddtionUpload">
          <input id="radioDESShip" style="margin-left: 10px;" [checked]='IsDESAddtionUpload' name="uploadType"
            (change)="changeUploadType_Dheeraj('3')" type="radio">
          DES Upload
        </label>
        <label style="color: gray;margin-left: 5px;font-weight: normal;font-size: small;"
          *ngIf="IsAuthorizeForRAShipment && IsRAAddtionUpload">
          <input id="radioRAShip" style="margin-left: 10px;" [checked]='IsRAAddtionUpload' name="uploadType"
            (change)="changeUploadType_Dheeraj('2')" type="radio">
          RA Upload
        </label>
        <label *ngIf="IsStandarAddtionUpload"
          style="color: gray;margin-left: 5px;font-weight: normal;font-size: small;">
          <input id="radioDESShip" style="margin-left: 10px;" name="uploadType" (change)="changeUploadType_Dheeraj('1')"
            [checked]='IsStandarAddtionUpload' type="radio">
          Standard Upload
        </label>

      </div>
    </div>
    <div class="card-body">
      <app-upload-additional-shipment [AccountData]="editData" [IsRAAdditionalShipment]="IsRAAdditionalShipment"
        [PackageType]="PackageType" (closeAdditionalUpload)="closeAdditionalShipmentUpload($event)">
      </app-upload-additional-shipment>
    </div>
  </div>
  <!-- Upload Shipment Package -->
  <!-- <div class="card card-default" id="UploadShipmentPackage">
    <div class="card-header">
      <h3 class="card-title" style="color: rgb(0, 123, 151); vertical-align: middle;">Upload Shipment</h3>
    </div>
    <div class="card-body">
      <app-upload-shipment-package  *ngIf="IsuploadShipmentPackage"  [AccountData]="editData"></app-upload-shipment-package>
    </div>
  </div> -->
  <!-- advanced filter -->
  <section class="content" *ngIf="showFilter">
    <app-advanced-filter-new (found)="handleResults($event)"></app-advanced-filter-new>
  </section>

  <!-- Unless code, commented by Kshitij, Please discuss before uncommenting  -->
  <!-- <section class="content" *ngIf="showDownloadQueue">
    <div class="container-fluid">
      <div class="card card-default">

        <div class="card-header">
          <h3 class="card-title" style="color: rgb(0, 123, 151);">Download Queue</h3>
        </div>
        <div class="card-body" id="Progress">
          <div class="table-responsive text-nowrap">
            <table class="table-hover table-bordered font14 noPad" style="width: 100%;">
              <thead>
                <tr class="th-sm RMSTableHeader">

                  <th>Account Name</th>
                  <th>Account Type</th>
                  <th>Work Type</th>
                  <th>Priority</th>
                  <th>Business Unit</th>

                  <th>Progress</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                <tr class="RMSTableContent" *ngFor="let item of downloadQueue">
                  <ng-container>

                    <td>{{item["account Name"]}}</td>
                    <td>{{item["account Type"]}}</td>
                    <td>Cleansing</td>

                    <td>{{item.priority}}</td>
                    <td>{{item["business Unit"]}}</td>

                    <td>

                      <div class="row" style="margin-left: inherit;">
                        <div class="Uploadprogress-cont col-sm-8" *ngIf="item.Progress > 0">
                          <div class="UploadProgress" [style.width]="item.Progress + '%'">
                            <label style="font-weight: normal;">{{item.Progress}}%</label>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div *ngIf="item.status==3">
                        <label style="font-weight: normal; color: #db202f;">{{item.Message}}</label>
                      </div>
                      <label *ngIf="item.status==1 || item.status==2"
                        style="font-weight: normal; color:green">{{item.Message}}</label>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <section class="content">
    <div class="container-fluid" style="padding: 0 !important;">
      <div class="card card-default">
        <div class="card-header">
          <h5 *ngIf="totalCount == 0">
            {{loadingMessage}}
          </h5>

          <div class="table-responsive text-nowrap table-wrapper " *ngIf="totalCount != 0">

            <table id="dataTable" class="table table-hover table-bordered font14 noPad" *ngIf="data" id="tabledetail">
              <!--class="StickyHeader -->
              <thead>
                <tr>
                  <th>#</th>
                  <th style="text-align: center; width: 20px;">Actions</th>
                  <ng-container *ngFor="let items of dynFieldheader | slice:10; let i = index">
                    <th class="th-sm" style="padding-left: 32px!important; cursor: pointer;"
                        
                        (click)="selectColumn(i,items)">
                      {{ items }}
                      <div class="btn-group-vertical">
                        <i class="fas fa-sort-amount-up-alt" *ngIf="i===columSelected && sortUP"></i>
                        <i class="fas fa-sort-amount-down-alt" *ngIf="i===columSelected && sortDesc"></i>
                      </div>
                    </th>
                  </ng-container>
                  <!-- <th class="th-sm" style="padding-left: 32px!important; cursor: pointer;"
                    *ngFor="let items of dynFieldheader | slice:10;  let i = index"  (click)="selectColumn(i,items)">
                  
                    {{ items }} 
                    <div class="btn-group-vertical">
                      <i class="fas fa-sort-amount-up-alt" *ngIf="i===columSelected && sortUP"></i>
                      <i class="fas fa-sort-amount-down-alt" *ngIf="i===columSelected && sortDesc"></i>
                    </div>
                  </th> -->
                </tr>
              </thead>
              <tbody>
                <!-- style="display: block; max-height: 500px;overflow-y: auto; overflow-x: hidden;"-->
                <tr *ngFor="let item of data; let i = index" id="tr_{{i}}" (click)="selectRow('tr_' + i)"
                  class="RmsTableRow">

                  <td>{{convertToInt(pageStart) + i + 1}}</td>
                  <td>
                    <!-- <button *ngIf="authorizedForEdit  && !isRmsUSer" class="btn btn-tool" title="Edit Account"
                      type="button" aria-expanded="false" (click)="EditAccount(item,target)" aria-controls="EditAccount"
                      [disabled]="item['statusID'] > '1'">
                      <i class="fa fa-edit" style="color:rgb(0, 123, 151)"></i>
                    </button> -->
                    <!-- <button *ngIf="authorizedForEdit && isRmsUSer" class="btn btn-tool" title="Edit Account"
                      type="button" aria-expanded="false" (click)="EditAccount(item,target)" aria-controls="EditAccount"
                      [disabled]="item['statusID'] == '8' || item['statusID'] == '9'">
                      <i class="fa fa-edit" style="color:rgb(0, 123, 151)"></i>
                    </button> -->
                    <!-- change here for the download functionality for item['statusID == 6 & 7'] -->
                    <button *ngIf="authorizedForDownload" class="btn btn-tool" title="Download Shipment Account"
                      type="button" [disabled]="item['statusID'] == '8' || item['statusID'] == '9'">
                      <!--[hidden]="item['account ID'] == rowNoForDownload && item['statusID'] != '6' || item['statusID'] != '7'"  -->
                      <i class="fa fa-download font15" title="Download Shipment Account"
                        [ngClass]="{'text-danger': item['statusID'] == '6' || item['statusID'] == '7' || item['statusID'] == '11'||
                         item['isDownloadFileExists'] }"
                        (click)="showDownloadAccount(item)" type="submit">
                      </i>
                      <!-- <span [hidden]="item['account ID'] != rowNoForDownload">{{percentDownload}}</span>
                      <i class="fas fa-spinner" title="Preparing File" style="color: green;"
                        *ngIf="item['account ID'] == rowNoForDownload && isDownloading && percentDownload < 1 "></i>
                      <i class="fas fa-check-circle" style="color: green;"
                        *ngIf="downloadDone && !isDownloading && item['account ID'] == rowNoForDownload && percentDownload < 1"></i> -->
                    </button>

                    <!-- <button *ngIf="authorizedForRaiseQuery" class="btn btn-tool" type="button" aria-expanded="false"
                      aria-controls="collapseExample" [disabled]="item['statusID'] == '8' || item['statusID'] == '9'">

                      <i *ngIf="item['queryStatus'] === '1'" class="fa fa-plus-circle font15"
                        style="color:rgb(196, 32, 50)" title="Raise Query" (click)='sendDataToMessageCenter(item, 0)'
                        type="submit">
                      </i>

                      <i *ngIf="item['queryStatus'] === '2'" class="fa fa-question-circle text-warning font15"
                        title="Open Query" type="submit" (click)='sendDataToMessageCenter(item, 1)'>
                      </i>

                      <i *ngIf="item['queryStatus'] === '3'" class="fa fa-question-circle text-success font15"
                        title="Closed Query" (click)='sendDataToMessageCenter(item, 1)' type="submit">
                      </i>
                      <i *ngIf="item['queryStatus'] === '4'" class="fa fa-plus-circle font15"
                        title="Closed/Canceled Query" (click)='sendDataToMessageCenter(item, 1)' type="submit">
                      </i>
                    </button> -->

                    <button type="button" class="btn btn-icon-toggle" title="Action" data-toggle="dropdown"
                      data-placement="top">
                      <i class="fa fa-cog" style="color:gray;" data-original-title="other Acionts"
                        aria-hidden="true"></i>
                    </button>
                    <div class="shadow" id=#dropdown>
                      <div class="dropdown-menu">
                        <ul>
                          <li class="li-header">
                            Choose an action
                          </li>
                          <li>
                            <i class="fa fa-history text-warning font15" title="Audit History" type="submit"
                              data-toggle="modal" data-target="#exampleModalCenter" (click)="showAudit(item)">
                              <span class="Icon-desc">Audit History</span>
                            </i>
                          </li>
                          <!-- <li [hidden]="item['statusID'] == '8' || item['statusID'] == '9'"
                            *ngIf='authorizedForUploadAdditionSOV && item.statusID!=6||item.statusID==8||item.statusID==9'>
                            <i title="Upload Additional SOV" aria-expanded="false" aria-controls="UploadAdditional"
                              class="fa fa-upload text-danger font15" (click)="AdditionalUpload(item,target)">
                              <span class="Icon-desc">Upload Additional SOV</span>
                            </i>
                          </li> -->
                          <li *ngIf='authorizedForDownloadSOV'>
                            <!-- <i class="fa fa-download text-danger font15" title="Download SOV" (click)="prepareSOV(item)"
      type="submit">
      <span class="Icon-desc">Download SOV</span>
    </i> -->
                            <i class="fa fa-download text-danger font15" title="Download Original SOV" type="submit"
                              (click)="getAdditionalfiletoDownload(item)">
                              <span class="Icon-desc">Download Original SOV</span>
                            </i>
                          </li>
                          <!-- <li
                            *ngIf="authorizedForUploadShipment && (userdetails == 1 || userdetails == 2) && (item.statusID != 8 && item.statusID != 9 && item.statusID != 10)">
                            <i class="fa fa-upload text-primary font15" title="Upload Shipment Package" type="submit"
                              (click)="UploadShipmentPackage(item)" routerLink="/uploadshipment">
                              <span class="Icon-desc">Upload Shipment Package</span>
                            </i>
                          </li> -->
                          <!-- <li *ngIf="IsAuthorizedForAdditionalShipment && (userdetails == 1 || userdetails == 2) && (item.statusID != 8 && item.statusID != 9 && item.statusID != 10)">
                            <i class="fa fa-upload text-primary font15" title="Upload Shipment Package" type="submit"
                               (click)="AdditionalShipmentUpload(item,target)">
                              <span class="Icon-desc">Upload Additional Shipment Package</span>
                            </i>
                          </li> -->
                          <!-- <li
                            *ngIf="IsAuthorizedForAdditionalShipment && (userdetails == 1 || userdetails == 2) && (item.statusID != 8 && item.statusID != 9 && item.statusID != 10)">
                            <i class="fa fa-upload text-primary font15" title="Upload Shipment Package" type="submit"
                              (click)="AdditionalShipmentUpload_Dheeraj(item,target)">
                              <span class="Icon-desc">Upload Additional Shipment Package</span>
                            </i>
                          </li> -->

                          <!-- <li *ngIf='authorizedForCancelAccount && 6>item.statusID||item.statusID==11 '> -->
                          <!-- <li
                            *ngIf='authorizedForCancelAccount && ((item.statusID == 1 && isRmsUSer==false) ||
                          (isRmsUSer==true && item.statusID !=6 && item.statusID !=7 && item.statusID !=8 && item.statusID !=9 && item.statusID !=11 && item.statusID!=10 )) '>
                            <i [ngClass]="CheckStataus(item)" title="Cancel" type="submit"
                              (click)="CancelAccount(item)">
                              <span class="Icon-desc">Cancel Account</span>
                            </i>
                          </li> -->
                          <!-- <li *ngIf="item['package Type'] === 'Standard' && !isRmsUSer">
                          <i class="fa fa-edit" style="color:rgb(0, 123, 151)" title="Upload Shipment Package" aria-controls="EditAccount"
                          (click)="EditPackageType(item,target)">
                            <span class="Icon-desc">Edit Package Type </span>
                          </i>
                        </li>
                          <li *ngIf='authorizedForCloseAccount && userdetails>2 && item.statusID==6|| item.statusID==7'>
                            <i class="fa fa-ban text-danger font15" title="close" type="submit"
                              (click)="CloseAccount(item)">
                              <span class="Icon-desc">Close Account</span>
                            </i>
                          </li>
                          <li *ngIf='authorizedForActivateAccount && (item.statusID==8 || item.statusID==9)'>
                            <i class="fa fa-check text-success font15" title="Activate Account" type="submit"
                              (click)="ReActivateAccount(item)">
                              <span class="Icon-desc">Reactivate Account</span>
                            </i>
                          </li> -->
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td *ngFor="let column of dynFieldheader | slice:10" class="set">
                    <div
                    *ngIf="column != 'status'&& column !='upload Date' && column !='expected Shipment' && column != 'actual Shipment'
                    && column!='special Instructions'&& column!='comments' && column != 'incept Date' && column != 'expiry Date' && column != 'file Name' && column != 'original TIV' && column != 'cleaned TIV'  ">
                    <span>{{ item[column] }}</span>
                    </div>
                    <div *ngIf="column == 'status'">

                      <span Class="badge  badge-settings" [ngClass]="item['statusClass']">{{item[column] }}</span>

                    </div>
                    <div
                      *ngIf="column == 'upload Date' ||  column == 'expected Shipment' || column == 'actual Shipment' ">

                      <span>{{ item[column]|date:'dd-MMM-yyyy hh:mm a' }}</span>

                    </div>

                    <div *ngIf="column == 'expiry Date'  || column == 'incept Date' ">

                      <span>{{ item[column]|date:'dd-MMM-yyyy' }}</span>

                    </div>

                    <div title="View Instructions" *ngIf="column == 'special Instructions' " style="cursor: pointer;"
                      (click)="instructionsPopup(item)" >

                      <span>{{ item[column]}}</span>

                    </div>
                    <div title="View Comments" *ngIf="column == 'comments' " style="cursor: pointer;"
                      (click)="commentPopUP(item)">

                      <span>{{ item[column]|slice:0:20}}</span>

                    </div>
                    <div title="Full file name" *ngIf="column == 'file Name' " style="cursor: pointer;"
                      (click)="fileNamePopUp(item)">

                      <span>{{ item[column]|slice:0:30}}<span *ngIf='item[column].length > 30'>....</span></span>

                    </div>
                    <div *ngIf="column == 'original TIV' ">
                      <span>{{ item[column]| number }}</span>
                    </div>
                    <div *ngIf="column == 'cleaned TIV'">
                      <span>{{ item[column]| number }}</span>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- sorting html  -->
          <div class="row" style="margin-top:10px;" *ngIf="totalCount != 0">
            <div class="col-sm-4" style="align-items: center;text-align: center;">
              <label style="font-weight: normal; float: left;font-size: smaller; margin-right: 5px;">Show</label>
              <select title="Records to show" [(ngModel)]="pageSize" class="form-control selectRecords" style="width: 3rem; height: 22px;padding: 0px;padding-left: 2px;
                font-size: smaller;float: left;text-align: center;" (change)="Records($event.target.value)">
                <!-- <option value="null">--Records--</option> -->
                <!-- <option value="1" > 1 </option> -->
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100" selected=true>100</option>
                <!-- <option value="500">500</option>
                <option value="all">All</option> -->
              </select>
              <label style="font-weight: normal; float: left;font-size: smaller; margin-left: 5px;">Records</label>
            </div>
            <label class="col-sm-4" style="font-size: smaller;text-align: center;font-weight: normal;">
              Showing
              {{pageStart+1}} to {{pageStart+rowCount}} of {{totalCount}} Records
            </label>
            <div class="col-sm-4">
              <nav aria-label="Page navigation" class="float-right">
                <ul class="pagination float-right">
                  <li class="page-item">
                    <a class="RMSButtonPageNavigation page-link " (click)="PageChange(1)" aria-label="First">
                      <label class="fa fa-angle-double-left"
                        style=" margin-left: -4px; font-size: small !important; font-weight: bold !important;"></label>
                      <!-- <span class="sr-only">Previous</span> -->
                    </a>
                  </li>
                  <li class="page-item">
                    <a (click)="PageChange(currentPage-1)" class="RMSButtonPageNavigation page-link"
                      aria-label="Previous">
                      <label class="fa fa-angle-left"
                        style="font-size:small !important;font-weight:bold !important;"></label>
                    </a>
                  </li>
                  <li class="page-item" *ngIf=previousDotShow>
                    <label class="RMSButtonPageNavigation" style="font-weight:bold !important;padding-top: 9px;">...</label>
                  </li>
                  <li *ngFor="let i of item">
                    <a (click)="PageChange(i)" [ngClass]="{'selectedPage':i===selectedPageNo}"
                      class="RMSButtonPageNavigation page-link">
                      <label style="vertical-align: sub !important;font-size: small;margin-left: -4px; ">{{ i }}</label>
                    </a>
                  </li>
                  <li class="page-item" *ngIf=nextDotShow>
                    <!-- <a   class="RMSButtonPageNavigation page-link text-info" >                   -->
                    <label class="RMSButtonPageNavigation" style="font-weight:bold !important;padding-top: 9px;">...</label>
                    <!-- </a> -->
                  </li>
                  <li class="page-item">
                    <a (click)="PageChange(currentPage+1)" class="RMSButtonPageNavigation page-link"
                      aria-label="Next">
                      <label class="fa fa-angle-right"
                        style="font-size:small !important;font-weight:bold !important;"></label>
                    </a>
                  </li>
                  <li class="page-item">
                    <a (click)="PageChange(pageCount)" class="RMSButtonPageNavigation page-link"
                      aria-label="Last">
                      <label class="fa fa-angle-double-right"
                        style="margin-left: -4px;font-size:small !important;font-weight:bold !important;"></label>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <!-- sorting end -->
        </div>
      </div>
    </div>
  </section>
</div>




<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 750px;">
    <!-- <div *ngIf="isValidifOpenModalForAudit; then AuditModal else DownloadModal"></div> -->
    <!-- <ng-template #AuditModal> -->
    <div class="modal-content">
      <div class="modal-header" style="display: inline !important;">
        <div class="modal-title row">
          <h6 class="col float-left">Audit History</h6>
          <button type="button" id="btnModalClose" data-dismiss="modal" style="padding-top: 2px;border-radius: 3px; max-width: 60px;;
              max-width: fit-content;margin-right: 10px;padding-bottom: 2px;margin-top: 10px;"
            class="col float-right btn btn-primary RMSSubmitButton">
            Close
          </button>
        </div>
        <hr />
        <div class="row font11">
          <div class="d-inline-block col-lg-6">
            <label class="col-form-label d-inline-block col-lg-4">Account Name :</label>
            <div class="col-lg-8 d-inline-block">
              <span class="label label-primary Audit-Label"> {{ auditAccName }}</span>
            </div>
          </div>
          <!-- <div class="d-inline-block col-lg-6">
            <label class="col-form-label d-inline-block col-lg-4">Account Type</label>
            <div class="col-lg-8 d-inline-block">
               <span class="label label-primary Audit-Label">LI API</span>
            </div>
          </div> -->

          <!-- <div class="d-inline-block col-lg-6">
            <label class="col-form-label d-inline-block col-lg-4">Business Unit</label>
            <div class="col-lg-8 d-inline-block">
              <span class="label label-primary Audit-Label"> {{ auditBU }}</span>
            </div>
          </div> -->
          <!-- <div class="display-inline col-lg-6">
            <label class="col-form-label d-inline-block col-lg-4">Work Type</label>
            <div class="col-lg-8 d-inline-block">
               <span class="label label-primary Audit-Label"> {{ auditWorkType }}</span>
            </div>
          </div> -->
        </div>
        
      </div>
      <div class="modal-body table-responsive" style="max-height: 220px;overflow-x: auto;">
        

        <table class="table table-bordered font11">
          <thead>
            <tr>
              <th class="text-left" scope="col">#</th>
              <th class="text-left" scope="col">Time Stamp</th>
              <th class="text-left" scope="col">User Name</th>
              <th class="text-left" scope="col">Activity</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of auditData; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ item["time Stamp"]|date:'dd-MMM-yyyy hh:mm a' }}</td>
              <td>{{ item["user Name"] }}</td>
              <td>
                {{
                  item["activity"] === 'Account Downloaded by the Cleansing Team' ? ' Data Downloaded for Processing' :
                  item["activity"] === 'Account Uploaded by Client' ? 'Data Uploaded by Client' :
                  item["activity"] === 'Account Shipped to Client' ? 'Data Processing Completed' :
                  item["activity"] === 'Account Downloaded by Client' ? 'Data Downloaded by Client' :
                  item["activity"] === 'Moved to Cleansing' ? 'Data Processing Started' :
                  item["activity"].replace("Account", '')
                }}
              </td>
            </tr>
            <!--
                            <tr>
                                <th scope="row">4</th>
                                <td>2019-08-12 08:08:25</td>
                                <td>2019-08-12 23:38:25</td>
                                <td>PenTester1_Client1</td>
                                <td>Account Uploaded by Client</td>
                            </tr> -->
          </tbody>
        </table>
      </div>
      <!-- <div class="modal-footer"> -->
      <!-- <button type="button" id="btnModalClose" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button> -->
      <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      <!-- </div> -->
    </div>
  </div>


</div>



<!--  *ngIf="showDownloadOptions" -->
<div class="modal" *ngIf="ifOpenModalForAudit" style="display: block" id="modalDownload" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitl1e" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 300px;">
    <div class="modal-content">
      <!-- <div class="modal-header" style="display: inline !important;"> -->
      <h6 class="modal-title li-header">Choose which file to download for {{DownloadshippemntAccountNmae}}</h6>

      <div class="row font11" style="margin:5 !important;">

        <ul style="list-style: none;">
          <li>
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="defaultchecked" name="2"
                (change)="selectFileToDownload('all',null)" checked>
              <label class="custom-control-label" for="defaultchecked">All</label>
            </div>
          </li>
          <li *ngFor="let items of accounTypeToDownload ;  let i = index">
            <div class="custom-control custom-radio">
              <input type="radio" (change)="selectFileToDownload('selected', items)" class="custom-control-input"
                id="{{i}}" name="2">
              <label class="custom-control-label" for="{{i}}">{{items.options}} </label>
            </div>
          </li>
        </ul>
      </div>

      <div class="modal-footer">


        <button type="button" class="btn btn-primary RMSSubmitButton" (click)="selectPackageFile()">
          Download
        </button>

        <button type="button" (click)="closeMultiDownload()" class="btn btn-secondary RMSButtonCancel"
          data-dismiss="modal">
          Close
        </button>

      </div>
    </div>

  </div>
</div>
<!-- 

code for modal ...........................................................................-->









<!-- Button trigger modal -->

<!-- Modal -->


<div [ngStyle]="{'display':display}" class="modal" id="additionalPopUpModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 300px;">
    <div class="modal-content">
      <!-- <div class="modal-header" style="display: inline !important;"> -->
      <h6 class="modal-title li-header">Choose file to download for {{accountName}} </h6>

      <div class="row font11" style="margin:5 !important;">

        <ul style="list-style: none;">
          <li>
            <div class="custom-control custom-radio">
              <input type="radio" class="custom-control-input" id="defaultchecked" name="AdditionalSOV"
                (change)="downloadFile('downloadAll','')" checked>
              <label class="custom-control-label" for="defaultchecked">All</label>
            </div>
          </li>
          <li>
            <div class="custom-control custom-radio" *ngFor="let item of sovDownload; let i = index">
              <input type="radio" class="custom-control-input" name="AdditionalSOV" id="SOV{{i}}"
                (change)="downloadFile(item,i)">
              <label class="custom-control-label" for="SOV{{i}}"> SOV</label>
            </div>
          </li>
          <li *ngFor="let items of aditionalfiles; let i = index">
            <div class="custom-control custom-radio">
              <input type="radio" (change)="downloadFile(items,i+1)" class="custom-control-input"
                id="AdditionalSOV{{i}}" name="AdditionalSOV">
              <label class="custom-control-label" for="AdditionalSOV{{i}}">Additional SOV{{i+1}} </label>
            </div>
          </li>

        </ul>
      </div>

      <div class="modal-footer">


        <button type="button" class="btn btn-primary RMSSubmitButton" (click)="downloadAccount()">
          Download
        </button>

        <button (click)="closemodal()" type="button" class="btn btn-secondary RMSButtonCancel" data-dismiss="modal">
          Close
        </button>

      </div>
    </div>

  </div>
</div>

<!-- ..........................modal for special instructions............................. -->

<div [ngStyle]="{'display':dispayForinstructionPopup}" class="modal">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-header">

        <h4 class="modal-title">{{instructionsHeader}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)='closeModal()'>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <textarea class="form-control" rows="7" style="background-color: white;" readonly
          [innerHtml]="completeInstructions"></textarea>
      </div>
      <div class="modal-footer">
        <div class="btn-group">
          <button class="btn btn-danger" (click)='closeModal()' data-dismiss="modal"><span
              class="glyphicon glyphicon-remove"></span>
            Close</button>
          <!-- <button class="btn btn-primary"><span class="glyphicon glyphicon-check"></span> Save</button> -->
        </div>
      </div>

    </div><!-- /.modal-content -->
  </div><!-- /.modal-dalog -->
</div><!-- /.modal -->