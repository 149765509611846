import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataShareService {
  //upload data list
  private currentListSource = new BehaviorSubject([]);
  private subscription = new BehaviorSubject([]);
  private retryList = new BehaviorSubject([]);
  private uploadedCount = new BehaviorSubject(0);
  private AccountType = new BehaviorSubject('');
  private userTimeZone = new BehaviorSubject('');

  userTimeZone$ = this.userTimeZone.asObservable();
  AccountType$ = this.AccountType.asObservable();
  currentList$ = this.currentListSource.asObservable();
  subscription$ = this.subscription.asObservable();
  retryList$ = this.retryList.asObservable();
  uploadedCount$ = this.uploadedCount.asObservable();
  //uploadshipment data list  
  private uploadedShipmentCount = new BehaviorSubject(0);
  private uploadShipmentList = new BehaviorSubject([]);
  uploadShipmentList$ = this.uploadShipmentList.asObservable();
  uploadedShipmentCount$ = this.uploadedShipmentCount.asObservable();
  //Process tracking data share
  private AccountId = new BehaviorSubject(0);
  AccountId$ = this.AccountId.asObservable();
  private AccountName = new BehaviorSubject('');
  AccountName$ = this.AccountName.asObservable();
  private SelectedRow = new BehaviorSubject('');
  SelectedRow$ = this.SelectedRow.asObservable();
  /// download shipment package
  private downloadShipmentPackage = new BehaviorSubject([]);
  currentDownload$ = this.downloadShipmentPackage.asObservable();
  private BulkdownloadShipment = new BehaviorSubject([]);
  bulkDwnldShipment$ = this.BulkdownloadShipment.asObservable();
  private BulkdownloadCount = new BehaviorSubject(0);
  BulkdownloadCount$ = this.BulkdownloadCount.asObservable();
  private ifBulkDownloadDivOpen = new BehaviorSubject(false);
  ifBulkDownloadDivOpen$ = this.ifBulkDownloadDivOpen.asObservable();
  private processTrackingFilter = new BehaviorSubject('');
  processTrackingFilter$ = this.processTrackingFilter.asObservable();
  private advanceFilter = new BehaviorSubject('');
  advanceFilter$= this.advanceFilter.asObservable();
  private recoreds = new BehaviorSubject('');
  recoreds$ = this.recoreds.asObservable();
  private selectedPage = new BehaviorSubject('');
  selectedPage$ = this.selectedPage.asObservable();
  private ifSideBarOpen = new BehaviorSubject(false);
  ifSideBarOpen$ = this.ifSideBarOpen.asObservable();

  private ifDownloadDivOpen = new BehaviorSubject(false);
  ifDownloadDivOpen$ = this.ifDownloadDivOpen.asObservable();

  private downloadCount = new BehaviorSubject(0);
  downloadCount$ = this.downloadCount.asObservable();

  private advanceFilterPill = new BehaviorSubject([]);
  advanceFilterPill$= this.advanceFilterPill.asObservable();
  constructor() { }
  updateListProcesses(list: any[], uploadedCount: any[], retry: any[], count: number) {
    this.currentListSource.next(list)
    this.subscription.next(uploadedCount);
    this.retryList.next(retry);
    this.uploadedCount.next(count);
  }
  updateShipmentProcess(list: any[], count: number) {
    this.uploadShipmentList.next(list);
    this.uploadedShipmentCount.next(count);
  }

  shareProcessTrackingData(AccountId: number, AccountName: string) {
    this.AccountId.next(AccountId);
    this.AccountName.next(AccountName);
  }

  downloadShipmentProcess(list: any[]) {
    this.downloadShipmentPackage.next(list);
  }
  BulkdownloadShipmentProcess(list: any[]) {
    this.BulkdownloadShipment.next(list);
  }
  SetBulkDownloadCount(count: number) {
    this.downloadCount.next(count);
  }
  IfBulkDownloadDivOpen(ifOpen: boolean) {
    this.ifBulkDownloadDivOpen.next(ifOpen);
  }
  shareProcessTrackingFilter(filter: string) {
    this.processTrackingFilter.next(filter);
  }
  shareProcessTrackingAdvanceFilter(filter: string) {
    this.advanceFilter.next(filter);
  }
  AdvanceFilterPill(pill: any[]) {
    this.advanceFilterPill.next(pill);
  }

  SelectedRowProcessTracking(row: string) {
    this.SelectedRow.next(row);
  }

  ShareAccountType(AccountType: string) {
    this.AccountType.next(AccountType);
  }

  IfSideBarOpen(ifOpen: boolean) {
    this.ifSideBarOpen.next(ifOpen);
  }

  SetDownloadCount(count: number) {
    this.downloadCount.next(count);
  }

  IfDownloadDivOpen(ifOpen: boolean) {
    this.ifDownloadDivOpen.next(ifOpen);
  }

  setUserTimezone(userTimeZone) {
    this.userTimeZone.next(userTimeZone);
  }
  setRecordCount(value: any) {
    this.recoreds.next(value);
  }
  setPageNo(value: any) {
    this.selectedPage.next(value);
  }
}
