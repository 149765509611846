<div class="row" style="margin-left: 20px;">
  <div class="col-4">
    <span>Account Name :</span>
    <span style="margin-left: 30px;font-weight: normal;">{{AccountName}}</span>
  </div>

  <div class="col">
    <div class="form-check form-check-inline">
      <input class="form-check-input" [disabled]="true" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
      <label class="form-check-label" for="inlineRadio1">Standard</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="radio" [disabled]="disableCustomizeRadio"  name="inlineRadioOptions" id="inlineRadio1" value="option1" (click)="setPackageType(2)">
      <label class="form-check-label" for="inlineRadio1">Customize</label>
    </div>
    <div class="form-check form-check-inline" style="margin-left:10px;">
      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"(click)="setPackageType(3)">
      <label class="form-check-label" for="inlineRadio2">Standard & Customize</label>
    </div>   
    <label style="font-weight: normal; color:red;overflow-wrap: break-word;">{{PackageTypeError}}</label>
  </div>
  </div>

<form class="form-row" [formGroup]="form">
  <div class="col" style="width: 100%; margin-left: 20px;">
    <div style="width: 140px;">
      <label style="font-weight: normal;">Attach Additional Files</label>
    </div>
    <div appDnd style="text-align: center;width: 100%;" (fileDropped)="onFileDropped($event)">
      <div class="container">
        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
        <div class="dragDrop">
          <div class="row">
            <img class=" col-sm-2 d-none d-sm-block" src="assets/svg/ic-upload-file.svg"
              style="width: 30px;height: 30px;">
            <h3 class="col-sm-8" style="text-align: center;">Drag and drop file here</h3>
          </div>
          <div class="row" style="text-align: center;">
            <h3 class="col">or</h3>
          </div>
          <div class="row" style="text-align: center;">
            <div class="col"></div>
            <label class="col" for="fileDropRef" style="width: 30%; height: 20%;border-radius: 21.5px; color: white;
              background-color: #db202f;padding: 8px 16px;">Browse for file</label>
            <div class="col"></div>
          </div>
        </div>
      </div>

      <div class="files-list" style="max-height: 200px; scroll-behavior: auto; overflow-y: scroll;">
        <div class="single-file" *ngFor="let file of fileList; let i = index">
          <img src="assets/svg/ic-file.svg" width="45px" alt="file">
          <div class="info">
            <h4 class="name">
              {{ file?.name }}
            </h4>
            <p class="size">
              {{ formatBytes(file?.size) }}
            </p>
            <div class="progress-cont">
              <div class="progress" [style.width]="file?.progress + '%'">
              </div>
            </div>
          </div>
          <img src="assets/svg/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
        </div>
      </div>
    </div>
    <label style="font-weight: normal; color:red;overflow-wrap: break-word;">{{fileSizeError}}</label>
  </div>
  <!-- <div class="form-row" [formGroup]="form" style="width:100%;white-space: pre;"> -->
  <div class="col" style="width: 100%; margin-left: 20px;margin-bottom: 10px;">
    <div style="width: 200px;">
      <label style="font-weight: normal;">Additional Special Instruction</label>
    </div>
    <div>
      <textarea formControlName="AdditionalInstruction" maxlength="4000" (ngModelChange)="showremainingcharacter();"
        style=" width: 100%;height: 125px;text-align: start;" #lengthOfText></textarea>
      <div class="form-row__description" style="color: #db202f;" *ngIf="isTyping">
        {{4000- lengthOfText.value.length }} character remaining
      </div>
    </div>
  </div>
  <!-- </div> -->
</form>
<div class="table-responsive text-nowrap" style="max-height: 200px !important;">
  <table class="table table-hover table-bordered font14 noPad text-nowrap">
    <thead>
      <tr>
        <th>Upload Type</th>
        <th>Instruction</th>
        <th>Files uploaded</th>
        <th>Uploaded By</th>
        <th>Upload Date</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let file of AdditionalUploadedFiles;">
        <td>
          <label style="font-style:normal;font-weight: normal;" *ngIf="file['isAdditional']==1">Additional</label>
          <label style="font-style:normal;font-weight: normal;" *ngIf="file['isAdditional']==0">Initial</label>
        </td>
        <td>
          <label style="font-style:normal;font-weight: normal;">{{file['instruction']}}</label></td>
        <td>
          <label style="font-style:normal;font-weight: normal;">{{file['fileName']}}</label></td>
        <td>
          <label style="font-style:normal;font-weight: normal;">{{file['uploadedBy']}}</label></td>
        <td>
          <label style="font-style:normal;font-weight: normal;">{{file['uploadDate']|date:'dd-MMM-yyyy hh:mm a'}}</label></td>
      </tr>
    </tbody>
  </table>
</div>
<div class="row" style="margin-left: 15px;margin-top: 10px; float: left;">
  <button type="button" class="btn btn-primary RMSSubmitButton" [disabled]="UpdateDisabled"
    style="margin-bottom:10px; margin-right: 10px;" (click)="UpdateAccount()">Update</button>
  <button type="button" class="btn btn-primary RMSButtonCancel" [disabled]="UpdateDisabled" style="margin-bottom:10px;"
    (click)="close()">Close</button>
</div>