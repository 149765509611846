import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RoleManagementServiceService } from 'src/services/AccessManagement/role-management-service.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AlertService } from 'src/services/Common/alert.service';
import { AlertMessages } from 'src/common/alert-messages';
import { ErrorHandler } from 'src/common/error-handler';
import { ExportService } from 'src/services/Common/export.service';
import { PopupService } from 'src/services/Common/popUp.service';
import { CommonMethod } from 'src/common/CommonMethod';
import { AuthenticationService } from 'src/services/Common/authentication.service';
import { MenuList } from 'src/common/menuList.enum';
import { Router } from '@angular/router';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { FeatureMaster } from 'src/common/feature-master';
@Component({
  selector: 'app-role-management',
  templateUrl: './view-role.component.html',
  styleUrls: ['./view-role.component.css']
})
export class ViewRoleComponent implements OnInit {

  //#region variables

  AddRoleView: boolean;
  fieldHeader: string[];
  fieldKey: string[];
  fieldKeyVal: string[];
  modalTitle: string;
  IsNewRole: boolean;
  editData;
  pageSize: number = 100;
  pageStart: number = 0;
  totalCount: number = 0;
  item: number[] = [];
  rowCount: number = 0;
  data: any;
  pageCount: number;
  currentPage: number = 1;
  nextDotShow: boolean = false;
  previousDotShow: boolean = false;
  selectedStatus: number = 1;
  compareUrl: any;
  selectedPageNo: any = 1;
  obj: CommonMethod = new CommonMethod();
  errorHandler = new ErrorHandler(this.alertService);
  @ViewChild('tblDetail', { static: false }) tblDetail: ElementRef;
  allowAddEditAction: boolean;
  allowActiveAction: boolean;
  addEditHeader: boolean;
  //#endregion
  //#region Constructor
  constructor(private service: RoleManagementServiceService, private popUp: PopupService, private alertService: AlertService,
    private exportFile: ExportService, private userInfo: AddEditUserSerivceService) {
    //  this.obj.checkAccess(MenuList.ManageRole);
  }
  //#endregion

  //#region Methods

  ngOnInit() {


    this.item = [];
    this.GetAllRoles(1);
    this.GetTotalCount();
    this.CheckAuthorized();
    // if (CommonMethod.checkAccess( this.compareUrl,MenuList.ManageRole)) {

    // }
    // else {
    //   //alert("no access")
    //   this.router.navigate(["/login-test"]);
    // }

  }
  GetSelectedStatus(value: any) {
    this.selectedStatus = value;
    this.ngOnInit();

  }
  /** Method to deactivate role */
  public Deactivate(value: any) {
    if (this.allowActiveAction) {
      let action: string;
      if (value.status) {
        action = "deactivate";
      }
      else {
        action = "activate";
      }
      this.popUp.Confirmation("Are you sure?", value['role Name'] + " role will " + action, 'Yes, ' + action, 'No, cancel').then((result) => {
        if (result.value) {
          let status: boolean = !value.status;
          let postData = new FormData();
          postData.append('RoleId', value.roleId);
          this.service.PostRequest(AppSettings.API_ENDPOINT + AppRelativeUrl.DEACTIVATEROLE, postData).subscribe(
            res => {
              this.ngOnInit();
              this.alertService.success(AlertMessages.ALERTMESSAGE13);
            }, err => {
              this.errorHandler.ErrorsHandler(err);
            }
          )
        }
        else {
          return;
        }
      });
    }
    else {
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
    }
  }
  /** Method to clear the fields */
  ClearField() {
    this.data = null;
    this.fieldHeader = [];//['#', 'Actions'];
    this.fieldKey = [];
    this.fieldKeyVal = [];

  }
  DownloadCSV() {
    let header = [];
    header = this.fieldHeader;
    header.unshift("role Name");

    this.exportFile.expotAsCSVFile(this.data, header, 'RoleList');
  }
  DownloadExcel() {
    let header = [];
    header = this.fieldHeader;
    let exportData = this.data;
    this.exportFile.generateExcel(header, exportData, "RoleList");
  }
  DownloadPDF() {
    this.exportFile.generatePdf(this.data, 'RoleList')
  }

  /** Method to get all role list */
  GetAllRoles(pageNumber: number) {
    if (pageNumber <= 1) {
      this.pageStart = 0;
    }
    else {
      this.pageStart = this.pageSize * (pageNumber - 1);
    }
    let formData: FormData = new FormData();
    formData.append('FilterData', '');
    formData.append('StartRow', String(this.pageStart));
    formData.append('RowLength', String(this.pageSize));
    formData.append('OrderFilter', '');
    formData.append('ExecType', '1');
    formData.append('SelectedStatus', String(this.selectedStatus));
    this.service.PostRequest(AppSettings.API_ENDPOINT + AppRelativeUrl.ROLECOMOPONENTDISPLAY, formData).subscribe(response => {
      this.ClearField();
      this.data = response;
      this.rowCount = this.data.length;
      this.fieldHeader = this.fieldHeader.concat(Object.keys(response[0]).slice(3, response[0].count));
      this.fieldKey = Object.keys(response[0]).slice(2, 3);
      this.fieldKeyVal = Object.keys(response[0]).slice(3, response[0].count);
    }, error => {
      this.errorHandler.ErrorsHandler(error);
    });
  }

  /** Method to handle the Add new role click event */
  AddNewRoleClick() {
    this.addEditHeader = false;
    this.modalTitle = 'Submit';
    this.IsNewRole = true;
    if (this.AddRoleView) {
      this.AddRoleView = false;
    }
    else {
      this.AddRoleView = true;
    }

  }

  /** Method to handle the edit roel click event */
  EditRoleClick(edit: [], el: HTMLElement) {
    this.addEditHeader = true;
    this.modalTitle = 'Update';
    this.editData = this.data.filter(entity => entity.roleId === edit["roleId"]);
    this.IsNewRole = false;
    this.AddRoleView = true;
    el.scrollIntoView();
  }

  /** Method to handle the close click for add new role */
  public CloseAddRole(value): void {
    this.AddRoleView = value;

  }

  /**Method to refresh the grid data  */
  public RefreshGridData(value): void {
    this.GetAllRoles(1);
  }

  /** Method to get the total pages count  */
  private GetTotalCount() {

    ////create form data
    let formData: FormData = new FormData();
    formData.append('FilterData', '');
    formData.append('StartRow', '0');
    formData.append('RowLength', '0');
    formData.append('OrderFilter', '');
    formData.append('ExecType', '2');
    formData.append('SelectedStatus', String(this.selectedStatus));
    this.service.PostRequest(AppSettings.API_ENDPOINT + AppRelativeUrl.ROLECOMOPONENTDISPLAY, formData).subscribe(result => {
      this.totalCount = result[0]['totalCount'];
      // console.log(this.totalCount);

      this.pageCount = (this.totalCount / this.pageSize);
      // alert(pageCount);
      if (this.pageCount <= 1) { this.pageCount = 1 }
      else if ((this.pageCount % 1) != 0)
        this.pageCount = Math.floor(this.pageCount) + 1;

      this.pageCount = Number(this.pageCount.toFixed());
      // alert(pageCount);

      for (let i = 1; i <= this.pageCount; i++) {
        if (i <= 5) {
          this.item.push(i);
        }
        else {
          this.nextDotShow = true;
        }
      }


    },
      error => {
        this.totalCount = -1;
        this.errorHandler.ErrorsHandler(error);
      });
  }

  /** Method to get the data depending upon pagination  */
  Records(record: string) {
    this.previousDotShow = false;
    this.nextDotShow = false;
    if (record != null) {
      if (record == 'all') {
        this.pageSize = this.totalCount;

        this.item = [];
        this.GetAllRoles(1);
        this.GetTotalCount();
        // alert(this.GetTotalCount());
      }
      else {
        this.pageSize = Number(record);
        this.item = [];
        this.GetAllRoles(1);
        this.GetTotalCount();
      }
    }
  }

  /** Method to handle the page change event */
  public PageChange(pageNumber: number) {
    this.selectedPageNo = pageNumber;
    this.previousDotShow = false;
    this.nextDotShow = false;
    if (pageNumber === 0)
      pageNumber = 1;
    if (pageNumber > this.pageCount)
      pageNumber = this.pageCount;
    this.currentPage = pageNumber;
    if (pageNumber === this.item[this.item.length - 1] && this.pageCount > this.item[this.item.length - 1]) {
      this.item.shift();
      this.item.push(this.item[this.item.length - 1] + 1);
      this.previousDotShow = true;
      this.nextDotShow = true;
      if (this.item[this.item.length - 1] == this.pageCount)
        this.nextDotShow = false;
    }
    else if (pageNumber == this.pageCount) {
      this.item = [];
      if (this.pageCount > 5) {
        for (let j = this.pageCount - 4; j <= this.pageCount; j++) {
          this.item.push(j);
        }
        this.nextDotShow = false;
        this.previousDotShow = true;
      } else {
        for (let j = 1; j <= this.pageCount; j++) {
          this.item.push(j);
        }
      }
    }
    else if (pageNumber == this.item[0] && this.item[0] > 5) {
      this.previousDotShow = true;
      this.nextDotShow = true;
      this.item.pop();
      this.item.unshift(pageNumber - 1);
    }
    else if (pageNumber <= 5) {
      this.previousDotShow = false;
      if (this.pageCount > 5) {
        this.nextDotShow = true;
      }
      let x = this.item.length;
      this.item = [];
      for (let j = 1; j <= x; j++) {
        this.item.push(j);
      }
    } else {
      this.previousDotShow = true;
      // this.nextDotShow=true;
    }
    if (this.pageCount < 5) {
      this.nextDotShow = false;
      this.previousDotShow = false;
    }
    this.GetAllRoles(pageNumber);

  }
  selectRow(trId: string) {
    //alert(trId)
    CommonMethod.selectRow(trId)
  }
  CheckAuthorized() {
    this.userInfo.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      if (res != null && res != "undefined") {
        this.allowAddEditAction = this.obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.AddEdit_Role);
        this.allowActiveAction = this.obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.Deactivate_Role);

      }
    },
      err => {
        this.errorHandler.ErrorsHandler(err);
      })
  }
  //#endregion
}
