import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MasterservcieService {

  constructor(private httpclient: HttpClient) { }
  GetMastersData(url: string, formdata: FormData) {
    return this.httpclient.post(url, formdata).pipe(map(res => {
      return res;
    }, err => {
      return err.json();
    }))

  }
  AddEditMaster(url: string, formdata: FormData) {
    return this.httpclient.post(url, formdata).pipe(map(res => {
      return res;
    }, err => {
      return err.json();
    }))
  }
}
