import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { GetClientList } from 'src/model/AccessManagement/GetClientList';
import { AlertService } from 'src/services/Common/alert.service';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertMessages } from 'src/common/alert-messages';
import { GetClientDetailsServiceService } from 'src/services/AccessManagement/get-client-details-service.service';
import { ExportService } from 'src/services/Common/export.service';
import { PopupService } from 'src/services/Common/popUp.service';
import { CommonMethod } from 'src/common/CommonMethod';
import { AuthenticationService } from 'src/services/Common/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuList } from 'src/common/menuList.enum';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { FeatureMaster } from 'src/common/feature-master';
// import{CommonMethod}
@Component({
  selector: 'app-view-client',
  templateUrl: './view-client.component.html',
  styleUrls: ['./view-client.component.css']
})
export class ViewClientComponent implements OnInit {
  ClientList: Array<GetClientList>;
  addclient: boolean;
  AddClientButton: boolean;
  errorHandler = new ErrorHandler(this.alertService);
  selectStatus: string = '1';
  search: string = '';
  pageSize: number = 20;
  pageStart: number = 0;
  totalCount: number = 0;
  item: number[] = [];
  getClient: any[] = [];
  allClient: any;
  rowCount: number = 0;
  searchInput: string = '';
  data: any[] = [];
  contractData: any;
  clientStatus: boolean;
  fieldHeader: any[] = [];
  pageCount: number;
  currentPage: number = 1;
  nextDotShow: boolean = false;
  previousDotShow: boolean = false;
  allowAddEdit: any;
  message: any;
  activateAccess: any;
  clientinfo: any;
  clientId: number;
  AddEditTitle: string;
  selectedRow: any;
  compareUrl: any;
  obj: CommonMethod = new CommonMethod();
  authorizedForDeactive: boolean;
  authorizedForAddEdit: boolean;
  showSearchValidationError = false;
  selectedPageNo: any = 1;
  clientElementMap: boolean = false;
  clientElementvalidationMap: boolean = false;
  passclientdetial: any;
  @Output() sendRoleData = new EventEmitter<string>();
  constructor(
    private route: ActivatedRoute,
    private getClientDetailsService: GetClientDetailsServiceService,
    private alertService: AlertService,
    private exportFile: ExportService, private popService: PopupService,
    private authenticationservice: AuthenticationService,
    private userInfo: AddEditUserSerivceService,
    private router: Router
  ) { }


  ngOnInit() {
    //  this.obj.checkAccess(MenuList.ManageClient)
    this.GetUserClientData(1);
    this.clientStatus = true;
    this.GetTotalCount();
    this.CheckAuthorization();
  }
  GetUserClientData(pageNumber: number) {
    this.data = [];
    if (pageNumber <= 1) {
      this.pageStart = 0;
    }
    else {
      this.pageStart = this.pageSize * (pageNumber - 1);
    }
    let formData: FormData = new FormData();
    formData.append('FilterData', this.search);
    formData.append('StartRow', String(this.pageStart));
    formData.append('RowLength', String(this.pageSize));
    formData.append('OrderFilter', '');
    formData.append('ExecType', '1');
    formData.append('SelectedStatus', this.selectStatus);


    this.getClientDetailsService.GetClientDetails(formData).subscribe(
      result => {
        this.allClient = result["table"];
        this.rowCount = result["table"].length;
        this.allowAddEdit = result["table1"][0]["allowAddEdit"]
        this.ShareEditContract(this.allowAddEdit);
        this.activateAccess = result["table2"][0]["allowActivate"]
        for (let i = 0; i < this.allClient.length; i++) {
          if (this.allClient[i].isActive) {
            this.data.push(this.allClient[i]);
          }
        }
        for (let i = 0; i < this.allClient.length; i++) {
          if (!this.allClient[i].isActive) {
            this.data.push(this.allClient[i]);
          }
        }

        this.fieldHeader = [];
        this.fieldHeader = this.fieldHeader.concat(Object.keys(result['table'][0]));

      },
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )

  }
  AddClient() {
    this.addclient = !this.addclient;
    this.clientinfo = null;
    this.AddEditTitle = "Add Client";

  }
  editClient(item: any) {
    this.clientElementMap = false;
    this.clientElementvalidationMap = false;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    if (item['clientID'] == '1' || item['clientID'] == '2' || item['is Active'] == 0) {
      this.addclient = false;
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
      return;
    }
    this.addclient = true;
    this.clientId = item['clientID'];
    this.AddEditTitle = "Edit Client";
    this.getClientDetailsService.GetEditData(this.clientId).subscribe(response => {
      this.clientinfo = response;


    },
      error => {
        this.errorHandler.ErrorsHandler(error);
      });

  }

  public CloseAddClient(value): void {
    this.addclient = false;
    this.AddClientButton = false;
    this.clientinfo = null;

  }

  public RefreshGridData(value): void {
    this.GetUserClientData(1)
  }
  deactivateClient(item: any, IsActive: boolean, clientName: string) {
    let clientId = item['clientID'];
    let action: string;
    if (item['clientID'] == '1' || item['clientID'] == '2') {
      this.addclient = false;
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
      return;
    }

    if (IsActive == false) {

      action = "Activate";
    }
    else {
      action = "Deactivate";

    }
    this.popService.Confirmation('Are you sure?', clientName + ' will be ' + action + '!', 'Yes, ' + action, 'No, Cancel').then(
      result => {
        if (result.value) {
          this.getClientDetailsService.deleteClientDetails(clientId, IsActive).subscribe(response => {
            this.ngOnInit();
          },
            error => {
              this.errorHandler.ErrorsHandler(error);
            });
        }
      }
    )
    this.GetUserClientData(1);
  }
  checkclientstatus(value) {
    this.data = [];
    this.item = [];
    switch (value) {
      case "all": this.selectStatus = '';
        this.GetUserClientData(1);
        this.GetTotalCount(); break
      case "active": this.selectStatus = '1';
        this.GetUserClientData(1)
        this.GetTotalCount();

        break;
      case "deactive": this.selectStatus = '0';
        this.GetUserClientData(1)
        this.GetTotalCount();

        break;
    }


  }


  public PageChange(pageNumber: number) {
    this.selectedPageNo = pageNumber
    if (pageNumber === 0)
      pageNumber = 1;
    if (pageNumber > this.pageCount)
      pageNumber = this.pageCount;
    this.currentPage = pageNumber;
    if (pageNumber === this.item[this.item.length - 1] && this.pageCount > this.item[this.item.length - 1]) {
      this.item.shift();
      this.item.push(this.item[this.item.length - 1] + 1);
      this.previousDotShow = true;
      this.nextDotShow = true;
      if (this.item[this.item.length - 1] == this.pageCount)
        this.nextDotShow = false;
    }
    else if (pageNumber == this.pageCount) {
      this.item = [];
      if (this.pageCount > 5) {
        for (let j = this.pageCount - 4; j <= this.pageCount; j++) {
          this.item.push(j);
        }
        this.nextDotShow = false;
        this.previousDotShow = true;
      } else {
        for (let j = 1; j <= this.pageCount; j++) {
          this.item.push(j);
        }
      }
    }
    else if (pageNumber == this.item[0] && this.item[0] > 5) {
      this.previousDotShow = true;
      this.nextDotShow = true;
      this.item.pop();
      this.item.unshift(pageNumber - 1);
    }
    else if (pageNumber <= 5) {
      this.previousDotShow = false;
      if (this.pageCount > 5) {
        this.nextDotShow = true;
      }
      let x = this.item.length;
      this.item = [];
      for (let j = 1; j <= x; j++) {
        this.item.push(j);
      }
    } else {
      this.previousDotShow = true;

    }
    if (this.pageCount < 5) {
      this.nextDotShow = false;
      this.previousDotShow = false;
    }
    this.GetUserClientData(pageNumber);

  }
  Records(record: string) {
    if (record != null) {
      if (record == 'all') {
        this.pageSize = this.totalCount;

        this.item = [];
        this.GetUserClientData(1);
        this.GetTotalCount();

      }
      else {
        this.pageSize = Number(record);
        this.item = [];
        this.GetUserClientData(1);
        this.GetTotalCount();

      }
    }
  }
  public GetTotalCount() {

    let formData: FormData = new FormData();
    formData.append('FilterData', this.search);
    formData.append('StartRow', '0');
    formData.append('RowLength', String(this.pageSize));
    formData.append('OrderFilter', '');
    formData.append('ExecType', '2');
    formData.append('SelectedStatus', this.selectStatus);

    this.getClientDetailsService.GetClientDetails(formData).subscribe(res => {

      this.totalCount = res['table'][0]['totalCount'];

      this.pageCount = (this.totalCount / this.pageSize);

      if (this.pageCount <= 1) { this.pageCount = 1 }
      else if ((this.pageCount % 1) != 0)
        this.pageCount = Math.floor(this.pageCount) + 1;

      this.pageCount = Number(this.pageCount.toFixed());


      for (let i = 1; i <= this.pageCount; i++) {
        if (i <= 5) {
          this.item.push(i);
        }
        else {
          this.nextDotShow = true;
        }
      }


    },
      error => {
        this.totalCount = -1;

        let apiError: string;
        if (error.status == 400) {
          apiError = String(error.error.detail);

        }
        else if (error.status == 415) {
          apiError = error.message;
        }
        else if (error.status == 401) {
          apiError = 'Unauthorized Access, please try to restart your browser and try again.';

        }
        else if (error.status == 0)
          apiError = 'Could not connect to the service.';
        else
          apiError = error.error;



      });
  }
  searchClient() {
    if (this.showSearchValidationError)
      return;

    if (this.searchInput != null || this.searchInput != '' || this.searchInput != undefined) {
      this.search = this.searchInput;
      this.item = [];
      this.GetUserClientData(1);
      this.GetTotalCount();
    }
    else {
      this.search = '';
      this.GetUserClientData(1);
    }

  }
  resetRecords() {
    this.showSearchValidationError = false;
    this.searchInput = '';
    this.search = '';
    this.item = [];
    this.GetUserClientData(1);
    this.GetTotalCount();
  }
  downloadExel() {

    this.getClientDetailsService.ExportData(String(this.totalCount), this.selectStatus).subscribe(res => {
      let data: any[] = res;
      let header = [];
      header = header.concat(Object.keys(data[0]));
      this.exportFile.generateExcel(header, data, "Client List");
    })
  }
  downloadCsv() {
    this.getClientDetailsService.ExportData(String(this.totalCount), this.selectStatus).subscribe(res => {
      let data = res;

      let header = [];
      header = header.concat(Object.keys(data[0]));
      this.exportFile.expotAsCSVFile(data, header, 'ClientList')
    })

  }
  downloadPdf() {
    this.getClientDetailsService.ExportData(String(this.totalCount), this.selectStatus).subscribe(res => {
      this.exportFile.generatePdf(res, 'Client List')
    })
  }

  ShareClientId(item: any, selectPage: any) {
    let clientId = item['clientID'];
    let clientName = item['client Name'];
    if (item['is Active'] == 0) {
      this.addclient = false;
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
      return;
    }
    if (selectPage == 1) {
      this.getClientDetailsService.SetClientId(clientId, clientName);
      this.router.navigate(['/contract']);
    }
    else {
      this.getClientDetailsService.SetClientId(clientId, clientName);
      this.router.navigate(['/usermanagement']);
    }

  }
  ShareEditContract(isAllowed: number) {
    this.getClientDetailsService.SetIsEditContract(isAllowed);
  }

  selectRow(trId: string) {
    CommonMethod.selectRow(trId)
  }
  CheckAuthorization() {
    this.userInfo.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      this.authorizedForAddEdit = this.obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.AddEdit_Client);
      this.authorizedForDeactive = this.obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.Deactivate_Client);
    })
  }

  checkSearch() {
    this.showSearchValidationError = this.obj.validateSearch(this.searchInput);
  }
  EditClientElementmapping(value) {
    this.addclient = false;
    this.clientElementvalidationMap = false;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    this.clientId = value['clientID'];
    this.passclientdetial = value;
    this.clientElementMap = true;
  }
  Closeclientelement(value) {
    this.clientElementMap = false;
    this.clientElementvalidationMap = false;
  }
  ElementValidationmapping(item) {
    this.addclient = false;
    this.clientElementMap = false;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    this.clientId = item['clientID'];
    this.passclientdetial = item;
    this.clientElementvalidationMap = true;
  }
  RequestNewUser(item) {
    this.popService.Confirmation('Do you want request new user?', 'Request!', 'Yes', ' Cancel').then(
      result => {
        if (result.value) {
          let formdata = new FormData();
          formdata.append('clientId', item['clientID']);
          this.getClientDetailsService.RequestNewUser(AppSettings.API_ENDPOINT+AppRelativeUrl.REQUESTNEWUSER, formdata).subscribe(response => {

            if (response) {
              this.alertService.success("Request has been send successfully");
            } else {
              this.alertService.error("Something wend wrong");
            }
          },
            error => {
              this.errorHandler.ErrorsHandler(error);
            });
        }
      }
    )
  }

}
