import '@angular/compiler'
import 'hammerjs';
import { enableProdMode, Injector } from '@angular/core';


import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { appInjector } from './common/Injector';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then((appRef: { injector: Injector }) => appInjector(appRef.injector))
  .catch(err => console.error(err));
