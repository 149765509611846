import { Component, AfterViewChecked, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DataShareService } from 'src/services/Common/dataShare.service';
import { UserSignService } from 'src/services/LoginServices/user-sign.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'RMS DsPortal Login';
  sideBarOpen = true;
  public showNav: boolean;
  isUndermaintenance:boolean;
  constructor(private cookieService: CookieService, private ShareData: DataShareService,private UserSignIn:UserSignService) {
 
  }

  ngOnInit() {
    // alert('App Component');
  
    this.ShareData.ifSideBarOpen$.subscribe(ifOpen => this.showNav = ifOpen);
  }
  // ngAfterViewChecked() {
  //   // setTimeout(() => {
  //   let showNav = this.IfUserLoggedIn();
  //   if (showNav != this.showNav) { // check if it change, tell CD update view
  //     this.showNav = showNav;
  //     // this.route.navigate(['/login']);
  //     this.cdRef.detectChanges();

  //   }
  //   // 
  //   // }, 700);
  // }

  SideBarToggler(t: any) {
    this.sideBarOpen = !this.sideBarOpen;
  }

  IfUserLoggedIn(): boolean {
    /// waiting for the ookies to be loaded to read once
    const timer: ReturnType<typeof setTimeout> = setTimeout(() => '', 700);
    let token = this.cookieService.get('ApiQuote');
    let firstTime = this.cookieService.get('FTLI');

    if (token === null || token === '') {
      // this.route.navigate(['/login']);
      return false;
    }
    else {
      if (firstTime === 'true')
        return false;
      else {
        return true;
      }

    }
  }


}
