export class AppRelativeUrl {
  public static FORGOT_PASSWORD = "login/ForgotPassword";
  public static LINK_EXPIRE = "login/ValidedResetPasswordToken";
  public static VALIDATE_GENERATEPASSWORDURL="Login/ValidateGeneratePasswordUrl";
  public static GENERATEPASSWORD="Login/GeneratePassword";
  public static RESET_PASSWORD = "login/ResetPassword";
  public static RESETUSERPASSWORD = "Users/ResetPassword";
  // public static CHANGE_PASSWORD = "Users/ChangePasswordFirst";
  public static CHANGE_PASSWORD = "login/ChangePassword";
  public static USER_CLIENT_INFO = "Users/GetUserClientInfoData";
  public static SAVEASUSER = "Users/SaveASUser";
  public static LOGIN = "login/Authenticate";
  public static LOGOUT = "login/Logout";
  public static FEEDBACK = 'login/SubmitFeedback'
  public static PROCESS_TRACKING = "ProcessTracking/GetProcessTrackingData";
  public static ACCOUNT_TRACKING = "Account/GetProcessTrackingData";
  public static EDITPROCESSTRACKING = "ProcessTracking/GetEditElementList";
  public static EDITPROCESSTRACKINGDATA = "ProcessTracking/GetEditElementListData";
  public static UPDATEACCOUNT = "ProcessTracking/UpdateAccount";
  public static UPDATEADDITIONALDETAILS = "ProcessTracking/UpdateAdditionalDetails";
  public static DELETEFILE = "ProcessTracking/DeleteFile";
  public static GETUPLOADEDFILESDETAILS = "ProcessTracking/GetUploadedFilesDetail";
  public static UPLOADADDITIONALFILE = "ProcessTracking/UploadAdditionalFile";
  public static GETACCOUNTSFORSHIPMENT = "UploadShipment/GetAccountForShipment";
  public static GETACCOUNTDATAFORSHIPMENT = "UploadShipment/GetAccountDataForShipment";
  public static UPLOADSHIPMENT = "UploadShipment/UploadShipmentPackage";
  public static GETADDITIONALSHIPMENTDETAILS = "ProcessTracking/GetAdditionalShipmentDetails";
  public static ROLECOMOPONENTDISPLAY = "AccessManagement/GetRoleComponentMapping";
  public static GETFEATURELIST = "AccessManagement/GetAllFeatureList";
  public static ADDROLE = "AccessManagement/AddEditRole";
  public static REFRESH_TOKEN = "login/RefreshAccessToken";
  public static ADDEDITCLIENT = "ClientMangement/SaveClientDetail";
  public static GETBUSSINESSDATA = "ClientManagement/GetBussinessUnit";
  public static GETCLIENTDETAILS = "ClientManagement/ViewClientDetails";
  public static GETCLIENTTELEMENTLIST="ClientManagement/GetClientElementList";
  public static GETALLOWEDFILEFORMATFORUPLOADACCOUNT = "UploadAccount/GetUploadFileFormatForUploadAccount";
  public static SSGETALLOWEDFILEFORMATFORUPLOADACCOUNT = "SsUploadData/GetUploadFileFormatForUploadAccount";
  public static GETELEMENTVALIDATIONMASTERDATA="ClientManagement/GetElementValidationMasterData";
  public static GETELEMENTVALIDATIONMAPPING="ClientManagement/GetClientElementValidationMapping";
  public static SAVEELEMENTVALIDATIONMAPPING="ClientManagement/SaveClientElementValidation";
  public static GETSPECIFICCLIENTDETAIL = 'Users/GetClientDetail';
  public static ADDEDITUSER = 'Users/AddEditUser';
  public static UPDATEUSERPROFILE = 'Users/UpdateUserProfile';
  public static EDITCLIENTDETAILS = 'ClientManagement/EditClientDetails';
  public static GetMenuInfo = 'Menu/Getmenu';
  public static GETNOTIFICATIONLIST = 'Users/GetNotificationList';
  public static UPDATEUSERNOTIFICATION = 'Users/UpdateUserNotifications';
  public static GETUSERINFO = "Users/GetUserInfo";
  public static UPLOADUIELEMENTLIST = "UploadAccount/GetUIElementList";
  public static GETACCOUNTBYPOLICYREF = "UploadAccount/GetAccountByPolicyRef";
  public static GETMAXFILESIZE = "UploadAccount/GetmaxFileSize";
  public static SSGETMAXFILESIZE = "SsUploadData/GetmaxFileSize";
  public static GETSUBSCRIPTIONDETAILS = "SsUploadData/GetSubscriptionDetails";
  public static GETALLOWEDFILEFORMAT = "UploadAccount/GetUploadFileFormat";
  public static UPLOADADDITIONALSHIPMENT = "ProcessTracking/UploadAdditionalShipment";
  public static GETLATEUPLOADMSG = "UploadAccount/LateUploadMessage"
  public static UPLOADACCOUNT = "UploadAccount/Upload";
  public static SSUPLOADACCOUNT = "SsUploadData/Upload";
  public static CREATEUPLOADJOB = "UploadAccount/CreateUploadJob";
  public static SSCREATEUPLOADJOB = "SsUploadData/CreateUploadJob";
  public static CANCELUPLOADJOB = "UploadAccount/CancelUploadJob";
  public static SSCANCELUPLOADJOB = "SsUploadData/CancelUploadJob";
  public static GETWORKTYPEONACCOUNTTYPE = "UploadAccount/GetWorkTypeOnAccountType";
  public static GETPACKAGEMASTER='UploadAccount/GetPackageMaster';
  public static ISALREADYUPLOADEDACCOUNT = "UploadAccount/IsAlreadyUploaded";
  public static SSISALREADYUPLOADEDACCOUNT = "SsUploadData/IsAlreadyUploaded";
  public static CHECKCONTRACTSTAUS ="SsUploadData/CheckContractStatus"
  public static ISSHOWRECENTACCOUNT = "UploadAccount/IsShowRecentAccount";
  public static GETCONTRACTDETAILS = 'ContractManagement/GetContractDetail';
  public static GETAUDITHISTORYBYACCOUNT = "ProcessTracking/GetAuditHistoryByAccount";
  public static GETAUDITHISTORY = "Account/GetAuditHistoryByAccount";
  public static GETADVANCEDFILTERCOLUMNS = "ProcessTracking/GetAdvancedFilterColumns";
  public static GETMULTISELECTCOLUMNS = "ProcessTracking/GetMultiSelectColumns";
  public static GETSAVEDFILTERVALUES = "ProcessTracking/GetSavedFilterValues";
  public static UPDATEADVANCEDFILTER = "ProcessTracking/UpdateFilterValues";
  public static GETSOVFILE = "ProcessTracking/DownloadSOVFile";
  public static GETSSSOVFILE = "Account/DownloadSOVFile";
  public static GETPACKAGEFILE = "ProcessTracking/DownloadShippedPackedFile";
  public static DOWNLOADZIPFILE = "ProcessTracking/DownloadInternalZipFile";
  public static SSDOWNLOADZIPFILE = "Account/DownloadInternalZipFile";
  // public static DOWNLOADACCOUNTFILE = "ProcessTracking/DownloadAccountByFileName";
  public static DOWNLOADACCOUNTFILE = "ProcessTracking/DownloadFileForShipment";
  public static DOWNLOADFILE = "ProcessTracking/DownloadFile";
  public static DELETEFILTER = "ProcessTracking/DeleteFilter";
  public static DELETETEMPZIP = "ProcessTracking/DeleteTempZipFile";
  public static SAVECONTRACTDETAILS = 'ContractManagement/SaveContractDetails';
  public static DELETECONTRACTDETAILS = 'ContractManagement/DeleteContract';
  public static EDITCONTRACTDETAILS = 'ContractManagement/GetEditContractDetails';
  public static GETCLIENTMAPPEDACCOUNTYPE = 'ContractManagement/GetClientMappedAccountType';
  public static SAVECLIENTINFO = 'ClientManagement/SaveClientDetail';
  public static DELETECLIENTDETAILS = 'ClientManagement/DeleteClientDetails'
  public static DEACTIVATEUSER = "Users/DeactivateUser";
  public static DEACTIVATEROLE = "AccessManagement/DeactivateRole";
  public static SENTCREDENTIALS = "Users/SentCredentials"
  public static UPDATEACCOUNTSTATUS = "ProcessTracking/UpdateAccountStatus";
  public static SAVEASCONTRACT = "Contractmanagement/SaveasContract"
  public static GETMESSAGEDETAIL = "MessageCenter/GetMessageThreads";
  public static MARKMESSAGE = "MessageCenter/MarkMessage";
  public static CLOSEACCOUNT = "ProcessTracking/CloseAccount";
  public static GETQUERYDETAIL = "MessageCenter/GetQueryDetail";
  public static VIEWQUERY = "MessageCenter/ViewQuery";
  public static GETCLIENTDATA = "Dashboard/GetClientData";
  public static GETREPORTS = "EmbedInfo/GetEmbedInfo";
  public static EXPORTREPORTS = "EmbedInfo/ExportReport";
  public static GETREPORTNAME = "EmbedInfo/GetReportName  ";
  public static GETDASHBOARD = "Dashboard/GetDashboarData";
  public static GETHOMEDATA = "Dashboard/GetDataForHome";
  public static RAISEQUERY = 'MessageCenter/RaiseQuery';
  public static REPLYQUERY = 'MessageCenter/ReplyQuery';
  public static EDITQUERY = "MessageCenter/EditQuery";
  // public static GETHOMEDATA="Dashboard/GetDataForHome";
  public static GETADITTIONALFILE = "ProcessTracking/GEtAdditionalFilestoDownLoad";
  public static SSGETADITTIONALFILE = "Account/GEtAdditionalFilestoDownLoad";
  public static GETADDITIONALZIPPATH = "ProcessTracking/GetDownloadFileZipPath";
  public static FORWARDQUERY = 'MessageCenter/ForwardQuery'
  public static GETUSERLIST = 'MessageCenter/GetUsersList';
  public static GETDATAFORUPLOADSHIPPMENT = "ProcessTracking/GetDataForUploadShippmentPackage";
  // public static UPLOADSHIPMENT = "UploadAccount/UploadShipmentPackage";
  public static CONTACTSUPPORT = "login/ContactSupport";
  public static CHANGEACCOUNTSTATUS = "ProcessTracking/ChangeDownloadedAccountStatus";
  public static SSCHANGEACCOUNTSTATUS = "Account/ChangeDownloadedAccountStatus";
  public static DOWNLOADATTACHEDFILEMC = "MessageCenter/DownloadAttachedFile";
  public static CONTACTSUPPORTAFTERLOGIN = "Common/ContactSupportAfterLogin";
  public static GETRECENTJOBS = "Dashboard/GetRecentJobs";
  public static INSERTJOBFORCONVERTTORLFILEFORMATE="Common/InsertJobForConvertToRLFormat";
  public static UPDATEJOBFORCONVERTTORLFILEFORMATE="Common/UpdateJobForConvertToRLFormat";
  public static CHECKAUTHORIZEDFORCONVERTTORLFORMAT="Common/CheckAuthorizedForConvertRLFormat";
  public static GETFILESIZEFORMESSAGECETNER = "MessageCenter/GetmaxFileSize";
  public static GETALLOWEDFILEFORMATFORMESSAGECENTER = "MessageCenter/GetUploadFileFormat";
  public static REOPENQUERY = "MessageCenter/ReopenQuery";
  public static GETUSERFORCLIENTLEADDASHBOARD = "Dashboard/GetUserForClietLead";
  public static GETDASHBOARDUSERWISE = "Dashboard/GetDashboarDataUserWise";
  public static UPDATEJOBFORDOWNLOAD = "ProcessTracking/UpdateJobForDownload";
  public static SSUPDATEJOBFORDOWNLOAD = "Account/UpdateJobForDownload";
  public static CLOSEQUERY = "MessageCenter/CloseQuery";
  public static GETACCOUNTTYPETODOWNLOAD = "ProcessTracking/GetAccounttypetoDownlod";
  public static DOWNLOADFAQ = "Common/FAQDownload";
  public static DOWNLOADTEMPLATE = "SsUploadData/TemplateDownload";
  public static GETUSERTIMEZONE = "Users/GetUserTimeZone";
  public static CLOSEBULKACCOUNTS = "BulkOperation/CloseBulkAccount";
  public static GETACCOUNTSTODOWNLOAD = "BulkOperation/GetAccountsForBulkOperation";
  public static BULKDOWNLOADACCOUNT = "BulkOperation/BulkDownloadAccount";
  public static GETALLCLIENTLIST = "InternalUpload/GetAllClient";
  public static GETCLIENTUSERS="InternalUpload/GetAllClientUserdetail";
  public static CHECKUNDERMAINTENANCE = "login/CheckUndermaintenance";
  public static ADDEDITCLIENTELEMENT = "ClientManagement/SaveClientElement";
  public static GETCLIENTELEMENTMAP = "ClientManagement/GetClientElementMap";
  public static GETALLACCOUNTTYPE = "MasterManagement/GetAllAccountType";
  public static ADDEDITACCOUNTTYPE = "MasterManagement/AddEditAccountType";
  public static GETALLBUSINESSUNIT = "MasterManagement/GetAllBusinessUnit";
  public static ADDEDITBUSINESSUNIT = "MasterManagement/AddEditBusinessUnit";
  public static GETLOCATIONTIMEZONEINFO = "MasterManagement/GetLocationTimeZoneInfo";
  public static ADDEDITLOCATION = "MasterManagement/AddEditLocation";
  public static DEACTIVATEMASTER = "MasterManagement/DeactivateAccountType";
  public static DEACTIVATEBUSINESSUNIT = "MasterManagement/DeactivateBusinessUnit";
  public static DEACTIVATELOCATION = "MasterManagement/DeactivateLocation";
  public static REQUESTNEWUSER = "Users/RequestNewUser";
  public static CHANGE_PASSWORDFROMINTERNAL = "Users/ChangePassword";
  public static UPDATEPACKAGETYPE="ProcessTracking/UpdatePackageType";
  public static ACTIVATEACCOUNT = "ProcessTracking/ReActivateAccount";
}
