<hr style="margin-top: 0px !important;" />
<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- SELECT2 EXAMPLE -->
        <div class="card card-default">
            <div class="card-header">
                <h3 class="card-title" style="color: rgb(0, 123, 151); margin-right: 10px;">
                    Manage Master
                </h3>
                <div class="card-tools">
                    <div class="input-group input-group-sm" style="width: 180px;">
                        <!-- <input type="text" [(ngModel)]="searchInput" (keyup)="checkSearch()" (keydown.enter)="SearchItem()"
                        name="table_search" class="form-control float-" placeholder="Search"> -->
                        <select (change)="SelectAction($event.target.value)" class="form-control float-">
                            <option value="AccountType">Manage Account Type</option>
                            <option value="BusinessUnit">Manage Business Unit</option>
                            <option value="Location">Manage Location</option>
                        </select>

                    </div>


                </div>
            </div>
        </div>
        <!-- /.card -->
    </div>
    <!-- /.container-fluid -->
</section>
<section class="content">
    <div class="card card-default">
        <div class="card-header">
            <div class="container-fluid">
                <app-manage-accounttype *ngIf="accountType"></app-manage-accounttype>
                <app-manage-businessunit *ngIf="businessUnit"></app-manage-businessunit>
                <app-manage-location *ngIf="location"></app-manage-location>
            </div>
        </div>
    </div>
</section>