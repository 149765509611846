import { Component, OnInit, Input, OnChanges } from '@angular/core';

import { DashboardServiceService } from 'src/services/DashboardService/dashboardService.service';
import { AlertService } from 'src/services/Common/alert.service';
import { ErrorHandler } from 'src/common/error-handler';


@Component({
  selector: 'app-projectManager-dashboard',
  templateUrl: './projectManager-dashboard.component.html',
  styleUrls: ['./projectManager-dashboard.component.css']
})
export class ProjectManagerDashboardComponent implements OnInit, OnChanges {
  @Input() clientList: string;
  @Input() View_User: boolean;
  errorHandler = new ErrorHandler(this.alertService);
  uploadData: any;
  fieldHeader: any[] = [];
  userName: any;
  totalInventory: any;
  accountInQuery: any;
  accountInProgress: any;
  shippedAccount: any;
  downloadedAccount: any;
  accountProcessed: number;
  noOfActiveUser: any;
  locationProcessed: any;
  Role: any;
  isData: boolean = false;
  cancelledAccount: any;
  contractEndDate: any;
  messageContent: any;
  accountSummary: any;
  headerForAccount: any;
  UserList: any;
  UserId: number = 0;
  clientId: number;
  Inventorydetail: boolean = false;
  @Input() hasUploadAccess: boolean;
  @Input() View_Message_Center: boolean;
  @Input() RaiseReply_Query: boolean
  constructor(private DashboardService: DashboardServiceService,
    private alertService: AlertService,) { }

  ngOnInit() {

    //this.getdashboard(this.clientList[0]['clientid'])

  }
  ngOnChanges() {
    if (this.clientList != undefined) {
      this.clientId = this.clientList[0]['clientid']
      this.getData(this.clientList[0]['clientid']);
      this.getdashboard(this.clientId, this.UserId)

    }


  }

  getData(clientId: number) {
    let formData: FormData = new FormData();
    formData.append('clientId', String(clientId))
    this.DashboardService.getUserForClientLead(formData).subscribe(
      result => {
        this.UserList = result['table'];
      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }
  getdashboard(clientID: number, userId) {
    this.DashboardService.GetDashboardFromUSerId(clientID, userId).subscribe(response => {
      if (response['tbldata'] != null && response['tbldata'] != undefined && response['tbldata'] != '') {
        this.isData = true
        this.uploadData = response['tbldata']
        this.fieldHeader = [];
        this.fieldHeader = this.fieldHeader.concat(Object.keys(response['tbldata'][0]));

      }
      else {
        this.isData = true;
      }
      // this.uploadData = response['tbldata']

      this.userName = response['tbluserName'];
      if (response['tbltotalinventory'] != undefined) {
        this.totalInventory = response['tbltotalinventory'][0]['totalinventory'];
      }
      if (response['tbltotalinqueue'][0] != undefined) {
        this.accountInQuery = response['tbltotalinqueue'][0]['totalinque'];
      }
      if (response['tbltotalinprogress'][0] != undefined) {
        this.accountInProgress = response['tbltotalinprogress'][0]['totalinprogress'];
      }
      if (response['tbltotalshipped'][0] != undefined) {
        this.shippedAccount = response['tbltotalshipped'][0]['totalshipped'];
      }


      this.downloadedAccount = response['tbltotaldownloaded'][0]['totaldownloaded'];
      this.noOfActiveUser = response['tblactiveuser'][0]['noofactiveusers'];
      this.locationProcessed = response['tbllocationprocessed'][0]['locationprocessed']
      this.accountProcessed = this.shippedAccount + this.downloadedAccount;
      this.cancelledAccount = response['table9'][0]['totalcancelled'];
      if (response['table10'][0] != undefined) {
        this.contractEndDate = response['table10'][0]['dashContEnddate']
      }
      this.messageContent = response['table11'];
      if (response['table12'].length > 0) {
        this.Inventorydetail = true;
        this.accountSummary = response['table12'];
        this.headerForAccount = [];
        this.headerForAccount = this.headerForAccount.concat(Object.keys(response['table12'][0]));
      }
      else {
        this.Inventorydetail = false
      }




    },
      error => {
        this.errorHandler.ErrorsHandler(error);
      });

  }
  getDashboardUserWise(userId) {
    this.UserId = userId
    this.getdashboard(this.clientId, this.UserId)
  }
  getDashboardClientWise(clientId) {
    this.clientId = clientId
    this.getData(this.clientId);
    this.getdashboard(this.clientId, 0)
  }

}
