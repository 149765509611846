export class GetUserClientInfo {
  userID: number;
  userName: string;
  emailAddress: string;
  clientName: string;
  phone: number;
  extension: number;
  location: string;
  locationId: string;
  role: string;
  defaultAccountType: string;
  defaultAccounttypeId: number;
  defaultBusinessUnitId: string;
  isFirstTime: boolean;
  clientId: number;
  roleId: number;
  businessUnits: string;
  businessUnitId: string;
  defaultWorkType:number;
}
