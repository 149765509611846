import { Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { PopupService } from 'src/services/Common/popUp.service';
import { LogoutService } from 'src/services/LoginServices/logout.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { Response } from '@angular/http';
import { DataShareService } from 'src/services/Common/dataShare.service';
import { Observable, Subscription } from 'rxjs';
import { HostListener } from '@angular/core';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { HttpEventType } from '@angular/common/http';
import { HeaderService } from 'src/services/Common/header.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  uploadAccntList: any[] = [];
  subscription: any[] = [];
  retryList: any[] = [];
  uploadedCount: number = 0;
  timezone: any;
  uploadShipmentProgress: any[] = [];
  uploadShipmentCount: number = 0;
  downloadCount: number = 0;
  constructor(private userinfo: AddEditUserSerivceService, private cookieService: CookieService, private dataShare: DataShareService,
    private router: Router, private popUp: PopupService, private logoutService: LogoutService
    , private headerService: HeaderService) { }
  ngOnDestroy(): void {
    this.dataShare.updateListProcesses([], [], [], 0);
    this.dataShare.downloadShipmentProcess([]);
    this.dataShare.BulkdownloadShipmentProcess([]);
    this.dataShare.IfBulkDownloadDivOpen(false);
    this.dataShare.BulkdownloadShipmentProcess([]);
    this.dataShare.shareProcessTrackingFilter('');
    this.dataShare.shareProcessTrackingData(0, '');
    this.dataShare.SelectedRowProcessTracking(undefined);
    this.dataShare.updateShipmentProcess([], 0);
    this.dataShare.SetDownloadCount(0);
    this.dataShare.IfDownloadDivOpen(false);
    this.dataShare.AdvanceFilterPill([]);
    //this.userinfo.updatetimezone('');
  }
  UserName: string;
  clientName:string;
  isGroupUser: boolean = false;

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    if ((this.uploadAccntList != undefined || this.uploadAccntList != null) && this.uploadAccntList.filter(x => x['status'] == 2).length > 0) {
      console.log("Uploading in progress...");
      event.returnValue = false;
    }
  }

  ngOnInit() {
    this.getUserInfo();
    // this.UserName = this.cookieService.get('UserName');
    this.userinfo.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      if (res != null) {
        this.UserName = res['userDetails'][0]['userName'];
        this.clientName=res['userDetails'][0]['clientName'];
        this.isGroupUser = res['userDetails'][0]['roleName'] == 'Group Client User'

        
      }
    })
    this.dataShare.currentList$.subscribe(uploadList => this.uploadAccntList = uploadList);
    this.dataShare.subscription$.subscribe(sub => this.subscription = sub);
    this.dataShare.retryList$.subscribe(retry => this.retryList = retry);
    this.dataShare.uploadedCount$.subscribe(count => this.uploadedCount = count);

    this.dataShare.uploadShipmentList$.subscribe(shipment => this.uploadShipmentProgress = shipment);
    this.dataShare.uploadedShipmentCount$.subscribe(shipmentCount => this.uploadShipmentCount = shipmentCount);
    this.dataShare.downloadCount$.subscribe(dCount => this.downloadCount = dCount);
    this.dataShare.userTimeZone$.subscribe(res => this.timezone = res);

  }

  /**
   * ToggleSideBar
   */
  public ToggleSideBar(e) {
    var element = document.getElementById('sidebar');
    e.preventDefault();
    element.classList.toggle('active');
    element = document.getElementById('DownMenu');
    element.classList.toggle('d-none');
    element = document.getElementById('content');
    element.classList.toggle('sidebarSmall');
    let el = document.getElementsByClassName('alertContainer');
    console.log(el);
    for (let i = 0; i < el.length; i++)
      el[i].classList.toggle('small-alert');

  }


  public toggleAlert() {
    // const timer: ReturnType<typeof setTimeout> = setTimeout(() => '', 10000);

    // setTimeout(() => {
    let el = document.getElementsByClassName('alertContainer');
    console.log(el);
    for (let i = 0; i < el.length; i++)
      el[i].classList.toggle('small-alert');
    // }, 40);

  }

  public logout() {
    this.router.navigate(['logout']);
  }
  AskFeedback(rate: any) {
    this.popUp.Feedback().then(Response => {
      if (Response.isConfirmed) {
        //let category = Response.value[0]['selectedOptions'][0].value
        let comment = Response.value[1][0].value;
        let formData: FormData = new FormData();
        formData.append("feedback", comment);
        formData.append("category", '');
        formData.append("rating", rate);
        //console.log(Response);
        this.logoutService.PostRequest(AppSettings.API_ENDPOINT + AppRelativeUrl.FEEDBACK, formData).subscribe(
          res => {
            this.router.navigate(['logout']);
            //  console.log(res);
          }, err => {
            //this.errorHandler.ErrorsHandler(err);
            console.log(err);
          }
        )
      } else {
        this.router.navigate(['logout']);
      }
    })

  }
  getUserInfo() {
    this.userinfo.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      if (res['userDetails'][0]['disTimeZoneName'] != null && res['userDetails'][0]['disTimeZoneName'] != "undefined") {
        this.timezone = res['userDetails'][0]['disTimeZoneName'];
      }

    })
  }
  FAQPDFDownload() {
    // this.loginServic.DownloadFAQ(AppSettings.API_ENDPOINT + AppRelativeUrl.DOWNLOADFAQ).subscribe(data => {
    //   console.log(data);
    // }, err => {
    //   console.log(err);
    // });


    this.headerService.DownloadFAQ(AppSettings.API_ENDPOINT + AppRelativeUrl.DOWNLOADFAQ).subscribe(
      data => {
        console.log(data);
        switch (data.type) {
          case HttpEventType.DownloadProgress:

            break;
          case HttpEventType.Response:

            const downloadedFile = new Blob([data.body], { type: data.body.type });
            // const a = document.createElement('a');
            // a.setAttribute('style', 'display:none;');
            // document.body.appendChild(a);
            // a.download = "RMS Portal Frequently Asked Questions";
            // a.href = URL.createObjectURL(downloadedFile);
            // a.target = '_blank';
            // a.click();
            // document.body.removeChild(a);
            var fileURL = window.URL.createObjectURL(downloadedFile);
            window.open(fileURL);
            break;
        }

      },
      error => {

      }
    );
  }
}
