<!-- <div class=" setBGImage">

  <div class="container MainContainer">
    <div class="row">
      <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 2rem !important;">
        <div class="alert alert-danger alert-dismissible" *ngIf="notfound">
          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
          Old password is not matched.
        </div>
      </div>
      <div class="col-lg-12 ErrorMessage NoPadding" style="color: green; height: 2rem !important;">

      </div>

      <div class="col-lg-6">
        <img src="../../../assets/img/RMS.png" style="width:102%;">
        <br />
        <img src="../../../assets/img/Web9.png" style="width:100%;margin-top: 40px;" />
      </div>
      <div class="col-lg-6">
        <div class="alert alert-success alert-dismissible" *ngIf="notification">
          Password has been changed successfully!
          <p class="lead">
            Please <a routerLink="/login">Click Here</a> to login again.
          </p>
        </div>

        <form *ngIf="template" [formGroup]="FormData"
          (ngSubmit)="ChangePassword(oldpasssword.value, newpassword.value)">
          <table class="table settable" style="width:100%;margin-top:8%">
            <tr>
              <td style="text-align:center;border-top-style:none">
                <br />
                <h4 class="HeadLogin">Change Password</h4>
              </td>
            </tr>
            <tr>
              <td>
                <div style="padding-top: 5%;" class="input-group">
                  <span class="input-group-addon" id="sizing-addon1"
                    style="border-radius: 0px; background-color: #c42032;">
                    <i class="fa fa-lock InputIcon"></i></span>
                  <input type="password" class="form-control inputClass" #oldpasssword
                    (ngModelChange)="ShowOldPassword()" formControlName="Old_Password" placeholder="Old password"
                    aria-describedby="sizing-addon1" style="border-radius: 0px; max-width: 100%; font-weight: 200;" />
                  <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                    <div *ngIf="f.Old_Password.errors && ShowOldPasswordErrors">
                      <div *ngIf="f.Old_Password.errors.required">
                        Old Password is required.
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td style="
                              border-top-style: none;
                              padding-top: 0px;
                              padding-bottom: 25px;
                            ">
                <div class="input-group">
                  <span class="input-group-addon" id="sizing-addon1" style="
                                  font-size: 14.5px;
                                  border-radius: 0px;
                                  background-color: #c42032;
                                "><i class="fa fa-lock InputIcon" style="font-size: 14.5px;"></i></span>
                  <input (capsLock)="capsOn=$event" (ngModelChange)="ShowNewPasswordError()" #newpassword placeholder="New password"
                    formControlName="New_Password" aria-describedby="sizing-addon1" type="password" id="inputPassword" class="form-control inputClass"
                    
                    style="border-radius: 0px; max-width: 100%; font-weight: 200;" />
                  <div *ngIf="ShowNewPasswordErrors && f.New_Password.errors" class="tooltip"
                    style="display: contents;">
                    <i class="fa fa-exclamation-circle" style="
                                    font-size: 18.5px;
                                    color: #c42032;
                                    margin-top: 8px;
                                    position: absolute;
                                    right: 2%;
                                  ">
                    </i>
                    <span class="tooltiptext col-lg-12">
                      <b>*</b> Password minimum length :10<br />
                      <b>*</b> Password maximum length :16<br />
                      <b>*</b> Minimum required numeric number :1<br />
                      <b>*</b> Minimum required lower-case letters :1<br />
                      <b>*</b> Minimum required upper-case letters :1<br />
                      <b>*</b> Minimum required special characters :1<br />
                      <b>*</b> Allowable special characters :!&#64;#$%*()_+^&&#125;{{
                      "{"
                      }}:;?."<br />
                    </span>
                  </div>
                  <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                    <div *ngIf="ShowNewPasswordErrors && f.New_Password.errors">
                      <div *ngIf="f.New_Password.errors.required">
                        New Password is required
                      </div>
                      <div *ngIf="f.New_Password.errors.minlength">
                        New Password is too short
                      </div>
                      <div *ngIf="f.New_Password.errors.maxlength">
                        New Password is too long
                      </div>
                      <div *ngIf="f.New_Password.errors.pattern">
                        New Password is invalid
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr></tr>
            <tr>
              <td style="
                              border-top-style: none;
                              padding-top: 0px;
                              padding-bottom: 25px;
                            ">
                <div class="input-group">
                  <span class="input-group-addon" id="sizing-addon1"
                    style="border-radius: 0px; background-color: #c42032;">
                    <i class="fa fa-lock InputIcon"></i></span>
                  <input type="password" class="form-control inputClass" (ngModelChange)="ShowConfirmPasswordError()"
                    formControlName="Confirm_Password" placeholder="Confirm password" aria-describedby="sizing-addon1"
                    style="border-radius: 0px; max-width: 100%; font-weight: 200;" />

                  <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                    <div *ngIf="
                                    f.Confirm_Password.errors && ShowConfirmPasswordErrors
                                  ">
                      <div *ngIf="f.Confirm_Password.errors.required">
                        Confirm Password is required.
                      </div>
                      <div *ngIf="f.Confirm_Password.errors.mustMatch">
                        Confirm Passwords must match
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 ErrorMessage  NoPadding" style="height: 1rem !important;">
                    <div *ngIf="capsOn">
                      <div style="background: #fffb0733;">
                        Warning: Caps Lock is ON
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td style="padding-top:5%">
                <button class="btn btn-success SignInBtn float-left">
                  Submit
                </button>
                <a routerLink="/login" class="btn btn-success SignInBtn float-right">Back</a>
              </td>
            </tr>
          </table>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col" style="text-align:center;">
        <h6 style="font-size: .8rem; color:#989696;">© {{Year | date: "y"}} Risk Management Solutions, Inc. All Rights
          Reserved.</h6>
        <br />
      </div>
    </div>
  </div>
</div> -->
<div class=" setBGImage">
  <div >
    <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 2rem !important;">
      <div class="alert alert-danger alert-dismissible" *ngIf="notfound">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
        Old password is not matched.
      </div>
    </div>
  <div class="main">
    <div class="container mt-5">
      <div class="row justify-content-center" style="padding-top: 100px;">     
          <div class="card" style="align-items: center;height: 680px;border-radius: 30px;padding-left: 25px;padding-right:25px;">
            <div style="text-align: center;" >
              <div class="app-header" style="width: 367px;">
                <img style="padding-top: 10px;" src="../../../assets/svg/MoodysBlue.svg" alt="Logo" class="app-logo">
                <h3 class="MoodysTittleColor">Data Solution Portal</h3>
              </div>
            </div>   
            <div class="card-body" style="width: 600px;">
              <form *ngIf="template" [formGroup]="FormData" (ngSubmit)="ChangePassword(oldpasssword.value, newpassword.value)" >
                <div class="input-group form-group" style="width: 100%;height: 60px;border-radius: 10px;padding-top: 5px;">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="height: 100%;"><i class="fas fa-key"></i></span>
                  </div>
                  <input  type="password" class="form-control inputClass" #oldpasssword
                  (ngModelChange)="ShowOldPassword()" formControlName="Old_Password" placeholder="Old password"
                  aria-describedby="sizing-addon1" style="height: 100%;border-bottom-right-radius: 10px;
                  border-top-right-radius: 10px;" class="form-control" >
                     <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                      <div *ngIf="f.Old_Password.errors && ShowOldPasswordErrors">
                        <div *ngIf="f.Old_Password.errors.required">
                          Old Password is required.
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 ErrorMessage  NoPadding" style="height: 1rem !important;">
                      <div *ngIf="capsOn">
                        <div style="background: #fffb0733;">
                          Warning: Caps Lock is ON
                        </div>
                      </div>
                    </div>
                </div>
             
              <div class="input-group form-group" style="width: 100%;height: 60px;border-radius: 10px;padding-top: 5px;">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="height: 100%;"><i class="fas fa-key"></i></span>
                </div>
                <input (capsLock)="capsOn=$event" (ngModelChange)="ShowNewPasswordError()" #newpassword placeholder="New password"
                formControlName="New_Password" aria-describedby="sizing-addon1" type="password" id="inputPassword" onCopy="return false"
                onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off id="inputPassword" type="password" style="height: 100%;border-bottom-right-radius: 10px;
                border-top-right-radius: 10px;" class="form-control" placeholder="Password">
                  <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                    <div *ngIf="f.Old_Password.errors && ShowOldPasswordErrors">
                      <div *ngIf="f.Old_Password.errors.required">
                        Old Password is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 ErrorMessage  NoPadding" style="height: 1rem !important;">
                    <div *ngIf="capsOn">
                      <div style="background: #fffb0733;">
                        Warning: Caps Lock is ON
                      </div>
                    </div>
                  </div>
              </div>
              

              <div class="input-group form-group" style="width: 100%;height: 60px;border-radius: 10px;padding-top: 5px;">
                <div class="input-group-prepend">
                  <span class="input-group-text" style="height: 100%;"><i class="fas fa-key"></i></span>
                </div>
                <input (ngModelChange)="ShowConfirmPasswordError()"
                formControlName="Confirm_Password" placeholder="Confirm password" aria-describedby="sizing-addon1" onCopy="return false"
                onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off id="inputPassword" type="password" style="height: 100%;border-bottom-right-radius: 10px;
                border-top-right-radius: 10px;" class="form-control" placeholder="Password">
                 <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                  <div *ngIf="
                                  f.Confirm_Password.errors && ShowConfirmPasswordErrors
                                ">
                    <div *ngIf="f.Confirm_Password.errors.required">
                      Confirm Password is required.
                    </div>
                    <div *ngIf="f.Confirm_Password.errors.mustMatch">
                      Confirm Passwords must match
                    </div>
                  </div>
                </div>
                  <div class="col-lg-12 ErrorMessage  NoPadding" style="height: 1rem !important;">
                    <div *ngIf="capsOn">
                      <div style="background: #fffb0733;">
                        Warning: Caps Lock is ON
                      </div>
                    </div>
                  </div>
              </div>
              <div class="text-center" style="margin-top: 10px;">
                  <input type="submit" style="width: 50%; height: 60px; border-radius: 10px; display: inline-block;"  type="submit"  class="btn btn-success SignInBtn float-left" value="Submit"  class="btn MoodysSubmitButton">
              </div>
               </form>
               <div style="padding-top: 30px;" class="row">
                <div class="col MoodysTittleColor" style="text-align:center;">
                  <h6 style="font-size: .8rem;">© {{Year | date: "y"}}  Moody's Analytics, Inc. and/or its licensors and affiliates (collectively, "Moody's"). All rights reserved.
                    Reserved.</h6>
                  <br />
                </div>
              </div>  
            </div>  
        </div>
      </div>
    </div>
  </div>    
</div>
</div>