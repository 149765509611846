import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ChangepasswordserviceService } from 'src/services/LoginServices/changepasswordservice.service';
import { CookieService } from 'ngx-cookie-service';
import { LogoutService } from 'src/services/LoginServices/logout.service';
import { AlertService } from 'src/services/Common/alert.service';
import { ErrorHandler } from 'src/common/error-handler';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangePasswordComponent implements OnInit {
  constructor(private fb: UntypedFormBuilder, private service: ChangepasswordserviceService, private userInfo: AddEditUserSerivceService,
    private router: Router, private cookieService: CookieService, private logoutService: LogoutService, private alertService: AlertService,
  ) { }
  capsOn;
  FormData: UntypedFormGroup;
  ShowOldPasswordErrors: boolean;
  ShowNewPasswordErrors: boolean;
  ShowConfirmPasswordErrors: boolean;
  shownotification: boolean;
  message: string;
  variable: boolean = false;
  notification: boolean;
  notfound: boolean;
  template: boolean;
  userName: string;
  @Input() check: boolean = false;
  errorHandler = new ErrorHandler(this.alertService);
  public classvariable = {
    //'alert alert-danger ':!this.variable,
    'alert alert-success ': !this.variable
  }
  ngOnInit() {
    this.userInfo.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      if (res != null) {
        this.userName = res['userDetails'][0]['emailID']
      }
    })
    let token = this.cookieService.get('ApiQuote');
    if (token === null || token === '') {
      this.router.navigate(['login']);
    }
    this.template = true;
    this.FormData = this.fb.group({
      Old_Password: ["", [Validators.required]],
      New_Password: ["", [Validators.required, Validators.maxLength(16), Validators.minLength(9)
        , Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$!@#$%()_+^&}{:;?.\"])[A-Za-z\\d$!@#$%()_+^&}{:;?.\"]{10,16}$")]],
      Confirm_Password: ["", [Validators.required]]
    },
      {
        validators: MustMatch('New_Password', 'Confirm_Password')
      });
  }
  get f() {
    return this.FormData.controls;
  }
  ShowOldPassword() {
    this.ShowOldPasswordErrors = true;
  }
  ShowNewPasswordError() {
    this.ShowNewPasswordErrors = true;
  }
  ShowConfirmPasswordError() {
    this.ShowConfirmPasswordErrors = true;
  }
  ChangePassword(OldPassword: string, NewPassword: string) {
    this.ShowOldPasswordErrors = true;
    this.ShowNewPasswordErrors = true;
    this.ShowConfirmPasswordErrors = true;
    if (this.FormData.invalid) {
      return;
    }
    if (OldPassword == NewPassword) {
      this.alertService.warn("Old password and new password can not be same");
      return;
    }
    this.service.ChangePasswordFromInternal(AppSettings.API_ENDPOINT+AppRelativeUrl.CHANGE_PASSWORDFROMINTERNAL,this.userName, OldPassword, NewPassword).subscribe(
      res => {
        console.log(res);
        if (res) {
          this.notification = true;
          this.notfound = false;
          this.template = false;
          this.alertService.success('Password has been changed successfully!');
          this.cookieService.delete('ApiQuote');
          this.cookieService.delete('Refresh');
          this.cookieService.delete('FTLI');
          this.logoutService.DeleteToken(AppSettings.API_ENDPOINT +   AppRelativeUrl.LOGOUT).subscribe()
          // this.router.navigate(['logout']);
        }
        else {
          this.notfound = true;
          this.notification = false;
          this.template = true;
          this.alertService.warn('Old password is not matched!')
        }
      },
      err => {
        this.errorHandler.ErrorsHandler(err);
      }
    )
  }
}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
