import { Component, Input, OnInit, OnChanges, SimpleChanges, ElementRef, ViewChild, EventEmitter, Output, ɵConsole, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { DynamicElementBase } from 'src/common/DynamicElementBase';
import { DynamicControlService } from 'src/services/Upload/DynamicControlService';
import { UploadService } from 'src/services/Upload/Upload.service';
import { HttpEventType, HttpEvent, HttpRequest } from '@angular/common/http';
//import { v4 as uuidv4 } from 'uuid'
import { UploadAccountModel } from 'src/model/UploadAccount/UploadAccountModel';
import { Observable, Subscription } from 'rxjs';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AlertService } from 'src/services/Common/alert.service';
import { PopupService } from 'src/services/Common/popUp.service';
import { DataShareService } from 'src/services/Common/dataShare.service';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertMessages } from 'src/common/alert-messages';
import { test } from 'node:test';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { ProcessTrackingService } from 'src/services/Process-TrackingServices/process-tracking.service';

@Component({
  selector: 'app-ss-upload',
  templateUrl: './ss-upload.component.html',
  styleUrls: ['./ss-upload.component.css', '../../../styles/tables.css']
})
export class SsUploadComponent implements OnChanges, OnInit, OnDestroy {

  @Input() elementList: DynamicElementBase<string>[] = [];
  @Input() UserDetails: any;
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];
  form: UntypedFormGroup;
  FilledFormData: any[]
  dynFieldheader: string[];
  data: any;
  payLoad = '';
  filteredHeaders:any
  isDisabled = true;
  errorHandler = new ErrorHandler(this.alertService);
  UploadingFileProgress: UploadAccountModel[];
  showError: boolean = false;
  @Output() public onUploadFinished = new EventEmitter();
  refresh: EventEmitter<boolean> = new EventEmitter();
  guid: any;
  activeCard: string = 'Downloaded';
  maxFileCount: number;
  maxFileSize: number;
  fileUploaded: number;
  fileSizeError: string;
  accountTypeRequired:boolean=false;
  AllowedFileFormat: any[]=[{ fileFormat: "xlsx ", mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    { fileFormat: "xls  ", mimeType: "application/vnd.ms-excel" }
  ];
  subscription: Subscription[];
  Msg:any="Allowed file formats are .xls and .xlsx"

  dataUploadList: Subscription;
  dataCancelList: Subscription;
  dataRetryList: Subscription;
  submitted: Boolean = false;
  textLength: any;
  isTyping: boolean;
  UserName:string
  ContractExpireMessage: string;
  //SpecialInstruction:string;
  //AttachFile:string;
  selectedItem: any
  RecentUploadedAccntData: any;
  RecentUploadedHeader: any;
  IsRecentlyUploadVisible: boolean = false;
  myForm: FormGroup;
  filename: any;
  PolicyRefVal: string;
  NoRecordsToShow: boolean = false;
  location_processed:any
  no_of_loc_subscribed:any
  no_of_loc_used:any
  canUpload :boolean =false
  accountBuinfo: any
  natureOfAccounts: string[];
  selectedNatureOfAccount: any;
  natureOfAccount_to_Upload: string = "";
  account_type_to_Upload:any;
  businessunit_to_Upload:any;
  is_visible_serviceType:boolean =false;

  constructor( private processTrackingService:ProcessTrackingService ,private serivce: AddEditUserSerivceService,private ShareData: DataShareService, private popUp: PopupService, private service: UploadService, private alertService: AlertService, private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      SpecialInstruction: ['', [Validators.maxLength(4000)]],
      UploadAnother: ['']
      // AttachFile: ['', '',]
    });
    this.myForm = new FormGroup({
      'Account Name': new FormControl(null),
      'Account Type': new FormControl(null),
      'UploadAnother': new FormControl(null),
      'Business Unit': new FormControl(null),
      'Priority': new FormControl(null),
      'Work Type': new FormControl(null),
      'Workflow Ref': new FormControl(null)
    });
    // this.SpecialInstruction="SpecialInstruction";
    // this.AttachFile="AttachFile";
    let group: any = {};
    //this.form = new FormGroup(group);  
    this.UploadingFileProgress = [];
    this.FilledFormData = [];
    this.fileUploaded = 0;
    this.subscription = [];

  }
  getPackageTypeValue(value: any) {
    if(value.trim()=='Standard'){
      this.SetAllowedFormatToUpload('1');
    }
    if(value.trim()=='Customize'){
      this.SetAllowedFormatToUpload('2');
    }
    if(value.trim()=='Standard&Customize'){
      this.SetAllowedFormatToUpload('3');
    }
   
  }
  
  SetAllowedFormatToUpload(PackageType:string){
    // use this apprelative url so not updating it 
    // let formData=new FormData();
    // formData.append("PackageTypeId",PackageType)
    // this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETALLOWEDFILEFORMATFORUPLOADACCOUNT,formData).subscribe(
    //   result => {
    //     this.AllowedFileFormat = result as [];
    //     console.log(this.AllowedFileFormat);
    //   });
  }


  setformValue(userdetail: any){
    if(this.account_type_to_Upload==null || this.account_type_to_Upload==undefined){
      console.log(this.account_type_to_Upload);
return;
    }
    this.myForm.setValue({
      'Account Name': this.filename,
      'Account Type': this.account_type_to_Upload,
      'UploadAnother': '',
      'Business Unit': this.businessunit_to_Upload,
      'Priority':'Urgent',
      'Work Type': userdetail["tblWorkTypeList"][0]["workType"],
      'Workflow Ref': this.natureOfAccount_to_Upload
    });
  }
  //commented on 6/10/2024     by mohit
  // getRecentUpload(value: string) {
  //   if (value) {
  //     //get recently uploaded accounts
  //     this.PolicyRefVal = value;
  //     this.IsRecentlyUploadVisible = true;
  //     let formData: FormData = new FormData();
  //     formData.append('PolicyRef', value);
  //     this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETACCOUNTBYPOLICYREF, formData).subscribe(
  //       result => {
  //         if (result[0] != undefined && Object.keys(result[0]).length > 0) {
  //           this.RecentUploadedHeader = Object.keys(result[0]).slice(0, result[0].count);
  //           this.RecentUploadedAccntData = result;
  //           this.NoRecordsToShow = false;
  //         } else {
  //           this.NoRecordsToShow = true;
  //         }
  //       },
  //       err => {
  //         this.NoRecordsToShow = true;
  //         this.IsRecentlyUploadVisible = false;
  //         this.alertService.error(AlertMessages.ALERTMESSAGE10);
  //       });
  //   } else {
  //     this.PolicyRefVal = '';
  //     this.IsRecentlyUploadVisible = false;
  //     this.NoRecordsToShow = true;
  //   }
  // }
  ngOnChanges(changes: SimpleChanges): void {
    if ('elementList' in changes) {
      if (this.elementList === null)
        return;
      //this.qcs.toFormGroup(this.elementList, this.form);
    }
  }

  ngOnInit() {
// dont change this url as it is common for upload api and upload additional
    this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.SSGETMAXFILESIZE, "").subscribe(
      result => {
        //console.log(result)
        this.maxFileCount = result[0]["maxFileCount"];
        this.maxFileSize = result[0]["maxFileSize"];
      });

      this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.CHECKCONTRACTSTAUS, "").subscribe(
        data => {
          //console.log(data)
          this.natureOfAccounts = data['table2']
          if(data['table'][0]['contract_status']===1){
            this.canUpload=true

          }
          else{
            this.ContractExpireMessage ="Your contract has expired, please renew the contract to upload account"
            this.canUpload=false
          
          }          

          this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETSUBSCRIPTIONDETAILS, "").subscribe(
            results => {
              this.location_processed = results['table1'][0]['locationprocessed']
              this.no_of_loc_subscribed = results['table2'][0]['location_subscribed']
              this.no_of_loc_used = (this.location_processed / this.no_of_loc_subscribed * 100)
              if (this.location_processed >= this.no_of_loc_subscribed) {
                this.canUpload = false
                this.ContractExpireMessage = "You have reached the limit of your current subscription for locations, please renew the contract to upload more accounts"
              }
            });

          this.accountBuinfo=data['table1']
          if(this.accountBuinfo.length >1){
            this.is_visible_serviceType =true
            this.selectedItem = this.accountBuinfo[1]
            this.account_type_to_Upload=this.accountBuinfo[1]['accounttypename']
            this.businessunit_to_Upload=this.accountBuinfo[1]['name']
          }
          else{
            this.account_type_to_Upload=this.accountBuinfo[0]['accounttypename']
            this.businessunit_to_Upload=this.accountBuinfo[0]['name']
          }
          
          
        });
    // this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETALLOWEDFILEFORMAT, "").subscribe(result => {
    //   this.AllowedFileFormat = result as [];
    //   //console.log(this.AllowedFileFormat);
    // })
    this.SetAllowedFormatToUpload('1');
    this.serivce.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      // console.log('this is user profile page', res);
      if (res != null && res != "undefined") {
      this.UserDetails=res;
      console.log(this.UserDetails)

     this.UserName =this.UserDetails["userDetails"][0]["userName"]
     this.fillData(this.UserName, 0, 5, '', 1);
      }
    },
      err => {
        this.errorHandler.ErrorsHandler(err);
      }
    )
   
    this.dataUploadList = this.ShareData.currentList$.subscribe(processes => this.UploadingFileProgress = processes);
    this.dataCancelList = this.ShareData.subscription$.subscribe(sub => this.subscription = sub);
    this.dataRetryList = this.ShareData.retryList$.subscribe(retry => this.FilledFormData = retry);
  }

  OnAccountTypeChange() {
    if(this.selectedItem==null || this.selectedItem==undefined){
      this.accountTypeRequired=true;
      this.account_type_to_Upload=null
      this.businessunit_to_Upload=null      
    }else{
      this.accountTypeRequired=false
      this.account_type_to_Upload=this.selectedItem['accounttypename']
      this.businessunit_to_Upload=this.selectedItem['name']
    }
  }


  OnNatureOfAccountChange() {
    if (this.selectedNatureOfAccount == undefined || this.selectedNatureOfAccount == null) {
      this.natureOfAccount_to_Upload = "";
    }
    else {
      this.natureOfAccount_to_Upload = this.selectedNatureOfAccount;
    }    
  }

    fillData(filter: string, startRow: number, rowLength: number, orderFilter: string, execType: number) {
      if (execType == 0)
        execType = 1;
    //  this.showLoader = true;
      let formData: FormData = new FormData();
      formData.append('FilterData', filter);
      formData.append('StartRow', String(startRow));
      formData.append('RowLength', String(rowLength));
      // formData.append('RowLength', '100');
      formData.append('ExecType', String(execType));
      formData.append('OrderFilter', String(orderFilter));
  
      this.data = [];
     // this.loadingMessage = "Fetching records...";
  
      this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.ACCOUNT_TRACKING, formData).subscribe(
        result => {
          this.data = result;
          this.dynFieldheader = [];
          this.dynFieldheader = this.dynFieldheader.concat(Object.keys(result[0]));
          this.filteredHeaders=this.dynFieldheader.concat(Object.keys(result[0]));
          // this.filteredHeaders = this.columntoshowforLIAPIUSer.filter(header => this.columntoshowforLIAPIUSer.includes(header));
          // this.showLoader = false;
          // this.rowCount = this.data.length;
  
        }
        ,
        error => {
         // this.loadingMessage = "No records found";
          this.errorHandler.ErrorsHandler(error)
          // ErrorHandler.getInstance().ErrorsHandler(error);
         // this.showLoader = false;
  
        }
      )
  
    }

  getTextStyle() {
    if (this.no_of_loc_used <= 80) {
      return { color: 'green' }; // Green text
    } else if (this.no_of_loc_used <= 90) {
      return { color: 'orange' }; // Amber text
    } else {
      return { color: 'red' }; // Red text
    }
  }
  get f() {
    return this.form.controls;
  }

  private setDefaultVaues() {
    let AccountType = this.elementList.find(element => element["key"] == 'Account Type').value;
    if (AccountType != undefined)
      this.form.controls['Account Type'].setValue(AccountType);
    else
      this.form.controls['Account Type'].setValue('');
    this.form.controls['Priority'].setValue('Urgent');
    if (this.form.get('Incept Date') != null && this.form.get('Incept Date') != undefined)
      this.form.get('Incept Date').patchValue(this.formatDate(new Date(), 0));
    if (this.form.get('Expiry Date') != null && this.form.get('Expiry Date') != undefined)
      this.form.get('Expiry Date').patchValue(this.formatDate(new Date(), 1));
    this.refresh.emit(true);
  }

  private formatDate(date, addYear: number) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();
    if (addYear > 0)
      year = year + addYear;
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  RetryUpload(item: any) {
    let position = this.UploadingFileProgress.indexOf(item);
    this.form.setValue(this.FilledFormData[position]);
    this.form.get('Business Unit').setValue(this.FilledFormData[position]['Business Unit']);
    this.UploadingFileProgress.splice(position, 1);
    this.FilledFormData.splice(position, 1);
    this.subscription.splice(position, 1);
    //console.log(t);
  }
  Submit() {  
    if(this.accountTypeRequired){      
      this.alertService.error("Please select the service type")
      return;
    }
    if(!this.ValidateTheFileNameLength(this.filename))
      return;
    this.filename=this.ReplaceInvalidCharacters(this.filename);
    let formData = new FormData();
    formData.append('AccountName', this.filename);
    this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.SSISALREADYUPLOADEDACCOUNT, formData).subscribe(result => {
      //console.log("Account uploaded already :"+result);
      
      if (typeof result === 'number' && result > 0) {
        this.filename = this.filename + "_v" + (result+1);
        this.uploadfile()
      }

      else{
        this.filename=this.filename
        this.uploadfile()
      }      
  
    }); 
  }
  //generateRandomNumber(min: number, max: number): number {
  //   return Math.floor(Math.random() * (max - min + 1)) + min;
  // }
  // appendRandomNumberToString(baseString: string, min: number, max: number): string {
  //   const randomNumber = this.generateRandomNumber(min, max);
  //   return `${baseString}${randomNumber}`;
  // }

  generateRandomLetters(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  appendRandomLettersToString(baseString: string, length: number): string {
    const randomLetters = this.generateRandomLetters(length);
    return `${baseString}_${randomLetters}`;
  }


  uploadfile(){
    this.submitted = true;
    this.showError = true;
    this.fileSizeError = "";
    this.setformValue(this.UserDetails)
    this.payLoad = JSON.stringify(this.myForm.getRawValue());
    // this.payLoad=JSON.stringify({"UploadAnother":"","Account Name":"test103",
    // "Account Type":"Pre Bind","Business Unit":"Property","Priority":"Normal",
    // "Work Type":"Cleansing & Risk Analytics"})


  
    if (this.form.invalid) {
      document.getElementById("title").scrollIntoView();
      return;
    }
    this.FilledFormData.push(this.form.getRawValue());
    if (this.validateForm()) {
      let jobId: string;
      const formData = new FormData();
      formData.append('UploadData', this.payLoad);
      this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.SSCREATEUPLOADJOB, formData).subscribe(
        result => {
          jobId = String(result);
          this.UploadAccount(jobId);
        });
    }


  }

  validateForm() {
    let valid: boolean = true;
    // if(this.UploadingFileProgress.some(x=>x.AccountName== this.form.get('Account Name').value))
    // {
    //   valid=false;
    //   this.form.get('Account Name').setErrors({notUnique: true});
    // }

    if (this.files.length === 0) {
      this.fileSizeError = "Please provide file's to upload."
      valid = false;
    }
    if (this.form.get('Incept Date') != null && this.form.get('Incept Date') != undefined &&
      this.form.get('Expiry Date') != null && this.form.get('Expiry Date') != undefined && (
        this.form.get('Incept Date').value > this.form.get('Expiry Date').value))
      valid = false;
    return valid;
  }

  UploadAccount(jobId: string) {

    this.fileUploaded = 0;
    let t: UploadAccountModel;
    t = new UploadAccountModel();
    t.AccountName = this.myForm.controls["Account Name"].value;
    t.AccountType = this.myForm.controls["Account Type"].value;
    t.Priority = this.myForm.controls["Priority"].value;
    t.BusinessUnit = this.myForm.controls["Business Unit"].value;
    t.NoOfLocs = '';
    t.Progress = '1';
    t.status = 2;
    t.JobId = jobId;
    // t.SumInsured = this.form.controls["Sum Insured"].value;
    // t.Underwriter = this.form.controls["Underwriter"].value;
    t.Message = "Uploading..."
    t.WorkType = this.myForm.controls["Work Type"].value;
    let filesToUpload: File[] = this.files;
    const formData = new FormData();
    formData.append('UploadData', this.payLoad);
    formData.append('jobId', jobId);
    let totalFileSize = 0;
    Array.from(filesToUpload).map((file, index) => {
      totalFileSize = totalFileSize + file.size;

      return formData.append('file' + index, file, file.name);
    });
    let subs = this.service.SSuploadFile(formData).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress && ((event.loaded / event.total) * 100) < 98) {
        t.status = 2;
        t.Progress = ((event.loaded / event.total) * 100).toFixed(2);
      }
      else if (event.type === HttpEventType.Response && event.status == 200) {
        t.status = 1;
        t.Message = "Done";
        t.Progress = '100';
        this.ShareData.updateListProcesses(this.UploadingFileProgress, this.subscription, this.FilledFormData, this.UploadingFileProgress.filter(x => x.status == 1).length);
        this.onUploadFinished.emit();
        this.showError = false;
        this.fillData(this.UserName, 0, 5, '', 1);
        // this.files = [];
      }
    }, error => {
      // this.errorHandler.ErrorsHandler(error);
      //console.log(error);
      t.Message = (error.error['ErrorMessage'] == null || error.error['ErrorMessage'] == undefined ? AlertMessages.ALERTMESSAGE6 : error.error['ErrorMessage']);
      // t.Progress = '0';
      t.status = 3;
      this.showError = false;

    })
    this.subscription.push(subs);
    this.UploadingFileProgress.push(t);
    this.files = [];
    this.selectedNatureOfAccount = null;
    this.natureOfAccount_to_Upload="";
    if (this.form.controls['UploadAnother'].value) {
      this.form.controls['Account Name'].setValue('');
      this.form.controls['SpecialInstruction'].setValue('');
      this.form.controls["No. of Locs"].setValue('');
      this.form.controls["Priority"].setValue('Urgent');
      this.form.controls['Account Name'].setErrors(null);
      this.form.controls["No. of Locs"].setErrors(null);     

      // this.form.clearValidators()
      this.isTyping = false;
      this.showError = false;
    } else {
      this.form.reset();
      //this.setDefaultVaues();
      this.showError = false;
    }
    if (this.PolicyRefVal != null && this.PolicyRefVal != undefined) {
      this.IsRecentlyUploadVisible = true;
    } else { this.IsRecentlyUploadVisible = false; }
    this.submitted = false;
  }
  cancelUpload(item: any) {
    this.popUp.Confirmation("Cancel Account", "Do you really want to cancel this account?").then(result => {
      let index = this.UploadingFileProgress.indexOf(item);
      if (result.value && this.UploadingFileProgress[index].status != 1) {
        this.subscription[index].unsubscribe();
        this.UploadingFileProgress[index].status = 3;
        this.UploadingFileProgress[index].Message = "canceled";
        const formData = new FormData();
        formData.append('JobID', this.UploadingFileProgress[index].JobId);
        this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.SSCANCELUPLOADJOB, formData).subscribe(
          result => {

          });
      }
    });
  }
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
    this.isDisabled=false
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
    this.isDisabled=false
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.fileUploaded = this.fileUploaded - Math.round(this.files[index]["size"] / (1024 * 1024));
    this.files.splice(index, 1);
    this.fileSizeError = "";
    this.isDisabled=true
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(filesList: any[]) {
    
    if (filesList.length <= this.maxFileCount && (this.files.length + filesList.length) <= this.maxFileCount) {
      let alreadyAddedFiles: string = '';
      let wrongFormatFiles: string = '';
      let zeroSizeFiles: string = '';
      for (let item of filesList) {
        item.progress = 0;
        //let isAdded:Boolean=false;
        // console.log(item["type"]);
        // console.log(this.AllowedFileFormat.filter(el => el["mimeType"] == item["type"]));
        let fileExtension: string = item["name"];
        fileExtension = fileExtension.substr(fileExtension.lastIndexOf('.') + 1, fileExtension.length);
        if (!this.AllowedFileFormat.some(el => String(el["fileFormat"]).toUpperCase().trim() == fileExtension.toUpperCase().trim())) {
          if (wrongFormatFiles == '')
            wrongFormatFiles = item['name'];
          else
            wrongFormatFiles = wrongFormatFiles + ',' + item['name'];
          // this.fileSizeError = "One of attached file(s) is not valid, please attach a valid file and try again";
          continue;
        }
        if (item["size"] < 1) {
          if (zeroSizeFiles == '')
            zeroSizeFiles = item['name'];
          else
            zeroSizeFiles = zeroSizeFiles + ',' + item['name'];
          continue;
        }
        if (this.files.some(el => el['name'] == item['name'])) {
          if (alreadyAddedFiles == '')
            alreadyAddedFiles = item['name'];
          else
            alreadyAddedFiles = alreadyAddedFiles + ',' + item['name'];
          continue;
        }
        // this.files.forEach(element =>{
        //   if(item['name']==element['name']){        
        //  if(alreadyAddedFiles=='')
        //  alreadyAddedFiles=element['name']
        //  else
        //  alreadyAddedFiles=alreadyAddedFiles+','+element['name']
        //  isAdded=true;
        //  return;}
        // })
        // if(isAdded)
        // continue;

        if ((this.fileUploaded + Math.round(item["size"] / (1024 * 1024))) > this.maxFileSize) {
          this.fileSizeError = "Upload files total size can't be greater than " + this.maxFileSize + " MB";
          return;
        }
        this.fileUploaded = this.fileUploaded + Math.round(item["size"] / (1024 * 1024));
        this.fileSizeError = "";
        this.files.push(item);
       
        const filename =this.files[0].name
        const filenameWithoutExtension = filename.slice(0, filename.lastIndexOf("."));
        this.filename = filenameWithoutExtension;
      }
      if (alreadyAddedFiles != '')
        this.fileSizeError = alreadyAddedFiles + " already provided for upload";

      if (wrongFormatFiles != '')
        this.fileSizeError = 'File(s) ' + wrongFormatFiles + " not valid, please attach valid file";
      if (zeroSizeFiles != '')
        this.fileSizeError = 'File(s) ' + zeroSizeFiles + " have 0 Kb size, please attach valid file";
      this.fileDropEl.nativeElement.value = "";
    } else {
      document.getElementById("title").scrollIntoView();
      this.alertService.error("Can't upload more than " + this.maxFileCount + " files for this account.")
      this.fileSizeError = "Can't upload more than " + this.maxFileCount + " files for this account.";
    }
    //this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
   // this.dataUploadList.unsubscribe();
    this.dataCancelList.unsubscribe();
    this.dataRetryList.unsubscribe();
  }
  showremainingcharacter() {
    this.isTyping = true
  }

  DownloadTemplate() {
    this.service.DownloadTemplate(AppSettings.API_ENDPOINT + AppRelativeUrl.DOWNLOADTEMPLATE).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
              break;
          case HttpEventType.Response:
              const downloadedFile = new Blob([data.body], { type: data.body.type });
              let fileExtension = '';
              switch (data.body.type) {
                  case 'text/csv':
                      fileExtension = '.csv';
                      break;
                  case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                      fileExtension = '.xlsx';
                      break;
         
                  default:
                      console.log('Unsupported file type');
                      return; // Exit if the file type is not supported
              }
              const fileURL = window.URL.createObjectURL(downloadedFile);
              const a = document.createElement('a');
              a.href = fileURL;
              a.download = "Input Template" + fileExtension;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(fileURL);
      
              break;
      
      }

      },
      error => {

      }
    );
  }  
 public ReplaceInvalidCharacters(input: string): string {
  const invalidChars = '*.%?&<>|:;=+!#`"~^/()[]{}';
  const startwith="=+@-"; 
  let result = '';
  for (let i = 0; i < input.length; i++) {
      if (invalidChars.includes(input[i])) {
          result += '_';
      } else {
          result += input[i];
      }
  }

  if (startwith.includes(input.charAt(0))) {
    // Replace the first character with an underscore
    result= '_' + input.slice(1);
}
  return result;
}
public ValidateTheFileNameLength(fileName:string):boolean{
 let flag:boolean=false;
  if(fileName.length>60){
    this.alertService.error("The file name should not be more than 60 characters.")
    return false;
  }  
  return true;
}
public getFormattedNoOfLocUsed(): string {
  if (Number.isInteger(this.no_of_loc_used)) {
    return this.no_of_loc_used.toString();
  } else {
    return this.no_of_loc_used.toFixed(2);
  }
}
}
