import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.css']
})
export class CardHeaderComponent implements OnInit {
  @Input() accountProcessed: number;
  @Input() noOfActiveUser: any;
  @Input() locationProcessed: any;
  @Input() contractEndDate: any;

  constructor() { }

  ngOnInit() {
  }

}
