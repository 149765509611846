import { Component, OnInit, Input, OnChanges, EventEmitter, Output, ViewChild } from '@angular/core';
import { GetClientDetailsServiceService } from 'src/services/AccessManagement/get-client-details-service.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AlertService } from 'src/services/Common/alert.service';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertMessages } from 'src/common/alert-messages';
import { PopupService } from 'src/services/Common/popUp.service';
import { CommonMethod } from 'src/common/CommonMethod';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { build$ } from 'protractor/built/element';
import { numberFormat } from 'highcharts';
import { AccountBUMappingModel } from 'src/model/ClientManagement/AccountBUMapping';
//import { element } from 'protractor';

@Component({
  selector: 'app-contract-client',
  templateUrl: './contract-client.component.html',
  styleUrls: ['./contract-client.component.css'],
  providers: [DatePipe]
})
export class ContractClientComponent implements OnInit {
  contractManagementForm: UntypedFormGroup;
  submitted = false;
  errorHandler = new ErrorHandler(this.alertService);
  public propertyId: number;
  accounttypeList: any;
  accountManagerList: any;
  contractTypeList: any
  BUList: any[];
  lblTitle: string;
  isEdit: boolean;
  editContract: any;
  workTypeList: any[];
  item: number[] = [];
  tableHeader: any[] = [
    "Contract Name",
    "Contract Start Date",
    "Contract Expiry Date",
    "Account Per AEU",
    "Location Per AEU",
    "No. of AEUs",
    "Project Manager",
    "Avg Account Size",
    "Process Type",
    "Subscribe "
  ]
  isShowDiv = false;
  operationMode: string;
  selectedAccountManager: any;
  selectedAccountType: string[] = [];
  selectedWorkType: any[] = [];
  selectedContractType: any[] = [];
  selectedBusinessType: any = [];
  fieldHeader: any[] = []
  openModal: boolean = false;
  idContract: any;
  contractList: any[]
  clientName: any;
  test: boolean;
  isData: boolean;
  SaveAsContractForm: UntypedFormGroup;
  contractName: string;
  valueRecieved: any;
  hideVIewClient: boolean = true;
  clientID: any = null;
  btnSubmitTxt: string;
  private WorkTypeBUdata: [];
  @ViewChild('closebutton') closebutton;
  CalCultedAEU: any = 0;



  constructor(private getClientDetailsService: GetClientDetailsServiceService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private alertService: AlertService,
    private popupService: PopupService) { }

  ngOnInit() {
    this.getClientDetailsService.ClientId$.subscribe(clientId => this.clientID = clientId);
    this.getClientDetailsService.IsEditContract$.subscribe(isAllowed => this.valueRecieved = isAllowed);
    this.getClientDetailsService.ClientName$.subscribe(clientName => this.clientName = clientName);
    //this.test = true;
    //this.propertyId = this.route.snapshot.params['id'];
    this.getcontractDetails(this.clientID);
    this.BUList = [];
    let formData = new FormData;
    formData.append('ClientID', this.clientID.toString());
    this.getClientDetailsService.PostRequest(AppSettings.API_ENDPOINT + AppRelativeUrl.GETCLIENTMAPPEDACCOUNTYPE, formData).subscribe(response => {
      let responseData = response;
      this.accounttypeList = responseData['tblAccountTypeList'];
      this.accountManagerList = responseData['tblProjectManagerList'];
      this.WorkTypeBUdata = responseData['tblAccountTypeBUWorktypeMapping'];
      this.contractTypeList = responseData['tblContractTypeList'];
      this.WorkTypeBUdata.forEach(element => {
        if (element != null && element != undefined) {
          let business: { businessUnitId: string, businessUnitDescription: string } =
            { businessUnitId: element['businessUnitID'], businessUnitDescription: element['businessUnitDescription'] };
          if (this.BUList.find(el => el['businessUnitId'] == element['businessUnitID']) == undefined)
            this.BUList.push(business);
        }

      });
      this.workTypeList = responseData['tblWorkType'];
      //this.BUList = [];//responseData['tblWorkType'];
    },
      error => {

      });

    this.contractManagementForm = this.formBuilder.group({
      contractName: ['', [Validators.required, Validators.maxLength(200)]],
      contractStartDate: ['', [Validators.required]],
      contractExpiryDate: ['', [Validators.required]],
      projectManager: [, [Validators.required]],
      accountPerAeu: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      locationPerAeu: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      noOfAeu: ['', [Validators.required, Validators.pattern(/^[1-9]\d*(\.\d+)?$/)]],
      avgAccountSize: ['', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      workType: [, [Validators.required]],
      contractType: [, Validators.required],
      businessUnit: [,],
      accountType: [,]

    });

    this.SaveAsContractForm = this.formBuilder.group({
      contractName: ['', [Validators.required, Validators.maxLength(200)]],
      contractStartDate: ['', [Validators.required]],
      contractExpiryDate: ['', [Validators.required]],

    });
    this.RemoveValidators();
    //this.getClientDetailsService.routingtoUser(0);
  }

  getcontractDetails(propertyId: number) {
    this.fieldHeader = [];
    this.getClientDetailsService.GetContractDetails(propertyId).subscribe(response => {
      this.contractList = response['tblContractDetails'];
      if (this.contractList != undefined && this.contractList.length > 0) {
        //this.clientName = response['tblContractDetails'][0]['clientName'];
        this.fieldHeader = this.fieldHeader.concat(Object.keys(this.contractList[0]));
        this.fieldHeader.splice(0, 3);
        this.isData = true;
      }
      else {
        this.isData = false
      }

    },
      error => {

        this.errorHandler.ErrorsHandler(error);
      });

  }
  OnselectedAccountTypeChange(item: any) {
    this.selectedAccountType = item;
  }
  OnProjectManagerChange(item: any) {
    this.selectedAccountManager = item;
  }

  OnAccountTypeChange(item: any) {
    this.selectedAccountType = item;
    if (this.selectedAccountType == undefined || this.selectedAccountType.length == 0) {
      this.WorkTypeBUdata.forEach(element => {
        if (element != null && element != undefined) {
          let business: { businessUnitId: string, businessUnitDescription: string } =
            { businessUnitId: element['businessUnitID'], businessUnitDescription: element['businessUnitDescription'] };
          if (this.BUList.find(el => el['businessUnitId'] == element['businessUnitID']) == undefined)
            this.BUList.push(business);
        }
      });
      return;
    }
    // this.workTypeList = [];
    this.BUList = [];
    this.selectedBusinessType = [];
    // this.selectedWorkType = [];
    this.contractManagementForm.get("businessUnit").reset();
    //this.contractManagementForm.get("workType").reset();
    this.selectedAccountType.forEach(accountType => {
      if (accountType != undefined && accountType != null) {
        this.WorkTypeBUdata.forEach(element => {
          if (element != null && element['accountTypeId'] == accountType['accountTypeId']) {
            // let work: { workId: string, workName: string } = { workId: element['workID'], workName: element['workName'] };
            // if (this.workTypeList.find(el => el['workId'] == element['workID']) == undefined)
            //   this.workTypeList.push(work);
            let business: { businessUnitId: string, businessUnitDescription: string } =
              { businessUnitId: element['businessUnitID'], businessUnitDescription: element['businessUnitDescription'] };
            if (this.BUList.find(el => el['businessUnitId'] == element['businessUnitID']) == undefined)
              this.BUList.push(business);
          }

        });
      }
    });
  }
  OnContractTypeChange(item: any) {
    //console.log(item);
    if (item != undefined) {
      this.selectedContractType = item;
      switch (this.selectedContractType['id']) {
        case 1:
          this.contractManagementForm.get('accountType').setValidators(Validators.required);
          this.contractManagementForm.get('accountType').updateValueAndValidity();
          this.contractManagementForm.get('businessUnit').clearValidators();
          this.contractManagementForm.get('businessUnit').updateValueAndValidity();
          break;
        case 2:
          this.contractManagementForm.get('businessUnit').setValidators(Validators.required);
          this.contractManagementForm.get('businessUnit').updateValueAndValidity();
          this.contractManagementForm.get('accountType').clearValidators();
          this.contractManagementForm.get('accountType').updateValueAndValidity();
          break;
        case 3:
          this.contractManagementForm.get('accountType').setValidators(Validators.required);
          this.contractManagementForm.get('businessUnit').setValidators(Validators.required);
          this.contractManagementForm.get('businessUnit').updateValueAndValidity();
          this.contractManagementForm.get('accountType').updateValueAndValidity();
          break;
        default:
          this.RemoveValidators();
          break;
      }
    }
  }
  RemoveValidators() {
    this.contractManagementForm.get('accountType').clearValidators();
    this.contractManagementForm.get('businessUnit').clearValidators();
    this.contractManagementForm.get('accountType').updateValueAndValidity();
    this.contractManagementForm.get('businessUnit').updateValueAndValidity();
  }
  OnWorkTypeChange(item: any) {
    this.selectedWorkType = item;
  }
  OnBusinessUnitChange(item: any) {
    this.selectedBusinessType = item;
  }
  addNewContractButton() {
    this.isEdit=false;
    this.submitted = false;
    this.contractManagementForm.reset();
    this.RemoveValidators();
    this.isShowDiv = !this.isShowDiv;
    this.lblTitle = "Add Contract";
    this.btnSubmitTxt = "Submit";

  }
  close() {
    this.contractManagementForm.reset();
    this.RemoveValidators();
    this.isShowDiv = false;
  }

  get f() {
    return this.contractManagementForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    let contractName = this.contractManagementForm.get('contractName').value;
    if (this.contractManagementForm.invalid) {
      return;
    }
    if (this.contractList.find(contract => contract['contract Name'] == contractName && contract['isActive'] == 1) && !this.isEdit) {
      this.alertService.error('Contract with same name already exists.')
      return;
    }

    let contractid;
    if (this.isEdit) {
      this.operationMode = "2";
      contractid = this.editContract['contractId'];
    }
    else {
      this.operationMode = "1";
      contractid = 0
    }
    if (this.ValidDate(this.contractManagementForm.get('contractStartDate').value,
      this.contractManagementForm.get('contractExpiryDate').value)) {
      alert('Expiry date must be greater than start date');
      return
    }
    let accountBUMapList: AccountBUMappingModel[] = [];
    let AccountTypeIds: number[] = [];
    let AccountTypes: string;
    let WorkTypeIds: number[] = [];
    let WorkTypes: string;
    let BUIds: number[] = [];
    let BUTypes: string;
    let accountBUmap: AccountBUMappingModel = new AccountBUMappingModel();
    if (this.selectedContractType['id'] == 1) {
      this.selectedAccountType.forEach(accountType => {
        if (this.selectedBusinessType != undefined && this.selectedBusinessType.length > 0) {
          this.selectedBusinessType.forEach(bu => {
            this.WorkTypeBUdata.forEach(element => {

              if (element != null && element['accountTypeId'] == accountType['accountTypeId']
                && bu['businessUnitId'] == element['businessUnitID']) {
                if (!AccountTypeIds.includes(element['accountTypeId']))
                  AccountTypeIds.push(element['accountTypeId']);
                if (!BUIds.includes(element['businessUnitID']))
                  BUIds.push(element['businessUnitID']);
              }
            });
          });
        } else {
          this.WorkTypeBUdata.forEach(element => {
            let accountBUmap: AccountBUMappingModel = new AccountBUMappingModel();
            if (element != null && element['accountTypeId'] == accountType['accountTypeId']) {
              if (!AccountTypeIds.includes(element['accountTypeId']))
                AccountTypeIds.push(element['accountTypeId']);
              if (!BUIds.includes(element['businessUnitID']))
                BUIds.push(element['businessUnitID']);
            }
          });
        }
        accountBUmap.AccountTypeId = AccountTypeIds;
        accountBUmap.BusinessUnitIds = BUIds;
      });
    } else if (this.selectedContractType['id'] == 2) {
      this.selectedBusinessType.forEach(bu => {
        if (this.selectedAccountType == undefined || this.selectedAccountType.length == 0) {
          this.WorkTypeBUdata.forEach(element => {

            if (element != null && bu['businessUnitId'] == element['businessUnitID']) {
              if (!AccountTypeIds.includes(element['accountTypeId']))
                AccountTypeIds.push(element['accountTypeId']);
              if (!BUIds.includes(element['businessUnitID']))
                BUIds.push(element['businessUnitID']);
              // accountBUmap.AccountTypeId.push(element['accountTypeId']);
              // accountBUmap.BusinessUnitIds.push(element['businessUnitID']);             
            }
          });
        } else {
          this.selectedAccountType.forEach(accountType => {
            this.selectedBusinessType.forEach(bu => {
              this.WorkTypeBUdata.forEach(element => {
                if (element != null && element['accountTypeId'] == accountType['accountTypeId']
                  && bu['businessUnitId'] == element['businessUnitID']) {
                  if (!AccountTypeIds.includes(element['accountTypeId']))
                    AccountTypeIds.push(element['accountTypeId']);
                  if (!BUIds.includes(element['businessUnitID']))
                    BUIds.push(element['businessUnitID']);
                  // accountBUmap.AccountTypeId.push(element['accountTypeId']);
                  // accountBUmap.BusinessUnitIds.push(element['businessUnitID']);                  
                }
              });
            });
          });
        }
        accountBUmap.AccountTypeId = AccountTypeIds;
        accountBUmap.BusinessUnitIds = BUIds;
      });

    } else {
      this.selectedAccountType.forEach(accountType => {
        this.selectedBusinessType.forEach(bu => {
          this.WorkTypeBUdata.forEach(element => {
            if (element != null && element['accountTypeId'] == accountType['accountTypeId']
              && bu['businessUnitId'] == element['businessUnitID']) {
              if (!AccountTypeIds.includes(element['accountTypeId']))
                AccountTypeIds.push(element['accountTypeId']);
              if (!BUIds.includes(element['businessUnitID']))
                BUIds.push(element['businessUnitID']);
              // accountBUmap.AccountTypeId.push(element['accountTypeId']);
              // accountBUmap.BusinessUnitIds.push(element['businessUnitID']);              
            }
          });
        });
        accountBUmap.AccountTypeId = AccountTypeIds;
        accountBUmap.BusinessUnitIds = BUIds;
      });
    }
    let work = this.workTypeList.filter(WorkType => WorkType['workID'] == this.contractManagementForm.get('workType').value);
    accountBUmap.WorkType = work[0]['workType'];
    accountBUmap.WorkTypeId = work[0]['workID'];
    accountBUMapList.push(accountBUmap);
    let formData = new FormData;
    formData.append('AccountBUMappingData', JSON.stringify(accountBUMapList));
    formData.append('ContractName', this.contractManagementForm.get('contractName').value);
    formData.append('StartDate', this.contractManagementForm.get('contractStartDate').value);
    formData.append('ExpiryDate', this.contractManagementForm.get('contractExpiryDate').value);
    formData.append('AccountPerAEU', this.contractManagementForm.get('accountPerAeu').value);
    formData.append('AEUNo', this.contractManagementForm.get('noOfAeu').value);
    formData.append('ContractID', contractid)
    formData.append('LocationPerAEU', this.contractManagementForm.get('locationPerAeu').value);
    formData.append('AvgAccountSize', this.contractManagementForm.get('avgAccountSize').value);
    let accountIds: string;
    this.selectedAccountType.forEach(element => {
      if (accountIds == undefined)
        accountIds = element['accountTypeId'];
      else
        accountIds = accountIds + ',' + element['accountTypeId'];
    });
    formData.append('SelectedAccountType', accountIds);
    let buIds: string;
    this.selectedBusinessType.forEach(element => {
      if (buIds == undefined)
        buIds = element['businessUnitId'];
      else
        buIds = buIds + ',' + element['businessUnitId'];
    });
    formData.append('SelectedBUType', buIds);
    //formData.append('WorkID', this.selectedWorkType["workId"]);
    formData.append('OperationMod', this.operationMode);
    formData.append('ProjectManager', this.contractManagementForm.get('projectManager').value);
    formData.append('ClientID', this.clientID.toString());
    formData.append('ContractType', this.selectedContractType['id'].toString())
    this.getClientDetailsService.addEditContract(formData).subscribe(response => {
      let responsedata = response;
      if (responsedata == 1) {
        if (this.operationMode == '1')//contract added
          this.alertService.success(AlertMessages.contractModuleAlert);
        else //contract edited
          this.alertService.success(AlertMessages.contractModuleAlert1);

      }
      this.submitted = false;
      this.isEdit = false;
      this.getcontractDetails(this.clientID);//this.ngOnInit();
      this.RemoveValidators();
      this.contractManagementForm.reset();
    },
      err => {
        this.errorHandler.ErrorsHandler(err);
      })


  }

  editContractClick(value: any) {
    if (value['isActive'] == 0) {
      this.isEdit = false;
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
      return;
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    this.lblTitle = "Edit Contract";
    this.btnSubmitTxt = "Update";
    this.isEdit = true;
    this.editContract = value;
    this.isShowDiv = true;
    this.selectedContractType = [];
    this.selectedAccountType = [];
    this.selectedBusinessType = []
    let formData = new FormData();
    formData.append('contractId', String(value['contractId']));
    this.getClientDetailsService.PostRequest(AppSettings.API_ENDPOINT + AppRelativeUrl.EDITCONTRACTDETAILS, formData).subscribe(respose => {
      // console.log(respose);
      if (respose != undefined) {
        this.contractManagementForm.controls.contractName.patchValue(respose['tblEditedContractDetails'][0]['contractName']);
        this.contractManagementForm.controls.contractStartDate.patchValue(this.datePipe.transform(respose['tblEditedContractDetails'][0]['startDate'], 'yyyy-MM-dd'));
        this.contractManagementForm.controls.contractExpiryDate.patchValue(this.datePipe.transform(respose['tblEditedContractDetails'][0]['expiryDate'], 'yyyy-MM-dd'));
        this.contractManagementForm.controls.projectManager.patchValue(respose['tblEditedContractDetails'][0]['projectManager']);
        this.contractManagementForm.controls.avgAccountSize.patchValue(respose['tblEditedContractDetails'][0]['avgAccountSize']);
        this.contractManagementForm.controls.accountPerAeu.patchValue(respose['tblEditedContractDetails'][0]['accountPerAEU']);
        this.contractManagementForm.controls.locationPerAeu.patchValue(respose['tblEditedContractDetails'][0]['locationPerAEU']);
        this.contractManagementForm.controls.noOfAeu.patchValue(respose['tblEditedContractDetails'][0]['aeU_NO']);
        // let work=this.workTypeList.filter(WorkType=>WorkType['workID']==respose['tblEditedContractDetails'][0]['workId']);
        this.contractManagementForm.get('workType').setValue(respose['tblEditedContractDetails'][0]['workId']);
        let AccountBUMap: [];
        AccountBUMap = respose['tblAccountBUMapping'];
        let contract = AccountBUMap.map(item => item['contractTypeId'])
          .filter((value, index, self) => self.indexOf(value) === index);
        if (contract != undefined)
          this.selectedContractType = this.contractTypeList.find(element => (element["id"] == contract));
        this.contractManagementForm.get('contractType').setValue(this.selectedContractType);
        let AccountType = AccountBUMap.map(item => item['accountTypeId'])
          .filter((value, index, self) => self.indexOf(value) === index);
        AccountType.forEach(accountTypeId => {
          let Accounts = this.accounttypeList.find(element => (element["accountTypeId"] == accountTypeId));
          if (Accounts != undefined)
            this.selectedAccountType.push(Accounts);
        });
        let BUType = AccountBUMap.map(item => item['businessUnitId'])
          .filter((value, index, self) => self.indexOf(value) === index);
        BUType.forEach(buId => {
          let bu = this.BUList.find(element => (element["businessUnitId"] == buId));
          if (bu != undefined)
            this.selectedBusinessType.push(bu);
        });
        this.contractManagementForm.get("accountType").setValue(this.selectedAccountType);
        this.selectedAccountType.forEach(accountType => {
          if (accountType != undefined && accountType != null) {
            this.BUList = [];
            this.WorkTypeBUdata.forEach(element => {
              if (element != null && element['accountTypeId'] == accountType['accountTypeId']) {
                let business: { businessUnitId: string, businessUnitDescription: string } =
                  { businessUnitId: element['businessUnitID'], businessUnitDescription: element['businessUnitDescription'] };
                if (this.BUList.find(el => el['businessUnitId'] == element['businessUnitID']) == undefined)
                  this.BUList.push(business);
              }
            });
          }
        });
        this.contractManagementForm.get("businessUnit").setValue(this.selectedBusinessType);

      }
    },
      error => {
        this.errorHandler.ErrorsHandler(error);
      });
  }


  deactivateContract(item: any) {
    let action: string;
    if (item['isActive'] == false) {
      action = "Activate";
    }
    else {
      action = "Deactivate";
    }
    this.popupService.Confirmation('Are you sure?', item['contract Name'] + ' will be ' + action + '!', 'Yes, ' + action, 'No, Cancel').then(
      result => {
        if (result.value) {
          let formData = new FormData();
          formData.append('ContractID', item['contractId']);
          this.getClientDetailsService.PostRequest(AppSettings.API_ENDPOINT + AppRelativeUrl.DELETECONTRACTDETAILS, formData).subscribe(response => {
            this.alertService.success(AlertMessages.DELETECONTRACT);
            this.getcontractDetails(this.clientID);
          },
            error => {
              this.errorHandler.ErrorsHandler(error);
            });
        }
      }
    )
    this.getcontractDetails(this.clientID);
  }

  ValidDate(startDate: string, endDate: string): boolean {
    let start = new Date(startDate);
    let end = new Date(endDate);
    if (start < end) {
      return false;
    }
    else {
      return true
    }
  }
  saveAsContract(item: any) {
    this.openModal = true;
    this.idContract = item['contractId'];
    this.contractName = item['contractName'];

  }
  saveAsSubmit() {

    if (this.ValidDate(this.SaveAsContractForm.get('contractStartDate').value,
      this.SaveAsContractForm.get('contractExpiryDate').value)) {
      alert('Expiry date must be greater than start date')
      return

    }
    let formData = new FormData;
    formData.append('ContractID', String(this.idContract))
    formData.append('ContractName', this.SaveAsContractForm.get('contractName').value);
    formData.append('StartDate', this.SaveAsContractForm.get('contractStartDate').value);
    formData.append('ExpiryDate', this.SaveAsContractForm.get('contractExpiryDate').value);
    formData.append('ClientID', this.clientID);

    this.getClientDetailsService.saveAsContractDetail(formData).subscribe(response => {
      let responsedata = response;
      this.alertService.success(AlertMessages.contractModuleAlert);
      this.ngOnInit();
      this.closebutton.nativeElement.click();
    },
      err => {
        this.errorHandler.ErrorsHandler(err);
        this.closebutton.nativeElement.click();
      })
  }

  accessData($event) {
    this.valueRecieved = $event;
    this.hideVIewClient = false;
  }
  selectRow(trId: string) {
    CommonMethod.selectRow(trId)
  }
  Calculate() {
    if (this.contractManagementForm.controls["accountPerAeu"].invalid || this.contractManagementForm.controls['locationPerAeu'].invalid) {
      this.CalCultedAEU = 0;
      return;
    }
    if (this.contractManagementForm.controls["accountPerAeu"].value != undefined
      && this.contractManagementForm.controls['locationPerAeu'].value != undefined
      && this.contractManagementForm.controls['locationPerAeu'].value > this.contractManagementForm.controls["accountPerAeu"].value) {
      this.CalCultedAEU = Number(this.contractManagementForm.controls['locationPerAeu'].value) / Number(this.contractManagementForm.controls["accountPerAeu"].value);
      this.CalCultedAEU = this.CalCultedAEU.toFixed(2);
    }
    else {
      this.CalCultedAEU = 0;
    }

  }
}
