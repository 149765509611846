
import { Authenticateuser } from './Authenticateuser';
import { Router } from '@angular/router';
import { appInjector } from './Injector';

export class CommonMethod {


  constructor() {

  }



  public static selectRow(trId: string) {
    let tr = document.getElementById(trId);
    let table = document.getElementById('tabledetail') as HTMLTableElement;
    let rows = table.rows;
    for (var i = 0; i < rows.length; i++) {
      rows[i].classList.remove('highLightSelectedRow');
    }
    tr = document.getElementById(trId);
    tr.classList.add("highLightSelectedRow");
  }

  public checkAccess(featureId: any) {
    // alert('here')
    //this.waitElement(compareUrl,featureId)
    // for (let i = 0; i < compareUrl.length; i++) {
    //   if (compareUrl[i]["id"] == featureId) {
    //     return true;
    //   }
    // }
    // return false;

    let eleColumn = document.getElementsByClassName('nav-item')
    // if (eleColumn.length > 2)
    // this.waitElement(featureId);
  }




  waitElement(featureId: string) {
    let argType = typeof featureId;
    let featureNumber: string;
    if (argType === "string")
      featureNumber = featureId
    else
      featureNumber = String(featureId);

    setTimeout(function () {

      let compareUrl = Authenticateuser.menuObj;
      // let eleColumn = document.getElementsByClassName('nav-item');
      // alert(eleColumn.length)
      if (compareUrl == null || compareUrl == undefined) {
        //// user is not logged in 
        // this.waitElement();
        /// navigate to login page
        this.waitElement(featureId);

      }
      // else if (eleColumn.length < 2) {
      //   this.waitElement(featureId);
      //   /// wait till you get the elements 
      //   // alert(eleColumn.length)
      // }
      else {
        // alert(compareUrl)
        let ifMatched = false;
        for (let i = 0; i < compareUrl.length; i++) {
          if (compareUrl[i]["id"] === featureId) {
            // alert(compareUrl)
            // return true;
            ifMatched = true;
            // return true;
            break;
          }
          //  }
          //// code to autheticate
        }
        if (!ifMatched) {
          const router: Router = appInjector().get(Router);
          router.navigate(["/error"]);
        }
      }

      // return true

    }, 1000);
    // return ifMatched;
  }
  public CheckFeatureAccess(FeaturesList: any, Feature: any): boolean {
    for (let i = 0; i < FeaturesList.length; i++) {
      if (FeaturesList[i]["featureId"] == Feature) {
        return true
      }
    }
    return false;
  }

  public validateSearch(input: string): boolean {

    if (input.trim() === '') {
      return false;
    }
    var format = /[`!#$%^*()+\=\[\]{};':"\\|,<>\/?~]/;

    if (format.test(input))
      return true;
    else
      return false;
  }



  public static selectMenuBYRouterLink(routerLink: string) {
    let el = [].slice.call(document.getElementsByClassName('nav-link'));
    let elNew = [].slice.call(document.getElementsByClassName('new-nav-link'));

    for (let i = 0; i < el.length; i++)
      el[i].classList.remove('active');


    for (let i = 0; i < elNew.length; i++)
      elNew[i].classList.remove('active');


    el = [].slice.call(document.getElementsByClassName('menu-class'));


    // for (let i = 0; i < el.length; i++)
    //   el[i].classList.remove("active");
    for (let i = 0; i < el.length; i++)
      el[i].setAttribute("style", "background-color: unset; color:black;");

    // var element = document.getElementById(eleName);
    // element.classList.add("active");
    let ele = document.querySelectorAll('a[href="' + routerLink + '"]')

    if (ele != undefined && ele.length > 0) {
      console.log(ele);
      ele[0].classList.add("active");
    }

  }
  public static GenrateUniqueIdentity() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

}
