<!-- <style>
    .SignInBtn {
        border-radius: 0px;
        width: 48%;
        border-bottom-color: #c42032;
        border-top-color: #c42032;
        border-right-color: #c42032;
        border-left-color: #c42032;
        background-color: #c42032;
        font-weight: 200;
        outline: auto;
        height: 2rem;
        padding: 0px !important;
    }
</style>
<div class=" setBGImage">

    <div class="container MainContainer">
        <div class="row">
            <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 2rem !important;">
                <div class="alert alert-success alert-dismissible" *ngIf="labelvisible">
                    An email with reset instructions has been sent to the associated account
                </div>
            </div>
            <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 2rem !important;">
                <div class="alert alert-dismissible" [ngClass]="IsSuccessMessage ? 'alert-success' : 'alert-danger'"
                  *ngIf="showFormError">
                  <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                  {{formError}}
                </div>
              </div>
              <div class="col-lg-6">
                   <img  src="../../../assets/img/RMS.png" style="width:102%;">
                <br />
                <img src="../../../assets/img/MoodysRMSLogo1.png" style="    width: 82%;
                margin-top: 65px;
                margin-left: 52px;" />
              </div>
            <div class="col-lg-6">

                <form [formGroup]="formdata" (ngSubmit)="onSubmit(usermail.value)">
                    <table class="table" style="width:100%;margin-top:8%">
                        <tr>
                            <td style="text-align:center;border-top-style:none">
                                <br />
                                <h4 class="HeadLogin">Forgot Password</h4>
                                <h1><i class="fa fa-lock"></i></h1>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <div style="padding-top:5%" class="input-group">
                                    <span class="input-group-addon" id="sizing-addon1"
                                        style="border-radius:0px;background-color:#c42032">
                                        <i class="fa fa-envelope InputIcon"></i></span>
                                    <input type="text" class="form-control inputClass" maxlength="60" autofocus
                                        #usermail (ngModelChange)="ShowUserNameError();" formControlName="userid"
                                        placeholder="Email Id" aria-describedby="sizing-addon1"
                                        style="border-radius:0px;max-width:100%;  font-weight: 200;" />
                                    <div class="col-lg-12 ErrorMessage NoPadding"
                                        style="color: #c42032; height: 1rem !important;">
                                        <div *ngIf="submitted && f.userid.errors">
                                            <div *ngIf="f.userid.errors.required ">Email Id is required.</div>
                                            <div *ngIf="f.userid.errors.pattern">Please check the format of the email
                                                address entered.</div>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td style="border-top-style:none;">
                              <div class="input-group captchaDiv">
                                <re-captcha style="margin-left: 42px;" formControlName="recaptchaReactive" class="gc-reset"  (resolved)="resolved($event)">
                                </re-captcha>
                              </div>
              
                            </td>
                          </tr>
                        <tr>
                            <td style="padding-top:5%">
                                <button type="submit" class="btn btn-success SignInBtn float-left" style="width:48%;">
                                    Send
                                </button>
                                <a routerLink="/login" class="btn btn-success SignInBtn float-right" style="width:48%;">
                                    Back</a>
                               </td>
                        </tr>
                    </table>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col" style="text-align:center;">
                <h6 style="font-size: .8rem; color:#989696;">© {{Year | date: "y"}} Risk Management Solutions, Inc. All
                    Rights Reserved.
                </h6>
                <br />
            </div>
        </div>
    </div>
</div> -->





<div class=" setBGImage">
    <div>
        <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 2rem !important;">
            <div class="alert alert-dismissible" [ngClass]="IsSuccessMessage ? 'alert-success' : 'alert-danger'"
                *ngIf="showFormError">
                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                {{formError}}
            </div>
        </div>
        <div class="main">
            <div class="align-middle">
                <div class="container" style="width: 35%;padding-top: 30px;">
                    <div class="card"
                        style="height: 530px;align-items: center;border-radius: 30px;padding-left: 25px;padding-right:25px;width: fit-content;">
                        <div style="text-align: center;">
                            <div class="app-header">
                                <img style="padding-top: 10px;" src="../../../assets/svg/MoodysBlue.svg" alt="Logo"
                                    class="app-logo">
                                <h3 class="MoodyTittleColor2">Data Solution Portal</h3>
                            </div>
                        </div>
                        <div class="card-body">
                            <form [formGroup]="formdata" (ngSubmit)="onSubmit(usermail.value)">
                                <div class="input-group" style="border-radius: 10px;">

                                    <div class="input-group-prepend">
                                        <div class="input-group-text"><i class="fa fa-envelope MoodyTittleColor2"></i>
                                        </div>
                                    </div>
                                    <input autofocus #usermail (ngModelChange)="ShowUserNameError();"
                                        formControlName="userid" placeholder="Email Id" aria-describedby="sizing-addon1"
                                        style="height: 100%;border-bottom-right-radius: 10px;
                        border-top-right-radius: 10px;" type="email" class="form-control" id="nombre" name="nombre"
                                        required>

                                    <div class="col-lg-12 ErrorMessage NoPadding"
                                        style="color: #c42032; height: 1rem !important;">
                                        <div *ngIf="submitted && f.userid.errors">
                                            <div *ngIf="f.userid.errors.required ">Email Id is required.</div>
                                            <div *ngIf="f.userid.errors.pattern">Please check the format of the email
                                                address entered.</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="input-group captchaDiv" style="margin-top: 10px;justify-content: center;align-items: center;">
                                    <re-captcha formControlName="recaptchaReactive"
                                        class="gc-reset" (resolved)="resolved($event)">
                                    </re-captcha>
                                </div>
                                <div class="text-center" style="margin-top: 20px;">
                                    <input type="submit"
                                        style="width: 40%; height: 50px; border-radius: 10px; display: inline-block;"
                                         class="btn btn-success SignInBtn float-left" value="Send"
                                        class="btn MoodysSubmitButton">
                                        <input 
                                        style="width: 40%; height: 50px; border-radius: 10px; display: inline-block;"
                                        routerLink="/login" class="btn btn-secondary float-right" value="Back"
                                        >
                                        <!-- <a routerLink="/login" class="btn btn-success SignInBtn float-right" style="width:48%;">
                                            Back</a> -->
                                </div>
                            </form>
                        </div>                        
                        <div class="row">
                            <div class="col MoodyTittleColor2" style="text-align:center;">
                                <h6 style="font-size: xx-small">© {{Year | date: "y"}} Moody's Analytics, Inc.
                                    and/or its licensors and affiliates (collectively, "Moody's"). All rights
                                    reserved.
                                    Reserved.</h6>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>