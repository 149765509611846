import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule, LowerCaseUrlSerializer, ModuleRouting } from './app-routing.module';
import { AppComponent } from './app.component';
// import { HeaderComponent } from './Common/header/header.component';
import { FooterComponent } from './Common/footer/footer.component';
import { LoginComponent } from './Login/login/login.component';
import { ForgetPasswordComponent } from './Login/forget-password/forget-password.component';
import { ResetPasswordComponent } from './Login/reset-password/reset-password.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginTestComponent } from './Common/login-test/login-test.component';
import { LogoutComponent } from './Login/logout/logout.component';
import { CookieService } from 'ngx-cookie-service';
import { ContactSupportComponent } from './Login/contact-support/contact-support.component'
import { ContactSupportTemplateComponent } from './Login/contact-support/contact-support-template/contact-support-template.component'
import { UrlSerializer } from '@angular/router';
import { AuthInterceptor } from './../services/Common/auth.interceptor';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
// import { MatDividerModule } from '@angular/material/divider';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatIconModule } from '@angular/material/icon';
// import { MatButtonModule } from '@angular/material/button';
// import { MatMenuModule } from '@angular/material/menu';
// import { MatListModule } from '@angular/material/list';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { SidebarComponent } from './Common/sidebar/sidebar.component';
// import { MatSidenavModule } from '@angular/material/sidenav';
import { ChangePasswordComponent } from './Login/changepassword/changepassword.component';

//import { AccessManagementModule } from './access-management/access-management.module';
import { UserModule } from './user/user.module';
// import { ProcessTrackingComponent } from './process-tracking/view-process-tracking/view-process-tracking.component';
// import { AreaComponent } from './Common/widgets/area/area.component';
// // import { CardComponent } from './Common/widgets/card/card.component';
// import { PieComponent } from './Common/widgets/pie/pie.component';
// import { MatCardModule } from '@angular/material/card';
// import { MatTableModule } from '@angular/material/table';
// import { MatPaginatorModule } from '@angular/material/paginator';
// import { UploadDataComponent } from './Upload/upload-data/upload-data.component';
import { AccountReportComponent } from './Report/account-report/account-report.component';
// import { MatExpansionModule } from '@angular/material/expansion';
// import{Select2Module} from'./../node_modules/ng-select2-component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HeaderComponent } from './Common/header/header.component';
import { RoleModule } from './role/role.module';
import { UploadModule } from './upload/upload.module';
import { SidebarComponent } from './Common/sidebar/sidebar.component';
import { AlertComponent } from './Common/alert/alert.component';
import { LoaderComponent } from './Common/loader/loader.component'
import { ProcessTrackingModule } from './process-tracking/process-tracking.module';
import { ClientModule } from './client/client.module';
import { FirsttimepasswordchangeComponent } from './Login/firsttimepasswordchange/firsttimepasswordchange.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { DatePipe } from '@angular/common';
import { ErrorPageComponent } from './Common/error-page/error-page.component';
import { ErrorPageTwoComponent } from './Common/error-page-two/error-page-two.component';
import { ScrollTopComponent } from './Common/scroll-top/scroll-top.component';
import { SidebarPipe } from '../common/SidebarPipe';
import { ChildMenuPipe } from '../common/ChildMenuPipe';
import { SessionExpireComponent } from './Login/session-expire/session-expire.component';
import { RecentJobsModule } from "./recent-jobs/recent-jobs/recent-jobs.module";
import { TrackCapsDirective } from "src/common/track-caps-directive";
import { MaintenanceComponent } from './Login/maintenance/maintenance/maintenance.component';
import { MasterModule } from "./master/master.module";
import { ConvertToRLImportFilesComponent } from "./convert-to-rlimport-files/convert-to-rlimport-files.component";
import { GeneratePasswordlinkComponent } from './Login/generate-password/generate-passwordlink/generate-passwordlink.component';
import { PowerbiReportComponent } from './PowerbiReport/PowerbiReport.component';
import { SsAccountTrackerComponent } from "./process-tracking/ss-account-tracker/ss-account-tracker.component";
import { SsUploadComponent } from "./upload/ss-upload/ss-upload.component";

@NgModule({
  declarations: [	
    AppComponent,
    LoaderComponent,
    // HeaderComponent,
    FooterComponent,
    LoginComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    LoginTestComponent,
    LogoutComponent,
    ContactSupportComponent,
    ContactSupportTemplateComponent,
    ChangePasswordComponent,
    // SidebarComponent,
    // AreaComponent,
    // // CardComponent,
    // PieComponent,
    // UploadDataComponent,
    AccountReportComponent,
    HeaderComponent,
    AccountReportComponent,
    SidebarComponent,
    AlertComponent,
    FirsttimepasswordchangeComponent,
    ErrorPageComponent,
    ErrorPageTwoComponent,
    ScrollTopComponent,
    SidebarPipe,
    ChildMenuPipe,
    SessionExpireComponent,
    TrackCapsDirective,
    MaintenanceComponent,
    ConvertToRLImportFilesComponent,
    GeneratePasswordlinkComponent,
      PowerbiReportComponent
   ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,    
    RecaptchaModule,
    RecaptchaFormsModule,
    // MatToolbarModule,
    // MatDividerModule,
    // MatIconModule,
    // MatButtonModule,
    // MatMenuModule,
    // MatListModule,
    // FlexLayoutModule,
    BrowserAnimationsModule,
    // MatSidenavModule,
    RecaptchaFormsModule,
      UserModule,
    // MatCardModule,
    // MatPaginatorModule,
    // MatTableModule,
    // MatExpansionModule,
    // Select2Module,
    NgSelectModule,
    ProcessTrackingModule,
    ClientModule,
    RoleModule,
    UploadModule,
    DashboardModule,
    RecentJobsModule,
    MasterModule,


  ],

  providers: [
    CookieService,
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: '6LeGb_QZAAAAANCHs9sVeTJBLl-gXz3W6mSK-a8g' } as RecaptchaSettings },
    DatePipe
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
