import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientRoutingModule } from './client-routing.module';
import { ViewClientComponent } from './view-client/view-client.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { ContractClientComponent } from './contract-client/contract-client.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ClientelementsettingComponent } from './clientelementsetting/clientelementsetting.component';
import { ElementvalidationsettingComponent } from './elementvalidationsetting/elementvalidationsetting.component';

@NgModule({
  declarations: [ViewClientComponent,
     EditClientComponent,
      ContractClientComponent,
      ClientelementsettingComponent,
      ElementvalidationsettingComponent],
  imports: [
    CommonModule,
    ClientRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,    
    NgSelectModule,
    FormsModule
  ]
})
export class ClientModule { }
