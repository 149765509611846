import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewRoleComponent } from './view-role/view-role.component';
import { ModuleWithProviders } from '@angular/core';


const routes: Routes = [
  {
    path: '',
    component: ViewRoleComponent

  },
  {
    path: 'rolemanagement',
    component: ViewRoleComponent
  }];


export const ModuleRouting: ModuleWithProviders = RouterModule.forChild(routes);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoleRoutingModule { }
