import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';

export interface EmbedInfo {
  EmbedToken: {
    Token: string;
    Expiration: string;
  };
  EmbedReport: Array<{
    EmbedUrl: string;
  }>;
}

@Injectable({
  providedIn: 'root',
})
export class PowerBiService {
  private data = new BehaviorSubject<any>(null);
  private reportPagesSource = new BehaviorSubject<any[]>([]);
  currentReportPages = this.reportPagesSource.asObservable();
  private pageSelectSubject = new Subject<string>();
  pageSelectAction$ = this.pageSelectSubject.asObservable();
  private ReportSelectedSubject = new BehaviorSubject<{ Reportname: any; MenuID: any }>(null);
  ReportSelected$ = this.ReportSelectedSubject.asObservable();
  private cachedReports = new Map<string, any>();
  private reportNameKey = 'reportName';
  private menuIdKey = 'selectedMenuId'; // Key for storing MenuID
  constructor(private http: HttpClient) {}
  shareData(data: any) {
    this.data.next(data);
    console.log(data)
  }

  updateReportPages(pages: any[]) {
    this.reportPagesSource.next(pages);
  }
  
  selectReportPage(pageName: string) {
    this.pageSelectSubject.next(pageName);
  }
  onReportnameselection(Reportname:string,MenuID:any){
    this.ReportSelectedSubject.next({ Reportname, MenuID });
    localStorage.setItem(this.reportNameKey, Reportname);
    localStorage.setItem(this.menuIdKey, JSON.stringify(MenuID)); 
  }
  getReportfromstorage(): string | null {
    return localStorage.getItem(this.reportNameKey);
  }
  getReportIDfromstorage(): string | null {
    return localStorage.getItem(this.menuIdKey);
  }
  
  getData() {
    return this.data.asObservable();
  }
  getEmbedInfo(ReportName:any): Observable<EmbedInfo> {
       // return this.http.get<EmbedInfo>('https://localhost:44331/Dashboard/getembedinfo');
    return this.http.post<EmbedInfo>(AppSettings.API_ENDPOINT + AppRelativeUrl.GETREPORTS,ReportName).pipe(map(res => {
      return res;
    },
      error => {
        return error.json();
      }))
  }
  exportReport() {
    
    return this.http.get(AppSettings.API_ENDPOINT + AppRelativeUrl.EXPORTREPORTS, {responseType: 'blob'});
  }
  getReportName() {
    // alert(1)
    return this.http.get(AppSettings.API_ENDPOINT + AppRelativeUrl.GETREPORTNAME).pipe(map(result => {
      // console.log(result)
      // alert(result)
      return result;
    },
      errors => {
        console.log(errors)
        //console.log(errors)
        // this.errorHandler(errors)
      }))
  }

  public cacheReport(reportName: string, reportObject: any): void {
   
    this.cachedReports.set(reportName, reportObject);
  }

  public getReport(reportName: string): any {
    return this.cachedReports.get(reportName);
  }

  public hasReport(reportName: string): boolean {
    return this.cachedReports.has(reportName);
  }
}

