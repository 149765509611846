import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';

@Injectable({
  providedIn: 'root'
})
export class RecentJobsService {

  constructor(private httpClient: HttpClient,private datePipe:DatePipe) { }

  GetRecentJobs(postData: FormData) {
    // let formData = new FormData();
    // formData.append('UserId', String(UserId))
    // formData.append('AccountId',AccountId);
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETRECENTJOBS, postData).pipe(map(response => {
      let result = response;
      return result;
    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
  ExportData( totalCount: string) {
    let formData: FormData = new FormData();
    formData.append('FilterData', '');
    formData.append('StartRow', '0');
    formData.append('RowLength', totalCount);
    formData.append('OrderFilter', '');
    formData.append('ExecType', '1');
    
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETRECENTJOBS, formData).pipe(map(response => {

      let list: any = response['table'];
      for (let i = 0; i < list.length; i++) {
       delete list[i]['statusSign'];  
       list[i]['start Date']=this.datePipe.transform(list[i]['start Date'],'dd-MMM-yyyy hh:mm aaa');  
       list[i]['end Date']=this.datePipe.transform(list[i]['end Date'],'dd-MMM-yyyy hh:mm aaa');  

      }
      for (let i = 0; i < list.length; i++) {
        Object.keys(list[i]).forEach(key => {
          list[i][key.toUpperCase()] = list[i][key];
          delete list[i][key];
        })
      }
      return list;
    }))
  }
  

}
