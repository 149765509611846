import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-account-planned',
  templateUrl: './account-planned.component.html',
  styleUrls: ['./account-planned.component.css']
})
export class AccountPlannedComponent implements OnInit, OnChanges {
  @Input() accountPlanned: any;
  fieldHeader: any[] = [];
  uploadData: any;
  isData: boolean = false;
  @Input() hasUploadAccess:boolean;
  constructor() { }

  ngOnInit() {

  }
  ngOnChanges() {
    this.fillData();
  }
  fillData() {

    if (this.accountPlanned != null && this.accountPlanned != undefined && this.accountPlanned != '') {
      this.uploadData = this.accountPlanned
      this.fieldHeader = [];
      this.fieldHeader = this.fieldHeader.concat(Object.keys(this.accountPlanned[0]));
      this.isData=true
    }
    else{
      this.isData=false;
    }

  }

}
