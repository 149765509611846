<!-- <div class="setBGImage">
    <div class="Container MainContainer">
      <div [ngClass]="ifError? 'alert-danger' : 'alert-success' " class="alert  alert-dismissible" *ngIf="notification">
        {{ errorMessage }}
        <span *ngIf="generatePasswordLink"><a routerLink="/forgotpassword" routerLinkActive="active">Click here</a> to raise
          another request.</span>
        <span *ngIf="loginLink"><a routerLink="/login" routerLinkActive="active">Click here</a> to go to Login.</span>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <img  src="../../../../assets/img/RMS.png" style="width:102%;">
          <br />
          <img src="../../../assets/img/MoodysRMSLogo1.png" style="width:100%;margin-top: 40px;" />
        </div>
  
        <div class="col-lg-6" *ngIf="generatePasswordtemplate" style="margin-top: 20px;">
          <form [formGroup]="formdata" class="col-lg-12" (ngSubmit)="onSubmit(UserId.value, password.value)">
            <table class="table">
              <tr>
                <td style="text-align: center; border-top-style: none;">
                  <br />
                  <h4 class="HeadLogin">Generate Password</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="input-group" style="padding-top: 4%;">
                    <span class="input-group-addon" id="sizing-addon1"
                      style="border-radius: 1px; background-color: #c42032;">
                      <i class="fa fa-envelope InputIcon"></i></span>
                    <input type="text" class="form-control inputClass" autofocus placeholder="EmailId" #UserId
                      formControlName="UserName" (ngModelChange)="ShowUserNameError()" aria-describedby="sizing-addon1"
                      name="email" autocomplete="new-email" style="
                        border-radius: 0px;
                        max-width: 100%;
                        font-weight: 200;
                      " />
                    <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                      <div *ngIf="ShowUserNameErrors && f.UserName.errors">
                        <div *ngIf="f.UserName.errors.required">
                          Email Id is required.
                        </div>
                        <div *ngIf="f.UserName.errors.pattern">
                          Please check the format of the email address entered.
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="
                    border-top-style: none;
                    padding-top: 0px;
                    padding-bottom: 15px;
                  ">
                  <div class="input-group">
                    <span class="input-group-addon" id="sizing-addon1"
                      style="border-radius: 1px; background-color: #c42032;"><i class="fa fa-lock InputIcon"
                        style="font-size: 14.5px;"></i></span>
                    <input (capsLock)="capsOn=$event" type="password" id="inputPassword" name="password"
                      autocomplete="new-password" class="form-control inputClass" placeholder="New password" #password
                      formControlName="password" (ngModelChange)="ShowUserPasswordError()"
                      aria-describedby="sizing-addon1" style="
                        border-radius: 0px;
                        max-width: 100%;
                        font-weight: 200;
                      " />
  
                    <div *ngIf="ShowUserPasswordErrors && f.password.errors" class="tooltip" style="display: contents;">
                      <i class="fa fa-info-circle" style="
                          font-size: 18.5px;
                          color: #c42032;
                          margin-top: 8px;
                          position: absolute;
                          right: 2%;
                        ">
                      </i>
                      <span class="tooltiptext">
                        <b>*</b> Password minimum length : 10<br />
                        <b>*</b> Password maximum length : 16<br />
                        <b>*</b> Minimum required numeric number : 1<br />
                        <b>*</b> Minimum required lower-case letters : 1<br />
                        <b>*</b> Minimum required upper-case letters : 1<br />
                        <b>*</b> Minimum required special characters : 1<br />
                        <b>*</b> Allowable special characters :!&#64;#$%*()_+^&&#125;{{
                        "{"
                        }}:;?."<br />
                      </span>
                    </div>
                   
                    <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                      <div *ngIf="ShowUserPasswordErrors && f.password.errors">
                         <div>Password is invalid</div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="
                    border-top-style: none;
                    padding-top: 0px;
                    padding-bottom: 15px;
                  ">
                  <div class="input-group">
                    <span class="input-group-addon" id="sizing-addon1"
                      style="border-radius: 1px; background-color: #c42032;"><i class="fa fa-lock InputIcon"
                        style="font-size: 14.5px;"></i></span>
                    <input type="password" id="inputPassword" class="form-control inputClass" autocomplete="new-password"
                      placeholder="Confirm password" formControlName="confirmPassword" name="password"
                      (ngModelChange)="ShowConfirmPasswordError()" aria-describedby="sizing-addon1" style="
                        border-radius: 0px;
                        max-width: 100%;
                        font-weight: 200;
                      " />
                    <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                      <div *ngIf="
                          ShowConfirmPasswordErrors && f.confirmPassword.errors
                        ">
                        <div *ngIf="f.confirmPassword.errors.required">
                          Confirm Password is required
                        </div>
                        <div *ngIf="f.confirmPassword.errors.mustMatch">
                          Passwords must match
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 ErrorMessage  NoPadding" style="height: 1rem !important;">
                      <div *ngIf="capsOn">
                        <div style="background: #fffb0733;">
                          Warning: Caps Lock is ON
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td style="padding-top: 5%;">
                  <button type="submit" class="btn btn-success SignInBtn">
                    Submit
                  </button>
                </td>
              </tr>
            </table>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col" style="text-align: center;">
          <h6 style="font-size: 0.8rem; color: #989696;">
            © {{Year | date: "y"}} Risk Management Solutions, Inc. All Rights Reserved.
          </h6>
          <br />
        </div>
      </div>
    </div>
  </div> -->


  



<div class=" setBGImage">
  <div >
    <div class="main">
    <div class="col-lg-12 ErrorMessage NoPadding" [ngClass]="ifError? 'alert-danger' : 'alert-success' " class="alert  alert-dismissible" *ngIf="notification">
      {{ errorMessage }}
       </div>
    
      <div class="container " style="width: 35%;padding-top: 30px;">
        <div class="align-middle" >     
            <div class="card " style="height: 530px;align-items: center;border-radius: 30px;padding-left: 25px;padding-right:25px;">
              <div style="text-align: center;" >
                <div class="app-header " >
                  <img  src="../../../assets/svg/MoodysBlue.svg" alt="Logo" class="app-logo">
                  <h3 class="MoodyTittleColor2">Data Solution Portal</h3>
                </div>
              </div>
            <div class="card-body" style="padding-top: 60px;">
              <span *ngIf="generatePasswordLink"><a routerLink="/forgotpassword" routerLinkActive="active">Click here</a> to raise
                another request.</span>
              <span *ngIf="loginLink"><a routerLink="/login" routerLinkActive="active">Click here</a> to go to Login.</span>
          
              <form *ngIf="generatePasswordtemplate"  [formGroup]="formdata" class="col-lg-12" (ngSubmit)="onSubmit(UserId.value, password.value)" >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-user"></i></span>
                  </div>
                  <input autofocus placeholder="EmailId" #UserId
                  formControlName="UserName" (ngModelChange)="ShowUserNameError()" aria-describedby="sizing-addon1"
                  name="email" autocomplete="new-email" class="form-control inputClass"  style="height: 100%;border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;" >
                     <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                      <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                        <div *ngIf="ShowUserNameErrors && f.UserName.errors">
                          <div *ngIf="f.UserName.errors.required">
                            Email Id is required.
                          </div>
                          <div *ngIf="f.UserName.errors.pattern">
                            Please check the format of the email address entered.
                          </div>
                        </div>
                      </div>
                </div>
                </div>
              <div class="input-group" >
                <div class="input-group-prepend">
                  <span class="input-group-text" ><i class="fas fa-key"></i></span>
                </div>
                <input (capsLock)="capsOn=$event" type="password" id="inputPassword" name="password"
                autocomplete="new-password" class="form-control inputClass" placeholder="New password" #password
                formControlName="password" (ngModelChange)="ShowUserPasswordError()"
                aria-describedby="sizing-addon1"  style="height: 100%;border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;" class="form-control" placeholder="Password">
                 <div *ngIf="ShowUserPasswordErrors && f.password.errors" class="tooltip" style="display: contents;">
                  <i class="fa fa-info-circle" style="
                      font-size: 18.5px;
                      color: #c42032;
                      margin-top: 8px;
                      position: absolute;
                      right: 2%;
                    ">
                  </i>
                  <span class="tooltiptext">
                    <b>*</b> Password minimum length : 10<br />
                    <b>*</b> Password maximum length : 16<br />
                    <b>*</b> Minimum required numeric number : 1<br />
                    <b>*</b> Minimum required lower-case letters : 1<br />
                    <b>*</b> Minimum required upper-case letters : 1<br />
                    <b>*</b> Minimum required special characters : 1<br />
                    <b>*</b> Allowable special characters :!&#64;#$%*()_+^&&#125;{{
                    "{"
                    }}:;?."<br />
                  </span>
                </div>
               
                <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                  <div *ngIf="ShowUserPasswordErrors && f.password.errors">
                     <div>Password is invalid</div>
                  </div>
                </div>
                <div class="col-lg-12 ErrorMessage  NoPadding" style="height: 1rem !important;">
                  <div *ngIf="capsOn">
                    <div style="background: #fffb0733;">
                      Warning: Caps Lock is ON
                    </div>
                  </div>
                </div>
              </div>
              

              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" ><i class="fas fa-key"></i></span>
                </div>
                <input type="password" id="inputPassword" class="form-control inputClass" autocomplete="new-password"
                placeholder="Confirm password" formControlName="confirmPassword" name="password"
                (ngModelChange)="ShowConfirmPasswordError()" aria-describedby="sizing-addon1"   style="height: 100%;border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;" placeholder="Password">
                 <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                  <div *ngIf="
                      ShowConfirmPasswordErrors && f.confirmPassword.errors
                    ">
                    <div *ngIf="f.confirmPassword.errors.required">
                      Confirm Password is required
                    </div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">
                      Passwords must match
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center" style="margin-top: 10px;">
                  <input type="submit" style="width: 50%; height: 60px; border-radius: 10px; display: inline-block;"  type="submit"  class="btn btn-success SignInBtn float-left" value="Submit"  class="btn MoodysSubmitButton">
              </div>
               </form>              
            </div> 
            <div style="padding-top: 90px;width: 100%;" class="row">
              <div class="col MoodyTittleColor2" style="text-align:center;">
                <h6 style="font-size: xx-small;">© {{Year | date: "y"}}  Moody's Analytics, Inc. and/or its licensors and affiliates (collectively, "Moody's"). All rights reserved.
                  Reserved.</h6>
                <br />
              </div>
            </div>  
        </div>
      </div>
    </div>
  </div>    
</div>
</div>
