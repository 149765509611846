import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private http: HttpClient) { }
  DownloadFAQ(path: string): Observable<HttpEvent<Blob>> {
    return this.http.request(new HttpRequest('GET', path, {
      reportProgress: true,
      responseType: 'blob'
    }));
  }


}
