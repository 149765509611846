import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from "@angular/forms";
import { EmailService } from "../../../services/LoginServices/email.service";
import { Router } from '@angular/router';


@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./../login/login.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  constructor(private fb: UntypedFormBuilder, private emailService: EmailService, private router: Router) { }
  formdata: UntypedFormGroup;
  submitted = false;
  labelvisible = false;
  isCaptchaDone = false;
  showFormError=false;
  IsSuccessMessage=false;
  formError:any;
  check: boolean;
  Year: string=Date();
  ngOnInit() {
    this.formdata = this.fb.group({
      userid: ["", [Validators.required,Validators.email,Validators.pattern('^[A-Za-z0-9.\'_%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,10}$')]],
      recaptchaReactive: new UntypedFormControl('null', Validators.required)
    });
  }
  get f() {
    return this.formdata.controls;
  }
  ShowUserNameError() {
    this.submitted = true;
  }
  onSubmit(usermail: string) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.formdata.invalid) {
      return;
    }
    if (!this.isCaptchaDone) {
      this.formError = 'Please verify captcha to continue';
      this.IsSuccessMessage = false;
      this.showFormError = true;
      return;
    }
    this.emailService.ForgotPassword(usermail.toLowerCase()).subscribe(
      res => {
        let resp: boolean = Boolean(res);

        this.submitted = false;
      },
      err => {
        console.log(err);
      }
    );
    this.labelvisible = true;
    this.formdata.reset();
    this.submitted = false;
    this.IsSuccessMessage = true;
    this.showFormError = false;
  }
  public resolved(captchaResponse: string) {
    // alert(captchaResponse);
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse === null || captchaResponse === '') {
      this.isCaptchaDone = false;
    }
    else
      this.isCaptchaDone = true;
  }
}

