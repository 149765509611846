import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/services/Common/alert.service';
import { RecentJobsService } from 'src/services/recentJobs/recent-jobs.service';
import { ErrorHandler } from 'src/common/error-handler';
import { CommonMethod } from 'src/common/CommonMethod';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { ExportService } from 'src/services/Common/export.service';

@Component({
  selector: 'app-recent-jobs',
  templateUrl: './recent-jobs.component.html',
  styleUrls: ['./recent-jobs.component.css']
})
export class RecentJobsComponent implements OnInit {

  fieldHeader: any = []
  pageSize: number = 20;
  pageStart: number = 0;
  search: string = '';
  data: any[] = [];
  totalCount: number = 0;
  pageCount: number;
  currentPage: number = 1;
  columSelected: any = 4;
  nextDotShow: boolean = false;
  item: number[] = [];
  selectedPageNo: any = 1;
  sortUP: boolean = true;
  sortDesc: boolean = false;
  rowCount: number = 0;
  previousDotShow: boolean = false;
  searchInput: string = '';
  isData: boolean = false;
  obj: CommonMethod = new CommonMethod();
  prevSort: string;
  showSearchValidationError = false;
  gridMessage: string = '';
  errorHandler = new ErrorHandler(this.alertService);
  constructor(private recentjobService: RecentJobsService, private alertService: AlertService, private exportFile: ExportService,) { }

  ngOnInit() {
    this.getRecentJobs(1, '');
    this.GetTotalCount();

    // alert(1)
  }
  selectColumn(Col: string, header) {
    this.columSelected = Col;
    if (this.sortUP) {
      this.sortUp(header)
    }
    else {
      this.sortDown(header)
    }

  }
  public sortDown(headerName: string) {
    this.sortDesc = false;
    this.sortUP = true;
    headerName = '[' + headerName + ']';
    this.checkPrevSort(headerName);

    // if (this.prevSort === '')
    //   this.prevSort = headerName + ' desc'
    // else
    //   this.prevSort += ' , ' + headerName + ' desc';

    this.prevSort = headerName + ' desc';
    this.getRecentJobs(1, this.prevSort);

  }
  public sortUp(headerName: string) {

    this.sortUP = false;
    this.sortDesc = true;

    headerName = '[' + headerName + ']';
    // this.checkPrevSort(headerName);

    // if (this.prevSort === '')
    //   this.prevSort = headerName + ' asc'
    // else
    //   // this.prevSort += ' , ' + headerName + ' asc';

    this.prevSort = headerName + ' asc';


    this.getRecentJobs(1, this.prevSort);
  }
  private checkPrevSort(headerName: string) {

    if (this.prevSort.includes(headerName)) {
      this.prevSort = this.prevSort.replace(' , ' + headerName + ' asc', '');
      this.prevSort = this.prevSort.replace(headerName + ' desc', '');
      this.prevSort = this.prevSort.replace(' , ' + headerName + ' desc', '');
      this.prevSort = this.prevSort.replace(headerName + ' asc', '');
      this.prevSort = this.prevSort.replace(headerName + ' desc', '');

      if (this.prevSort.trim() === ',')
        this.prevSort = '';

      if (this.prevSort.trimLeft().startsWith(',')) {
        this.prevSort = this.prevSort.trimLeft().substring(1, this.prevSort.length);
      }


      if (this.prevSort.trimRight().endsWith(',')) {
        this.prevSort = this.prevSort.trimLeft().substring(0, this.prevSort.length - 1);
      }

    }
  }
  selectRow(trId: string) {
    CommonMethod.selectRow(trId)
  }
  getRecentJobs(pageNumber: number, orderFilter: string) {
    this.gridMessage = 'Fetching Records';
    this.data = [];
    if (pageNumber <= 1) {
      this.pageStart = 0;
    }
    else {
      this.pageStart = this.pageSize * (pageNumber - 1);
    }
    let formData: FormData = new FormData();
    formData.append('FilterData', this.search);
    formData.append('StartRow', String(this.pageStart));
    formData.append('RowLength', String(this.pageSize));
    formData.append('OrderFilter', String(orderFilter));
    formData.append('ExecType', '1');
    // formData.append('SelectedStatus', this.selectStatus);


    this.recentjobService.GetRecentJobs(formData).subscribe(
      result => {
        if (result['table'].length > 0) {
          this.isData = true
          this.data = result['table']
          this.rowCount = result["table"].length;
          this.fieldHeader = [];
          this.fieldHeader = this.fieldHeader.concat(Object.keys(result['table'][0]));
        }
        else {
          this.isData = false
          this.gridMessage = 'No record found'
        }
      },
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )


  }
  checkSearch() {
    this.showSearchValidationError = this.obj.validateSearch(this.searchInput);
  }
  downloadExel() {
    this.recentjobService.ExportData(String(this.totalCount)).subscribe
      (res => {
        let data: any[] = res;
        let header = [];
        header = header.concat(Object.keys(data[0]));
        this.exportFile.generateExcel(header, data, "Recent Jobs");
      })

  }
  downloadCsv() {
    this.recentjobService.ExportData(String(this.totalCount)).subscribe(res => {
      let data = res;

      let header = [];
      header = header.concat(Object.keys(data[0]));
      this.exportFile.expotAsCSVFile(data, header, 'Recent Jobs')
    })

  }
  downloadPdf() {
    this.recentjobService.ExportData(String(this.totalCount)).subscribe(res => {
      this.exportFile.generatePdf(res, 'Recent Jobs')
    })

  }


  searchJobs() {
    if (this.showSearchValidationError)
      return;

    if (this.searchInput != null || this.searchInput != '' || this.searchInput != undefined) {
      this.search = this.searchInput;
      this.item = [];
      this.getRecentJobs(1, '');
      this.GetTotalCount();
    }
    else {
      this.search = '';
      this.getRecentJobs(1, '');
    }


  }
  resetRecords() {
    this.showSearchValidationError = false;
    this.searchInput = '';
    this.search = '';
    this.item = [];
    this.getRecentJobs(1, '');
    this.GetTotalCount();

  }
  Records(record: string) {
    if (record != null) {
      if (record == 'all') {
        this.pageSize = this.totalCount;

        this.item = [];
        this.getRecentJobs(1, '');
        this.GetTotalCount();

      }
      else {
        this.pageSize = Number(record);
        this.item = [];
        this.getRecentJobs(1, '');
        this.GetTotalCount();

      }
    }
  }
  public PageChange(pageNumber: number) {
    this.selectedPageNo = pageNumber
    if (pageNumber === 0)
      pageNumber = 1;
    if (pageNumber > this.pageCount)
      pageNumber = this.pageCount;
    this.currentPage = pageNumber;
    if (pageNumber === this.item[this.item.length - 1] && this.pageCount > this.item[this.item.length - 1]) {
      this.item.shift();
      this.item.push(this.item[this.item.length - 1] + 1);
      this.previousDotShow = true;
      this.nextDotShow = true;
      if (this.item[this.item.length - 1] == this.pageCount)
        this.nextDotShow = false;
    }
    else if (pageNumber == this.pageCount) {
      this.item = [];
      if (this.pageCount > 5) {
        for (let j = this.pageCount - 4; j <= this.pageCount; j++) {
          this.item.push(j);
        }
        this.nextDotShow = false;
        this.previousDotShow = true;
      } else {
        for (let j = 1; j <= this.pageCount; j++) {
          this.item.push(j);
        }
      }
    }
    else if (pageNumber == this.item[0] && this.item[0] > 5) {
      this.previousDotShow = true;
      this.nextDotShow = true;
      this.item.pop();
      this.item.unshift(pageNumber - 1);
    }
    else if (pageNumber <= 5) {
      this.previousDotShow = false;
      if (this.pageCount > 5) {
        this.nextDotShow = true;
      }
      let x = this.item.length;
      this.item = [];
      for (let j = 1; j <= x; j++) {
        this.item.push(j);
      }
    } else {
      this.previousDotShow = true;

    }
    if (this.pageCount < 5) {
      this.nextDotShow = false;
      this.previousDotShow = false;
    }
    this.getRecentJobs(pageNumber, '');

  }
  public GetTotalCount() {

    let formData: FormData = new FormData();
    formData.append('FilterData', this.search);
    formData.append('StartRow', '0');
    formData.append('RowLength', String(this.pageSize));
    formData.append('OrderFilter', '');
    formData.append('ExecType', '2');
    //formData.append('SelectedStatus', this.selectStatus);

    this.recentjobService.GetRecentJobs(formData).subscribe(
      res => {

        this.totalCount = res['table'][0]['totalCount'];

        this.pageCount = (this.totalCount / this.pageSize);

        if (this.pageCount <= 1) { this.pageCount = 1 }
        else if ((this.pageCount % 1) != 0)
          this.pageCount = Math.floor(this.pageCount) + 1;

        this.pageCount = Number(this.pageCount.toFixed());


        for (let i = 1; i <= this.pageCount; i++) {
          if (i <= 5) {
            this.item.push(i);
          }
          else {
            this.nextDotShow = true;
          }
        }


      },
      error => {
        this.totalCount = -1;

        let apiError: string;
        if (error.status == 400) {
          apiError = String(error.error.detail);

        }
        else if (error.status == 415) {
          apiError = error.message;
        }
        else if (error.status == 401) {
          apiError = 'Unauthorized Access, please try to restart your browser and try again.';

        }
        else if (error.status == 0)
          apiError = 'Could not connect to the service.';
        else
          apiError = error.error;



      });
  }
}
