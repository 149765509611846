import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';

import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ExportService } from 'src/services/Common/export.service';
import { AlertService } from 'src/services/Common/alert.service';
import { ConvertToRLImportableService } from 'src/services/ConvertToRLImportabe/convert-to-rlimportable.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-convert-to-rlimport-files',
  templateUrl: './convert-to-rlimport-files.component.html',
  styleUrls: ['./convert-to-rlimport-files.component.css']
})
export class ConvertToRLImportFilesComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,private _date: DatePipe, private exportFile: ExportService, private service: ConvertToRLImportableService, private alertService: AlertService) {
    this.uploadShippmentForm = this.formBuilder.group({
    })
  }
  uploadShippmentForm: UntypedFormGroup;
  fileToUpload: File;
  fileError: string
  Excel_Data_Loc: any[]
  Excel_Data_Acc: any[]
  NewData: any[] = new Array
  JobId: number;
  IsSuccess: boolean
  ngOnInit(): void {
    this.CheckAuthorized()
  }
  CheckAuthorized() {
    this.service.CheckAuthorized().subscribe(res => {
      if (!Boolean(res)) {
        this.alertService.warn("Not Authorized For This Feature");

      }
    })
  }
  deleteFile() {
    this.fileToUpload = null;
    //  this.fileToUpload.slice(0,1);
    // this.uploadShippmentForm.reset();
  }
  onSubmit() {
    if (this.fileToUpload == null || this.fileToUpload == undefined) {
      this.fileError = "Plese Select the file";
      return
    }
    this.onFileChange(this.fileToUpload);
  }
  IsFileNameValid(name: string): boolean {
    if (name.toUpperCase() === "exl")
      return true;
    else {
      this.fileError = "Only Excel file is allowed";
      return false;
    }
  }

  /**
   * on file drop handler
   */
  onFileDropped(files) {
    this.fileError="";
    this.fileToUpload = files.item(0);
    if (this.fileToUpload.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.fileError = 'Only RMS Cleansed Standard output is allowed';
      this.fileToUpload = null
      return
    }
    if (files.item(0).size > 10000000) {
      this.fileError = "File Size should be less than 10MB";
      this.fileToUpload = null
      return;
    }

    // console.log(this.fileToUpload.type);
    // if (this.IsFileNameValid(files.item(0).name)) {
    //   this.fileError = ''; 
    //   this.fileToUpload = files.item(0);
    // }
    // else {
    //   return;
    // }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.fileError="";
    this.fileToUpload = files.item(0);
    this.fileToUpload = files.item(0);
    if (this.fileToUpload.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.fileError = 'Only RMS Cleansed Standard output is allowed';
      this.fileToUpload = null
      return
    }
    if (files.item(0).size > 10000000) {
      this.fileError = "File Size should be less than 10MB";
      this.fileToUpload = null
      return;
    }
    // this.fileToUpload = files.item(0);
    // console.log(this.fileToUpload.type);
    // if (this.IsFileNameValid(files.item(0).name)) {
    //   this.fileError = '';
    //   this.fileToUpload = files.item(0);
    // }
    // else {
    //   return;
    // }
  }
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }


  onFileChange(event: File) {
    var reader: FileReader = new FileReader();
    reader.readAsBinaryString(event);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[2];
      const ws: XLSX.WorkSheet = wb.Sheets["Cleaned Data - Loc".trim()];
      if (ws == null || ws == undefined) {
        this.fileError="The Sheet Name 'Cleaned Data - Loc' does not exist in selected file";
        return
      }
      const ws2: XLSX.WorkSheet = wb.Sheets["Cleaned Data - Acc".trim()];
      if (ws2 == null || ws2 == undefined) {
        this.fileError=="The Sheet Name 'Cleaned Data - Acc' does not exist in selected file";
        return
      }
      let postData = new FormData();
      postData.append("FileName", event.name)
      this.service.InsertJobForConvertToRLFormat(postData).subscribe(res => {
        if (Number(res) > 0) {
          this.JobId = Number(res)
          this.Excel_Data_Acc = XLSX.utils.sheet_to_json(ws2,{
            raw: false,
            header: ws2 ? 0 : 1,
            dateNF: "dd/mm/yyyy"
          });
          this.Excel_Data_Loc = XLSX.utils.sheet_to_json(ws,{
            raw: false,
            header: ws ? 0 : 1,
            dateNF: "dd/mm/yyyy"
          });
          if(this.Excel_Data_Loc==null || this.Excel_Data_Loc==undefined|| this.Excel_Data_Loc.length==0){
            this.alertService.error("There are no row in Cleaned Data-Loc");
            let postData1 = new FormData();
            postData1.append("JobId", String(this.JobId))
            postData1.append("JobStatusId", "4")
            postData1.append("ErrorMessage", "There are no row in Cleaned Data-Loc");
  
            this.service.UpdateJobForConvertToRLFormat(postData1).subscribe(res => {
              if (Boolean(res) == true) {
                this.fileError = '';
                this.fileToUpload = null;
                //  this.alertService.success("Fail to convert the clenased file,Something went wrong while convert the file");
              } else {
                this.alertService.error("Something went wrong while convert the file");
              } err => {
                this.alertService.error(err.message);
              }
            })
            this.fileError = '';
            this.fileToUpload = null;
            return;
          }
          if( this.Excel_Data_Acc==null ||  this.Excel_Data_Acc==undefined || this.Excel_Data_Acc.length==0){
            this.alertService.error("There are no row in Cleaned Data-Acc");
            let postData1 = new FormData();
            postData1.append("JobId", String(this.JobId))
            postData1.append("JobStatusId", "4")
            postData1.append("ErrorMessage", "There are no row in Cleaned Data-Acc");
  
            this.service.UpdateJobForConvertToRLFormat(postData1).subscribe(res => {
              if (Boolean(res) == true) {
                this.fileError = '';
                this.fileToUpload = null;
                //  this.alertService.success("Fail to convert the clenased file,Something went wrong while convert the file");
              } else {
                this.alertService.error("Something went wrong while convert the file");
              } err => {
                this.alertService.error(err.message);
              }
            })
            this.fileError = '';
            this.fileToUpload = null;
            return;
          }
          // to get 2d array pass 2nd parameter as object {header: 1}
          //  console.log(this.Excel_Data); // Data will be logged in array format containing objects
          const Excepcolumns: string[] = ["CONSTRUCTION DESCRIPTION",
            "OCCUPANCY DESCRIPTION",
            "NATURE OF ACCOUNT",
            "OTHER OCCUP DESC",
            "OTHER CONST DESC",
            "CONSTRUCTION SOURCE",
            "OCCUPANCY SOURCE",
            "APITYPE",
            "AREAUNIT"]
          this.Excel_Data_Loc.forEach((e, index) => {
            Object.keys(e).forEach((key, id) => {
              if (key.toUpperCase().endsWith('_Ref'.toUpperCase()) || key.toUpperCase().trim().startsWith('XXCV'.toUpperCase().trim())
               || Excepcolumns.includes(key.toUpperCase())) {
                delete this.Excel_Data_Loc[index][key]
              }
            })
          });
          let header = [];
          header = header.concat(Object.keys(this.Excel_Data_Loc[0]));
          this.exportFile.expotAsTxtFile(this.Excel_Data_Loc, header, event.name.replace(".xlsx", "").replace(".xls", "") + " " + "Loc");
          let header2 = [];
          header2 = header2.concat(Object.keys(this.Excel_Data_Acc[0]));
          this.exportFile.expotAsTxtFile(this.Excel_Data_Acc, header2, event.name.replace(".xlsx", "").replace(".xls", "") + " " + "Acc");
          // console.log(this.NewData);
          let postData1 = new FormData();
          postData1.append("JobId", String(this.JobId))
          postData1.append("JobStatusId", "3")
          postData1.append("ErrorMessage", "");

          this.service.UpdateJobForConvertToRLFormat(postData1).subscribe(res => {
            if (Boolean(res) == true) {
              this.alertService.success("File Converted Successfully");
              this.fileError = '';
              this.fileToUpload = null;
            } else {
              this.alertService.error("Something went wrong while convert the file");
            } err => {
              this.alertService.error(err.message);
            }
          })
        } else {
          this.alertService.error("Something went wrong while convert the file");
          let postData1 = new FormData();
          postData1.append("JobId", String(this.JobId))
          postData1.append("JobStatusId", "4")
          postData1.append("ErrorMessage", "Something went wrong while convert the file");

          this.service.UpdateJobForConvertToRLFormat(postData1).subscribe(res => {
            if (Boolean(res) == true) {
              this.fileError = '';
              this.fileToUpload = null;
              //  this.alertService.success("Fail to convert the clenased file,Something went wrong while convert the file");
            } else {
              this.alertService.error("Something went wrong while convert the file");
            } err => {
              this.alertService.error(err.message);
            }
          })
          return;
        } err => {
          this.alertService.error(err.message);
        }
      })
    };
  }
  download() {
    const data = 'Hello, world!';
    const blob = new Blob([data], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'hello.txt';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 100);

  }

}
