


<section class="content">
  <div class="error-page">
    <h2 class="headline text-yellow"> 404</h2>

    <div class="error-content">
      <h3><i class="fa fa-warning text-yellow"></i> Oops! Page not found.</h3>

      <p>
        We could not find the page you were looking for.
        Meanwhile, you may return to Home
      </p>

      <form class="search-form">
        <div class="error-actions">
          <a routerLink="/home" class="btn btn-primary btn-lg MoodybackgroudColor"><span
              class="glyphicon glyphicon-home"></span>
              Go To Home </a>

        </div>
        <!-- /.input-group -->
      </form>
    </div>
    <!-- /.error-content -->
  </div>
  <!-- /.error-page -->
</section>