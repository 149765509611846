import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClientDashboardComponent } from './client-dashboard/client-dashboard.component';
import { AccountManagerDashboardComponent } from './accountManager-dashboard/accountManager-dashboard.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ProjectManagerDashboardComponent } from './projectManager-dashboard/projectManager-dashboard.component';
import { AccountPlannedComponent } from './dashboard-Tools/account-planned/account-planned.component';
import { TotalAccountStatusComponent } from './dashboard-Tools/total-account-status/total-account-status.component';
//import { RecentlyAddedQueriesComponent } from './dashboard-Tools/recently-added-queries/recently-added-queries.component';
import { LatestUsersComponent } from './dashboard-Tools/latest-users/latest-users.component';
import { CardHeaderComponent } from './dashboard-Tools/card-header/card-header.component';
import { RecentlyAddedQueriesComponent } from './dashboard-Tools/recently-added-queries/recently-added-queries.component';
import { ClientLeadDashboardComponent } from './client-lead-dashboard/client-lead-dashboard.component';

@NgModule({
  declarations:[
      DashboardComponent,
      ClientDashboardComponent,
      AccountManagerDashboardComponent,
      AdminDashboardComponent,
      ProjectManagerDashboardComponent,
      AccountPlannedComponent,
      TotalAccountStatusComponent,
      RecentlyAddedQueriesComponent,
      LatestUsersComponent,
      CardHeaderComponent,
      ClientLeadDashboardComponent
    ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    NgSelectModule
  ]
})
export class DashboardModule { }
