<hr style="margin-top: 0px !important;" />
<div class="container-fluid col-lg-12">
  <!-- <div class="row">
      <div class="col-md-12 image-section">
        <img src="../../../../assets/img/bg_image.jpg" />
      </div>
    </div> -->

  <section class="content">
    <div class="container-fluid">
      <!-- SELECT2 EXAMPLE -->
      <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title MoodysTittleColor" >User Profile</h3>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <!-- SELECT2 EXAMPLE -->
      <div class="card card-default">
        <div class="row">
          <div class="col-md-3 ">
            <div class="text-center">
              <img src="../../../../assets/img/user.png" class='img-responsive'
                style="height: 180px;width: 180px;margin-top: 20px;" />
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 user-detail-section1 text-center">
              <h3 class="follow">{{ userDetail?.userName }}</h3>
              <h5>{{ userDetail?.city }}</h5>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 user-detail-section1 text-center">
              <a data-placement="bottom" title="Edit Profile" routerLink="/usersetting">
                <i class="fa fa-edit"> Edit Profile</i>
              </a>
            </div>
          </div>

          <div class="col-md-9">
            <!-- <div class="row ml-1">
                <h3><i class="fas fa-user-circle mr-2"></i>User Profile</h3>
              </div> -->
            <div class="row">
              <div class="col-md-4">
                <label>User Name :</label>
              </div>
              <div class="col-md-6">
                <p>{{ userDetail?.userName }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Email Id :</label>
              </div>
              <div class="col-md-6">
                <p>{{ userDetail?.emailID }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Password :</label>
              </div>
              <div class="col-md-6">
                <a data-placement="bottom" title="change Password" routerLink="/changepassword">
                  <i class="fa fa-lock"> Change Password</i>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Location :</label>
              </div>
              <div class="col-md-6">
                <p>{{ userDetail?.city }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Time Zone :</label>
              </div>
              <div class="col-md-6">
                <p>{{ userDetail?.timeZoneName }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Client Name :</label>
              </div>
              <div class="col-md-6">
                <p>{{ userDetail?.clientName }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Phone Number :</label>
              </div>
              <div class="col-md-6">
                <p>{{ userDetail?.phone }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label>Extension :</label>
              </div>
              <div class="col-md-6">
                <p>{{ userDetail?.extension }}</p>
              </div>
            </div>
            <div *ngIf="isClientUser">
              <div class="row">
                <div class="col-md-4">
                  <label>Account Type Mapping :</label>
                </div>
                <div class="col-md-6">
                  <div class="row badge_left">
                    <span *ngFor="let item of accountTypeList" class="badge badge-info list">
                      {{ item["accountTypeName"] }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Default Account Type :</label>
                </div>
                <div class="col-md-6">
                  <p>{{ userDetail["accounttypeinfo"] }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Business Unit Mapping :</label>
                </div>
                <div class="col-md-6">
                  <div class="row badge_left">
                    <span *ngFor="let item of businessunitList" class="badge badge-info list">
                      {{ item["description"] }}</span>
                  </div>
                  <!-- <p *ngFor="let item of businessunitList; let i = index">
                            {{ i + 1 }}.{{ item["businessUnitDescription"] }}
                          </p> -->
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Default Business Unit :</label>
                </div>
                <div class="col-md-6">
                  <p>{{ userDetail["defaultBusinessUnit"] }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Default Work Type :</label>
                </div>
                <div class="col-md-6">
                  <p>{{ userDetail["workName"] }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Client Mapping :</label>
                </div>
                <div class="col-md-6">
                  <div class="row badge_left">
                    <span class="badge badge-info list">
                      {{ userDetail.clientName }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isClientUser" class="row">
              <div class="col-md-4">
                <label>Client Mapping :</label>
              </div>
              <div class="col-md-6">
                <div class="row badge_left">
                  <span *ngFor="let item of clientList" class="badge badge-info list">
                    {{ item.clientname }}
                  </span>
                </div>
              </div>
            </div>


            <hr />
          </div>
        </div>
      </div>
    </div>
  </section>

</div>