export class ClientElementModel {
    GUID:string;
    Id:number;
    ClientId: number;
    ElementId: number;
    ElementName: string;
    MappedName: string;
    DefaultValue: string;
    ValidationReq: boolean;
    IsMandatory: boolean;
    IsActive: boolean;
    IsEditable: boolean;
      Validator:string ;
    MasterDataQuery: string;
    Validation: any;
    validationValue: string;
    IsIgnorable: boolean;
}
export class ElementValidationModel{
    GUID:string;
    id:number;
    clientId:number;
    ElementId:number;
    ValidationId:number;
    validtionName:string;
    IsIgnorable: boolean;  
    ElementName:string;
    ValidatorValue:string;
    IsActive:boolean;
}