<div class="container-fluid" style="padding: 0 !important;">
    <div class="card card-default">
        <div class="card-header">
            <h3 class="card-title MoodysTittleColor" style="vertical-align: middle;">Filters</h3>
            <div class="card-tools" style="margin-left: 20px;">
                <!-- <button class="btn btn-sm RMSSubmitButton mr-2" title="Display Condition" type="button"
                    aria-expanded="false" aria-controls="collapseExample" (click)="toggleDisplayCondition()">
                    Display Condition
                </button> -->
                <div class="btn-group btn-group-sm mr-2" (click)="toggleDisplayCondition()" *ngIf="showCreateFilter">
                    <button class="btn btn-default" type="button">View Condition</button>
                    <button class="input-group-text btn btn-primary" aria-hidden="true">
                        <i class="fa fa-eye"></i>
                    </button>
                </div>
                <div class="btn-group btn-group-sm mr-2" data-toggle="modal" data-target="#saveFilterModal"
                    *ngIf="showCreateFilter">
                    <button class="btn btn-default" type="button">Save Filter</button>
                    <button class="btn btn-primary" aria-hidden="true">
                        <i class="fa fa-save"></i>
                    </button>
                </div>
                <div class="btn-group btn-group-sm" (click)="toggleCreateFilter()">
                    <button class="btn btn-default" type="button">Create Filter</button>
                    <button class="btn btn-primary" aria-hidden="true">
                        <i class="fa fa-filter"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <!-- Create Filter -->
            <div class="row" *ngIf="showCreateFilter">
                <!-- Condition Pills -->
                <div class="col-lg-12 font11 bg-info mb-2" *ngIf="displayCondition">
                    <small>
                        Condition: {{ filter }}
                    </small>
                </div>
                <!-- [ngClass]="{'p-2 mb-2': selectedArray.length > 0}" -->
                <div class="mr-2 mb-3 bg-info">
                    <small class="d-inline-block border m-1 iEditHover" title="Edit" (click)="editConditon(items)"
                        *ngFor="let items of selectedArray">
                        <a class="pl-1 pr-1">
                            {{items[0]}}
                            {{items[1]}}
                            {{items[2]}}
                        </a>
                        <!-- <i class="fas fa-pencil-alt text-decondary conditionBtns pr-2 ihover" title="Edit"
                                (click)="editConditon(items)" aria-hidden="true"></i> -->
                        <i class="fa fa-times text-decondary conditionBtns pr-2 iCloseHover" title="Close"
                            (click)="deleteConditon(items)" aria-hidden="true"></i>
                    </small>
                </div>

                <div class="mb-3" *ngIf="!showConditions">
                    <button type="button" (click)="showConditonsDiv('and')" class="btn btn-sm btn-default mr-2">+ Single
                        Valued Condition</button>
                    <button type="button" (click)="showConditonsDivMulti('in')" class="btn btn-sm btn-default mr-2">+
                        Multi Valued Condition</button>

                    <!-- <button type="button" (click)="showConditonsDiv('and')" data-toggle="modal"
                        data-target="#filterConditionModal" class="btn btn-sm btn-default mr-2">+ Single
                        Valued Condition</button>
                    <button type="button" (click)="showConditonsDivMulti('in')" data-toggle="modal"
                        data-target="#filterConditionModal" class="btn btn-sm btn-default mr-2">+
                        Multi Valued Condition</button> -->
                </div>
                <!--Show when the conditons is called -->
                <div class="row mr-2 mb-3" *ngIf="showConditions">
                    <div class="pl-2">
                        <div class=" d-inline-block ">
                            <div class=" select-settings ">
                                <select id="columnSelect" #columnSelect
                                    (change)="setConditionToSelect(columnSelect.value)"
                                    class="form-control form-control-sm">
                                    <option value="null">Select Column</option>
                                    <option *ngFor="let items of columnData" value="{{ items | json }}">
                                        {{ items['columnName'] }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="pl-2" *ngIf="valueType != 5 && !showMultiCondition">
                        <div class="d-inline-block">
                            <div class=" select-settings">
                                <select id="filterSelect" class="form-control  form-control-sm" #filterSelect
                                    (change)="conditionType(filterSelect.value)">
                                    <option value="null">Select Filter</option>
                                    <option *ngFor="let items of conditionValues" value="{{ items[1] }}">
                                        {{ items[0] }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="pl-2">
                        <div class="d-inline-block  text-center" *ngIf="!showMultiCondition">
                            <input type="text" id="valueSelectString" *ngIf="valueType == 1" [(ngModel)]="selectedValue"
                                class="form-control  form-control-sm select-settings" placeholder="Value">
                            <input type="number" id="valueSelectNumber" *ngIf="valueType == 2"
                                [(ngModel)]="selectedValue" class="form-control  form-control-sm select-settings"
                                placeholder="Value">
                            <input placeholder="MM/dd/yyyy" *ngIf="valueType == 3" [(ngModel)]="selectedValue"
                                class="form-control  form-control-sm select-settings" type="date"
                                class="form-control" />

                        </div>
                        <div *ngIf="showMultiCondition" class="d-inline-block text-center" style="height: 20px;">
                            <ng-select placeholder="Select value" [multiple]="true" #multiSelect class="custom-height"
                                (change)="setMultiValueCondition()" [(ngModel)]="selectedMulti">
                                <ng-option *ngFor="let val of defaultMultiVals" [value]="val.name"
                                    [disabled]="val.disabled">
                                    {{val.name}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="pl-2">
                        <div class="d-inline-block  text-center pl-2" (click)="saveConditions()">
                            <button class="btn btn-sm btn-success">
                                <i class="fa fa-check text-white conditionBtns" title="Save" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="d-inline-block  text-center pl-2" (click)="closeConditonsDiv()">
                            <button class="btn btn-sm btn-danger">
                                <i class="fa fa-times text-white conditionBtns" title="Close" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <!-- <button class="btn btn-sm RMSSubmitButton mr-2" title="" type="button"
                            aria-expanded="false" aria-controls="collapseExample" (click)="fetchData()">
                            Search
                        </button> -->
                    <div class="btn-group btn-group-sm" (click)="fetchData()">
                        <button class="btn btn-default" type="button">Search</button>
                        <button class="btn btn-primary" aria-hidden="true">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
                <!-- <button class="btn btn-sm RMSSubmitButton mr-2" title="Display Condition" type="button"
                    aria-expanded="false" aria-controls="collapseExample" (click)="toggleDisplayCondition()">
                    Display Condition
                </button> -->
                <!-- <button type="button" class="btn btn-sm RMSSubmitButton" data-toggle="modal" data-target="#saveFilterModal">Save</button> -->
                <!-- </div> -->
            </div>

            <!-- Save Modal Dialog-->
            <div class="modal fade" id="saveFilterModal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Save Filter</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <div class="row m-2">
                                <div class="col-sm-3">
                                    <label>Filter Name</label>
                                </div>
                                <div class="col-sm-9">
                                    <input type="text" id="valueSelectString" [(ngModel)]="filterName"
                                        class="form-control form-control-sm ml-2 pb-2" placeholder="Filter name">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-sm RMSSubmitButton d-inline-block mr-2 pl-2 pr-2"
                                style="width: 75px;" title="Submit Filter" (click)="saveFilter()" type="button"
                                aria-expanded="false">
                                Save
                            </button>
                            <button type="button" id="btnCloseSaveFilterModal" class="btn btn-sm btn-danger"
                                data-dismiss="modal" style="width: 75px;">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Filter Condition Dialog-->
            <div class="modal fade" id="filterConditionModal">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">+ Condition</h4>
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <div class="row mr-2 mb-3">
                                <div class="pl-2">
                                    <div class=" d-inline-block ">
                                        <div class=" select-settings ">
                                            <select id="columnSelect" #columnSelect
                                                (change)="setConditionToSelect(columnSelect.value)"
                                                class="form-control form-control-sm">
                                                <option value="null">Select Column</option>
                                                <option *ngFor="let items of columnData" value="{{ items | json }}">
                                                    {{ items['columnName'] }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="pl-2" *ngIf="valueType != 5 && !showMultiCondition">
                                    <div class="d-inline-block">
                                        <div class=" select-settings">
                                            <select id="filterSelect" class="form-control  form-control-sm"
                                                #filterSelect (change)="conditionType(filterSelect.value)">
                                                <option value="null">Select Filter</option>
                                                <option *ngFor="let items of conditionValues" value="{{ items[1] }}">
                                                    {{ items[0] }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="pl-2">
                                    <div class="d-inline-block  text-center" *ngIf="!showMultiCondition">
                                        <input type="text" id="valueSelectString" *ngIf="valueType == 1"
                                            [(ngModel)]="selectedValue"
                                            class="form-control  form-control-sm select-settings" placeholder="Value">
                                        <input type="number" id="valueSelectNumber" *ngIf="valueType == 2"
                                            [(ngModel)]="selectedValue"
                                            class="form-control  form-control-sm select-settings" placeholder="Value">
                                        <input placeholder="MM/dd/yyyy" *ngIf="valueType == 3"
                                            [(ngModel)]="selectedValue"
                                            class="form-control  form-control-sm select-settings" type="date"
                                            class="form-control" />

                                    </div>
                                    <div *ngIf="showMultiCondition" class="d-inline-block text-center"
                                        style="height: 20px;">
                                        <ng-select placeholder="Select value" [multiple]="true" #multiSelect
                                            (change)="setMultiValueCondition()" [(ngModel)]="selectedMulti">
                                            <ng-option *ngFor="let val of defaultMultiVals" [value]="val.name"
                                                [disabled]="val.disabled">
                                                {{val.name}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="d-inline-block  text-center pl-2" (click)="saveConditions()">
                                <button class="btn btn-sm btn-success">
                                    Save <i class="fa fa-check text-white conditionBtns" title="Save"
                                        aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="d-inline-block  text-center pl-2" (click)="closeConditonsDiv()">
                                <button class="btn btn-sm btn-danger">
                                    Close <i class="fa fa-times text-white conditionBtns" title="Close"
                                        aria-hidden="true"></i>
                                </button>
                            </div>
                            <!-- <button class="btn btn-sm RMSSubmitButton d-inline-block mr-2 pl-2 pr-2"
                                style="width: 75px;" title="Submit Filter" type="button" aria-expanded="false">
                                Save
                            </button>
                            <button type="button" id="btnCloseSaveFilterModal" class="btn btn-sm btn-danger"
                                data-dismiss="modal" style="width: 75px;">Close</button> -->
                        </div>
                    </div>
                </div>
            </div>


            <!--View Saved Filter Detail-->
            <div class="row table-responsive text-nowrap">
                <table class="table table-condensed table-hover" style="font-size: small;">
                    <thead>
                        <tr>
                            <th class="text-center" scope="col">#</th>
                            <th class="text-center" scope="col">Action</th>
                            <th scope="col">Filter Name</th>
                            <th scope="col">Filter Condition</th>
                            <th class="text-center" scope="col">Created Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of savedFilter; let i = index">
                            <td class="text-center">{{ i + 1 }}</td>
                            <td class="text-center">
                                <i class="fa fa-edit btn-tool MoodysIconColor" (click)="editFilter(item)"
                                    ></i>
                                <i class="fas fa-eye btn-tool" (click)="selectedSavedFilter(item)"></i>
                                <i class="fa fa-trash btn-tool" (click)="deleteFilter(item.id)" *ngIf="!item.isActive"
                                    style="color:red;"></i>
                            </td>
                            <td>{{ item["filterName"] }}</td>
                            <td>{{ item["filter"] }}</td>
                            <td class="text-center">{{ item["createDate"] }}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
</div>