<div class="setBGImage">
  <div class="main">


    <div class="col-lg-12 ErrorMessage NoPadding" [ngClass]="ifError? 'alert-danger' : 'alert-success' "
      class="alert  alert-dismissible" *ngIf="notification">
      {{ errorMessage }}
      <span *ngIf="forgotPasswordLink"><a routerLink="/forgotpassword" routerLinkActive="active">Click here</a> to raise
        another request.</span>
      <span *ngIf="loginLink"><a routerLink="/login" routerLinkActive="active">Click here</a> to go to Login.</span>
    </div>

    <div class="container " style="width: 35%;padding-top: 30px;">

      <div class="card"
        style="height: 530px;align-items: center;border-radius: 30px;padding-left: 25px;padding-right:25px;">
        <div style="text-align: center;">
          <div class="app-header ">
            <img src="../../../assets/svg/MoodysBlue.svg" alt="Logo" class="app-logo">
            <h3 class="MoodyTittleColor2">Data Solution Portal</h3>
          </div>
        </div>
        <div class="card-body col-lg-12" style="padding-top: 30px;">
          <div *ngIf="resetPasswordtemplate" style="margin-top: 20px;">
            <form [formGroup]="formdata" class="col-lg-12" (ngSubmit)="onSubmit(UserId.value, password.value)">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-key"></i></span>
                </div>
                <input type="text" class="form-control" autofocus placeholder="EmailId" #UserId
                  formControlName="UserName" (ngModelChange)="ShowUserNameError()" aria-describedby="sizing-addon1"
                  name="email" autocomplete="new-email" style="height: 100%;border-bottom-right-radius: 10px;
                      border-top-right-radius: 10px;" />
                <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                  <div *ngIf="ShowUserNameErrors && f.UserName.errors">
                    <div *ngIf="f.UserName.errors.required">
                      Email Id is required.
                    </div>
                    <div *ngIf="f.UserName.errors.pattern">
                      Please check the format of the email address entered.
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-key"></i></span>
                </div>
                <input (capsLock)="capsOn=$event" style="height: 100%;border-bottom-right-radius: 10px;
                      border-top-right-radius: 10px;" type="password" id="inputPassword" name="password"
                  autocomplete="new-password" class="form-control" placeholder="New password" #password
                  formControlName="password" (ngModelChange)="ShowUserPasswordError()"
                  aria-describedby="sizing-addon1" />

                <div *ngIf="ShowUserPasswordErrors && f.password.errors" class="tooltip" style="display: contents;">
                  <i class="fa fa-info-circle" style="
                        font-size: 18.5px;
                        color: #c42032;
                        margin-top: 8px;
                        position: absolute;
                        right: 2%;
                      ">
                  </i>
                  <span class="tooltiptext">
                    <b>*</b> Password minimum length : 10<br />
                    <b>*</b> Password maximum length : 16<br />
                    <b>*</b> Minimum required numeric number : 1<br />
                    <b>*</b> Minimum required lower-case letters : 1<br />
                    <b>*</b> Minimum required upper-case letters : 1<br />
                    <b>*</b> Minimum required special characters : 1<br />
                    <b>*</b> Allowable special characters :!&#64;#$%*()_+^&&#125;{{
                    "{"
                    }}:;?."<br />
                  </span>
                </div>

                <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                  <div *ngIf="ShowUserPasswordErrors && f.password.errors">
                     <div>Password is invalid</div>
                  </div>
                </div>
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-key"></i></span>
                </div>
                <input type="password" id="inputPassword" style="height: 100%;border-bottom-right-radius: 10px;
                      border-top-right-radius: 10px;" class="form-control" autocomplete="new-password"
                  placeholder="Confirm password" formControlName="confirmPassword" name="password"
                  (ngModelChange)="ShowConfirmPasswordError()" aria-describedby="sizing-addon1" />
                <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                  <div *ngIf="
                        ShowConfirmPasswordErrors && f.confirmPassword.errors
                      ">
                    <div *ngIf="f.confirmPassword.errors.required">
                      Confirm Password is required
                    </div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">
                      Passwords must match
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 ErrorMessage  NoPadding" style="height: 1rem !important;">
                  <div *ngIf="capsOn">
                    <div style="background: #fffb0733;">
                      Warning: Caps Lock is ON
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center" style="margin-top: 10px;">
                <input type="submit" style="width: 50%; height: 60px; border-radius: 10px; display: inline-block;"
                  type="submit" class="btn btn-success SignInBtn float-left" value="Reset"
                  class="btn MoodysSubmitButton">
              </div>
            </form>
          </div>  
          <div *ngIf="!resetPasswordtemplate && !notification">
            <div class="text-center">
              <h5>
               Rest Password link has been expired.
              </h5>
              <p class="lead">
                Please  <a routerLink="/login">Click Here</a> to raise new request.
              </p>
            </div>
          </div>       
        </div>
        <div class="row">
          <div class="col MoodyTittleColor2" style="text-align:center;">
            <h6 style="font-size: xx-small;">© {{Year | date: "y"}} Moody's Analytics, Inc. and/or its licensors and
              affiliates (collectively, "Moody's"). All rights reserved.
              Reserved.</h6>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>