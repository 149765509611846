import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MasterRoutingModule } from './master-routing.module';
import { MasterComponent } from './master/master.component';
import { ManageAccounttypeComponent } from './manage-accounttype/manage-accounttype.component';
import { ManageBusinessunitComponent } from './manage-businessunit/manage-businessunit.component';
import { ManageLocationComponent } from './manage-location/manage-location.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [MasterComponent, ManageAccounttypeComponent, ManageBusinessunitComponent, ManageLocationComponent],
  imports: [
    CommonModule,
    MasterRoutingModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class MasterModule { }
