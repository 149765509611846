<div class="container-fluid" style="padding: 0 !important;">
    <div class="card card-default">
        <div class="card-header">
            <h3 class="card-title MoodysTittleColor" style="vertical-align: middle;">Advance Filter Search
                <span *ngIf="filterName != ''"> : {{filterName}}</span>
            </h3>
            <div class="card-tools" style="margin-left: 20px;">
                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="remove">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="card-body" style="padding-bottom: 0px;padding-top: 10px;padding-right: 10px;padding-left: 10px;">
            <div class="row">
                <!-- Create Filter -->
                <div class="col-lg-8">
                    <div class="card card-default p-2">
                        <div>
                            <div class="row mr-2 mb-2 mt-1">
                                <div class="" style="padding-left: 12px;">
                                    <div class=" d-inline-block ">
                                        <div class=" select-settings ">
                                            <select id="columnSelect"
                                                (change)="setConditionToSelect($event.target.value)"
                                                class="form-control form-control-sm">
                                                <option value="select">Select Column</option>
                                                <option *ngFor="let item of columnData" value="{{item | json}}">
                                                    {{ item['columnName'] }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!-- textbox or numberbox -->
                                <div class="pl-2"
                                    *ngIf="columnType.toLowerCase() == 'textbox' || columnType.toLowerCase() == 'numberbox'">
                                    <div class="d-inline-block">
                                        <div class=" select-settings">
                                            <select id="filterSelect" class="form-control  form-control-sm"
                                                [(ngModel)]="selectedCondition">
                                                <option value="select">Select Filter</option>
                                                <option *ngFor="let items of conditionValues" [ngValue]="items[1]">
                                                    {{ items[0] }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="pl-2"
                                *ngIf="columnType.toLowerCase() == 'textbox' || columnType.toLowerCase() == 'numberbox'">
                                <div class="d-inline-block">
                                    <div class=" select-settings">
                                        <select id="filterSelect_Temp" class="form-control  form-control-sm" #filterSelect_Temp
                                            (change)="conditionType(filterSelect_Temp.value)" [(ngModel)]="selectedconditionValues">
                                            <option value="null">Select Filter</option>
                                            <option *ngFor="let items of conditionValues" [ngValue]="items[1]">
                                                {{ items[0] }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div> -->

                                <div class="pl-2">
                                    <!-- textbox -->
                                    <div class="d-inline-block  text-center"
                                        *ngIf="columnType.toLowerCase() == 'textbox'">
                                        <input type="text" id="valueSelectString" [(ngModel)]="selectedValue"
                                            class="form-control  form-control-sm select-settings" placeholder="Value">
                                    </div>
                                    <!-- numberbox -->
                                    <div class="d-inline-block  text-center"
                                        *ngIf="columnType.toLowerCase() == 'numberbox'">
                                        <input type="number" id="valueSelectNumber" [(ngModel)]="selectedValue"
                                            class="form-control  form-control-sm select-settings" placeholder="Value">
                                    </div>
                                    <!--datepicker-->
                                    <div class="d-inline-block" *ngIf="columnType.toLowerCase() == 'datepicker'">
                                        <small class="d-inline-block "> From</small>
                                        <input placeholder="MM/dd/yyyy" [(ngModel)]="selectedDateFrom"
                                            class="form-control mx-1  form-control-sm select-settings d-inline-block col-lg-4"
                                            type="date" />
                                        <small class="d-inline-block"> To</small>
                                        <input placeholder="MM/dd/yyyy" [(ngModel)]="selectedDateTo"
                                            class="form-control  ml-1 form-control-sm select-settings d-inline-block col-lg-4"
                                            type="date" />
                                    </div>
                                    <!-- combobox -->
                                    <div *ngIf="columnType.toLowerCase() == 'combobox'"
                                        class="d-inline-block text-center ">
                                        <ng-select style="text-align:left !important;width: 305px;"
                                            placeholder="Select value" [multiple]="true" #multiSelect
                                            class="custom-height" [(ngModel)]="selectedMulti">
                                            <ng-option *ngFor="let val of defaultMultiVals" [value]="val.name"
                                                [disabled]="val.disabled">
                                                {{val.name}}</ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="pl-1">
                                    <div class=" d-inline-block  text-center pl-2" (click)="addConditions()">
                                        <!-- <button class="btn btn-default RMSSearchButton"
                                            title="Add condition for search">
                                            <i class="text-white conditionBtns" title="Add condition for search"
                                                aria-hidden="true">Add Condition</i>
                                        </button> -->
                                        <button class="btn btn-sm btn-default text-white MoodysbackgroudColorSecond"
                                            [title]='buttonTitle'
                                            type="button">{{buttonTitle}}</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- *ngIf="selectedArray.length > 0" -->
                        <div class="row card card-default MoodysbackgroudColorSecond"
                            style="margin:10px 4px 10px 4px !important; height: 80px !important; overflow:auto; ">
                            <small class="p-2 text-white" *ngIf="selectedArray.length == 0">
                                No filer condition
                            </small>
                            <div class=" mr-2 mb-1 " style="height: 35px;  color: #000; display:inline-block;"
                                *ngFor="let items of selectedArray">
                                <small class="d-inline-block border m-1 iEditHover" style=" background: #ffffff;"
                                    title="Edit">
                                    <a class="pl-1 pr-1">
                                        {{items[0]}}
                                        {{items[1]}}
                                        {{items[2]}}
                                    </a>
                                    <i class="fas fa-pencil-alt text-decondary conditionBtns pr-2 ihover" title="Edit"
                                        (click)="editConditon(items)" aria-hidden="true"></i>
                                    <i class="fa fa-times text-decondary conditionBtns pr-2 iCloseHover" title="Delete"
                                        (click)="deleteConditon(items)" aria-hidden="true"></i>
                                </small>
                            </div>
                        </div>
                        <!-- <span>filter - {{filter}}</span>
                    <br>
                    <span>filterSQL - {{filterSQL}}</span> -->
                        <div class="row mt-1 ml-1 mb-1">
                            <div (click)="fetchData()">
                                <button class="btn btn-sm btn-default text-white MoodysbackgroudColorSecond" 
                                    title="Search" type="button">Search</button>
                            </div>
                            <div class="ml-2" (click)="resetFilter()">
                                <button class="btn btn-sm btn-default text-white MoodysbackgroudColorSecond" 
                                    type="button">
                                    <span *ngIf="filterName != ''" title="Cancel">Cancel</span>
                                    <span *ngIf="filterName == ''" title="Reset">Reset</span>
                                </button>
                            </div>
                            <div class="ml-2" *ngIf="selectedArray.length > 0" data-toggle="modal"
                                data-target="#saveFilterModal">
                                <button class="btn btn-sm btn-default text-white MoodysbackgroudColorSecond" 
                                    type="button">
                                    <span *ngIf="filterName != ''" title="Update">Update</span>
                                    <span *ngIf="filterName == ''" title="Save">Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!--View Saved Filter Detail-->
                <div class="col-lg-4">
                    <div class="card card-default p-2">
                        <div class="row" style="overflow-x: auto;height: 182px;">
                            <table class="table table-condensed table-hover" style="font-size: small;">
                                <thead>
                                    <tr>
                                        <th class="text-center" style=" border-top: 0px;" scope="col">Action</th>
                                        <th scope="col" style=" border-top: 0px;">Filter Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of savedFilter; let i = index">
                                        <td class="text-center">                                        
                                            <button *ngIf="!item.isDefault;else editTemp;" (click)="editFilter(item)"
                                                class="btn btn-tool" title="Edit Filter">
                                                <i class="fa fa-edit MoodysIconColor"></i>
                                            </button>
                                            <ng-template #editTemp>
                                                <button title="Edit Filter" class="btn btn-tool" disabled> <i
                                                        class="fa fa-edit MoodysIconColor"></i>
                                                </button>
                                            </ng-template>
                                            <button *ngIf="!item.isDefault;else deleteTemp;" class="btn btn-tool"
                                                title="Delete Filter" (click)="deleteFilter(item.id)">
                                                <i class="fa fa-trash" style="color:red;"></i>
                                            </button>
                                            <ng-template #deleteTemp>
                                                <button title="Delete Filter" class="btn btn-tool" disabled>
                                                    <i class="fa fa-trash" style="color: red"></i>
                                                </button>
                                            </ng-template>
                                        </td>
                                        <td>{{ item["filterName"] }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Save Modal Dialog-->
                <div class="modal fade" id="saveFilterModal">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">Save Filter</h4>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body">
                                <div class="row m-2">
                                    <div class="col-sm-3">
                                        <label>Filter Name</label>
                                    </div>
                                    <div class="col-sm-9">
                                        <input type="text" id="valueSelectString1" [(ngModel)]="filterName"
                                            maxlength="50" class="form-control form-control-sm ml-2 pb-2"
                                            placeholder="Filter name">
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-sm RMSSubmitButton d-inline-block mr-2 pl-2 pr-2"
                                    style="width: 75px;" title="Submit Filter" (click)="saveFilter()" type="button"
                                    aria-expanded="false">
                                    Save
                                </button>
                                <button type="button" id="btnCloseSaveFilterModal" class="btn btn-sm btn-danger"
                                    data-dismiss="modal" style="width: 75px;">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>