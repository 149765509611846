import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { GetUserClientInfo } from '../../../model/AccessManagement/GetUserClientInfo';

@Injectable({
  providedIn: 'root'
})
export class GetUserClientInfoService {

  constructor(private http: HttpClient) { }

  GetUserClientInfo(url: string, formData: FormData) {
    return this.http.post<GetUserClientInfo[]>(url, formData).pipe(map(
      res => {
        let data = res;
        return data;
      },
      err => {
        let data = err.json()
        console.error(err)
        return data;
      }
    ));
  }
  ExportData(Url: string, totalCount: string, status: string, filter: string,clientList:string) {
    let formdata: FormData = new FormData();
    formdata.append('userId', '1');
    formdata.append('filter', filter);
    formdata.append('startRow', '0');
    formdata.append('rowLength', totalCount);
    formdata.append('orderFilter', '');
    formdata.append('execType', '1');
    formdata.append('status', status);
    formdata.append('clientsid',clientList);
    return this.http.post<GetUserClientInfo[]>(Url, formdata).pipe(map(
      res => {
        // console.log(res["tbleUserList"])
        let list: any = res["tbleUserList"];
        for (let i = 0; i < list.length; i++) {
          delete list[i].businessUnitId;
          delete list[i].clientId;
          delete list[i].defaultAccounttypeId;
          delete list[i].defaultBusinessUnitId
          delete list[i].extension;
          delete list[i].isFirstTime;
          delete list[i].phone;
          delete list[i].locationId;
          delete list[i]['isSentCredential'];
          delete list[i].userID;
          delete list[i]['workType'];
          delete list[i].businessUnits;
          delete list[i].roleId
          delete list[i].defaultWorkType
  
        }
        for (let i = 0; i < list.length; i++) {
          Object.keys(list[i]).forEach(key => {
            list[i][key.toUpperCase()] = list[i][key];
            delete list[i][key];
          })
        }
        return list;
      },
      err => {
        return err.json();
      }))
  }
}
