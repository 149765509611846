<div class="row">
<div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-file-contract"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Contract Term Elapsed</span>
         
            <span class="info-box-number" style="font-size: x-small;">Expiring on {{contractEndDate|date}}</span>
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->
      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3" >
          <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-cog"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Account Processed</span>
            <span class="info-box-number"style="font-size: x-small;">{{accountProcessed}}</span>
            <!-- <span class="info-box-number"><small>(200 / 300)</small></span> -->
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->

      <!-- fix for small devices only -->
      <div class="clearfix hidden-md-up"></div>

      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-success elevation-1"><i class="fas fa-map-marker"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Location Processed</span>
            <span class="info-box-number" style="font-size: x-small;">{{locationProcessed}} </span>
            <!-- <span class="info-box-number"><small>(1772 / 1800)</small></span> -->
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
      <!-- /.col -->
      <div class="col-12 col-sm-6 col-md-3">
        <div class="info-box mb-3">
          <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-users"></i></span>

          <div class="info-box-content">
            <span class="info-box-text">Number of User</span>
            <span class="info-box-number" style="font-size: x-small;">{{noOfActiveUser}} </span>
            <!-- <span class="info-box-number"><small>(4.88/5.00)</small></span> -->
          </div>
          <!-- /.info-box-content -->
        </div>
        <!-- /.info-box -->
      </div>
    </div>