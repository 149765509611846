export class AlertMessages {
  public static ALERTMESSAGE1 = "Could not connect to the server";
  public static ALERTMESSAGE2 = 'Not able to process your request';
  public static ALERTMESSAGE3 = 'Unauthorized Access';
  public static ALERTMESSAGE4 = "User Profile has been updated successfully!";
  public static ALERTMESSAGE5 = "User Notification has been updated successfully!";
  public static ALERTMESSAGE6 = "Internal Server error. Please try again";
  public static ALERTMESSAGE7 = "Invalid Submission!";
  public static CLIENTSAVESUCESS = "Client added sucessfully";
  public static CLIENTEDITSUCESS = " Client edited sucessfully";
  public static contractModuleAlert = "Contract added sucessfully";
  public static contractModuleAlert1 = " Contract edited sucessfully";
  public static DELETECONTRACT = " Contract deactivated sucessfully";
  public static ALERTMESSAGE8 = ' User has been added sucessfully';
  public static ALERTMESSAGE9 = "User details has been updated sucessfully";
  public static ALERTMESSAGE10 = "Unable to get recently uploaded account's";
  public static ALERTMESSAGE11 = "Account has been cancelled sucessfully";
  public static ALERTMESSAGE12 = "Email ID already exists,please try with different Email Id.";
  public static ALERTMESSAGE13 = "Role has been updated sucessfully";
  public static ALERTMESSAGE14 = "Account has been closed";
  public static ALERTMESSAGE15 = 'You are not authorized for this action';
  public static ALERTMESSAGE16 = "Query has been sent successfully";
  public static ALERTMESSAGE17 = "Account has been uploaded sucessfully";
  public static ALERTMESSAGE18 = "Account can not be closed";
  public static ALERTMESSAGE19 = "Query has been closed sccussfully";
  public static ALERTMESSAGE20 = "Account has been reactivated";
  public static ALERTMESSAGE21 = "Account can not be reactivate";
  public static ALERTMESSAGE22 = "Please provide valid emailId!";
  public static ALERTMESSAGE23="You are not authorized to close the account";
}
