import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { DashboardServiceService } from 'src/services/DashboardService/dashboardService.service';
import { MessageCenterserviceService } from 'src/services/message-centerservice.service';
import { ProcessTrackingService } from 'src/services/Process-TrackingServices/process-tracking.service';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-recently-added-queries',
  templateUrl: './recently-added-queries.component.html',
  styleUrls: ['./recently-added-queries.component.css']
})
export class RecentlyAddedQueriesComponent implements OnInit {
  data: any;
  rAiseQuery: boolean;
  @Input() RaiseReply_Query: boolean;
  @Input() messageContent: boolean;

  constructor(private messageService: MessageCenterserviceService, private processTrackingService: ProcessTrackingService, private router: Router) { }

  ngOnInit() {
   // this.getRecentlyAddedQueries()
  }
  // getRecentlyAddedQueries() {
  //   let formData = new FormData;
  //   formData.append('StartRow', '0');
  //   formData.append('RowLength', '10');
  //   formData.append('OrderFilter', '');
  //   formData.append('ExecType', '1');
  //   formData.append('UserId', '0');
  //   formData.append('AccountId', '0');
  //   formData.append('ItemSearch', '');
  //   this.messageService.Getmessage(formData).subscribe(response => {

  //     if (response != null && response != undefined && response != '') {
  //       this.data = response['tblThread'];
  //     }
  //   },
  //     error => {
  //       //   this.errorHandler.ErrorsHandler(error);
  //     })
  // }
  RedirectToMesageCenter(value) {
    //this.messageService.RedirectToMesageCenter(value);
    
    let accountID = value['accountID'];
    
    let formData: FormData = new FormData();
    formData.append('FilterData', accountID);
    formData.append('StartRow', '0');
    formData.append('RowLength', '10');
    // formData.append('RowLength', '100');
    formData.append('ExecType', '1');
    formData.append('OrderFilter', '');

    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.PROCESS_TRACKING, formData).subscribe(
      result => {
        let data = result[0];
        let viewThread = 1;
        let redirectionfromDash = true;
        if(data == undefined)
          data = {};
        data['account ID'] = accountID;
        data['viewThread'] = viewThread;
        data['redirectionfromDash'] = redirectionfromDash
        data['threadId'] = value['threadID'];

       
        this.processTrackingService.PassedData(data);
        this.router.navigate(['messagecenter']);
      }
      ,
      error => {


      })
    // this.processTrackingService.PassedData(value);
  }
  RaiseQuery() {
    this.rAiseQuery = true;
    this.messageService.OpenRaiseQuery(this.rAiseQuery)
  }
}
