<hr style="margin-top: 0px!important;">
<!-- <div class="container-fluid" #target>
  <h4 class="text-info"> <span class="fas fa-user-tag mr-2"></span>Role Management</h4>
</div> -->
<!-- <section class="content-header">
  <div class="container-fluid">
    <div class="row">
    </div>
  </div>
</section> -->
<div>
  <section class="content" style="margin-top: 15px;">
    <div class="container-fluid">
      <div class="card card-default" #target>
        <div class="card-header">
          <h3 class="card-title MoodysTittleColor">Role Details</h3>
          <div class="card-tools" style="margin-left: 20px;">
            <div class="input-group input-group-sm">
              <button type="button" class="btn btn-default btn-block btn-sm d-none d-sm-block" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" style="width: 100px;">
                Export</button>
              <div class="input-group-append">
                <button type="button" class="btn btn-default btn-block btn-sm" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" style="background-color:#1D6F42">
                  <i class="fa fa-file-excel-o RMSSearchButtonIcon"></i></button>
                <!--begin::Dropdown Menu-->
                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <!-- <span class="dropdown-item dropdown-header"> Choose an option:</span> -->
                  <!-- <div class="dropdown-divider"></div> -->
                  <button class="dropdown-item btn  btn-block btn-sm" (click)="DownloadExcel()">
                    <i class="fa fa-file-excel-o mr-3" style="color:#1D6F42;"></i> Excel
                  </button>
                  <div class="dropdown-divider"></div>
                  <button class="dropdown-item btn btn-block btn-sm" (click)="DownloadCSV()">
                    <i class="fa fa-file-code-o mr-3" style="color:#1D6F42;"></i> CSV
                  </button>
                  <div class="dropdown-divider"></div>
                  <button class="dropdown-item btn  btn-block btn-sm" (click)="DownloadPDF()">
                    <i class="fa fa-file-pdf-o mr-3" style="color:rgb(196, 32, 50);"></i>PDF
                  </button>
                  <!-- <div class="dropdown-divider"></div>
                      <a class="dropdown-item dropdown-footer"></a> -->
                </div>
                <!--End::Dropdown Menu-->
              </div>

            </div>
          </div>
          <!-- Add button start -->
          <div *ngIf="allowAddEditAction" class="card-tools" style="margin-left: 20px;">
            <div class="input-group input-group-sm">
              <button type="button" class="btn btn-default btn-block btn-sm d-none d-sm-block" data-toggle="collapse"
                data-target="#AddEditRole" aria-expanded="false" aria-controls="AddEditRole" style="width: 120px;"
                (click)="AddNewRoleClick()">Add Role</button>
              <div class="input-group-append">
                <button type="submit" class="btn btn-default btn-block btn-sm" data-toggle="collapse"
                  data-target="#AddEditRole" aria-expanded="false" aria-controls="AddEditRole"
                  (click)="AddNewRoleClick()" style="background-color:rgb(196, 32, 50)">
                  <i class="fas fa-plus RMSSearchButtonIcon"></i></button>
              </div>
            </div>
          </div>
          <!-- Add button end -->
          <div class="card-tools" style="margin-top: 2px;">
            <div class="input-group input-group-sm">
              <h3 class="card-title selector" style="font-size: small;">Status
                <select class="selectstatus" (change)="GetSelectedStatus($event.target.value)">
                  <option value="3">All</option>
                  <option selected value="1">Active</option>
                  <option value="2">Deactive</option>
                </select></h3>
              <div class="input-group-append">

              </div>
            </div>
          </div>
        </div>
        <!-- <div class="card-body">
         
        </div> -->
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <!-- SELECT2 EXAMPLE -->
      <div class="card card-default" *ngIf="AddRoleView" id="AddEditRole">
        <div class="card-header"*ngIf="!addEditHeader">
          <h3 class="card-title MoodysEditButton" style="vertical-align: middle;">Add Role</h3>
        </div>
        <div class="card-header" *ngIf="addEditHeader">
          <h3 class="card-title MoodysEditButton" style="vertical-align: middle;">Edit Role</h3>
        </div>
        <div>
          <app-add-edit-role (closeAddRole)="CloseAddRole($event)" (RefreshGridData)="RefreshGridData($event)"
            [IsNewRole]="IsNewRole" [editData]="editData" [modalTitle]="modalTitle"></app-add-edit-role>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="card card-default">
        <div class="card-body">
          <div class="table-responsive text-nowrap">
            <table id="tabledetail" class="table-hover table-bordered font14 noPad" #tblDetail>
              <thead>

                <tr class="th-sm RMSTableHeader">                 
                  <th class="text-center" style="width: 141px;">Actions</th>
                  <th >RoleName</th>
                  <th class="text-center">Status</th>
                  <th *ngFor="let col of fieldHeader">{{col | titlecase}}</th>
                </tr>
              </thead>
              <tbody class="RMSTableContent">
                <tr *ngFor="let item of data; let i = index" id="tr_{{i}}" (click)="selectRow('tr_' + i)" class="RmsTableRow" >
                  <!-- <td> {{i+1+ pageStart}}</td> -->                  
                  <td class="text-center icon">
                    <button *ngIf="allowAddEditAction" title="Edit Role" class="btn btn-tool" (click)="EditRoleClick(item,target)">
                      <i class="fa fa-edit MoodysEditButton" type="submit"></i>
                    </button>
                    <button *ngIf="allowActiveAction"  class="btn btn-icon-toggle" (click)="Deactivate(item)" data-toggle="tooltip"
                      data-placement="top">
                      <i class="fa fa-trash" title="Deactive" *ngIf="item.status" style="color:rgb(196, 32, 50)"></i>
                      <i *ngIf="!item.status" title="Activate" class="fa fa-trash" style="color:rgb(90, 89, 89)"></i>
                    </button>
                  </td>
                  <td *ngFor="let key of fieldKey" style="text-align: left;">
                    {{item[key]}}
                  </td>
                  <td>
                    <!-- <span *ngIf="headerItem=='status'"> -->
                      <span *ngIf="item.status" class="badge badge-info list activeBadge">
                        Active</span>
                      <span *ngIf="!item.status" class="badge list deactiveBadge">
                        Deactive</span>
                    <!-- </span> -->
                  </td>                  
                  <ng-container *ngFor="let key of fieldKeyVal">
                    <!-- {{item[key]}} -->
                    <td class="text-center colcheck" *ngIf="item[key]"><i class="fas fa-check"></i></td>
                    <td class="text-center colnotcheck" *ngIf="!item[key]"><i class="fa fa-times"></i></td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" style="margin-top:10px;">
            <div class="col-sm-4" style="align-items: center;text-align: center;">
              <label style="font-weight: normal; float: left;font-size: smaller; margin-right: 5px;">Show</label>
              <select title="Records to show" class="form-control selectRecords" style="width: 2rem; height: 22px;padding: 0px;padding-left: 2px;
                font-size: smaller;float: left;text-align: center;" (change)="Records($event.target.value)">
                <!-- <option value="null">--Records--</option> -->
                <!-- <option value="1" > 1 </option> -->
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100" selected=true>100</option>
                <option value="500">500</option>
                <option value="all">All</option>
              </select>
              <label style="font-weight: normal; float: left;font-size: smaller; margin-left: 5px;">Records</label>
            </div>
            <label class="col-sm-4" style="font-size: smaller;text-align: center;font-weight: normal;">Showing
              {{pageStart+1}} to {{pageStart+rowCount}} of {{totalCount}} Records</label>
            <div class="col-sm-4">
              <nav aria-label="Page navigation" class="float-right">
                <ul class="pagination float-right">
                  <li class="page-item">
                    <a class="RMSButtonPageNavigation page-link" (click)="PageChange(1)" aria-label="First">
                      <label class="fa fa-angle-double-left"
                        style=" margin-left: -4px; font-size: small !important; font-weight: bold !important;"></label>
                      <!-- <span class="sr-only">Previous</span> -->
                    </a>
                  </li>
                  <li class="page-item">
                    <a (click)="PageChange(currentPage-1)" class="RMSButtonPageNavigation page-link"
                      aria-label="Previous">
                      <label class="fa fa-angle-left"
                        style="font-size:small !important;font-weight:bold !important;"></label>
                    </a>
                  </li>
                  <li class="page-item" *ngIf=previousDotShow>
                    <label class="RMSButtonPageNavigation" style="font-weight:bold !important;padding-top: 9px;">...</label>
                  </li>
                  <li *ngFor="let i of item">
                    <a (click)="PageChange(i)"   [ngClass]="{'selectedPage':i===selectedPageNo}"class="RMSButtonPageNavigation page-link">
                      <label style="vertical-align: sub !important;font-size: small;margin-left: -4px; ">{{ i }}</label>
                    </a>
                  </li>
                  <li class="page-item" *ngIf=nextDotShow>
                    <!-- <a   class="RMSButtonPageNavigation page-link text-info" >                   -->
                    <label class="RMSButtonPageNavigation" style="font-weight:bold !important;padding-top: 9px;">...</label>
                    <!-- </a> -->
                  </li>
                  <li class="page-item">
                    <a (click)="PageChange(currentPage+1)" class="RMSButtonPageNavigation page-link"
                      aria-label="Next">
                      <label class="fa fa-angle-right"
                        style="font-size:small !important;font-weight:bold !important;"></label>
                    </a>
                  </li>
                  <li class="page-item">
                    <a (click)="PageChange(pageCount)" class="RMSButtonPageNavigation page-link"
                      aria-label="Last">
                      <label class="fa fa-angle-double-right"
                        style="margin-left: -4px;font-size:small !important;font-weight:bold !important;"></label>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>