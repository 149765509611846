import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';

import { DynamicElementBase } from 'src/common/DynamicElementBase';

@Injectable()
export class DynamicControlService {
  constructor() { }
  toFormGroup(elementList: DynamicElementBase<string>[], from: UntypedFormGroup) {
    let group: any = {};
    if (elementList === null)
      return new UntypedFormGroup(group);
    elementList.forEach(element => {
      let validators: ValidatorFn[];
      validators = [];
      if (element.isMandatory)
        validators.push(Validators.required);
      if (element.validations != null && element.validations.length > 0) {
        //console.log(element.validations);
        element.validations.forEach(validation => {
          if (!validation['isIgnorable']) {
            if (validation['validationType'] === 'MaxLength')
              validators.push(Validators.maxLength(validation['validationValue']))
            if (validation['validationType'] == 'SpecialCharacter' || validation['validationType'] == 'NumericCheck' || validation['validationType'] == 'SpecialCharacterAtStart') {
              let val: string = validation['validationValue'];
              validators.push(Validators.pattern(val));
              // console.log(val);
              // console.log(validators);           
            }
            if (validation['validationType'] === 'MaxLimit'){          
            validators.push(Validators.min(0));
            validators.push(Validators.max(validation['validationValue']));
          }
          }

          //validators.push(Validators.pattern('(?![=+@-]).*'));
          //let pa='[a-zA-Z1-9]*'
          // let pa='((?![\\/|:*.%?<>,;=+!#`~^\\{\\}\\[\\]\\\\]).)*'          
          // validators.push(Validators.pattern(pa));//^((?!hede).)*$

        });
      }

      if (validators != null && validators.length > 0)
        from.addControl(element.key, new UntypedFormControl(element.value || '', { validators: validators, updateOn: "change" }));
      else
        from.addControl(element.key, new UntypedFormControl(element.value || ''));

      // from.addControl(element.key, element.isMandatory ? new FormControl(element.value || '', { validators: validators, updateOn: "change" })
      //   : new FormControl(element.value || ''));
    });
  }
}