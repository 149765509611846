import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, NgModule, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { color } from 'highcharts';
import { ApexChart, ApexDataLabels, ApexNonAxisChartSeries, ApexTooltip, NgApexchartsModule } from 'ng-apexcharts';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { CommonMethod } from 'src/common/CommonMethod';
import { FeatureMaster } from 'src/common/feature-master';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { DataShareService } from 'src/services/Common/dataShare.service';
import { MessageCenterserviceService } from 'src/services/message-centerservice.service';
import { ProcessTrackingService } from 'src/services/Process-TrackingServices/process-tracking.service';

export type ChartOptions = {
  series: {
    name: string;
    data: any[];
  }[];
  chart: {
    type: string;
    height: number;
    events: any;
  };
  xaxis: {
    categories: string[];
  };
  plotOptions:{
    bar:{
      horizontal: boolean;
      distributed: boolean;
    }
  };
  tooltip: ApexTooltip;
};

@Component({ 
  selector: 'app-accounts-summary',
  templateUrl: './accounts-summary.component.html',
  styleUrl: './accounts-summary.component.css'
})

export class AccountsSummaryComponent implements OnInit, AfterViewInit{
  
  constructor(private messageService: MessageCenterserviceService, private processTrackingService: ProcessTrackingService, private Userdetail: AddEditUserSerivceService, private ShareData: DataShareService){
  }
  ngAfterViewInit(): void {
    let selectedRow: string;
    this.ShareData.SelectedRow$.subscribe(row => selectedRow = row);
    if (selectedRow != undefined && selectedRow != '')
      this.waitElement(selectedRow);
  }
  waitElement(id: string) {

    setTimeout(function () {
      let eleToClick = document.getElementById(id) as HTMLElement;
      if (eleToClick == null || eleToClick == undefined) {
        this.waitElement(id);
      }
      else {
        CommonMethod.selectRow(id);
      }
    }, 900);
  }

  PreBindAccountFlag: boolean = false;
  PreBindAccounts: any;
  TempAccountsData: any;
  showAccountsTable = true;
  dynFieldheader: string[];
  dynamicHeader: any[];
  obj: CommonMethod = new CommonMethod();
  showFilter = false;
  authorizedForEdit: boolean;
  authorizedForDownload: boolean;
  authorizedForCancelAccount: boolean;
  authorizedForUploadAdditionSOV: boolean;
  authorizedForDownloadSOV: boolean;
  authorizedForCloseAccount: boolean;
  authorizedForRaiseQuery: boolean;
  IsAuthorizedForAdditionalShipment: boolean;
  IsAuthorizeForRAShipment: boolean;
  IsAuthorizeForDESShipment: boolean;
  IsRAAdditionalShipment: boolean;
  IsRAApplicable: boolean = false;
  IsDESApplicable: boolean = false;
  authorizedForUploadShipment: boolean = false;
  sortUP: boolean = true;
  sortDesc: boolean = false;
  columSelected: any = 5;
  downloading: boolean = false;
  progress: number = 0;
  DownloadshippemntAccountNmae;
  authorizedForActivateAccount: boolean;
  userdetails: any;
  isRmsUSer: boolean;
  AccountsData:any;
  AccountsDataForTable: any;
  QueryData:any;
  pageStart: number = 0;
  showDashboard = true;
  DashboardAccountsUploaded: any;
  DashboardNoOfAccountsUploaded = 0;
  DashboardAccountsShipped: any;
  DashboardNoOfAccountsShipped = 0;
  DashboardAccountsInQuery: any;
  DashboardNoOfAccountsInQuery = 0;
  DashboardInProcessAccounts: any;
  DashboardNoOfInProcessAccounts = 0;
  DashboardInProcessAccountsRA: any;
  DashboardNoOfInProcessAccountsRA = 0;
  DashboardInProcessAccountsGC: any;
  DashboardNoOfInProcessAccountsGC = 0;
  DashboardInProcessAccountsCleansing: any;
  DashboardNoOfInProcessAccountsCleansing = 0;
  DashboardInProcessAccountsQueue: any;
  DashboardNoOfInProcessAccountsQueue = 0;
  DashboardInProcessAccountsND: any;
  DashboardNoOfInProcessAccountsND = 0;
  AccountsInQueryOnPriority : any;
  AccountsInQueryOnCancellation : any;
  AccountsInQueryOnAdditionalUpdates : any;
  AccountsInQueryOnStatusUpdates : any;
  AccountsInQueryHavingIssues : any;
  AccountsInQueryInGeneral : any;
  AccountsInQueryOnUsage : any;
  AccountsInQueryOnRequest : any;
  NoOfAccountsInQueryOnPriority = 0;
  NoOfAccountsInQueryOnCancellation = 0;
  NoOfAccountsInQueryOnAdditionalUpdates = 0;
  NoOfAccountsInQueryOnStatusUpdates = 0;
  NoOfAccountsInQueryHavingIssues = 0;
  NoOfAccountsInQueryInGeneral = 0;
  NoOfAccountsInQueryOnUsage = 0;
  NoOfAccountsInQueryOnRequest = 0;
  AccountTypesMapped : any[];
  SelectedAccountTypeValue = "All";
  
  // Chart Variables
  TempPieChartSeries: number[]= [];
  PieChartSeries: ApexNonAxisChartSeries;
  PieChartDetails: ApexChart;
  AllPieChartLabels = ["Priority", "Cancellation" ,"Updates" ,"Status Update" ,"Issues" ,"General" ,"Usage" ,"Meeting Request"];
  TempPieChartLabels: string[] = [];
  PieChartLabels : string[];
  PieChartDataLabels: ApexDataLabels;
  BarChartOptions: ChartOptions;
  BarChartLabels = ["Uploaded", "Shipped", "Query", "RA", "GC", "Cleansing", "Queue", "ND"];

  ngOnInit(){
    this.CheckAuthorized();
    this.Userdetail.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      this.userdetails = res['userDetails'][0]['clientID'];
      this.AccountTypesMapped = res['accountType'];
      this.AccountTypesMapped.splice(0,0, {accountTypeName:"All", accountTypeID : 0 });

      if (this.userdetails > 2) {
        this.isRmsUSer = false
      } else {
        this.isRmsUSer = true;
      }
      if(this.userdetails){
        this.showDashboard = true;
        this.ShowAccountsDashboard();
      }}, err => {
    })
    
    this.fillData('', 0, 100, '', 1);
  }

  fillData(filter: string, startRow: number, rowLength: number, orderFilter: string, execType: number) {

    if (execType == 0)
      execType = 1;
    let formData: FormData = new FormData();
    formData.append('FilterData', filter);
    formData.append('StartRow', String(startRow));
    formData.append('RowLength', String(rowLength));
    formData.append('ExecType', String(execType));
    formData.append('OrderFilter', String(orderFilter));

    this.AccountsData = [];

    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.PROCESS_TRACKING_DASHBOARD, formData).subscribe(
      result => {
        this.AccountsData = result;
        this.TempAccountsData = this.AccountsData;
        this.AccountsDataForTable = this.AccountsData;
        this.PreBindAccounts = this.getPreBindAccounts(this.AccountsData);

        this.dynFieldheader = [];
        this.dynFieldheader = this.dynFieldheader.concat(Object.keys(result[0]));

        this.PopulateDashboardData();
      },
      error => {
        console.log(error);
      }
    )
  }

  ShowAccountsDashboard(){
    
  }

  convertToInt(val) {
    return parseInt(val);
  }

  ShowAccountsFromLast24Hours(){
    this.AccountsDataForTable = this.DashboardAccountsUploaded;
  }

  getPreBindAccounts(data: any[]): any[] {
    return data.filter(item => {
      return item["account Type"] == "Pre Bind";
    });
  }

  getAccountsInQuery(data: any[]): any[] {
    return data.filter(item => {
      return item["account Status"] == "Query";
    });
  }

  ShowAccountsWithQueryData(){
    this.AccountsDataForTable = this.DashboardAccountsInQuery;
  }

  getShippedAccounts(data: any[]): any[] {
    return data.filter(item => {
      return item["account Status"] == "Shipped" || item["account Status"] == "Downloaded";
    });
  }

  ShowShippedAccountsData(){
    this.AccountsDataForTable = this.DashboardAccountsShipped;
  }

  getAccountsInProcess(data: any[]): any[] {
    return data.filter(item => {
      return (item["account Status"] == "In Cleansing" || item["account Status"] == "In Geo-coding"
      || item["account Status"] == "In Risk Analytics" || item["account Status"] == "In Queue" 
      || item["account Status"] == "Not Downloaded");
    });
  }

  ShowAccountsInProcess(){
    this.AccountsDataForTable = this.DashboardInProcessAccounts;
  }

  getInProcessAccountsRA(data: any[]): any[] {
    return data.filter(item => {
      return item["account Status"] == "In Risk Analytics";
    });
  }

  getInProcessAccountsGC(data: any[]): any[] {
    return data.filter(item => {
      return item["account Status"] == "In Geo-coding";
    });
  }

  getInProcessAccountsCleansing(data: any[]): any[] {
    return data.filter(item => {
      return item["account Status"] == "In Cleansing";
    });
  }

  getInProcessAccountsQueue(data: any[]): any[] {
    return data.filter(item => {
      return item["account Status"] == "In Queue";
    });
  }

  getInProcessAccountsND(data: any[]): any[] {
    return data.filter(item => {
      return item["account Status"] == "Not Downloaded";
    });
  }
  
  getAccountsInQueryOnPriority(){
    return this.DashboardAccountsInQuery.filter(item => {
      return item["query Type"] == "Priority";
    });
  }
  getAccountsInQueryOnCancellation(){
    return this.DashboardAccountsInQuery.filter(item => {
      return item["query Type"] == "Cancellation";
    });
  }
  getAccountsInQueryOnAdditionalUpdates(){
    return this.DashboardAccountsInQuery.filter(item => {
      return item["query Type"] == "Additional Information/Updates";
    });
  }
  getAccountsInQueryOnStatusUpdates(){
    return this.DashboardAccountsInQuery.filter(item => {
      return item["query Type"] == "Status Update";
    });
  }
  getAccountsInQueryHavingIssues(){
    return this.DashboardAccountsInQuery.filter(item => {
      return item["query Type"] == "Issues/Attention Required";
    });
  }
  getAccountsInQueryInGeneral(){
    return this.DashboardAccountsInQuery.filter(item => {
      return item["query Type"] == "General";
    });
  }
  getAccountsInQueryOnUsage(){
    return this.DashboardAccountsInQuery.filter(item => {
      return item["query Type"] == "Subscription/Usage";
    });
  }
  getAccountsInQueryOnRequest(){
    return this.DashboardAccountsInQuery.filter(item => {
      return item["query Type"] == "Meeting Request";
    });
  }
  getAccountsBasedOnAccountType(accountType:string){
    return this.TempAccountsData.filter(item => {
      return item["account Type"] == accountType;
    });
  }

  PopulateDashboardData(){
    this.DashboardAccountsUploaded = this.AccountsData;
        this.DashboardNoOfAccountsUploaded = this.DashboardAccountsUploaded.length;

        this.DashboardAccountsInQuery = this.getAccountsInQuery(this.AccountsData);
        this.DashboardNoOfAccountsInQuery = this.DashboardAccountsInQuery.length;

        this.DashboardAccountsShipped = this.getShippedAccounts(this.AccountsData);
        this.DashboardNoOfAccountsShipped = this.DashboardAccountsShipped.length;

        this.DashboardInProcessAccounts = this.getAccountsInProcess(this.AccountsData);
        this.DashboardNoOfInProcessAccounts = this.DashboardInProcessAccounts.length;

        this.DashboardInProcessAccountsRA = this.getInProcessAccountsRA(this.DashboardInProcessAccounts);
        this.DashboardNoOfInProcessAccountsRA = this.DashboardInProcessAccountsRA.length;

        this.DashboardInProcessAccountsGC = this.getInProcessAccountsGC(this.DashboardInProcessAccounts);
        this.DashboardNoOfInProcessAccountsGC = this.DashboardInProcessAccountsGC.length;

        this.DashboardInProcessAccountsCleansing = this.getInProcessAccountsCleansing(this.DashboardInProcessAccounts);
        this.DashboardNoOfInProcessAccountsCleansing = this.DashboardInProcessAccountsCleansing.length;

        this.DashboardInProcessAccountsQueue = this.getInProcessAccountsQueue(this.DashboardInProcessAccounts);
        this.DashboardNoOfInProcessAccountsQueue = this.DashboardInProcessAccountsQueue.length;

        this.DashboardInProcessAccountsND = this.getInProcessAccountsND(this.DashboardInProcessAccounts);
        this.DashboardNoOfInProcessAccountsND = this.DashboardInProcessAccountsND.length;
        
        this.BarChartOptions = {
          series:[{
            name: "Accounts",
            data: [
              { x:"Uploaded", y:this.DashboardNoOfAccountsUploaded, fillcolor:"#FF5733"}
              , { x:"Shipped", y:this.DashboardNoOfAccountsShipped, fillcolor:"#33FF57"} 
              , { x:"Query", y:this.DashboardNoOfAccountsInQuery, fillcolor:"#FF33A1"} 
              , { x:"RA", y:this.DashboardNoOfInProcessAccountsRA, fillcolor:"#3357FF"}
              , { x:"GC", y:this.DashboardNoOfInProcessAccountsGC, fillcolor:"#3357FF"} 
              , { x:"Cleansing", y:this.DashboardNoOfInProcessAccountsCleansing, fillcolor:"#3357FF"} 
              , { x:"Queue", y:this.DashboardNoOfInProcessAccountsQueue, fillcolor:"#B733FF"}
              , { x:"ND", y:this.DashboardNoOfInProcessAccountsND, fillcolor:"#FF5733"} 
            ]
          }],
          chart:{
            type:"bar",
            height: 350,
            events:{
              click: (event, chartContext, config) => {
                if(this.BarChartLabels[config.dataPointIndex] == "Uploaded"){
                  this.AccountsDataForTable = this.AccountsData;
                }
                else if(this.BarChartLabels[config.dataPointIndex] == "Shipped"){
                  this.AccountsDataForTable = this.DashboardAccountsShipped;
                }
                else if(this.BarChartLabels[config.dataPointIndex] == "Query"){
                  this.AccountsDataForTable = this.DashboardAccountsInQuery;
                }
                else if(this.BarChartLabels[config.dataPointIndex] == "RA"){
                  this.AccountsDataForTable = this.DashboardInProcessAccountsRA;
                }
                else if(this.BarChartLabels[config.dataPointIndex] == "Geo-Coding"){
                  this.AccountsDataForTable = this.DashboardInProcessAccountsGC;
                }
                else if(this.BarChartLabels[config.dataPointIndex] == "Cleansing"){
                  this.AccountsDataForTable = this.DashboardInProcessAccountsCleansing;
                }
                else if(this.BarChartLabels[config.dataPointIndex] == "Queue"){
                  this.AccountsDataForTable = this.DashboardInProcessAccountsQueue;
                }
                else if(this.BarChartLabels[config.dataPointIndex] == "ND"){
                  this.AccountsDataForTable = this.DashboardInProcessAccountsND;
                }
              }
            }
          },
          xaxis:{
            categories:["Uploaded", "Shipped", "Query", "RA", "GC", "Cleansing", "Queue", "ND"]
          },
          plotOptions:{
            bar:{
              horizontal: false,
              distributed: true
            }
          },
          tooltip:{
            enabled:true,
            fillSeriesColor: true, 
            custom: function({series,seriesIndex,dataPointIndex, w})
            {
              const color = w.globals.colors[seriesIndex];
              let category = w.globals.labels[dataPointIndex]; 
              if (category == "RA") category = "Risk Analytics";
              if (category == "GC") category = "Geo-Coding";
              if (category == "ND") category = "Not Downloaded";
              const value = series[seriesIndex][dataPointIndex];  
              return `
                  <div class="custom-tooltip" 
                  style = "background-color: ${color}; color: #fff !important; border-radius: 5px !important; padding: 8px 15px !important; font-size: 12px !important; box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
                  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;">
                    <strong>${category}</strong><br>
                    Accounts : ${value}
                  </div>`;
            },
            style: {
              fontSize: '14px', 
              fontFamily: 'Arial, sans-serif', 
            }
          }
        };

        this.TempPieChartSeries = [];
        this.TempPieChartLabels = [];

        this.AccountsInQueryOnPriority = this.getAccountsInQueryOnPriority()
        this.NoOfAccountsInQueryOnPriority = this.AccountsInQueryOnPriority.length;
        this.AccountsInQueryOnCancellation = this.getAccountsInQueryOnCancellation()
        this.NoOfAccountsInQueryOnCancellation = this.AccountsInQueryOnCancellation.length;
        this.AccountsInQueryOnAdditionalUpdates = this.getAccountsInQueryOnAdditionalUpdates()
        this.NoOfAccountsInQueryOnAdditionalUpdates = this.AccountsInQueryOnAdditionalUpdates.length;
        this.AccountsInQueryOnStatusUpdates = this.getAccountsInQueryOnStatusUpdates()
        this.NoOfAccountsInQueryOnStatusUpdates = this.AccountsInQueryOnStatusUpdates.length;
        this.AccountsInQueryHavingIssues = this.getAccountsInQueryHavingIssues()
        this.NoOfAccountsInQueryHavingIssues = this.AccountsInQueryHavingIssues.length;
        this.AccountsInQueryInGeneral = this.getAccountsInQueryInGeneral()
        this.NoOfAccountsInQueryInGeneral = this.AccountsInQueryInGeneral.length;
        this.AccountsInQueryOnUsage = this.getAccountsInQueryOnUsage()
        this.NoOfAccountsInQueryOnUsage = this.AccountsInQueryOnUsage.length;
        this.AccountsInQueryOnRequest = this.getAccountsInQueryOnRequest()
        this.NoOfAccountsInQueryOnRequest = this.AccountsInQueryOnRequest.length;

        if(this.NoOfAccountsInQueryOnPriority>0){
          if(!this.TempPieChartSeries.includes(this.NoOfAccountsInQueryOnPriority)) 
          {
            this.TempPieChartSeries.push(this.NoOfAccountsInQueryOnPriority);
            this.TempPieChartLabels.push("Priority");
          }
        }        
        if(this.NoOfAccountsInQueryOnCancellation>0){
          if(!this.TempPieChartSeries.includes(this.NoOfAccountsInQueryOnCancellation)) 
          {
            this.TempPieChartSeries.push(this.NoOfAccountsInQueryOnCancellation);
            this.TempPieChartLabels.push("Cancellation");
          }
        }
        if(this.NoOfAccountsInQueryOnAdditionalUpdates>0){
          if(!this.TempPieChartSeries.includes(this.NoOfAccountsInQueryOnAdditionalUpdates)) 
          {
            this.TempPieChartSeries.push(this.NoOfAccountsInQueryOnAdditionalUpdates);
            this.TempPieChartLabels.push("Updates");
          }
        }
        if(this.NoOfAccountsInQueryOnStatusUpdates>0){
          if(!this.TempPieChartSeries.includes(this.NoOfAccountsInQueryOnStatusUpdates)) 
          {
            this.TempPieChartSeries.push(this.NoOfAccountsInQueryOnStatusUpdates);
            this.TempPieChartLabels.push("Status Update");
          }
        }
        if(this.NoOfAccountsInQueryHavingIssues>0){
          if(!this.TempPieChartSeries.includes(this.NoOfAccountsInQueryHavingIssues)) 
          {
            this.TempPieChartSeries.push(this.NoOfAccountsInQueryHavingIssues);
            this.TempPieChartLabels.push("Issues");
          }
        }
        if(this.NoOfAccountsInQueryInGeneral>0){
          if(!this.TempPieChartSeries.includes(this.NoOfAccountsInQueryInGeneral)) 
          {
            this.TempPieChartSeries.push(this.NoOfAccountsInQueryInGeneral);
            this.TempPieChartLabels.push("General");
          }
        }
        if(this.NoOfAccountsInQueryOnUsage>0){
          if(!this.TempPieChartSeries.includes(this.NoOfAccountsInQueryOnUsage)) 
          {
            this.TempPieChartSeries.push(this.NoOfAccountsInQueryOnUsage);
            this.TempPieChartLabels.push("Usage");
          }
        }
        if(this.NoOfAccountsInQueryOnRequest>0){
          if(!this.TempPieChartSeries.includes(this.NoOfAccountsInQueryOnRequest)) 
          {
            this.TempPieChartSeries.push(this.NoOfAccountsInQueryOnRequest);
            this.TempPieChartLabels.push("Meeting Request");
          }
        }

        this.PieChartSeries = this.TempPieChartSeries;
        this.PieChartLabels = this.TempPieChartLabels;

        this.PieChartDetails = {
          type: 'pie',
          toolbar: {
            show: true
          },
          events:{
            click: (event, chartContext, config) => {
              if(this.AllPieChartLabels[config.dataPointIndex] == "Priority"){
                this.AccountsDataForTable = this.AccountsInQueryOnPriority;
              }
              else if(this.AllPieChartLabels[config.dataPointIndex] == "Cancellation"){
                this.AccountsDataForTable = this.AccountsInQueryOnCancellation;
              }
              else if(this.AllPieChartLabels[config.dataPointIndex] == "Updates"){
                this.AccountsDataForTable = this.AccountsInQueryOnAdditionalUpdates;
              }
              else if(this.AllPieChartLabels[config.dataPointIndex] == "Status Update"){
                this.AccountsDataForTable = this.AccountsInQueryOnStatusUpdates;
              }
              else if(this.AllPieChartLabels[config.dataPointIndex] == "Issues"){
                this.AccountsDataForTable = this.AccountsInQueryHavingIssues;
              }
              else if(this.AllPieChartLabels[config.dataPointIndex] == "General"){
                this.AccountsDataForTable = this.AccountsInQueryInGeneral;
              }
              else if(this.AllPieChartLabels[config.dataPointIndex] == "Usage"){
                this.AccountsDataForTable = this.AccountsInQueryOnUsage;
              }
              else if(this.AllPieChartLabels[config.dataPointIndex] == "Meeting Request"){
                this.AccountsDataForTable = this.AccountsInQueryOnRequest;
              }
            }
          }
        };
        this.PieChartDataLabels = {
          enabled: true
        };
  }

  AccountTypeChange(){
    if(this.SelectedAccountTypeValue != "All"){
      this.AccountsData = this.getAccountsBasedOnAccountType(this.SelectedAccountTypeValue);
      this.AccountsDataForTable = this.AccountsData;
      this.PopulateDashboardData();
    }
    else{
      this.AccountsData = this.TempAccountsData;
      this.AccountsDataForTable = this.TempAccountsData;
      this.PopulateDashboardData();
    }
  }

  CheckAuthorized() {
    this.Userdetail.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      let FeatureList = res["roleFeatureMapping"]
      this.authorizedForEdit = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Edit_Account);
      this.authorizedForDownload = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Download_SOV);
      this.authorizedForCancelAccount = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Cancel_Account);
      this.authorizedForUploadAdditionSOV = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Upload_Additional_SOV);
      this.authorizedForDownloadSOV = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Download_SOV);
      this.authorizedForCloseAccount = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Close_Account);
      this.authorizedForRaiseQuery = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.RaiseReply_Query);
      this.IsAuthorizedForAdditionalShipment = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Upload_Additional_Shipment);
      this.IsAuthorizeForDESShipment = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Upload_DES_Shipment);
      this.IsAuthorizeForRAShipment = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Upload_RA_Shipment);
      this.authorizedForActivateAccount = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.ReActivate_Account);
      if (this.IsAuthorizeForRAShipment && !this.IsAuthorizeForDESShipment) {
        this.IsRAAdditionalShipment = true;
      } else {
        this.IsRAAdditionalShipment = false;
      }
      if (this.IsAuthorizeForDESShipment || this.IsAuthorizeForRAShipment) {
        this.authorizedForUploadShipment = true;
      } else {
        this.authorizedForUploadShipment = false;
      }
    })
  }
}
