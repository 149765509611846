import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';

@Injectable({
  providedIn: 'root'
})
export class RoleManagementServiceService {
  

  constructor(private httpClient: HttpClient) { }
  PostRequest(Path: string,FormVal: any)
  { 
    return this.httpClient.post(Path,FormVal).pipe(map(response => {
    let result = response;    
    return result;
  },
    error => {
      let err = error.json();
      return err;
    }))
  }

  GetRoleMappingData(FormValue: any) {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.ROLECOMOPONENTDISPLAY,FormValue).pipe(map(response => {
      let result = response;
      return result;
    },
      error => {
        let err = error.json();
        return err;
      }))
  }
  getFeatureList()
  {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETFEATURELIST, "").pipe(map(response => {
      let result = response;
      return result;
    },
      error => {
        let err = error.json();
        return err;
      }))
  }

  AddRoleMappingData(FormValue: any) {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.ADDROLE,FormValue,{responseType: 'text'}).pipe(map(response => {
      let result = response;
      return result;
    },
      error => {
        let err = error.json();
        return err;
      }))
  }

}
