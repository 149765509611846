<!-- <style>
  .inputClass1 {
    height: auto !important;
  }

  .SignInBtn {
    width: 48% !important;
  }

  .captchaDiv {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .gc-reset {
    transform: scale(0.95) !important;
    -webkit-transform: scale(0.95) !important;
    transform-origin: 0 0 !important;
    -webkit-transform-origin: 0 0 !important;
  }
  @media screen and (max-width: 575px) {
    .captchaDiv {
      max-width: 150px !important;
      transform: scale(0.60) !important;
      -webkit-transform: scale(0.60);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
  }

  @media screen and (max-width: 1024px) {
    .captchaDiv {
      max-width: 150px !important;
    }

    table {
      width: 60%;
    }
  }
</style>


<div class=" setBGImage">

  <div class="container MainContainer">
    <div class="row">
      <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 2rem !important;">
        <div class="alert alert-dismissible" [ngClass]="IsSuccessMessage ? 'alert-success' : 'alert-danger'"
          *ngIf="showFormError">
          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
          {{formError}}
        </div>
      </div>
      <div class="col-lg-6">
      <img  src="../../../assets/img/RMS.png" style="width:102%;">
        <br />
        <img src="../../../assets/img/MoodysRMSLogo1.png" style="width: 82%;
        margin-top: 65px;
        margin-left: 52px;" />
      </div>
      <div class="col-lg-6">

        <form [formGroup]="contactForm" (ngSubmit)="SubmitForm();">
          <table class="table" style="width:100%;">
            <tr>
              <td style="text-align:center;border-top-style:none">
                <br />
                <h4 class="HeadLogin">Contact Support</h4>
              </td>

            </tr>
            <tr>
              <td style=" padding-bottom:0px;">
                <div style="padding-top:5%" class="input-group">
                  <span class="input-group-addon" id="sizing-addon1" style="border-radius:0px;background-color:#c42032">
                    <i class="fa fa-envelope InputIcon"></i></span>
                  <input type="text" class="form-control inputClass" (ngModelChange)="ShowEmailError();"
                    formControlName="cEmail" placeholder="Enter your email address" aria-describedby="sizing-addon1"
                    style="border-radius:0px;max-width:100%;  font-weight: 200;" />
                  <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; min-height: 1rem !important;">
                    <div *ngIf="GetFormControls.cEmail.errors && showEmailError ">
                      <div *ngIf="showFormError && GetFormControls.cEmail.errors.required">Email is required.</div>
                      <div *ngIf="GetFormControls.cEmail.errors.pattern">Please check the format of the email address
                        entered.</div>
                    </div>
                  </div>
                </div>

              </td>
            </tr>

            <tr>
              <td style="border-top-style:none; padding-top:0px; padding-bottom:0px;">
                <div class="input-group">
                  <span class="input-group-addon" id="sizing-addon1" style="border-radius:0px;background-color:#c42032">
                    <i class="fa fa-envelope InputIcon"></i></span>
                  <input type="text" class="form-control inputClass" (ngModelChange)="ShowSubjectError();"
                    formControlName="cSubject" placeholder="Subject" aria-describedby="sizing-addon1"
                    style="border-radius:0px;max-width:100%;  font-weight: 200;" />
                  <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; min-height: 1rem !important;">
                    <div *ngIf="GetFormControls.cSubject.errors && showcSubjectError ">
                      <div *ngIf="  showFormError && GetFormControls.cSubject.errors.required">Subject is required.
                      </div>
                      <div *ngIf="GetFormControls.cSubject.errors.maxlength">Maximum 100 characters allowed.</div>
                      <div *ngIf="GetFormControls.cSubject.errors.minlength">Minimum 10 characters allowed.</div>
                    </div>
                  </div>
                </div>

              </td>
            </tr>


            <tr>

              <td style="border-top-style:none; padding-top:0px; padding-bottom:0px;">
                <div class="input-group">
                  <span class="input-group-addon" id="sizing-addon1"
                    style="font-size: 14.5px;border-radius:0px;background-color:#c42032"><i
                      class="fa fa-envelope InputIcon" style="font-size: 14.5px;"></i></span>
                  <textarea id="inputMessage" maxlength="1001" class="form-control inputClass inputClass1"
                    (ngModelChange)="ShowMessageError();" formControlName="cMsg" placeholder="Message" name="txtMessage"
                    cols="30" rows="3" aria-describedby="sizing-addon1"
                    style="border-radius:0px;max-width:100%; font-weight: 200;"></textarea>
                  <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                    <div *ngIf="GetFormControls.cMsg.errors && showcMsgError">
                      <div *ngIf="showFormError && GetFormControls.cMsg.errors.required">Message is required.</div>
                      <div *ngIf="GetFormControls.cMsg.errors.maxlength">Maximum 1000 characters allowed</div>
                      <div *ngIf="GetFormControls.cMsg.errors.minlength">Minimum 20 characters allowed</div>
                    </div>
                  </div>
                </div>

              </td>
            </tr>
            <tr>
              <td style="border-top-style:none;">
                <div class="input-group captchaDiv">
                  <re-captcha formControlName="recaptchaReactive" class="gc-reset"  (resolved)="resolved($event)">
                  </re-captcha>
                </div>

              </td>
            </tr>

            <tr>
              <td style="padding-top:5%">
                <button  type="submit" (click)="SubmitForm()" class="btn btn-success SignInBtn float-left">
                  Submit
                </button>

                <a routerLink="/login" class="btn btn-success SignInBtn float-right"> Back</a>
              </td>
            </tr>

          </table>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col" style="text-align:center;">
        <h6 style="font-size: .8rem; color:#989696;">© {{Year | date: "y"}} Risk Management Solutions, Inc. All Rights Reserved.</h6>
        <br />
      </div>
    </div>
  </div>
</div> -->

<div class=" setBGImage">
  <div>
    <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 2rem !important;">
      <div class="alert alert-dismissible" [ngClass]="IsSuccessMessage ? 'alert-success' : 'alert-danger'"
        *ngIf="showFormError">
        <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
        {{formError}}
      </div>
    </div>
    <div class="main">
      <div class="container" style="width: 35%;padding-top: 30px;">
        <div class="align-middle">
          <div class="card"
            style="height: 530px;align-items: center;border-radius: 30px;padding-left: 25px;padding-right:25px;width: fit-content;">
            <div style="text-align: center;">
              <div class="app-header">
                <img src="../../../assets/svg/MoodysBlue.svg" alt="Logo" class="app-logo">
                <h3 class="MoodyTittleColor2">Data Solution Portal</h3>
              </div>
            </div>
            <div class="card-body" style="width: -webkit-fill-available;">
              <form [formGroup]="contactForm" (ngSubmit)="SubmitForm();">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-envelope MoodysTittleColor2"></i></span>
                  </div>
                  <input (ngModelChange)="ShowEmailError();" formControlName="cEmail"
                    placeholder="Enter your email address" style="border-bottom-right-radius: 10px;
              border-top-right-radius: 10px;" type="email" class="form-control" id="nombre" name="nombre"
                    placeholder="Example@moodys.com" required>

                  <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; min-height: 1rem !important;">
                    <div *ngIf="GetFormControls.cEmail.errors && showEmailError ">
                      <div *ngIf="showFormError && GetFormControls.cEmail.errors.required">Email is required.</div>
                      <div *ngIf="GetFormControls.cEmail.errors.pattern">Please check the format of the email address
                        entered.</div>
                    </div>
                  </div>
                </div>

                <div class="input-group">

                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-envelope MoodyTittleColor2"></i></span>
                  </div>
                  <input style="border-bottom-right-radius: 10px;
              border-top-right-radius: 10px;" (ngModelChange)="ShowSubjectError();" formControlName="cSubject"
                    placeholder="Subject" class="form-control" id="nombre" name="email" placeholder="Subject" required>
                  <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; min-height: 1rem !important;">
                    <div *ngIf="GetFormControls.cSubject.errors && showcSubjectError ">
                      <div *ngIf="  showFormError && GetFormControls.cSubject.errors.required">Subject is required.
                      </div>
                      <div *ngIf="GetFormControls.cSubject.errors.maxlength">Maximum 100 characters allowed.</div>
                      <div *ngIf="GetFormControls.cSubject.errors.minlength">Minimum 10 characters allowed.</div>
                    </div>
                  </div>
                </div>


                <div class="input-group " style="height: 60px;border-radius: 10px;padding-top: 5px;">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fa fa-comment MoodysTittleColor2"></i></span>
                    </div>
                    <textarea class="form-control" (ngModelChange)="ShowMessageError();" formControlName="cMsg" style="height: 55px;border-bottom-right-radius: 10px;
                  border-top-right-radius: 10px;" placeholder="Message" required></textarea>
                  </div>
                  <div *ngIf="GetFormControls.cMsg.errors && showcMsgError" class="col-lg-12 ErrorMessage NoPadding"
                    style="color: #c42032; min-height: 1rem !important;">
                    <div *ngIf="showFormError && GetFormControls.cMsg.errors.required">Message is required.</div>
                    <div *ngIf="GetFormControls.cMsg.errors.maxlength">Maximum 1000 characters allowed</div>
                    <div *ngIf="GetFormControls.cMsg.errors.minlength">Minimum 20 characters allowed</div>
                  </div>
                </div>
                 <div class="input-group " style="margin-top: 15px;justify-content: center;align-items: center;">
                  <re-captcha formControlName="recaptchaReactive" class="gc-reset" (resolved)="resolved($event)">
                  </re-captcha>
                </div> 
                <div class="text-center" style="margin-top: 10px;">
                  <input type="submit" style="width: 40%; height: 50px; border-radius: 10px; display: inline-block;"
                    type="submit" (click)="SubmitForm()" class="btn btn-success SignInBtn float-left" value="Submit"
                    class="btn MoodysSubmitButton">
                  <input style="width: 40%; height: 50px; border-radius: 10px; display: inline-block;"
                    routerLink="/login" class="btn btn-secondary float-right" value="Back">
                </div>
              </form>
              <div style="padding-top: 40px;width: 100%;" class="row">
                <div class="col MoodyTittleColor2" style="text-align:center;">
                  <h6 style="font-size: xx-small">© {{Year | date: "y"}} Moody's Analytics, Inc. and/or its licensors
                    and affiliates (collectively, "Moody's"). All rights reserved.
                    Reserved.</h6>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
