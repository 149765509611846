<hr style="margin-top: 0px !important;" />
<section class="content">
  <div class="container-fluid">
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor" >Dashboard</h3>

        <div class="card-tools" style="margin-left: 20px;">

          <!-- <div class="input-group input-group-sm" style="width: 150px;">

            <h3 class="card-title" style="font-size: medium;margin-top: 5px ;margin-right: 5px;">Client</h3>

            <select (change)="getdashboard($event.target.value)" class="form-control">
              <option *ngFor='let item of clientList' [value]="item.clientid">{{item.clientname}}</option>

            </select>

          </div> -->
        </div>
      </div>


    </div>


  </div>
</section>
<section class="content">
  <div class="container-fluid">
    <app-card-header [locationProcessed]="locationProcessed" [noOfActiveUser]="noOfActiveUser"
    [contractEndDate]="contractEndDate"  [accountProcessed]="accountProcessed"></app-card-header>
    <div class="row">
      <div class="col-md-8">
        <app-account-planned *ngIf="isData" [hasUploadAccess]="hasUploadAccess" [accountPlanned]="uploadData"></app-account-planned>
        <div class="row">
          <div class="col">
            <app-recently-added-queries *ngIf="View_Message_Center" [RaiseReply_Query]="RaiseReply_Query" [messageContent]="messageContent"></app-recently-added-queries>
          </div>
         

        </div>
     
      </div>
      <div class="col-md-4">
        <app-total-account-status [totalInventory]="totalInventory" [accountInProgress]="accountInProgress"
        [cancelledAccount]="cancelledAccount"  [downloadedAccount]="downloadedAccount" [shippedAccount]="shippedAccount" [accountInQuery]="accountInQuery">
        </app-total-account-status>
     
      </div>
      


    </div>

  </div>
</section>
<div class="col">
  <app-latest-users [View_User]="View_User" [username]='userName'></app-latest-users>
</div>