import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ViewProcessTrackingComponent} from './view-process-tracking/view-process-tracking.component';
import { SsAccountTrackerComponent } from './ss-account-tracker/ss-account-tracker.component';

const routes: Routes =  [
{
  path: '',
  component: ViewProcessTrackingComponent

},
{
  path: 'process',
  component: ViewProcessTrackingComponent
},
{
  path: 'processtracker',
  component: SsAccountTrackerComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProcessTrackingRoutingModule { }
