import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from "rxjs/operators";
import { CookieService } from 'ngx-cookie-service'
import { BehaviorSubject } from 'rxjs';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';


@Injectable({
  providedIn: 'root'
})
export class UserSignService {
  userDetails = new BehaviorSubject<any>([]);
  private emailId = new BehaviorSubject("");
  tokenforFirstTimeChangePassword=new BehaviorSubject<any>("");
  emailId$ = this.emailId.asObservable();

  get userData() {
    // alert(1);
    return this.userDetails.asObservable();
  }
  get token(){
    return this.tokenforFirstTimeChangePassword.asObservable();
  }
  localvar: any;
  constructor(private http: HttpClient, private cookieService: CookieService) { }


  SetToken1(url: string, postData: any) {

    let formdata: FormData = new FormData();

    this.cookieService.delete('ApiQuote');
    this.cookieService.delete('Refresh');
    // formdata.append('UserMailId', 'Kshitij');
    if (postData != "" && postData !== undefined && postData != null) {
      for (var property in postData) {
        if (postData.hasOwnProperty(property)) {
          formdata.append(property, postData[property]);
        }
      }
    }

    return this.http.post(url, formdata).pipe(map(
      res => {
        let data = res;
        let token: string;
        let refreshToken: string;
        let userId: string;
        let userName: string;
        //console.log(data);
        token = String(data['validationToken'][0]['token']);
        refreshToken = String(data['validationToken'][0]['refreshToken']);
        userId = String(data['userDetails'][0]['userID']);
        userName = String(data['userDetails'][0]['userName']);
        // this.localvar = data['userDetails'];
        this.userDetails.next(data);
        // alert(2);
        if (token.length > 0) {

          // this.cookieService.set('ApiQuote', token, null, null, null, true, "None");
          //   this.cookieService.set('FTLI', String(data['userDetails'][0]['isFirstTime']), null, null, null, true, "None");
          //   this.cookieService.set('Refresh', refreshToken, null, null, null, true, "None");
          //   this.cookieService.set('UserName', userName, null, null, null, true, "None");         
          //   return data;
          if (!data['userDetails'][0]['isFirstTime']) {
            this.cookieService.set('ApiQuote', token, null, null, null, true, "None");
            this.cookieService.set('FTLI', String(data['userDetails'][0]['isFirstTime']), null, null, null, true, "None");
            this.cookieService.set('Refresh', refreshToken, null, null, null, true, "None");
            this.cookieService.set('UserName', userName, null, null, null, true, "None");
            return data;
          } else {
            this.tokenforFirstTimeChangePassword.next(token);
            return data;
          }
        }

      },
      err => {
        let data = err.json()
        console.error(err)
        return data;
      }
    ));
  }
  SendEmailId(emailId) {
    this.emailId.next(emailId);
  }

  CheckUnderMaitenance(){
   return this.http.post(AppSettings.API_ENDPOINT+AppRelativeUrl.CHECKUNDERMAINTENANCE,'').pipe(map(res=>{
      return res;
    }))
  }

}
