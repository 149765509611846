<div class="card">
  <div class="card-header">
    <h3 class="card-title MoodysTittleColor" >Recently Modified Queries</h3>

    <div class="card-tools">
      <button type="button" class="btn btn-tool" data-card-widget="collapse">
        <i class="fas fa-minus"></i>
      </button>
      <button type="button" class="btn btn-tool" data-card-widget="remove">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <!-- /.card-header -->
  <div class="card-body p-0">
    <ul class="products-list product-list-in-card pl-2 pr-2">
      <li class="item" *ngFor="let items of messageContent;let i=index ">
        <!-- <div class="product-img">
        <img src="dist/img/default-150x150.png" alt="Product Image" class="img-size-50">
      </div>  -->
        <span class="float-left" *ngIf="items['status']=='Open';else badge"
          class="badge badge-danger ">{{items['status']}}</span>
        <ng-template #badge class="float-left">
          <span class="badge badge-success float-left">{{items['status']}}</span>
        </ng-template>
        <div class="product-info" (click)="RedirectToMesageCenter(items)">

          <a href="javascript:void(0)" class="product-title">{{items['account Name']}}

            <span class="badge badge-info float-right">{{items['user Name']}}</span></a>
          <span class="product-description" [innerHTML]="items['messagetext']">
          </span>
        </div>
      </li>

    </ul>
  </div>
  <!-- /.card-body -->
  <div class="card-footer text-center">
    <a href="javascript:void(0)" *ngIf="RaiseReply_Query" routerLink="/messagecenter"
      class="btn btn-sm MoodysSubmitButton float-left" (click)="RaiseQuery()">Raise new Query</a>
    <a href="javascript:void(0)" routerLink="/messagecenter" class="btn btn-sm btn-secondary float-right">View All
      Queries</a>
  </div>
  <!-- /.card-footer -->
</div>