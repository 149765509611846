<div class="container">
  <form [formGroup]="clientManagementForm" (ngSubmit)="onSubmit()">
    <div class="row" style="border:solid rgba(204, 203, 206, 0.473); border-radius: 5px;">
      <!-- <div class="container-fluid"> -->
      <div class="AddEditFromDiv row">
        <label class="col-sm-4">Client Name<label class='required'>*</label></label>
        <div class="col-sm-8">
          <div class="col" style="height:auto;">
            <input id="ClientName" [readonly]="!checkAuthorizedforedit" style="width: 100%; height: 100%;"
              name="ClientName" type="text" required="required" formControlName="clientName"
              [ngClass]="{ 'is-invalid': submitted && f.clientName.errors }" placeholder="Client Name" />
          </div>

          <div *ngIf="submitted && f.clientName.errors" class="col">
            <label *ngIf="f.clientName.errors.required" style="color: red;">Name is required</label>
          </div>
        </div>
      </div>
      <div class="AddEditFromDiv row">
        <label class="col-sm-4">Client Type<label class='required'>*</label></label>
        <div class="col-sm-8">
          <div class="col">
            <ng-select formControlName="clientType" [readonly]="!checkAuthorizedforedit" placeholder="IS LMDC"
              [clearable]="false" style="width: 100%; height: 100%;"
              [ngClass]="{ 'is-invalid': submitted && f.clientType.errors }" (change)="OnClientTypeChange($event)">
              <ng-option *ngFor="let item of clientTypeList" [value]="item.businessTypeID">
                {{ item.businessTypeDescription }}
              </ng-option>
            </ng-select>
          </div>
          <div *ngIf="submitted && f.clientType.errors" class="col">
            <label *ngIf="f.clientType.errors.required" style="color: red;">LMDC is required</label>
          </div>
        </div>
      </div>
      <div class="AddEditFromDiv row">
        <label for="Syndicate" class="col-sm-4 col-form-label">Syndicate<label class='required'>*</label></label>
        <div class="col-sm-8">
          <div class="col"  style="height: 30px;">
            <input id="Syndicate" [readonly]="!checkAuthorizedforedit" name="Syndicate" type="text"
              style="width: 100%; height: 100%;" required="required" formControlName="syndicate"
              [ngClass]="{ 'is-invalid': submitted && f.syndicate.errors }" placeholder="Syndicate" />
          </div>
          <div *ngIf="submitted && f.syndicate.errors" class="col">
            <label *ngIf="f.syndicate.errors.required" style="color: red;">
              syndicate is required
            </label>
            <label *ngIf="f.syndicate.errors.maxlength" style="color: red;">
              Max Length Excedded
            </label>
          </div>
        </div>
      </div>
      <div class="AddEditFromDiv row">
        <label for="AccountManager" class="col-sm-4 col-form-label">Account Manager<label
            class='required'>*</label></label>
        <div class="col-sm-8">
          <div class="col">
            <ng-select formControlName="accountManager" [readonly]="!checkAuthorizedforedit" [clearable]="false"
              placeholder="Account Manager" style="width: 100%; height: 100%;" (change)="OnAccountManagerChange($event)"
              [ngClass]="{ 'is-invalid': submitted && f.accountManager.errors }">
              <ng-option *ngFor="let item of accountManagerList" [value]="item.userID">
                {{ item.userName }}
              </ng-option>
            </ng-select>
          </div>
          <div *ngIf="submitted && f.accountManager.errors" class="col">
            <label *ngIf="f.accountManager.errors.required" style="color: red;">Account manager is required</label>
          </div>
        </div>
      </div>
      <div class="AddEditFromDiv row" style="margin-bottom: 10px;">
        <label for="business type" class="col-sm-4 col-form-label">Location<label class='required'>*</label></label>
        <div class="col-sm-8">
          <div class="col">
            <ng-select formControlName="location" [readonly]="!checkAuthorizedforedit" placeholder="Location"
              [multiple]="true" (change)="OnLocationChange($event)"
              [ngClass]="{'is-invalid': submitted && f.location.errors }">
              <ng-option *ngFor="let item of locationList" [value]="item.city">
                {{ item.city }}
              </ng-option>
            </ng-select>
          </div>
          <div *ngIf="submitted && f.location.errors" class="col">
            <label *ngIf="f.location.errors.required" style="color: red;">Location is required</label>
          </div>
        </div>
      </div>
      <div class="AddEditFromDiv row" style="margin-bottom: 10px;">
        <label for="business type" class="col-sm-4 col-form-label">Applications<label class='required'>*</label></label>
        <div class="col-sm-8">
          <div class="col">
            <ng-select formControlName="applications" [readonly]="!checkAuthorizedforedit" placeholder="Application"
              [multiple]="true" (change)="OnApplicationChange($event)"
              [ngClass]="{'is-invalid': submitted && f.applications.errors }">
              <ng-option *ngFor="let item of applicationList" [value]="item.id">
                {{ item.applicationName }}
              </ng-option>
            </ng-select>
          </div>
          <div *ngIf="submitted && f.applications.errors" class="col">
            <label *ngIf="f.applications.errors.required" style="color: red;">Application is required</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row"
      style="border:solid rgba(204, 203, 206, 0.473); border-radius: 5px;margin-top: 5px; margin-bottom: 10px;">
      <div class="container">
        <label class="MoodysTittleColor">Map Account type & Business Unit</label>

      </div>
      <div class="AddEditFromDiv row" *ngIf="checkAuthorizedforedit">
        <div class="col-sm-4 col-form-label">
          <label>Account Type</label>
        </div>
        <div class="col-sm-8">
          <div class="col">
            <ng-select id="accountType" [readonly]="!checkAuthorizedforedit" formControlName="accountType"
              placeholder="Account Type" [multiple]="true" style="width: 100%; height: 100%;"
              (change)="OnAccountTypeChange($event)">
              <ng-option *ngFor="let item of accounttypeList" [value]="item">
                {{ item.accountTypeName }}
              </ng-option>
            </ng-select>
          </div>
          <!-- <div *ngIf="submitted && f.accountType.errors" class="col">
            <label *ngIf="f.accountType.errors.required" style="color: red;">Account type is required</label>
          </div> -->
        </div>
      </div>
      <div class="AddEditFromDiv row"  *ngIf="checkAuthorizedforedit">
        <label class="col-sm-4 col-form-label">Business Unit</label>
        <div class="col-sm-8">
          <div class="col">
            <ng-select id="businessType" [readonly]="!checkAuthorizedforedit" formControlName="businessType"
              [multiple]="true" style="width: 100%; height: 100%;" placeholder="Business Type"
              (change)="OnBusinessUnitChange($event)" [ngClass]="{ 'is-invalid': submitted && f.businessType.errors }">
              <ng-option *ngFor="let item of BUList" [value]="item">
                {{ item.businessUnitDescription }}
              </ng-option>
            </ng-select>
          </div>
          <!-- <div *ngIf="submitted && f.businessType.errors" class="col">
            <label *ngIf="f.businessType.errors.required" style="color: red;">Bussiness type is required</label>
          </div> -->
        </div>
      </div>
      <div class="AddEditFromDiv row"  *ngIf="checkAuthorizedforedit">
        <label class="col-sm-4 col-form-label">Work Type</label>
        <div class="col-sm-8">
          <div class="col">
            <ng-select id="workType" formControlName="workType" [readonly]="!checkAuthorizedforedit" [multiple]="false"
              placeholder="Work Type" style="width: 100%; height: 100%;" (change)="OnWorkTypeChange($event)"
              [ngClass]="{ 'is-invalid': submitted && f.businessType.errors }">
              <ng-option *ngFor="let item of workTypeList" [value]="item">
                {{ item.workName }}
              </ng-option>
            </ng-select>
          </div>
          <!-- <div *ngIf="submitted && f.workType.errors" class="col">
            <label *ngIf="f.workType.errors.required" style="color:red">Work type is required</label>
          </div> -->
        </div>
      </div>

      <div class="container" style="margin-top: 10px;" >
        <div style="float: ledt;margin-bottom: 5px;margin-left: 10px;" *ngIf="checkAuthorizedforedit">
          <button name="Add" type="button" class="btn RMSSubmitButton"
            (click)="AddAccountBUMapping()">Add</button>
          <label id="lblAccountBUmapError" style="margin-left: 20px;color: red;"></label>

        </div>
        <div class="table-responsive text-nowrap">
          <table id="tblAccountBUMap" style="width: 100%;margin-bottom: 5px;"
            class="table-hover table-bordered font14 noPad">
            <thead>
              <tr class="th-sm RMSTableHeader">
                <th class="text-center" style="width: 141px;">Actions</th>
                <th style="width: 200px;">Account Type</th>
                <th style="width: 200px;">Work Type</th>
                <th>Business Unit</th>
              </tr>
            </thead>
            <tbody class="RMSTableContent">
              <tr class="RmsTableRow" *ngFor="let item of tblAccountBUMappingData; let i = index">
                <td class="text-center icon">
                  <button class="btn btn-tool" [disabled]="!checkAuthorizedforedit" type="button">
                    <i class="fa fa-edit MoodysEditButton" (click)="EditAccountBUMapping(item)" ></i>
                  </button>
                  <button class="btn btn-icon-toggle" [disabled]="!checkAuthorizedforedit" type="button">
                    <i class="fa fa-trash" (click)="DeleteAccountBUMapping(item)" style="color:rgb(196, 32, 50)"></i>
                  </button>
                </td>
                <td>{{item.AccountType}}</td>
                <td>{{item.WorkType}}</td>
                <td>{{item.BusinessUnit}}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>


    </div>


    <div class="col-8">
      <button name="submit" *ngIf="checkAuthorizedforedit" type="submit" class="btn btn-primary RMSSubmitButton">
        {{btnSubmitTxt}}
      </button>
      <button name="submit" type="submit" class="btn btn-primary RMSButtonCancel" (click)="close()">
        Cancel
      </button>
    </div>
    <!-- <div class="container">
      <div class="col-6">
        <div class="form-group row">
          <label for="ClientName" class="col-4 col-form-label">Client Name</label>
          <div class="col-8">
            <div class="input-group">
              <input id="ClientName" name="ClientName" type="text" class="form-control" required="required"
                formControlName="clientName" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.clientName.errors }" placeholder="Client Name" />
            </div>

            <div *ngIf="submitted && f.clientName.errors" class="invalid-feedback">
              <div *ngIf="f.clientName.errors.required">Name is required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="Syndicate" class="col-4 col-form-label">Syndicate</label>
          <div class="col-8">
            <input id="Syndicate" name="Syndicate" type="text" class="form-control" required="required"
              formControlName="syndicate" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.syndicate.errors }" placeholder="Syndicate" />
            <div *ngIf="submitted && f.syndicate.errors" class="invalid-feedback">
              <div *ngIf="f.syndicate.errors.required">
                syndicate is required
              </div>
              <div *ngIf="f.syndicate.errors.maxlength">
                Max Length Excedded
              </div>
            </div>
          </div>
        </div>
       

        <div class="form-group row">
          <label for="business type" class="col-4 col-form-label">Location</label>
          <div class="col-8">
            <ng-select formControlName="location" placeholder="Location" [multiple]="true"
              [(ngModel)]="selectedLocation" [ngClass]="{ 'is-invalid': submitted && f.location.errors }">
              <ng-option *ngFor="let item of locationList" [value]="item.id">
                {{ item.city }}
              </ng-option>
            </ng-select>
            <div *ngIf="submitted && f.location.errors" class="invalid-feedback">
              <div *ngIf="f.location.errors.required">required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group row">
          <label class="col-4">Client Type</label>
          <div class="col-8">
            <ng-select formControlName="clientType" required placeholder="IS LMDC" [(ngModel)]="clientT"
              [ngClass]="{ 'is-invalid': submitted && f.clientType.errors }">
              <ng-option *ngFor="let item of clientTypeList" [value]="item.businessTypeID">
                {{ item.businessTypeDescription }}
              </ng-option>
            </ng-select>
            <div *ngIf="submitted && f.clientType.errors" class="invalid-feedback">
              <div *ngIf="f.clientType.errors.required">required</div>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="AccountManager" class="col-4 col-form-label">Account Manager</label>
          <div class="col-8">
            <ng-select formControlName="accountManager" placeholder="Account Manager"
              [(ngModel)]="selectedAccountManager" [ngClass]="{ 'is-invalid': submitted && f.accountManager.errors }">
              <ng-option *ngFor="let item of accountManagerList" [value]="item.userID">
                {{ item.userName }}
              </ng-option>
            </ng-select>
            <div *ngIf="submitted && f.accountManager.errors" class="invalid-feedback">
              <div *ngIf="f.accountManager.errors.required">required</div>
            </div>
          </div>
        </div>

      </div>
      <div class="container">

        <div class="form-group row">
          <div class="col-sm-2 col-form-label">
            <label>Account Type</label>
          </div>
          <div class="col-sm-4">
            <ng-select formControlName="accountType" placeholder="Account Type"
              [ngClass]="{ 'is-invalid': submitted && f.accountType.errors }">
              <ng-option *ngFor="let item of accounttypeList" [value]="item.accountTypeID">
                {{ item.accountTypeName }}
              </ng-option>
            </ng-select>
            <div *ngIf="submitted && f.accountType.errors" class="invalid-feedback">
              <div *ngIf="f.accountType.errors.required">required</div>
            </div>
          </div>
          <label class="col-sm-2 col-form-label">Business Type</label>
          <div class="col-sm-4">
            <ng-select formControlName="businessType" [multiple]="true" placeholder="Business Type"
              [ngClass]="{ 'is-invalid': submitted && f.businessType.errors }">
              <ng-option *ngFor="let item of BUList" [value]="item.businessUnitID">
                {{ item.businessUnitDescription }}
              </ng-option>
            </ng-select>
            <div *ngIf="submitted && f.businessType.errors" class="invalid-feedback">
              <div *ngIf="f.businessType.errors.required">required</div>
            </div>
          </div>
        </div>
        
        <div class="form-group row">
          <label class="col-2 col-form-label">Work Type</label>
          <div class="col-4">
            <ng-select formControlName="workType" placeholder="Work Type"
              [ngClass]="{ 'is-invalid': submitted && f.businessType.errors }">
              <ng-option *ngFor="let item of workTypeList" [value]="item.workId">
                {{ item.workName }}
              </ng-option>
            </ng-select>
            <div *ngIf="submitted && f.workType.errors" class="invalid-feedback">
              <div *ngIf="f.workType.errors.required">required</div>
            </div>
          </div>
          <button name="Add" class="btn btn-primary RMSSubmitButton">
            Add
          </button>
        </div>

      </div>
      <div class="col-8">
        <button name="submit" type="submit" class="btn btn-primary RMSSubmitButton">
          Submit
        </button>
        <button name="submit" type="submit" class="btn btn-primary RMSButtonCancel" (click)="close()">
          Cancel
        </button>
      </div>
    </div> -->
  </form>
</div>