<app-projectManager-dashboard *ngIf="isProjectManager" [clientList]="clientList" [hasUploadAccess]="hasUploadAccess"
  [RaiseReply_Query]="RaiseReply_Query" [View_Message_Center]="View_Message_Center"></app-projectManager-dashboard>
<app-admin-dashboard *ngIf="isAdmin"></app-admin-dashboard>
<app-client-dashboard *ngIf="isClientManager" [clientList]="clientList" [hasUploadAccess]="hasUploadAccess"
  [View_User]="View_User" [RaiseReply_Query]="RaiseReply_Query" [View_Message_Center]="View_Message_Center">
</app-client-dashboard>
<app-accountManager-dashboard *ngIf="isAccountManager" ></app-accountManager-dashboard>
<app-client-lead-dashboard *ngIf="View_Client_lead_dashboard" [clientList]="clientList" [hasUploadAccess]="hasUploadAccess"
[View_User]="View_User" [RaiseReply_Query]="RaiseReply_Query" [View_Message_Center]="View_Message_Center"></app-client-lead-dashboard>
<hr>
<div class="text-danger text-center">
  <p><strong>** Please note - above DS Portal snapshot is on the basis of accounts uploaded on Portal **</strong></p>
</div>
<hr>