import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// import { LoginUserService } from '../../../services/LoginServices/login-user.service'
import { Router } from '@angular/router'
import { UserSignService } from '../../../services/LoginServices/user-sign.service';
import { AppRelativeUrl } from '../../../common/AppRelativeUrl';
import { AppSettings } from '../../../common/AppSettings';
import { CookieService } from 'ngx-cookie-service'
import { Authenticateuser } from 'src/common/Authenticateuser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  capsOn;
  title = 'RMS DSPortal Login';
  registerForm: UntypedFormGroup;
  submitted: boolean = false;
  showLoginError: boolean = false;
  loginError: string = "";
  showUserNameError = false;
  showPassWordError = false;
  rediretochangepassword: boolean;
  timeZone: any;
  showPasteError: boolean = false;
  Year: string = Date();
  checkedRemember: boolean = false;
  isUndermaintenance: boolean ;
  private UserSignIn: UserSignService
  // private _loginUserService: LoginUserService;
  constructor(private formBuilder: UntypedFormBuilder, UserSignIn: UserSignService, private router: Router, private cookieService: CookieService) {
    // this._loginUserService = loginUserService;
    this.UserSignIn = UserSignIn;
  

  }

  ngOnInit() {
    // alert('Login Component');
    
    this.UserSignIn.CheckUnderMaitenance().subscribe(res => {
      if (res) {
        this.isUndermaintenance = true;
        this.cookieService.delete('ApiQuote');
        this.cookieService.delete('Refresh');
        this.cookieService.delete('UserName');
        this.router.navigate(['maintenance']);
      }
    }, error => { console.log(error); })
     let emailPattern = '^[A-Za-z0-9.\'_%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,10}$';
    
    // let emailPattern = "\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*";
    this.registerForm = this.formBuilder.group({
      UserName: ['', Validators.compose([Validators.required, Validators.pattern(emailPattern)])],
      Password: ['', Validators.required]
    });

    let token = this.cookieService.get('ApiQuote');
    if (token != null && token != '')
      
      this.router.navigate(['home']);

    if (this.cookieService.get("LoginId") != undefined && this.cookieService.get("LoginId") != null && this.cookieService.get("LoginId") != "") {
      this.registerForm.patchValue({
        UserName: this.cookieService.get("LoginId")
      })
      this.checkedRemember = true;
    }
  }

  ngAfterViewInit() {
    let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
    if (invalidFields.length > 0) {
      invalidFields[1].focus();
      this.showUserNameError = false;
      this.showPassWordError = false;
      this.showPasteError = false;
      // for (let i = 0; i < invalidFields.length; i++) {
      //   invalidFields[i].style.borderColor = "green !important";
      //   // invalidFields[i].classList.remove('ng-invalid');
      // }

      invalidFields.className = '';
    }
  }


  get f() {
    // this.showValidationError = true;
    return this.registerForm.controls;
  }


  ShowUserNameError() {
    this.showUserNameError = true;
  }
  ShowPasswordError() {
    this.showPassWordError = true;
    this.showPasteError = false;
  }

  SignIn() {

    if (this.registerForm.invalid) {
      this.showUserNameError = true;
      this.showPassWordError = true;
      let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
      invalidFields[1].focus();
      return;
    }

    this.showLoginError = false;
    // 
    //// call by obseravble 
    this.UserSignIn.SetToken1(AppSettings.API_ENDPOINT + AppRelativeUrl.LOGIN, this.registerForm.value).subscribe(result => {

      this.showLoginError = false;
      this.showPasteError = false;
      let isFirstTime: boolean;
      isFirstTime = Boolean(result['userDetails'][0]['isFirstTime']);
      if (result['userDetails'][0]['disTimeZoneName'] == null || result['userDetails'][0]['disTimeZoneName'] == undefined) {
        Authenticateuser.timeZone = "GMT"
      }
      else {
        Authenticateuser.timeZone = result['userDetails'][0]['disTimeZoneName'].slice(1, 10);
      }
      if (this.checkedRemember) {
        this.cookieService.set("LoginId", result['userDetails'][0]['emailID'], 365, '', '', true, 'Strict');
      } else {
        this.cookieService.delete("LoginId");
      }

      if (isFirstTime) {
        // this.router.navigate(['changepassword']);
        this.UserSignIn.SendEmailId(this.registerForm.value.UserName)
        this.router.navigate(['changepasswordfirst']);
      }
      else {
          this.router.navigate(['home']);
        }
    },
      error => {


        this.registerForm.controls.Password.setValue('');

        this.showLoginError = true;
        if (error.status == 400) {
          this.loginError = String(error.error.detail);
          // this.loginError = error.error.errors[0].title; 
        }
        else if (error.status == 401) {
          // now throwing error we get from api earlier it was showing hard coded error
          this.loginError = String(error.error.ErrorMessage);
          // this.loginError = 'Unauthorized Access, please try to restart your browser and try again.';
          // this.loginError = error.error.errors[0].title; 
        }
        else if (error.status == 0)
          this.loginError = 'Could not connect to the service.';
        else
          this.loginError = error.error;
      });
  }
  onpaste(event: any) {
    this.showPasteError = true;
    return false
  }
  CheckedRememberMe() {
    this.checkedRemember = !this.checkedRemember;
  }

}
