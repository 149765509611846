import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { CookieService } from 'ngx-cookie-service'
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  DeleteToken(url: string) {
    return this.http.post(url, null, {
      headers: {
        'token': this.cookieService.get('Refresh')//,
        //'userId':this.cookieService.get('UserId')
      },
      observe: 'response'
    }).pipe(
      map(
        res => {
          console.log(res);
          return res;
        },
        err => {
          let er = err.json();
          return er
        }
      )
    )
  }

  PostRequest(Path: string, FormVal: any) {
    return this.http.post(Path, FormVal).pipe(map(response => {
      let result = response;
      return result;
    },
      error => {
        let err = error.json();
        return err;
      }))
  }
}
