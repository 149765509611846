import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EmailService } from './../../../services/LoginServices/email.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  capsOn;
  guid = '';
  flag: boolean;
  resetPasswordtemplate: boolean;
  expiretemplate: boolean;
  notification: boolean;
  errorMessage: string;
  forgotPasswordLink = false;
  loginLink = false;
  ShowUserNameErrors: boolean;
  ShowUserPasswordErrors: boolean;
  ShowConfirmPasswordErrors: boolean;
  ifError = false;
  Year: string=Date();

  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private EService: EmailService) {
    this.guid = this.route.snapshot.queryParamMap.get('guid');
    if (this.guid !== null) {
      // this.EService.linkexpire(this.guid)
      // let response  = this.EService.linkexpire(this.guid);

      //// calling the api
      this.EService.linkexpire(this.guid).subscribe(
        res => {
          let resB: boolean = Boolean(res);
          if (resB) {
            this.ifError = true;
            this.resetPasswordtemplate = true;
            // this.expiretemplate = false;
          } else {
            this.resetPasswordtemplate = false;
            this.loginLink = true;
          }
        }, err => {
          this.ifError = true;

          // console.log(err.status);
          switch (err.status) {
            case 400:
              this.errorMessage = err.error;
              this.notification = true;
              this.forgotPasswordLink = true;
              break;
            case 401:
              this.errorMessage = err.error;
              this.notification = true;
              break;
            case 0:
              this.errorMessage = 'Could not connect to the  server ';
              this.notification = true;

              this.loginLink = true;

              break;
            case 410:
              this.errorMessage = err.error;
              this.forgotPasswordLink = true;
              this.notification = true;
              break;

          }
        }
      )
    }
  }

  Userid = '';

  formdata: UntypedFormGroup;
  ngOnInit() {
    this.formdata = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(16)
        , Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$!@#$%()_+^&}{:;?.\"])[A-Za-z\\d$!@#$%()_+^&}{:;?.\"]{10,16}$")]],
      confirmPassword: ['', Validators.required],
      UserName: ['', [Validators.required,Validators.email,Validators.pattern('^[A-Za-z0-9.\'_%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,10}$')]]
    }
      ,
      {
        validators: MustMatch('password', 'confirmPassword')
      });
  }

  get f() {
    return this.formdata.controls;
  }

  ShowUserNameError() {
    this.ShowUserNameErrors = true;
  }

  ShowUserPasswordError() {
    this.ShowUserPasswordErrors = true;
  }

  ShowConfirmPasswordError() {
    this.ShowConfirmPasswordErrors = true;
  }

  onSubmit(UserId: string, Password: string) {
    this.ShowConfirmPasswordErrors = true;
    this.ShowUserPasswordErrors = true;
    this.ShowUserNameErrors = true;
    // stop here if form is invalid
    if (this.formdata.invalid) {
      return;
    }
    this.EService.UpdatePassword(UserId, Password, this.guid).subscribe(
      res => {
        // console.log(res);
        let resB: boolean = Boolean(res);
        if (resB) {
          this.ifError = false;
          this.notification = true;
          this.errorMessage = "Password has been reset successfully!  ";
          this.loginLink = true;
          this.resetPasswordtemplate = false;
        }
        else {
          this.notification = false;

        }
      }, err => {
        // console.log(err.status);
        this.ifError = true;
        this.formdata.reset();

        switch (err.status) {
          case 400:
            this.errorMessage = err.error;
            this.notification = true;
            this.forgotPasswordLink = true;
            break;
          case 400:
            this.errorMessage = "could";
            this.notification = true;
            break;
          case 401:
            this.errorMessage = err.error;
            this.notification = true;
            break;
          case 0:
            this.errorMessage = err.error;
            this.notification = true;
            break;
          case 500:
            this.errorMessage = err.error;
            this.notification = true;
            break;

        }

      }
    )
  }
}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

