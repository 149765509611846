import { HttpEvent, HttpHandler, HttpResponse, HttpInterceptor, HttpRequest, HttpClient, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../../common/AppSettings';
import { CookieService } from 'ngx-cookie-service';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { tap, switchMap, catchError, map } from 'rxjs/operators';
import { Observable, throwError, Subject, pipe } from 'rxjs';
import { UserSignService } from '../../services/LoginServices/user-sign.service';
import { Router } from '@angular/router';
import { LoaderService } from '../../services/Common/loader.service';
import { DataShareService } from 'src/services/Common/dataShare.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService, private router: Router
    , private UserSignIn: UserSignService, public http: HttpClient
    , private loaderService: LoaderService
    , private ShareData: DataShareService
  ) { }
  refreshingAccessToken: boolean;
  accessTokenRefreshed: Subject<any> = new Subject();
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    /// show the loader
    this.showLoader();
    // Handle the request
    request = this.addAuthHeader(request);

    /// hide previous error
    let divsToHide = document.getElementsByClassName("alertContainer") as HTMLCollectionOf<HTMLElement>; //divsToHide is an array
    for (let i = 0; i < divsToHide.length; i++) {
      divsToHide[i].style.display = "none";
    }


    if (request == null) {
      this.ShareData.IfSideBarOpen(false);
      this.cookieService.delete('ApiQuote');
      this.cookieService.delete('Refresh');
      this.cookieService.delete('UserName');
      this.cookieService.delete('FTLI');
      this.router.navigate(['logout']);
      this.hideLoader();
      return;
    }



    // call next() and handle the response
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {

        if (event instanceof HttpResponse || event.type == HttpEventType.UploadProgress || event.type == HttpEventType.DownloadProgress) {
          this.hideLoader();
        }
      },
        (err: any) => {
          this.hideLoader();
          //  return this.handleResponseError(err, request, next);
        }),

      catchError((error: HttpErrorResponse) => {

        let refreshToken = this.cookieService.get('Refresh');
        if (error.status === 401 && refreshToken != null && refreshToken != '' && this.refreshAccessToken != undefined) {
          // 401 error so we are unauthorized
          // refresh the access token          
          return this.refreshAccessToken()
            .pipe(switchMap(() => {
              request = this.addAuthHeader(request);
              // hide the loader
              this.hideLoader();
              return next.handle(request);

            }),
              catchError((err: any) => {
                this.ShareData.IfSideBarOpen(false);
                this.cookieService.delete('ApiQuote');
                this.cookieService.delete('Refresh');
                this.cookieService.delete('UserName');
                this.cookieService.delete('FTLI');
                // this.router.navigate(['/login']);
                // this.router.navigate(['logout']);
                this.router.navigate(['session-expire'])
                this.hideLoader();
                return '';
              })
            )
        } else if (error.status === 401 && (refreshToken == null || refreshToken == '' || this.refreshAccessToken == undefined)) {


        }

        this.hideLoader();
        return throwError(error);
      }),
      // tap(evt => {this.hideLoader();})
    )
  }


  refreshAccessToken() {
    if (this.refreshingAccessToken) {
      return new Observable(observer => {
        this.accessTokenRefreshed.subscribe(() => {
          // this code will run when the access token has been refreshed
          observer.next();
          observer.complete();
        })
      })
    } else {
      this.refreshingAccessToken = true;
      // we want to call a method in the auth service to send a request to refresh the access token
      return this.getNewAccessToken().pipe(
        tap(() => {

          this.refreshingAccessToken = false;
          this.accessTokenRefreshed.next;
          this.hideLoader();
        })
      )
    }

  }

  addAuthHeader(request: HttpRequest<any>) {
    // get the access token
    const token = this.cookieService.get('ApiQuote');
    const loginReq = "login/";


    if (request.url.search('SubmitFeedback') != -1 || request.url.search('login/Logout') != -1) {
      this.ShareData.IfSideBarOpen(false);
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
        , withCredentials: true
      })
    }
    else if (request.url.toLowerCase().search(loginReq) != -1) {
      this.ShareData.IfSideBarOpen(false);
      return request;
    } else if ((token)) {
      this.ShareData.IfSideBarOpen(true);
      // append the access token to the request header
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
        , withCredentials: true
      })
    }
    else{
      this.ShareData.IfSideBarOpen(false);
      return null;
    }
  }


  getNewAccessToken() {
    return this.http.post(AppSettings.API_ENDPOINT + AppRelativeUrl.REFRESH_TOKEN, null, {
      headers: {
        'token': this.cookieService.get('Refresh')//,
        // 'userId': this.cookieService.get('UserId')
      },
      observe: 'response'
    }).pipe(
      tap((res: HttpResponse<any>) => {
        this.cookieService.set('ApiQuote', res.body[0]["token"], null, null, null, true, 'Strict');
        this.cookieService.set('Refresh', res.body[0]["refreshToken"], null, null, null, true, 'Strict');

        this.hideLoader();

      })
    )
  }

  //// added by Kshitij to check if failed requests need to retry 

  handleResponseError(error, request?, next?) {

    if (error.status === 400) {
      // Show message
    }

    // Invalid token error
    else if (error.status === 401) {

      console.log('<<<<IMP>>>> Failed request have been retried when 401, To be deleted later. ')
      return this.refreshAccessToken()
      // .pipe(
      //   switchMap(() => {
      //     request = this.addAuthHeader(request);
      //     return next.handle(request);
      //   }),
      //   catchError(e => {
      //     if (e.status !== 401) {
      //       return this.handleResponseError(e);
      //     } else {
      //       this.logOut();
      //     }
      //   }));
    }

    // Access denied error
    else if (error.status === 403) {
      // Show message
      // Logout
      this.logOut();
    }

    // Server error
    else if (error.status === 500) {
      // Show message
    }

    // Maintenance error
    else if (error.status === 503) {
      // Show message
      // Redirect to the maintenance page
    }

    return throwError(error);
  }





  logOut() {
    this.cookieService.delete('ApiQuote');
    this.cookieService.delete('Refresh');
    this.cookieService.delete('UserName');
    this.cookieService.delete('FTLI');
    this.router.navigate(['/login']);
  }

  // for shwing the loader on request

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }

}
