<hr style="margin-top: 0px!important;">
<div>
  <section class="content" style="margin-top: 15px;">
    <div class="container-fluid">
      <!-- <div class="card card-default">
        <div class="card-header row">
          <h3 class="card-title col-lg-12 " id="title" style="color:#005EFF; text-align: center;">Subscription Details</h3>
         
        </div>     
      </div>  -->

    </div>
    <div class="container-fluid">
      <div>
        <div class="row justify-content-center">
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3" style="height: 75px;">
              <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-file-contract"></i></span>

              <div class="info-box-content">
                <span class="info-box-text" style="font-size: small;">Location Subscribed</span>

                <span class="info-box-number"
                  style="font-size: small;text-align: left;padding-left: 2%;">{{no_of_loc_subscribed.toLocaleString('en-US')}}
                </span>
              </div>
              <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3">
              <span class="info-box-icon bg-primary elevation-1"><i class="fas fa-map-marker"></i></span>
              <div class="info-box-content">
                <span class="info-box-text" style="font-size: small;overflow: visible;">Locations Consumed</span>

                <span class="info-box-number"
                  style="font-size: small;text-align: left;padding-left: 2%;">{{location_processed.toLocaleString('en-US')}}</span>
              </div>
              <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box mb-3">
              <span class="info-box-icon bg-success elevation-1"><i class="fa fa-balance-scale"></i></span>

              <div class="info-box-content">
                <span class="info-box-text" style="font-size: small;">Subscription Used</span>

                <span class="info-box-number" [ngStyle]="getTextStyle()"
                  style="font-size: small;text-align: left;padding-left: 2%;">{{getFormattedNoOfLocUsed()}} %</span>
              </div>
              <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
          </div>

        </div>

      </div>
    </div>
  </section>

  <section class="content" *ngIf="canUpload">
    <div class="container-fluid">
      <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title MoodysTittleColor">Upload</h3>
          <div class="dropdown-container ">
            <!-- <button type="button" title="Download Template" class="RMSSubmitButton" style="margin-right: 20px;" (click)="DownloadTemplate()">Download  Template</button>
  -->

            <div class="row" style="gap: 20px;padding-right: 10px;">
              <div class="row" style="gap: 5px;align-items: baseline;">
                    <div style="color: gray;font-weight: bold;">Nature of Account </div>
                    <div> <ng-select id="natureOfAccount" style="width: 200px" placeholder="---Select---"
                      [items]="natureOfAccounts" bindValue="natureOfAccount" bindLabel="natureOfAccount"
                      (change)="OnNatureOfAccountChange()" [(ngModel)]="selectedNatureOfAccount">
                      <ng-template ng-option-tmp let-item="item">
                        <div class="full-width-hover">{{ item.natureOfAccount }}</div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
               <div class="row " style="align-items: baseline;">
                <div style="color: gray;font-weight: bold;">Service Type<label class='required' style="margin: 5px;">*</label></div>
                <div class="col">
                     <ng-select (change)="OnAccountTypeChange()" [(ngModel)]="selectedItem"
                    style="height: 100%;width: 200px;">
                    <ng-option *ngFor="let item of accountBuinfo" [title]="item.accounttypename" [value]="item">
                      {{item.accounttypename}}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>


            <!-- <div style="width: 20px;"></div> -->


          </div>
        </div>

        <div class="card-body">

          <!-- <label class="row Allowidefiletype"
         style="background-color:#005EFF; color: white; font-weight: bold; margin: 20px 0;">
            {{Msg}}
        </label> -->

          <form (ngSubmit)="Submit()" [formGroup]="form" class="form-row">
            <div class="form-row" [formGroup]="form" style="width:90%;white-space: pre;">
              <div class="form-row" style="width: 80%; margin-left: 5%;">
                <div appDnd class="col-12" style="margin-left:5px;text-align: center;"
                  (fileDropped)="onFileDropped($event)">
                  <div class="container">
                    <input type="file" #fileDropRef id="fileDropRef" multiple
                      (change)="fileBrowseHandler($event.target.files)" />
                    <div class="dragDrop">
                      <!-- <div class="row">
                      
                         <div class="col-lg-12" style="text-align: right;">
                          <label  style=" cursor: pointer;" (click)="DownloadTemplate()">Download Input Template </label> 
                         </div>
                      
                      </div> -->
                      <div class="row">
                        <h3 class="col-lg-12" style="text-align: center;">Drag and drop file here</h3>

                        <!-- <label class="col-2"  style="width: 30%; margin-bottom: 10%; cursor: pointer; height: 25%;border-radius: 21.5px;
                      " (click)="DownloadTemplate()">Download Input Template </label> -->


                      </div>
                      <div class="row" style="text-align: center;">
                        <h3 class="col">or</h3>
                      </div>
                      <div class="row" style="text-align: center;">
                        <div class="col"></div>
                        <label class="col MoodyBrowserFileButton" for="fileDropRef" style="width: 30%; height: 20%;border-radius: 21.5px; color: white;
                     padding: 8px 16px;">Browse for file</label>
                        <div class="col"></div>
                      </div>

                      <div class="row" style="text-align: center;">
                        <div class="col"></div>
                        <label class="col" style="width: 30%; margin-bottom: 10%; cursor: pointer; height: 25%;border-radius: 21.5px;
                      ">{{Msg}} </label>
                        <div class="col"></div>
                      </div>

                    </div>
                  </div>
                  <div class="files-list" style="max-height: 200px; scroll-behavior: auto; overflow-y: scroll;">
                    <div class="single-file" *ngFor="let file of files; let i = index">
                      <img src="assets/svg/ic-file.svg" width="45px" alt="file">
                      <div class="info">
                        <h4 class="name">
                          {{ file?.name }}
                        </h4>
                        <p class="size">
                          {{ formatBytes(file?.size) }}
                        </p>
                        <!-- <div class="progress-cont">
                  <div class="progress" [style.width]="file?.progress + '%'">
                  </div>
                </div> -->
                      </div>
                      <img src="assets/svg/ic-delete-file.svg" class="delete" width="20px" alt="file"
                        (click)="deleteFile(i)">
                    </div>
                  </div>
                </div>


                <!-- <div appDnd class="col-12"  

                  (fileDropped)="onFileDropped($event)">
                  <div class="container">
                    <input type="file" #fileDropRef id="fileDropRef" multiple
                      (change)="fileBrowseHandler($event.target.files)" />
                    <div class="dragDrop">
                      <div class="row" style="margin-left: 80px;">
                        <h6 class="col" style="text-align: center;margin-right: 70px;">Drag and drop file here</h6>
                      </div>
                      <div class="row" style="text-align: center;">
                        <h6 class="col">or</h6>
                      </div>
                      <div class="row" style="text-align: center;">
                        <div class="col"></div>
                        <label class="col MoodyBrowserFileButton" for="fileDropRef" style="width: 30%; height: 20%;border-radius: 21.5px; color: white;
                      background-color: #005EFF;">Select File </label>
                        <div class="col"></div>
                      </div>
                      <div class="row"  style="text-align: center; margin-top: 5%;">
                        <div class="col"></div>
                        <label class="col"  style="width: 30%; cursor: pointer; height: 25%;border-radius: 21.5px;
                      " (click)="DownloadTemplate()">Download Input Template </label>
                        <div class="col"></div>
                      </div>
                    </div>
                  </div>
                  <div class="files-list" style="max-height: 200px; scroll-behavior: auto; overflow-y: scroll;">
                    <div class="single-file" *ngFor="let file of files; let i = index">
                      <img src="assets/svg/ic-file.svg" width="45px" alt="file">
                      <div class="info">
                        <h4 class="name">
                          {{ file?.name }}
                        </h4>
                        <p class="size">
                          {{ formatBytes(file?.size) }}
                        </p>
                    
                      </div>
                      <img src="assets/svg/ic-delete-file.svg" class="delete" width="20px" alt="file"
                        (click)="deleteFile(i)">
                    </div>
                  </div>
                </div> -->
                <div>
                  <label style="font-weight: normal; color:red;overflow-wrap: break-word;">{{fileSizeError}}</label>
                </div>
              </div>
            </div>


            <hr>
            <div class="container-fluid" style="width: 100%;">
              <button type="submit" style="float: right; width: auto; background-color:#005EFF;" [disabled]="isDisabled"
                class="col-sm-2 btn btn-primary">Upload
                Account</button>
              <label style="font-weight: normal; cursor: pointer; float: right; margin-right: 20px; margin-top: 1%;"
                (click)="DownloadTemplate()">Download Input Template
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>


  <section class="content" *ngIf="canUpload == false">
    <div class="container-fluid">
      <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title MoodysTittleColor">Upload</h3>
        </div>
        <div class="card-body">
          <label class="row"
            style="font-weight: bold;color:white;margin-left: 10px;margin-bottom: 20px; background-color:#005EFF;">
            {{ContractExpireMessage}}</label>

        </div>
      </div>
    </div>
  </section>



  <section class="content">
    <div class="container-fluid">
      <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title" style="color: #005EFF;">Upload Queue</h3>
        </div>
        <div class="card-body" id="Progress">
          <div class="table-responsive text-nowrap">
            <table class="table-hover table-bordered font14 noPad" style="width: 100%;">
              <thead>
                <tr class="th-sm RMSTableHeader">
                  <th style="width: 10px;">Action</th>
                  <th>Account Name</th>
                  <th>Service Type</th>
                  <!-- <th>Work Type</th>
                <th>Priority</th>
                <th>Business Unit</th> -->
                  <!-- <th>No. of Locs</th> -->
                  <!-- <th>Sum Insured</th> -->
                  <!-- <th>Underwriter</th> -->
                  <th>Progress</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                <tr class="RMSTableContent" *ngFor="let item of UploadingFileProgress">
                  <ng-container>
                    <!-- {{item[key]}} -->
                    <td>
                      <div>
                        <button class="btn btn-tool" *ngIf="item.status==1">
                          <i class="fa fa-check" style="color:green;" aria-hidden="true"></i>
                        </button>
                        <button class="btn btn-tool" *ngIf="item.status==2">
                          <i class="fa fa-times" style="color:#db202f;" (click)="cancelUpload(item)"
                            aria-hidden="true"></i>
                        </button>
                        <button class="btn btn-tool" *ngIf="item.status==3" title="click here to retry upload"
                          (click)="RetryUpload(item)">
                          <i class="fa fa-exclamation-triangle" style="color:#db202f;" aria-hidden="true"></i>
                        </button>

                      </div>
                    </td>
                    <td>{{item.AccountName}}</td>
                    <td>{{item.AccountType}}</td>
                    <!-- <td>{{item.WorkType}}</td>  -->
                    <!--{{item.WorkType}} -->
                    <!-- <td>{{item.Priority}}</td>
                  <td>{{item.BusinessUnit}}</td>
                  <td>{{item.NoOfLocs}}</td> -->
                    <!-- <td>{{item.SumInsured}}</td> -->
                    <!-- <td>{{item.Underwriter}}</td> -->
                    <td>

                      <div class="row" style="margin-left: inherit;">
                        <div class="Uploadprogress-cont col-sm-8" *ngIf="item.Progress > 0">
                          <div class="UploadProgress" [style.width]="item.Progress + '%'">
                            <label style="font-weight: normal;">{{item.Progress}}%</label>
                          </div>
                        </div>
                        <!-- <div class="col-sm-2" *ngIf="item.Progress > 0">
                      <label style="font-weight: normal; color:green">{{item.Message}}</label>
                    </div> -->
                      </div>
                    </td>
                    <td>
                      <div *ngIf="item.status==3">
                        <label style="font-weight: normal; color: #db202f;">{{item.Message}}</label>
                      </div>
                      <label *ngIf="item.status==1 || item.status==2"
                        style="font-weight: normal; color:green">{{item.Message}}</label>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">
      <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title" style="color: #005EFF;">Recently Uploaded Jobs</h3>
        </div>
        <div class="card-body" id="Progress">
          <div class="table-responsive text-nowrap">
            <table id="dataTable" class="table table-hover table-bordered font14 noPad" *ngIf="data" id="tabledetail">
              <!--class="StickyHeader -->
              <thead>
                <tr>
                  <ng-container *ngFor="let items of dynFieldheader | slice:11; let i = index">
                    <th class="th-sm" style="padding-left: 32px!important; cursor: pointer;">
                      {{ items }}

                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <!-- style="display: block; max-height: 500px;overflow-y: auto; overflow-x: hidden;"-->
                <tr *ngFor="let item of data; let i = index" id="tr_{{i}}" class="RmsTableRow">

                  <td *ngFor="let column of dynFieldheader | slice:11" class="set">
                    <div
                      *ngIf="column != 'status'&& column !='upload Date' && column !='expected Shipment' && column != 'actual Shipment'
                  && column!='special Instructions'&& column!='comments' && column != 'incept Date' && column != 'expiry Date' && column != 'file Name' && column != 'original TIV' && column != 'cleaned TIV'  ">
                      <span>{{ item[column] }}</span>
                    </div>
                    <div *ngIf="column == 'status'">

                      <span Class="badge  badge-settings" [ngClass]="item['statusClass']">{{item[column] }}</span>

                    </div>
                    <div
                      *ngIf="column == 'upload Date' ||  column == 'expected Shipment' || column == 'actual Shipment' ">

                      <span>{{ item[column]|date:'dd-MMM-yyyy hh:mm a' }}</span>

                    </div>

                    <div *ngIf="column == 'expiry Date'  || column == 'incept Date' ">

                      <span>{{ item[column]|date:'dd-MMM-yyyy' }}</span>

                    </div>

                    <div title="View Instructions" *ngIf="column == 'special Instructions' " style="cursor: pointer;">

                      <span>{{ item[column]}}</span>

                    </div>
                    <div title="View Comments" *ngIf="column == 'comments' " style="cursor: pointer;">

                      <span>{{ item[column]|slice:0:20}}</span>

                    </div>
                    <div title="Full file name" *ngIf="column == 'file Name' " style="cursor: pointer;">

                      <span>{{ item[column]|slice:0:30}}<span *ngIf='item[column].length > 30'>....</span></span>

                    </div>
                    <div *ngIf="column == 'original TIV' ">
                      <span>{{ item[column]| number }}</span>
                    </div>
                    <div *ngIf="column == 'cleaned TIV'">
                      <span>{{ item[column]| number }}</span>
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>