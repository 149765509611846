declare var $;

$(function () {

});
// $('a').tooltip();
import { Component, AfterViewChecked, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MenuInfo } from '../../../model/Menu/MenuInfo';
import { GetMenuService } from '../../../services/LoginServices/get-menu.service';
import { AppRelativeUrl } from '../../../common/AppRelativeUrl';
import { AppSettings } from '../../../common/AppSettings';
import { Observable } from 'rxjs';
//import { trimTrailingNulls } from '@angular/compiler/src/render3/view/util';
import { AuthenticationService } from 'src/services/Common/authentication.service';
import { Router } from '@angular/router';
import { PowerBiService } from 'src/services/powerbi.service';
import { publicDecrypt } from 'node:crypto';
// import { SidebarPipe } from '../../../common/SidebarPipe'

// import { Authenticateuser } from 'src/common/Authenticateuser';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuDetails: MenuInfo[] = [];
  linkArray: string[] = [];
  distinctMenuIds:string[]=[];
  public showMenu: boolean = false;
  @Input() pages: any[] = [];
  // currently active item
     activeItemId: any | null = null;
  @Output() pageSelected: EventEmitter<string> = new EventEmitter<string>();
  reportData:any
  selectedReport:any;
  constructor(private powerbiservice: PowerBiService, private getMenuService: GetMenuService, private authenticationService: AuthenticationService,private router: Router) { }

  ngOnInit() {
    this.powerbiservice.getData().subscribe(data => {
      if (data!=null && data!=undefined){
        this.reportData = data;
        this.activeItemId=data[0]["displayName"]
        let el = [].slice.call(document.getElementsByClassName('nav-link'));
        let elNew = [].slice.call(document.getElementsByClassName('new-nav-link'));
    
        for (let i = 0; i < el.length; i++)
          el[i].classList.remove('active');
    
    
        for (let i = 0; i < elNew.length; i++)
          elNew[i].classList.remove('active');
      }
      
    
    });
    this.getUserMenuInfo();
  }

  
  ngAfterViewChecked() {
 

  }

  onSelectPage(pageName: string,eleName:any): void {
    this.powerbiservice.selectReportPage(pageName);
    this.router.navigate(["powerbi"]);
    this.activeItemId = this.activeItemId === eleName ? null : eleName;
    let el = [].slice.call(document.getElementsByClassName('nav-link'));
    let elNew = [].slice.call(document.getElementsByClassName('new-nav-link'));

    for (let i = 0; i < el.length; i++)
      el[i].classList.remove('active');


    for (let i = 0; i < elNew.length; i++)
      elNew[i].classList.remove('active');

  }
  

  checkIfMenuIsStillThere(): boolean {
    if (this.menuDetails == undefined || this.menuDetails == null) {
      return false;
    }
    else
      return true;
  }
  getUserMenuInfo() {
    this.getMenuService.GetMenuListByUser(AppSettings.API_ENDPOINT + AppRelativeUrl.GetMenuInfo).subscribe(result => {
      this.menuDetails = result;
      this.distinctMenuIds=this.menuDetails.map(item => item.applicationId) 
      .filter((id, index, array) => array.indexOf(id) === index);     
      let data = [];
      data = result;
      this.authenticationService.setUrl(data)   
    },
      error => {

        let apiError: string;
        if (error.status == 400) {
          apiError = String(error.error.detail);
          // this.loginError = error.error.errors[0].title;
        }
        else if (error.status == 415) {
          apiError = error.message;
        }
        else if (error.status == 401) {
          apiError = 'Unauthorized Access, please try to restart your browser and try again.';
          // this.loginError = error.error.errors[0].title;
        }
        else if (error.status == 0)
          apiError = 'Could not connect to the service.';
        else
          apiError = error.error;

      });
  }



  public getChildMenu(menuId: string): MenuInfo[] {
    let lstMenu: MenuInfo[] = [];
    let objMenu: MenuInfo;
    for (let menu in this.menuDetails) {
      objMenu = this.menuDetails[menu];
      if (objMenu.parentId != null && objMenu.parentId == menuId) {
        lstMenu.push(objMenu);
      }
    }

    //const timer: ReturnType<typeof setTimeout> = setTimeout(() => '', 100);
    if (lstMenu.length == 0)
      return null;
    else
      return lstMenu;
  }
  
  public getMenu(applicationId:string):MenuInfo[]{
    let lstMenu: MenuInfo[] = [];
    let objMenu: MenuInfo;
    for (let menu in this.menuDetails) {
      objMenu = this.menuDetails[menu];
      if ( objMenu.applicationId == applicationId) {
        lstMenu.push(objMenu);
      }
    }
       return lstMenu;
  }


  counter = 0;
  public get fListMenu(): MenuInfo[] {
    let lstMenu: MenuInfo[] = [];
    let objMenu: MenuInfo;

 

    
    for (let menu in this.menuDetails) {

      objMenu = this.menuDetails[menu];
      if (objMenu.parentId == null) {
        lstMenu.push(objMenu);
      }
    }

    return lstMenu;
  }

  showActive(eleName:any,menuname:any,parentmenu:any,menuID :any) {
   
    if (parentmenu === 'Reports'){
     this.reportData=null;
      this.selectedReport=menuname
      this.powerbiservice.onReportnameselection(menuname,menuID);
    }
    this.activeItemId=null;
    let el = [].slice.call(document.getElementsByClassName('nav-link'));
    let elNew = [].slice.call(document.getElementsByClassName('new-nav-link'));

    for (let i = 0; i < el.length; i++)
      el[i].classList.remove('active');


    for (let i = 0; i < elNew.length; i++)
      elNew[i].classList.remove('active');


    el = [].slice.call(document.getElementsByClassName('menu-class'));


    // for (let i = 0; i < el.length; i++)
    //   el[i].classList.remove("active");
    for (let i = 0; i < el.length; i++)
      el[i].setAttribute("style", "background-color: unset; color:black;");

    var element = document.getElementById(eleName);
    element.classList.add("active");
  }

 

  openChild(linkId: string, e) {
    let element = document.getElementById('dd' + linkId);
    let linkElement = document.getElementById('lnk' + linkId);

    if (element == null) {
      // alert('NA');

      return false;
    }

    let style = window.getComputedStyle(element);
    let display = style.getPropertyValue('display');

    let childArrow = linkElement.getElementsByClassName('right');
    if (display.trim().toLowerCase() === 'none') {
      $('#dd' + linkId).slideDown('fast');
      // element.style.display = 'block';
      if (childArrow.length > 0) {
        childArrow[0].classList.remove('fa-angle-right');
        childArrow[0].classList.add('fa-angle-down');
      }
    }
    else {
      $('#dd' + linkId).slideUp('fast');
      // element.style.display = 'none';
      if (childArrow.length > 0) {
        childArrow[0].classList.remove('fa-angle-down');
        childArrow[0].classList.add('fa-angle-right');
      }
    }

 


    return false;
  }



  CheckIfLinkExists(linkId: string) {

    setTimeout(function () {

      let link = document.getElementById('lnk' + linkId) as HTMLElement;
      let dd = document.getElementById('dd' + linkId) as HTMLElement;

      
      //if (typeof(link) != 'undefined' || typeof(dd) != 'undefined' ) {
      if (link == null || dd == null) {
        this.CheckIfLinkExists(linkId);
        return;
      }
      //data-toggle="dropdown"  aria-haspopup="true" aria-expanded="false"
      link.setAttribute('aria-haspopup', 'true');
      link.setAttribute('aria-expanded', 'false');
      link.setAttribute('data-toggle', 'dropdown');

      dd.setAttribute("aria-labelledby", "lnk" + linkId);


    }, 1000);
  }
  public getapplicationwiseMenu(applicationid:string):MenuInfo[]{
    let menuList:MenuInfo[];
    menuList=this.menuDetails.filter(menuItem => menuItem.applicationId == applicationid);
    return menuList;
  }
  public getApplicationName(id:string):string{
    const foundItem=this.menuDetails.find(menuitem=>menuitem.applicationId==id )
    return foundItem.applicationName;
  }

}
