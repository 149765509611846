import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpHeaders } from '@angular/common/http';
import { map, retryWhen, catchError } from "rxjs/operators";
// import { RequestOptions, ResponseContentType } from '@angular/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { DynamicElementBase } from 'src/common/DynamicElementBase';
import { TextBox } from 'src/common/TextBox';
import { DatePicker } from 'src/common/DatePicker';
import { ComboBox } from 'src/common/ComboBox';

import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class ProcessTrackingService {

  data: any
  ElementList: DynamicElementBase<string>[];
  ElementVal: { key: string, value: string }[];
  editData: any[] = [];
  private passData = new BehaviorSubject('');
  getDataToOpenQuery = this.passData.asObservable();
  constructor(private httpClient: HttpClient, private datePipe: DatePipe) { }
  ProcessTracking(url: string, postData: FormData) {
    //let header = { headers: new HttpHeaders().set('Authorization  ', '') }

    // let formData: FormData = new FormData();
    // // formData.append('filter','');
    // // formData.append('startRow','0');
    // // formData.append('rowLength','10');
    // // formData.append('orderFilter','');
    // if (postData != '' && postData != undefined && postData != null) {
    //   for (var property in postData) {
    //     if (postData.hasOwnProperty(property)) {
    //       formData.append(property, postData[property]);
    //     }
    //   }
    // }
    return this.httpClient.post(url, postData).pipe(map(result => {
      return result;

    },
      errors => {
        //console.log(errors)
        this.errorHandler(errors)
      }))
  }

  CancelAccount(url: string, postData: FormData) {
    return this.httpClient.post(url, postData).pipe(map(res => {
      return res;
    }, err => {
      this.errorHandler(err);
    }
    ))
  }


  // { responseType: ResponseContentType.Blob })
  //// Download file Services
  DownloadFile(url: string, postData: FormData, fileExtension: string): Observable<any> {
    return this.httpClient.post(url, postData,

      { responseType: 'arraybuffer' }).pipe(
        map(
          (res: any) => {
            return new Blob([res], { type: fileExtension })
          },
          error => { this.errorHandler(error) }
        ));

  }
  CloseAccount(url: string, postData: FormData) {
    return this.httpClient.post(url, postData).pipe(map(result => {
      return result
    }, err => {
      return err;
    }))
  }

  private errorHandler(error) {
    let msg: string;
    if (error.status == 400) {
      msg = String(error.error.detail);
      // this.loginError = error.error.errors[0].title;
    }
    else if (error.status == 401) {
      msg = 'Unauthorized Access, please try to restart your browser and try again.';
      // this.loginError = error.error.errors[0].title;
    }
    else if (error.status == 0)
      msg = 'Could not connect to the service.';
    else
      msg = error.error;

    alert(msg);
  }

  GetUIElementList(url: string, formData: any): Promise<DynamicElementBase<string>[]> {
    this.ElementList = [];
    return this.httpClient.post(url, formData).pipe(
      map(result => {
        this.data = result as [];
        for (let i = 0; i < this.data.length; i++) {
          let elementType = this.data[i]['elementType'];

          switch (elementType) {
            case 'TextBox':
              let t = new TextBox({
                key: this.data[i]['elementName'],
                label: this.data[i]['elementName'],
                value: '',
                required: true,
                isMandatory: this.data[i]['isMandatory'],
                order: this.data[i]['elementSeq'],
                validations: this.data[i]['validationList']
              });

              this.ElementList.push(t);
              break;
            case 'DatePicker':
              let d = new DatePicker({
                key: this.data[i]['elementName'],
                label: this.data[i]['elementName'],
                value: '',
                required: true,
                isMandatory: this.data[i]['isMandatory'],
                order: this.data[i]['elementSeq']
              });
              this.ElementList.push(d);
              break;
            case 'ComboBox':
              let defaultValue;
              if (this.data[i]['elementVal'].length > 0) {
                this.ElementVal = [];
                let t: string;
                t = this.data[i]['elementName'];
                this.data[i]['elementVal'].forEach((value) => {
                  this.ElementVal.push({ key: value, value: value });
                  if (this.data[i]['defaultValue'] == value)
                    defaultValue = value;
                });
                if (this.ElementVal.length == 1) {
                  defaultValue = this.ElementVal[0].value;
                }
              }
              let x = new ComboBox({
                key: this.data[i]['elementName'],
                label: this.data[i]['elementName'],
                isMandatory: this.data[i]['isMandatory'],
                value: defaultValue,
                options: this.ElementVal,
                // options: [
                //   {key: '1',  value: 'PreBind'},
                //   {key: '2',  value: 'PostBind'}        
                // ],
                order: this.data[i]['elementSeq']
              });
              this.ElementList.push(x);
              this.ElementVal = [];
              break;
          }
        }
        return this.ElementList.sort((a, b) => a.order - b.order);
      })).toPromise();
  }

  ExportData(url: string, totalCount: string, filterData: string, orderFilter: string) {
    let formData: FormData = new FormData();
    formData.append('FilterData', filterData);
    formData.append('StartRow', '0');
    formData.append('RowLength', totalCount);
    formData.append('ExecType', '1');
    formData.append('OrderFilter', orderFilter);
    return this.httpClient.post(url, formData).pipe(map(result => {
      let list: any = result;
      for (let i = 0; i < list.length; i++) {
        delete list[i]['account ID'];
        delete list[i]['workID'];
        delete list[i]['queryStatus'];
        delete list[i]['statusClass'];
        delete list[i]['statusID'];
        delete list[i]['clientID'];
        delete list[i]['complete Instructions'];
        delete list[i]['complete Comments'];
        delete list[i]['emailId'];
        delete list[i]['isDownloadFileExists'];

        list[i]['upload Date'] = this.datePipe.transform(list[i]['upload Date'], 'dd-MMM-yyyy hh:mm aaa'); 
        list[i]['expected Shipment'] = this.datePipe.transform(list[i]['expected Shipment'], 'dd-MMM-yyyy hh:mm aaa'); 
        list[i]['actual Shipment'] = this.datePipe.transform(list[i]['actual Shipment'], 'dd-MMM-yyyy hh:mm aaa'); 
        list[i]['incept Date'] = this.datePipe.transform(list[i]['incept Date'], 'dd-MMM-yyyy'); 
        list[i]['expiry Date']=this.datePipe.transform(list[i]['expiry Date'],'dd-MMM-yyyy');

      }
      for (let i = 0; i < list.length; i++) {
        Object.keys(list[i]).forEach(key => {
          list[i][key.toUpperCase()] = list[i][key];
          delete list[i][key];
        })
      }
      return list;
    }))
  }


  fetchNew(url: string, fd: FormData) {
    // name="file"; filename="blob"
    // let headers = new HttpHeaders()
    // headers =  headers.append('name', 'abc.zip')
    // headers =  headers.append('filename', 'zip')
    //  headers =  headers.append('Content-Type', 'mulipart/form-data');
    // headers = headers.append('Content-Disposition', 'form-data; name="abc.zip"; filename="zip"')
    // console.log (headers);
    return this.httpClient.post(url, fd).pipe(
      map(result => {

        return result;
      }, (error) => {
        console.log(error)
      }
      )
      // retryWhen(errors => errors
      //   .pipe(
      //     map((error, count) => {
      //       alert(count);
      //       if  (error.status == 204 || error.status == 500) {
      //           console.log(1);
      //           alert(1)
      //         return of(error.status);
      //       }

      //       return throwError(error);
      //     }),
      //     // delay(500)
      //   )
      // )
    )
      .toPromise()
  }



  //   public upload (url: string, fd:FormData): Promise<any> {
  //     return new Promise((resolve, reject) => {
  //         // let formData: FormData = new FormData(),

  //         let header = new HttpHeaders();
  //         header.set('Access-Control-Allow-Origin', '*');

  //         let xhr: XMLHttpRequest = new XMLHttpRequest()

  //         // for (let i = 0; i < files.length; i++) {
  //         //     formData.append("uploads[]", files[i], files[i].name);
  //         // }

  //         xhr.onreadystatechange = () => {
  //             if (xhr.readyState === 4) {
  //                 if (xhr.status === 200) {
  //                     resolve(JSON.parse(xhr.response));
  //                 } else {
  //                   // this.upload(url, fd)
  //                     reject(xhr.response);
  //                 }
  //             }
  //         };

  //         //FileUploadService.setUploadUpdateInterval(500);

  //         // xhr.upload.onprogress = (event) => {
  //         //     this.progress = Math.round(event.loaded / event.total * 100);

  //         //     this.progressObserver.next(this.progress);
  //         // };

  //         xhr.open('POST', url, true);
  //         xhr.send(fd);
  //     });
  // }


  upload(url: string, formData: FormData): Observable<HttpEvent<any>> {
    // const headers = new HttpHeaders()
    // .append('Content-Type', 'application/json')
    // .append('Access-Control-Allow-Headers', 'Content-Type')
    // .append('Access-Control-Allow-Methods', 'POST')
    // .append('Access-Control-Allow-Origin', '*');
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true
      // responseType: 'json'
    });

    return this.httpClient.request(req);
  }

  PassedData(value) {
    this.passData.next(value);
  }


  SendDataASBody(url: string, postData: any) {
    let headers = new HttpHeaders()
    headers = headers.append('Content-Type', 'application/json')
    const pl = JSON.stringify(postData);
    console.log(pl)
    return this.httpClient.post(url, pl, { headers: headers }).pipe(map(result => {
      return result;

    },
      errors => {
        //console.log(errors)
        this.errorHandler(errors)
      }))
  }




  GetAdditonalAccount(accountid: number) {

    let formData = new FormData();
    formData.append('accountid', String(accountid))
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETADITTIONALFILE, formData).pipe(map(response => {
      let result = response;

      return result;


    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
  SSGetAdditonalAccount(accountid: number) {

    let formData = new FormData();
    formData.append('accountid', String(accountid))
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.SSGETADITTIONALFILE, formData).pipe(map(response => {
      let result = response;

      return result;


    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
  GetAdditonal(UploadFileId: string, isAdditional: boolean) {

    let formData = new FormData();
    formData.append('uploadFileId', UploadFileId);
    formData.append('isAdditional', String(isAdditional));
    //formData.append('zipPath', String(file))
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETADDITIONALZIPPATH, formData).pipe(map(response => {
      let result = response;

      return result;


    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }

  deleteFilter(FilterId: number) {

    let formData = new FormData();
    formData.append('filterId', String(FilterId));
    //formData.append('IsActive', String(!IsActive))
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.DELETEFILTER, formData).pipe(map(response => {
      let result = response;

      return result;

    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }

  GetDataForUploadShippmentPackage(Url: string) {
    return this.httpClient.post(Url, '').pipe(map(res => {
      return res;
    }, err => {
      return err.json();
    }))
  }



  downloadFileAsync(formData: any) {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.DOWNLOADACCOUNTFILE, formData,
      { responseType: 'blob' })
      .pipe(map(d => {
        return d;
      }
      ));
  }


  ///// test download async by kshitij 24 dev 2020

  public downloadFileWithProgress(formData: FormData, serviceName): Observable<HttpEvent<Blob>> {
    return this.httpClient.request(new HttpRequest(
      'POST',
      `${AppSettings.API_ENDPOINT + serviceName}`,
      formData,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  GetAccounttypetoDownlod(accountid: number) {

    let formData = new FormData();
    formData.append('accountid', String(accountid))
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETACCOUNTTYPETODOWNLOAD, formData).pipe(map(response => {
      let result = response;

      return result;


    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
  ActivateAccount(url: string, postData: FormData) {
    return this.httpClient.post(url, postData).pipe(map(result => {
      return result
    }, err => {
      return err;
    }))
  }


}
