<div class="row" [formGroup]="form" style="width: 420px; margin-left: 10px;margin-bottom: 10px;">
  <div class="col-sm-4" style="height: 30px;">
    <label [attr.for]="element.key" style="font-weight: normal;">{{element.label}}
      <label *ngIf="element.isMandatory" class='required'>*</label>
    </label>
  </div>
  <div class="col-sm-8" [ngSwitch]="element.controlType" style="width: 100%;">
    <div class="col">
      <input *ngSwitchCase="'textbox'" class="form-control" style="width: 100%;height: 100%;" [formControlName]="element.key"
             [id]="element.key" type="text" (focusout)="TextInput($event.target.value)">

      <input *ngSwitchCase="'number'" class="form-control" style="width: 100%;height: 100%;font-size: small;" [formControlName]="element.key"
             [id]="element.key" type="number" (focusout)="TextInput($event.target.value)">

      <input *ngSwitchCase="'datepicker'" class="form-control" style="width: 100%;height: 100%;" (change)="dateSelected()"
             placeholder="DD-MMM-YYYY" type="date" [formControlName]="element.key">

      <select [id]="element.key" *ngSwitchCase="'dropdown'" class="form-control" (change)="onChange($event.target.value)"
              style="width: 100%;height: 100%;" [formControlName]="element.key">
        <option value="">---Select---</option>
        <option *ngFor="let opt of element.options" [value]="opt.value">{{opt.value}}</option>
      </select>

      <div *ngIf="element.key=='Original Account'">
        <ng-select [id]="element.key" *ngSwitchCase="'dropdownAuto'"
                   placeholder="---Select---" [items]="OriginalAccountList" bindValue="key" bindLabel="value" (change)="OriginalAccountSelected($event)"  formControlName="element.key">
          style="width: 100%; height: 100%;">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div class="ngselect-wordwrap" title="{{item.value}}">{{item.value}}</div>
          </ng-template>
        </ng-select>
      </div>

    </div>
    <div class="col" style="font-size:small; color: red; margin-left: 15px;" id="error{{element.key}}"
         class="errorMessage" *ngIf="!isValid && (isTouched||showError)">{{element.errorMsg}}</div>
    <div class="col" style="font-size:small; color:rgb(255, 187, 0); margin-left: 15px;" class="errorMessage"
         *ngIf="isWarning && element.warningMsg!=''">{{element.warningMsg}}</div>
  </div>

</div>
