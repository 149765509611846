import { Pipe, PipeTransform } from '@angular/core'; 
import { MenuInfo } from '../model/Menu/MenuInfo';

@Pipe({ name: 'SidebarPipe' })
export class SidebarPipe implements PipeTransform {
    transform(menuDetails: any): MenuInfo[] {
        return this.fListMenu(menuDetails);
    }



    public fListMenu(menuDetails:MenuInfo): MenuInfo[] {
        let lstMenu: MenuInfo[] = [];
        let objMenu: MenuInfo;
    
        // if(this.menuDetails == undefined || this.menuDetails == null){
        //   this.getUserMenuInfo();
        //   console.log("Test");
        //   return ;
        // }
       
        for (let menu in menuDetails) {
    
          objMenu = menuDetails[menu];
          if (objMenu.parentId == null) {
            lstMenu.push(objMenu);
          }
        }
        return lstMenu;
      }
    

}