<div class="info-box mb-3 bg-warning"(click)="accountsInQuery('')" style="cursor: pointer;">
  <span class="info-box-icon"><i class="fas fa-tag"></i></span>

  <div class="info-box-content">
    <span class="info-box-text">Total Inventory</span>
    <span class="info-box-number">{{totalInventory}}</span>
  </div>

</div>
<!-- /.info-box -->
<div class="info-box mb-3 bg-danger" (click)="accountsInQuery('Query')" style="cursor: pointer;">
  <span class="info-box-icon"><i class="far fa-comment"></i></span>

  <div class="info-box-content">
    <span class="info-box-text">Accounts In Query</span>
    <span class="info-box-number">{{accountInQuery}}</span>
  </div>
  <!-- /.info-box-content -->
</div>
<!-- /.info-box -->
<!-- /.info-box -->
<div class="info-box mb-3 bg-info" (click)="accountsInQuery('In Queue')" style="cursor: pointer;">
  <span class="info-box-icon"><i class="fas fa-cloud-download-alt"></i></span>

  <div class="info-box-content">
    <span class="info-box-text">Accounts In Progress</span>
    <span class="info-box-number">{{accountInProgress}}</span>
  </div>
  <!-- /.info-box-content -->
</div>
<!-- /.info-box -->

<!-- /.info-box -->
<div class="info-box mb-3 bg-success" (click)="accountsInQuery('shipped')" style="cursor: pointer;">
  <span class="info-box-icon"><i class="fas fa-shopping-cart"></i></span>

  <div class="info-box-content">
    <span class="info-box-text">Shipped Accounts</span>
    <span class="info-box-number">{{shippedAccount}}</span>
  </div>
  <!-- /.info-box-content -->
</div>
<!-- /.info-box -->
<div class="info-box mb-3 bg-dark" (click)="accountsInQuery('Downloaded')" style="cursor: pointer;">
  <span class="info-box-icon"><i class="fas fa-thumbs-up"></i></span>

  <div class="info-box-content">
    <span class="info-box-text">Downloaded/Closed Accounts</span>
    <span class="info-box-number">{{downloadedAccount}}</span>
  </div>
  <!-- /.info-box-content -->
</div>
<div class="info-box mb-3 bg-secondary" (click)="accountsInQuery('Cancelled')" style="cursor: pointer;">
  <span class="info-box-icon"><i class="fas fa-ban"></i></span>

  <div class="info-box-content">
    <span class="info-box-text">Cancelled Account</span>
    <span class="info-box-number">{{cancelledAccount}}</span>
  </div>
  <!-- /.info-box-content -->
</div>

<!-- 
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Subscription Usage</h3>

              <div class="card-tools">
                <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i>
                </button>
                <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i>
                </button>
              </div>
            </div>
           
            <div class="card-body">
              <div class="row">
                <div class="col-md-8">
                  <div class="chart-responsive">
                    <canvas id="pieChart" height="150"></canvas>
                  </div>
                 
                </div>
 
                <div class="col-md-4">
                  <ul class="chart-legend clearfix">
                    <li><i class="far fa-circle text-danger"></i> London</li>
                    <li><i class="far fa-circle text-success"></i> LatAM</li>
                    <li><i class="far fa-circle text-warning"></i> Bermuda</li>
                    <li><i class="far fa-circle text-info"></i> Binder New</li>
                    <li><i class="far fa-circle text-primary"></i> Binder Update</li>                   
                  </ul>
                </div>
         
              </div>
     
            </div>
            
            <div class="card-footer bg-white p-0">
              <ul class="nav nav-pills flex-column">
                <li class="nav-item">
                  <a href="#" class="nav-link">
                   Ria
                    <span class="float-right text-danger">
                      <i class="fas fa-arrow-down text-sm"></i>
                      12%</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    Jane
                    <span class="float-right text-success">
                      <i class="fas fa-arrow-up text-sm"></i> 4%
                    </span>
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link">
                   Sarah
                    <span class="float-right text-warning">
                      <i class="fas fa-arrow-left text-sm"></i> 0%
                    </span>
                  </a>
                </li>
              </ul>
            </div>
           
          </div> -->
<!-- /.card -->