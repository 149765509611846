import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertService } from 'src/services/Common/alert.service';
import { MasterservcieService } from 'src/services/MasterService/masterservcie.service';

@Component({
  selector: 'app-manage-businessunit',
  templateUrl: './manage-businessunit.component.html',
  styleUrls: ['./manage-businessunit.component.css']
})
export class ManageBusinessunitComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder, private Service: MasterservcieService, private alertService: AlertService) { }
  _formBusinessUnit: UntypedFormGroup;
  checkerror: boolean;
  tblBusinessUnit: any;
  searchInput: any = '';
  showSearchValidationError: boolean;
  showForm: boolean;
  isEdit: boolean;
  editedBusinessUnitId: number;
  errorhandlerService = new ErrorHandler(this.alertService);
  buttonTittle: string = "Add";
  dynmaicFieldHeader: any = [];
  _formdeativateBu:UntypedFormGroup;
  BusinessUnitName:string;
  checkModelError:boolean;
  IsActive:boolean;
  statusButtonTittle:string;
  @ViewChild('closebutton', { static: false }) closebutton;



  ngOnInit(): void {
    this._formBusinessUnit = this.formBuilder.group({
      name: ['', [Validators.required,Validators.maxLength(50)]],
      description: ['', [Validators.required,Validators.maxLength(50)]],
      requestedby: ['', Validators.required],
      comment:['',Validators.required]
    })
    this.GetBusinessUnitDetail('');
    this._formdeativateBu = this.formBuilder.group({
      reasoncomment: ['', [Validators.required, Validators.maxLength(500)]]
    })
  }
  GetBusinessUnitDetail(searchInput: string) {
    this.dynmaicFieldHeader=[];
    this.tblBusinessUnit='';
    let formdata = new FormData();
    formdata.append('searchInput', searchInput);
    this.Service.GetMastersData(AppSettings.API_ENDPOINT + AppRelativeUrl.GETALLBUSINESSUNIT, formdata).subscribe(res => {
      this.tblBusinessUnit = res;
      this.dynmaicFieldHeader = this.dynmaicFieldHeader.concat(Object.keys(this.tblBusinessUnit[0]));
      console.log(this.dynmaicFieldHeader);
    }, err => {
     this.errorhandlerService.ErrorsHandler(err);
    })
  }
  get GetFormControls() {
    return this._formBusinessUnit.controls;
  }
  Add() {
    this.checkerror = true
    if (this._formBusinessUnit.controls['name'].invalid || this._formBusinessUnit.controls['description'].invalid
      || this._formBusinessUnit.controls['requestedby'].invalid || this._formBusinessUnit.controls['comment'].invalid) {
      return
    } else {
      let formdata = new FormData();
      if (this.isEdit) {
        formdata.append('BusinessUnitId', String(this.editedBusinessUnitId));
      }
      else {
        formdata.append('BusinessUnitId', '0')
      }
      formdata.append('BusinessUnitName', this._formBusinessUnit.value.name)
      formdata.append('Description', this._formBusinessUnit.value.description)
      formdata.append('Comment', this._formBusinessUnit.value.comment)
      formdata.append('RequestedBy', this._formBusinessUnit.value.requestedby)
      formdata.append('UserId', '0');
      this.Service.AddEditMaster(AppSettings.API_ENDPOINT + AppRelativeUrl.ADDEDITBUSINESSUNIT, formdata).subscribe(res => {
        if (res) {
          this.alertService.success("Business Unit has been successfully")
          this._formBusinessUnit.reset();
          this.isEdit = false;
          this.checkerror=false;
          this.buttonTittle = "Add";
          this.GetBusinessUnitDetail('');
        } else {
          this.alertService.warn('This Business Unit is allready exists');
        }
      },
        err => {
          this.errorhandlerService.ErrorsHandler(err);
        })
    }
  }
  DeleteBusinessUnit(item) {
    this.editedBusinessUnitId = item.id;
    this.BusinessUnitName = item.name;
    if (item.isActive) {
      this.statusButtonTittle = 'Deactivate';
    }
    else {
      this.statusButtonTittle = "Activate";
    }
    this.IsActive = item.isActive;
  }
  EditBusinessUnit(item) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
    this.editedBusinessUnitId = item.id;
    this.showForm = true;
    this.isEdit = true;
    this.buttonTittle = "Edit";
    this._formBusinessUnit.patchValue({
      name: item.name,
      description: item.description,
      comment: item.comment,
      requestedby:item.requestedBy
    });

  }
  SearchItem() {
    this.GetBusinessUnitDetail(this.searchInput);
  }
  resetRecords() {
    this.searchInput = ''
    this.GetBusinessUnitDetail(this.searchInput);
  }
  checkSearch() {

  }
  AddAccountType() {
    this.showForm = true;
  }
  close() {
    this.buttonTittle="Add"
    this.showForm = false;
    this.checkerror = false;
    this._formBusinessUnit.reset();
  }
  ShowPasswordError() {
    this.checkModelError = true;
  }
  CloseModel() {
    this._formdeativateBu.reset();
    this.BusinessUnitName = null;
    this.checkModelError = false;
  }
  ActivateDeativateBusinessUnit() {
    this.checkModelError = true;
    if (this._formdeativateBu.controls['reasoncomment'].invalid) {
      return;
    } else {
      let postData = new FormData();
      postData.append("BusinessUnitId",String(this.editedBusinessUnitId));
      postData.append("Comment", this._formdeativateBu.value.reasoncomment);
      postData.append("IsActive", String(!this.IsActive));
      this.Service.AddEditMaster(AppSettings.API_ENDPOINT + AppRelativeUrl.DEACTIVATEBUSINESSUNIT, postData).subscribe(
        res => {
          if (res) {
            this.alertService.success('Business Unit details has been saved successfully');    
            this.closebutton.nativeElement.click();      
            this.ngOnInit();
          } else {
            this.alertService.error("SomeThing went wrong");
          }
        },
        err => {
          this.errorhandlerService.ErrorsHandler(err);
        }
      )
    }
  }
  get ModelGetFormControls() {
    return this._formdeativateBu.controls
  }

}
