import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecentJobsComponent } from './recent-jobs/recent-jobs.component';

const routes: Routes =  [
  {
    path: '',
    component:RecentJobsComponent  },
  {
    path: 'recent-jobs',
    component: RecentJobsComponent
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecentJobsRoutingModule { }
