import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { format } from 'url';

@Injectable({
  providedIn: 'root'
})
export class ChangepasswordserviceService {

  constructor(private httpClient: HttpClient) { }
  ChangePasswordFirstTime(OldPassword: string, NewPassword: string, Token: string) {
    const form = new FormData();
    form.append('OldPassword', OldPassword);
    form.append('NewPassword', NewPassword);    
      return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.CHANGE_PASSWORD, form,
        { headers: new HttpHeaders().set('Authorization','Bearer '+ Token)}).pipe(
        map(
          res => {
            return res;
          },
          err => {
            let er = err.json();
            return er
          }
        )
      )    
  } 
  ChangePasswordFromInternal(Url:string,UserName:string,OldPassword:string,NewPassword){
    const form = new FormData();
    form.append('OldPassword', OldPassword);
    form.append('NewPassword', NewPassword);
    form.append('UserName', UserName);
    return this.httpClient.post(Url, form).pipe(
      map(res => {
        return res
      },
        err => {
          return err.json();
        })
    )
  }
}
