
<section id="top" style="padding-top: 20px;">
    <div class="container-fluid">
        <div class="card card-default ">
            <div class="card-body ">
                <div class="container  mt-3">
                    <h3 class="text-center" style="margin-right: 10px;">
                        Welcome to Data Solution Portal
                    </h3>
                    <hr> <br>
                    <div class="row text-center">
                        <div class="col-lg-3 col-md-4" style="cursor: pointer;" *ngFor="let items of description;let i = index">
                            <div class=" card" title="Click here to navigate to {{items.menuName}}">
                                <i  routerLink="{{items.url}}" class="{{items.icon}} fa-2x mt-3 mb-2 icon"
                                    style="outline : none; cursor: pointer;" ></i>
                                <img class="card-img-top" src="" alt="">
                                <div  routerLink="{{items.url}}">
                                    <h5 style="text-align: center;">{{items.menuName}}</h5>
                                    <p class="card-text" style="height: 50px;"> {{items.description}}
                                    </p>
                                </div>
                                <div class="mt-2">
                                    <a class="btn btn MoodysIconColor" (click)="readMore(i)" >Read
                                        More</a>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngFor="let item of description; let i = index" class="container-fluid"  id=div{{i}}>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="alert1 col-5 col-lg-3">
                    <h4>{{item.menuName}}</h4>
                </div>
                <div class="alert1 mt-3 col-5 col-md-3" style="height: 1px;">

                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <i class="{{item.icon}} fa-4x icon" style="margin-left: 50px;"></i>
                </div>
                <div class="col-md-10">
                    <ul *ngFor='let items of menudetailforupload'>
                        <li *ngIf="item.id == items.menuId">{{items.detail}} </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section id="processtracking" class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="alert1 col-5 col-lg-3">
                    <h4>Process Tracking</h4>
                </div>
                <div class="alert1 mt-3 col-5 col-lg-3" style="height: 1px;">

                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <i class="fas fa-truck-monster fa-4x icon" style="margin-left: 50px;"></i>
                </div>
                <div class="col-md-10">
                    <ul>
                        <li>Upload Account with 10 files and 300 MB limit </li>
                        <li>Bulk Async Upload </li>
                        <li>Easy to upload default auto-fill </li>
                        <li>Dynamic optional fields as per client need </li>
                        <li>Ability to add additional SOV in his uploaded jobs</li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="messagecenter" class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="alert1 col-5 col-lg-3">
                    <h4>Message Center</h4>
                </div>
                <div class="alert1 mt-3 col-5 col-lg-3" style="height: 1px;">

                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <i class="fas fa-envelope fa-4x icon" style="margin-left: 50px;"></i>
                </div>
                <div class="col-md-10">
                    <ul>
                        <li>Upload Account with 10 files and 300 MB limit </li>
                        <li>Bulk Async Upload </li>
                        <li>Easy to upload default auto-fill </li>
                        <li>Dynamic optional fields as per client need </li>
                        <li>Ability to add additional SOV in his uploaded jobs</li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="usermanagement" class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="alert1 col-5 col-lg-3">
                    <h4>User Management</h4>
                </div>
                <div class="alert1 mt-3 col-5 col-lg-3" style="height: 1px;">

                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <i class="fa fa-user fa-4x icon" style="margin-left: 50px;"></i>
                </div>
                <div class="col-md-10">
                    <ul>
                        <li>Upload Account with 10 files and 300 MB limit </li>
                        <li>Bulk Async Upload </li>
                        <li>Easy to upload default auto-fill </li>
                        <li>Dynamic optional fields as per client need </li>
                        <li>Ability to add additional SOV in his uploaded jobs</li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="clientmanagement" class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="alert1 col-5 col-lg-3">
                    <h4>Client Management</h4>
                </div>
                <div class="alert1 mt-3 col-5 col-lg-3" style="height: 1px;">

                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <i class="fa fa-cog fa-4x icon" style="margin-left: 50px;"></i>
                </div>
                <div class="col-md-10">
                    <ul>
                        <li>Upload Account with 10 files and 300 MB limit </li>
                        <li>Bulk Async Upload </li>
                        <li>Easy to upload default auto-fill </li>
                        <li>Dynamic optional fields as per client need </li>
                        <li>Ability to add additional SOV in his uploaded jobs</li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="rolemanagement" class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="alert1 col-5 col-lg-3">
                    <h4>Role Management</h4>
                </div>
                <div class="alert1 mt-3 col-5 col-lg-3" style="height: 1px;">

                </div>
            </div>
            <div class="row">
                <div class="col-md-2">
                    <i class="fas fa-edit fa-4x icon" style="margin-left: 50px;"></i>
                </div>
                <div class="col-md-10">
                    <ul>
                        <li>Upload Account with 10 files and 300 MB limit </li>
                        <li>Bulk Async Upload </li>
                        <li>Easy to upload default auto-fill </li>
                        <li>Dynamic optional fields as per client need </li>
                        <li>Ability to add additional SOV in his uploaded jobs</li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->