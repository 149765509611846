import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ProcessTrackingService } from 'src/services/Process-TrackingServices/process-tracking.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { AlertService } from '../../../services/Common/alert.service'
// import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AlertMessages } from 'src/common/alert-messages';
import { ErrorHandler } from 'src/common/error-handler';
import { PopupService } from 'src/services/Common/popUp.service';
import { ExportService } from 'src/services/Common/export.service';
import { CommonMethod } from 'src/common/CommonMethod';
import { FeatureMaster } from 'src/common/feature-master';
import { MenuList } from 'src/common/menuList.enum';
import { Authenticateuser } from 'src/common/Authenticateuser';
import { HttpEventType } from '@angular/common/http';
import { DownloadShipmentModel } from '../../../model/DonwloadShipmentPackage/DownloadShipmentModel';
import { DataShareService } from 'src/services/Common/dataShare.service';
import { AlertComponent } from 'src/app/Common/alert/alert.component';




@Component({
  selector: 'app-process-tracking',
  templateUrl: './view-process-tracking.component.html',
  styleUrls: [
    './view-process-tracking.component.css'
    , '../../../styles/tables.css'
  ]
})
export class ViewProcessTrackingComponent implements OnInit, AfterViewInit {
  IsAdditionalUpload: any;
  IsEditPackageType:any;
  IsAdditionalShipmentUpload: any;

IsRAAddtionUpload:boolean
IsDESAddtionUpload:boolean
IsStandarAddtionUpload:boolean

  constructor(private processTrackingService: ProcessTrackingService, private router: Router
    , private exportFile: ExportService,
    private alertService: AlertService, private Userdetail: AddEditUserSerivceService,
    private popupService: PopupService
    , private ShareData: DataShareService

  ) {
    // this.obj.checkAccess(MenuList.ProcessTracking)
  }


  data: any;
  auditData: any;
  auditAccName: string;
  auditAccType: string;
  auditBU: string;
  auditWorkType: string;
  searchInput: string = '';
  showLoader: boolean = false;
  dynFieldheader: string[];
  prevSort: string;
  userdetails: any;
  editData;
  tblHeader;
  userType: boolean = false;
  obj: CommonMethod = new CommonMethod();
  downloadDone: boolean = false;
  // paging variables
  pageSize: number = 100;
  pageStart: number = 0;
  totalCount: number = 0;
  item: number[] = [];
  rowCount: number = 0;
  pageCount: number;
  currentPage: number = 1;
  nextDotShow: boolean = false;
  EditAccountName: string = '';
  previousDotShow: boolean = false;
  errorHandler = new ErrorHandler(this.alertService);
  IsEditVisible: boolean;
  ifOpenModalForAudit = false;
  accountNameForShipmentDownload: string = '';
  display = 'none';
  //// advanced filter 
  showFilter = false;
  authorizedForEdit: boolean;
  authorizedForDownload: boolean;
  authorizedForCancelAccount: boolean;
  authorizedForUploadAdditionSOV: boolean;
  authorizedForDownloadSOV: boolean;
  authorizedForCloseAccount: boolean;
  authorizedForRaiseQuery: boolean;
  IsAuthorizedForAdditionalShipment: boolean;
  IsAuthorizeForRAShipment: boolean;
  IsAuthorizeForDESShipment: boolean;
  IsRAAdditionalShipment: boolean;
  IsRAApplicable: boolean = false;
  IsDESApplicable: boolean = false;
  authorizedForUploadShipment: boolean = false;
  sortUP: boolean = true;
  sortDesc: boolean = false;
  columSelected: any = 5;
  timezoneid = Authenticateuser.timeZone
  downloading: boolean = false;
  progress: number = 0;
  DownloadshippemntAccountNmae;
  /*
  Variable for Download Shipment packages
  1 for cleansing, 2 for RA, 3 for both
  * */
  downloadTypeForPackage: any = 'all';
  shipmentDownload: any;
  accountIDforShipmentDownload = '';
  sovDownload: any;
  hasAdditionalfile: any;
  showModalBox: boolean = false;
  aditionalfiles: any;
  headerForAdditionalfiles: any[] = [];
  Name: any;
  downloadFileId: any;
  accountId: any;
  accountName: any;
  sovFileno: any;
  IsuploadShipmentPackage: boolean = false;
  showDownloadQueue: boolean = false;
  downloadQueue: any[] = [];
  isDownloading: boolean = false;
  rowNoForDownload: any;
  percentDownload: number;
  dispayForinstructionPopup = 'none';
  dataDownloadShipmentList: any;
  selectedPageNo: any = 1;
  downloadFileProgress: any[] = [];
  showDownload = false;
  completeInstructions: any;
  instructionsHeader: any;
  isRmsUSer: boolean;
  accounTypeToDownload: any;
  showSearchValidationError: boolean = false;
  loadingMessage = 'Fetching records...';
  downloadProperties: [string, string, string] = null;
  downloadFailedCalledFrom = '';
  showAllInModal: boolean;
  AdvanceFilterSql: string = '';
  PackageType:number
  authorizedForActivateAccount: boolean;

  public CloseEdit(value): void {
    this.IsEditVisible = value;

  }
  public closeAdditionalUpload(value) {
    this.IsAdditionalUpload = value;
    this.fillData('', 0, this.pageSize, '', 1);
  }

 public CloseEditPackage(value): void {
    this.IsEditPackageType = value;
    this.fillData('', 0, 100, '', 1);

  }
  public closeAdditionalShipmentUpload(value) {
    this.IsAdditionalShipmentUpload = value;
    this.fillData('', 0, 100, '', 1);
  }
  // ngOnDestroy(): void {
  //   this.dataDownloadShipmentList.unsubscribe();
  //   this.ShareData.downloadShipmentProcess(null);
  // }

  ngOnInit() {

    CommonMethod.selectMenuBYRouterLink('/processtracking');

    this.prevSort = '';

    this.ShareData.ifDownloadDivOpen$.subscribe(ifOpen => { this.showDownload = ifOpen; });
    this.ShareData.processTrackingFilter$.subscribe(filter => this.searchInput = filter);
    this.ShareData.advanceFilter$.subscribe(filter => this.AdvanceFilterSql = filter);
    this.ShareData.recoreds$.subscribe(pagesize => {
      if (pagesize != null && pagesize != undefined && pagesize != '') {
        this.pageSize = Number(pagesize)
      }
    }
    );
    this.ShareData.selectedPage$.subscribe(selectedpage => {
      if (selectedpage != null && selectedpage != undefined && selectedpage != '') {
        this.selectedPageNo = selectedpage;
        let pageNO: number = Number(selectedpage);
        if (pageNO <= 1) {
          this.pageStart = 0;
        }
        else {
          this.pageStart = this.pageSize * (pageNO - 1);
        }
      }
    })
    // alert(this.searchInput);
    let options = {
      autoClose: false,
      keepAfterRouteChange: false
    };
    this.CheckAuthorized();
    // if(this.selectpageize != undefined && this.selectpageize != null && this.selectpageize != ''){
    //   this.pageSize=Number(this.selectpageize);
    // }
    if (this.AdvanceFilterSql != undefined && this.AdvanceFilterSql != null && this.AdvanceFilterSql != '') {
      this.showFilter = true;
      this.fillData(this.AdvanceFilterSql, this.pageStart, this.pageSize, '', 1);
      this.GetTotalCount(this.AdvanceFilterSql);
    }
    else if (this.searchInput.trim() == '') {
      this.fillData('', this.pageStart, this.pageSize, '', 1);
      this.GetTotalCount(this.searchInput.trim());
    } else {
      this.searchRecords();
    }
    this.subscribeToShareData();


    this.Userdetail.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      this.userdetails = res['userDetails'][0]['clientID'];
      if (this.userdetails > 2) {
        this.isRmsUSer = false
      } else {
        this.isRmsUSer = true;
      }

    }, err => {

    })

  }

  ngAfterViewInit(): void {
    let selectedRow: string;
    this.ShareData.SelectedRow$.subscribe(row => selectedRow = row);
    if (selectedRow != undefined && selectedRow != '')
      this.waitElement(selectedRow);
  }

  waitElement(id: string) {

    setTimeout(function () {
      let eleToClick = document.getElementById(id) as HTMLElement;
      if (eleToClick == null || eleToClick == undefined) {
        this.waitElement(id);
      }
      else {
        CommonMethod.selectRow(id);
      }
    }, 900);
  }

  ProcessTracking() {

  }
  changeUploadType($event) {
    let el: HTMLElement = document.getElementById("radioRAShip") as HTMLElement;
    // console.log(document.getElementById('radioRAShip')['checked'])
    this.IsRAAdditionalShipment = document.getElementById('radioRAShip')['checked'];

  }
  changeUploadType_Dheeraj(packageType:any) {
if(packageType=='1'){
this.PackageType=1
}
if(packageType=='2'){
  this.PackageType=2
}
if(packageType=='3'){
  this.PackageType=3
}
  }


  EditAccount(edit: [], el: HTMLElement) {
    if (!this.authorizedForEdit) {
      this.IsEditVisible = false;
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
      return;
    }
    if (this.authorizedForEdit && !this.isRmsUSer && edit['statusID'] > '1') {
      this.IsEditVisible = false;
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
      return;
    }
    if (this.authorizedForEdit && this.isRmsUSer && (edit['statusID'] == '8' || edit['statusID'] == '9')) {
      this.IsEditVisible = false;
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
      return;
    }
    this.IsAdditionalUpload = false;
    this.IsAdditionalShipmentUpload = false;
    this.IsEditPackageType=false;
    this.IsEditVisible = true;
    this.EditAccountName = " : " + edit['account Name'];
    this.editData = edit;
    el.scrollIntoView();
  }

  AdditionalUpload(item: [], el: HTMLElement) {
    this.IsEditVisible = false;
    this.IsEditPackageType=false;
    this.IsAdditionalUpload = true;
    this.editData = item;
    el.scrollIntoView();
  }
  EditPackageType(item: [], el: HTMLElement) {
    this.IsEditVisible = false;
    this.IsAdditionalUpload = false;
    this.IsEditPackageType=true;
    this.editData = item;
    el.scrollIntoView();
  }
  AdditionalShipmentUpload(item: [], el: HTMLElement) {
    this.IsEditVisible = false;
    this.IsAdditionalShipmentUpload = true;   

    if (item['workID'] == 1) {
      this.IsDESApplicable = true
      this.IsRAApplicable = false;
      this.IsRAAdditionalShipment = false;
    } else if (item['workID'] == 2) {
      this.IsDESApplicable = false;
      this.IsRAApplicable = true;
      this.IsRAAdditionalShipment = true;
    } else {
      this.IsDESApplicable = true;
      this.IsRAApplicable = true;

    }
    this.editData = item;
    el.scrollIntoView();

  }
  AdditionalShipmentUpload_Dheeraj(item: [], el: HTMLElement) {
//     IsRAAddtionUpload:boolean
// IsDESAddtionUpload:boolean
// IsStandarAddtionUpload:boolean
    this.IsEditVisible = false;
    this.IsAdditionalShipmentUpload = true;  
    if(item['package Type'].toLowerCase()=='standard'){
      // alert('s')
     this.IsStandarAddtionUpload=true;
     this.IsDESAddtionUpload=false;
      this.IsRAAddtionUpload=false;
      this.PackageType=1
    }
    if(item['package Type'].toLowerCase()=='customize'){  
      // alert('c')
      this.IsStandarAddtionUpload=false;  
    if (item['workID'] == 1) {
      this.IsDESAddtionUpload=true;
      this.IsRAAddtionUpload=false;
      this.PackageType=3
    } else if (item['workID'] == 2) {
      this.IsRAAddtionUpload=true
      this.IsDESAddtionUpload=false;
      this.PackageType=2
    } else {
      this.IsDESAddtionUpload=true;
      this.IsRAAddtionUpload=true
      this.PackageType=2
    }    
    }
    if(item['package Type'].toLowerCase()=='standard & customize'){       
      if (item['workID'] == 1) {        
        this.IsDESAddtionUpload=true;
        this.IsRAAddtionUpload=false;
        this.PackageType=3
      } else if (item['workID'] == 2) {
        this.IsRAAddtionUpload=true
        this.IsDESAddtionUpload=false;
        this.PackageType=2
      } else {
        this.IsDESAddtionUpload=true;
        this.IsRAAddtionUpload=true
        this.PackageType=2
      }  
      this.IsStandarAddtionUpload=true;
      this.PackageType=1    
    }
    this.editData = item;
    el.scrollIntoView();

  }
  fillData(filter: string, startRow: number, rowLength: number, orderFilter: string, execType: number) {
    if (execType == 0)
      execType = 1;
    this.showLoader = true;
    let formData: FormData = new FormData();
    formData.append('FilterData', filter);
    formData.append('StartRow', String(startRow));
    formData.append('RowLength', String(rowLength));
    // formData.append('RowLength', '100');
    formData.append('ExecType', String(execType));
    formData.append('OrderFilter', String(orderFilter));

    this.data = [];
    this.loadingMessage = "Fetching records...";

    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.PROCESS_TRACKING, formData).subscribe(
      result => {
        this.data = result;
        this.dynFieldheader = [];
        this.dynFieldheader = this.dynFieldheader.concat(Object.keys(result[0]));
        this.showLoader = false;
        this.rowCount = this.data.length;

      }
      ,
      error => {
        this.loadingMessage = "No records found";
        this.errorHandler.ErrorsHandler(error)
        // ErrorHandler.getInstance().ErrorsHandler(error);
        this.showLoader = false;

      }
    )

  }

  /**
   * showAudit
obj:any   */
  public showAudit(obj: any) {
    // alert(obj.accountID);



    this.ifOpenModalForAudit = false;
    this.auditData = [];
    this.auditAccName = String(obj['account Name']);
    this.auditAccType = String(obj['account Type']);
    this.auditBU = String(obj['business Unit']);
    this.auditWorkType = String(obj['work Type']);
    let formData: FormData = new FormData();
    formData.append('accountId', String(obj['account ID']));
    //formData.append('disTimeZoneID', String(this.timezoneid));

    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETAUDITHISTORYBYACCOUNT, formData).subscribe(
      result => {
        this.auditData = result;

        // this.data = result;
      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }

  /// check if serach required
  checkSearchValue(search: string) {
    // let search = this.searchInput;
    if (search == undefined || search == null || search === '') {
      return '';
    }
    else
      return search.trim();
  }

  public searchRecords() {
    //// ----reset advance filter
    this.showFilter = false;
    this.ShareData.AdvanceFilterPill([]);
    this.ShareData.shareProcessTrackingAdvanceFilter('');
    /// check if no error
    if (this.showSearchValidationError)
      return;

    let search = this.checkSearchValue(this.searchInput);

    this.ShareData.shareProcessTrackingFilter(search);

    this.GetTotalCount(this.searchInput);
    this.fillData(search, 0, this.pageSize, '', 1);
  }

  public resetRecords() {
    this.showFilter = false;
    this.showSearchValidationError = false;
    this.searchInput = '';
    this.ShareData.shareProcessTrackingFilter('');
    this.ShareData.shareProcessTrackingAdvanceFilter('');
    this.ShareData.setRecordCount(100);
    this.ShareData.setPageNo(1);
    this.ShareData.AdvanceFilterPill([]);
    this.GetTotalCount(this.searchInput);
    // this.fillData('', 0, 10, '', 1);
    this.fillData('', this.pageStart, this.pageSize, '', 1)
  }


  //// Code for download sov filw
  public prepareSOV(obj: any, accountName: any) {

    this.accountIDforShipmentDownload = String(obj);
    let formData: FormData = new FormData();
    formData.append('accountId', String(obj));
    // formData.append('disTimeZoneID', '3');
    this.downloadFailedCalledFrom = 'SOV';
    this.callServiceForDownload(formData, AppRelativeUrl.GETSOVFILE, true, String(accountName));

  }



  private callServiceForDownload(formData: FormData, serviceName: string, isZipDownload: boolean, fileName: string) {
    /// segrigating on different service name to download SOV or Shipment account
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + serviceName, formData).subscribe(
      result => {
        //    console.log(result)

        if (!result || result === 'undefined' || result == null)
          return;

        let zipPath: string = JSON.stringify(result[0]['zipPath']);
        // alert(zipPath);
        //// call to download the file

        if (zipPath != undefined) {
          //// sov or additional file to be downloaded
          if (zipPath.trim() === '') {
            this.alertService.error('No file found.');
            return;
          }

          // zipPath = zipPath.replace('/\\/', '/\/');
          // alert(zipPath);
          ///call the api downloading the actual file

          // alert(zipPath)
          let serviceName: string;
          // if (isZipDownload)
          serviceName = AppRelativeUrl.DOWNLOADZIPFILE;
          // else
          //   serviceName = AppRelativeUrl.DOWNLOADFILE;
          this.downloadFailedCalledFrom = 'SOV';
          formData = new FormData();
          formData.append('fileName', zipPath)

          this.tryDownload(formData, fileName, serviceName, 'SOV', 2);

          // this.callSOVDownload(zipPath, serviceName, fileName);
        }
        else {
          /// download sov to be downloaded
          this.isDownloading = false;
          let i = 0;
          while (1) {
            if (result[i] == undefined || result[i] == null || result[i] === '') {
              break;
            }
            zipPath = JSON.stringify(result[i]['filePath']);


            let serviceName = AppRelativeUrl.DOWNLOADACCOUNTFILE;
            // else
            //   serviceName = AppRelativeUrl.DOWNLOADFILE;

            //// update in the download progress page
            //this.downloadFailedCalledFrom = result[i]['fileType'];
            zipPath = zipPath.replace(/"/g, '').trim();
            formData = new FormData();
            formData.append('fileName', zipPath)
            fileName = fileName;

            this.tryDownload(formData, fileName, serviceName, result[i]['fileType'], 4, false);

            i++;


          }
        }
      }
      ,
      error => {
        this.isDownloading = false;
        this.downloadDone = false;


        if (this.downloadFailedCalledFrom.toLowerCase().trim() === 'sov') {
          this.tryDownload(formData, fileName, serviceName, this.downloadFailedCalledFrom, 2);
        }
        else if (this.downloadFailedCalledFrom.toLowerCase().trim() === 'download account') {

          this.tryDownload(formData, fileName, serviceName, 'DES', 4);
          this.tryDownload(formData, fileName, serviceName, 'RA', 4);
        }
        else {

          this.tryDownload(formData, fileName, serviceName, this.downloadFailedCalledFrom, 4);
        }
        this.errorHandler.ErrorsHandler(error);
      }
    )

    // this.isDownloading = false;
    // this.downloadDone = false;
  }

  /**Method to refresh the grid data  */
  public RefreshGridData(value): void {
    this.ngOnInit();
  }


  /// this needs to be changed later with an async download
  callZipDownload(filePath: string, apiService: string, fileName: string) {
    // let fileName = '5ac52436-302a-42e0-8f79-3c646986bd7c.zip';
    // let fileName = 'test300MB.zip';)
    if (filePath === '')
      return;
    filePath = filePath.replace(/"/g, '').trim();
    // alert(fileName);
    let url = AppSettings.API_ENDPOINT + apiService;
    // let url = AppSettings.API_ENDPOINT + AppRelativeUrl.DOWNLOADSOVFILE + '?fileName=' + fileName;
    //get file type
    let fileType: string = this.getFileExtension(filePath);

    let formData: FormData = new FormData();
    formData.append('fileName', filePath);



    this.processTrackingService.DownloadFile(url, formData, fileType)
      .subscribe(
        success => {
          // saveAs(success, filePath);
          saveAs(success, fileName);

          // let postData = new FormData();
          // postData.append('accountId', String(this.accountIDforShipmentDownload));
          // postData.append('statusId', String(7));
          // // this.updateAccountStatus()
          // this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.CHANGEACCOUNTSTATUS, postData).subscribe();
        },
        err => {
          // this.errorHandler.ErrorsHandler(err);
          this.alertService.error("File not available to download.");
        }
      );

    this.downloadDone = false;
  }



  callSOVDownload(filePath: string, apiService: string, fileName: string) {
    // let fileName = '5ac52436-302a-42e0-8f79-3c646986bd7c.zip';
    // let fileName = 'test300MB.zip';)
    if (filePath === '')
      return;
    filePath = filePath.replace(/"/g, '').trim();
    // alert(fileName);
    let url = AppSettings.API_ENDPOINT + apiService;
    // let url = AppSettings.API_ENDPOINT + AppRelativeUrl.DOWNLOADSOVFILE + '?fileName=' + fileName;
    //get file type
    let fileType: string = this.getFileExtension(filePath);

    let formData: FormData = new FormData();
    formData.append('fileName', filePath);



    this.processTrackingService.DownloadFile(url, formData, fileType)
      .subscribe(
        success => {
          // saveAs(success, filePath);
          saveAs(success, fileName);
          this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.DELETETEMPZIP, formData).subscribe();
        },
        err => {
          this.alertService.error("Server error while downloading file.");
        }
      );

    this.downloadDone = false;
  }


  private getFileExtension(fileName: string): string {
    let checkFileType = fileName.split('.').pop().trim().toLowerCase();
    let fileType;
    if (checkFileType == "zip")
      fileType = "application/zip";

    if (checkFileType == "txt") {
      fileType = "text/plain";
    }
    if (checkFileType == "pdf") {
      fileType = "application/pdf";
    }
    if (checkFileType == "doc") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == "docx") {
      fileType = "application/vnd.ms-word";
    }
    if (checkFileType == "xls") {
      fileType = "application/vnd.ms-excel";
    }
    if (checkFileType == "png") {
      fileType = "image/png";
    }
    if (checkFileType == "jpg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == "jpeg") {
      fileType = "image/jpeg";
    }
    if (checkFileType == "gif") {
      fileType = "image/gif";
    }
    if (checkFileType == "csv") {
      fileType = "text/csv";
    }
    return fileType;
  }


  public sortUp(headerName: string) {

    this.sortUP = false;
    this.sortDesc = true;

    headerName = '[' + headerName + ']';
    // this.checkPrevSort(headerName);

    // if (this.prevSort === '')
    //   this.prevSort = headerName + ' asc'
    // else
    //   // this.prevSort += ' , ' + headerName + ' asc';

    this.prevSort = headerName + ' asc';


    this.fillData(this.checkSearchValue(this.searchInput), 0, this.pageSize, this.prevSort, 1);
  }


  public sortDown(headerName: string) {
    this.sortDesc = false;
    this.sortUP = true;
    headerName = '[' + headerName + ']';
    this.checkPrevSort(headerName);

    // if (this.prevSort === '')
    //   this.prevSort = headerName + ' desc'
    // else
    //   this.prevSort += ' , ' + headerName + ' desc';

    this.prevSort = headerName + ' desc';

    this.fillData(this.checkSearchValue(this.searchInput), 0, this.pageSize, this.prevSort, 1);
  }

  /// Check if the previous sorting string contains the value of current headr sorting
  private checkPrevSort(headerName: string) {

    if (this.prevSort.includes(headerName)) {
      this.prevSort = this.prevSort.replace(' , ' + headerName + ' asc', '');
      this.prevSort = this.prevSort.replace(headerName + ' desc', '');
      this.prevSort = this.prevSort.replace(' , ' + headerName + ' desc', '');
      this.prevSort = this.prevSort.replace(headerName + ' asc', '');
      this.prevSort = this.prevSort.replace(headerName + ' desc', '');

      if (this.prevSort.trim() === ',')
        this.prevSort = '';

      if (this.prevSort.trimLeft().startsWith(',')) {
        this.prevSort = this.prevSort.trimLeft().substring(1, this.prevSort.length);
      }


      if (this.prevSort.trimRight().endsWith(',')) {
        this.prevSort = this.prevSort.trimLeft().substring(0, this.prevSort.length - 1);
      }

    }
  }
  CheckStataus(value): string {
    if (this.userdetails > 2) {
      return 'fa fa-times  dis font15';
    }
    else {
      if (value.statusID > 6 || value.statusID == 11) {
        return 'fa fa-times  dis font15';
      }
      else {
        return 'fa fa-times text-primary font15';
      }
    }
  }
  CancelAccount(value) {
    if (!this.authorizedForCancelAccount) {
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
      return;
    }
    if (value.statusID >= 6 || value.statusID == 11) {
      return;
    }
    this.popupService.Confirmation('Are you sure?', value['account Name'] + ' will be cancel!', 'Yes, Confirm', 'No, Cancel').then(result => {
      if (result.value) {
        let postData = new FormData();
        postData.append('accountId', String(value['account ID']));
        postData.append('status', '9');//sending status 9 to cancel the account
        this.processTrackingService.CancelAccount(AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEACCOUNTSTATUS, postData)
          .subscribe(res => {
            if (res) {
              this.alertService.success(AlertMessages.ALERTMESSAGE11);
              this.ngOnInit();
            }
          }, err => {

          })
      }
    })
  }



  // paging funtions

  /** Method to get the total pages count  */
  private GetTotalCount(search: string) {

    ////create form data
    let formData: FormData = new FormData();
    formData.append('FilterData', this.checkSearchValue(search));
    formData.append('StartRow', '0');
    formData.append('RowLength', '0');
    formData.append('OrderFilter', '');
    formData.append('ExecType', '2');

    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.PROCESS_TRACKING, formData).subscribe(result => {
      this.totalCount = result[0]['totalCount'];
      // alert(this.totalCount);
      if (this.totalCount == 0) {
        this.loadingMessage = "No Records found";
      }

      this.pageCount = (this.totalCount / this.pageSize);
      // alert(pageCount);
      if (this.pageCount <= 1) { this.pageCount = 1 }
      else if ((this.pageCount % 1) != 0)
        this.pageCount = Math.floor(this.pageCount) + 1;

      this.pageCount = Number(this.pageCount.toFixed());
      // alert(pageCount);
      this.item = [];
      this.nextDotShow = false;
      for (let i = 1; i <= this.pageCount; i++) {
        if (i <= 5) {
          this.item.push(i);
        }
        else {
          this.nextDotShow = true;
        }
      }
    },
      error => {
        this.totalCount = -1;
        this.loadingMessage = "No Records found";
        this.errorHandler.ErrorsHandler(error)
        // ErrorHandler.getInstance().ErrorsHandler(error);
      });
  }

  /** Method to get the data depending upon pagination  */
  Records(record: string) {
    // save record count in service 
    this.ShareData.setRecordCount(record);
    this.ShareData.setPageNo(1);
    this.pageStart = 0;
    this.selectedPageNo = 1;


    this.previousDotShow = false;
    this.nextDotShow = false;

    if (record != null) {
      if (record == 'all') {
        this.pageSize = this.totalCount;

        this.item = [];
        if (this.AdvanceFilterSql != undefined && this.AdvanceFilterSql != null && this.AdvanceFilterSql != '') {
          this.fillData(this.checkSearchValue(this.AdvanceFilterSql), this.pageStart, this.pageSize, '', 1);
          this.GetTotalCount(this.AdvanceFilterSql);
        } else {
          this.fillData(this.checkSearchValue(this.searchInput), this.pageStart, this.pageSize, '', 1);
          this.GetTotalCount(this.searchInput);
        }
      }
      else {

        this.pageSize = Number(record);
        this.item = [];
        if (this.AdvanceFilterSql != undefined && this.AdvanceFilterSql != null && this.AdvanceFilterSql != '') {
          this.fillData(this.checkSearchValue(this.AdvanceFilterSql), this.pageStart, this.pageSize, '', 1);
          this.GetTotalCount(this.AdvanceFilterSql);
        } else {
          this.fillData(this.checkSearchValue(this.searchInput), this.pageStart, this.pageSize, '', 1);
          this.GetTotalCount(this.searchInput);
        }
      }
    }
  }


  /** Method to handle the page change event */
  public PageChange(pageNumber: number) {
    //Save page no in service--
    this.ShareData.setPageNo(pageNumber);

    this.selectedPageNo = pageNumber;
    this.previousDotShow = false;
    this.nextDotShow = false;
    if (pageNumber === 0)
      pageNumber = 1;
    if (pageNumber > this.pageCount)
      pageNumber = this.pageCount;
    this.currentPage = pageNumber;
    if (pageNumber === this.item[this.item.length - 1] && this.pageCount > this.item[this.item.length - 1]) {
      this.item.shift();
      this.item.push(this.item[this.item.length - 1] + 1);
      this.previousDotShow = true;
      this.nextDotShow = true;
      if (this.item[this.item.length - 1] == this.pageCount)
        this.nextDotShow = false;
    }
    else if (pageNumber == this.pageCount) {
      this.item = [];
      if (this.pageCount > 5) {
        for (let j = this.pageCount - 4; j <= this.pageCount; j++) {
          this.item.push(j);
        }
        this.nextDotShow = false;
        this.previousDotShow = true;
      } else {
        for (let j = 1; j <= this.pageCount; j++) {
          this.item.push(j);
        }
      }
    }
    else if (pageNumber == this.item[0] && this.item[0] > 5) {
      this.previousDotShow = true;
      this.nextDotShow = true;
      this.item.pop();
      this.item.unshift(pageNumber - 1);
    }
    else if (pageNumber <= 5) {
      this.previousDotShow = false;
      if (this.pageCount > 5) {
        this.nextDotShow = true;
      }
      let x = this.item.length;
      this.item = [];
      for (let j = 1; j <= x; j++) {
        this.item.push(j);
      }
    } else {
      this.previousDotShow = true;
      // this.nextDotShow=true;
    }
    if (this.pageCount < 5) {
      this.nextDotShow = false;
      this.previousDotShow = false;
    }
    // this.GetAllRoles(pageNumber);

    if (pageNumber <= 1) {
      this.pageStart = 0;
    }
    else {
      this.pageStart = this.pageSize * (pageNumber - 1);
    }
    if (this.AdvanceFilterSql != undefined && this.AdvanceFilterSql != null && this.AdvanceFilterSql != '') {
      this.fillData(this.checkSearchValue(this.AdvanceFilterSql), this.pageStart, this.pageSize, '', 1);
    } else {
      this.fillData(this.checkSearchValue(this.searchInput), this.pageStart, this.pageSize, '', 1);
    }
  }

  convertToInt(val) {
    return parseInt(val);
  }
  CloseAccount(val) {
    if (!this.authorizedForCloseAccount) {
      this.alertService.error(AlertMessages.ALERTMESSAGE15);
      return;
    }
    if (val.statusID != 6 && val.statusID != 7) {
      this.alertService.error(AlertMessages.ALERTMESSAGE18);
      return;
    }
    this.popupService.Confirmation('Are you sure?', val['account Name'] + 'will be close', 'Yes, Close', 'No, Cancel').then(result => {
      if (result.value) {
        let postData = new FormData();
        postData.append("accountId", val['account ID'])
        this.processTrackingService.CloseAccount(AppSettings.API_ENDPOINT + AppRelativeUrl.CLOSEACCOUNT, postData)
          .subscribe(
            res => {
              if (res) {
                this.alertService.success(AlertMessages.ALERTMESSAGE14)
                this.ngOnInit();
              }
            },
            err => {
              this.errorHandler.ErrorsHandler(err);
            }
          )
      }
    })
  }


  ////Show Modal for account download
  public showDownloadAccount(obj: any) {
    
    this.DownloadshippemntAccountNmae = obj['account Name']
    this.downloadTypeForPackage = 'all';
    var isReadyforDownload=Boolean(obj['isDownloadFileExists'])
    //// return if account is not downloaded or shipped
    this.rowNoForDownload = obj['account ID']
    this.isDownloading = true;
    let statusId = String(obj['statusID']);
    if (statusId == undefined || statusId == null || statusId === '' || !(statusId == '6' || statusId == '7' || statusId == '11'||isReadyforDownload)
   ) {
      return;
    }
    /// adding properties to show in download
    this.downloadProperties = [obj['account Type'], obj['business Unit'], obj['work Type']];
    this.accountIDforShipmentDownload = String(obj['account ID']);
    let workType = Number(obj['workID']);
    let accountName = String(obj['account Name']);
    this.processTrackingService.GetAccounttypetoDownlod(obj['account ID']).subscribe(response => {
      this.accounTypeToDownload = response['table'];
      this.accountNameForShipmentDownload = accountName;
      if (this.accounTypeToDownload.length > 1) {
        this.ifOpenModalForAudit = true;
      }
      else {
        if (response['table'][0] == undefined) {
          this.alertService.error("File not found on server ");
        }
        else {
          //this.downloadShipmentData(response['table'][0]['type'], accountName)
          this.downloadShipmentPackage(response['table'][0]);
        }
      }
    },
      error => {
        this.errorHandler.ErrorsHandler(error);
      });
  }

  // Select the file to download
  selectFileToDownload(execType, item) {
    this.downloadTypeForPackage = execType;
    this.shipmentDownload = item;
  }

  selectPackageFile() {
    this.ifOpenModalForAudit = false;
    if (this.downloadTypeForPackage == 'all') {
      this.accounTypeToDownload.forEach(element => {
        this.downloadShipmentPackage(element);
      });
      // for (let i = 0; i < this.accounTypeToDownload.length; i++) {
      //   this.downloadShipmentData(this.accounTypeToDownload[i]['type'], this.accountNameForShipmentDownload)
      // }
    }
    else {
      this.downloadShipmentPackage(this.shipmentDownload);
      //this.downloadShipmentData(String(this.downloadTypeForPackage), this.accountNameForShipmentDownload);
    }
  }

  private downloadShipmentPackage(item: any) {
    this.ShareData.IfDownloadDivOpen(true);
    this.showDownload = true;
    //insert job
    let jobId: any;
    let formData1: FormData = new FormData();
    formData1.append('accountId', item['accountId']);
    formData1.append('jobId', '0');
    formData1.append('jobName', item['jobName']);
    formData1.append('jobTypeId', item['jobType']);
    formData1.append('errorMessage', '');
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEJOBFORDOWNLOAD, formData1).subscribe(res => {
      jobId = Number(res);
    }, err => {
      jobId = 0;
    })

    //create job on ui
    var objPacket = new DownloadShipmentModel();
    objPacket.AccountName = this.accountNameForShipmentDownload;
    objPacket.Message = "In queue";
    objPacket.Progress = 0;
    objPacket.AccountType = this.downloadProperties[0];
    objPacket.BusinessUnit = this.downloadProperties[1];
    objPacket.WorkType = this.downloadProperties[2];
    objPacket.JobType = item['jobTypeName'];
    objPacket.FileName = item['fileName'];
    this.downloadFileProgress.push(objPacket);

    let formData: FormData = new FormData();
    formData.append('fileName', item['filePath']);
    this.processTrackingService.downloadFileWithProgress(formData, AppRelativeUrl.DOWNLOADACCOUNTFILE).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            this.isDownloading = true;
            if (objPacket.Message != "Downloading...")
              objPacket.Message = "Downloading...";
            objPacket.Progress = Math.round((data.loaded / data.total) * 100);;
            break;
          case HttpEventType.Response:
            this.ShareData.SetDownloadCount(this.downloadFileProgress.filter(x => x.Progress == 100).length);
            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = item['fileName'];
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            this.percentDownload = 0;
            this.isDownloading = false;
            this.downloadDone = true;

            let postData = new FormData();
            postData.append('accountId', String(item['accountId']));
            postData.append('statusId', String(7));
            this.UpdateJobOnDownload(item['accountId'], jobId, item['jobName'], item['jobType'], '');

            this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.CHANGEACCOUNTSTATUS, postData).subscribe();
            objPacket.Message = "File downloaded";
            
            break;
        }
      },
      error => {
        this.isDownloading = false;
        this.downloadDone = false;
        objPacket.Progress = '0.1';
        let options = {
          autoClose: false,
          keepAfterRouteChange: false
        };
        objPacket.Message = "File not available";
        this.alertService.error(this.accountNameForShipmentDownload + ' is not available to download', options);
        this.UpdateJobOnDownload(item['accountId'], jobId, item['jobName'], item['jobType'], this.accountNameForShipmentDownload + ' is not available to download');
      }
    );
  }

  private downloadShipmentData(execType: string, accountName: string) {
    let formData: FormData = new FormData();
    formData.append('accountId', this.accountIDforShipmentDownload);
    formData.append('execType', execType);
    this.isDownloading = true;
    this.callServiceForDownload(formData, AppRelativeUrl.GETPACKAGEFILE, false, accountName);
  }



  DownloadExcel() {
    let search: string;
    if (this.AdvanceFilterSql != undefined && this.AdvanceFilterSql != null && this.AdvanceFilterSql != '') {
      search = this.AdvanceFilterSql;
    }
    else
      search =this.searchInput;
    this.processTrackingService.ExportData(AppSettings.API_ENDPOINT + AppRelativeUrl.PROCESS_TRACKING, String(this.totalCount), search, this.prevSort).subscribe
      (
        result => {
          let data: any[] = result;
          let header = [];
          header = header.concat(Object.keys(data[0]));
          let pillValue: any[];
          this.ShareData.advanceFilterPill$.subscribe(filter => pillValue = filter);
          this.exportFile.generateExcel(header, data, "ProcessTracking List", pillValue);
        })
  }
  DownloadCSV() {
    let search: string;
    if (this.AdvanceFilterSql != undefined && this.AdvanceFilterSql != null && this.AdvanceFilterSql != '') {
      search = this.AdvanceFilterSql;
    }
    else
      search = this.AdvanceFilterSql;
    this.processTrackingService.ExportData(AppSettings.API_ENDPOINT + AppRelativeUrl.PROCESS_TRACKING, String(this.totalCount), search, this.prevSort).subscribe
      (
        result => {
          let header = [];
          header = header.concat(Object.keys(result[0]));
          this.exportFile.expotAsCSVFile(result, header, 'ProcessTrackingList');
        }
      )
  }
  DownloadPDF() {
    let search: string;
    if (this.AdvanceFilterSql != undefined && this.AdvanceFilterSql != null && this.AdvanceFilterSql != '') {
      search = this.AdvanceFilterSql;
    }
    else
      search = this.AdvanceFilterSql;
    this.processTrackingService.ExportData(AppSettings.API_ENDPOINT + AppRelativeUrl.PROCESS_TRACKING, String(this.totalCount), search, this.prevSort).subscribe
      (
        result => {
          this.exportFile.generatePdf(result, 'ProcessTracking List')
        })
  }


  toggleAdvancedFilter() {
    if (this.showFilter)
      this.showFilter = false;
    else
      this.showFilter = true
  }

  handleResults($event) {
    this.searchInput = '';
    this.ShareData.shareProcessTrackingFilter('');
    this.AdvanceFilterSql = $event;
    //this.searchInput=$event;    
    this.ShareData.shareProcessTrackingAdvanceFilter(this.AdvanceFilterSql);
    this.fillData(this.AdvanceFilterSql, 0, this.pageSize, '', 1)
    this.GetTotalCount(this.AdvanceFilterSql);
  }

  sendDataToMessageCenter(value, viewThread: number) {
    // this.ShareData.shareProcessTrackingData(value['account ID'], value['account Name']);
    this.UploadShipmentPackage(value);
    let statusID = String(value['statusID']);
    if (statusID != null && statusID != undefined)
      statusID = statusID.trim();
    if (statusID === '8' || statusID === '9') {
      return;
    }
    else {
      if (viewThread == 1) {
        let redirectionfromDash = false;
        value['viewThread'] = viewThread;
        value['redirectionfromDash'] = redirectionfromDash
        this.processTrackingService.PassedData(value);
      }
      else
        this.processTrackingService.PassedData(value);
      // this.router.navigate(['messagecenter']);
      // this.ShareData.shareProcessTrackingAdvanceFilter(this.AdvanceFilterSql);
      this.router.navigate(['messagecenter']);
    }
  }


  getAdditionalfiletoDownload(item) {
    /// this only returns filename and account Name
    // this.showModalBox = false;
    // this.rowNoForDownload = accountid
    this.downloadFileId = 'downloadAll';
    let accountid = item['account ID'];
    /// adding properties to show in download
    this.downloadProperties = [item['account Type'], item['business Unit'], item['work Type']];
    this.isDownloading = false;
    this.processTrackingService.GetAdditonalAccount(accountid).subscribe(response => {

      this.hasAdditionalfile = response['tbl1'][0]['hasadditionalfiles'];

      if (this.hasAdditionalfile == 0) {
        // this.showModalBox = false;
        this.display = 'none';
        this.prepareSOV(response['tbl'][0]['accountId'], response['tbl'][0]['account Name']);
        this.sovDownload = [];
        this.aditionalfiles = [];
      }
      else {
        // this.showModalBox = true;
        this.display = 'block';
        this.sovDownload = response['tbl'];
        this.accountName = response['tbl'][0]['account Name'];
        this.aditionalfiles = response['table2'];
      }

    },
      error => {
        this.errorHandler.ErrorsHandler(error);
      });

  }
  closemodal() {
    // this.showModalBox = false
    this.display = 'none';
  }



  callDownloadForAdditonal(isAdditional, accountname, index) {
    this.processTrackingService.GetAdditonal(this.downloadFileId, isAdditional).subscribe(result => {
      if (!result || result === 'undefined' || result == null)
        return;
      let zipPath: string = JSON.stringify(result[0]['zipPath'])

      // alert(zipPath);
      //// call to download the file
      if (zipPath.trim() === '') {
        this.alertService.error('No file found.');
        return;
      }

      let formData: FormData = new FormData();
      formData.append('fileName', zipPath);

      this.tryDownload(formData, accountname, AppRelativeUrl.DOWNLOADZIPFILE, "AdditionalSOV" + index, 5, false);
      // this.callZipDownload(zipPath, AppRelativeUrl.DOWNLOADZIPFILE, accountname)

    },
      error => {

        this.alertService.error(error.error);

        this.tryDownload(null, accountname, AppRelativeUrl.DOWNLOADZIPFILE, "AdditionalSOV" + index, 5, true);

      });


  }


  downloadAccount() {
    this.display = 'none';
    let accountname: any;
    let isAdditional: boolean = false;
    if (this.downloadFileId == 'downloadAll') {
      this.prepareSOV(this.sovDownload[0]['accountId'], this.sovDownload[0]['account Name']);
      for (let i = 0; i < this.aditionalfiles.length; i++) {
        this.sovFileno = i + 1;
        this.downloadFileId = this.aditionalfiles[i]['additionalFileId'];
        accountname = this.accountName;
        isAdditional = true;
        this.callDownloadForAdditonal(isAdditional, accountname, (i + 1));
      }
    }
    else {

      this.accountIDforShipmentDownload = String(this.downloadFileId);

      this.accountIDforShipmentDownload = this.sovDownload[0]['accountId'];
      // for (let i = 0; i < this.allfilePath.length; i++) {

      if (this.sovFileno == 0) {
        accountname = this.accountName;
        this.prepareSOV(this.downloadFileId, accountname)
      }
      else {
        accountname = this.accountName;
        isAdditional = true;
        this.processTrackingService.GetAdditonal(this.downloadFileId, isAdditional).subscribe(result => {
          if (!result || result === 'undefined' || result == null)
            return;
          let zipPath: string = JSON.stringify(result[0]['zipPath'])

          // alert(zipPath);
          //// call to download the file
          if (zipPath.trim() === '') {
            this.alertService.error('No file found.');
            return;
          }

          let formData: FormData = new FormData();
          formData.append('fileName', zipPath);

          this.tryDownload(formData, accountname, AppRelativeUrl.DOWNLOADZIPFILE, "AdditionalSOV" + this.sovFileno, 5);
          // this.callZipDownload(zipPath, AppRelativeUrl.DOWNLOADZIPFILE, accountname)

        },
          error => {
            this.alertService.error(error.error);

            this.tryDownload(null, accountname, AppRelativeUrl.DOWNLOADZIPFILE, "AdditionalSOV" + this.sovFileno, 5, true);
          });
      }

    }


  }
  // else {

  //   this.processTrackingService.GetAdditonal(String(this.downloadFileId),true).subscribe(result => {
  //     if (!result || result === 'undefined' || result == null)
  //       return;
  //     let zipPath: string = JSON.stringify(result[0]['zipPath'])

  //     // alert(zipPath);
  //     //// call to download the file
  //     if (zipPath.trim() === '') {
  //       this.alertService.error('No file found.');
  //       return;
  //     }
  //     this.callZipDownload(zipPath, AppRelativeUrl.DOWNLOADZIPFILE, accountname)

  //   },
  //     error => {


  //     });
  // }
  //let accountname = "AdditionSOV"+this.sovFileno;


  //}
  //this.callZipDownload(filePath,AppRelativeUrl.DOWNLOADZIPFILE,accountname);
  //this.callServiceForDownload(accountid, accountid, true, String(accountname));
  // this.processTrackingService.GetAdditonal()

  // }

  // DownloadAll(event: any) {


  //   this.downloadALL = document.getElementsByClassName("custom-control-input");

  // }
  downloadFile(item: any, nameofFile: any) {
    this.sovFileno = nameofFile;

    if (item == 'downloadAll' && nameofFile == '') {
      this.downloadFileId = 'downloadAll';

    }
    else {
      if (this.sovFileno != 0)
        this.downloadFileId = item['additionalFileId'];
      else
        this.downloadFileId = item['accountId'];
    }


  }
  selectRow(trId: string) {
    this.ShareData.SelectedRowProcessTracking(trId)
    CommonMethod.selectRow(trId)
  }
  selectColumn(Col: string, header) {
    this.columSelected = Col;
    if (this.sortUP) {
      this.sortUp(header)
    }
    else {
      this.sortDown(header)
    }

  }
  CheckAuthorized() {
    this.Userdetail.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {

      let FeatureList = res["roleFeatureMapping"]
      this.authorizedForEdit = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Edit_Account);

      this.authorizedForDownload = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Download_SOV);
      this.authorizedForCancelAccount = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Cancel_Account);
      this.authorizedForUploadAdditionSOV = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Upload_Additional_SOV);
      this.authorizedForDownloadSOV = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Download_SOV);
      this.authorizedForCloseAccount = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Close_Account);
      this.authorizedForRaiseQuery = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.RaiseReply_Query);
      this.IsAuthorizedForAdditionalShipment = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Upload_Additional_Shipment);
      this.IsAuthorizeForDESShipment = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Upload_DES_Shipment);
      this.IsAuthorizeForRAShipment = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.Upload_RA_Shipment);
      this.authorizedForActivateAccount = this.obj.CheckFeatureAccess(FeatureList, FeatureMaster.ReActivate_Account);
      if (this.IsAuthorizeForRAShipment && !this.IsAuthorizeForDESShipment) {
        this.IsRAAdditionalShipment = true;
      } else {
        this.IsRAAdditionalShipment = false;
      }
      if (this.IsAuthorizeForDESShipment || this.IsAuthorizeForRAShipment) {
        this.authorizedForUploadShipment = true;
      } else {
        this.authorizedForUploadShipment = false;
      }
    })
  }

  UploadShipmentPackage(item: any) {
    this.ShareData.shareProcessTrackingData(item['account ID'], item['account Name']);
    //this.processTrackingService.PassedData(String(AccountId));
  }

  tryDownload(formData: FormData, accountName: string, serviceName: string, fileType: string, fileTypeNumber: number, ifErrorEntryOnly: boolean = false) {

    let fileName = accountName + ' - ' + fileType;
    var objPacket = new DownloadShipmentModel();
    objPacket.AccountName = accountName;

    this.ShareData.IfDownloadDivOpen(true);
    this.showDownload = true;

    objPacket.Message = "In queue";
    objPacket.Progress = 0;
    if (this.downloadProperties != undefined && this.downloadProperties != null) {
      objPacket.AccountType = this.downloadProperties[0];
      objPacket.BusinessUnit = this.downloadProperties[1];
      objPacket.WorkType = this.downloadProperties[2];

      // this.downloadProperties = null;
    }
    let accountId = this.accountIDforShipmentDownload;
    let jobId: number = 0;
    let jobTypeId: number = 0;

    let fileTypeLower = fileType.toLowerCase().trim();


    jobTypeId = fileTypeNumber
    if (fileTypeNumber == 4) {
      if (fileTypeLower === 'cleansing' || fileTypeLower === 'des') {
        fileName = accountName + '_DES';
        objPacket.JobType = 'DES Download Shipment';
      }
      else {
        fileName = accountName + '_RA';
        objPacket.JobType = 'RA Download Shipment';
        jobTypeId = 7;
      }
    }
    else
      objPacket.JobType = fileType;

    objPacket.FileName = fileName;
    // if (fileTypeNumber == 2) // sov
    //    2;

    // else if (fileTypeNumber == 5) // additional sov
    //   jobTypeId = 5;
    // else if (fileTypeNumber == 4)  // shipment package
    //   jobTypeId = 4;


    let errorMsg = '';
    if (ifErrorEntryOnly) {
      this.isDownloading = false;
      this.downloadDone = false;
      //this.errorHandler.ErrorsHandler(error);
      objPacket.Progress = '0.1';
      errorMsg = 'File not found.'
    }


    let formData1: FormData = new FormData();
    formData1.append('accountId', accountId);
    formData1.append('jobId', String(jobId));
    formData1.append('jobName', fileName);
    formData1.append('jobTypeId', String(jobTypeId));
    formData1.append('errorMessage', errorMsg);



    //console.log('acc Name - '  + accountId ,  jobId, fileName, jobTypeId, errorMsg);

    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEJOBFORDOWNLOAD, formData1).subscribe(res => {
      // return Number(res);
      jobId = Number(res);
    }, err => {
      jobId = 0;
    })


    // jobId =Number( this.UpdateJobOnDownload(accountId, jobId, fileName, jobTypeId, ''));

    // if (ifErrorEntryOnly) {
    //   this.isDownloading = false;
    //   this.downloadDone = false;
    //   //this.errorHandler.ErrorsHandler(error);
    //   objPacket.Progress = '0.1';

    // }


    if (this.downloadFileProgress == null || this.downloadFileProgress == undefined) {
      // alert(1);
      // this.showDownload = false
      this.subscribeToShareData();
    }
    this.downloadFileProgress.push(objPacket);


    this.ShareData.downloadShipmentProcess(this.downloadFileProgress);

    document.getElementById('target').focus();

    this.processTrackingService.downloadFileWithProgress(formData, serviceName).subscribe(
      data => {
        // let setRowNumber = this.rowNoForDownload;



        switch (data.type) {
          case HttpEventType.DownloadProgress:
            this.isDownloading = true;
            // this.rowNoForDownload = setRowNumber;
            // this.percentDownload = Math.round((data.loaded / data.total) * 100);

            if (objPacket.Message != "Downloading...")
              objPacket.Message = "Downloading...";
            objPacket.Progress = Math.round((data.loaded / data.total) * 100);;
            // var objPacket = new DownloadShipmentModel();
            // objPacket.AccountName = accountName;
            // objPacket.Message = "Downloading...";
            // objPacket.Progress = this.percentDownload;

            // this.downloadFileProgress.push(objPacket);


            // this.alertService.info('Download file has started');
            // this.downloadStatus.emit( {status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / data.total) * 100)});
            break;
          case HttpEventType.Response:
            // this.downloadStatus.emit( {status: ProgressStatusEnum.COMPLETE});

            // this.rowNoForDownload = setRowNumber;

            // objPacket.Progress = 100;

            // this.ShareData.downloadShipmentProcess(this.downloadFileProgress);

            this.ShareData.SetDownloadCount(this.downloadFileProgress.filter(x => x.Progress == 100).length);

            const downloadedFile = new Blob([data.body], { type: data.body.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);

            this.percentDownload = 0;
            this.isDownloading = false;
            this.downloadDone = true;


            let postData = new FormData();
            postData.append('accountId', String(accountId));
            postData.append('statusId', String(7));
            // this.updateAccountStatus()

            this.UpdateJobOnDownload(accountId, jobId, fileName, jobTypeId, '');

            if (objPacket.JobType == 'DES Download Shipment' || objPacket.JobType == 'RA Download Shipment')
              this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.CHANGEACCOUNTSTATUS, postData).subscribe();
            objPacket.Message = "File downloaded";
            break;
        }
      },
      error => {

        this.isDownloading = false;
        this.downloadDone = false;
        //this.errorHandler.ErrorsHandler(error);
        objPacket.Progress = '0.1';
        let options = {
          autoClose: false,
          keepAfterRouteChange: false
        };
        objPacket.Message = "File not available";
        this.alertService.error(accountName + ' is not available to download', options);
        this.UpdateJobOnDownload(accountId, jobId, fileName, jobTypeId, accountName + ' is not available to download');
      }
    );
  }



  subscribeToShareData() {
    this.dataDownloadShipmentList = this.ShareData.currentDownload$.subscribe(processes => {
      this.downloadFileProgress = processes;
      // if (this.downloadTypeForPackage != null && this.downloadFileProgress.length > 0)
      //   this.showDownload = true;
      // else
      //   this.showDownload = false;
    });
  }

  closeMultiDownload() {
    this.ifOpenModalForAudit = false;
  }
  instructionsPopup(item: any) {
    this.completeInstructions = item['complete Instructions']
    String(this.completeInstructions).replace('/n', '<br>');
    if (this.completeInstructions.length > 15) {
      this.dispayForinstructionPopup = 'block';
      this.instructionsHeader = "Complete Instructions";
    }
    else {
      this.dispayForinstructionPopup = 'none';
    }


  }
  commentPopUP(item: any) {

    this.completeInstructions = item['complete Comments'];
    if (this.completeInstructions.length > 15) {
      this.dispayForinstructionPopup = 'block';
      this.instructionsHeader = "Complete Comments";
    }
    else {
      this.dispayForinstructionPopup = 'none';
    }

  }
  fileNamePopUp(item: any) {
    this.completeInstructions = item['file Name'];
    if (this.completeInstructions.length > 30) {
      this.dispayForinstructionPopup = 'block';
      this.instructionsHeader = "File Name";
    }
    else {
      this.dispayForinstructionPopup = 'none';
    }
  }
  closeModal() {
    this.dispayForinstructionPopup = 'none';
  }

  checkSearch() {
    this.showSearchValidationError = this.obj.validateSearch(this.searchInput);
  }


  closeDownloadProgress() {

    this.ShareData.IfDownloadDivOpen(false);
    this.ShareData.downloadShipmentProcess([]);
    // this.ShareData.ifDownloadDivOpen$.subscribe(ifOPen => alert(ifOPen));
    this.showDownload = false;

  }


  UpdateJobOnDownload(accountId: string, jobId: number, jobName: string, jobTypeId: number, errorMessage: string): any {

    let formData: FormData = new FormData();
    formData.append('accountId', accountId);
    formData.append('jobId', String(jobId));
    formData.append('jobName', jobName);
    formData.append('jobTypeId', String(jobTypeId));
    formData.append('errorMessage', errorMessage);

    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEJOBFORDOWNLOAD, formData).subscribe(res => {
      return Number(res);
    }, err => {
      return 0;
    })

  }
  ReActivateAccount(item){
    if (!this.authorizedForActivateAccount) {
      this.alertService.error(AlertMessages.ALERTMESSAGE15);
      return;
    }
    if (!(item.statusID==9 || item.statusID==8)) {
      this.alertService.error(AlertMessages.ALERTMESSAGE21);
      return;
    }
    this.popupService.Confirmation('Are you sure?', item['account Name'] + ' will be re-activate', 'Yes, Reactivate', 'No, Cancel').then(result => {
      if (result.value) {
        let postData = new FormData();
        postData.append("accountId", item['account ID'])
        this.processTrackingService.ActivateAccount(AppSettings.API_ENDPOINT + AppRelativeUrl.ACTIVATEACCOUNT, postData)
          .subscribe(
            res => {
              if (res) {
                this.alertService.success(AlertMessages.ALERTMESSAGE20)
                this.ngOnInit();
              }
            },
            err => {
              this.errorHandler.ErrorsHandler(err);
            }
          )
      }
    })
  }

}

