import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-latest-users',
  templateUrl: './latest-users.component.html',
  styleUrls: ['./latest-users.component.css']
})
export class LatestUsersComponent implements OnInit {
  @Input() username: any;
  @Input() View_User:boolean;
  constructor() { }

  ngOnInit() {
  }

}
