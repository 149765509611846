import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { GetUserClientInfo } from 'src/model/AccessManagement/GetUserClientInfo';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { AlertService } from 'src/services/Common/alert.service';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertMessages } from 'src/common/alert-messages';
import { Router } from '@angular/router';
import { FeatureMaster } from 'src/common/feature-master';
import { CommonMethod } from 'src/common/CommonMethod';

@Component({
  selector: 'app-notification-user',
  templateUrl: './notification-user.component.html',
  styleUrls: ['./notification-user.component.css']
})
export class NotificationUserComponent implements OnInit, OnChanges {
  // export class NotificationUserComponent implements OnInit {
  @Input() userDetail: any;
  @Output() cancel = new EventEmitter();
  formData: UntypedFormGroup;
  notificationList: [];
  checkedNofification: boolean;
  isAlertOnAllAccount: boolean;
  ListNotification: any[] = [];
  radioCheck = false;
  errorHandler = new ErrorHandler(this.alertService);
  notificationForm: UntypedFormGroup;
  getParameter: any;
  accounttypeList: any;
  businesstypeList: any;
  allowaddeditAction: boolean;
  userId: number;
  obj: CommonMethod = new CommonMethod();
  previousUrl: string;
  selectedaccountType: string[] = [];
  selectedBusinessUnit: string[] = [];
  constructor(private addedituserService: AddEditUserSerivceService,
    private formBuilder: UntypedFormBuilder, private alertService: AlertService, private route: Router
  ) { this.previousUrl = this.route.routerState.snapshot.url; }
  ngOnInit() {
    this.notificationForm = this.formBuilder.group({
      radioCheck: new UntypedFormControl(),
      BusinessType: new UntypedFormControl(),
      AccountType: new UntypedFormControl(),
      checklist: new UntypedFormArray([])
    })
    this.CheckAuthorization();
  }
  ngOnChanges() {
    this.selectedaccountType = [];
    this.selectedBusinessUnit = [];
    this.GetUserNotification();
  }
  GetUserNotification() {

    let postData = new FormData();
    postData.append("UserID", String(this.userDetail.userID));
    this.addedituserService.GetNotificationList(AppSettings.API_ENDPOINT + AppRelativeUrl.GETNOTIFICATIONLIST, postData)
      .subscribe(res => {
        this.notificationList = res['tblUserCheckNotification'];
        this.checkedNofification = res['tblUserAlert'][0].alert;
        this.isAlertOnAllAccount = res['tblUserAlert'][0].alertOnAccount;
        this.accounttypeList = res['tblUserAccountType'];
        this.businesstypeList = res['tblUserBusinessType'];

        for (let i = 0; i < res['tblselectedAccountType'].length; i++) {
          this.selectedaccountType.push(res['tblselectedAccountType'][i].accountTypeId)
        }
        for (let i = 0; i < res['tblselectedBusinessUnit'].length; i++) {
          this.selectedBusinessUnit.push(res['tblselectedBusinessUnit'][i].businessUnitId)
        }
        this.notificationForm.patchValue({
          AccountType: this.selectedaccountType,
          BusinessType: this.selectedBusinessUnit
        })
        if (this.checkedNofification) {
          this.radioCheck = true;
        }
        this.ListNotification = [];
        while ((this.notificationForm.controls.checklist as UntypedFormArray).length !== 0) {
          (this.notificationForm.controls.checklist as UntypedFormArray).removeAt(0)
        }
        for (let i = 0; i < this.notificationList.length; i++) {
          this.ListNotification.push({
            id: this.notificationList[i]["notificationID"]
            , Desc: this.notificationList[i]['notificationDescription']
          })
          let control;
          if (Number(this.notificationList[i]['isEnable']) == 1) {
            control = new UntypedFormControl(1);
          } else {
            control = new UntypedFormControl(0);
          }
          (this.notificationForm.controls.checklist as UntypedFormArray).push(control);

        }
      },
        err => {
          this.errorHandler.ErrorsHandler(err);
        }
      );
  }
  SubmitNotification() {
    if (this.allowaddeditAction || this.userDetail.userID == this.userId) {
      let updateNotification = this.notificationForm.controls['checklist'].value
        .map((v, i) => v ? this.ListNotification[i].id : null)
        .filter(v => v !== null);
      // console.log(this.notificationForm.value.radioCheck);
      let postData = new FormData();
      postData.append('userId', String(this.userDetail.userID))
      postData.append('notificationId', String(updateNotification));
      postData.append('alert', String(this.radioCheck));
      postData.append('alertOnAccount', String(this.isAlertOnAllAccount));
      if (this.isAlertOnAllAccount) {
        postData.append('alertAccountId', String(this.selectedaccountType));
        postData.append('alertBusinessTypeId', String(this.selectedBusinessUnit));
      }
      else {
        postData.append('alertAccountId', '');
        postData.append('alertBusinessTypeId', '');
      }

      this.addedituserService.UpdateUserNotification(AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEUSERNOTIFICATION, postData).subscribe(res => {

        if (res) {
          this.alertService.success(AlertMessages.ALERTMESSAGE5)
          this.Cancel()
        }
      }, err => {
        this.errorHandler.ErrorsHandler(err);
      })
    }
    else {
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
    }
  }
  RadioChecked(value: boolean) {
    this.radioCheck = !this.radioCheck;
  }
  Cancel() {
    this.cancel.emit(0);
  }

  UploadByMe() {
    this.isAlertOnAllAccount = !this.isAlertOnAllAccount;

  }
  CheckAuthorization() {
    this.addedituserService.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      if (res != null && res != "undefined") {
        // this.allowactivateAction = this.obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.Deactivate_User);
        this.allowaddeditAction = this.obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.AddEdit_User);
        this.userId = res['userDetails'][0]['userID'];
      }
    }
      , err => {
        this.errorHandler.ErrorsHandler(err);
      })

  }

}
