import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserRoutingModule } from './user-routing.module';
import { ViewUserComponent } from './view-user/view-user.component'
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditUserComponent } from './edit-user/edit-user.component';
import { SettingUserComponent } from './setting-user/setting-user.component';
import { NotificationUserComponent } from './notification-user/notification-user.component';
import { ProfileUserComponent } from './profile-user/profile-user.component';

@NgModule({
  declarations: [
      ViewUserComponent
    , EditUserComponent,
    SettingUserComponent,
    NotificationUserComponent,
    ProfileUserComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,    
    NgSelectModule,
    FormsModule
  ]
})
export class UserModule { }
