<section class="content">
    <div class="container-fluid">
        <!-- SELECT2 EXAMPLE -->
        <div class="card card-default">
            <div class="card-header">
                <h3 class="card-title" style="color: rgb(0, 123, 151);">Business Unit Details</h3>
                <div class="card-tools">
                    <div class="input-group input-group-sm" style="width: 180px;">
                        <input type="text" [(ngModel)]="searchInput" (keydown.enter)="SearchItem()" name="table_search"
                            class="form-control float-" placeholder="Search">
                        <div class="input-group-append">
                            <button type="button" (click)=SearchItem() class="RMSSearchButton">
                                <i class="fas fa-search RMSSearchButtonIcon"></i>
                            </button>
                            <button class="RMSSearchButton" style="margin-left: 1px;" type="submit"
                                data-toggle="tooltip" data-placement="top" title="Reset" id="basic-text1"
                                (click)="resetRecords()">
                                <i class="fas fa-history text-grey" style="color: #ffffff;" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>

                    <div class="col-lg-12 ErrorMessage NoPadding" *ngIf="showSearchValidationError"
                        style="padding-top:10px; color: #c42032; font-size: 0.8rem;">
                        Special characters are not allowed
                    </div>

                </div>
                <div class="card-tools" style="margin-left:20px;">
                    <div class="input-group input-group-sm" style="width: 250px;">
                        <button type="button" class="btn btn-default btn-block btn-sm" data-toggle="collapse"
                            title="Add New Business Unit" data-target="#collapseExample" aria-expanded="false"
                            aria-controls="collapseExample" style="width: 200px;" (click)="AddAccountType()">Add
                            BusinessUnit</button>
                        <div class="input-group-append">
                            <button type="submit" class="btn btn-default btn-block btn-sm" data-toggle="collapse"
                                data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                                style="background-color:rgb(196, 32, 50)"><i
                                    class="fas fa-plus RMSSearchButtonIcon"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="content">
    <form *ngIf="showForm" [formGroup]="_formBusinessUnit">
        <div style="border:solid rgba(204, 203, 206, 0.473); border-radius: 5px;">
            <div class="container">
                <div style="margin-top: 15px;" class="row">
                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm row">
                            <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                for="selectbasic">Business Unit Name<label class='required'>*</label></label>
                            <div class="col-md-4">

                                <input type='text' placeholder="Business Unit Name" formControlName="name"
                                    class="form-control form-control-sm">
                                <div class="col-lg-12 ErrorMessage NoPadding"
                                    style="font-size: small;color: #c42032; height: 1rem !important;">
                                    <div
                                        *ngIf="GetFormControls.name.errors && checkerror || GetFormControls.name.touched && GetFormControls.name.invalid">
                                        <div *ngIf="GetFormControls.name.errors.required">
                                            Business Unit Name is required
                                        </div>
                                        <div *ngIf="GetFormControls.name.errors.maxlength">
                                            Only 50 characters are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm  row">
                            <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                for="selectbasic">Description<label class='required'>*</label></label>
                            <div class="col-md-4">
                                <input formControlName="description" placeholder="Description"
                                    class="form-control form-control-sm" type="text">
                                <div class="col-lg-12 ErrorMessage NoPadding"
                                    style="font-size: small;color: #c42032; height: 1rem !important;">
                                    <div
                                        *ngIf="GetFormControls.description.errors && checkerror || GetFormControls.description.touched && GetFormControls.description.invalid">
                                        <div *ngIf="GetFormControls.description.errors.required">
                                            Description is required
                                        </div>
                                        <div *ngIf="GetFormControls.description.errors.maxlength">
                                            Only 50 characters are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
                <div style="margin-bottom: 10px;" class="row">
                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm row">
                            <label class="col-md-4 " style="font-size: small; font-weight: normal;" for="selectbasic">
                                Requested By<label class='required'>*</label></label>
                            <div class="col-md-4">
                                <!-- <select formControlName="requestedby" class="form-control form-control-sm">
                                    <option value=true>Yes</option>
                                    <option value=false>No</option>
                                </select> -->
                                <input formControlName="requestedby" placeholder="Description"
                                    class="form-control form-control-sm" type="text">
                                <div class="col-lg-12 ErrorMessage NoPadding"
                                    style="font-size: small;color: #c42032; height: 1rem !important;">
                                    <div
                                        *ngIf="GetFormControls.requestedby.errors && checkerror || GetFormControls.requestedby.touched && GetFormControls.requestedby.invalid">
                                        <div *ngIf="GetFormControls.requestedby.errors.required">
                                            Requested by is required
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm row">
                            <label class="col-md-4 " style="font-size: small; font-weight: normal;" for="selectbasic">
                                Comment<label class='required'>*</label></label>
                            <div class="col-md-4">
                                <textarea formControlName="comment" placeholder="Comment"
                                    class="form-control form-control-sm"></textarea>
                                <div class="col-lg-12 ErrorMessage NoPadding"
                                    style="font-size: small;color: #c42032; height: 1rem !important;">
                                    <div
                                        *ngIf="GetFormControls.comment.errors && checkerror || GetFormControls.comment.touched && GetFormControls.comment.invalid">
                                        <div *ngIf="GetFormControls.comment.errors.required">
                                            Comment is required
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8 row" style="margin-left: 14px;
            margin-bottom: 14px;">
                <button id="singlebutton" (click)="Add()" name="singlebutton"
                    class="btn btn-primary RMSSubmitButton">{{buttonTittle}} </button>
                <button name="submit" type="submit" style="margin-left: 10px;" class="btn btn-primary RMSButtonCancel"
                    (click)="close()">
                    Cancel
                </button>

            </div>

        </div>




    </form>

    <div style="border:solid rgba(204, 203, 206, 0.473); border-radius: 5px;margin-top: 10px;">
        <div class="container" style="margin-top: 10px;">
            <div class="container ">
            </div>

            <div class="table-responsive text-nowrap">
                <div *ngIf="tblBusinessUnit.length==0" style="font-weight: bold;">No Record found</div>
                <table *ngIf="tblBusinessUnit.length>0" id="tblAccountBUMap" style="width: 100%;margin-bottom: 5px;"
                    class="table-hover table-bordered font14 noPad">
                    <thead>
                        <tr class="th-sm RMSTableHeader">
                            <th class="text-center" style="width: 141px;">Actions</th>
                            <th class="text-center" *ngFor="let headerItem of dynmaicFieldHeader | slice:2">
                                {{headerItem | titlecase}}
                            </th>
                            <!-- <th class="text-center" style="width: 200px;">Description</th>
                        <th class="text-center" style="width: 200px;">Status</th> -->
                        </tr>
                    </thead>
                    <tbody class="RMSTableContent">
                        <tr class="RmsTableRow" *ngFor="let item of tblBusinessUnit; let i = index">

                            <td class="text-center icon">
                                <button class="btn btn-tool" type="button">
                                    <i class="fa fa-edit" title="Edit Business Unit" (click)="EditBusinessUnit(item)"
                                        style="color:rgb(0, 123, 151)"></i>
                                </button>
                                <button *ngIf="item.isActive == true;else deactive" data-toggle="modal"
                                    data-target="#Modal" class="btn btn-icon-toggle" type="button">
                                    <i class="fa fa-trash" title="Deactivate BusinessUnit"
                                        (click)="DeleteBusinessUnit(item)" style="color:rgb(196, 32, 50)"></i>
                                </button>
                                <ng-template #deactive>
                                    <button class="btn btn-icon-toggle" data-toggle="modal" data-target="#Modal"
                                        type="button">
                                        <i class="fa fa-trash" title="Activate BusinessUnit"
                                            (click)="DeleteBusinessUnit(item)" style="color:rgb(107, 106, 106)"></i>
                                    </button>
                                </ng-template>
                            </td>
                            <td class="text-center " *ngFor="let header of dynmaicFieldHeader|slice:2">
                                <span *ngIf="header!='isActive' && header!='created Date' && header!='modified Date'">
                                    {{item[header] | slice:0:30}}
                                </span>
                                <span *ngIf="header=='created Date' ">
                                    {{item[header] | date: 'dd-MMM-yyyy'}}
                                </span>
                                <span *ngIf="header=='modified Date' ">
                                    {{item[header]| date:'dd-MMM-YYYY'}} </span>

                            </td>
                            <!-- <td class="text-center ">{{item.name}}</td>
                        <td class="text-center icon">{{item.description}}</td>
                        <td class="text-center icon">
                            <i *ngIf="item.isActive == true;else deactive" class="fa fa-check"
                                style="color:green;;"></i>

                            <ng-template #deactive>
                                <i class="fa fa-times" style="color:red;"></i>
                            </ng-template>
                        </td> -->
                        </tr>
                    </tbody>
                </table>

            </div>


        </div>
    </div>
</section>

<div class="modal fade" data-backdrop="static" data-keyboard="false" backdrop="static" id="Modal" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 700px;">
        <div class="modal-content">
            <div class="modal-header">
                <h6 style="color: #007b97;" class="modal-title">{{statusButtonTittle}} Business Unit :
                    <span *ngIf="BusinessUnitName!=null"> {{BusinessUnitName}}</span>
                </h6>
                <button type="button" #closebutton (click)="CloseModel()" class="close"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body ">
                <form [formGroup]='_formdeativateBu' (ngSubmit)="ActivateDeativateBusinessUnit()">
                    <div class='col-lg-12'>
                        <div class="row">
                            <label style="font-size: small;">Comment<span style="color: red;">*</span></label>

                            <!-- <div class="col-lg-12"> -->
                            <textarea maxlength="501" (ngModelChange)="ShowPasswordError()"
                                formControlName="reasoncomment" placeholder="Enter Comment"
                                class="form-control form-control-sm"></textarea>
                            <div class="ErrorMessage NoPadding" style="color: #c42032;">
                                <div *ngIf="ModelGetFormControls.reasoncomment.errors && checkModelError">
                                    <div *ngIf="ModelGetFormControls.reasoncomment.errors.required"> Comment is required
                                    </div>
                                    <div *ngIf="ModelGetFormControls.reasoncomment.errors.maxLength">Allow only 500
                                        characters</div>
                                </div>
                            </div>
                            <!-- </div> -->
                            <div class="col-lg-6">

                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">

                        <button type="submit" class=" btn btn-secondary RMSSubmitButton">{{statusButtonTittle}}</button>
                        <button type="button" (click)="CloseModel()" class="btn btn-secondary" data-dismiss="modal">
                            Close
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>