import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
// import { MatTableDataSource, MatPaginator } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/common/AppSettings';
import { AlertService } from 'src/services/Common/alert.service';
import { ErrorHandler } from 'src/common/error-handler';
import { DashboardServiceService } from 'src/services/DashboardService/dashboardService.service';
import { CommonMethod } from 'src/common/CommonMethod';
import { MenuList } from 'src/common/menuList.enum';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login-test',
  templateUrl: './login-test.component.html',
  styleUrls: ['./login-test.component.css']
})
export class LoginTestComponent implements OnInit {

  description: any;
  menudetailforupload: any;
  errorHandler = new ErrorHandler(this.alertService);
  obj: CommonMethod = new CommonMethod();
  constructor(private getHomeData: DashboardServiceService,
    private alertService: AlertService, private router: Router, private cookieService: CookieService) {
    // this.obj.checkAccess(MenuList.Dashboard);
  }

  ngOnInit() {
    
    this.getData();

  }
  getData() {
    let formData: FormData = new FormData();
    this.getHomeData.getHomeData(formData).subscribe(
      result => {
        this.description = result['tblhomedData'];
        this.menudetailforupload = result['table1'];
      },
      error => {
        this.errorHandler.ErrorsHandler(error);

      }
    )

  }
  readMore(item: any) {
    document.getElementById('div' + item).scrollIntoView();
  }

}
