import { AlertService } from 'src/services/Common/alert.service';
import { AlertMessages } from '../common/alert-messages';

export class ErrorHandler {
  constructor(private alertService: AlertService) {

  }
  public ErrorsHandler(error: any) {

    let divsToHide = document.getElementsByClassName("alertContainer") as HTMLCollectionOf<HTMLElement>; //divsToHide is an array
    for(let i = 0; i < divsToHide.length; i++){   
        divsToHide[i].style.display = "none"; 
    }


    let errorCode = error.error['ErrorCode'];
    let errorMsg = '';
    if (errorCode != null || errorCode != undefined)
      errorMsg = '. (<b>Error Code:</b> ' + error.error['ErrorCode'] + ')';

    let options = {
      autoClose: false,
      keepAfterRouteChange: false
    };

    switch (error.status) {
      case 0:
        this.alertService.error(AlertMessages.ALERTMESSAGE1 + errorMsg, options)
        break;
      case 400:
        this.alertService.error( (error.error.title == undefined ? '' : error.error.title) +
        (error.error.ErrorMessage == undefined ? '' : error.error.ErrorMessage)
        + errorMsg, options)
        break;
      case 403:
        this.alertService.error(AlertMessages.ALERTMESSAGE15 + errorMsg, options);
        break;
      case 404:
        this.alertService.error(AlertMessages.ALERTMESSAGE2 + errorMsg, options);
        break;
      case 401:
        this.alertService.error(AlertMessages.ALERTMESSAGE3 + errorMsg, options)
        break;
      case 500:
        this.alertService.error((error.error['ErrorMessage'] == null || error.error['ErrorMessage'] == undefined ? AlertMessages.ALERTMESSAGE6 : error.error['ErrorMessage']) + errorMsg, options);
        break;
      case 417:
        this.alertService.error(AlertMessages.ALERTMESSAGE2 + errorMsg, options);
        break;
      case 409:
        this.alertService.error(AlertMessages.ALERTMESSAGE12 + errorMsg, options);
        break;
      default:
        this.alertService.error(AlertMessages.ALERTMESSAGE2 + errorMsg, options);
        break;
    }
  }


}
