export class UploadAccountModel {
    AccountName: string;
    AccountType: string;
    WorkType: string;
    Priority: string;
    BusinessUnit: string;
    NoOfLocs: string;
    SumInsured: string;
    Underwriter: string;
    Progress: any;
    Message: string;
    JobId: string;
    status: number;//1-complete,2-uploading,3-error
    // IsUploading:boolean;
}