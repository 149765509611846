import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { RoleManagementServiceService } from 'src/services/AccessManagement/role-management-service.service';
import { AlertService } from 'src/services/Common/alert.service'
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';

@Component({
  selector: 'app-add-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css']
})

export class EditRoleComponent implements OnInit, OnChanges {
  // masterName: string;
  AddRoleForm: UntypedFormGroup;
  @Input() modalTitle: string;
  @Input() editData: [];
  @Input() IsNewRole: boolean;
  FeatureListData = [];
  MenuListData = [];
  editDataVal: string[] = [];
  AddedRolesList: Array<string>;
  //NotificationMsg: string;
  IsMsgVisible: boolean;
  @Output() public closeAddRole = new EventEmitter();
  @Output() public RefreshGridData = new EventEmitter();


  //#region constructor
  constructor(private formBuilder: UntypedFormBuilder, private cookieService: CookieService,
    private service: RoleManagementServiceService, private alertService: AlertService,
    private userInfo: AddEditUserSerivceService
  ) {
    this.AddRoleForm = this.formBuilder.group({
      RoleName: ['', Validators.compose([Validators.required])],
      FeatureList: new UntypedFormArray([])
    });
  }
  //#endregion

  //#region Methods

  /** Method to handle on change event */
  ngOnChanges(changes: SimpleChanges): void {

    while ((this.AddRoleForm.controls.FeatureList as UntypedFormArray).length !== 0) {
      (this.AddRoleForm.controls.FeatureList as UntypedFormArray).removeAt(0)
    }
    this.AddRoleForm.controls.RoleName.patchValue("");
    this.getFeatureList();
    if (!this.IsNewRole) {
      this.editDataVal = Object.values(this.editData);
      this.AddRoleForm.controls.RoleName.patchValue(this.editDataVal[0]["role Name"]);
      let t = Object.entries(this.editDataVal[0]).slice(3);
      for (let i = 0; i < t.length; i++) {
        let x = t[i];
        this.FeatureListData.push({ id: i, featureName: x[0] });
        let control;
        if (x[1].toString() === "1") {
          control = new UntypedFormControl(1);
        } else {
          control = new UntypedFormControl(0);
        }
        (this.AddRoleForm.controls.FeatureList as UntypedFormArray).push(control);
      }

    }
    // else {
    //   this.AddRoleForm.controls.RoleName.patchValue("");
    //   this.getFeatureList();
    // }
  }

  ngOnInit() {
    // console.log(this.editData);

  }

  /** Method to add map Feature UI*/
  private AddFeaturesUI() {
    //this.AddRoleForm.controls.FeatureList = new FormArray([]);
    this.FeatureListData.forEach((o, i) => {
      const control = new UntypedFormControl();
      (this.AddRoleForm.controls.FeatureList as UntypedFormArray).push(control);
    });
  }

  /**Method to get the list of features */
  getFeatureList() {
    this.service.PostRequest(AppSettings.API_ENDPOINT + AppRelativeUrl.GETFEATURELIST, "").subscribe(response => {

      // console.log(response);    
      this.FeatureListData = response["featureMenuMapping"];
      this.MenuListData = response["menuMaster"];

      this.AddFeaturesUI();
    }, error => {
      this.alertService.error(error);
    });
  }

  OnClick(menu: any) {

    for (let k = 0; k < this.FeatureListData.length; k++) {
      let element = this.FeatureListData[k];
      if (element["menuId"] === menu["id"]) {
        let selectAll = <HTMLInputElement>document.getElementById('menu' + element["menuId"]);
        this.AddRoleForm.controls.FeatureList["controls"][k].patchValue(selectAll.checked);
      }

    }

  }
  /**Method to handle save click event */
  SaveNewRoleClick() {
   
          if (this.AddRoleForm.invalid) {
            return;
          }
          this.AddedRolesList = this.AddRoleForm.value.FeatureList
            .map((v, i) => v ? this.FeatureListData[i].featureName : null)
            .filter(v => v !== null);

          let formData: FormData = new FormData();
          if (this.IsNewRole) {
            formData.append('IsNewRole', 'true');
          } else {
            formData.append('RoleId', this.editDataVal[0]["roleId"]);
            formData.append('IsNewRole', 'false');
          }

          //formData.append('UserId', this.cookieService.get('UserId'));
          formData.append('RoleName', this.AddRoleForm.value['RoleName']);
          for (var i = 0; i < this.AddedRolesList.length; i++) {
            formData.append('ComponentList[]', this.AddedRolesList[i]);
          }

          this.service.AddRoleMappingData(formData).subscribe(response => {

      this.IsMsgVisible = true;
      this.alertService.success(response);
      this.RefreshGridData.emit(1);
      this.AddRoleForm.reset();
      this.closeAddRole.emit(0);
    }, error => {
      this.IsMsgVisible = true;
      this.alertService.error(error.error);
      //console.log(error);
    });
    return;

   
  }

  /** Method to handle close event */
  close() {

    this.AddedRolesList = [];
    this.IsMsgVisible = false;
    this.closeAddRole.emit(0);
    this.AddRoleForm.reset();
  }
  //#endregion
}
