import { Component, OnInit, VERSION } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { EmailService } from 'src/services/LoginServices/email.service';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./../login/login.component.css']
})
export class ContactSupportComponent implements OnInit {
  contactForm: UntypedFormGroup;
  showEmailError = false;
  showcMsgError = false;
  showcSubjectError = false;
  formError: string;
  showFormError = false;
  IsSuccessMessage = false;
  public version = VERSION.full;
  isCaptchaDone = false;
  Year: string = Date();
  constructor(private formBuilder: UntypedFormBuilder, private emailService: EmailService) { }

  ngOnInit() {
    let emailPattern = '^[A-Za-z0-9.\'_%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,10}$';
    this.contactForm = this.formBuilder.group({
      cEmail: ['', Validators.compose([Validators.required,
      Validators.pattern(emailPattern)])],
      cMsg: ['', Validators.compose([Validators.required, Validators.minLength(20), Validators.maxLength(1000)])],
      cSubject: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(100)])],
      recaptchaReactive: new UntypedFormControl('null', Validators.required)
    });
  }

  ngAfterViewInit() {
    let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
    if (invalidFields.length > 0) {
      invalidFields[1].focus();
      this.showEmailError = false;
      this.showcMsgError = false;
      invalidFields.className = '';
    }
  }


  ShowEmailError() {
    this.showEmailError = true;
  }
  ShowMessageError() {
    this.showcMsgError = true;
  }
  ShowSubjectError() {
    this.showcSubjectError = true;
  }


  get GetFormControls() {
    return this.contactForm.controls;
  }


  SubmitForm() {
    this.IsSuccessMessage = false;
    this.showFormError = true;
    if (this.contactForm.invalid) {

      this.showEmailError = true;
      this.showcMsgError = true;
      this.showcSubjectError = true;
      let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
      invalidFields[1].focus();
      return;
    }
    if (!this.isCaptchaDone) {
      this.formError = 'Please verify captcha to continue';
      this.IsSuccessMessage = false;
      this.showFormError = true;
      return;
    }
    let emailFromUser = this.contactForm.get('cEmail').value;
    let formData = new FormData;
    formData.append('MailTo', emailFromUser);
    //formData.append('MailBody', this.createMailToSend(this.contactForm.get('cMsg').value, emailFromUser));
    formData.append('MailBody', this.contactForm.get('cMsg').value);
    formData.append('Subject', this.contactForm.get('cSubject').value)

    this.emailService.contactSupport(formData).subscribe(response => {
      this.IsSuccessMessage = true;
      this.formError = 'Your query have been submitted, our team will get back to you at the earliest.';
      this.showFormError = true;
      this.showcSubjectError = false;
      this.showEmailError = false;
      this.showcMsgError = false;

    },
      err => {
        // this.errorHandler.ErrorsHandler(err);
        this.IsSuccessMessage = false;
        this.formError = 'Error in submiting request, Please try again later.';
        this.showFormError = true;


        this.showcSubjectError = false;
        this.showEmailError = false;
        this.showcMsgError = false;


      })
    this.IsSuccessMessage = true;
    this.contactForm.reset();
    this.showFormError = false;
    this.IsSuccessMessage = true;

    // this.formError = 'Your query have been submitted, our team will get back to you at the earliest.';
    // alert('clicked');
  }

  public resolved(captchaResponse: string) {
    // alert(captchaResponse);
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse === null || captchaResponse === '') {
      this.isCaptchaDone = false;
    }
    else
      this.isCaptchaDone = true;
  }

  // public executeImportantAction(): void {
  //   this.recaptchaV3Service.execute('importantAction')
  //     .subscribe((token) => this.handleToken(token));
  // }


}
