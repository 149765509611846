<div class="card">
  <div class="card-header border-transparent">
    <h3 class="card-title MoodysTittleColor">Accounts Planned for Today</h3>

    <div class="card-tools">
      <button type="button" class="btn btn-tool" data-card-widget="collapse">
        <i class="fas fa-minus"></i>
      </button>
      <button type="button" class="btn btn-tool" data-card-widget="remove">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <!-- /.card-header -->
  <div class="card-body p-0">
    <div class="table-responsive">
      <h6 *ngIf="!isData" style="padding-left: 20px;">
        No Accounts Planned For Today
      </h6>
      <table style="font-size: small;" class="table m-0" *ngIf="isData">
        <thead>
          <tr>
            <th style="white-space: nowrap;" *ngFor="let header of fieldHeader |slice:1 " class="text-left">
              {{ header | titlecase }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of uploadData  let i = index">
            <td>{{item['account Name']}}</td>
            <td>{{item['account Type']}}</td>
            <td>{{item['business Unit']}}</td>
            <!-- <td><span class="badge badge-info">{{item.status}}</span></td> -->
            <td><span Class="badge" [ngClass]="item['cssClass']">{{item.status}}</span></td>
            <td>
              <div class="sparkbar" data-color="#00a65a" data-height="20">{{item.priority}}</div>
            
            </td>
            <td>
              {{item.workType}}
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
  <!-- /.card-body -->
  <div class="card-footer clearfix">
    <a href="javascript:void(0)" *ngIf="hasUploadAccess" routerLink="/uploadaccount"
      class="btn btn-sm MoodysSubmitButton ">Upload New
      Account</a>
    <a href="javascript:void(0)" routerLink="/processtracking" class="btn btn-sm btn-secondary float-right">View
      All Accounts</a>
  </div>
  <!-- /.card-footer -->
</div>
