<hr style="margin-top: 0px!important;">
<div>
  <section class="content" style="margin-top: 15px;">
    <div class="container-fluid">
      <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title MoodysTittleColor" id="title" >Upload Account</h3>
        </div>
      </div>
    </div>
  </section>
  <section class="content" *ngIf="IsRecentlyUploadVisible">
    <div class="container-fluid">
      <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title MoodysTittleColor" >Already Uploaded Jobs for Policy Ref : {{PolicyRefVal}}</h3>
          <div class="row float-right">
            <!-- Search -->
            <!-- <div>
              <div class="input-group input-group-sm">
                <input class="form-control font11" (keyup)="checkSearch()" (keydown.enter)="searchRecords()" type="text"
                  placeholder="Search" [(ngModel)]="searchInput" aria-label="Search" />
                <div class="input-group-append">
                  <button class="input-group-text bg-primary" data-toggle="tooltip" data-placement="top" type="submit"
                    id="basic-text1" title="Search" (click)="searchRecords()" class="RMSSearchButton">
                    <i class="fas fa-search RMSSearchButtonIcon"></i>
                  </button>
                  <button class="RMSSearchButton" type="submit" data-toggle="tooltip" data-placement="top"
                    style="margin-left: 1px;" title="Refresh" id="basic-text1" (click)="resetRecords()">
                    <i class="fas fa-history text-grey" style="color: #ffffff;" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div class="col-lg-12 ErrorMessage NoPadding" *ngIf="showSearchValidationError"
                style="color: #c42032; font-size: 0.8rem;">
                Special characters are not allowed
              </div>
            </div> -->
          </div>
        </div>

        <div class="card-body" id="RecentJobs">
          <div class="row">
            <div class="table-responsive text-nowrap" *ngIf="!NoRecordsToShow" style="min-width: 500px; max-height: 200px;">
              <table class="table-hover table-bordered font14 noPad">
                <thead>
                  <tr class="th-sm RMSTableHeader">
                    <th *ngFor="let header of RecentUploadedHeader">{{header | titlecase}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="RMSTableContent" *ngFor="let item of RecentUploadedAccntData; let i = index">
                    <td *ngFor="let key of RecentUploadedHeader">
                      <div *ngIf="key == 'date Received'">
                        <span>{{ item[key]|date:'dd-MMM-yyyy hh:mm a' }}</span>
                      </div>
                      <div *ngIf="key != 'date Received'">
                        <span> {{item[key]}}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p *ngIf="NoRecordsToShow"><i>No records found</i></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title MoodysTittleColor" >Upload</h3>
        </div>
        <div class="card-body">
          <label class="row"
            style="font-weight: bold;color:white;margin-left: 10px;margin-bottom: 20px; background-color:#0A1264;">
            {{lateUploadMsg}}</label>
          <form (ngSubmit)="onSubmit()" [formGroup]="form" class="form-row">            
            <div *ngFor="let element of elementList" style="float: left;">
              <app-dynamic-elements [showError]="showError" (getRecentUpload)="getRecentUpload($event)"
                [refresh]="refresh" [OriginalAccountList]="OriginalAccountList" [element]="element" [form]="form" (SetPackageType)="getPackageTypeValue($event)" (SetOriginalAccount)="getOriginalAccounts($event)">
              </app-dynamic-elements>
            </div>

            <div class="form-row" [formGroup]="form" style="width:100%;white-space: pre;">
              <div class="form-row" style="width: 100%; margin-left: 20px;margin-bottom: 10px;">
                <div style="width: 140px;">
                  <label style="font-weight: normal;">Special Instruction</label>
                </div>
                <div class="col-sm-6" style="margin-left:5px">
                  <textarea style=" width: 100%;height: 100px;text-align: start;" maxlength="4000"
                    formControlName="SpecialInstruction" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.SpecialInstruction.errors }" #lengthOfText
                    (ngModelChange)="showremainingcharacter();"></textarea>
                  <div *ngIf="submitted && f.SpecialInstruction.errors" class="invalid-feedback">
                    <div *ngIf="f.SpecialInstruction.errors.maxlength">Only 4000 characters allowed</div>
                  </div>
                  <div class="form-row__description" style="color: #db202f;" *ngIf="isTyping">
                    {{4000- lengthOfText.value.length }} character remaining
                  </div>
                </div>
              </div>
              <div class="form-row" style="width: 100%; margin-left: 20px;">
                <div style="width: 140px;">
                  <label style="font-weight: normal;">Attach Files <label class='required'>*</label></label>
                </div>
                <div appDnd class="col-sm-6" style="margin-left:5px;text-align: center;"
                  (fileDropped)="onFileDropped($event)">
                  <div class="container">
                    <input type="file" #fileDropRef id="fileDropRef" multiple
                      (change)="fileBrowseHandler($event.target.files)" />
                    <div class="dragDrop">
                      <div class="row">
                        <img class=" col-sm-2 d-none d-sm-block" src="assets/svg/ic-upload-file.svg"
                          style="width: 30px;height: 30px;">
                        <h3 class="col-sm-8" style="text-align: center;">Drag and drop file here</h3>
                      </div>
                      <div class="row" style="text-align: center;">
                        <h3 class="col">or</h3>
                      </div>
                      <div class="row" style="text-align: center;">
                        <div class="col"></div>
                        <label class="col MoodyBrowserFileButton" for="fileDropRef" style="width: 30%; height: 20%;border-radius: 21.5px; color: white;
                     padding: 8px 16px;">Browse for file</label>
                        <div class="col"></div>
                      </div>
                    </div>
                  </div>
                  <div class="files-list" style="max-height: 200px; scroll-behavior: auto; overflow-y: scroll;">
                    <div class="single-file" *ngFor="let file of files; let i = index">
                      <img src="assets/svg/ic-file.svg" width="45px" alt="file">
                      <div class="info">
                        <h4 class="name">
                          {{ file?.name }}
                        </h4>
                        <p class="size">
                          {{ formatBytes(file?.size) }}
                        </p>
                        <!-- <div class="progress-cont">
                  <div class="progress" [style.width]="file?.progress + '%'">
                  </div>
                </div> -->
                      </div>
                      <img src="assets/svg/ic-delete-file.svg" class="delete" width="20px" alt="file"
                        (click)="deleteFile(i)">
                    </div>
                  </div>
                </div>
                <div>
                  <label style="font-weight: normal; color:red;overflow-wrap: break-word;">{{fileSizeError}}</label>
                </div>
              </div>
            </div>

            <hr>
            <div class="container-fluid" style="width: 100%;">
              <button type="submit" style="float: right; width: auto;"
                class="col-sm-2 btn  btn-block MoodysSubmitButton">Upload
                Account</button>
              <label style="font-weight: normal; float: right; margin-right: 20px;">
                <input type="checkbox" style="margin-right: 10px;" formControlName="UploadAnother">Upload Another
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="card card-default">
        <div class="card-header">
          <h3 class="card-title MoodysTittleColor" >Upload Queue</h3>
        </div>
        <div class="card-body" id="Progress">
          <div class="table-responsive text-nowrap">
            <table class="table-hover table-bordered font14 noPad" style="width: 100%;">
              <thead>
                <tr class="th-sm RMSTableHeader">
                  <th style="width: 10px;">Action</th>
                  <th>Account Name</th>
                  <th>Account Type</th>
                  <th>Work Type</th>
                  <th>Priority</th>
                  <th>Business Unit</th>
                  <th>No. of Locs</th>
                  <!-- <th>Sum Insured</th> -->
                  <!-- <th>Underwriter</th> -->
                  <th>Progress</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                <tr class="RMSTableContent" *ngFor="let item of UploadingFileProgress">
                  <ng-container>
                    <!-- {{item[key]}} -->
                    <td>
                      <div>
                        <button class="btn btn-tool" *ngIf="item.status==1">
                          <i class="fa fa-check" style="color:green;" aria-hidden="true"></i>
                        </button>
                        <button class="btn btn-tool" *ngIf="item.status==2">
                          <i class="fa fa-times" style="color:#db202f;" (click)="cancelUpload(item)"
                            aria-hidden="true"></i>
                        </button>
                        <button class="btn btn-tool" *ngIf="item.status==3" title="click here to retry upload"
                          (click)="RetryUpload(item)">
                          <i class="fa fa-exclamation-triangle" style="color:#db202f;" aria-hidden="true"></i>
                        </button>

                      </div>
                    </td>
                    <td>{{item.AccountName}}</td>
                    <td>{{item.AccountType}}</td>
                    <td>{{item.WorkType}}</td>
                    <!--{{item.WorkType}} -->
                    <td>{{item.Priority}}</td>
                    <td>{{item.BusinessUnit}}</td>
                    <td>{{item.NoOfLocs}}</td>
                    <!-- <td>{{item.SumInsured}}</td> -->
                    <!-- <td>{{item.Underwriter}}</td> -->
                    <td>

                      <div class="row" style="margin-left: inherit;">
                        <div class="Uploadprogress-cont col-sm-8" *ngIf="item.Progress > 0">
                          <div class="UploadProgress" [style.width]="item.Progress + '%'">
                            <label style="font-weight: normal;">{{item.Progress}}%</label>
                          </div>
                        </div>
                        <!-- <div class="col-sm-2" *ngIf="item.Progress > 0">
                        <label style="font-weight: normal; color:green">{{item.Message}}</label>
                      </div> -->
                      </div>
                    </td>
                    <td>
                      <div *ngIf="item.status==3">
                        <label style="font-weight: normal; color: #db202f;">{{item.Message}}</label>
                      </div>
                      <label *ngIf="item.status==1 || item.status==2"
                        style="font-weight: normal; color:green">{{item.Message}}</label>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
