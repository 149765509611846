import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AlertService } from 'src/services/Common/alert.service';
import { EmailService } from 'src/services/LoginServices/email.service';
import { ErrorHandler } from 'src/common/error-handler';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';

@Component({
  selector: 'app-contact-support-template',
  templateUrl: './contact-support-template.component.html',
  styleUrls: ['./contact-support-template.component.css']
})
export class ContactSupportTemplateComponent implements OnInit {
  contactForm: UntypedFormGroup;
  errorHandler = new ErrorHandler(this.alertService);
  showEmailError = false;
  showcMsgError = false;
  showcSubjectError = false;
  formError: string;
  showFormError = false;
  IsSuccessMessage = false;
  //public version = VERSION.full;
  isCaptchaDone = false;
  emailID: any;
  isTyping: boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, private emailService: EmailService, private alertService: AlertService,
    private service: AddEditUserSerivceService) { }

  ngOnInit() {
    let emailPattern = '^[A-Za-z0-9.\'_%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,10}$';
    this.contactForm = this.formBuilder.group({
      cEmail: [{ value: '', disabled: true }, Validators.compose([Validators.required,
      Validators.pattern(emailPattern)])],
      cMsg: ['', Validators.compose([Validators.required, Validators.minLength(20), Validators.maxLength(1000)])],
      cSubject: ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(100)])],
      recaptchaReactive: new UntypedFormControl('null', Validators.required)
    });
    this.service.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      if (res != null && res != "undefined") {
        this.emailID = res['userDetails'][0]['emailID'];
        this.contactForm.get('cEmail').setValue(this.emailID)
      }
    },
      err => {
        this.errorHandler.ErrorsHandler(err);
      }
    )


  }

  ngAfterViewInit() {
    let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
    if (invalidFields.length > 0) {
      invalidFields[1].focus();
      this.showEmailError = false;
      this.showcMsgError = false;
      invalidFields.className = '';
    }
  }


  ShowEmailError() {
    this.showEmailError = true;
  }
  ShowMessageError() {
    this.isTyping = true;
    this.showcMsgError = true;
  }
  ShowSubjectError() {
    this.showcSubjectError = true;
  }


  get GetFormControls() {
    return this.contactForm.controls;
  }

  SubmitForm() {
    //alert('hello')
    // console.log(this.contactForm.value)
    this.IsSuccessMessage = false;
    this.showFormError = true
    if (this.contactForm.invalid) {

      this.showEmailError = true;
      this.showcMsgError = true;
      this.showcSubjectError = true;
      let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
      invalidFields[1].focus();
      return;
    }
    if (!this.isCaptchaDone) {

      this.alertService.error('Please verify captcha to continue');
      // this.formError = '';
      this.IsSuccessMessage = false;
      this.showFormError = true;
      return;
    }
    let emailFromUser = this.contactForm.get('cEmail').value;
    let formData = new FormData;
    formData.append('MailTo', emailFromUser);
    //formData.append('MailBody', this.createMailToSend(this.contactForm.get('cMsg').value, emailFromUser));
    formData.append('MailBody', this.contactForm.get('cMsg').value);
    formData.append('Subject', this.contactForm.get('cSubject').value)

    this.emailService.contactSupportAfterLogin(formData).subscribe(response => {

      this.alertService.success('Your query have been submitted, our team will get back to you at the earliest.');

      // this.formError = 'Request successfully submitted.';
      this.showFormError = true;

      this.showcSubjectError = false;
      this.showEmailError = false;
      this.showcMsgError = false;

    },
      err => {

        this.errorHandler.ErrorsHandler(err);
        this.showcSubjectError = false;
        this.showEmailError = false;
        this.showcMsgError = false;

      })
    this.contactForm.reset();
    this.showcSubjectError = false;
    this.showEmailError = false;
    this.showcMsgError = false;

    this.showFormError = false;
    this.IsSuccessMessage = true;
    this.isTyping = false;
    // this.formError = 'Your query have been submitted, our team will get back to you at the earliest.';

  }

  public resolved(captchaResponse: string) {
    // alert(captchaResponse);
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse === null || captchaResponse === '') {
      this.isCaptchaDone = false;
    }
    else
      this.isCaptchaDone = true;
  }

  // public executeImportantAction(): void {
  //   this.recaptchaV3Service.execute('importantAction')
  //     .subscribe((token) => this.handleToken(token));
  // }



}
