
import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GetClientDetailsServiceService } from 'src/services/AccessManagement/get-client-details-service.service';
import { AlertService } from 'src/services/Common/alert.service';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertMessages } from 'src/common/alert-messages';
import { AccountBUMappingModel } from 'src/model/ClientManagement/AccountBUMapping';
import { map } from 'rxjs/internal/operators/map';
import { stringify } from 'querystring';
import { Button, element } from 'protractor';
// import { asString } from 'html2canvas/dist/types/css/types/color';
import { from } from 'rxjs';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { CommonMethod } from 'src/common/CommonMethod';
import { FeatureMaster } from 'src/common/feature-master';
import { build$ } from 'protractor/built/element';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {
  clientManagementForm: FormGroup;
  errorHandler = new ErrorHandler(this.alertService);
  submitted = false;
  router: any;
  data: any;
  BUList: any[];
  accounttypeList: any[];
  locationList: any;
  applicationList:any;
  workTypeList: any[];
  accountManagerList: any;
  clientTypeList: any;
  selectedAccountType: any;
  selectedBusinessType: any;
  selectedWorkType: any;
  selectedAccountManager: any;
  clientT: any;
  operationMode: string;
  btnSubmitTxt: string;
  selectedLocation: any[] = [];
  selectedApplications:any[]=[];
  tblAccountBUMappingData: AccountBUMappingModel[];
  checkAuthorizedforedit: boolean;
  IsAccountBUEdit: boolean = false;
  AccountBUEditRowId: number;
  MaxAccountBURow: number=0;
  NewAccountBUMapRow: number=0;
  @Input() clientinfo: any;
  @Input() clientId: number;
  @Output() public closeAddClient = new EventEmitter();
  @Output() public RefreshGridData = new EventEmitter();


  constructor(
    private getClientDetailsService: GetClientDetailsServiceService,
    private formBuilder: FormBuilder
    , private alertService: AlertService, private userInfo: AddEditUserSerivceService

  ) { }

  ngOnInit() {
    this.clientManagementForm = this.formBuilder.group({
      clientName: ['', [Validators.required, Validators.maxLength(200)]],
      syndicate: ['', [Validators.required, Validators.maxLength(200)]],
      accountType: [,],
      businessType: ['',],
      accountManager: [, Validators.required],
      clientType: [, Validators.required],
      location: ['', Validators.required],
      applications:['',Validators.required],
      workType: [,]
    });
    this.tblAccountBUMappingData = [];

  }
  AddAccountBUMapping() {
    if (this.selectedAccountType == null || this.selectedBusinessType == null || this.selectedBusinessType.length == 0 || this.selectedWorkType == null) {
      document.getElementById("lblAccountBUmapError").textContent = "Please provide valid values to Add";
      return;
    }
    document.getElementById("lblAccountBUmapError").textContent = "";
    this.clientManagementForm.get("businessType").reset();
    this.clientManagementForm.get("workType").reset();
    this.clientManagementForm.get("accountType").reset();
    let mapping: AccountBUMappingModel = new AccountBUMappingModel();

    let BUtype: string = '';
    let BUids: number[] = [];
    let AccountTypeIds: number[] = [];
    let AccountTypes: string = '';
    let WorkTypeIds: number;
    let WorkTypes: string = '';
    this.selectedAccountType.forEach(element => {
      if (AccountTypeIds == undefined || AccountTypeIds.length == 0) {
        AccountTypes = element["accountTypeName"];
        AccountTypeIds.push(element["accountTypeID"]);
      }
      else {
        AccountTypes = AccountTypes + " , " + element["accountTypeName"];
        AccountTypeIds.push(element["accountTypeID"]);
      }
    });
    this.selectedBusinessType.forEach(element => {
      if (BUids == undefined || BUids.length == 0) {
        BUtype = element["businessUnitDescription"];
        BUids.push(element["businessUnitID"]);
      }
      else {
        BUtype = BUtype + " , " + element["businessUnitDescription"];
        BUids.push(element["businessUnitID"]);
      }
    });
    // this.selectedWorkType.forEach(element => {
    //   //if (WorkTypeIds == undefined || WorkTypeIds.length==0) {
    //     WorkTypes = element["workName"];
    //     WorkTypeIds=element["workID"];
    //   // }
    //   // else {
    //   //   WorkTypes = WorkTypes + " , " + element["workName"];
    //   //   WorkTypeIds.push(element["workID"]);
    //   // }
    // });    
    if (this.IsAccountBUEdit) {
      let index = this.tblAccountBUMappingData.findIndex(item => item.RowCombinationId == this.AccountBUEditRowId);
      this.tblAccountBUMappingData[index].AccountType = AccountTypes;
      this.tblAccountBUMappingData[index].AccountTypeId = AccountTypeIds;
      this.tblAccountBUMappingData[index].BusinessUnit = BUtype;
      this.tblAccountBUMappingData[index].BusinessUnitIds = BUids;
      this.tblAccountBUMappingData[index].WorkTypeId = this.selectedWorkType["workID"];
      this.tblAccountBUMappingData[index].WorkType = this.selectedWorkType["workName"];
      this.IsAccountBUEdit = false;
      this.AccountBUEditRowId = null;

    } else {
      this.NewAccountBUMapRow = this.NewAccountBUMapRow + 1;
      mapping.AccountType = AccountTypes;
      mapping.AccountTypeId = AccountTypeIds;
      mapping.BusinessUnit = BUtype;
      mapping.BusinessUnitIds = BUids;
      mapping.WorkTypeId = this.selectedWorkType["workID"];
      mapping.WorkType = this.selectedWorkType["workName"];
      mapping.RowCombinationId = this.NewAccountBUMapRow;
      this.tblAccountBUMappingData.push(mapping);
    }

    this.selectedBusinessType = null;
    this.selectedAccountType = null;
    this.selectedWorkType = null;
  }

  DeleteAccountBUMapping(item: any) {
    if (!this.checkAuthorizedforedit)
      return;
    // if (item['RowCombinationId'] > this.MaxAccountBURow) {
    //   this.NewAccountBUMapRow = this.NewAccountBUMapRow - 1;
    // }
    let index = this.tblAccountBUMappingData.indexOf(item);
    this.tblAccountBUMappingData.splice(index, 1);

  }

  EditAccountBUMapping(item: any) {
    if (!this.checkAuthorizedforedit)
      return;
    this.IsAccountBUEdit = true;
    let accountType: string = item["AccountType"];
    var selectedAccntType = accountType.split(',');
    this.selectedAccountType = [];
    selectedAccntType.forEach(element => {
      this.selectedAccountType.push(this.accounttypeList.find(account => (account["accountTypeName"] == element.trim())));
    });
    this.clientManagementForm.get("accountType").setValue(this.selectedAccountType);

    // let workType: string = item["WorkType"];
    // var selectedwrkType = workType.split(',');
    // //this.selectedWorkType = item["WorkType"];

    this.selectedWorkType = this.workTypeList.find(account => (account["workName"] == item["WorkType"]));


    // this.selectedWorkType = this.workTypeList.find(element => (element["workName"] == item["WorkType"]));
    this.clientManagementForm.get("workType").setValue(this.selectedWorkType);
    let businessUnit: string = item["BusinessUnit"];
    var selectedBU = businessUnit.split(',');
    this.selectedBusinessType = [];
    selectedBU.forEach(element => {
      this.selectedBusinessType.push(this.BUList.find(Bu => (Bu["businessUnitDescription"] == element.trim())));
    });
    this.clientManagementForm.get("businessType").setValue(this.selectedBusinessType);
    //this.clientManagementForm.get("businessType").setValue(item["BusinessUnit"])

    let Index = this.tblAccountBUMappingData.indexOf(item);
    this.AccountBUEditRowId = this.tblAccountBUMappingData[Index].RowCombinationId
  }

  OnBusinessUnitChange(item: any) {
    this.selectedBusinessType = item;
  }

  OnClientTypeChange(item: any) {
    this.clientT = item;
  }
  OnAccountManagerChange(item: any) {
    this.selectedAccountManager = item;
  }
  OnLocationChange(item: any) {
    this.selectedLocation = item;
  }
  OnApplicationChange(item:any){
    this.selectedApplications=item;
  }

  OnAccountTypeChange(item: any) {
    this.selectedAccountType = item;
  }

  OnWorkTypeChange(item: any) {
    this.selectedWorkType = item;
  }



  ngOnChanges() {

    this.CheckAuthorization();
    if (this.clientinfo) {
      this.btnSubmitTxt = "Update";
      this.selectedAccountType = [];
      this.selectedLocation = [];
      this.selectedApplications=[];
      this.selectedBusinessType = [];
      this.operationMode = "2";
      this.clientManagementForm.controls['clientName'].setValue(String(this.clientinfo['tblClientDetails'][0]['clientName']));
      this.clientManagementForm.controls['syndicate'].setValue(String(this.clientinfo['tblClientDetails'][0]['syndicate']));
      this.selectedAccountManager = this.clientinfo['tblClientDetails'][0]['accountManager'];
      this.clientManagementForm.controls['clientType'].setValue(this.clientinfo['tblClientDetails'][0]['businessTypeID']);
      this.MaxAccountBURow = this.clientinfo['maxAccountBUmapRow'][0]['maxRowId'];
      this.NewAccountBUMapRow = this.MaxAccountBURow;
      this.clientManagementForm.get("accountManager").setValue(this.selectedAccountManager);    
      // let accountBUmap = this.clientinfo["tblAccountBUMapping"];
      this.tblAccountBUMappingData = [];
      // let AccountTypeIds: number[] = [];
      // let AccountTypes: string;
      // let WorkTypeIds: number[] = [];
      // let WorkTypes: string;
      // let BUIds: number[] = [];
      // let BUTypes: string;
      // let mapping: AccountBUMappingModel = new AccountBUMappingModel();

      // accountBUmap.forEach(element => {

      //   if (AccountTypeIds==undefined || AccountTypeIds.length==0) {
      //     AccountTypeIds.push(element["accountTypeID"]);
      //     AccountTypes = element["accountType"];
      //   }
      //   else {
      //     if (!AccountTypeIds.includes(element["accountTypeID"])) {
      //       AccountTypeIds.push (element["accountTypeID"]);
      //       AccountTypes = AccountTypes + ',' + element["accountType"];
      //     }
      //   }
      //   if (WorkTypeIds == undefined || WorkTypeIds.length==0) {
      //     WorkTypeIds.push(element["workId"]);
      //     WorkTypes = element["workType"];
      //   }
      //   else {
      //     if (!WorkTypeIds.includes(element["workId"])) {
      //       WorkTypeIds.push(element["workId"]);
      //       WorkTypes = WorkTypes + ',' + element["workType"];
      //     }
      //   }
      //   if (BUIds == undefined || BUIds.length==0) {
      //     BUIds.push(element["businessUnitID"]);
      //     BUTypes = element["businessUnitDescription"];
      //   }
      //   else {
      //     if (!BUIds.includes(element["businessUnitID"])) {
      //       BUIds.push(element["businessUnitID"]);
      //       BUTypes = BUTypes + ',' + element["businessUnitDescription"];
      //     }
      //   }

      // });
      // mapping.AccountType = AccountTypes;
      // mapping.AccountTypeId = AccountTypeIds;
      // mapping.BusinessUnit = BUTypes;
      // mapping.BusinessUnitIds = BUIds;
      // mapping.WorkType = WorkTypes;
      // mapping.WorkTypeId = WorkTypeIds;
      // this.tblAccountBUMappingData.push(mapping);    

      this.clientinfo["tblAccountBUMapping"].forEach(element => {
        let AccountBUMapping: AccountBUMappingModel = new AccountBUMappingModel();
        if (this.tblAccountBUMappingData == undefined || this.tblAccountBUMappingData.length == 0 || !this.tblAccountBUMappingData.some(row => row.RowCombinationId == element['rowCombinationId'])) {
          AccountBUMapping.AccountType = element['accountType'];
          AccountBUMapping.AccountTypeId = [];
          AccountBUMapping.AccountTypeId.push(element['accountTypeID']);
          AccountBUMapping.BusinessUnit = element['businessUnitDescription'];
          AccountBUMapping.BusinessUnitIds = [];
          AccountBUMapping.BusinessUnitIds.push(element['businessUnitID']);
          AccountBUMapping.WorkType = element['workType'];
          AccountBUMapping.WorkTypeId = element['workId'];
          AccountBUMapping.RowCombinationId = element['rowCombinationId'];
          this.tblAccountBUMappingData.push(AccountBUMapping);
        } else { //if (this.tblAccountBUMappingData.some(row => row.RowCombinationId == element['rowCombinationId']))
          let index = this.tblAccountBUMappingData.findIndex(row => row.RowCombinationId == element['rowCombinationId']);
          if (!this.tblAccountBUMappingData[index].AccountTypeId.includes(element['accountTypeID'])) {
            this.tblAccountBUMappingData[index].AccountType = this.tblAccountBUMappingData[index].AccountType + ',' + element['accountType'];
            this.tblAccountBUMappingData[index].AccountTypeId.push(element['accountTypeID']);
          }
          if (!this.tblAccountBUMappingData[index].BusinessUnitIds.includes(element['businessUnitID'])) {
            this.tblAccountBUMappingData[index].BusinessUnit = this.tblAccountBUMappingData[index].BusinessUnit + ',' + element['businessUnitDescription'];
            this.tblAccountBUMappingData[index].BusinessUnitIds.push(element['businessUnitID']);
          }
          if (element['workId'] == 3) {
            this.tblAccountBUMappingData[index].WorkType = element['workType'];
            this.tblAccountBUMappingData[index].WorkTypeId = element['workId'];
          }
        }
      });

      // let AccountBUMapping:AccountBUMappingModel=new AccountBUMappingModel();
      // AccountBUMapping.AccountType=element['accountType'];
      // AccountBUMapping.AccountTypeId=element['accountTypeID'];
      // AccountBUMapping.BusinessUnit=element['businessUnitDescription'];
      // AccountBUMapping.BusinessUnitIds=element['businessUnitID'];
      // AccountBUMapping.WorkType=element['workType'];
      // AccountBUMapping.WorkTypeId=element['workId'];
      // this.tblAccountBUMappingData.push(AccountBUMapping) 
      //this.tblAccountBUMappingData=this.clientinfo["tblAccountBUMapping"];
      // let accountlist: any[] = this.clientinfo['tblLocationDetail'];

      // for (let i = 0; i < accountlist.length; i++) {
      //   this.selectedAccountType = this.clientinfo['tblAccountType'][i]['accountTypeID'];
      // }

      // this.selectedAccountManager = this.clientinfo['tblProjectManagerDesc'][0]['userID']

      let list: any[] = this.clientinfo['tblLocationDetail'];

      for (let i = 0; i < list.length; i++) {
        this.selectedLocation.push(this.clientinfo['tblLocationDetail'][i]['city'])
      }
      this.clientManagementForm.get("location").setValue(this.selectedLocation);
     let applicationList:any[]=this.clientinfo['tblApplicationMaster']
     for (let i = 0; i < applicationList.length; i++) {
      this.selectedApplications.push(this.clientinfo['tblApplicationMaster'][i]['applicationId'])
    }
    this.clientManagementForm.get("applications").setValue(this.selectedApplications);
    }
    else {
      this.btnSubmitTxt = "Submit";
      this.operationMode = "1";
      this.getClientDetailsService.GetData().subscribe(response => {
        let responseData = response;
        this.BUList = responseData['tblBusinessUnitDesc'];
        this.accounttypeList = responseData['tblAccountType'];
        this.accountManagerList = response['tblProjectManagerDesc'];
        this.clientTypeList = response['tblBusinessType'];
        this.locationList = response['tblLocationMaster'];
        this.applicationList=response['tblApplicationMaster'];        
        this.workTypeList = response['tblWorkType'];
      }, error => {
        this.errorHandler.ErrorsHandler(error);
      });
    }

  }
  get f() {
    return this.clientManagementForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.tblAccountBUMappingData == undefined || this.tblAccountBUMappingData == null || this.tblAccountBUMappingData.length == 0) {
      document.getElementById("lblAccountBUmapError").textContent = "Please provide valid values to Add";
      return;
    }
    if (this.clientManagementForm.invalid) {
      return;
    }
    document.getElementById("lblAccountBUmapError").textContent = '';
    let formData = new FormData;
    if (this.clientId == undefined || this.clientId == null) {
      this.clientId = 0;
    }
    let payLoad = JSON.stringify(this.clientManagementForm.getRawValue());
    formData.append('ClientID', this.clientId.toString());
    formData.append('ClientName', this.clientManagementForm.get('clientName').value);
    formData.append('Syndicate', this.clientManagementForm.get('syndicate').value);
    formData.append('AccountManagerID', this.clientManagementForm.get('accountManager').value);
    formData.append('LMDC', this.clientManagementForm.get('clientType').value);
    formData.append('location', this.clientManagementForm.get('location').value);
    formData.append('applications', this.clientManagementForm.get('applications').value);
    // formData.append('ClientData', payLoad);
    formData.append('AccountTypeBUMapping', JSON.stringify(this.tblAccountBUMappingData));
    formData.append('OperationMod', this.operationMode);
    // formData.append('SelectedAccountType', this.clientManagementForm.get('accountType').value);
    // formData.append('SelectedBusinessUnit', this.clientManagementForm.get('businessType').value);


    this.getClientDetailsService.AddEditClient(formData).subscribe(response => {

      let responsedata = response;
      if (responsedata == 1) {
        if (this.operationMode == '1') {
          this.alertService.success(AlertMessages.CLIENTSAVESUCESS);
        } else {
          this.alertService.success(AlertMessages.CLIENTEDITSUCESS);
        }
        this.submitted = false;
      }
      this.RefreshGridData.emit(1);
      this.closeAddClient.emit(0);
      this.ngOnInit();

    },
      err => {

        this.errorHandler.ErrorsHandler(err);
      })

  }

  onReset() {
    this.submitted = false;
    this.clientManagementForm.reset();
  }


  close() {
    this.clientManagementForm.reset();
    this.closeAddClient.emit(0);
  }
  CheckAuthorization() {
    this.userInfo.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      if (res != null) {
        // console.log(res);
        var obj: CommonMethod = new CommonMethod();
        this.checkAuthorizedforedit = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.AddEdit_Client);
        // console.log(this.checkAuthorizedforedit);
      }
    })
  }
}
