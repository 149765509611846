import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientelementmappingserviceService {

  constructor(private httpclient: HttpClient) { }
  GetClientElementList(url: string) {
    return this.httpclient.get(url).pipe(map(res => {
      return res;
    },
      error => {
        return error.json();
      }))
  }
  SaveClientElementMapping(url: string, ClientelementList: string, IsEdit: boolean) {
    console.log(JSON.parse(ClientelementList));
    let formdata = new FormData;
    formdata.append("ClientElementList", ClientelementList);
    // return;
    return this.httpclient.post(url, formdata).pipe(map(res => {
      return res;
    }, err => {
      return err.json();
    }))
  }
  GetClientElementMapping(url: string, ClientId: number) {
    let formdata = new FormData;
    formdata.append("ClientId", String(ClientId));
    return this.httpclient.post(url, formdata).pipe(map(res => {
      return res;
    }, error => {
      return error.json();
    }))
  }
  GetElementValidationMasterData(url: string, clientId: string) {
    return this.httpclient.get(url).pipe(map(res => {
      return res;
    },
      error => {
        return error.json();
      }))
  }
  GETELEMENTVALIDATIONMASTERDATA(url: string, clientid: string) {
    let formdata = new FormData;
    formdata.append('clientid', clientid);
    return this.httpclient.post(url, formdata).pipe(map(res => {
      return res;
    }, err => {
      return err.json();
    }))
  }
  SaveElementValidationSettings(url: string, elementvalidationList: string) {
    let formdata = new FormData;
    formdata.append('ClientElementValidationMapping', elementvalidationList);
    return this.httpclient.post(url, formdata).pipe(map(res => {
      return res;
    }, err => {
      return err.json();
    }))
  }
}
