<div class="tab-pane fade show active" id="client_details" role="tabpanel" aria-labelledby="nav-profile-tab">
    <!-- <div class="card border-top-0" style="border-radius: 0px !important;"> -->
    <!-- <div class="card-body"> -->
    <form [formGroup]="clientManagementForm">
        <div style="border:solid rgba(204, 203, 206, 0.473); border-radius: 5px;">
            <div class="container">
                <div style="margin-top: 10px;" class="row">

                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm row">
                            <label class="col-md-4" style="font-size: small; font-weight: normal;"
                                for="selectbasic">Select Element<label class='required'>*</label></label>
                            <div class="col-md-4">

                                <select formControlName="Elementname" autofocus id='inputClientName'
                                    placeholder="Select Element" class="form-control form-control-sm"
                                    (change)="GetElementID($event.target.value)">
                                    <option [value]="" selected='true' hidden='true'>Select Element</option>
                                    <option *ngFor="let item of _listelement" [value]="item['id']">
                                        {{ item["elementName"] }}</option>
                                </select>
                                <div class="col-lg-12 ErrorMessage NoPadding"
                                    style="font-size: small;color: #c42032; height: 1rem !important;">
                                    <div
                                        *ngIf="GetFormControls.Elementname.errors && checkerror || GetFormControls.Elementname.touched && GetFormControls.Elementname.invalid">
                                        <div *ngIf="GetFormControls.Elementname.errors.required">
                                            Select the Element.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm row">
                            <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                for="selectbasic">Mapped Name</label>
                            <div class="col-md-4">
                                <input type="text" placeholder="Mapped Name" formControlName="MappedName"
                                    class="form-control form-control-sm">

                            </div>

                        </div>

                    </div>


                </div>
                <div class="row">
                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm row">
                            <label class="col-md-4 " style="font-size: small; font-weight: normal;" for="selectbasic">Is
                                Mandatory<label class='required'>*</label></label>
                            <div class="col-md-4">
                                <select formControlName="IsMandatory" class="form-control form-control-sm">
                                    <option value=true>Yes</option>
                                    <option value=false>No</option>
                                </select>
                                <div class="col-lg-12 ErrorMessage NoPadding"
                                    style="font-size: small;color: #c42032; height: 1rem !important;">
                                    <div
                                        *ngIf="GetFormControls.IsMandatory.errors && checkerror || GetFormControls.IsMandatory.touched && GetFormControls.IsMandatory.invalid">
                                        <div *ngIf="GetFormControls.IsMandatory.errors.required">
                                            select IsMandatory.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm row">
                            <label class="col-md-4 " style="font-size: small; font-weight: normal;" for="selectbasic">Is
                                Active<label class='required'>*</label></label>
                            <div class="col-md-4">
                                <select formControlName="IsActive" class="form-control form-control-sm">
                                    <option value=true>Yes</option>
                                    <option value=false>No</option>
                                </select>
                                <div class="col-lg-12 ErrorMessage NoPadding"
                                    style="font-size: small;color: #c42032; height: 1rem !important;">
                                    <div
                                        *ngIf="GetFormControls.IsActive.errors && checkerror || GetFormControls.IsActive.touched && GetFormControls.IsActive.invalid">
                                        <div *ngIf="GetFormControls.IsActive.errors.required">
                                            select isactive .
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm row">
                            <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                for="selectbasic">Default Value</label>
                            <div class="col-md-4">

                                <input type='text' placeholder="Defatult value" formControlName="DefaultValue"
                                    class="form-control form-control-sm">
                                <div class="col-lg-12 ErrorMessage NoPadding"
                                    style="font-size: small;color: #c42032; height: 1rem !important;">
                                    <!-- <div
                                                *ngIf="GetFormControls.IsMandatory.errors && checkerror || GetFormControls.IsMandatory.touched && GetFormControls.IsMandatory.invalid">
                                                <div *ngIf="GetFormControls.IsMandatory.errors.required">
                                                    select IsMandatory.
                                                </div>
                                            </div> -->
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm  row">
                            <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                for="selectbasic">MasterDataQuery</label>
                            <div class="col-md-4">
                                <input formControlName="MasterDataQuery" placeholder="MasterDataQuery"
                                    class="form-control form-control-sm" type="text">
                            </div>

                        </div>
                    </div>
                    <!-- <div class="col">
                                <div class="input-group-sm row">
                                    <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                        for="selectbasic">ValidationReq<label class='required'>*</label></label>
                                    <div class="col-md-4">
                                        <select (change)="ifrequred($event.target.value)" placeholder=""
                                            formControlName="ValidationReq" class="form-control form-control-sm">
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        <div class="col-lg-12 ErrorMessage NoPadding"
                                            style="font-size: small;color: #c42032; height: 1rem !important;">
                                            <div
                                                *ngIf="GetFormControls.ValidationReq.errors && checkerror || GetFormControls.ValidationReq.touched && GetFormControls.ValidationReq.invalid">
                                                <div *ngIf="GetFormControls.ValidationReq.errors.required">
                                                    select validation required.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div> -->
                </div>
                <div style="margin-bottom: 10px;" class="row">
                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm row">
                            <label class="col-md-4 " style="font-size: small; font-weight: normal;" for="selectbasic">Is
                                Editable<label class='required'>*</label></label>
                            <div class="col-md-4">
                                <select formControlName="IsEditable" class="form-control form-control-sm">
                                    <option value=true>Yes</option>
                                    <option value=false>No</option>
                                </select>
                                <div class="col-lg-12 ErrorMessage NoPadding"
                                    style="font-size: small;color: #c42032; height: 1rem !important;">
                                    <div
                                        *ngIf="GetFormControls.IsEditable.errors && checkerror || GetFormControls.IsEditable.touched && GetFormControls.IsEditable.invalid">
                                        <div *ngIf="GetFormControls.IsEditable.errors.required">
                                            select IsEditable .
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col">
                        <!-- Select Basic -->
                        <div class="input-group-sm row">
                            <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                for="selectbasic">Validation Required<label class='required'>*</label></label>
                            <div class="col-md-4">
                                <select formControlName="ValidationReq" class="form-control form-control-sm">
                                    <option value=true>Yes</option>
                                    <option value=false>No</option>
                                </select>
                                <div class="col-lg-12 ErrorMessage NoPadding"
                                    style="font-size: small;color: #c42032; height: 1rem !important;">
                                    <div
                                        *ngIf="GetFormControls.ValidationReq.errors && checkerror || GetFormControls.ValidationReq.touched && GetFormControls.ValidationReq.invalid">
                                        <div *ngIf="GetFormControls.ValidationReq.errors.required">
                                            select validation Req.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div style="margin-left: 2px;" class="form-group row">
                    <!-- <div class="col-md-4"> -->
                    <button style="margin-top: 9px;" id="singlebutton" (click)="AddToElementList()" name="singlebutton"
                        class="btn btn-primary RMSSubmitButton">{{_buttonTittle}}</button>
                    <!-- </div>
                            <div class="col-md-4"> -->
                    <button *ngIf="IsEdit" style="margin-top: 9px;margin-left: 10px;" id="singlebutton" (click)="CancelEdit()" name="singlebutton"
                        class="btn btn-primary RMSButtonCancel">Cancel</button>
                    <!-- </div> -->
                </div>
            </div>


        </div>




    </form>
    <div style="border:solid rgba(204, 203, 206, 0.473); border-radius: 5px;margin-top: 10px;">
        <div class="container" style="margin-top: 10px;">
            <div class="container ">
                <label style="color: rgb(0, 123, 151);font-size: small;">Client Element Mapping</label>

            </div>
            <div class="table-responsive text-nowrap">
                <table id="tblAccountBUMap" style="width: 100%;margin-bottom: 5px;"
                    class="table-hover table-bordered font14 noPad">
                    <thead>
                        <tr class="th-sm RMSTableHeader">
                            <th class="text-center" style="width: 141px;">Actions</th>
                            <th class="text-center" style="width: 200px;">ElementName</th>
                            <th class="text-center" style="width: 200px;">MappedName</th>
                            <th class="text-center" style="width: 200px;">Status</th>
                            <th class="text-center" style="width: 200px;">IsEditable</th>
                            <th class="text-center" style="width: 200px;">IsMandatory</th>
                            <th class="text-center" style="width: 200px;">ValidationReq</th>
                            <th class="text-center" style="width: 200px;">DefaultValue</th>
                            <th class="text-center" style="width: 200px;">Applied Validation</th>
                        </tr>
                    </thead>
                    <tbody class="RMSTableContent">
                        <tr class="RmsTableRow" *ngFor="let item of _clientelementarray; let i = index">

                            <td class="text-center icon">
                                <button *ngIf="item.ClientId!=null" class="btn btn-tool" type="button">
                                    <i class="fa fa-edit" title="Edit Element" (click)="EditElement(item)"
                                        style="color:rgb(0, 123, 151)"></i>
                                </button>
                                <button *ngIf="item.ClientId==null" style="color: gray;" class="btn btn-tool"
                                    type="button">
                                    <i class="fa fa-edit" title="Edit Element" style="color:gray"></i>
                                </button>
                                <button *ngIf="item.ClientId!=null" class="btn btn-icon-toggle" type="button">
                                    <i class="fa fa-trash" title="Delete Element" (click)="DeleteElement(item)"
                                        style="color:rgb(196, 32, 50)"></i>
                                </button>
                                <button *ngIf="item.ClientId==null" class="btn btn-icon-toggle" type="button">
                                    <i class="fa fa-trash" title="Delete Element" style="color:gray"></i>
                                </button>
                                <!-- <button class="btn btn-icon-toggle" type="button">
                                                <i class="fa fa-list" title="Apply Validation" (click)="DeleteElement(item)"
                                                    style="color:rgb(196, 32, 50)"></i>
                                            </button> -->
                            </td>
                            <td class="text-center ">{{item.ElementName}}</td>
                            <td class="text-center icon">{{item.MappedName}}</td>
                            <td class="text-center icon">
                                <!-- {{item.IsActive}} -->
                                <i *ngIf="item.IsActive == true;else deactive" class="fa fa-check"
                                    style="color:green;;"></i>

                                <ng-template #deactive>
                                    <i class="fa fa-times" style="color:red;"></i>
                                </ng-template>
                            </td>
                            <td class="text-center icon">
                                <!-- {{item.IsEditable}} -->
                                <i *ngIf="item.IsEditable == true;else isedit" class="fa fa-check"
                                    style="color:green;;"></i>

                                <ng-template #isedit>
                                    <i class="fa fa-times" style="color:red;"></i>
                                </ng-template>
                            </td>
                            <td class="text-center icon">
                                <!-- {{item.IsMandatory}} -->
                                <i *ngIf="item.IsMandatory == true;else isMan" class="fa fa-check"
                                    style="color:green;;"></i>

                                <ng-template #isMan>
                                    <i class="fa fa-times" style="color:red;"></i>
                                </ng-template>
                            </td>
                            <td class="text-center icon">
                                <!-- {{item.IsMandatory}} -->
                                <i *ngIf="item.ValidationReq == true;else validation" class="fa fa-check"
                                    style="color:green;;"></i>

                                <ng-template #validation>
                                    <i class="fa fa-times" style="color:red;"></i>
                                </ng-template>
                            </td>
                            <td class="text-center icon">
                                {{item.DefaultValue}}

                            </td>
                            <td class="text-center icon">
                                {{item.Validation}}

                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>


        </div>
    </div>



    <!-- </div> -->
    <!-- </div> -->
</div>
<div style="margin-top: 10px;" class="col-8">
    <button type="button" style="margin-right: 20px;" (click)="SaveClientElementMapping()"
        class="btn btn-primary RMSSubmitButton">{{_secondButtonTittle}}</button>
    <!-- <button name="submit" *ngIf="checkAuthorizedforedit" type="submit" class="btn btn-primary RMSSubmitButton">
      {{btnSubmitTxt}}
    </button> -->
    <button name="submit" type="submit" class="btn btn-primary RMSButtonCancel" (click)="close()">
        Cancel
    </button>
</div>