
<div class=" setBGImage">
  <div >
   
    <div class="main">
      <div class="row">
        <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 2rem !important;">
          <div class="alert alert-danger alert-dismissible" style="max-height: 56px;" *ngIf="showLoginError">
            <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
            {{loginError}}
          </div>
        </div>
      <!-- <div *ngIf="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
      </div> -->
      <div class="container " style="width: 35%;padding-top: 30px;">
        <div class="align-middle" >     
            <div class="card " style="height: 530px;align-items: center;border-radius: 30px;padding-left: 25px;padding-right:25px;">
              <div style="text-align: center;" >
                <div class="app-header " >
                  <img  src="../../../assets/svg/MoodysBlue.svg" alt="Logo" class="app-logo">
                  <h3 class="MoodyTittleColor2">Data Solution Portal</h3>
                </div>
              </div>
              <div >    
              <div class="card-body" >
                <form  *ngIf='!rediretochangepassword' [formGroup]="registerForm" (ngSubmit)="SignIn();" autocomplete="off">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-user"></i></span>
                    </div>
                    <input (ngModelChange)="ShowUserNameError();" formControlName="UserName" placeholder="Login Id" type="text" style="border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;" class="form-control"  >
                      <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                        <div *ngIf="f.UserName.errors && showUserNameError">
                          <div *ngIf="f.UserName.errors.required">Login Id is required.</div>
                          <div *ngIf="f.UserName.errors.pattern">Please check the format of the email address entered.</div>
                        </div>
                      </div>
                  </div>
                  <div class="input-group" style="padding-top: 1%;" >
                    <div class="input-group-prepend">
                      <span class="input-group-text" style="height: 100%;"><i class="fas fa-key"></i></span>
                    </div>
                    <input (ngModelChange)="ShowPasswordError();" placeholder="Password"
                    formControlName="Password" (capsLock)="capsOn=$event" type="password" (paste)='onpaste($event)' onCopy="return false"
                    onCut="return false" onDrag="return false" onDrop="return false" autocomplete=off id="inputPassword" type="password" style="height: 100%;border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;" class="form-control">
                    <div class="col-lg-12 ErrorMessage NoPadding" style="color: #c42032; height: 1rem !important;">
                      <div  *ngIf="f.Password.errors && showPassWordError" >
                        
                        <div *ngIf="f.Password.errors.required">Password is required.</div>
                      
                      <div *ngIf="showPasteError">You are not allowed to copy paste password, Please type your password.
                      </div>
                    </div>
                    </div>
                      
                      <div class="col-lg-12 ErrorMessage  NoPadding" style="height: 1rem !important;">
                        <div *ngIf="capsOn">
                          <div style="background: #fffb0733;">
                            Warning: Caps Lock is ON
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="mb-3 form-check"  >
                    <input  [checked]='checkedRemember'
                    (click)="CheckedRememberMe()" type="checkbox" class="form-check-input"  id="rememberMe">
                    <label class="form-check-label" for="rememberMe">Please remember my login id</label>
                  </div>
                  <div style="text-align: center;">
                    <button type="submit" style="width: 50%; height: 50px; border-radius: 10px; display: inline-block;" class="btn MoodysSubmitButton">Sign In</button>
                  </div>     
                   </form>
                   <div class="row" style="display: flex; justify-content: space-between;padding-top: inherit;">
                    <div class="mb-3">
                      <a  routerLink="/forgotpassword">Forgot Password?</a>
                    </div>
                    <div class="mb-3">
                      <a  routerLink="/contactsupport" class="forgot-password">Contact Support</a>
                    </div>
                </div>                                
              </div>  
              <div style="padding-top: 70px;width: 100%;" class="row">
                <div class="col MoodyTittleColor2" style="text-align:center;">
                  <h6 style="font-size: xx-small;">© {{Year | date: "y"}}  Moody's Analytics, Inc. and/or its licensors and affiliates (collectively, "Moody's"). All rights reserved.
                    Reserved.</h6>
                  <br />
                </div>
              </div>  
            </div>         
          </div>
        </div>
      </div>
    </div>  
  </div>
</div>
