<div class="container-fluid">
  <form [formGroup]="addeditformData" (ngSubmit)="FormSubmit()">
    <div class="row form-mobile">
      <div class="col-lg-12 form-group row">
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2" for="client">Client Name </label>
                <P style="color: red; margin-top: -3px; margin-bottom: 1rem;">*</P>
              </div>
            </div>
            <div class="col-lg-7">
              <select autofocus id='inputClientName' placeholder="Select Client Name"
                class="form-control form-control-sm" formControlName="clientNameselector"
                (change)="GetClientInfo($event.target.value)">
                <!-- <option [value]="" disabled='true' selected='true' hidden='true'>Select Client Name</option> -->
                <option *ngFor="let item of listclient" [value]="item['clientID']">
                  {{ item["clientName"] }}</option>
              </select>
              <div class="col-lg-12 ErrorMessage NoPadding"
                style="font-size: small;color: #c42032; height: 1rem !important;">
                <div *ngIf="GetFormControls.clientNameselector.errors && checkerror || GetFormControls.clientNameselector.touched &&
              GetFormControls.clientNameselector.invalid">
                  <div *ngIf="GetFormControls.clientNameselector.errors.required">
                    Client Name is required.
                  </div>
                </div>
              </div>
              <!-- <div class="ErrorMessage NoPadding" style="color: #c42032;">
                <div *ngIf="
                  GetFormControls.clientNameselector.touched &&
                  GetFormControls.clientNameselector.invalid
                ">
                  <div *ngIf="GetFormControls.clientNameselector.errors.required">
                    Client Name is required.
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2">User Name </label>
                <P style="color: red; margin-top: -3px; margin-bottom: 1rem;">*</P>
              </div>
            </div>
            <div class="col-lg-7">
              <input [readonly]="!allowAction" placeholder="Enter User Name" type="text"
                class="form-control form-control-sm" formControlName="usernameText" [ngClass]="{
                'is-invalid':
                  GetFormControls.usernameText.touched &&
                  GetFormControls.usernameText.invalid
              }" />
              <div class="col-lg-12 ErrorMessage NoPadding"
                style="font-size: small;color: #c42032; height: 1rem !important;">
                <div *ngIf="GetFormControls.usernameText.errors && checkerror || GetFormControls.usernameText.touched &&
                GetFormControls.usernameText.invalid">
                  <div *ngIf="GetFormControls.usernameText.errors.required">
                    User Name is required.
                  </div>
                  <div *ngIf="GetFormControls.usernameText.errors.maxlength">
                    too long
                  </div>
                  <div *ngIf="GetFormControls.usernameText.errors.minlength">
                    too short
                  </div>
                </div>
              </div>
              <!-- <div class="ErrorMessage NoPadding" style="color: #c42032;">
                <div *ngIf="
                  GetFormControls.usernameText.touched &&
                  GetFormControls.usernameText.invalid
                ">
                  <div *ngIf="GetFormControls.usernameText.errors.required">
                    User Name is required.
                  </div>
                  <div *ngIf="GetFormControls.usernameText.errors.maxlength">
                    too long
                  </div>
                  <div *ngIf="GetFormControls.usernameText.errors.minlength">
                    too short
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2" for="notes">Email </label>
                <P style="color: red; margin-top: -3px; margin-bottom: 1rem;">*</P>
              </div>
            </div>

            <div class="col-lg-7">
              <input [readonly]="!allowAction || _editUser" type="text" placeholder="Enter Email Address"
                class="form-control inputClass form-control-sm" formControlName="emailaddresstext" [ngClass]="{
                'is-invalid':
                  GetFormControls.emailaddresstext.touched &&
                  GetFormControls.emailaddresstext.invalid
              }" />
              <div class="col-lg-12 ErrorMessage NoPadding"
                style="font-size: small;color: #c42032; height: 1rem !important;">
                <div *ngIf="GetFormControls.emailaddresstext.errors && checkerror || GetFormControls.emailaddresstext.touched &&
                GetFormControls.emailaddresstext.invalid">
                  <div *ngIf="GetFormControls.emailaddresstext.errors.required">
                    Email Address is required.
                  </div>
                  <div *ngIf="GetFormControls.emailaddresstext.errors.maxlength">
                    too long
                  </div>
                  <div *ngIf="GetFormControls.emailaddresstext.errors.minlength">
                    too short
                  </div>
                  <div *ngIf="GetFormControls.emailaddresstext.errors.email">
                    invalid Email Id
                  </div>
                </div>
              </div>
              <!-- <div class="ErrorMessage NoPadding">
                <div *ngIf="
                  GetFormControls.emailaddresstext.touched &&
                  GetFormControls.emailaddresstext.invalid
                ">
                  <div *ngIf="GetFormControls.emailaddresstext.errors.required">
                    Email Address is required.
                  </div>
                  <div *ngIf="GetFormControls.emailaddresstext.errors.maxlength">
                    too long
                  </div>
                  <div *ngIf="GetFormControls.emailaddresstext.errors.minlength">
                    too short
                  </div>
                  <div *ngIf="GetFormControls.emailaddresstext.errors.email">
                    invalid Email Id
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 form-group row">
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2">Extenstion No. </label>
              </div>
            </div>
            <div class="col-lg-7">
              <input [readonly]="!allowAction"  placeholder="Enter Extenstion Number"
                type="text" class="form-control inputClass form-control-sm" formControlName="extenText" />
              <div class="col-lg-12 ErrorMessage NoPadding"
                style="font-size: small;color: #c42032; height: 1rem !important;">
                <div *ngIf="GetFormControls.extenText.errors && checkerror || GetFormControls.extenText.touched &&
                GetFormControls.extenText.invalid">
                  <div *ngIf="GetFormControls.extenText.errors.maxlength">Only 10 digit is allowed!</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2">Phone No. </label>
              </div>
            </div>
            <div class="col-lg-7">
              <input [readonly]="!allowAction"  placeholder="Enter Phone Number"
                class="form-control inputClass form-control-sm" type="text" formControlName="phone" />
              <div class="col-lg-12 ErrorMessage NoPadding"
                style="font-size: small;color: #c42032; height: 1rem !important;">
                <div *ngIf="GetFormControls.phone.errors && checkerror || GetFormControls.phone.touched &&
                GetFormControls.phone.invalid">
                  <div *ngIf="GetFormControls.phone.errors.maxlength">Only 50 digit is allowed!</div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2">Location </label>
                <P style="color: red; margin-top: -3px; margin-bottom: 1rem;">*</P>
              </div>
            </div>
            <div class="col-lg-7">
              <div>
                <ng-select placeholder="Select Location" [readonly]="!allowAction" formControlName="locationSelector"
                  [(ngModel)]="selectedLocation">
                  <ng-option *ngFor="let item of listLocation" [value]="item.locationId">{{ item.cityName }}</ng-option>
                </ng-select>
                <div class="col-lg-12 ErrorMessage NoPadding"
                  style="font-size: small;color: #c42032; height: 1rem !important;">
                  <div *ngIf="GetFormControls.locationSelector.errors && checkerror || GetFormControls.locationSelector.touched &&
                  GetFormControls.locationSelector.invalid">
                    <div *ngIf="GetFormControls.locationSelector.errors.required">Location is required</div>
                  </div>
                </div>
                <!-- <div class="ErrorMessage NoPadding" style="color: #c42032;">
                  <div *ngIf="
                    GetFormControls.locationSelector.touched &&
                    GetFormControls.locationSelector.invalid
                  ">
                    <div *ngIf="GetFormControls.locationSelector.errors.required">
                      location is required.
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 form-group row">
        <div class="col-lg-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2">Role </label>
                <P style="color: red; margin-top: -3px; margin-bottom: 1rem;">*</P>
              </div>
            </div>
            <div class="col-lg-7">
              <ng-select placeholder="Select Role" [readonly]="!allowAction" formControlName="roleSelector"
                placeholder="Enter Role">
                <ng-option *ngFor="let item of listRole" [value]="item.roleid">
                  {{ item.rolename }}
                </ng-option>
              </ng-select>
              <div class="col-lg-12 ErrorMessage NoPadding"
                style="font-size: small;color: #c42032; height: 1rem !important;">
                <div *ngIf="GetFormControls.roleSelector.errors && checkerror || GetFormControls.roleSelector.touched &&
                GetFormControls.roleSelector.invalid">
                  <div *ngIf="GetFormControls.roleSelector.errors.required">Role is required</div>
                </div>
              </div>
              <!-- <div class="ErrorMessage NoPadding" style="color: #c42032;">
                <div *ngIf="
                  GetFormControls.roleSelector.touched &&
                  GetFormControls.roleSelector.invalid
                ">
                  <div *ngIf="GetFormControls.roleSelector.errors.required">
                    Role is required.
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div *ngIf="clientUser" class="col-md-4">
          <div class="row">
            <div class="col-md-5">
              <div class="row">
                <label class="offset-2">Account Type </label>
                <P style="color: red; margin-top: -3px; margin-bottom: 1rem;">*</P>
              </div>
            </div>
            <div class="col-md-7">
              <ng-select placeholder="Select Account Type" [readonly]="!allowAction"
                formControlName="accounttypemappingSelector" [multiple]="true" [(ngModel)]="selectedaccounttypemapping"
                (change)="FillDefaultAccountType()">
                <ng-option *ngFor="let item of listaccType" [value]="item.accountTypeID">
                  {{ item.accountTypeName }}
                </ng-option>
              </ng-select>
              <div class="col-lg-12 ErrorMessage NoPadding"
                style="font-size: small;color: #c42032; height: 1rem !important;">
                <div *ngIf="GetFormControls.accounttypemappingSelector.errors && checkerror || GetFormControls.accounttypemappingSelector.touched &&
                GetFormControls.accounttypemappingSelector.invalid">
                  <div *ngIf="GetFormControls.accounttypemappingSelector.errors.required">Account Type is required</div>
                </div>
              </div>
              <!-- <div class="ErrorMessage NoPadding" style="color: #c42032;">
                <div *ngIf="
                  GetFormControls.accounttypemappingSelector.touched &&
                  GetFormControls.accounttypemappingSelector.invalid
                ">
                  <div *ngIf="
                    GetFormControls.accounttypemappingSelector.errors.required
                  ">
                    Account Type is required.
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div *ngIf="clientUser" class="col-lg-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2">Business Unit </label>
                <P style="color: red; margin-top: -3px; margin-bottom: 1rem;">*</P>
              </div>
            </div>
            <div class="col-lg-7">
              <ng-select placeholder="Select Businesss Unit" [readonly]="!allowAction"
                formControlName="BUmappingSelector" [multiple]="true" [(ngModel)]="selectedbu"
                (change)="FillDefaultBusinessUnit()">
                <ng-option *ngFor="let item of listBU" [value]="item.businessUnitID">
                  {{ item.businessUnitDescription }}
                </ng-option>
              </ng-select>
              <div class="col-lg-12 ErrorMessage NoPadding"
                style="font-size: small;color: #c42032; height: 1rem !important;">
                <div *ngIf="GetFormControls.BUmappingSelector.errors && checkerror || GetFormControls.BUmappingSelector.touched &&
                GetFormControls.BUmappingSelector.invalid">
                  <div *ngIf="GetFormControls.BUmappingSelector.errors.required">Business Unit is required</div>
                </div>
              </div>
              <!-- <div class="ErrorMessage NoPadding" style="color: #c42032;">
                <div *ngIf="
                  GetFormControls.BUmappingSelector.touched &&
                  GetFormControls.BUmappingSelector.invalid
                ">
                  <div *ngIf="GetFormControls.BUmappingSelector.errors.required">
                    latest Businees Unit is required.
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>       
      </div>
      <div class="col-lg-12 form-group row">
        <div  class="col-lg-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2">Applications</label>
                <P style="color: red; margin-top: -3px; margin-bottom: 1rem;">*</P>
              </div>
            </div>
            <div class="col-lg-7">
              <ng-select placeholder="Select Applications" [readonly]="!allowAction" formControlName="applications" [(ngModel)]="selectedapplications"
              [multiple]="true">
              <ng-option *ngFor="let item of listApplications" [value]="item.applicationId">
                {{ item.applicationName }}
              </ng-option>
            </ng-select>
              <div class="col-lg-12 ErrorMessage NoPadding"
                style="font-size: small;color: #c42032; height: 1rem !important;">
                <div *ngIf="GetFormControls.applications.errors && checkerror || GetFormControls.applications.touched &&
                GetFormControls.applications.invalid">
                  <div *ngIf="GetFormControls.applications.errors.required">Application is required</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="clientUser" class="col-md-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2">Default Account </label>
              </div>
            </div>
            <div class="col-lg-7">
              <ng-select placeholder="Select Default Account" [readonly]="!allowAction"
                formControlName="defaultaccountypeSelector">
                <ng-option *ngFor="let item of DefaultAccountTypeList" [value]="item.accountTypeID">
                  {{ item.accountTypeName }}</ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div *ngIf="clientUser" class="col-md-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2">Default Business Unit </label>
              </div>
            </div>
            <div class="col-lg-7">
              <ng-select placeholder="Select Default Business Unit" [readonly]="!allowAction"
                formControlName="defaultbusinessunitSelector">
                <ng-option *ngFor="let item of userBusinessTypeList" [value]="item.businessUnitID">
                  {{ item.businessUnitDescription }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>     
      </div>
      <div class="col-lg-12 form-group row">
        <div *ngIf="clientUser" class="col-md-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2">Default Work Type </label>
              </div>
            </div>
            <div class="col-lg-7">
              <ng-select [readonly]="!allowAction"  placeholder="Select Default Work Type"
                formControlName="defaultworktypeSelector">
                <ng-option *ngFor="let item of listworkType" [value]="item.workId">
                  {{ item.workName }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        <div *ngIf="internalUser" class="col-lg-4">
          <div class="row">
            <div class="col-lg-5">
              <div class="row">
                <label class="offset-2">Client Mapping </label>
                <P style="color: red; margin-top: -3px; margin-bottom: 1rem;">*</P>
              </div>
            </div>
            <div class="col-lg-7">
              <!-- <ng-select
                      clientmappingSelector
                      [items]="listclient"
                      bindLabel="clientName"
                      bindValue="clientID"
                      [multiple]="true"
                      placeholder="Client Name"
                      [(ngModel)]="selectedclientmapping"
                      (change)="SelectedClientMapping()"
                      [ngModelOptions]="{ standalone: true }"
                    >
                    </ng-select> -->
              <ng-select placeholder="Select Client" [readonly]="!allowAction" formControlName="clientmappingSelector"
                [multiple]="true">
                <ng-option *ngFor="let item of listclientForMapping" [value]="item.clientID">
                  {{ item.clientName }}
                </ng-option>
              </ng-select>
            </div>
          </div>
        </div>
        </div>
      <!-- <div class="row d-flex align-items-end flex-column">
      <div class="col-md-3">
        <button type="submit" class="btn btn-success btnpopup">
          {{ action }}
        </button>
        <button
          type="button"
          class="btncancel btn btn-sucess  btnpopup" 
          (click)="Cancel()"
        >
          Cancel
        </button>
      </div>
    </div> -->
      <!-- <div >
      <button type="submit" class="btn btn-success btncancel btnpopup fload-left">
        {{ action }}
      </button>
    </div> -->
      <div class="row " style="margin-left: 15px; float: left;">
        <button *ngIf="allowAction" type="submit" class="btn btn-primary RMSSubmitButton"
          style=" margin-right: 10px;">{{ action }}</button>
        <button type="button" class="btn btn-primary RMSButtonCancel" (click)="Cancel()">Cancel</button>
      </div>
    </div>

  </form>
</div>