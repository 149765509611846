import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertMessages } from 'src/common/alert-messages';
import { AlertService } from 'src/services/Common/alert.service';
import { EmailService } from 'src/services/LoginServices/email.service';

@Component({
  selector: 'app-generate-passwordlink',
  templateUrl: './generate-passwordlink.component.html',
  styleUrls: ['./generate-passwordlink.component.css']
})
export class GeneratePasswordlinkComponent implements OnInit {
  capsOn;
  guid = '';
  flag: boolean;
  generatePasswordtemplate: boolean;
  expiretemplate: boolean;
  notification: boolean;
  errorMessage: string;
  generatePasswordLink = false;
  loginLink = false;
  ShowUserNameErrors: boolean;
  ShowUserPasswordErrors: boolean;
  ShowConfirmPasswordErrors: boolean;
  ifError = false;
  Year: string=Date();
  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private EService: EmailService,private alertService: AlertService) {
    this.guid = this.route.snapshot.queryParamMap.get('id');
    if (this.guid !== null) {
      //// calling the api
      this.EService.CheckGeneratePasswordUrl(this.guid).subscribe(
        res => {          
          let resB: boolean = Boolean(res);
          if (resB) {
            this.ifError = true;
            this.generatePasswordtemplate = true;
          } else {         
            this.notification = true;
            this.errorMessage = "This link has been expired!  ";
            this.loginLink = true;
            this.generatePasswordtemplate = false;
          }
        }, err => {
          this.ifError = true;

          // console.log(err.status);
          switch (err.status) {
            case 400:
              this.errorMessage = err.error;
              this.notification = true;
              this.generatePasswordLink = true;
              break;
            case 401:
              this.errorMessage = err.error;
              this.notification = true;
              break;
            case 0:
              this.errorMessage = 'Could not connect to the  server ';
              this.notification = true;

              this.loginLink = true;

              break;
            case 410:
              this.errorMessage = err.error;
              this.generatePasswordLink = true;
              this.notification = true;
              break;

          }
        }
      )
    }
   }
   Userid = '';
   formdata: UntypedFormGroup;
  ngOnInit(): void {
    this.formdata = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(16)
        , Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$!@#$%()_+^&}{:;?.\"])[A-Za-z\\d$!@#$%()_+^&}{:;?.\"]{10,16}$")]],
      confirmPassword: ['', Validators.required],
      UserName: ['', [Validators.required,Validators.email,Validators.pattern('^[A-Za-z0-9.\'_%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{1,10}$')]]
    }
      ,
      {
        validators: MustMatch('password', 'confirmPassword')
      });
      this.formdata.get('password').valueChanges.subscribe(() => {
        this.ShowUserPasswordErrors = this.formdata.get('password').invalid;
      });
    
  }
  get f() {
    return this.formdata.controls;
  }

  ShowUserNameError() {
    this.ShowUserNameErrors = true;
  }

  ShowUserPasswordError() {
    this.ShowUserPasswordErrors = true;
  }
  

  ShowConfirmPasswordError() {
    this.ShowConfirmPasswordErrors = true;
  }
  onSubmit(UserId: string, Password: string) {
    this.ShowConfirmPasswordErrors = true;
    this.ShowUserPasswordErrors = true;
    this.ShowUserNameErrors = true;
    // stop here if form is invalid
    if (this.formdata.invalid) {
      return;
    }
    this.EService.GeneratePassword(UserId, Password, this.guid).subscribe(
      res => {
        // console.log(res);
        let resB: boolean = Boolean(res);
        if (resB) {
          this.ifError = false;
          this.notification = true;
          this.errorMessage = "Password has been generated successfully!  ";
          this.loginLink = true;
          this.generatePasswordtemplate = false;
        }
        else {
          this.notification = true;
          this.errorMessage = "Something went wrong!";
          this.loginLink = true;
          this.generatePasswordtemplate = false;
        }
      }, err => {
         // console.log(err.status);
        this.alertService.error(err["error"]["ErrorMessage"]);
        this.ifError = true;
        this.formdata.reset();

        // switch (err.status) {
        //   case 400:
        //     this.errorMessage = err.error;
        //     this.notification = true;
        //     this.generatePasswordLink = true;
        //     break;
        //   case 400:
        //     this.errorMessage = "could";
        //     this.notification = true;
        //     break;
        //   case 401:
        //     this.errorMessage = err.error;
        //     this.notification = true;
        //     break;
        //   case 0:
        //     this.errorMessage = err.error;
        //     this.notification = true;
        //     break;
        //   case 500:
        //     this.errorMessage = err.error;
        //     this.notification = true;
        //     break;

        // }

      }
    )
  }
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
