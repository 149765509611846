<!-- Navbar -->

<div class="fixed">
  <nav class="main-header navbar navbar-expand navbar-white navbar-light ">
    <!-- Left navbar links -->
    <ul class="navbar-nav skin-red">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" (click)="toggleAlert()" href="#" role="button"><i
            class="fas fa-bars"></i></a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a routerLink="/home" class="nav-link" title="Home">Home</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a routerLink="/contact" class="nav-link" title="Contact Support" *ngIf="!isGroupUser" >Contact Support</a>
      </li>
     
    </ul>
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto" style="align-items: right;">
      <li class="nav-item d-none d-sm-inline-block ">
        <a   class="nav-link" style="font-size: larger;" title="Client Name">{{clientName}}</a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <div class="nav-link ">
          <span style="font-size: x-small;">{{timezone}}</span>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/userprofile">
          <i class="far fa-user color-white font16  "   title="Click to View/Edit Profile"></i>
          <label class="navbar-toggler togglerFontSetting">
            User Settings</label>
        </a>
      </li>
      <li class="nav-item">
        <a (click)="FAQPDFDownload()" class="nav-link btn ">
          <i class="fa fa-question-circle "   title="FAQ"></i>
        </a>
      </li>
      <!-- Notifications Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell" title="Notification"></i>
          <span class="badge badge-warning navbar-badge">
            <span *ngIf="uploadedCount>0 || downloadCount > 0">1</span>
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <!-- <span class="dropdown-item dropdown-header">15 Notifications</span>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <i class="fas fa-envelope mr-2"></i> 4 new messages
          <span class="float-right text-muted text-sm">3 mins</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <i class="fas fa-users mr-2"></i> 8 Accounts Download
          <span class="float-right text-muted text-sm">12 hours</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <i class="fas fa-file mr-2"></i> 3 new reports
          <span class="float-right text-muted text-sm">2 days</span>
        </a> -->
          <a class="dropdown-item" routerLink="/uploadaccount" *ngIf="uploadedCount>0">
            <i class="fa fa-upload MoodysIconColor" style=" margin-right: 20px;"></i> {{uploadedCount}} account
            uploaded
            <!-- <span class="float-right text-muted text-sm">2 days</span> -->
          </a>

          <a class="dropdown-item" routerLink="/recentjobs" *ngIf="downloadCount>0">
            <i class="fa fa-upload MoodysIconColor" style=" margin-right: 20px;"></i> {{downloadCount}} file(s)
            downloaded
            <!-- <span class="float-right text-muted text-sm">2 days</span> -->
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item dropdown-footer" title="">See All Notifications</a>
        </div>
      </li>
      <!-- <li class="nav-item">
      <a class="nav-link">
        <i class="fas fa-question-circle color-white font16"></i>
        <label class="navbar-toggler togglerFontSetting">
          Contact Support</label
        >
      </a>
    </li> -->
      <li class="nav-item">
        <a (click)="logout()" class="nav-link">
          <i class="fas fa-sign-out-alt color-white font16 "  title="Sign Out"></i>
          <label class="navbar-toggler togglerFontSetting"> Sign Out</label>
        </a>
      </li>
      <!-- <li class="nav-item">
      <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button"><i
          class="fas fa-th-large"></i></a>
    </li> -->
    </ul>
  </nav>
  <!-- /.navbar -->
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-light-primary elevation-4 MoodysbackgroudColorPrimary"
   >
    <!-- Brand Logo -->
    <a href="/home" class="brand-link MoodysbackgroudColorPrimary"
     >
      <!-- <img src="../../../assets/svg/RMS Logo.jpg" alt="favicon"  width="2px" class="brand-image" style="opacity: 0.9;margin-top: 5px;max-height: 24px;margin-left: -0.2rem;"> -->
      <!-- <img src="https://cdn-assets-us.frontify.com/s3/frontify-enterprise-files-us/eyJwYXRoIjoicm1zXC9maWxlXC9NTkZxY0tHeUprUWt1dDJvZWVORC5zdmcifQ:rms:sqTpFu8TpHnagwN5vHnTQNFx-6APy1uF4yArs0JpSuc?width={width}" alt="favicon"  width="2px" class="brand-image" style="opacity: 0.9;margin-top: 5px;max-height: 24px;margin-left: -0.2rem;"> -->
      <img src="../../../assets/svg/MoodysLogo.png" alt="favicon"  width="2px" class="brand-image" style="opacity: 0.9;margin-top: 5px;max-height: 24px;margin-left: -0.2rem;">

      <span class="brand-text font-weight-bold" style="color: white;font-size:large;"> <sup> </sup> Data Portal</span>

      <!-- <span class="brand-text font-weight-bold" style="color: white;"> <sup></sup></span> -->
    </a>
    <!-- https://cdn-assets-us.frontify.com/s3/frontify-enterprise-files-us/eyJwYXRoIjoicm1zXC9maWxlXC9NTkZxY0tHeUprUWt1dDJvZWVORC5zdmcifQ:rms:sqTpFu8TpHnagwN5vHnTQNFx-6APy1uF4yArs0JpSuc?width={width} -->
    <!-- Sidebar -->
    <div class="sidebar">
      
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
       
        <a routerLink="/userprofile">

         
        <div class="image">
          <!-- <img src="assets/Images/avatar3.png" class="img-circle elevation-2" alt="User Image"> -->
          <i class="fa fa-user-circle" style="font-size:32px;color: white;"></i>
        </div>
        <div class="info">
          <a href="#" class="d-block" style="color: white;">{{UserName}}</a>
        </div>
      </a>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <app-sidebar></app-sidebar>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>
  <!-- /.control-sidebar -->

</div>
