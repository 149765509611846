import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule, DefaultUrlSerializer, UrlTree } from '@angular/router';
import { LoginComponent } from './Login/login/login.component';
import { LogoutComponent } from './Login/logout/logout.component';
import { ForgetPasswordComponent } from './Login/forget-password/forget-password.component';
import { LoginTestComponent } from '../app/Common/login-test/login-test.component';
import { ResetPasswordComponent } from './Login/reset-password/reset-password.component';
import { ContactSupportComponent } from './Login/contact-support/contact-support.component';
import { ContactSupportTemplateComponent } from './Login/contact-support/contact-support-template/contact-support-template.component'
import { ChangePasswordComponent } from './Login/changepassword/changepassword.component';

import { RaiseComponent } from './MessageCenter/raise/raise.component'
// import { ProcessTrackingComponent } from './process-tracking/view-process-tracking/view-process-tracking.component';
// import { UploadDataComponent } from './Upload/upload-data/upload-data.component';
import { AccountReportComponent } from './Report/account-report/account-report.component';
import { ProfileUserComponent } from './user/profile-user/profile-user.component';
import { FirsttimepasswordchangeComponent } from './Login/firsttimepasswordchange/firsttimepasswordchange.component';
import { ErrorPageComponent } from './Common/error-page/error-page.component';
import { ErrorPageTwoComponent } from './Common/error-page-two/error-page-two.component';
import { SettingUserComponent } from './user/setting-user/setting-user.component';
import { SessionExpireComponent } from './Login/session-expire/session-expire.component';
import { MaintenanceComponent } from './Login/maintenance/maintenance/maintenance.component';
import { ConvertToRLImportFilesComponent } from './convert-to-rlimport-files/convert-to-rlimport-files.component';
import { GeneratePasswordlinkComponent } from './Login/generate-password/generate-passwordlink/generate-passwordlink.component';
import { PowerbiReportComponent } from './PowerbiReport/PowerbiReport.component';
import { SsAccountTrackerComponent } from './process-tracking/ss-account-tracker/ss-account-tracker.component';
import { SsUploadComponent } from './upload/ss-upload/ss-upload.component';
import { AccountsSummaryComponent } from './process-tracking/accounts-summary/accounts-summary.component';
//import { ViewRoleComponent } from './role/view-role/view-role.component';


const routes: Routes = [
  //{ path: '**', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '', redirectTo: '/login', pathMatch: 'full'
  },    
  {
    path: 'home', component: LoginTestComponent
  },
  {
    path: 'logout', component: LogoutComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'forgotpassword', component: ForgetPasswordComponent
  },
  {
    path: 'resetpassword', component: ResetPasswordComponent
  },
  {
    path: 'changepassword', component: ChangePasswordComponent
  },

  {
    path: 'changepasswordfirst', component: FirsttimepasswordchangeComponent
  },
  {
    path: 'contactsupport', component: ContactSupportComponent
  },
  {
    path: 'contact', component: ContactSupportTemplateComponent
  },
  {
    path: 'rolemanagement', loadChildren: () => import('./role/role.module').then(m => m.RoleModule)
  },
  {
    path: 'contactsupportouter', component: ContactSupportTemplateComponent
  },
  {
    path: 'uploadaccount', loadChildren: () => import('./upload/upload.module').then(m => m.UploadModule)
  },
  // {
  //   // path: 'uploaddata', component: UploadDataComponent
  // },
  {
    path: 'accountreport', component: AccountReportComponent
  },
  {
    path: 'messagecenter',
    loadChildren: () => import('./MessageCenter/messagecenter.module').then(m => m.MessagecenterModule)
    , runGuardsAndResolvers: 'always',
  },
  // {
  //   path: '**', redirectTo: '/login'
  // }
  {
    path: 'recentjobs', loadChildren: () => import('./recent-jobs/recent-jobs/recent-jobs.module').then(m => m.RecentJobsModule)
  },
  {
    path: 'uploadshipment', loadChildren: () => import('./uploadShipment/upload-shipment.module').then(m => m.UploadShipmentModule)
  },
  {
    path: 'bulkoperation', loadChildren: () => import('./bulk/bulk-opreation.module').then(m => m.BulkOpreationModule)
  },

  {
    path: 'clientmanagement', loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
  },


  {
    path: 'usermanagement', loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'mastermagement', loadChildren: () => import('./master/master.module').then(m => m.MasterModule)
  },
  {
    path: 'processtracking', loadChildren: () => import('./process-tracking/process-tracking.module').then(m => m.ProcessTrackingModule)
  },

  {
    path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'error', component: ErrorPageComponent
  },
  {
    path: 'error-500', component: ErrorPageTwoComponent
  },
  {
    path: 'userprofile', component: ProfileUserComponent
  },
  {
    path: 'usersetting', component: SettingUserComponent
  },
  {
    path: 'session-expire', component: SessionExpireComponent
  },
  {
    path: 'maintenance', component: MaintenanceComponent
  },
  {
    path:'createmri',component:ConvertToRLImportFilesComponent
  },
  {
    path:'generatepassword',component:GeneratePasswordlinkComponent
  },
  {
    path:'powerbi',component:PowerbiReportComponent
  },
  {
    path:'accountsummary',component:AccountsSummaryComponent
  }


  // ,
  // {
  //   path: 'internalupload', loadChildren: () => import('./internalupload/internalupload.module').then(m => m.InternaluploadModule)
  // }

  // { path: '**', redirectTo: '/error', },

];

//declare module "@angular/core" {
//  interface ModuleWithProviders<T = any> {
//    ngModule: Type<T>;
//    providers?: Provider[];
//  }
//}


const subModuleRoutes = [
  // ,
  // {
  // path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  //}
]


declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
  }
}

export const ModuleRouting: ModuleWithProviders = RouterModule.forRoot(subModuleRoutes);

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {

    return super.parse(url.toLowerCase());
  }
}
