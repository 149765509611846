import { Component, OnInit, SimpleChanges, EventEmitter, Output, Input, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { DynamicElementBase } from 'src/common/DynamicElementBase';
import { UntypedFormGroup, UntypedFormBuilder, FormArray } from '@angular/forms';
import { ProcessTrackingService } from 'src/services/Process-TrackingServices/process-tracking.service';
import { DynamicControlService } from 'src/services/Upload/DynamicControlService';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AlertService } from 'src/services/Common/alert.service';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { FeatureMaster } from 'src/common/feature-master';
import { CommonMethod } from 'src/common/CommonMethod';
import { AlertMessages } from 'src/common/alert-messages';
import { ErrorHandler } from 'src/common/error-handler';
import { DataShareService } from 'src/services/Common/dataShare.service';
import { SelectorMatcher } from '@angular/compiler';
import { Console } from 'console';
@Component({
  selector: 'app-edit-process-tracking',
  templateUrl: './edit-process-tracking.component.html',
  styleUrls: ['./edit-process-tracking.component.css'],
  providers: [DynamicControlService]
})
export class EditProcessTrackingComponent implements OnInit, OnChanges {
  elementList$: DynamicElementBase<any>[];
  form: UntypedFormGroup;
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  //files: any[] = [];
  payLoad = '';
  @Output() public closeEdit = new EventEmitter();
  @Output() public RefreshGridData = new EventEmitter();
  @Input() editData: [];
  @Input() tblHeader: [];
  showError: boolean = false;
  authorizedForEdit: boolean;
  authorizedForCloseAccount:boolean;
  guid: any;
  names: string;
  UpdateDisabled: boolean;
  DefaultElementData: any;
  StatusData: any[];
  IsRMSUser: boolean;
  errorHandler = new ErrorHandler(this.alertService);
  constructor(private processTrackingService: ProcessTrackingService, private qcs: DynamicControlService, private alertService: AlertService,
    private formBuilder: UntypedFormBuilder, private Userdetail: AddEditUserSerivceService, private dataShare: DataShareService) {
    this.form = this.formBuilder.group({
      Status: [''],
      "Package Type":['']
      // FileName: [''],
      // Path: ['']     
    });
    this.UpdateDisabled = false;
    // this.form = this.formBuilder.group({});
    this.editData = [];
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.guid = uuidv4();
    let formData: FormData = new FormData();
    formData.append('accountId', this.editData['account ID']);
    this.processTrackingService.GetUIElementList(AppSettings.API_ENDPOINT + AppRelativeUrl.EDITPROCESSTRACKING, formData).then
      ((val) => {
        console.log(val)
        //this.DefaultElementData = val.values;
        this.elementList$ = val;
        this.qcs.toFormGroup(this.elementList$, this.form);
        let DataToEdit: any[];
        this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.EDITPROCESSTRACKINGDATA, formData).subscribe
          (
            (result) => {
              DataToEdit = result['editProcessTrackingData'][0];
              this.StatusData = result['statusData'];
              this.IsRMSUser = result['isRMSUser'][0]['isRMSUser'];
              let AccountType=DataToEdit['accountType'];
              console.log(result)
              this.dataShare.ShareAccountType(AccountType);
             
              //DataToEdit.splice(0,1);
              //console.log(result);
              for (let data of Object.keys(this.form.controls)) {
                for (let edit of Object.entries(DataToEdit)) {
                  if (data.toUpperCase() === edit[0].toUpperCase()) {
                    this.form.controls[data].patchValue(edit[1]);
                    break;
                  }
                }
              }
            }
          )
      },
        errors => {
          this.closeEdit.emit(0);//console.log(errors);
          this.errorHandler.ErrorsHandler(errors);

        });

  }

  ngOnInit() {
    this.CheckAuthorized();
  }
  UpdateAccount() {
    if (!this.authorizedForEdit) {
      this.alertService.error(AlertMessages.ALERTMESSAGE15);
      return
    }
     //Added by Dheeraj Bisht on 20-Oct-2023 to check authorization for Close Account
    if(this.form.controls['Status'].value.toUpperCase()==="CLOSED"){
      if(!this.authorizedForCloseAccount){
        this.alertService.error(AlertMessages.ALERTMESSAGE23);
        return
      }
     }
    this.showError = true;
    if (this.form.invalid) {
      return;
    }
    var Package=this.editData['package Type']
    if(Package =='Standard' && this.form.getRawValue()['Work Type']=='Risk Analytics Only'||Package =='Standard' && this.form.getRawValue()['Work Type']=='Cleansing & Risk Analytics'){
      this.form.controls['Package Type'].setValue('Customize')
      this.payLoad =this.form.getRawValue()
      this.payLoad = JSON.stringify( this.payLoad);
    }
    else{
      if(Package =='Standard & Customize' && this.form.getRawValue()['Work Type']=='Risk Analytics Only'){
        // this.form.controls['Package Type'].setValue('Customize')
        this.alertService.error("Work type can not be updated for this Account");
        return
        // this.payLoad =this.form.getRawValue()
        // this.payLoad = JSON.stringify( this.payLoad);
      }
      else{
        this.form.controls['Package Type'].setValue(Package)
        this.payLoad = JSON.stringify(this.form.getRawValue());
      }
     
    }
    // console.log(this.payLoad)
    // return
    // console.log(this.form.getRawValue())
    // this.payLoad = JSON.stringify(this.form.getRawValue());
  
    const formData = new FormData();
    formData.append('accountId', this.editData['account ID']);
    formData.append('EditedAccount', this.payLoad);
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEACCOUNT, formData).subscribe(
      Response => {
        this.RefreshGridData.emit(1);
        this.closeEdit.emit(0);
        this.alertService.success("Account updated successfully.");
      },
      err => {
        this.errorHandler.ErrorsHandler(err);
      });

  }
  CheckAuthorized() {
    var obj: CommonMethod = new CommonMethod();
    this.Userdetail.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      this.authorizedForEdit = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.Edit_Account);
      this.authorizedForCloseAccount=obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.Close_Account);
    })
  }

  // async UploadAdditionaFile(file: any) {
  //   // this.payLoad = JSON.stringify(this.form.getRawValue());
  //   // let filesToUpload: File[] = this.files;
  //   this.UpdateDisabled = true;
  //   const formData = new FormData();
  //   // formData.append('accountId', this.editData['account ID']);
  //   // formData.append('EditedAccount', this.payLoad);
  //   // Array.from(filesToUpload).map((file, index) => {
  //   formData.append('file', file, file.name);
  //   formData.append('guid', this.guid);
  //   // });
  //   this.processTrackingService.upload(AppSettings.API_ENDPOINT + AppRelativeUrl.UPLOADADDITIONALFILE, formData).subscribe(
  //     Response => {
  //       //console.log(Response);
  //       if (((Response['loaded'] / Response['total']) * 100) < 98) {
  //         file.progress = ((Response['loaded'] / Response['total']) * 100).toFixed(2);
  //       }
  //       if (Response['status'] == '200') {//&& (Response['body'] != null || Response['body'] != undefined)) {
  //         file.progress = '100';
  //         this.UpdateDisabled = false;
  //         // this.form.controls['FileName'].setValue(Response['body']['fileName']);
  //         // console.log(Response['body']['fileName']);
  //         //this.alertService.success("File's uploaded successfully.")

  //       }
  //     },
  //     err => {

  //     });

  // }

  close() {
    this.editData = [];

    this.closeEdit.emit(0);
  }

  // /**
  //  * on file drop handler
  //  */
  // onFileDropped($event) {
  //   this.prepareFilesList($event);
  // }

  // /**
  //  * handle file from browsing
  //  */
  // fileBrowseHandler(files) {
  //   this.prepareFilesList(files);

  // }

  // /**
  //  * Delete file from files list and server
  //  * @param index (File index)
  //  */
  // deleteFile(index: number) {
  //   if (this.files[index].progress < 100) {
  //     this.alertService.warn("Upload in progress.");
  //     return;
  //   } else {
  //     const formData = new FormData();
  //     let file = this.files[index]
  //     formData.append('fileName', file.name);
  //     formData.append('guid', this.guid);
  //     this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.DELETEFILE, formData).subscribe(
  //       Response => {
  //         console.log(Response);
  //       },
  //       err => {
  //         this.alertService.error(err);
  //       });
  //     this.files.splice(index, 1);
  //     this.names = null;
  //     for (const file of this.files) {
  //       if (isNullOrUndefined(this.names)) {
  //         this.names = file.name;
  //       } else {
  //         this.names = this.names.concat(' || ', file.name);
  //       }
  //     }
  //     this.form.controls['FileName'].setValue(this.names);
  //   }
  // }
  // /**
  //  * Convert Files list to normal array list
  //  * @param files (Files List)
  //  */
  // prepareFilesList(files: Array<any>) {
  //   this.form.controls['Path'].setValue(this.guid);

  //   for (const file of files) {
  //     file.progress = 0;
  //     if (isNullOrUndefined(this.names)) {
  //       this.names = file.name;
  //     } else {
  //       this.names = this.names.concat(' || ', file.name);
  //     }
  //     this.files.push(file);
  //     this.UploadAdditionaFile(file);
  //   }
  //   this.form.controls['FileName'].setValue(this.names);
  //   //console.log(this.names);
  //   // this.UpdateAccount();
  //   this.fileDropEl.nativeElement.value = "";
  //   // this.uploadFilesSimulator(0);
  // }

  // /**
  //  * format bytes
  //  * @param bytes (File size in bytes)
  //  * @param decimals (Decimals point)
  //  */
  // formatBytes(bytes, decimals = 2) {
  //   if (bytes === 0) {
  //     return "0 Bytes";
  //   }
  //   const k = 1024;
  //   const dm = decimals <= 0 ? 0 : decimals;
  //   const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  // }
}
