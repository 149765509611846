import { Component, OnInit, Input } from '@angular/core';
import { DashboardServiceService } from 'src/services/DashboardService/dashboardService.service';
import { AlertService } from 'src/services/Common/alert.service';
import { ErrorHandler } from 'src/common/error-handler';

@Component({
  selector: 'app-accountManager-dashboard',
  templateUrl: './accountManager-dashboard.component.html',
  styleUrls: ['./accountManager-dashboard.component.css']
})
export class AccountManagerDashboardComponent implements OnInit {
  @Input() clientList: string;

  errorHandler = new ErrorHandler(this.alertService);
  uploadData: any;
  fieldHeader: any[] = [];
  userName: any;
  totalInventory: any;
  accountInQuery: any;
  accountInProgress: any;
  shippedAccount: any;
  downloadedAccount: any;
  accountProcessed: number;
  noOfActiveUser: any;
  locationProcessed:any;

  constructor(private DashboardService: DashboardServiceService,
    private alertService: AlertService) { }

  ngOnInit() {
  }

  getdashboard(clientID: number) {
    console.log(clientID)
    this.DashboardService.GetDashboard(clientID).subscribe(response => {
      //console.log(response)
      this.uploadData = response['tbldata']
      
      this.fieldHeader = [];
      this.fieldHeader = this.fieldHeader.concat(Object.keys(response['tbldata'][0]));
      //console.log(this.fieldHeader)
      
      this.userName = response['tbluserName'];
      this.totalInventory = response['tbltotalinventory'][0]['totalinventory'];
      this.accountInQuery = response['tbltotalinqueue'][0]['totalinque'];
      this.accountInProgress = response['tbltotalinprogress'][0]['totalinprogress'];
      this.shippedAccount = response['tbltotalshipped'][0]['totalshipped'];
      this.downloadedAccount = response['tbltotaldownloaded'][0]['totaldownloaded'];
      this.noOfActiveUser = response['tblactiveuser'][0]['noofactiveusers'];
      this.locationProcessed=response['tbllocationprocessed'][0]['locationprocessed']
      this.accountProcessed = this.shippedAccount + this.downloadedAccount;
      //console.log(this.noOfActiveUser)

    },
      error => {
        this.errorHandler.ErrorsHandler(error);
      });

  }

}
