<hr style="margin-top: 0px !important;" />
<section class="content">
  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor">User Setting</h3>
      </div>
    </div>
  </div>
</section>
<!-- <div class="container-fluid">
  <h4 class="text-info">
    <span class="fa fa-user-cog mr-2"></span>User Setting
  </h4>
</div> -->
<section class="content">
  <div class="card card-default">
    <div class="card-header">
      <div class="container-fluid">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-item nav-link active" id="nav-profile-tab" data-toggle="tab" href="#user-profile" role="tab"
              aria-controls="nav-profile" aria-selected="false">User Profile Setting</a>
            <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#notification-setting" role="tab"
              aria-controls="nav-contact" aria-selected="false">Notification Setting</a>
          </div>
        </nav>

        <div class="tab-content" id="nav-tabContent">
          <!-- start of notification-setting -->

          <div class="tab-pane fade" id="notification-setting" role="tabpanel" aria-labelledby="nav-home-tab">
            <div class="card border-top-0" style="border-radius: 0px !important;">
              <div class="card-body">
                <!-- <div class="row">
                  <div class="col-sm-12">
                    <h6>Alerts for Account :</h6>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        class="custom-control-input"
                        id="customRadio4"
                        name="example"
                        value="customEx4"
                      />
                      <label class="custom-control-label" for="customRadio4"
                        >All</label
                      >
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        class="custom-control-input"
                        id="customRadio3"
                        name="example"
                        checked="true"
                        value="customEx3"
                      />
                      <label class="custom-control-label" for="customRadio3"
                        >Uploaded by me</label
                      >
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-12">
                    <h6>Filter by :</h6>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="input-group mb-3 input-group-sm">
                          <div class="input-group-prepend">
                            <span class="mr-3">Account Type(s)</span>
                          </div>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="input-group mb-3 input-group-sm">
                          <div class="input-group-prepend">
                            <span class="mr-3">Business Unit(s)</span>
                          </div>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <!-- <form
                  [formGroup]="notificationForm"
                  (ngSubmit)="SubmitNotification()"
                >
                  <div class="row">
                    <div class="col-sm-12">
                      <h6>Frequency of Alerts :</h6>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="customRadio"
                          formControlName="radioCheck"
                          [checked]="radioCheck"
                          (click)="RadioChecked($event.target.value)"
                          value="false"
                        />
                        <label class="custom-control-label" for="customRadio"
                          >Individual emails for each scheduled event</label
                        >
                      </div>
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="customRadio2"
                          [checked]="!radioCheck"
                          (click)="RadioChecked($event.target.value)"
                          value="true"
                        />
                        <label class="custom-control-label" for="customRadio2"
                          >Once a day, all emails grouped into one</label
                        >
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <h6>Alert the user when :</h6>
      
                    <div
                      class="form-check"
                      *ngFor="
                        let feature of notificationForm.get('checklist')['controls'];
                        let g = index
                      "
                    >
                      <label formArrayName="checklist" class="form-check-label">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          style="height: 14px;"
                          [formControlName]="g"
                        />
                        {{ ListNotification[g].Desc }}
                      </label>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <button type="submit" style="margin-top: 20px;" class="btn btn-primary btncancel float-left">
                          Update 
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                </form> -->
                <app-notification-user (cancel)="BackAction($event)" [userDetail]="userDetail"> </app-notification-user>
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
          <div class="tab-pane fade show active" id="user-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div class="card border-top-0 mb-3" style="border-radius: 0px !important;">
              <!-- <div class="card-header">User Notification Setting</div> -->
              <div class="card-body">
                <!-- <div *ngIf="messageFlag" class="text-center">
                  <div
                    [ngClass]="{
                      'alert-success': successFlag,
                      'alert-danger': failureFlag
                    }"
                  >
                    <div class="alert alert-dismissible">
                      <a
                        href="#"
                        class="close"
                        data-dismiss="alert"
                        aria-label="close"
                        (click)="Close()"
                        >&times;</a
                      >
                      {{ sucessMessage }}
                    </div>
                  </div>
                </div> -->
                <form [formGroup]="formData" (ngSubmit)="FormSubmit()">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="clientName">Client Name</label>
                        </div>
                        <div class="col-md-9 from-group">
                          <input readonly type="text" formControlName="clientnameTextBox" placeholder="Client Name"
                            class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="userName">User Name</label>
                        </div>
                        <div class="col-md-9 form-group">
                          <input type="text" formControlName="userTextBox" placeholder="User Name"
                            class="form-control" />
                          <div class="ErrorMessage NoPadding" style="color: #c42032;">
                            <div *ngIf="
                                GetFormControls.userTextBox.touched &&
                                GetFormControls.userTextBox.invalid
                              ">
                              <div *ngIf="GetFormControls.userTextBox.errors.required">
                                User Name is required.
                              </div>
                              <div *ngIf="GetFormControls.userTextBox.errors.maxlength">
                                too long
                              </div>
                              <div *ngIf="GetFormControls.userTextBox.errors.minlength">
                                too short
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="clientName">Location</label>
                        </div>
                        <div class="col-md-9 form-group">
                          <select formControlName="locationText" (change)="ChangeLocation($event.target.value)" name="location" id="location" class="form-control">
                            <option *ngFor="let item of locationList" value="{{ item['locationid'] }}">
                              {{ item["city"] }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="timwzone">Time Zone</label>
                        </div>
                        <div class="col-md-9 form-group">
                          <input readonly type="text" formControlName="timezoneSelector" placeholder="Time Zone"
                            class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="email">E-Mail</label>
                        </div>
                        <div class="col-md-9 form-group">
                          <input readonly type="email" formControlName="emailTextBox" placeholder="E-Mail"
                            class="form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="role">Role</label>
                        </div>
                        <div class="col-md-9 form-group">
                          <input readonly type="text" formControlName="role" placeholder="Role" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="email">Extension</label>
                        </div>
                        <div class="col-md-9 form-group">
                          <input formControlName="extensionTextBox" type="extension" placeholder="Extension"
                            class="form-control" />
                          <div class="ErrorMessage NoPadding" style="color: #c42032;">
                            <div *ngIf="
                                GetFormControls.extensionTextBox.touched &&
                                GetFormControls.extensionTextBox.invalid
                              ">
                              <div *ngIf="
                                  GetFormControls.extensionTextBox.errors.pattern
                                ">
                                only numeric value!
                              </div>
                              <div *ngIf="
                                  GetFormControls.extensionTextBox.errors.maxlength
                                ">
                                too long
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="phone">Phone</label>
                        </div>
                        <div class="col-md-9 form-group">
                          <input formControlName="phonenoTextBox" type="text" placeholder="phone"
                            class="form-control" />
                          <div class="ErrorMessage NoPadding" style="color: #c42032;">
                            <div *ngIf="
                                GetFormControls.phonenoTextBox.touched &&
                                GetFormControls.phonenoTextBox.invalid
                              ">
                              <div *ngIf="GetFormControls.phonenoTextBox.errors.pattern">
                                only numeric value!
                              </div>
                              <div *ngIf="
                                  GetFormControls.phonenoTextBox.errors.maxlength
                                ">
                                too long
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isClientUser" class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="phone">Account Type</label>
                        </div>
                        <div class="col-md-9 form-group">
                          <div class="row badge_left">
                            <span *ngFor="let item of acounttypeList" class="badge badge-info list">
                              {{ item["accountTypeName"] }}</span>
                          </div>
                          <!-- <ul>
                            <li *ngFor="let item of acounttypeList">
                              {{ item["accountTypeName"] }}
                            </li>
                          </ul> -->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 form-group">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="clientName">Default Account Type</label>
                        </div>
                        <div class="col-md-9 form-group">
                          <select name="defaultAccount" formControlName="defacctype" id="DefaultAccount"
                            class="form-control">
                            <option value="">-------Select Default Account Type--------</option>
                            <option *ngFor="let item of acounttypeList" value="{{ item['accountTypeID'] }}">
                              {{ item["accountTypeName"] }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isClientUser" class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="timwzone">Business Unit</label>
                        </div>
                        <div class="col-md-9">
                          <div class="row badge_left">
                            <span *ngFor="let item of businessunitList" class="badge badge-info list">
                              {{ item["description"] }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="clientName">Default Business Unit</label>
                        </div>
                        <div class="col-md-9">
                          <select name="defaultAccount" formControlName="default_Business_Unit" class="form-control">
                            <option value="">------Select Default Business Unit--------</option>
                            <option *ngFor="let item of businessunitList" value="{{ item['id'] }}">
                              {{ item["description"] }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isClientUser" class="row" offset='2'>
                    <div class="col-md-6 " style="left: 50%;">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="email">Default Work Type</label>
                        </div>
                        <div class="col-md-9">
                          <!-- <div class="row badge_left">
                            <div class="badge badge-info">
                              {{this.userDetail["workName"] }}
                            </div>
                          </div> -->
                          <!-- <input formControlName="workType" type="extension" placeholder="Extension"
                          class="form-control" /> -->
                          <select name="defaultAccount" formControlName="workType" class="form-control">
                            <option value="">------Select Default Work Type--------</option>
                            <option *ngFor="let item of workTypeList" value="{{ item['workID'] }}">
                              {{ item["workType"] }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!isClientUser" class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-3">
                          <label for="email">Client Mapping</label>
                        </div>
                        <div class="col-md-9">
                          <div class="row badge_left">
                            <span *ngFor="let item of clientList" class="badge badge-info list">
                              {{ item.clientname }}
                            </span>
                          </div>
                          <!-- <ul>
                            <li *ngFor="let item of clientList">
                              {{ item.clientname }}
                            </li>
                          </ul> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="col-md-4 ">
                    <button type="submit" class="btn btn-primary RMSSubmitButton float-left">
                      Update
                    </button>
                    <a class="btn btn-primary RMSButtonCancel" routerLink="/userprofile" style="margin-left: 14px;">
                      Cancel</a>
                  </div>
                </form>
              </div>

              <div class="card-footer">
                <div class="row">
                  <div class="col-sm-12"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- End of User Profile -->

          <!-- Start of Change Password -->
          <div class="tab-pane fade" id="change-password" role="tabpanel" aria-labelledby="nav-contact-tab">
            <!-- <div
              class="card border-top-0 mb-3"
              style="border-radius: 0px !important;"
            > -->
            <!-- <div class="card-header">User Notification Setting</div> -->
            -->
            <!-- End of Change Password -->
          </div>
        </div>
      </div>
    </div>
  </div>

</section>