import { Input, OnChanges, SimpleChanges } from '@angular/core';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { stringify } from 'querystring';
import { Subscription } from 'rxjs';
import { AlertMessages } from 'src/common/alert-messages';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { CommonMethod } from 'src/common/CommonMethod';
import { ErrorHandler } from 'src/common/error-handler';
import { FeatureMaster } from 'src/common/feature-master';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AlertService } from 'src/services/Common/alert.service';
import { ProcessTrackingService } from 'src/services/Process-TrackingServices/process-tracking.service';
import { v4 as uuidv4 } from 'uuid'
@Component({
  selector: 'app-upload-additional-shipment',
  templateUrl: './upload-additional-shipment.component.html',
  styleUrls: ['./upload-additional-shipment.component.css']
})
export class UploadAdditionalShipmentComponent implements OnInit {

  AdditionalShipmentFrom: UntypedFormGroup;
  guid: any;
  fileList: any[];
  fileToUpload: File = null;
  @Input() AccountData: [];
  @Input() IsRAAdditionalShipment: boolean = false;
  @Input() PackageType: number;
  names: string;
  AccountName: string;
  maxFileCount: number;
  subscribeUpload: Subscription[];
  AdditionalUploadedFiles: any[];
  UpdateDisabled: boolean;
  fileError: string;
  authorizedForUploadAccount: boolean;
  isTyping: boolean
  errorHandler: ErrorHandler = new ErrorHandler(this.alertService);
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @Output() public closeAdditionalUpload = new EventEmitter();

  constructor(private processTrackingService: ProcessTrackingService, private alertService: AlertService,
    private formBuilder: UntypedFormBuilder, private Userdetail: AddEditUserSerivceService) {
    this.AdditionalShipmentFrom = this.formBuilder.group({
      ShipmentComment: [''],
      FileName: [''],
      Path: ['']
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fileError = '';
    this.AdditionalShipmentFrom.reset();
    this.guid = uuidv4();
    this.AccountName = this.AccountData['account Name'];
    let AccountId = this.AccountData['account ID'];
    const formData = new FormData();
    formData.append('accountId', AccountId);
    formData.append('IsRAShipment', JSON.stringify(this.IsRAAdditionalShipment));
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETADDITIONALSHIPMENTDETAILS, formData).subscribe(
      Response => {
        this.AdditionalUploadedFiles = Response['uploadedFilesDetails'] as [];
      },
      err => {
        this.closeAdditionalUpload.emit(0);
        this.errorHandler.ErrorsHandler(err);
      });
    //console.log('IsRAAdditionalShipment :'+this.IsRAAdditionalShipment)
  }

  ngOnInit() {
    this.subscribeUpload = [];
    this.fileList = [];
    this.UpdateDisabled = false;
    this.AdditionalUploadedFiles = [];
    this.maxFileCount = 1;
    this.CheckAuthorized()
  }

  close() {
    const formData = new FormData();
    formData.append('guid', this.guid);
    this.closeAdditionalUpload.emit(0);
  }

  UploadShipment() {
    if (!this.authorizedForUploadAccount) {
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
      return;
    }

    if (this.fileList.length === 0) {
      this.fileError = "Please provide file's to upload."
      return;
    } this.fileError = "";
    let jobId: number;
    let formData1: FormData = new FormData();
    formData1.append('accountId', this.AccountData['account ID']);
    formData1.append('jobId', String(0));
    formData1.append('jobName', "Additional Shipment");
    formData1.append('jobTypeId', String(3));//upload Shipment
    formData1.append('errorMessage', "");
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEJOBFORDOWNLOAD, formData1).subscribe(res => {

      jobId = Number(res);
      const formData = new FormData();
      formData.append('AccountId', this.AccountData['account ID']);
      formData.append('ShipmentComment', this.AdditionalShipmentFrom.get('ShipmentComment').value == null ? '' : this.AdditionalShipmentFrom.get('ShipmentComment').value);
      formData.append('IsRAShipment', JSON.stringify(this.IsRAAdditionalShipment));
      formData.append('UploadType', String(this.PackageType));
      formData.append('jobId', JSON.stringify(jobId));
      formData.append('file', this.fileList[0], this.fileList[0].name);
      this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.UPLOADADDITIONALSHIPMENT, formData).subscribe(result => {
        if (result) {
          this.alertService.success('Additional shipment uploaded successfully.')
          this.AdditionalShipmentFrom.reset();
          this.fileList = [];
          this.closeAdditionalUpload.emit(0);
        }
      },
        err => {
          this.errorHandler.ErrorsHandler(err);
        })
    }, err => {
      jobId = 0;
    })
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    if ($event.length <= this.maxFileCount && (this.fileList.length + $event.length) <= this.maxFileCount) {
      this.fileList = [];
      this.fileToUpload = null;
      // if (this.IsFileNameValid($event[0]["name"])) {
      if (this.IsFileNameValid_Dheeraj($event[0]["name"])) {
        this.fileError = '';
        this.ValidateFilesList($event);
      }
      else {
        return;
      }
    }
    else {
      //this.alertService.error("Only " + this.maxFileCount + " shipment packet can be uploaded.")
      this.fileError = "Only " + this.maxFileCount + " shipment packet can be uploaded.";
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.fileList = [];
    this.fileToUpload = null;
    // if (this.IsFileNameValid(files.item(0).name)) {
    if (this.IsFileNameValid_Dheeraj(files.item(0).name)) {
      this.fileError = '';
      this.ValidateFilesList(files);
    }
    else {
      return;
    }

  }
  IsFileNameValid(name: string): boolean {
    let uploadType: string;
    if (this.IsRAAdditionalShipment)
      uploadType = "RA";
    else
      uploadType = "DES";
    let ShippedCount = this.AdditionalUploadedFiles.filter(x => x['isAdditionalShipment'] == 0).length;
    if (ShippedCount != 1) {
      window.scrollTo(0, 0);
      this.alertService.error("Additional shipment can't be done without initial shipment.")
      return false;
    }
    let AdditionalCount = this.AdditionalUploadedFiles.filter(x => x['isAdditionalShipment'] == 1).length + 1;
    let fileName: string = this.AccountName + '_' + uploadType + '_Additional' + AdditionalCount + '.zip';

    if (name.toUpperCase().includes(fileName.toUpperCase()))
      return true;
    else {
      this.fileError = "Shipment file name should be '" + fileName + "'."
      return false;
    }
  }

  IsFileNameValid_Dheeraj(name: string): boolean {
    console.log(this.AdditionalUploadedFiles)
    let ShippedCount = this.AdditionalUploadedFiles.filter(x => x['isAdditionalShipment'] == 0).length;
    console.log(ShippedCount);
    if (ShippedCount == 0) {
      window.scrollTo(0, 0);
      this.alertService.error("Additional shipment can't be done without initial shipment.")
      return false;
    }
    let uploadType: string;
    if (this.PackageType == 1) {
      uploadType = "Standard";
    }
    if (this.PackageType == 3) {
      uploadType = "DES";
    }
    if (this.PackageType == 2) {
      uploadType = "RA";
    }
    let AdditionalCount = this.AdditionalUploadedFiles.filter(x => x['isAdditionalShipment'] == 1).length + 1;
    let fileName: string = this.AccountName + '_' + uploadType + '_Additional' + AdditionalCount + '.zip';
    if (name.toUpperCase().includes(fileName.toUpperCase()))
      return true;
    else {
      this.fileError = "Shipment file name should be '" + fileName + "'."
      return false;


    }


  }
  /**
   * Delete file from files list and server
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.subscribeUpload.splice(index, 1);
    this.fileList.splice(index, 1);
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  ValidateFilesList(files: Array<any>) {
    this.AdditionalShipmentFrom.controls['Path'].setValue(this.guid);
    files[0].progress = 0;
    let fileExtension: string = files[0]["name"];
    fileExtension = fileExtension.substr(fileExtension.lastIndexOf('.') + 1, fileExtension.length);
    if (files[0]["size"] < 1) {
      this.fileError = 'File(s) ' + files[0]['name'] + " have 0 Kb size, please attach valid file";
      return;
    }
    if (this.fileList.some(el => el['name'] == files[0]['name'])) {
      this.fileError = files[0]['name'] + " already provided for upload";
      return;
    }
    this.fileError = "";
    this.names = files[0].name;
    this.fileList.push(files[0]);
    this.fileDropEl.nativeElement.value = "";

    this.AdditionalShipmentFrom.controls['FileName'].setValue(this.names);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  CheckAuthorized() {
    var obj: CommonMethod = new CommonMethod();
    this.Userdetail.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      this.authorizedForUploadAccount = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.Upload_Additional_Shipment);
    })
  }
  showremainingcharacter() {
    this.isTyping = true
  }
}

