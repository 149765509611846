<hr style="margin-top: 0px !important;" />
<section class="content">
  <div class="container-fluid">
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor">Dashboard</h3>
        <div class="card-tools" style="margin-left: 20px;">
          <div class="input-group input-group-sm" style="width: 150px;">

            <h3 class="card-title" style="font-size: medium;margin-top: 5px ;margin-right: 5px;">User</h3>

            <select (change)="getdashboard($event.target.value)" class="form-control">
              <option [value]='0'>All</option>
              <option *ngFor='let item of UserList' [value]="item.userid">{{item.userName}}</option>

            </select>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="content">
  <div class="container-fluid">
    <app-card-header [locationProcessed]="locationProcessed" [noOfActiveUser]="noOfActiveUser"
      [contractEndDate]="contractEndDate" [accountProcessed]="accountProcessed"></app-card-header>
    <div class="row">
      <div class="col-md-8">
        <app-account-planned *ngIf="isData" [hasUploadAccess]="hasUploadAccess" [accountPlanned]="uploadData">
        </app-account-planned>
        <div class="row">
          <div class="col">
            <app-recently-added-queries *ngIf="View_Message_Center" [RaiseReply_Query]="RaiseReply_Query"
              [messageContent]="messageContent"></app-recently-added-queries>
          </div>


        </div>
    
      

      </div>
      <div class="col-md-4">
        <app-total-account-status [totalInventory]="totalInventory" [accountInProgress]="accountInProgress"
          [cancelledAccount]="cancelledAccount" [downloadedAccount]="downloadedAccount"
          [shippedAccount]="shippedAccount" [accountInQuery]="accountInQuery">
        </app-total-account-status>
      
      </div>



    </div>

  </div>
</section>

<div class="row">
  <div class="col">
    <app-latest-users [View_User]="View_User" [username]='userName'></app-latest-users>
  </div>
</div>
 
<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-header border-transparent">
        <h3 class="card-title MoodysTittleColor">Userwise Inventory detail</h3>

        <div class="card-tools">
          <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-minus"></i>
          </button>
          <button type="button" class="btn btn-tool" data-card-widget="remove">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <!-- /.card-header -->
      <div class="card-body p-0">
        <div class="table-responsive">
          <h6 *ngIf="!Inventorydetail" style="padding-left: 20px;">
            No Records Found
          </h6>

          <table class="table m-0" *ngIf="Inventorydetail">
            <thead>
              <tr>
                <th *ngFor="let header of headerForAccount " class="text-center" style="font-size:small;">
                  <div *ngIf="header!='total Inventory' && header!='in Progress'&& header!='downloaded/Closed' && header!='in Query'&& header!='shipped'
                  && header!='cancelled'">
                    <span>{{ header | titlecase }}</span>
                  </div>
                  <div *ngIf="header=='total Inventory'">
                    <span class="badge badge-warning">{{ header | titlecase }}</span>
                  </div>
                  <div *ngIf="header=='in Progress'">
                    <span  class="badge badge-info">{{ header | titlecase }}</span>
                  </div>
                  <div *ngIf="header=='downloaded/Closed'">
                    <span class=" badge badge-dark">{{ header | titlecase }}</span>
                  </div>
                  <div *ngIf="header=='in Query'">
                    <span class="badge badge-danger">{{ header | titlecase }}</span>
                  </div>
                  <div *ngIf="header=='shipped'">
                    <span class="badge badge-success">{{ header | titlecase }}</span>
                  </div>
                  <div *ngIf="header=='cancelled'">
                    <span class="badge badge-secondary">{{ header | titlecase }}</span>
                  </div>
          

                </th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr>
                <td>Pre-Bind</td>
                <td>Property</td>
                <td>Test Account 1</td>
                <td><span class="badge badge-warning">In Queue</span></td>
                <td>
                  <div class="sparkbar" data-color="#00a65a" data-height="20">High</div>
                </td>
              </tr>
              <tr>
                <td>Pre-Bind</td>
                <td>Property</td>
                <td>Test Account 2</td>
                <td><span class="badge badge-danger">In Query</span></td>
                <td>
                  <div class="sparkbar" data-color="#00a65a" data-height="20">High</div>
                </td>
              </tr>
              <tr>
                <td>Pre-Bind</td>
                <td>Property</td>
                <td>Test Account 3</td>
                <td><span class="badge badge-info">In QA</span></td>
                <td>
                  <div class="sparkbar" data-color="#00a65a" data-height="20">High</div>
                </td>
              </tr> -->
              <tr *ngFor="let item of accountSummary  let i = index" >
                <td *ngFor="let items of headerForAccount" class="text-center" >
                  <div *ngIf="items!='total Inventory' && items!='cancelled' && items!='in Progress'&& items!='in Query' 
                  && items!='shipped' && items!='downloaded/Closed' && items!='username'">
                    <span>
                      {{item[items]}}
                    </span>
                  </div>
                  <div *ngIf="items == 'total Inventory'">
                    <span class="badge badge-warning" >
                      {{ item[items] }}

                    </span>
                  </div>
                  <div *ngIf="items == 'cancelled'">
                    <span class="badge badge-secondary" >
                      {{ item[items] }}

                    </span>
                  </div>
                  <div *ngIf="items == 'in Progress'">
                    <span class="badge badge-info" >
                      {{ item[items] }}

                    </span>
                  </div>
                  <div *ngIf="items == 'in Query'">
                    <span class="badge badge-danger" >
                      {{ item[items] }}

                    </span>
                  </div>
                  <div *ngIf="items == 'shipped'">
                    <span class="badge badge-success" >
                      {{ item[items] }}

                    </span>
                  </div>
                  <div *ngIf="items == 'downloaded/Closed'">
                    <span class="badge badge-dark" >
                      {{ item[items] }}

                    </span>
                  </div>
                  <div *ngIf="items == 'username'">
                    <span  style="font-weight:600;" >
                      {{ item[items] }}

                    </span>
                  </div>
                  
                  
                </td>
                <td>
                  <div class="sparkbar" data-color="#00a65a" data-height="20">{{item.priority}}</div>
                </td>
              </tr>
              <!-- <tr>
                <td>Pre-Bind</td>
                <td>Quote</td>
                <td>Test Account 5</td>
                <td><span class="badge badge-success">Downloaded</span></td>
                <td>
                  <div class="sparkbar" data-color="#00a65a" data-height="20">Medium</div>
                </td>
              </tr>
              <tr>
                <td>Pre-Bind</td>
                <td>Quote</td>
                <td>Test Account 6</td>
                <td><span class="badge badge-danger">In Query</span></td>
                <td>
                  <div class="sparkbar" data-color="#00a65a" data-height="20">Medium</div>
                </td>
              </tr>
              <tr>
                <td>Pre-Bind</td>
                <td>Quote</td>
                <td>Test Account 7</td>
                <td><span class="badge badge-success">Shipped</span></td>
                <td>
                  <div class="sparkbar" data-color="#00a65a" data-height="20">Medium</div>
                </td>
              </tr> -->


            </tbody>
          </table>
        </div>
        <!-- /.table-responsive -->
      </div>
      <!-- /.card-body -->
      <!-- <div class="card-footer clearfix">
        <a href="javascript:void(0)" *ngIf="hasUploadAccess"  class="btn btn-sm btn-info float-left">Upload New
          Account</a>
        <a href="javascript:void(0)" routerLink="/processtracking" class="btn btn-sm btn-secondary float-right">View
          All Accounts</a>
      </div> -->
      <!-- /.card-footer -->
    </div>
  </div>
</div>
