export class DownloadShipmentModel {
    AccountName: string;
    FileName: string;
    Message:string;
    AccountType:string;
    BusinessUnit:string;
    WorkType:string;
    status:number;
    Progress:any;
    JobType: string;
}