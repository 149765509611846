import { Input, OnChanges, SimpleChanges } from '@angular/core';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AlertMessages } from 'src/common/alert-messages';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { CommonMethod } from 'src/common/CommonMethod';
import { ErrorHandler } from 'src/common/error-handler';
import { FeatureMaster } from 'src/common/feature-master';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AlertService } from 'src/services/Common/alert.service';
import { ProcessTrackingService } from 'src/services/Process-TrackingServices/process-tracking.service';
import { DynamicControlService } from 'src/services/Upload/DynamicControlService';
import { v4 as uuidv4 } from 'uuid'
@Component({
  selector: 'app-upload-additional',
  templateUrl: './upload-additional.component.html',
  styleUrls: ['./upload-additional.component.css'],
  providers: [DynamicControlService]
})
export class UploadAdditionalComponent implements OnInit, OnChanges {

  form: UntypedFormGroup;
  guid: any;
  fileList: any[];
  payLoad = '';
  @Input() AccountData: [];
  names: string;
  AccountName: string;
  maxFileCount: number;
  maxFileSize: number;
  fileUploaded: any;
  subscribeUpload: Subscription[];
  AdditionalUploadedFiles: any[];
  usedFileSize: number
  UpdateDisabled: boolean;
  fileSizeError: string;
  AllowedFileFormat: any[];
  authorizedForUploadAccount: boolean;
  isTyping: boolean
  errorHandler: ErrorHandler = new ErrorHandler(this.alertService);
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @Output() public closeAdditionalUpload = new EventEmitter();
  constructor(private processTrackingService: ProcessTrackingService, private alertService: AlertService,
    private formBuilder: UntypedFormBuilder, private Userdetail: AddEditUserSerivceService) {
    this.form = this.formBuilder.group({
      AdditionalInstruction: [''],
      FileName: [''],
      Path: ['']
    });

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.guid = uuidv4();
    this.AccountName = this.AccountData['account Name'];
    let AccountId = this.AccountData['account ID'];
    const formData = new FormData();
    formData.append('accountId', AccountId);
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUPLOADEDFILESDETAILS, formData).subscribe(
      Response => {
        this.AdditionalUploadedFiles = Response['uploadedFilesDetails'] as [];
        this.usedFileSize = Response['usedFileSize'][0]['usedFileSize'];
      },
      err => {
        this.closeAdditionalUpload.emit(0);
        this.errorHandler.ErrorsHandler(err);
      });
  }

  ngOnInit() {
    this.subscribeUpload = [];
    this.fileList = [];
    this.fileUploaded = 0;
    this.UpdateDisabled = false;
    this.AdditionalUploadedFiles = [];
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETMAXFILESIZE, new FormData).subscribe(
      result => {
        this.maxFileCount = result[0]["maxFileCount"];
        this.maxFileSize = result[0]["maxFileSize"];
      },
      err => {
        this.closeAdditionalUpload.emit(0);
        this.errorHandler.ErrorsHandler(err);
      });
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETALLOWEDFILEFORMAT, new FormData).subscribe(result => {
      this.AllowedFileFormat = result as [];
    },
      err => {
        this.closeAdditionalUpload.emit(0);
        this.errorHandler.ErrorsHandler(err);
      })
    this.CheckAuthorized()
  }

  async UploadAdditionaFile(file: any) {

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('guid', this.guid);
    
    this.UpdateDisabled = true;
    
    // });
    let sub = this.processTrackingService.upload(AppSettings.API_ENDPOINT + AppRelativeUrl.UPLOADADDITIONALFILE, formData).subscribe(
      Response => {
        //console.log(Response);
        if (((Response['loaded'] / Response['total']) * 100) < 98) {
          file.progress = ((Response['loaded'] / Response['total']) * 100).toFixed(2);
        }
        if (Response['status'] == '200') {//&& (Response['body'] != null || Response['body'] != undefined)) {
          file.progress = '100';
          let uploadedAll: boolean;
          for (let i = 0; i < this.fileList.length; i++) {
            uploadedAll = false;
            if (this.fileList[i].progress < 100) {
              uploadedAll = true;
              break;
            }
          }
          this.UpdateDisabled = uploadedAll;
        }
      },
      err => {
        this.errorHandler.ErrorsHandler(err);
      });
    this.subscribeUpload.push(sub);
    this.fileList.push(file);
  }

  close() {

    const formData = new FormData();
    formData.append('guid', this.guid);
   
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.DELETEFILE, formData).subscribe(
      Response => {
        // console.log(Response);
      },
      err => {
        this.errorHandler.ErrorsHandler(err);
      });
    this.closeAdditionalUpload.emit(0);
  }

  UpdateAccount() {

    if (!this.authorizedForUploadAccount) {
      this.alertService.warn(AlertMessages.ALERTMESSAGE15);
      return
    }

    if (this.fileList.length === 0) {
      this.fileSizeError = "Please provide file's to upload."
      return;
    } this.fileSizeError = "";
    this.payLoad = JSON.stringify(this.form.getRawValue());
    // console.log(this.payLoad);
    this.fileList;
    let totalFileSize=0;
    this.fileList.forEach(element => { totalFileSize=totalFileSize+element.size});
    totalFileSize=totalFileSize/ (1024 * 1024);
    const formData = new FormData();
    formData.append('accountId', this.AccountData['account ID']);
    formData.append('EditedAccount', this.payLoad);
    formData.append('fileSize', totalFileSize.toString()); 
    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEADDITIONALDETAILS, formData).subscribe(
      Response => {
        this.alertService.success("Account updated successfully.");
        this.form.reset();
        this.fileList = [];
        this.names = '';
        this.guid = uuidv4();
        this.closeAdditionalUpload.emit(0);
        this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUPLOADEDFILESDETAILS, formData).subscribe(
          Response => {
            this.AdditionalUploadedFiles = Response['uploadedFilesDetails'] as [];
            this.usedFileSize = Response['usedFileSize'][0]['usedFileSize'];
          },
          err => {
            this.errorHandler.ErrorsHandler(err);
          });
      },
      err => {
        this.errorHandler.ErrorsHandler(err);
      });  

  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);

  }

  /**
   * Delete file from files list and server
   * @param index (File index)
   */
  deleteFile(index: number) {

    let file = this.fileList[index]
    if (this.fileList[index].progress < 100) {
      this.subscribeUpload[index].unsubscribe();
      this.UpdateDisabled = false;
    } else {
      const formData = new FormData();
      formData.append('fileName', file.name);
      formData.append('guid', this.guid);
 
      this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.DELETEFILE, formData).subscribe(
        Response => {
          //console.log(Response);
        },
        err => {
          this.errorHandler.ErrorsHandler(err);
        });
      //this.fileList.splice(index, 1);      
    }
    this.names = this.names.replace(file.name, '').trim();
    this.names = this.names.replace('||  ||', '||').trim();
    if (this.names.startsWith('||'))
      this.names = this.names.substr(2, this.names.length).trim();
    if (this.names.endsWith('||'))
      this.names = this.names.substr(0, this.names.length - 2).trim();


    this.fileUploaded = this.fileUploaded - Math.round(this.fileList[index]["size"] / (1024 * 1024));
    this.subscribeUpload.splice(index, 1);
    this.fileList.splice(index, 1);
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    this.form.controls['Path'].setValue(this.guid);
    if (files.length <= this.maxFileCount && (this.fileList.length + files.length) <= this.maxFileCount) {
      let alreadyAddedFiles: string = '';
      let wrongFormatFiles: string = '';
      let zeroSizeFiles: string = '';

      for (const item of files) {
        item.progress = 0;
        let fileExtension: string = item["name"];
        fileExtension = fileExtension.substr(fileExtension.lastIndexOf('.') + 1, fileExtension.length);
        if (!this.AllowedFileFormat.some(el => String(el["fileFormat"]).toUpperCase().trim() == fileExtension.toUpperCase().trim())) {
          if (wrongFormatFiles == '')
            wrongFormatFiles = item['name'];
          else
            wrongFormatFiles = wrongFormatFiles + ',' + item['name'];
          // this.fileSizeError = "One of attached file(s) is not valid, please attach a valid file and try again";
          continue;
        }
        if (item["size"] < 1) {
          if (zeroSizeFiles == '')
            zeroSizeFiles = item['name'];
          else
            zeroSizeFiles = zeroSizeFiles + ',' + item['name'];
          continue;
        }
        if (this.fileList.some(el => el['name'] == item['name'])) {
          if (alreadyAddedFiles == '')
            alreadyAddedFiles = item['name'];
          else
            alreadyAddedFiles = alreadyAddedFiles + ',' + item['name'];
          continue;
        }

        if ((this.fileUploaded + this.usedFileSize + Math.round(item["size"] / (1024 * 1024))) > this.maxFileSize) {
          this.fileSizeError = (this.fileUploaded + this.usedFileSize) + " MB is already used, can't upload more than " + (this.maxFileSize) + " MB in this account";
          return;
        }
        this.fileUploaded = this.fileUploaded + Math.round(item["size"] / (1024 * 1024));
        this.fileSizeError = "";

        if (this.names == undefined || this.names == '') {
          this.names = item.name;
        } else {
          this.names = this.names.concat(' || ', item.name);
        }
        this.UploadAdditionaFile(item);
      }
      if (alreadyAddedFiles != '')
        this.fileSizeError = alreadyAddedFiles + " already provided for upload";

      if (wrongFormatFiles != '')
        this.fileSizeError = 'File(s) ' + wrongFormatFiles + " not valid, please attach valid file";
      if (zeroSizeFiles != '')
        this.fileSizeError = 'File(s) ' + zeroSizeFiles + " have 0 Kb size, please attach valid file";
      this.fileDropEl.nativeElement.value = "";



    }
    else {
      document.getElementById("title").scrollIntoView();
      this.alertService.error("Can't upload more than " + this.maxFileCount + " files for this account.")
      this.fileSizeError = "Can't upload more than " + this.maxFileCount + " files for this account.";
    }
    this.form.controls['FileName'].setValue(this.names);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  CheckAuthorized() {
    var obj: CommonMethod = new CommonMethod();
    this.Userdetail.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      this.authorizedForUploadAccount = obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.Edit_Account);
    })
  }
  showremainingcharacter() {
    this.isTyping = true
  }
}
