<!-- <div class=" setBGImage">

  <div class="container MainContainer">
    <div class="row">
    
      <div class="col-lg-6">
        <img  src="../../../assets/img/RMS.png" style="width:102%;">
        <br />
        <img src="../../../assets/img/MoodysRMSLogo1.png" style="    width: 82%;
        margin-top: 65px;
        margin-left: 52px;" />
      </div>
      <div class="col-lg-6">
        <div class="container" style="margin: 30% auto">

          <div class="text-center">
            <h4>
              You have been successfully logged out.
            </h4>
            <p class="lead">
              Please <a routerLink="/login">Click Here</a> to login again.
            </p>
          </div>

        </div>

      </div>
    </div>
    <div class="row">
      <div class="col" style="text-align:center;">
        <h6 style="font-size: .8rem; color:#989696;">© {{Year | date: "y"}} Risk Management Solutions, Inc. All Rights Reserved.</h6>
        <br />
      </div>
    </div>
  </div>
</div> -->

<div class=" setBGImage">

  <div class="main"  >
    <div class="container" style="width: 35%;padding-top: 64px;">
      <div class="align-middle">
        <div class="card"
          style="align-items: center;border-radius: 30px;padding-left: 25px;padding-right:25px;width: fit-content;height: 530px;">
          <div style="text-align: center;">
            <div class="app-header">
              <img src="../../../assets/svg/MoodysBlue.svg" alt="Logo" class="app-logo">
              <h3 class="MoodyTittleColor2">Data Solution Portal</h3>
            </div>
          </div>
          <div class="card-body" style="width: -webkit-fill-available;">
            <div class="text-center">
              <h5>
                You have been successfully logged out.
              </h5>
              <p class="lead">
                Please <a routerLink="/login">Click Here</a> to login again.
              </p>
            </div>
          </div>
          <div style="padding-top: 70px;width: 100%;" class="row">
            <div class="col MoodyTittleColor2" style="text-align:center;">
              <h6 style="font-size: xx-small;">© {{Year | date: "y"}}  Moody's Analytics, Inc. and/or its licensors and affiliates (collectively, "Moody's"). All rights reserved.
                Reserved.</h6>
              <br />
            </div>
          </div>  
        </div>
      </div>

    </div>
  </div>
</div>