<div class="container-fluid mt-3">
  <form [formGroup]="notificationForm" (ngSubmit)="SubmitNotification()">
    <div class='col-lg-12 row'>
      <div class='col-lg-6 row'>
        <div class=col-lg-4>
          <h6>Alerts for Account : </h6>
        </div>
        <div class='col-lg-8'>
          <div class='row'>
            <div class='col-lg-6'>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" class="custom-control-input" id="customRadio4" name="example" (click)="UploadByMe()"
                  [checked]="isAlertOnAllAccount" />
                <label class="custom-control-label" for="customRadio4">All</label>
              </div>
            </div>
            <div class='col-lg-6'>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" class="custom-control-input" id="customRadio3" name="example" (click)="UploadByMe()"
                  [checked]="!isAlertOnAllAccount" />
                <label class="custom-control-label" for="customRadio3">Uploaded by me</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isAlertOnAllAccount" class='col-lg-6 row'>
        <div class=col-lg-3>

          <h6>Filter by :</h6>
        </div>
        <div class='col-lg-9'>
          <div class="row">
            <div class='col-lg-6'>
              <ng-select formControlName="AccountType" placeholder="Account Type" [multiple]="true"
                [(ngModel)]="selectedaccountType">
                <ng-option *ngFor="let item of accounttypeList" [value]="item.accountTypeId">
                  {{ item.accountTypeName }}
                </ng-option>
              </ng-select>
            </div>
            <div class="col-lg-6">
              <div class='col-lg-12'>
                <ng-select formControlName="BusinessType" [multiple]="true" placeholder="Business Unit"
                  [(ngModel)]="selectedBusinessUnit">
                  <ng-option *ngFor="let item of businesstypeList" [value]="item.businessUnitId">
                    {{ item.description }}
                  </ng-option>
                </ng-select>
              </div>


            </div>
          </div>
        </div>


      </div>
    </div>

    <hr>


    <!-- <div class="row">
            <div class="col-sm-12 row">
              <div class=col-lg-2> <h6>Alerts for Account : </h6></div>
              <div class="custom-control custom-radio custom-control-inline" style="margin-left: 4px;">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="customRadio4"
                  name="example"
                  (click)="CheckOnlyMe()"
                  [checked]="!onlyMe"
                />
                <label class="custom-control-label" for="customRadio4">All</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="customRadio3"
                  name="example"
                  (click)="CheckOnlyMe()"
                  [checked]="onlyMe"
                />
                <label class="custom-control-label" for="customRadio3"
                  >Uploaded by me</label
                >
              </div>
            </div>
          </div> -->

    <!-- <div class="row">           
            <div class="col-sm-12 row">
             <div class=col-lg-2>
               <h6>Filter by :</h6
                ></div> 
              <div class="row ">
                <div class="col-lg-5">
                  <div class="row">
                 <div class='col-lg-12'>
                     <ng-select
                  formControlName="AccountType"
                  placeholder="Account Type"
                  [multiple]="true"
                  [(ngModel)]="selectedaccountType"
                >
                  <ng-option
                    *ngFor="let item of accounttypeList"
                    [value]="item.accountTypeid"
                  >
                    {{ item.accountTypeName }}
                  </ng-option>
                </ng-select></div>
                  
                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="row">
                    <div class='col-lg-12'>
                      <ng-select
                      formControlName="BusinessType"
                      [multiple]="true"
                      placeholder="Business Unit"
                      [(ngModel)]="selectedBusinessUnit"
                    >
                      <ng-option *ngFor="let item of businesstypeList">
                        {{ item.description }}
                      </ng-option>
                    </ng-select>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div> -->
    <div class="row">
      <div class="col-sm-12">
        <div class="row">
          <div class='col-lg-3'>
            <h6>Frequency of Alerts :</h6>
          </div>
          <div class='col-lg-9'>
            <div class='row'>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" class="custom-control-input" id="customRadio" formControlName="radioCheck"
                  [checked]="radioCheck" (click)="RadioChecked($event.target.value)" value="false" />
                <label class="custom-control-label" for="customRadio">Individual emails for each scheduled event</label>
              </div>

              <div class='col-lg-6'>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" formControlName="radioCheck" class="custom-control-input" id="customRadio2"
                    [checked]="!radioCheck" (click)="RadioChecked($event.target.value)" value="true" />
                  <label class="custom-control-label" for="customRadio2">
                    <p>Once a day, all emails grouped into one</p>
                  </label>
                </div>
              </div>
            </div>

          </div>



        </div>

      </div>
    </div>
    <hr />
    <div>
      <h6>Alert the user when :</h6>
      <div class='row'>
        <div style="margin-left:5px" class="form-check col-lg-3" *ngFor="
                let feature of notificationForm.get('checklist')['controls'];
                let g = index
              ">
          <div>
            <label formArrayName="checklist" class="form-check-label">
              <input [attr.disabled]="ListNotification[g].id == '8'? '' : null" type="checkbox" class="form-check-input"
                style="height: 14px;" [formControlName]="g" />
              {{ ListNotification[g].Desc }}
            </label>
          </div>
        </div>
      </div>      
      <div class='row '>
        <div class="col-lg-12">
          <button *ngIf="allowaddeditAction || userDetail.userID == userId && previousUrl !='/usermanagement'" type="submit"
            class="btn RMSSubmitButton btnadd btn-primary float-left">
            Update
          </button>
          <!-- <button type="button"  routerLink="/userprofile" class="btn btn-primary  btnadd RMSButtonCancel "
            style="  margin-left: 14px;">
            Cancel
          </button> -->
          <button type="button" (click)="Cancel()" class="btn btn-primary  btnadd RMSButtonCancel "
            style="  margin-left: 14px;">
            Cancel
          </button>
        </div>

      </div>
    </div>
    <hr />
  </form>
  <!-- </div>
    </div> -->
</div>