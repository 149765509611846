import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LogoutService } from './../../../services/LoginServices/logout.service';
import {AppSettings} from '../../../common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { DataShareService } from 'src/services/Common/dataShare.service';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./../login/login.component.css']
})
export class LogoutComponent implements OnInit {
Year:string=Date();
  constructor(private route: Router, private cookieService: CookieService, private logoutService: LogoutService, private ShareData: DataShareService) { }

  ngOnInit() {

    // this.ShareData.downloadShipmentProcess(null);

    let token = this.cookieService.get('ApiQuote');
    // console.log(token);
    if (token === null || token === '') {
      
      // this.route.navigate(['login']);
      // return;
    }
    else {
      this.logoutService.DeleteToken(AppSettings.API_ENDPOINT +   AppRelativeUrl.LOGOUT,).subscribe(
        res => {
          console.log(res);          
        },
        err => {
          console.log(err);
        }
      );
      this.cookieService.delete('ApiQuote');
      this.cookieService.delete('Refresh');
      //this.cookieService.delete('UserId');
      this.cookieService.delete('UserName');



      // /// # call the api to logout the token  
      // this.logoutService.DeleteToken(AppSettings.API_ENDPOINT + 'Login/LogOut').subscribe(result => {
      //   // console.log('result');
      //   // console.log(result);
      //   this.coookieService.delete('ApiQuote');
      // },
      //   error => {
      //     console.log('error');
      //     console.log(error);
      //   })
    }

    //// deletethe cookie after calling the api


  }
}
