import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as pbi from 'powerbi-client';
import { Subscription } from 'rxjs';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertService } from 'src/services/Common/alert.service';
import { PowerBiService } from 'src/services/powerbi.service';

@Component({
  selector: 'app-PowerbiReport',
  templateUrl: './PowerbiReport.component.html',
  styleUrls: ['./PowerbiReport.component.css']
})
export class PowerbiReportComponent implements OnInit {
  @Output() pagesFetched: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild('reportContainer') reportContainer: ElementRef;
  themeSubscription!:  Subscription;
  isDarkTheme: boolean = false;
  reportName:any;
  private pbiService: pbi.service.Service;
  
  // report1:any;
  private pageChangeSubscription: Subscription;
  private Reportchangesubscription: Subscription;
  private report: pbi.Report;
  constructor(private powerBiService: PowerBiService,private alertService: AlertService) {
    this.pbiService = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    
  }
  errorHandler = new ErrorHandler(this.alertService);

  ngOnInit(): void {
    
  
    // this.Reportchangesubscription = this.powerBiService.ReportSelected$.subscribe(
    //   Reportname => {
        
    //     if(Reportname == null){
         
    //       var name =this.powerBiService.getReportfromstorage()
    //       this.loadOrEmbedReport(name,"");
    //     }
    //     else{
    //       this.reportName=Reportname
    //       this.loadOrEmbedReport(Reportname,"");
    //     }
        
        
    //   }
    // );
   
    this.Reportchangesubscription = this.powerBiService.ReportSelected$.subscribe({
      next: (selection) => {
          if(selection == null){
           
            var name =this.powerBiService.getReportfromstorage()
            var menuid =this.powerBiService.getReportIDfromstorage()
            this.loadOrEmbedReport(name,menuid);
          }
          else{
            
            this.reportName=selection.Reportname
            this.loadOrEmbedReport(this.reportName,selection.MenuID);
          }
          // Perform actions based on the new selection
        
      },
    });

    this.pageChangeSubscription = this.powerBiService.pageSelectAction$.subscribe(
      pageName => {
        if (this.report) {     
               
          this.report.setPage(pageName).catch(error => console.error('Error changing page:', error));
        }
      }
    );

  }
  
  // ngOnDestroy(): void {
  //   if (this.themeSubscription) {
  //     this.themeSubscription.unsubscribe();
  //   }
  // }

  navigateToPage(pageName: string): void {
    // Assuming `report` is the Power BI report instance available in this component
    this.report.setPage(pageName).catch(error => {
      console.error('Error navigating to page:', error);
    });
  }
  exportReport() {
    this.powerBiService.exportReport().subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'ExportedReport.pdf';
      link.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Export error', error);
    });
  }

  PrintReport(){
    this.report.print()
  }
  
  getembedinfo(Reportname:string,MenuiD){

    let formData: FormData = new FormData();
    formData.append('Reportname', Reportname);
    formData.append('menuID',MenuiD)
    this.powerBiService.getEmbedInfo(formData).subscribe({
      next: (embedParams) => {
        this.pbiService.reset(this.reportContainer.nativeElement);
        this.reportName = embedParams.EmbedReport[0]['ReportName'];
        const models = pbi.models;
        const reportLoadConfig: pbi.IEmbedConfiguration  = {
          type: 'report',
          tokenType: models.TokenType.Embed,
          accessToken: embedParams.EmbedToken.Token,
          embedUrl: embedParams.EmbedReport[0].EmbedUrl,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false, // Disable the default navigation pane
            layoutType: models.LayoutType.Custom, // Set layout type as Custom
            customLayout: {
              displayOption: models.DisplayOption.FitToWidth // Fit report content to container width
            }
          },
        };
    
        const report = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory,
        ).embed(this.reportContainer.nativeElement, reportLoadConfig) as pbi.Report;
        this.report = report;
    
        report.off('loaded');
        report.on('loaded', () => {
          console.log('Report load successful');
          // Fetch the names of the pages after the report is loaded
          report.getPages().then(pages => {
            // Filter out hidden pages
            const visiblePages = pages.filter(page => page.visibility === models.SectionVisibility.AlwaysVisible );
    
            // Emit and share only visible pages
            this.pagesFetched.emit(visiblePages);
            this.powerBiService.shareData(visiblePages);
    
            // Check if there are any visible pages
            if (visiblePages && visiblePages.length > 0) {
              // Set the first visible page as the active page
              const firstPage = visiblePages[0];
              report.setPage(firstPage.name).then(() => {
                console.log(`Set to first page: ${firstPage.displayName}`);
              }).catch(error => {
                console.error('Error setting first page:', error);
              });
            }
    
            // Log information about visible pages
            visiblePages.forEach(page => {
              console.log(`Page name: ${page.name}, display name: ${page.displayName}`);
              // Additional logic for visible pages can go here
            });
          }).catch(error => {
            console.error('Error fetching pages:', error);
          });
        });
    
          
        report.off('rendered');
        report.on('rendered', () => {
          console.log('Report render successful');
        });
    
        report.off('error');
        report.on('error', (event) => {
          console.error(event.detail);
        });
      },
      error: (err) => {
        this.errorHandler.ErrorsHandler(err);
      },
    });
  }

  loadOrEmbedReport(Reportname:any,menuid:any) {
    
    // const reportName = Reportname; // Define how to determine the report name
    // const pageName = pageName1
   
    // if (this.powerBiService.hasReport(reportName)) {
    //   const cachedReport = this.powerBiService.getReport(reportName);
    //   cachedReport.embed(this.reportContainer.nativeElement, cachedReport.config);
    //   console.log(cachedReport.getPages())
    //   cachedReport.getPages().then(pages => {
    //     const setPage = pages.find(page => page.name === pageName);
    //     if (setPage) {
          
    //       cachedReport.setActivePage(setPage);
    //       console.log(`Page set to ${pageName}`);
    //     } else {
    //       console.log(`Page ${pageName} not found in report`);
    //     }
    //   });
      
    //   console.log('Report loaded from cache');
    // } else {
      this.getembedinfo(Reportname,menuid);
    //}
  }
  refreshReport() {
    if (this.report) {
      this.report.refresh()
        .then(() => console.log('Report refresh successful'))
        .catch(error => console.error('Error refreshing report:', error));
    } else {
      console.error('Report object is not initialized');
    }
  }
  
}
