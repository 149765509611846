import { Component, Input, OnInit, OnChanges, SimpleChanges, ElementRef, ViewChild, EventEmitter, Output, ɵConsole, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DynamicElementBase } from 'src/common/DynamicElementBase';
import { DynamicControlService } from 'src/services/Upload/DynamicControlService';
import { UploadService } from 'src/services/Upload/Upload.service';
import { HttpEventType, HttpEvent, HttpRequest } from '@angular/common/http';
//import { v4 as uuidv4 } from 'uuid'
import { UploadAccountModel } from 'src/model/UploadAccount/UploadAccountModel';
import { Observable, Subscription } from 'rxjs';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AlertService } from 'src/services/Common/alert.service';
import { PopupService } from 'src/services/Common/popUp.service';
import { DataShareService } from 'src/services/Common/dataShare.service';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertMessages } from 'src/common/alert-messages';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-dynamic-upload-form',
  templateUrl: './upload-dynamic-form.component.html',
  styleUrls: ['./upload-dynamic-form.component.css'],
  providers: [DynamicControlService]
})
export class UploadDynamicFormComponent implements OnChanges, OnInit, OnDestroy {

  @Input() elementList: DynamicElementBase<string>[] = [];  
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];
  form: UntypedFormGroup;
  FilledFormData: any[]
  payLoad = '';
  errorHandler = new ErrorHandler(this.alertService);
  UploadingFileProgress: UploadAccountModel[];
  showError: boolean = false;
  @Output() public onUploadFinished = new EventEmitter();
  refresh: EventEmitter<boolean> = new EventEmitter();
  guid: any;
  maxFileCount: number;
  maxFileSize: number;
  fileUploaded: number;
  fileSizeError: string;
  AllowedFileFormat: any[];
  subscription: Subscription[];
  dataUploadList: Subscription;
  dataCancelList: Subscription;
  dataRetryList: Subscription;
  submitted: Boolean = false;
  textLength: any;
  isTyping: boolean;
  lateUploadMsg: string;
  //SpecialInstruction:string;
  //AttachFile:string;
  RecentUploadedAccntData: any;
  RecentUploadedHeader: any;
  IsRecentlyUploadVisible: boolean = false;

  PolicyRefVal: string;
  NoRecordsToShow: boolean = false;
  OriginalAccountList: any[] = [];
  constructor(private qcs: DynamicControlService, private ShareData: DataShareService, private popUp: PopupService, private service: UploadService, private alertService: AlertService, private formBuilder: UntypedFormBuilder, public datepipe: DatePipe) {
    this.form = this.formBuilder.group({
      SpecialInstruction: ['', [Validators.maxLength(4000)]],
      UploadAnother: ['']
      // AttachFile: ['', '',]
    });
    // this.SpecialInstruction="SpecialInstruction";
    // this.AttachFile="AttachFile";
    let group: any = {};
    //this.form = new FormGroup(group);  
    this.UploadingFileProgress = [];
    this.FilledFormData = [];
    this.fileUploaded = 0;
    this.subscription = [];

  }
  getOriginalAccounts(value: any[]) {


    if (this.form.controls['Original Account'] != undefined) {
      this.form.controls['Original Account'].reset();
      this.OriginalAccountList = value;

        }
      }

  getPackageTypeValue(value: any) {
    if(value.trim()=='Standard'){
      this.SetAllowedFormatToUpload('1');
    }
    if(value.trim()=='Customize'){
      this.SetAllowedFormatToUpload('2');
    }
    if(value.trim()=='Standard&Customize'){
      this.SetAllowedFormatToUpload('3');
    }
   
  }
  
  SetAllowedFormatToUpload(PackageType:string){
    let formData=new FormData();
    formData.append("PackageTypeId",PackageType)
    this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETALLOWEDFILEFORMATFORUPLOADACCOUNT,formData).subscribe(
      result => {
        this.AllowedFileFormat = result as [];
      });
  }
  getRecentUpload(value: string) {
    if (value) {
      //get recently uploaded accounts
      this.PolicyRefVal = value;
      this.IsRecentlyUploadVisible = true;
      let formData: FormData = new FormData();
      formData.append('PolicyRef', value);
      this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETACCOUNTBYPOLICYREF, formData).subscribe(
        result => {
          if (result[0] != undefined && Object.keys(result[0]).length > 0) {
            this.RecentUploadedHeader = Object.keys(result[0]).slice(0, result[0].count);
            this.RecentUploadedAccntData = result;
            this.NoRecordsToShow = false;
          } else {
            this.NoRecordsToShow = true;
          }
        },
        err => {
          this.NoRecordsToShow = true;
          this.IsRecentlyUploadVisible = false;
          this.alertService.error(AlertMessages.ALERTMESSAGE10);
        });
    } else {
      this.PolicyRefVal = '';
      this.IsRecentlyUploadVisible = false;
      this.NoRecordsToShow = true;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if ('elementList' in changes) {
      if (this.elementList === null)
        return;
      this.qcs.toFormGroup(this.elementList, this.form);
    }
  }

  ngOnInit() {

    this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETMAXFILESIZE, "").subscribe(
      result => {
        this.maxFileCount = result[0]["maxFileCount"];
        this.maxFileSize = result[0]["maxFileSize"];
      });

    // this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETALLOWEDFILEFORMAT, "").subscribe(result => {
    //   this.AllowedFileFormat = result as [];
    //   //console.log(this.AllowedFileFormat);
    // })
    this.SetAllowedFormatToUpload('2');
    this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETLATEUPLOADMSG, "").subscribe(result => {
      this.lateUploadMsg = result.toString();
      //console.log(result);
    })
    this.setDefaultVaues();
    this.dataUploadList = this.ShareData.currentList$.subscribe(processes => this.UploadingFileProgress = processes);
    this.dataCancelList = this.ShareData.subscription$.subscribe(sub => this.subscription = sub);
    this.dataRetryList = this.ShareData.retryList$.subscribe(retry => this.FilledFormData = retry);
  }

  get f() {
    return this.form.controls;
  }

  private setDefaultVaues() {
    let AccountType = this.elementList.find(element => element["key"] == 'Account Type').value;
    if (AccountType != undefined)
      this.form.controls['Account Type'].setValue(AccountType);
    else
      this.form.controls['Account Type'].setValue('');
    this.form.controls['Priority'].setValue('Normal');
    if (this.form.get('Incept Date') != null && this.form.get('Incept Date') != undefined)
      this.form.get('Incept Date').patchValue(this.formatDate(new Date(), 0));
    if (this.form.get('Expiry Date') != null && this.form.get('Expiry Date') != undefined)
      this.form.get('Expiry Date').patchValue(this.formatDate(new Date(), 1));    
    this.refresh.emit(true);
  }

  private formatDate(date, addYear: number) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();
    if (addYear > 0)
      year = year + addYear;
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  RetryUpload(item: any) {
    let position = this.UploadingFileProgress.indexOf(item);
    this.form.setValue(this.FilledFormData[position]);
    this.form.get('Business Unit').setValue(this.FilledFormData[position]['Business Unit']);
    this.UploadingFileProgress.splice(position, 1);
    this.FilledFormData.splice(position, 1);
    this.subscription.splice(position, 1);
    //console.log(t);
  }

  onSubmit() {
    this.validateOriginalAccount();
    this.submitted = true;
    this.showError = true;
    this.fileSizeError = "";


    if (this.form.invalid) {
      document.getElementById("title").scrollIntoView();
      return;
    }
    this.FilledFormData.push(this.form.getRawValue());
    if (this.validateForm()) {

      if (this.form.controls['Due Date'] != null && this.form.controls['Due Date'] != undefined && this.form.controls['Due Date'].value != '' && this.form.get('Due Date').value!=null) {        
        let dueDate = this.datepipe.transform(this.form.get('Due Date').value, 'dd-MMM-yyyy');
        this.form.controls['Due Date'].setValue(dueDate);
      }

      if (this.form.controls['Policy Incept Date'] != null && this.form.controls['Policy Incept Date'] != undefined && this.form.controls['Policy Incept Date'].value != '' && this.form.get('Policy Incept Date').value != null) {
        let PolicyInceptDate = this.datepipe.transform(this.form.get('Policy Incept Date').value, 'dd-MMM-yyyy');
        this.form.controls['Policy Incept Date'].setValue(PolicyInceptDate);
      }
      this.payLoad = JSON.stringify(this.form.getRawValue());

      let jobId: string;
      const formData = new FormData();
      formData.append('UploadData', this.payLoad);
      this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.CREATEUPLOADJOB, formData).subscribe(
        result => {
          jobId = String(result);
          this.UploadAccount(jobId);
          this.OriginalAccountList = [];
          if (this.form.controls['Original Account'] != null && this.form.controls['Original Account'] != undefined) {
            this.form.controls['Original Account'].setValue('');
          }
        }, error => {
          console.log(error);
          this.alertService.error(error.error['ErrorMessage']);          
        }
      );
    }

  }

  validateOriginalAccount()
  {
    if ((this.form.controls['Account Type'].value == 'Remodel'
      //|| this.form.controls['Account Type'].value == 'Renewal'
    )
      && this.form.controls['Original Account'] != undefined
      && (this.form.controls['Original Account'].value == null
        || this.form.controls['Original Account'].value == undefined
        || this.form.controls['Original Account'].value == '')) {
      this.form.controls['Original Account'].setErrors({ 'InvalidValue': true });
    }    
  }

  validateForm() {
    let valid: boolean = true;
    // if(this.UploadingFileProgress.some(x=>x.AccountName== this.form.get('Account Name').value))
    // {
    //   valid=false;
    //   this.form.get('Account Name').setErrors({notUnique: true});
    // }

    if (this.files.length === 0) {
      this.fileSizeError = "Please provide file's to upload."
      valid = false;
    }
    if (this.form.get('Incept Date') != null && this.form.get('Incept Date') != undefined &&
      this.form.get('Expiry Date') != null && this.form.get('Expiry Date') != undefined && (
        this.form.get('Incept Date').value > this.form.get('Expiry Date').value))
      valid = false;
    return valid;
  }

  UploadAccount(jobId: string) {

    this.fileUploaded = 0;
    let t: UploadAccountModel;
    t = new UploadAccountModel();
    t.AccountName = this.form.controls["Account Name"].value;
    t.AccountType = this.form.controls["Account Type"].value;
    t.Priority = this.form.controls["Priority"].value;
    t.BusinessUnit = this.form.controls["Business Unit"].value;
    t.NoOfLocs = this.form.controls["No. of Locs"].value;
    t.Progress = '1';
    t.status = 2;
    t.JobId = jobId;
    // t.SumInsured = this.form.controls["Sum Insured"].value;
    // t.Underwriter = this.form.controls["Underwriter"].value;
    t.Message = "Uploading..."
    t.WorkType = this.form.controls["Work Type"].value;
    let filesToUpload: File[] = this.files;
    const formData = new FormData();
    formData.append('UploadData', this.payLoad);
    formData.append('jobId', jobId);
    let totalFileSize = 0;
    Array.from(filesToUpload).map((file, index) => {
      totalFileSize = totalFileSize + file.size;

      return formData.append('file' + index, file, file.name);
    });
    let subs = this.service.uploadFile(formData).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress && ((event.loaded / event.total) * 100) < 98) {
        t.status = 2;
        t.Progress = ((event.loaded / event.total) * 100).toFixed(2);
      }
      else if (event.type === HttpEventType.Response && event.status == 200) {
        t.status = 1;
        t.Message = "Done";
        t.Progress = '100';
        this.ShareData.updateListProcesses(this.UploadingFileProgress, this.subscription, this.FilledFormData, this.UploadingFileProgress.filter(x => x.status == 1).length);
        this.onUploadFinished.emit();
        this.showError = false;
        // this.files = [];
      }
    }, error => {
      // this.errorHandler.ErrorsHandler(error);
      //console.log(error);
      t.Message = (error.error['ErrorMessage'] == null || error.error['ErrorMessage'] == undefined ? AlertMessages.ALERTMESSAGE6 : error.error['ErrorMessage']);
      // t.Progress = '0';
      t.status = 3;
      this.showError = false;

    })
    this.subscription.push(subs);
    this.UploadingFileProgress.push(t);
    this.files = [];
    if (this.form.controls['UploadAnother'].value) {
      this.form.controls['Account Name'].setValue('');
      this.form.controls['SpecialInstruction'].setValue('');
      this.form.controls["No. of Locs"].setValue('');
      this.form.controls["Priority"].setValue('Normal');
      this.form.controls['Account Name'].setErrors(null);
      this.form.controls["No. of Locs"].setErrors(null);
      this.form.controls["Original Account"].setValue('');

      // this.form.clearValidators()
      this.isTyping = false;
      this.showError = false;
    } else {
      this.form.reset();
      this.setDefaultVaues();
      this.showError = false;
    }
    if (this.PolicyRefVal != null && this.PolicyRefVal != undefined) {
      this.IsRecentlyUploadVisible = true;
    } else { this.IsRecentlyUploadVisible = false; }
    this.submitted = false;
  }
  cancelUpload(item: any) {
    this.popUp.Confirmation("Cancel Account", "Do you really want to cancel this account?").then(result => {
      let index = this.UploadingFileProgress.indexOf(item);
      if (result.value && this.UploadingFileProgress[index].status != 1) {
        this.subscription[index].unsubscribe();
        this.UploadingFileProgress[index].status = 3;
        this.UploadingFileProgress[index].Message = "canceled";
        const formData = new FormData();
        formData.append('JobID', this.UploadingFileProgress[index].JobId);
        this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.CANCELUPLOADJOB, formData).subscribe(
          result => {

          });
      }
    });
  }
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.fileUploaded = this.fileUploaded - Math.round(this.files[index]["size"] / (1024 * 1024));
    this.files.splice(index, 1);
    this.fileSizeError = "";
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(filesList: any[]) {

    if (filesList.length <= this.maxFileCount && (this.files.length + filesList.length) <= this.maxFileCount) {
      let alreadyAddedFiles: string = '';
      let wrongFormatFiles: string = '';
      let zeroSizeFiles: string = '';
      for (let item of filesList) {
        item.progress = 0;
        //let isAdded:Boolean=false;
        // console.log(item["type"]);
        // console.log(this.AllowedFileFormat.filter(el => el["mimeType"] == item["type"]));
        let fileExtension: string = item["name"];
        fileExtension = fileExtension.substr(fileExtension.lastIndexOf('.') + 1, fileExtension.length);
        if (!this.AllowedFileFormat.some(el => String(el["fileFormat"]).toUpperCase().trim() == fileExtension.toUpperCase().trim())) {
          if (wrongFormatFiles == '')
            wrongFormatFiles = item['name'];
          else
            wrongFormatFiles = wrongFormatFiles + ',' + item['name'];
          // this.fileSizeError = "One of attached file(s) is not valid, please attach a valid file and try again";
          continue;
        }
        if (item["size"] < 1) {
          if (zeroSizeFiles == '')
            zeroSizeFiles = item['name'];
          else
            zeroSizeFiles = zeroSizeFiles + ',' + item['name'];
          continue;
        }
        if (this.files.some(el => el['name'] == item['name'])) {
          if (alreadyAddedFiles == '')
            alreadyAddedFiles = item['name'];
          else
            alreadyAddedFiles = alreadyAddedFiles + ',' + item['name'];
          continue;
        }
        // this.files.forEach(element =>{
        //   if(item['name']==element['name']){        
        //  if(alreadyAddedFiles=='')
        //  alreadyAddedFiles=element['name']
        //  else
        //  alreadyAddedFiles=alreadyAddedFiles+','+element['name']
        //  isAdded=true;
        //  return;}
        // })
        // if(isAdded)
        // continue;

        if ((this.fileUploaded + Math.round(item["size"] / (1024 * 1024))) > this.maxFileSize) {
          this.fileSizeError = "Upload files total size can't be greater than " + this.maxFileSize + " MB";
          return;
        }
        this.fileUploaded = this.fileUploaded + Math.round(item["size"] / (1024 * 1024));
        this.fileSizeError = "";
        this.files.push(item);
      }
      if (alreadyAddedFiles != '')
        this.fileSizeError = alreadyAddedFiles + " already provided for upload";

      if (wrongFormatFiles != '')
        this.fileSizeError = 'File(s) ' + wrongFormatFiles + " not valid, please attach valid file";
      if (zeroSizeFiles != '')
        this.fileSizeError = 'File(s) ' + zeroSizeFiles + " have 0 Kb size, please attach valid file";
      this.fileDropEl.nativeElement.value = "";
    } else {
      document.getElementById("title").scrollIntoView();
      this.alertService.error("Can't upload more than " + this.maxFileCount + " files for this account.")
      this.fileSizeError = "Can't upload more than " + this.maxFileCount + " files for this account.";
    }
    //this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.dataUploadList.unsubscribe();
    this.dataCancelList.unsubscribe();
    this.dataRetryList.unsubscribe();
  }
  showremainingcharacter() {
    this.isTyping = true
  }
}



