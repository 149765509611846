<!-- <nav class="navbar navbar-expand-lg" [ngClass]="{'navbar-light bg-light': !isDarkTheme, 'navbar-dark bg-dark': isDarkTheme}">
  <div class="container-fluid">
   
    <button class="btn btn-sm btn-secondary" style="margin-right:30px;" (click)="exportReport()"><i class="fa-solid fa-file-export"></i> Export</button>
     </div>
</nav> -->

<div class="card card-default">
  <div class="card-header" id="target" style="background-color: #ffffff; " #target>
    <h3 class="card-title mt-1 MoodysTittleColor" >Reports</h3>
    <h3 class="card-title mt-1 ml-4 MoodysTittleColor" >{{reportName}}</h3>
    <div class="row float-right">
      <!-- Search -->
      <div>
        <div class="input-group input-group-sm  mt-0" (click)="PrintReport()">
          <button type="button" class="btn btn-default btn-block btn-sm d-none d-sm-block" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" style="width: 70px;" >
            Print
          </button>
          <div class="input-group-append">
            <button type="button"  data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              <!-- <i class="fa-solid fa-file-pdf-o RMSSearchButtonIcon"></i> -->
              <i class="fas fa-solid fa-print"></i>
              <!-- <i class="fa fa-file-excel-o RMSSearchButtonIcon"></i> -->
            </button>
            
          </div>
      </div>
        <!-- <div class="input-group input-group-sm">
          <input class="form-control font11" (keyup)="checkSearch()" (keydown.enter)="searchRecords()" type="text"
            placeholder="Search" [(ngModel)]="searchInput" aria-label="Search" />
          <div class="input-group-append">
            <button class="input-group-text bg-primary" data-toggle="tooltip" data-placement="top" type="submit"
              id="basic-text1" title="Search" (click)="searchRecords()" class="RMSSearchButton">
              <i class="fas fa-search RMSSearchButtonIcon"></i>
            </button>
            <button class="RMSSearchButton" type="submit" data-toggle="tooltip" data-placement="top"
              style="margin-left: 1px;" title="Refresh" id="basic-text1" (click)="resetRecords()">
              <i class="fa fa-refresh text-grey" style="color: #ffffff;" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div class="col-lg-12 ErrorMessage NoPadding" *ngIf="showSearchValidationError"
          style="color: #c42032; font-size: 0.8rem;">
          Special characters are not allowed
        </div> -->
      </div>
      <!-- Advanced Filter -->
      <!-- <div style="margin-left: 10px;">
        <div class="btn-group btn-group-sm" (click)="toggleAdvancedFilter()">
          <button class="btn btn-default" type="button">Filter</button>
          <button class="RMSSearchButton" aria-hidden="true">
            <i class="fa fa-filter text-white"></i>
          </button>
        </div>
      </div> -->
      <!-- Export -->
      <div style="margin-left: 10px;" (click)="exportReport()">
        <div class="input-group input-group-sm  mt-0">
          <button type="button" class="btn btn-default btn-block btn-sm d-none d-sm-block" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" style="width: 70px;" >
            Export
          </button>
          <div class="input-group-append">
            <button type="button" class="btn btn-default btn-block btn-sm" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" style="background-color:rgb(156, 45, 45)">
              <!-- <i class="fa-solid fa-file-pdf-o RMSSearchButtonIcon"></i> -->
              <i class="fas fa-regular fa-file-pdf-o RMSSearchButtonIcon"></i>
              <!-- <i class="fa fa-file-excel-o RMSSearchButtonIcon"></i> -->
            </button>
            
          </div>
       
      </div>
    </div>

    <div style="margin-left: 10px;" (click)="refreshReport()">
      <div class="input-group input-group-sm  mt-0">
        <button type="button" class="btn btn-default btn-block btn-sm d-none d-sm-block" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" style="width: 70px;">
          Refresh
        </button>
        <div class="input-group-append">
          <button type="button" class="btn btn-default btn-block btn-sm" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" style="background-color:rgb(7, 13, 132)">
      
            <i class="fa fa-refresh RMSSearchButtonIcon"></i>
          
          </button>
          
        </div>
     
    </div>
  </div>
  </div>
</div>

</div>

<section class="content">
  <div class="container-fluid" style="padding: 0 !important;">
    <!-- <div class="card card-default"> -->
        `<div #reportContainer class="report-container" style="height:720px"></div>

    <!-- </div> -->
  </div>
</section>

