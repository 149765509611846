<div class="tab-pane fade show active" id="client_details" role="tabpanel" aria-labelledby="nav-profile-tab">
    <!-- <div class="card border-top-0" style="border-radius: 0px !important;"> -->
        <!-- <div class="card-body"> -->
            <form [formGroup]="clientManagementForm">
                <div style="border:solid rgba(204, 203, 206, 0.473); border-radius: 5px;">
                    <div class="container">
                        <div style="margin-bottom: 10px;margin-top: 10px;" class="row">
                            <div class="col">
                                <!-- Select Basic -->
                                <div class="input-group-sm row">
                                    <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                        for="selectbasic">Select Element<label class='required'>*</label></label>
                                    <div class="col-md-4">
                                        <select formControlName="elementselector"
                                            (change)="GetElementID($event.target.value)"
                                            class="form-control form-control-sm">
                                            <option selected="selected">Select Element</option>
                                            <option *ngFor="let item of _elementList" [value]="item['elementId']">
                                                {{item['elementName']}}</option>
                                        </select>
                                        <!-- <div class="col-lg-12 ErrorMessage NoPadding"
                                            style="font-size: small;color: #c42032; height: 1rem !important;">
                                            <div *ngIf="GetFormControls.elementselector.errors && checkerror || GetFormControls.elementselector.touched && GetFormControls.elementselector.invalid">
                                                <div *ngIf="GetFormControls.elementselector.errors.required">
                                                    Please Select Element.
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-lg-12 ErrorMessage NoPadding"
                                            style="font-size: small;color: #c42032; height: 1rem !important;">
                                            <div
                                                *ngIf="GetFormControls.elementselector.errors && checkerror || GetFormControls.elementselector.touched && GetFormControls.elementselector.invalid">
                                                <div *ngIf="GetFormControls.elementselector.errors.required">
                                                    Select the Element.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col">
                                <!-- Select Basic -->
                                <div class="input-group-sm row">
                                    <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                        for="selectbasic">Select Validator<label class='required'>*</label></label>
                                    <div class="col-md-4">
                                        <select formControlName="validator"
                                            (change)="GetValidatorId($event.target.value)"
                                            class="form-control form-control-sm">
                                            <option selected="selected">Select Validator</option>
                                            <option *ngFor="let item of ValidatorMasterData" [value]="item['id']">

                                                {{item['validationType']}}''{{item['validationValue']}},,</option>
                                            <!-- <option value=false>No</option> -->
                                        </select>
                                        <div class="col-lg-12 ErrorMessage NoPadding"
                                            style="font-size: small;color: #c42032; height: 1rem !important;">
                                            <div
                                                *ngIf="GetFormControls.validator.errors && checkerror || GetFormControls.validator.touched && GetFormControls.validator.invalid">
                                                <div *ngIf="GetFormControls.validator.errors.required">
                                                    Please Select Element.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <!-- <div class="row">
                            <div class="col">
                                <div class="input-group-sm row">
                                    <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                        for="selectbasic">Is Required<label class='required'>*</label></label>
                                    <div class="col-md-4">
                                        <select formControlName="isRequired" class="form-control form-control-sm">
                                            <option value=true>Yes</option>
                                            <option value=false>No</option>
                                        </select>
                                        <div class="col-lg-12 ErrorMessage NoPadding"
                                            style="font-size: small;color: #c42032; height: 1rem !important;">
                                            <div
                                                *ngIf="GetFormControls.isRequired.errors && checkerror || GetFormControls.isRequired.touched && GetFormControls.isRequired.invalid">
                                                <div *ngIf="GetFormControls.isRequired.errors.required">
                                                    select isRequired.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col">
                                <!-- Select Basic -->
                                <div class="input-group-sm row">
                                    <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                        for="selectbasic">Is Active<label class='required'>*</label></label>
                                    <div class="col-md-4">
                                        <select formControlName="isActive" class="form-control form-control-sm">
                                            <option value=true>Yes</option>
                                            <option value=false>No</option>
                                        </select>
                                        <div class="col-lg-12 ErrorMessage NoPadding"
                                            style="font-size: small;color: #c42032; height: 1rem !important;">
                                            <div
                                                *ngIf="GetFormControls.isActive.errors && checkerror || GetFormControls.isActive.touched && GetFormControls.isActive.invalid">
                                                <div *ngIf="GetFormControls.isActive.errors.required">
                                                    select isactive.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                            <div class="col">
                                <!-- Select Basic -->
                                <div class="input-group-sm row">
                                    <label class="col-md-4 " style="font-size: small; font-weight: normal;"
                                        for="selectbasic">Is Ignorable<label class='required'>*</label></label>
                                    <div class="col-md-4">
                                        <select formControlName="isignorable" class="form-control form-control-sm">
                                            <option value=true>Yes</option>
                                            <option value=false>No</option>
                                        </select>
                                        <div class="col-lg-12 ErrorMessage NoPadding"
                                            style="font-size: small;color: #c42032; height: 1rem !important;">
                                            <div
                                                *ngIf="GetFormControls.isignorable.errors && checkerror || GetFormControls.isignorable.touched && GetFormControls.isignorable.invalid">
                                                <div *ngIf="GetFormControls.isignorable.errors.required">
                                                    select isactive .
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Button -->
                    <div class="form-group">
                        <!-- <div class="col-md-4"> -->
                            <button style="margin-top: 9px;" id="singlebutton" (click)="AddToElementList()"
                                name="singlebutton" class="btn btn-primary RMSSubmitButton">{{_buttonTittle}}</button>
                                <button  *ngIf="_isEdit" style="margin-top: 9px;margin-left: 10px;" id="singlebutton" (click)="Cancel()"
                                name="singlebutton" class="btn btn-primary RMSSubmitButton">Cancel</button>
                       
                        <!-- </div> -->
                    </div>

                </div>




            </form>

            <div style="border:solid rgba(204, 203, 206, 0.473); border-radius: 5px; margin-top: 10px;">
                <div class="container" style="margin-top: 10px;">
                    <div class="container ">
                        <label style="color: rgb(0, 123, 151);font-size: small;">Element Validation Mapping</label>

                    </div>
                    <div class="table-responsive text-nowrap">
                        <div *ngIf="_elementvalidationgridList.length==0" style="font-weight: bold;">No Record found</div>
                        <table *ngIf="_elementvalidationgridList.length>0" id="tblAccountBUMap" style="width: 100%;margin-bottom: 5px;"
                            class="table-hover table-bordered font14 noPad">
                            <thead>
                                <tr class="th-sm RMSTableHeader">
                                    <th class="text-center" style="width: 141px;">Actions</th>
                                    <th class="text-center" style="width: 200px;">ElementName</th>
                                    <th class="text-center" style="width: 200px;">Validator</th>
                                    <th class="text-center" style="width: 200px;">Value</th>
                                    <!-- <th class="text-center" style="width: 200px;">IsRequired</th> -->
                                    <th class="text-center" style="width: 200px;">IsIgonorable</th>
                                    <th class="text-center" style="width: 200px;">Status</th>
                                </tr>
                            </thead>
                            <tbody class="RMSTableContent">
                                <tr class="RmsTableRow" *ngFor="let item of _elementvalidationgridList; let i = index">
                                    <td *ngIf="item.clientId==null" class="text-center icon">
                                        <button class="btn btn-tool" type="button">
                                            <i class="fa fa-edit" title="Edit Element"  style="color:gray"></i>
                                        </button>
                                        <button class="btn btn-icon-toggle" type="button">
                                            <i class="fa fa-trash" title="Delete Element" style="color:gray"></i>
                                        </button>
                                    </td>
                                    <td  *ngIf="item.clientId!=null"  class="text-center icon">
                                        <button class="btn btn-tool" type="button">
                                            <i class="fa fa-edit" title="Edit Element" (click)="EditValidation(item)"
                                                style="color:rgb(0, 123, 151)"></i>
                                        </button>
                                        <button class="btn btn-icon-toggle" type="button">
                                            <i class="fa fa-trash" title="Delete Element"
                                                (click)="DeleteValidation(item)" style="color:rgb(196, 32, 50)"></i>
                                        </button>
                                    </td>
                                    <td class="text-center ">{{item['ElementName']}}</td>
                                    <td class="text-center icon">{{item['validtionName']}}</td>
                                    <td class="text-center icon">{{item['ValidatorValue']}}
                                    </td>
                                    <td class="text-center icon">
                                        <!-- {{item.IsMandatory}} -->
                                        <i *ngIf="item['IsIgnorable']== true;else isingorable" class="fa fa-check"
                                            style="color:green;;"></i>

                                        <ng-template #isingorable>
                                            <i class="fa fa-times" style="color:red;"></i>
                                        </ng-template>
                                    </td>
                                    <td class="text-center icon">
                                        <!-- {{item.IsMandatory}} -->
                                        <i *ngIf="item['IsActive']== true;else status" class="fa fa-check"
                                            style="color:green;;"></i>

                                        <ng-template #status>
                                            <i class="fa fa-times" style="color:red;"></i>
                                        </ng-template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>


                </div>
            </div>


        <!-- </div> -->
    <!-- </div> -->
</div>
<div style="margin-top: 10px;" class="col-8">
    <button type="button" style="margin-right: 20px;" (click)="SaveElementValidation()"
        class="btn btn-primary RMSSubmitButton">Save</button>
    <button name="submit" type="submit" class="btn btn-primary RMSButtonCancel" (click)="close()">
        Cancel
    </button>
</div>