export enum FeatureMaster {
    View_Client_Manager_Dashboard = 1,
    Upload_SOV = 2,
    View_Report = 3,
    View_Process_Tracking = 4,
    View_Message_Center = 5,
    View_Client = 6,
    View_User = 7,
    View_Role = 8,
    Cancel_Account = 9,
    Close_Account = 10,
    Edit_Account = 11,
    Download_SOV = 12,
    Upload_RA_Shipment = 13,
    Upload_DES_Shipment = 14,
    Download_Shipment = 15,
    RaiseReply_Query = 16,
    Forward_Query = 17,
    Close_Query = 18,
    AddEdit_Client = 19,
    AddEdit_User = 20,
    Deactivate_User = 21,
    Deactivate_Client = 22,
    AddEdit_Role = 23,
    Deactivate_Role = 24,
    View_Account_Manager_Dashboard = 25,
    View_Project_Manager_Dashboard = 26,
    View_Admin_Dashboard = 27,
    Upload_Additional_SOV = 28,
    SaveAS_User = 29,
    SaveAS_Contract = 30,
    View_Recent_Jobs = 31,
    View_Client_lead_dashboard = 32,
    Upload_Additional_Shipment = 33,
    AddEdit_Master=34,
    Convert_To_RL_Import_Files=37,
    ReActivate_Account = 38,
    SS_Process_Tracking = 39,
    SS_Upload =40,
    SS_View_Reports=41
}
