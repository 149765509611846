import { Component, OnInit } from '@angular/core';
import { UploadService } from 'src/services/Upload/Upload.service'
import { DynamicElementBase } from 'src/common/DynamicElementBase';
import { Observable, of } from 'rxjs';
import { AlertService } from 'src/services/Common/alert.service'
import { AlertMessages } from 'src/common/alert-messages';
import { CommonMethod } from 'src/common/CommonMethod';
import { MenuList } from 'src/common/menuList.enum';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
//import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-upload-data',
  templateUrl: './upload-account.component.html',
  styleUrls: ['./upload-account.component.css'],
  providers: [UploadService]
})
export class UploadAccountComponent implements OnInit {

  elementList$: Observable<DynamicElementBase<any>[]>;

  RecentUploadedAccntData: any;
  RecentUploadedHeader: any;
  IsVisible = false;
  NoRecordsToShow: boolean = false;
  obj: CommonMethod = new CommonMethod();
  constructor(private service: UploadService, private alertService: AlertService) {
    this.obj.checkAccess(MenuList.UploadAccount)
  }

  ngOnInit() {

    this.service.GetUIElementList().then
      ((val) => {
        this.elementList$ = of(val);
        this.IsVisible = true;
      },
        errors => {
          console.log(errors);
        });
    // let formData: FormData = new FormData();
    // formData.append('TimeZoneId', '0');
    // this.service.Post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETRECENTUPLOADEDACCOUNT, formData).subscribe(
    //   result => {
    //     this.RecentUploadedHeader = Object.keys(result[0]).slice(0, result[0].count);
    //     this.RecentUploadedAccntData = result;
    //     this.NoRecordsToShow = false;
    //   },
    //   err => {
    //     this.NoRecordsToShow = true;
    //     this.alertService.error(AlertMessages.ALERTMESSAGE10);
    //   });
  }
  onUploadFinished() {
    this.alertService.success(AlertMessages.ALERTMESSAGE17)
  }
}
