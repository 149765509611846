import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';

@Injectable({
  providedIn: 'root'
})
export class ConvertToRLImportableService {

  constructor(private httpClient: HttpClient, private datePipe: DatePipe) { }
  CheckAuthorized() {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.CHECKAUTHORIZEDFORCONVERTTORLFORMAT, '').pipe(map(response => {
      let result = response;
      return result;
    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
  InsertJobForConvertToRLFormat(postData: FormData) {
    // let formData = new FormData();
    // formData.append('UserId', String(UserId))
    // formData.append('AccountId',AccountId);
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.INSERTJOBFORCONVERTTORLFILEFORMATE, postData).pipe(map(response => {
      let result = response;
      return result;
    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
  UpdateJobForConvertToRLFormat(postData: FormData) {
    // let formData = new FormData();
    // formData.append('UserId', String(UserId))
    // formData.append('AccountId',AccountId);
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEJOBFORCONVERTTORLFILEFORMATE, postData).pipe(map(response => {
      let result = response;
      return result;
    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
}
