<div>
  <form class="form-row" [formGroup]="form">
    <div *ngFor="let element of elementList$" style="float: left;">
      <app-dynamic-elements [showError]="showError" [element]="element" [form]="form">
      </app-dynamic-elements>
    </div>
    <div class="form-row" *ngIf="IsRMSUser" style="width: 420px; margin-left: 10px;margin-bottom: 10px;">
      <div class="col-sm-4">
        <label style="font-weight: normal;">Status</label>
      </div>
      <div class="col-sm-8" style="width: 100%;">
        <div class="col">
          <select style="width: 100%;height: 100%;min-height: 30px; font-size: small;" class="form-control"
            formControlName="Status">
            <option selected value="">---Select---</option>
            <option *ngFor="let opt of StatusData" [value]="opt.statusName">{{opt.statusName}}</option>
          </select>
        </div>
      </div>
    </div>
    <!-- <div class="form-row" [formGroup]="form" style="width:100%;white-space: pre;">
    <div class="form-row" style="width: 100%; margin-left: 20px;margin-bottom: 10px;">
      <div style="width: 140px;">
        <label style="font-weight: normal;">Additional Instruction</label>
      </div>
      <div class="col-sm-6">
        <textarea  formControlName="AdditionalInstruction" style=" width: 100%;height: 100px;text-align: start;"></textarea>
      </div>
    </div>
    <div class="form-row" style="width: 100%; margin-left: 20px;">
      <div style="width: 140px;">
        <label style="font-weight: normal;">Upload Additional Files</label>
      </div>
      <div appDnd class="col-sm-6" style="text-align: center;" (fileDropped)="onFileDropped($event)">
        <div class="container">
          <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
          <div class="dragDrop">
            <div class="row">
              <img class=" col-sm-2 d-none d-sm-block" src="assets/svg/ic-upload-file.svg"
                style="width: 30px;height: 30px;">
              <h3 class="col-sm-8" style="text-align: center;">Drag and drop file here</h3>
            </div>
            <div class="row" style="text-align: center;">
              <h3 class="col">or</h3>
            </div>
            <div class="row" style="text-align: center;">
              <div class="col"></div>
              <label class="col" for="fileDropRef" style="width: 30%; height: 20%;border-radius: 21.5px; color: white;
                background-color: #db202f;padding: 8px 16px;">Browse for file</label>
              <div class="col"></div>
            </div>           
          </div>
        </div>
       
        <div class="files-list" style="max-height: 200px; scroll-behavior: auto; overflow-y: scroll;">
          <div class="single-file" *ngFor="let file of files; let i = index">
            <img src="assets/svg/ic-file.svg" width="45px" alt="file">
            <div class="info">
              <h4 class="name">
                {{ file?.name }}
              </h4>
              <p class="size">
                {{ formatBytes(file?.size) }}
              </p>
               <div class="progress-cont">
                  <div class="progress" [style.width]="file?.progress + '%'">
                  </div>
                </div>
            </div>
            <img src="assets/svg/ic-delete-file.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
          </div>
        </div>
      </div>
    </div>
  </div> -->
  </form>
  <div class="row" style="margin-left: 15px;margin-top: 10px; float: left;">
    <button type="button" class="btn btn-primary RMSSubmitButton" [disabled]="UpdateDisabled"
      style="margin-bottom:10px; margin-right: 10px;" (click)="UpdateAccount()">Update</button>
    <button type="button" class="btn btn-primary RMSButtonCancel" [disabled]="UpdateDisabled"
      style="margin-bottom:10px;" (click)="close()">Cancel</button>
  </div>
</div>