<div class=" setBGImage">

    <div class="container MainContainer">
      <div class="row">
        <div class="col-lg-6">
          <img  src="../../../assets/img/RMS.png" style="width:102%;">
          <br />
          <img src="../../../assets/img/MoodysRMSLogo1.png" style="    width: 82%;
          margin-top: 65px;
          margin-left: 52px;" />
        </div>
        <div class="col-lg-6">
            <img src="../../../../assets/Images/Capture.PNG" style="width:100%" />
        </div>
      </div>
      <div class="row">
        <div class="col" style="text-align:center;">
          <h6 style="font-size: .8rem; color:#989696;">© {{Year | date: "y"}} Risk Management Solutions, Inc. All Rights
            Reserved.</h6>
          <br />
        </div>
      </div>
    </div>
  </div>