
<div class="container-fluid" style="margin-top: 10px;  padding-top:10px;" #target>
  <div class="card card-default" >
      <div class="card-header">
         
          <h3 class="card-title MoodysTittleColor" style="vertical-align: middle;">Create MRI
          </h3>
      </div>
  </div>  
  <div class="card card-default" >
    <div class="card-header">
        <h3 class="card-title MoodysTittleColor" >Convert to RL Importable File </h3>
        </div>
      <div class="card-body">
        <form [formGroup]="uploadShippmentForm" (ngSubmit)="onSubmit()" autocomplete="off" >
          <div class="form-row" style="width: 100%; margin-left: 10px;margin-top: 10px;">
            <div style="width: 140px;">
                <label style="font-weight: normal;">Select File <label class='required'>*</label></label>
            </div>
            <div appDnd class="col-sm-6" style="margin-left:5px;text-align: center;"
                (fileDropped)="onFileDropped($event)">
                <div class="container col">
                    <input type="file"  #fileDropRef id="fileDropRef"
                        (change)="fileBrowseHandler($event.target.files)" (click)="$event.target.value=null" />
                    <div class="dragDrop">
                        <div class="row">
                            <img class=" col-sm-2 d-none d-sm-block" src="assets/svg/ic-upload-file.svg"
                                style="width: 30px;height: 30px;">
                            <h3 class="col-sm-8" style="text-align: center;">Drag and drop file here</h3>
                        </div>
                        <div class="row" style="text-align: center;">
                            <h3 class="col">or</h3>
                        </div>
                        <div class="row" style="text-align: center;">
                            <div class="col"></div>
                            <label class="col" for="fileDropRef" style="width: 30%; height: 20%;border-radius: 21.5px; color: white;
                      background-color: #db202f;padding: 8px 16px;">Browse for file</label>
                            <div class="col"></div>
                        </div>
                    </div>
                </div>
                <div class="files-list col"
                    style="max-height: 200px; scroll-behavior: auto; overflow-y: scroll;">
                    <div class="single-file" *ngIf="fileToUpload">
                        <img src="assets/svg/ic-file.svg" width="45px" alt="file">
                        <div class="info">
                            <h4 class="name">
                                {{ fileToUpload?.name }}
                            </h4>
                            <p class="size">
                                {{ formatBytes(fileToUpload?.size) }}
                            </p>
                            <!-- <div class="progress-cont">
                  <div class="progress" [style.width]="file?.progress + '%'">
                  </div>
                </div> -->
                        </div>
                        <img src="assets/svg/ic-delete-file.svg" class="delete" width="20px" alt="file"
                            (click)="deleteFile()">
                    </div>
                </div>
            </div>
            <div>
                <label style="font-weight: normal; color:red;overflow-wrap: break-word;">{{fileError}}</label>
            </div>
        </div>
        <!-- <div class="form-row" style="width: 100%; margin-left: 10px;margin-top: 10px;">
            <div style="width: 140px;">
                <label style="font-weight: normal;">Select Destination <label class='required'>*</label></label>
            </div>
                <input type="file" 
                (change)="Check($event.target)" webkitdirectory />
               
        </div> -->
        <div class="container-fluid" style="width: 100%;">
          <button type="submit" style="float: right; width: auto;"
            class="col-sm-2 btn btn-primary btn-block RMSSubmitButton">Convert</button>
        </div>
        </form>
      </div>
  </div>
</div>



   