import { Component, OnInit } from '@angular/core';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertService } from 'src/services/Common/alert.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.css']
})
export class ProfileUserComponent implements OnInit {
  userDetail: any;
  constructor(private serivce: AddEditUserSerivceService,
    private alertServic: AlertService
  ) {
  }
  editDetails: boolean;
  isClientUser: boolean;
  accountTypeList: any;
  businessunitList: any;
  clientList: any;
  errorHandler = new ErrorHandler(this.alertServic);
  ngOnInit() {
    this.serivce.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      // console.log('this is user profile page', res);
      if (res != null && res != "undefined") {
        this.userDetail = res['userDetails'][0];
        this.clientList = res['clientList'];
        // console.log(this.userDetail);
        this.accountTypeList = res['accountType'];
        this.businessunitList = res['businessUnitType'];
        if (this.userDetail.clientID > 2) {
          this.isClientUser = true;
        }
      }
    },
      err => {
        this.errorHandler.ErrorsHandler(err);
      }
    )
  }
  EditDetails() {
    this.editDetails = true;
  }
  Cancel() {
    this.editDetails = false;
  }
}
