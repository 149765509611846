<hr style="margin-top: 0px !important;" />

<!-- Content Wrapper. Contains page content -->
<section class="content">
  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor" >Dashboard</h3>

        <div class="card-tools" style="margin-left: 20px;">
          <div class="input-group input-group-sm" style="width: 150px;">

            <h3 class="card-title" style="font-size: medium;margin-top: 5px ;margin-right: 5px;">Client</h3>

            <select (change)="getdashboard($event.target.value)" class="form-control">
              <option *ngFor='let item of clientList' [value]="item.clientid">{{item.clientname}}</option>

            </select>

          </div>
        </div>
      </div>


    </div>


  </div>
</section>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- Info boxes -->
    
  <app-card-header [locationProcessed]="locationProcessed"
[noOfActiveUser]="noOfActiveUser" [accountProcessed]="accountProcessed"
  ></app-card-header>
      <!-- /.col -->
    
    <!-- /.row -->

    <!-- Main row -->
    <div class="row">
      <!-- Left col -->
      <div class="col-md-8">
        <!-- MAP & BOX PANE -->

        <app-account-planned  [accountPlanned]="uploadData"></app-account-planned>
        <!-- /.card -->
        <div class="row">
          <div class="col-md-6">
            <!-- PRODUCT LIST -->
            <app-recently-added-queries></app-recently-added-queries>
            <!-- /.card -->
          </div>
          <!-- /.col -->

          <div class="col-md-6">
            <!-- USERS LIST -->

            <app-latest-users [username]='userName'></app-latest-users>
          </div>

        </div>

      </div>
      <div class="col-md-4">
        <app-total-account-status [totalInventory]="totalInventory" [accountInProgress]="accountInProgress"
          [downloadedAccount]="downloadedAccount" [shippedAccount]="shippedAccount" [accountInQuery]="accountInQuery">
        </app-total-account-status>
      </div>


    </div>

  </div>
  <!--/. container-fluid -->
</section>
<!-- /.content -->
<!-- /.content-wrapper -->