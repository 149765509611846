<nav class="mt-2">
  <div class="accordion" id="accordionExample">
    <div *ngFor="let menuId of distinctMenuIds; let i = index" >
      <div *ngIf="getapplicationwiseMenu(menuId)!=[]" class="card" style="background-color: white;">
        <h2 class="mb-0">
          <button class="btn btn-link btn-block text-left" style="color: black;" type="button" data-toggle="collapse"
          [attr.data-target]="'#collapse' + menuId" aria-expanded="true" aria-controls="collapseOne">
           <b>{{getApplicationName(menuId)}}</b> 
          </button>
        </h2>

      <div [id]="'collapse' + menuId" class="collapse show"  [class.show]="i === 0" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body" style="padding-left:inherit;padding-top: unset;">
          <ul class="nav nav-pills nav-sidebar flex-column "
          data-widget="treeview" role="menu" data-accordion="false">
      
          <li *ngFor="let menu of getapplicationwiseMenu(menuId) | SidebarPipe" class="new-nav-item has-treeview menu" style="cursor: pointer;">
            <div *ngIf="getChildMenu(menu.id);then SubMenu; else Menu"></div>
            <ng-template #Menu>
              <a routerLink="{{'/' + menu.url}}" title="{{menu.menuName}}" id="{{'link' + menu.id}}"
                (click)="showActive('link' + menu.id,menu.menuName,menu.menuName,menu.id)" class="nav-link menu-class"
                style="color: black;">
                <i class="{{menu.icon}} nav-icon"></i>
                <p>{{menu.menuName}} </p>
              </a>
            </ng-template>
            <ng-template #SubMenu>
              <a title="{{menu.menuName}}" class="new-nav-link menu-class display-tran" id="{{'lnk' + menu.id}}"
                (click)="openChild(menu.id, $event)" style="color: black;">
      
                <i class="{{menu.icon}} nav-icon"></i>
                <p>{{menu.menuName}}
                  <i class="right fas fa-angle-right"></i>
                </p>
              </a>
              <ul class="nav nav-treeview display-tran" id="{{'dd' + menu.id}}">
                <li *ngFor="let subMenu of menuDetails | ChildMenuPipe: menu.id" class="nav-item menu-class">
                  <a routerLink="{{'/' + subMenu.url}}" id="{{'link' + subMenu.id}}" title="{{subMenu.menuName}}"
                    (click)="showActive('link' + subMenu.id,subMenu.menuName,menu.menuName,subMenu.id)" class="nav-link"
                    style="color: black;">
                    <i class="far fa-circle nav-icon text-sm"></i>
                    <p class="text-sm">{{subMenu.menuName}} </p>
                  </a>
                  <ul>
                    <div style="margin-left: 18px;" *ngIf="subMenu.menuName == selectedReport">
                      <li *ngFor="let page of reportData" type="none" style="cursor: pointer;"
                        [class.active]="page.displayName === activeItemId" (click)="onSelectPage(page.name,page.displayName)">
                        <!-- <a class="nav-link" style="color: white;" > -->
                        <!-- <i class="fa fa-solid fa-file"></i> -->
                        <p style="margin-top: 20px;" title="{{page.displayName}}" class="text-sm">{{page.displayName}}</p>
                        <!-- </a> -->
                      </li>
                    </div>
      
                  </ul>
                </li>
              </ul>
            </ng-template>
          </li>
      
        </ul>
        </div>
      </div>
    </div>
    </div>



























    
   
  </div>
</nav>


<!-- <ul >
  <li *ngFor="let page of reportData" style="cursor: pointer;" (click)="onSelectPage(page.name)">
    {{ page.displayName }}
  </li>
</ul> -->


