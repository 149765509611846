export class DynamicElementBase<T> {
    value: T;
    key: string;
    label: string;
    validations: any[];
    order: number;
    isMandatory:boolean;
    controlType: string;
    type: string;
    options: {key: string, value: string}[];
    errorMsg:string;
    warningMsg:string;
  
    constructor(options: {
        value?: T,
        key?: string,
        label?: string,
        required?: boolean,
        isMandatory?:boolean,
        order?: number,
        controlType?: string,
        type?: string,
        validations?: any        
      } = {}) {
      this.value = options.value;
      this.key = options.key || '';
      this.label = options.label || '';      
      this.isMandatory = !!options.isMandatory;
      this.order = options.order === undefined ? 1 : options.order;
      this.controlType = options.controlType || '';
      this.type = options.type || '';
      this.validations=options.validations;
    }
  }