import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardServiceService {

  constructor(private httpClient: HttpClient) { }

  getDAshboardData(FormValue: any) {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETCLIENTDATA, FormValue).pipe(map(response => {
      let result = response;

      return result;

    },
      error => {

        return error;
      }
    ))
  }
  GetDashboard(clientId: number) {

    let formData = new FormData();
    formData.append('clientId', String(clientId))
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETDASHBOARD, formData).pipe(map(response => {
      let result = response;

      return result;


    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
  getHomeData(FormValue: any) {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETHOMEDATA, FormValue).pipe(map(response => {
      let result = response;

      return result;

    },
      error => {

        return error;
      }
    ))
  }
  getUserForClientLead(FormValue: any) {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERFORCLIENTLEADDASHBOARD, FormValue).pipe(map(response => {
      let result = response;

      return result;

    },
      error => {

        return error;
      }
    ))
  }
  GetDashboardFromUSerId(clientId: number, userId: number) {
    let formData = new FormData();
    formData.append('clientId', String(clientId))
    formData.append('userId', String(userId))
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.GETDASHBOARDUSERWISE, formData).pipe(map(response => {
      let result = response;
      return result;


    },
      error => {
        let er = error.json();
        return er;
      }
    ))
  }
}
