import { Injectable, EventEmitter, Output } from '@angular/core';

import { ComboBox } from 'src/common/ComboBox';
import { ComboBoxAuto } from 'src/common/ComboBoxAuto';
import { DynamicElementBase } from 'src/common/DynamicElementBase';
import { TextBox } from 'src/common/TextBox';
import { HttpClient, HttpHeaders, HttpEventType, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { map } from 'rxjs/operators';
import { DatePicker } from 'src/common/DatePicker';
import { NumberBox } from 'src/common/NumberBox';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private httpClient: HttpClient) { }
  data: any
  ElementList: DynamicElementBase<string>[];
  ElementVal: { key: string, value: string }[];

  uploadFile(formData: any) {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.UPLOADACCOUNT, formData,
      { reportProgress: true, observe: 'events' })
      .pipe(map(event => {
        return event;
      }
      ));
  }
  SSuploadFile(formData: any) {
    return this.httpClient.post(AppSettings.API_ENDPOINT + AppRelativeUrl.SSUPLOADACCOUNT, formData,
      { reportProgress: true, observe: 'events' })
      .pipe(map(event => {
        return event;
      }
      ));
  }

  Post(url: string, formData: any) {
    return this.httpClient.post(url, formData).pipe
      (map(result => {
        return result;

      },
        errors => {
          console.log(errors);
        }))
  }

  GetUIElementList(): Promise<DynamicElementBase<string>[]> {
    let url = AppSettings.API_ENDPOINT + AppRelativeUrl.UPLOADUIELEMENTLIST;
    this.ElementList = [];

    return this.httpClient.post(url, '').pipe(
      map(result => {
        this.data = result as [];
        for (let i = 0; i < this.data.length; i++) {
          let elementType = this.data[i]['elementType'];
          switch (elementType) {
            case 'TextBox':
              let t = new TextBox({
                key: this.data[i]['elementName'],
                label: this.data[i]['elementName'],
                value: '',
                required: true,
                isMandatory: this.data[i]['isMandatory'],
                order: this.data[i]['elementSeq'],
                validations: this.data[i]['validationList']
              });
              this.ElementList.push(t);
              break;
            case 'NumberBox':
              let y = new NumberBox({
                key: this.data[i]['elementName'],
                label: this.data[i]['elementName'],
                value: '',
                required: true,
                isMandatory: this.data[i]['isMandatory'],
                order: this.data[i]['elementSeq'],
                validations: this.data[i]['validationList']
              });
              this.ElementList.push(y);
              break;
            case 'DatePicker':
              let d = new DatePicker({
                key: this.data[i]['elementName'],
                label: this.data[i]['elementName'],
                value: '',
                required: true,
                isMandatory: this.data[i]['isMandatory'],
                order: this.data[i]['elementSeq']
              });
              this.ElementList.push(d);
              break;
            case 'ComboBox':
              let defaultValue;
              if (this.data[i]['elementVal'].length > 0) {
                this.ElementVal = [];
                let t: string;
                t = this.data[i]['elementName'];
                this.data[i]['elementVal'].forEach((value) => {
                  this.ElementVal.push({ key: value, value: value });
                  if (this.data[i]['defaultValue'] == value)
                    defaultValue = value;
                });
                if (this.ElementVal.length == 1) {
                  defaultValue = this.ElementVal[0].value;
                }
              }
              let x = new ComboBox({
                key: this.data[i]['elementName'],
                label: this.data[i]['elementName'],
                isMandatory: this.data[i]['isMandatory'],
                value: defaultValue,
                options: this.ElementVal,
                // options: [
                //   {key: '1',  value: 'PreBind'},
                //   {key: '2',  value: 'PostBind'}        
                // ],
                order: this.data[i]['elementSeq']
              });
              this.ElementList.push(x);
              this.ElementVal = [];
              break;

            case 'ComboBoxAuto':
              let defaultValue2;
              if (this.data[i]['elementVal'].length > 0) {
                this.ElementVal = [];
                let t: string;
                t = this.data[i]['elementName'];
                this.data[i]['elementVal'].forEach((value) => {
                  this.ElementVal.push({ key: value, value: value });
                  if (this.data[i]['defaultValue'] == value)
                    defaultValue2 = value;
                });
                if (this.ElementVal.length == 1) {
                  defaultValue2 = this.ElementVal[0].value;
                }
              }
              let ca = new ComboBoxAuto({
                key: this.data[i]['elementName'],
                label: this.data[i]['elementName'],
                isMandatory: this.data[i]['isMandatory'],
                value: defaultValue2,
                options: this.ElementVal,
                // options: [
                //   {key: '1',  value: 'PreBind'},
                //   {key: '2',  value: 'PostBind'}        
                // ],
                order: this.data[i]['elementSeq']
              });
              this.ElementList.push(ca);
              this.ElementVal = [];
              break;

          }
        }
        return this.ElementList.sort((a, b) => a.order - b.order);
      })).toPromise();
  }
  UploadShipmentPackage(Url: string, formData: FormData) {
    return this.httpClient.post(Url, formData,
      { reportProgress: true, observe: 'events' })
      .pipe(map(event => {
        return event;
      }
      ));
  }
  public downloadBulkFiles(serviceURL, formData: FormData): Observable<HttpEvent<Blob>> {
    return this.httpClient.post(serviceURL, formData,
      { reportProgress: true, observe: 'events', responseType: 'blob' });
  }

  DownloadTemplate(path: string): Observable<HttpEvent<Blob>> {
    return this.httpClient.request(new HttpRequest('GET', path, {
      reportProgress: true,
      responseType: 'blob'
    }));
  }
}

