import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AppSettings } from 'src/common/AppSettings';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { ProcessTrackingService } from 'src/services/Process-TrackingServices/process-tracking.service';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertService } from '../../../services/Common/alert.service';
import { FormsModule } from '@angular/forms';
import { ignoreElements } from 'rxjs/operators';
import { PreparePillsModel } from '../../../model/AdvancedFilter/FilterPills';
import { PopupService } from 'src/services/Common/popUp.service';


@Component({
  selector: 'app-advance-filter',
  templateUrl: './advance-filter.component.html',
  styleUrls: ['./advance-filter.component.css']
})
export class AdvanceFilterComponent implements OnInit {

  @Output() public found = new EventEmitter<any>();

  errorHandler = new ErrorHandler(this.alertService);
  columnData: any;
  normalColumnData: any;
  showConditions = false;
  selectedColumn: string = '';
  selectedCondition: string = '';
  selectedValue: string = '';
  conditionValues: [string, string][];
  stringCondition: [string, string][];
  numberCondition: [string, string][];
  dateCondition: [string, string][];
  bitCondition: [string, string][];
  valueType: number = 1;
  showCreateFilter: boolean = false;
  displayCondition: boolean = false;
  filter: string = '';
  operatorType = '';

  filterName: string = '';
  filterToShow: string = '';
  // saved filter variables
  savedFilter: any;
  currentFilterSettingId: number = 0;

  // Column value, Condition Value, Input value, Type, id
  selectedArray: [string, string, string, number, number][] = [];

  deleteFilterPill: [string, string, string, number] = null

  showMultiCondition = false;

  multiSelectData: any;
  multiSelectColumnData = [];
  // for custom default value select filter

  selectedMulti = [];
  defaultMultiVals = [];

  pillIdToEdit: number = 0;
  // { id: 1, name: 'Demo' },
  // { id: 2, name: 'Test' },
  // { id: 3, name: 'Apollo' },
  // { id: 4, name: 'D&F' },
  // ];

  constructor(private processTrackingService: ProcessTrackingService
    , private alertService: AlertService, private popService: PopupService) {
    this.stringCondition = [
      ['Contains', 'contains']
      , ['Equals to', '=']
      , ['Not equals to', '!=']
      , ['Starts With', 'start']
      , ['Ends With', 'end']
    ];

    this.numberCondition = [
      ['Equals to', '=']
      , ['Not equals to', '!=']
      , ['Greater than', '>']
      , ['Less than', '<']
    ];

    this.dateCondition = [
      ['One week', '1w']
      , ['One month', '1m']
      , ['One year', '1y']
      , ['Equal to', '=']
      , ['Not equal to', '!=']
    ];

    this.bitCondition = [
      ['Equals to', '=']
      , ['Not equal to', '!=']
      , ['is null', 'null']
    ];
  }

  ngOnInit() {
    this.getColumnsToFilter();
    this.getSavedFilter('1', '0');
    this.getMultiFilterValues();
  }

  public getColumnsToFilter() {
    //console.log(AppSettings.API_ENDPOINT);
    let formData: FormData = new FormData();
    formData.append('moduleName', 'process-tracking');
    // formData.append('disTimeZoneID', '3');

    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETADVANCEDFILTERCOLUMNS, formData).subscribe(
      result => {

        if (result == null || result == 'undefined')
          this.normalColumnData = null;
        else {
          this.normalColumnData = result;
        }
        // console.log(this.columnData);
      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }


  public getMultiFilterValues() {
    //console.log(AppSettings.API_ENDPOINT);
    let formData: FormData = new FormData();
    // formData.append('moduleName', 'process-tracking');
    // formData.append('disTimeZoneID', '3');

    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETMULTISELECTCOLUMNS, null).subscribe(
      result => {
        // console.log('distinct columns');
        // console.log(result);

        this.multiSelectData = result;

        /// get unique values fom multi select data

        let distinctColumn =
          this.multiSelectData.map(item => item['col'])
            .filter((value, index, self) => self.indexOf(value) === index)


        for (let i = 0; i < distinctColumn.length; i++) {
          let multiColArr = {};
          multiColArr['columnName'] = distinctColumn[i];
          multiColArr['columnType'] = 'custom';
          multiColArr['id'] = i + 1;
          multiColArr['isActive'] = true;
          this.multiSelectColumnData.push(multiColArr);
        }

        // console.log(multiSelectColumnData);



        //   if (result == null || result == 'undefined')
        //     this.columnData = null;
        //   else {
        //     this.columnData = result;
        //   }
        //   // console.log(this.columnData);
      }
      , error => {

        // console.log(error);
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }

  public getSavedFilter(execType: string, filterSettingId: string) {

    let formData: FormData = new FormData();
    formData.append('execType', execType);
    formData.append('filterSettingId', filterSettingId);

    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETSAVEDFILTERVALUES, formData).subscribe(
      result => {

        if (result == null || result == 'undefined')
          this.savedFilter = null;
        else {
          this.savedFilter = result;
        }

      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }



  public getPillsValueByFilter(filterSettingId: string) {


    let formData: FormData = new FormData();
    formData.append('execType', '2');
    formData.append('filterSettingId', filterSettingId);

    this.processTrackingService.ProcessTracking(AppSettings.API_ENDPOINT + AppRelativeUrl.GETSAVEDFILTERVALUES, formData).subscribe(
      (result: any[]) => {
        if (result == null || result.length == 0)
          this.selectedArray = [];
        else {
          this.selectedArray = [];
          for (let i = 0; i < result.length; i++) {

            // console.log(result[i]["condition"], result[i]["operators"], result[i]["value"]);
            this.selectedArray.push([result[i]["condition"], result[i]["operators"], result[i]["value"], this.getValueTypeForColumn(result[i]["columnType"]), i + 1]);
          }


          console.log('check saved pills');
          console.log(this.selectedArray);
          // this.savedFilter = result;
        }

      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
      }
    )
  }

  //// gets the type of value for columns
  private getValueTypeForColumn(cType: string = ''): number {

    if (cType.trim().toLowerCase() === 'string')
      return 1;
    else if (cType.trim().toLowerCase() === 'number')
      return 2;
    else if (cType.trim().toLowerCase() === 'date')
      return 3;
    else if (cType.trim().toLowerCase() === 'bit')
      return 4;
    else if (cType.trim().toLowerCase() === 'custom')
      return 5;
    else
      return 0;
  }


  private setMultiValuesToSelect(columnName: string) {

    /// get all the values to show 
    // defaultMultiVals = [
    //   { id: 1, name: 'Demo' },
    //   { id: 2, name: 'Test' },
    //   { id: 3, name: 'Apollo' },
    //   { id: 4, name: 'D&F' },
    // ];



    //console.log(this.multiSelectData);

    this.selectedMulti = [];
    let distinctValues = this.multiSelectData.filter(x => x.col.trim().toLowerCase() === columnName.trim().toLowerCase())
      .map(item => item['val']);

    this.defaultMultiVals = [];

    // let val = {};
    // val['id'] = 0;
    // val['name'] = 'Contains';

    // this.defaultMultiVals.push(val);
    // val = {};
    // val['id'] = 0;
    // val['name'] = 'Equals';
    // this.defaultMultiVals.push(val);

    distinctValues.forEach(element => {
      let val = {};
      val['id'] = 0;
      val['name'] = element;
      this.defaultMultiVals.push(val);
    });




    // console.log(distinctValues);
  }
  // Column's select event
  public setConditionToSelect(obj) {

    let argType = typeof obj;
    let jObj;
    if (argType === "string")
      jObj = JSON.parse(obj);
    else
      jObj = obj;
    this.selectedColumn = jObj.columnName;

    if (this.showMultiCondition) {
      // set values from multi select values
      this.setMultiValuesToSelect(jObj.columnName);
    }
    else {
      if (obj === "" || obj == null) {
        this.resetAdvancedFilter();
      }


      // alert(typeof jObj)

      let cType: string = '';
      cType = String(jObj.columnType);

      this.valueType = this.getValueTypeForColumn(cType);

      if (cType.toLowerCase() === 'string') {
        this.conditionValues = this.stringCondition;
      }

      else if (cType.toLowerCase() === 'number') {
        // this.valueType = 2;
        this.conditionValues = this.numberCondition;
      }

      else if (cType.toLowerCase() === 'date') {
        // this.valueType = 3;
        this.conditionValues = this.dateCondition;
      }

      else if (cType.toLowerCase() === 'bit') {
        // this.valueType = 4;
        this.conditionValues = this.bitCondition;
      }


    }
    // alert(this.selectedColumn);
  }

  public conditionType(obj: any) {
    //console.log(obj);

    if (obj == null)
      return;
    else
      this.selectedCondition = obj;

    // alert(this.selectedColumn);
  }


  public showConditonsDiv(cType: string) {
    this.showConditions = true;
    // alert(cType);
    this.operatorType = cType;

    this.showMultiCondition = false;
    this.columnData = this.normalColumnData;
  }

  public showConditonsDivMulti(cType: string) {
    this.showConditions = true;
    // alert(cType);
    this.operatorType = cType;
    this.showMultiCondition = true;
    this.columnData = this.multiSelectColumnData;
  }

  public closeConditonsDiv() {
    // this.deleteConditon([this.selectedColumn, this.selectedCondition, String(this.selectedValue), this.valueType]);
    // this.saveConditions();

    this.selectedMulti = [];
    this.deleteFilterPill = null;
    this.showConditions = false;
    this.operatorType = '';
    this.pillIdToEdit = 0;
  }

  public saveConditions() {

    // If the values are multi select then add here, for any other condition go to add part

    // console.log(this.showMultiCondition + ' -- ' + this.selectedColumn)
    if (this.showMultiCondition) { //// conditions for multi select
      if (this.selectedColumn == undefined || this.selectedColumn.trim() === '') {
        this.alertService.error('Please select column first');
        return;
      }

      if (this.selectedMulti.length == 0) {
        this.alertService.error('Please select values from dropdown');
        return;
      }

      ////make the condition for multi select
      let condition = this.setMutliSelectCondition(this.selectedMulti, this.selectedColumn);
      this.addValueInFilter(condition, this.selectedColumn, 5);

    }

    else { //every other condition other than multi select

      if (this.selectedColumn == undefined || this.selectedColumn.trim() === ''
        || this.selectedCondition == undefined || this.selectedCondition.trim() === '' || this.selectedValue == undefined || this.selectedValue === '') {
        this.alertService.error('Please select condition first');
        return;
      }

      let condition: string = '';
      this.selectedValue = this.selectedValue.trim();
      if (this.valueType == 1) { // string condition
        condition = this.setStringCondition(this.selectedColumn, this.selectedCondition, this.selectedValue);
      }
      else if (this.valueType == 2) { // number condition
        condition = this.setNumberCondition(this.selectedColumn, this.selectedCondition, Number(this.selectedValue));
        // this.setNumberCondition();
      }
      else if (this.valueType == 3) { // date condition
      }
      else if (this.valueType == 4) { // bit condition
      }

      this.addValueInFilter(condition, this.selectedColumn, this.valueType);
      // if (this.deleteFilterPill != null) {
      //   this.deleteConditon(this.deleteFilterPill);
      //   this.deleteFilterPill = null;
      // }

    }
    // this.deleteConditon([this.selectedColumn, this.selectedCondition, String(this.selectedValue), this.valueType])
    this.resetAdvancedFilter();
  }


  private setMutliSelectCondition(selectedMulti: any[], selectedColumn: string): string {

    let multiSel: string = "";
    for (let i = 0; i < selectedMulti.length; i++) {
      if (i == 0)
        multiSel = '\'' + selectedMulti[i] + '\'';
      else
        // multiSel = '\'' + this.selectedMulti[i] + '\'';
        multiSel += ', \'' + selectedMulti[i] + '\'';
    }

    let condition: string = '';

    condition = 'Lower([' + selectedColumn + ']) in (' + multiSel.trim() + ')';

    /// set varibale for further wokring
    this.selectedCondition = 'in';
    this.selectedValue = multiSel.trim();

    return condition;
  }
  private setStringCondition(columnName: string, selectedCondition: string, selectedValue: string): string {
    //// set the string condition here
    //// if contians make the string like columnsname like  '%afasfas%'

    /// check first if it is to be added replaced

    // let columnName = this.selectedColumn;
    let condition = '';
    // this.checkPrevfilter(columnName);

    // if (this.filter === '')
    //   this.filter = columnName + ' asc'
    // else
    //   this.filter += ' and ' + columnName + ' asc';

    // selectedCondition = selectedCondition.trim().toLowerCase();
    // for (let i = 0; i < this.stringCondition.length; i++) {
    //   if (this.stringCondition[i][1].trim().toLowerCase() === selectedCondition)
    //     selectedCondition = this.stringCondition[i][1];
    //   break;
    // }

    // check what type of consition to create
    if (selectedCondition.toLowerCase() === 'contains') {
      condition = 'Lower([' + columnName + '])' + ' like  \'%' + selectedValue.toLowerCase() + '%\'';
      // this.filterToShow = 'Lower([' + columnName + '])' + ' like  \'%' + this.selectedValue.toLowerCase() + '%\'';
    }

    else if (selectedCondition.toLowerCase() === '=') {
      condition = 'Lower([' + columnName + '])' + ' =  \'' + selectedValue.toLowerCase() + '\'';
    }
    else if (selectedCondition.toLowerCase() === '!=') {
      condition = 'Lower([' + columnName + '])' + ' <>  \'' + selectedValue.toLowerCase() + '\'';
    }
    else if (selectedCondition.toLowerCase() === 'start') {
      condition = 'Lower([' + columnName + '])' + ' like  \'%' + selectedValue.toLowerCase() + '\'';
    }
    else if (selectedCondition.toLowerCase() === 'end') {
      condition = 'Lower([' + columnName + '])' + ' like  \'' + selectedValue.toLowerCase() + '%\'';
    }
    return condition;
    //  this.showConditions = false;
  }

  private setNumberCondition(columnName: string, selectedCondition: string, selectedValue: number): string {
    let condition = '';
    // this.numberCondition;

    // selectedCondition = selectedCondition.trim().toLowerCase();
    // for (let i = 0; i < this.numberCondition.length; i++) {
    //   if (this.numberCondition[i][1].trim().toLowerCase() === selectedCondition)
    //     selectedCondition = this.numberCondition[i][1];
    //   break;
    // }


    if (selectedCondition === '=') {
      condition = 'Lower([' + columnName + '])' + ' =  \'' + selectedValue + '\'';
    }
    else if (selectedCondition === '!=') {
      condition = 'Lower([' + columnName + '])' + ' <>  \'' + selectedValue + '\'';
    }
    else if (selectedCondition === '>') {
      condition = 'Lower([' + columnName + '])' + ' >  \'' + selectedValue + '\'';
    }
    else if (selectedCondition === '<') {
      condition = 'Lower([' + columnName + '])' + '   \'' + selectedValue + '\'';
    }

    return condition;
    // this.addValueInFilter(condition, columnName);

    //console.log(this.filter)

    // this.resetAdvancedFilter();
  }





  private fillPillsAndMakeFilter(value, columnName, valueType: number) {
    // alert(value);

    // if (value.trim() === '') {
    //   this.alertService.warn('This condition cannot be added');
    //   return;
    // }

    // if (this.filter.trim().includes(value.trim())) {
    //   this.alertService.warn('This condition already exists');
    //   return;
    // }


    // if (this.operatorType.trim() === '')
    //   this.operatorType = 'and';
    // if (this.filter === '')
    //   this.filter = value
    // else {

    // this.selectedArray.push([columnName, this.selectedCondition, this.selectedValue, valueType]);


    /// 1) Get distinct column name
    /// 2) get filters with select column names
    /// 3) add those columns in a or condition
    /// 4) add others with an and 
    /// 5) update filter 

    // let distinctColumn = 
    // this.selectedArray.map(item => item[0])
    // .filter((value, index, self) => self.indexOf(value) === index)

    // for(let i = 0; i < distinctColumn.length; i++){

    // }





    // console.log(test);

    // }
  }


  private addValueInFilter(value, columnName, valueType: number) {
    // alert(value);

    if (value.trim() === '') {
      this.alertService.warn('This condition cannot be added');
      return;
    }

    if (this.filter.trim().includes(value.trim())) {
      this.alertService.warn('This condition already exists');
      return;
    }


    if (this.operatorType.trim() === '')
      this.operatorType = 'and';

    if (this.filter === '')
      this.filter = value

    else {   // this.filter += ' ' + this.operatorType + ' ' + value;

      //// add same columns with or

      if (value.trim() != '') {

        columnName = columnName.trim();
        let splitArr = this.filter.split('and');

        let checkColumnVal: string = 'Lower([' + columnName + '])';

        // if(this.filter === ''){
        //   this.filter
        // }

        // get distinct
        // let findFilterVal = splitArr.filter(a=> a.indexOf(checkColumnVal) > -1 ? a : '');
        // console.log(findFilterVal);

        let ifConditionAdded = false;
        for (let i = 0; i < splitArr.length; i++) {
          if (splitArr[i].trim().toLowerCase().indexOf(checkColumnVal.toLowerCase()) > -1) {
            // column found 
            let foundCondition = splitArr[i].trim();
            //check if it is an "In" type of condition then replace with different kind
            // if(foundCondition.indexOf(' in '))
            // if(foundCondition.endsWith('\')'))
            // foundCondition = foundCondition + ')';
            //replace only first or last (  )
            foundCondition = splitArr[i].trim().replace(/^\(+|\)$/g, '');

            /// check if there are more than one condition already 
            // let splitForOr = foundCondition.split['or'];
            // console.log(splitForOr);
            /// add condition
            // if (splitForOr == undefined || splitForOr.length == 1)


            let validateString = foundCondition.substring(
              foundCondition.lastIndexOf("]) ") + 2,
              foundCondition.length
            );

            if (validateString.indexOf(' in ') > -1 && validateString.indexOf('(') > -1 && validateString.indexOf(')') == -1)
              foundCondition += ')';



            //// check if previous condition is to added in found condition





            // alert(foundCondition);
            if (this.pillIdToEdit) {
              // console.log('pilltoedit' + this.pillIdToEdit)
              let selPill: [string, string, string, number, number];
              for (let i = 0; i < this.selectedArray.length; i++) {
                if (this.selectedArray[i][4] === this.pillIdToEdit) {
                  selPill = this.selectedArray[i];
                  break;
                }
              }

              // console.log(selPill);


              let prevCondition = this.remakeConditionTodelete(selPill);

              foundCondition = foundCondition.replace(prevCondition, value);

              console.log('prev condition -- ' + prevCondition, '   ---- new condition  --' + value);
              if (foundCondition.indexOf(' or ') == -1) {
                splitArr[i] = foundCondition;
              }
              else
                splitArr[i] = "(" + foundCondition + ' ) '
            }
            else
              splitArr[i] = "(" + foundCondition + ' or ' + value + ' ) ';


            // else {
            //   splitForOr.push(value);
            //   foundCondition = splitForOr.join(' or ');
            //   splitArr[i] = "( " + foundCondition + " ) "; 
            // }

            ifConditionAdded = true;
            break;
          }
        }
        // this.filter = newFilter.join(' and ');

        if (!ifConditionAdded) {
          splitArr.push(value);
        }

        this.filter = splitArr.join(' and ');
      }
    }


    if (this.filter.trimRight().endsWith('and')) {
      this.filter = this.filter.trimRight().substring(0, this.filter.length - 3);
    }

    if (this.filter.trimRight().endsWith('or')) {
      this.filter = this.filter.trimRight().substring(0, this.filter.length - 2);
    }


    if (this.pillIdToEdit > 0 && this.selectedArray.length > 0) {
      this.selectedArray.filter
      for (let i = 0; i < this.selectedArray.length; i++) {
        if (this.selectedArray[i][4] === this.pillIdToEdit) {
          this.selectedArray[i][0] = columnName;
          this.selectedArray[i][1] = this.selectedCondition;
          this.selectedArray[i][2] = this.selectedValue;
          this.selectedArray[i][3] = valueType;
        }
      }
      this.pillIdToEdit = 0;
    }
    else
      this.selectedArray.push([columnName, this.selectedCondition, this.selectedValue, valueType, (this.selectedArray.length + 1)]);

    console.log('to check new array made');
    console.log(this.selectedArray);

    /// sort the array 
    // this.selectedArray = this.selectedArray.sort((a, b) => (a[0] < b[0] ? -1 : 1));

    // this.fillPillsAndMakeFilter(value, columnName, valueType);
    // alert(this.filter);
  }

  private checkPrevfilter(headerName: string) {
    if (this.filter.includes(headerName)) {
      this.filter = this.filter.replace(' and ' + headerName + ' asc', '');
      this.filter = this.filter.replace(headerName + ' desc', '');
      this.filter = this.filter.replace(' and ' + headerName + ' desc', '');
      this.filter = this.filter.replace(headerName + ' asc', '');
      this.filter = this.filter.replace(headerName + ' desc', '');

      if (this.filter.trim() === 'and')
        this.filter = '';

      if (this.filter.trimLeft().startsWith('and')) {
        this.filter = this.filter.trimLeft().substring(1, this.filter.length);
      }

      if (this.filter.trimRight().endsWith('and')) {
        this.filter = this.filter.trimLeft().substring(0, this.filter.length - 1);
      }
      //console.log(this.filter);
    }
  }


  private resetAdvancedFilter() {
    this.selectedColumn = "";
    this.selectedCondition = "";
    this.selectedValue = "";
    this.showConditions = false;
    this.valueType = 1;
    this.conditionValues = [];
    this.selectedMulti = [];
    this.pillIdToEdit = 0;
    // this.filterName ='';
  }

  public toggleConditons() {
    if (this.showConditions)
      this.showConditions = false;
    else
      this.showConditions = true;
  }

  fetchData() {

    this.found.emit(this.filter);
  }


  private remakeConditionTodelete(pill: [string, string, string, number, number]): string {

    let colType: number = Number(pill[3]);
    let condition: string = "";
    if (colType == 1) {
      condition = this.setStringCondition(pill[0], pill[1], pill[2]);
    }
    else if (colType == 2) {
      condition = this.setNumberCondition(pill[0], pill[1], Number(pill[2]));
    }
    else if (colType == 5) {
      let arrMultiSelect = pill[2].split(',');
      let tmpArrSelectedValue = [];

      for (let i = 0; i < arrMultiSelect.length; i++) {
        tmpArrSelectedValue.push(arrMultiSelect[i].trim().replace(/^\'+|\'$/g, ''));
        // console.log(arrMultiSelect[i].trim().replace(/^\'+|\'$/g, ''));
      }

      /// make the default string here and delete frome filter
      condition = this.setMutliSelectCondition(tmpArrSelectedValue, pill[0]);
    }

    return condition;
  }

  deleteConditon(pill: [string, string, string, number, number]) {


    let newSelArray: [string, string, string, number, number][] = [];
    for (let i = 0; i < this.selectedArray.length; i++) {
      if (this.selectedArray[i][0].trim().toLowerCase() === pill[0].trim().toLowerCase() && this.selectedArray[i][1].trim().toLowerCase() === pill[1].trim().toLowerCase()
        && this.selectedArray[i][2].trim().toLowerCase() === pill[2].trim().toLowerCase() && this.selectedArray[i][3] == pill[3]) {
        continue;
      }
      else
        newSelArray.push(this.selectedArray[i]);
    }

    //replce the new array 
    this.selectedArray = [];
    this.selectedArray = newSelArray



    /// make and delete the condition for filter variable


    // this.filter = this.filter.replace(condition, '');
    // alert(this.filter);
    // replace the value from condition as well when done .
    let condition = this.remakeConditionTodelete(pill);
    if (condition.trim() === '') {
      this.filter = '';
      this.filterToShow = '';
    }
    else {
      let splitArr = this.filter.split('and');
      let newFilter: string[] = [];
      for (let i = 0; i < splitArr.length; i++) {
        if (splitArr[i].trim().indexOf('or') > -1) {
          let splitArrForOr = splitArr[i].trim().replace(/^\(+|\)$/g, '').trim().split('or');
          let addArrForOr = [];
          for (let j = 0; j < splitArrForOr.length; j++) {
            if (splitArrForOr[j].trim() != condition.trim()) {
              addArrForOr.push(splitArrForOr[j]);
            }
          }  /// or loop finish

          let newOrString = '';

          if (addArrForOr != undefined) {
            newOrString = addArrForOr.join(' or ');
            if (addArrForOr.length > 1) {

              newOrString = '(' + newOrString + ')';


            }
          }
          // else
          // newOrString = splitArrForOr;

          // console.log(newOrString);
          newFilter.push(newOrString);

        }
        else if (splitArr[i].trim() != condition)
          newFilter.push(splitArr[i].trim());
      }
      this.filter = newFilter.join(' and ');
    }
  }


  makeFirstCharCapital(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }



  setElement(id: string, name: string, obj: any = null) {

    setTimeout(function () {
      let eleColumn = document.getElementById(id) as HTMLSelectElement;
      if (eleColumn == null || eleColumn == undefined) {

        this.setElement(String(id), String(name), null);
      }

      else { /// make josn to set cpolumnselect
        if (id === 'columnSelect') {


          for (let i = 0; i < obj.length; i++) {
            if (obj[i].columnName === name) {
              //  obj = this.columnData[i];
              //console.log(JSON.stringify(obj[i]));
              //eleColumn.value = obj[i];
              eleColumn.selectedIndex = i + 1
              break;
            }
          }

          // console.log(newPill);
        }
        else
          eleColumn.value = name;
      }
    }, 100);
  }



  resetValuesToEdit(pill: [string, string, string, number, number]) {
    // console.log(pill)
    this.pillIdToEdit = pill[4];

    if (pill[1].trim().toLowerCase() === 'in') {

      pill[3] = 5;
      this.columnData = this.multiSelectColumnData;
    }
    else
      this.columnData = this.normalColumnData;

    this.setElement("columnSelect", pill[0], this.columnData);

    /// set if edit pill is type of custom
    if (pill[3] == 5) {
      let arrMultiSelect = pill[2].split(',');


      this.setMultiValuesToSelect(pill[0]);

      for (let i = 0; i < arrMultiSelect.length; i++) {
        this.selectedMulti.push(arrMultiSelect[i].trim().replace(/^\'+|\'$/g, ''));
        // console.log(arrMultiSelect[i].trim().replace(/^\'+|\'$/g, ''));
      }

      // fill value for multi select values

      this.selectedCondition = 'in';
      this.showMultiCondition = true;
      this.selectedColumn = pill[0];
      this.selectedValue = pill[2];

    }
    else {
      // get what type is the column and then call the event to fill other values 
      for (let i = 0; i < this.columnData.length; i++) {
        if (this.columnData[i].columnName === pill[0]) {
          let obj = this.columnData[i];
          this.setConditionToSelect(obj);
          this.getEditCorrectValue(obj, pill[1]);
          break;
        }
      }
      this.selectedCondition = pill[1];

      this.setElement("valueSelectString", pill[2]);
      // this.setElement("valueSelectNumber", pill[2]);
      this.showMultiCondition = false;
      this.selectedValue = pill[2];
    }
  }

  //// get ans set  the type of values to be filled when clicked on edit
  private getEditCorrectValue(obj, valueToSelect: string) {
    let conArray: [string, string][];
    if (obj.columnType.toLowerCase() === 'string') {

      conArray = this.stringCondition;
    }


    else if (obj.columnType.toLowerCase() === 'number') {
      conArray = this.numberCondition;
    }

    else if (obj.columnType.toLowerCase() === 'date') {
      conArray = this.dateCondition;
    }


    else if (obj.columnType.toLowerCase() === 'bit') {
      conArray = this.bitCondition;
    }


    for (let i = 0; i < conArray.length; i++) {
      if (conArray[i][1] === valueToSelect) {
        this.setElement("filterSelect", conArray[i][1]);
        break;
      }
    }

  }


  editConditon(pill: [string, string, string, number, number]) {
    // this.selectedColumn = pill[0];
    // this.selectedCondition = pill[1];
    // this.selectedValue = pill[2];


    this.showConditions = true;
    this.resetValuesToEdit(pill);
    // this.deleteFilterPill = pill;
    // this.deleteConditon(pill);
    // console.log(pill);
  }


  prepareFilterToSave() {
    let prepareFilter: PreparePillsModel[] = [];

    // let formData: FormData = new FormData();


    // formData.append('filter', JSON.stringify(this.filter));
    // formData.append('order', JSON.stringify(''));



    for (let i = 0; i < this.selectedArray.length; i++) {
      const test = {}
      let filter: PreparePillsModel = new PreparePillsModel();
      filter.prevId = 0;
      filter.userid = 0;
      filter.condition = this.selectedArray[i][0];
      filter.operators = this.selectedArray[i][1];
      filter.value = this.selectedArray[i][2];
      filter.filterSettingId = 0;


      prepareFilter.push(filter);
      // formData.append('advancedFilterPills', JSON.stringify(filter));
    }

    const data = {
      'filter': this.filter, 'order': '', 'prevFilterValue': this.currentFilterSettingId
      , 'filterName': this.filterName, 'filterToShow': this.filterToShow, 'advancedFilterPills': prepareFilter
    }
    return data;
  }


  saveFilter() {
    // console.log( JSON.stringify(this.prepareFilterToSave()));

    // formData.append('disTimeZoneID', '3');

    if (this.filter.trim() === '') {
      this.alertService.error('Empty filter cannot be saved.');
      return;
    }

    if (this.filterName.trim() === '') {
      this.alertService.error('Filter name cannot be empty.');
      return;
    }
    else {

      // console.log(this.savedFilter);
      if (this.savedFilter.length > 0) {

        if (this.savedFilter.length >= 10) {
          this.alertService.error('Max 10 filters can be saved.');
          return;
        }


        if (this.currentFilterSettingId == 0) {
          let ifReturn = false;
          for (let i = 0; i < this.savedFilter.length; i++) {
            if (this.savedFilter[i].filterName != null && this.savedFilter[i].filterName.trim().toLowerCase() === this.filterName.trim().toLowerCase()) {
              ifReturn = true;
              break;
            }
          }

          if (ifReturn) {
            this.alertService.error('You already have a filter saved with this name.');
            return;
          }
        }
      }
    }


    this.processTrackingService.SendDataASBody(AppSettings.API_ENDPOINT + AppRelativeUrl.UPDATEADVANCEDFILTER, this.prepareFilterToSave()).subscribe(
      result => {
        this.getSavedFilter('1', '0');
        this.filterName = '';
        this.filter = '';
        this.filterToShow = '';
        this.selectedArray = [];
        this.alertService.success('Filter saved successfully.');
        let closeModal = document.getElementById('btnCloseSaveFilterModal') as HTMLButtonElement;
        closeModal.click();
      }
      ,
      error => {
        this.errorHandler.ErrorsHandler(error);
        let closeModal = document.getElementById('btnCloseSaveFilterModal') as HTMLButtonElement;
        closeModal.click();
      });
  }


  public selectedSavedFilter(obj: any) {
    this.filter = obj.filter;
    this.fetchData();
  }



  public editFilter(obj: any) {
    //  alert(obj.filterID);
    this.showCreateFilter = true;
    let filterId = obj.filterID;
    this.filter = obj.filter;
    this.filterName = obj.filterName;
    this.currentFilterSettingId = filterId;
    this.getPillsValueByFilter(filterId);
  }


  public resetFilter() {
    this.selectedColumn = '';
    this.selectedArray = [];
    this.selectedValue = '';
    this.selectedCondition = '';
    this.showConditions = false;
    this.filter = '';
    this.filterToShow = '';
    this.filterName = '';
  }
  // async fileSelected(file) {
  //   // console.log(file.srcElement.files[0].size)
  //   file= file.srcElement.files[0];
  //   // console.log(file);
  //   const chunkSize = 40000;
  // // alert(file.size);
  //   let url = AppSettings.API_ENDPOINT + 'ProcessTracking/UploadFileKshitij';
  //   // for( let offset = 0; offset < file.size; offset += chunkSize){
  //   //   const chunk = file.slice( offset, offset + chunkSize );
  //   //   // console.log(chunk)
  //       const fd = new FormData() 
  //       fd.append('UploadFiles', file, file.name);
  //       // // fd.append('FileName', "zip")
  //       // // fd.append('Name', 'abc')

  //      // await fetch(url, {method: 'post', body: fd}).then(res => res.text())
  //        this.processTrackingService.fetch(url,  fd).then(res => res)
  //    //   // alert(await fetch(url, { body: fd}).then(res => res.text()));
  //   // }
  // }

  deleteFilter(item: any) {
    let action: string = "Delete";
    this.popService.Confirmation('Are you sure?', ' you want to ' + action + '!', 'Yes, ' + action, 'No, Cancel').then(
      result => {
        if (result.value) {
          this.processTrackingService.deleteFilter(item).subscribe(response => {

            // this.ngOnInit();
            this.getSavedFilter('1', '0');
            this.resetAdvancedFilter();
            this.savedFilter = '';
          },
            error => {
              this.errorHandler.ErrorsHandler(error);
            });
        }
      }
    )
  }

  //// set the condition for multi value
  public setMultiValueCondition() {

    let multiSel: string = "";
    for (let i = 0; i < this.selectedMulti.length; i++) {
      if (i == 0)
        multiSel = '\'' + this.selectedMulti[i] + '\'';
      else
        // multiSel = '\'' + this.selectedMulti[i] + '\'';
        multiSel += ', \'' + this.selectedMulti[i] + '\'';


    }
  }


  toggleCreateFilter() {
    this.showCreateFilter = !this.showCreateFilter
    this.resetAdvancedFilter();
    this.filter = '';
    this.selectedArray = [];

  }

  toggleDisplayCondition() {
    this.displayCondition = !this.displayCondition
  }

}
