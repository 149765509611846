import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRelativeUrl } from 'src/common/AppRelativeUrl';
import { AppSettings } from 'src/common/AppSettings';
import { CommonMethod } from 'src/common/CommonMethod';
import { ErrorHandler } from 'src/common/error-handler';
import { FeatureMaster } from 'src/common/feature-master';
import { AddEditUserSerivceService } from 'src/services/AccessManagement/Users/add-edit-user-serivce.service';
import { AlertService } from 'src/services/Common/alert.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent implements OnInit {

  constructor(private addedituserService: AddEditUserSerivceService, private alertservice: AlertService, private route: Router, private alertService: AlertService) { }
  accountType: boolean = true;
  businessUnit: boolean = false;
  location: boolean = false;
  errorHandler: ErrorHandler = new ErrorHandler(this.alertservice);
  obj: CommonMethod = new CommonMethod();
  allowaddeditMaster: boolean;
  ngOnInit(): void {
    this.CheckAuthorization();  
  }
  SelectAction(item) {
    if (item === "AccountType") {
      this.accountType = true
      this.businessUnit = false;
      this.location = false;
    }
    if (item === "BusinessUnit") {
      this.accountType = false
      this.businessUnit = true;
      this.location = false;
    }
    if (item === "Location") {
      this.accountType = false
      this.businessUnit = false;
      this.location = true;
    }
  }
  CheckAuthorization() {
    this.addedituserService.GetUserInfo(AppSettings.API_ENDPOINT + AppRelativeUrl.GETUSERINFO).subscribe(res => {
      // console.log('********************',res)
      if (res != null && res != "undefined") {
        this.allowaddeditMaster = this.obj.CheckFeatureAccess(res["roleFeatureMapping"], FeatureMaster.AddEdit_Master);
        if (!this.allowaddeditMaster) {
          this.alertservice.warn("Unauthorized Access");
          this.route.navigate(['home']);
        }
      }
    }
      , err => {
        this.errorHandler.ErrorsHandler(err);
      })

  }
}
