<app-loader></app-loader>
<div class="wrapper">

  <app-header *ngIf="showNav"></app-header>
  <!-- <app-header2></app-header2>  -->
  <!-- Content Wrapper. Contains page content -->
  <div [ngClass]="{TopPaddingAlert: showNav}"><app-alert></app-alert></div>
  <!-- [ngClass]="{TopPaddingWrapper: showNav}" -->
  <div class="content-wrapper"  >
    <!-- Main content -->
    <section class="content">
      <!-- container-fluid -->
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </section>
    <!-- /.content -->
  </div>
  <!-- Main Footer -->
  <app-footer *ngIf="showNav"></app-footer>
</div>
<app-scroll-top></app-scroll-top>