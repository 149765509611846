import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChangepasswordserviceService } from 'src/services/LoginServices/changepasswordservice.service';
import { CookieService } from 'ngx-cookie-service';
import { LogoutService } from 'src/services/LoginServices/logout.service';
import { Router } from '@angular/router';
import { ErrorHandler } from 'src/common/error-handler';
import { AlertService } from 'src/services/Common/alert.service';
import { UserSignService } from 'src/services/LoginServices/user-sign.service';

@Component({
  selector: 'app-firsttimepasswordchange',
  templateUrl: './firsttimepasswordchange.component.html',
  styleUrls: ['./firsttimepasswordchange.component.css']
})
export class FirsttimepasswordchangeComponent implements OnInit {
  userName: any;


  constructor(private fb: UntypedFormBuilder, private service: ChangepasswordserviceService,
    private router: Router, private cookieService: CookieService, private logoutService: LogoutService,
    private alertService: AlertService, private UserSignService: UserSignService  ) { }
  capsOn;
  FormData: UntypedFormGroup;
  ShowOldPasswordErrors: boolean;
  ShowNewPasswordErrors: boolean;
  ShowConfirmPasswordErrors: boolean;
  shownotification: boolean;
  message: string;
  //variable: boolean;
  notification: boolean;
  notfound: boolean;
  template: boolean;
  @Input() check: boolean = false;
  errorHandler = new ErrorHandler(this.alertService);
  Year: string = Date();
  Token: any;

  ngOnInit() {
    
    this.UserSignService.emailId$.subscribe(result => { this.userName = result })
    this.UserSignService.tokenforFirstTimeChangePassword.subscribe(x => this.Token = x);
    this.template = true;
    this.FormData = this.fb.group({
      Old_Password: ["", [Validators.required]],
      New_Password: ["", [Validators.required, Validators.maxLength(16), Validators.minLength(9)
        , Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$!@#$%()_+^&}{:;?."])[A-Za-z\\d$!@#$%()_+^&}{:;?."].{9,16}$')]],

      Confirm_Password: ["", [Validators.required]]
    },
      {
        validators: MustMatch('New_Password', 'Confirm_Password')
      });
  }
  get f() {
    return this.FormData.controls;
  }
  ShowOldPassword() {
    this.ShowOldPasswordErrors = true;
  }
  ShowNewPasswordError() {
    this.ShowNewPasswordErrors = true;
  }
  ShowConfirmPasswordError() {
    this.ShowConfirmPasswordErrors = true;
  }
  ChangePassword(OldPassword: string, NewPassword: string) {

    this.ShowOldPasswordErrors = true;
    this.ShowNewPasswordErrors = true;
    this.ShowConfirmPasswordErrors = true;

    if (this.FormData.invalid) {
      return;
    }
    if (OldPassword == NewPassword) {
      this.alertService.warn("Old password and new password should not be same");
      return;
    }
    this.service.ChangePasswordFirstTime( OldPassword, NewPassword,this.Token).subscribe(
      res => {
        if (res) {
          this.notification = true;
          this.notfound = false;
          this.template = false;
          this.cookieService.delete('ApiQuote');
          this.cookieService.delete('Refresh');
          this.cookieService.delete('UserName');
          this.cookieService.delete('FTLI');
          //  this.alertService.success("Password has been changed successfully!")
          //  alert("Password has been changed successfully!")
          //  this.router.navigate(['login'])    
        }
        else {
          this.notfound = true;
          this.notification = false;
          this.template = true;
          // this.alertService.warn('Old password is not matched!')
        }
        //     console.log(this.variable);
      },
      err => {
        this.errorHandler.ErrorsHandler(err);
      }
    )
  }
}
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}

