<!-- Content Header (Page header) -->
<hr style="margin-top: 0px !important;" />

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default">
      <div class="card-header" style="padding-left: 10px;">
        <div class="card-tools" style="float: left;">
          <div class="input-group input-group-sm" routerLink="/clientmanagement" style="width: 100px;">
            <button type="button" class="btn btn-default btn-block btn-sm" aria-expanded="false"
              aria-controls="collapseExample" style="max-width:50px;">
              Back
            </button>
            <div class="input-group-append">
              <button type="submit" class="btn btn-default btn-block btn-sm MoodysbackgroudColorSecond" routerLink="/clientmanagement"
               >
                <i class="fa fa-arrow-left RMSSearchButtonIcon" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <h3 class="card-title MoodysTittleColor" >
          Contract Details : {{ clientName }}
        </h3>
        <div class="card-tools" style="margin-left: 20px;">
          <div class="input-group input-group-sm" style="width: 150px;" *ngIf="valueRecieved=='1'">
            <button type="button" class="btn btn-default btn-block btn-sm" data-toggle="collapse"
              data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" style="width: 120px;"
              (click)="addNewContractButton()">
              Add Contract
            </button>
            <div class="input-group-append">
              <button type="submit" class="btn btn-default btn-block btn-sm" data-toggle="collapse"
                data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                style="background-color: rgb(196, 32, 50);">
                <i class="fas fa-plus RMSSearchButtonIcon"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="card-body">
        <form [formGroup]="contractManagementForm" (ngSubmit)="onSubmit()">
          <div class="row form-mobile col" *ngIf="isShowDiv">
            <div class="col-sm-3 col-md-6 col-lg-6">
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">Contract Name</label>
                <div class="col-8">
                  <input id="" name="" placeholder="Contract Name" type="text" required="required" class="form-control"
                    formControlName="contractName" [ngClass]="{
                     'is-invalid': submitted && f.contractName.errors
                   }" />
                  <div *ngIf="submitted && f.contractName.errors" class="invalid-feedback">
                    <div *ngIf="f.contractName.errors.required">
                      Name is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">Contract Start Date</label>
                <div class="col-8">
                  <input placeholder="MM/dd/yyyy" type="date" class="form-control" formControlName="contractStartDate"
                    [ngClass]="{
                     'is-invalid': submitted && f.contractStartDate.errors
                   }" />
                  <div *ngIf="submitted && f.contractStartDate.errors" class="invalid-feedback">
                    <div *ngIf="f.contractStartDate.errors.required">
                      Start Date is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="text" class="col-4 col-form-label">Contract Expiry Date</label>
                <div class="col-8">
                  <input id="text" name="text" placeholder="" type="date" class="form-control"
                    formControlName="contractExpiryDate" [ngClass]="{
                     'is-invalid': submitted && f.contractExpiryDate.errors
                   }" />
                  <div *ngIf="submitted && f.contractExpiryDate.errors" class="invalid-feedback">
                    <div *ngIf="f.contractExpiryDate.errors.required">
                      Expire Date is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="select" class="col-4 col-form-label">Project Manager</label>
                <div class="col-8">
                  <ng-select formControlName="projectManager" placeholder="Project Manager"
                    [(ngModel)]="selectedAccountManager" [ngClass]="{
                     'is-invalid': submitted && f.projectManager.errors
                   }">
                    <ng-option *ngFor="let item of accountManagerList" [value]="item.userID">
                      {{ item.userName }}
                    </ng-option>
                    <div *ngIf="submitted && f.projectManager.errors" class="invalid-feedback">
                      <div *ngIf="f.projectManager.errors.required">
                        Project Manager is required
                      </div>
                    </div>
                  </ng-select>
                  <div *ngIf="submitted && f.projectManager.errors" class="invalid-feedback">
                    <div *ngIf="f.projectManager.errors.required">required</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="text" class="col-4 col-form-label">Subscribe</label>
                <div class="col-8">
                  <ng-select formControlName="subscribedTo" placeholder="Account Type" [(ngModel)]="selectedAccountType"
                    [ngClass]="{
                     'is-invalid': submitted && f.subscribedTo.errors
                   }">
                    <ng-option *ngFor="let item of accounttypeList" [value]="item.accountTypeID">
                      {{ item.accountTypeName }}
                    </ng-option>
                  </ng-select>
                  <div *ngIf="submitted && f.subscribedTo.errors" class="invalid-feedback">
                    <div *ngIf="f.subscribedTo.errors.required">required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-6 col-lg-6">
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">Accounts Per AEU</label>
                <div class="col-8">
                  <input id="" name="" placeholder="Accounts Per AEU" type="text" class="form-control"
                    formControlName="accountPerAeu" [ngClass]="{
                     'is-invalid': submitted && f.accountPerAeu.errors
                   }" />
                  <div *ngIf="submitted && f.accountPerAeu.errors" class="invalid-feedback">
                    <div *ngIf="f.accountPerAeu.errors.required">required</div>
                    <div *ngIf="f.accountPerAeu.errors.pattern">
                      Only numeric
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">Location Per AEU</label>
                <div class="col-8">
                  <input id="" name="" placeholder="Location Per AEU" type="text" class="form-control"
                    formControlName="locationPerAeu" [ngClass]="{
                     'is-invalid': submitted && f.locationPerAeu.errors
                   }" />
                  <div *ngIf="submitted && f.locationPerAeu.errors" class="invalid-feedback">
                    <div *ngIf="f.locationPerAeu.errors.required">required</div>
                    <div *ngIf="f.locationPerAeu.errors.pattern">
                      Only numeric
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">No of AEU</label>
                <div class="col-8">
                  <input id="" name="" placeholder="No of AEU" type="text" class="form-control"
                    formControlName="noOfAeu" [ngClass]="{ 'is-invalid': submitted && f.noOfAeu.errors }" />
                  <div *ngIf="submitted && f.noOfAeu.errors" class="invalid-feedback">
                    <div *ngIf="f.noOfAeu.errors.required">required</div>
                    <div *ngIf="f.noOfAeu.errors.pattern">Only numeric</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">AVG Account Size</label>
                <div class="col-8">
                  <input id="" name="" placeholder="AVG Account Size" type="text" class="form-control"
                    formControlName="avgAccountSize" [ngClass]="{
                     'is-invalid': submitted && f.avgAccountSize.errors
                   }" />
                  <div *ngIf="submitted && f.avgAccountSize.errors" class="invalid-feedback">
                    <div *ngIf="f.avgAccountSize.errors.required">required</div>
                    <div *ngIf="f.avgAccountSize.errors.pattern">
                      Only numeric
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">Work Type</label>
                <div class="col-8">
                  <ng-select formControlName="workType" placeholder="Work Type" [(ngModel)]="selectedWorkType"
                    [ngClass]="{ 'is-invalid': submitted && f.workType.errors }">
                    <ng-option *ngFor="let item of workTypeList" [value]="item.workID">
                      {{ item.workName }}
                    </ng-option>
                  </ng-select>
                  <div *ngIf="submitted && f.workType.errors" class="invalid-feedback">
                    <div *ngIf="f.workType.errors.required">required</div>
                  </div>
                </div>
              </div>
              <div class="form-group row"></div>
            </div>
            <div class="col-8">
              <button name="submit" type="submit" class="btn btn-primary float-left RMSSubmitButton">
                Submit
              </button>
              <button name="submit" type="submit" class="btn btn-primary RMSButtonCancel" (click)="close()">
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div> -->
    </div>
  </div>
</section>
<section class="content" *ngIf="isShowDiv">
  <div class="container-fluid">
    <div class="card card-default">
      <div class="card-header">
        <h3 class="card-title MoodysTittleColor" style=" vertical-align: middle;">{{lblTitle}}</h3>
      </div>
      <div class="card-body">
        <div class="container">
          <form [formGroup]="contractManagementForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="AddEditFromDiv row">
                <label class="col-sm-4">Contract Name<label class='required'>*</label></label>
                <div class="col-sm-8">
                  <div class="col">
                    <input id="" name="" placeholder="Contract Name" type="text" required="required"
                      class="form-control" formControlName="contractName"
                      [ngClass]="{'is-invalid': submitted && f.contractName.errors}" />
                  </div>
                  <div *ngIf="submitted && f.contractName.errors" class="col">
                    <label *ngIf="f.contractName.errors.required" class='required'>Name is required</label>
                  </div>
                </div>
              </div>
              <div class="AddEditFromDiv row">
                <label for="" class="col-sm-4">Number of Accounts<label class='required'>*</label></label>
                <div class="col-sm-8">
                  <div class="col">
                    <input id="" name="" (ngModelChange)="Calculate();" placeholder="Number of Accounts" type="text"
                      class="form-control" formControlName="accountPerAeu" [ngClass]="{
                      'is-invalid': submitted && f.accountPerAeu.errors
                    }" />
                  </div>
                  <div *ngIf="submitted && f.accountPerAeu.errors" class="col">
                    <label *ngIf="f.accountPerAeu.errors.required" class='required'>Number of Accounts is
                      required</label>
                    <label *ngIf="f.accountPerAeu.errors.pattern" class='required'>
                      Only numeric values allowed
                    </label>
                  </div>
                </div>
              </div>
              <div class="AddEditFromDiv row">
                <label for="" class="col-sm-4">Number of Locations<label class='required'>*</label></label>
                <div class="col-sm-8">
                  <div class="col">
                    <input id="" name="" (ngModelChange)="Calculate();" placeholder="Number of Locations" type="number"
                      class="form-control" formControlName="locationPerAeu" [ngClass]="{
                      'is-invalid': submitted && f.locationPerAeu.errors
                    }" />
                  </div>
                  <div *ngIf="submitted && f.locationPerAeu.errors" class="col">
                    <label *ngIf="f.locationPerAeu.errors.required" class='required'>Number of Locations is
                      required</label>
                    <label *ngIf="f.locationPerAeu.errors.pattern" class='required'>
                      Only numeric values allowed
                    </label>
                  </div>
                </div>
              </div>
              <div class="AddEditFromDiv row">
                <label for="" class="col-sm-4">No of AEU<label class='required'>*</label></label>
                <div class="col-sm-8">
                  <div class="col">
                    <input id="" name="" placeholder="No of AEU" type="text" class="form-control"
                      formControlName="noOfAeu" [ngClass]="{ 'is-invalid': submitted && f.noOfAeu.errors }" />

                  </div>
                  <!-- <div class="input-group mb-3">
                    <input type="text" class="form-control">
                    <div class="input-group-append">
                      <span class="input-group-text">@example.com</span>
                    </div>
                  </div> -->
                  <div *ngIf="submitted && f.noOfAeu.errors" class="col">
                    <label *ngIf="f.noOfAeu.errors.required" class='required'>No of AEU is required</label>
                    <label *ngIf="f.noOfAeu.errors.pattern" class='required'>
                      Only numeric values allowed
                    </label>
                  </div>
                </div>
              </div>
              <div class="AddEditFromDiv row">
                <label for="" class="col-sm-4">AVG Account Size<label class='required'>*</label></label>
                <div class="col-sm-8" style="padding-left:15px;padding-right:15px">
                  <div class="input-group ">
                    <input id="" name="" placeholder="AVG Account Size" type="text" class="form-control"
                      formControlName="avgAccountSize" [ngClass]="{
                      'is-invalid': submitted && f.avgAccountSize.errors
                    }" />
                    <div class="input-group-append">
                      <span style="font-size: small;" class="input-group-text">Calculated : {{CalCultedAEU}}</span>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.locationPerAeu.errors" class="col">
                    <label *ngIf="f.locationPerAeu.errors.required" class='required'>AVG Account Size is
                      required</label>
                    <label *ngIf="f.locationPerAeu.errors.pattern" class='required'>
                      Only numeric values allowed
                    </label>
                  </div>
                </div>
              </div>
              <div class="AddEditFromDiv row">
                <label for="" class="col-sm-4">Project Manager<label class='required'>*</label></label>
                <div class="col-sm-8">
                  <div class="col">
                    <ng-select formControlName="projectManager" placeholder="Project Manager"
                      (change)="OnProjectManagerChange($event)" [ngClass]="{
                      'is-invalid': submitted && f.projectManager.errors
                    }">
                      <ng-option *ngFor="let item of accountManagerList" [value]="item.userID">
                        {{ item.userName }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf="submitted && f.projectManager.errors" class="col">
                    <label *ngIf="f.projectManager.errors.required" class='required'> Project Manager is
                      required</label>
                  </div>
                </div>
              </div>
              <div class="AddEditFromDiv row">
                <label class="col-sm-4">Start Date<label class='required'>*</label></label>
                <div class="col-sm-8">
                  <div class="col">
                    <input placeholder="MM/dd/yyyy" type="date" class="form-control" formControlName="contractStartDate"
                      [ngClass]="{
                  'is-invalid': submitted && f.contractStartDate.errors
                }" />
                  </div>
                  <div class="col" *ngIf="submitted && f.contractStartDate.errors">
                    <label class='required' *ngIf="f.contractStartDate.errors.required">
                      Start Date is required
                    </label>
                  </div>
                </div>
              </div>
              <div class="AddEditFromDiv row">
                <label class="col-sm-4">Expiry Date<label class='required'>*</label></label>
                <div class="col-sm-8">
                  <div class="col">
                    <input id="text" name="text" placeholder="" type="date" class="form-control"
                      formControlName="contractExpiryDate" [ngClass]="{
                      'is-invalid': submitted && f.contractExpiryDate.errors
                    }" />
                  </div>
                  <div class="col" *ngIf="submitted && f.contractExpiryDate.errors">
                    <label class='required' *ngIf="f.contractExpiryDate.errors.required">
                      Expire Date is required
                    </label>
                  </div>
                </div>
              </div>
              <div class="AddEditFromDiv row">
                <label for="" class="col-sm-4">Contract Type<label class='required'>*</label></label>
                <div class="col-sm-8">
                  <div class="col">
                    <ng-select formControlName="contractType" placeholder="Contract Type"
                      (change)="OnContractTypeChange($event)">
                      <ng-option *ngFor="let item of contractTypeList" [value]="item">
                        {{ item.contractType }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf="submitted && f.contractType.errors" class="col">
                    <label *ngIf="f.contractType.errors.required" class='required'>Contract type is
                      required</label>
                  </div>
                </div>
              </div>
              <div class="AddEditFromDiv row">
                <div class="col-sm-4">
                  <label>Account Type</label>
                </div>
                <div class="col-sm-8">
                  <div class="col">
                    <ng-select id="accountType" formControlName="accountType" placeholder="Account Type"
                      [multiple]="true" style="width: 100%; height: 100%;" (change)="OnAccountTypeChange($event)">
                      <ng-option *ngFor="let item of accounttypeList" [value]="item">
                        {{ item.accountTypeName }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf="f.accountType.errors" class="col">
                    <label *ngIf="f.accountType.errors.required" style="color: red;">Account type is required</label>
                  </div>
                </div>
              </div>
              <div class="AddEditFromDiv row">
                <label class="col-sm-4">Business Unit</label>
                <div class="col-sm-8">
                  <div class="col">
                    <ng-select id="businessUnit" formControlName="businessUnit" [multiple]="true"
                      style="width: 100%; height: 100%;" placeholder="Business Type"
                      (change)="OnBusinessUnitChange($event)">
                      <ng-option *ngFor="let item of BUList" [value]="item">
                        {{ item.businessUnitDescription }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf="f.businessUnit.errors" class="col">
                    <label *ngIf="f.businessUnit.errors.required" style="color: red;">Business type is required</label>
                  </div>
                </div>
              </div>
              <div class="AddEditFromDiv row">
                <div class="col-sm-4">
                  <label>Work Type</label>
                </div>
                <div class="col-sm-8">
                  <div class="col">
                    <ng-select id="workType" formControlName="workType" placeholder="Work Type"
                      style="width: 100%; height: 100%;">
                      <ng-option *ngFor="let item of workTypeList" [value]="item.workID">
                        {{ item.workType }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf="submitted && f.workType.errors" class="col">
                    <label *ngIf="f.workType.errors.required" style="color: red;">Work type is required</label>
                  </div>
                </div>
              </div>
              <!-- <div class="AddEditFromDiv row">
                <label class="col-sm-4">Work Type</label>
                <div class="col-sm-8">
                  <div class="col">
                    <ng-select id="workType" formControlName="workType" [clearable]="false" placeholder="Work Type"
                      style="width: 100%; height: 100%;" (change)="OnWorkTypeChange($event)">
                      <ng-option *ngFor="let item of workTypeList" [value]="item">
                        {{ item.workName }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div *ngIf="submitted && f.workType.errors" class="col">
                    <label *ngIf="f.workType.errors.required" style="color:red">Work type is required</label>
                  </div>
                </div>
              </div> -->
            </div>

            <div class="col-8" style="margin-top: 10px;">
              <button name="submit" type="submit" class="btn btn-primary float-left RMSSubmitButton">
                {{btnSubmitTxt}}
              </button>
              <button name="submit" type="submit" class="btn btn-primary RMSButtonCancel" (click)="close()">
                Cancel
              </button>
            </div>
            <!-- <div class="row form-mobile col" *ngIf="isShowDiv">
            <div class="col-sm-3 col-md-6 col-lg-6">
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">Contract Name</label>
                <div class="col-8">
                  <input id="" name="" placeholder="Contract Name" type="text" required="required" class="form-control"
                    formControlName="contractName" [ngClass]="{
                      'is-invalid': submitted && f.contractName.errors
                    }" />
                  <div *ngIf="submitted && f.contractName.errors" class="invalid-feedback">
                    <div *ngIf="f.contractName.errors.required">
                      Name is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">Contract Start Date</label>
                <div class="col-8">
                  <input placeholder="MM/dd/yyyy" type="date" class="form-control" formControlName="contractStartDate"
                    [ngClass]="{
                      'is-invalid': submitted && f.contractStartDate.errors
                    }" />
                  <div *ngIf="submitted && f.contractStartDate.errors" class="invalid-feedback">
                    <div *ngIf="f.contractStartDate.errors.required">
                      Start Date is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="text" class="col-4 col-form-label">Contract Expiry Date</label>
                <div class="col-8">
                  <input id="text" name="text" placeholder="" type="date" class="form-control"
                    formControlName="contractExpiryDate" [ngClass]="{
                      'is-invalid': submitted && f.contractExpiryDate.errors
                    }" />
                  <div *ngIf="submitted && f.contractExpiryDate.errors" class="invalid-feedback">
                    <div *ngIf="f.contractExpiryDate.errors.required">
                      Expire Date is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="select" class="col-4 col-form-label">Project Manager</label>
                <div class="col-8">
                  <ng-select formControlName="projectManager" placeholder="Project Manager"
                    [(ngModel)]="selectedAccountManager" [ngClass]="{
                      'is-invalid': submitted && f.projectManager.errors
                    }">
                    <ng-option *ngFor="let item of accountManagerList" [value]="item.userID">
                      {{ item.userName }}
                    </ng-option>
                    <div *ngIf="submitted && f.projectManager.errors" class="invalid-feedback">
                      <div *ngIf="f.projectManager.errors.required">
                        Project Manager is required
                      </div>
                    </div>
                  </ng-select>
                  <div *ngIf="submitted && f.projectManager.errors" class="invalid-feedback">
                    <div *ngIf="f.projectManager.errors.required">required</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="text" class="col-4 col-form-label">Subscribe</label>
                <div class="col-8">
                  <ng-select formControlName="subscribedTo" placeholder="Account Type" [(ngModel)]="selectedAccountType"
                    [ngClass]="{
                      'is-invalid': submitted && f.subscribedTo.errors
                    }">
                    <ng-option *ngFor="let item of accounttypeList" [value]="item.accountTypeID">
                      {{ item.accountTypeName }}
                    </ng-option>
                  </ng-select>
                  <div *ngIf="submitted && f.subscribedTo.errors" class="invalid-feedback">
                    <div *ngIf="f.subscribedTo.errors.required">required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-3 col-md-6 col-lg-6">
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">Accounts Per AEU</label>
                <div class="col-8">
                  <input id="" name="" placeholder="Accounts Per AEU" type="text" class="form-control"
                    formControlName="accountPerAeu" [ngClass]="{
                      'is-invalid': submitted && f.accountPerAeu.errors
                    }" />
                  <div *ngIf="submitted && f.accountPerAeu.errors" class="invalid-feedback">
                    <div *ngIf="f.accountPerAeu.errors.required">required</div>
                    <div *ngIf="f.accountPerAeu.errors.pattern">
                      Only numeric
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">Location Per AEU</label>
                <div class="col-8">
                  <input id="" name="" placeholder="Location Per AEU" type="text" class="form-control"
                    formControlName="locationPerAeu" [ngClass]="{
                      'is-invalid': submitted && f.locationPerAeu.errors
                    }" />
                  <div *ngIf="submitted && f.locationPerAeu.errors" class="invalid-feedback">
                    <div *ngIf="f.locationPerAeu.errors.required">required</div>
                    <div *ngIf="f.locationPerAeu.errors.pattern">
                      Only numeric
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">No of AEU</label>
                <div class="col-8">
                  <input id="" name="" placeholder="No of AEU" type="text" class="form-control"
                    formControlName="noOfAeu" [ngClass]="{ 'is-invalid': submitted && f.noOfAeu.errors }" />
                  <div *ngIf="submitted && f.noOfAeu.errors" class="invalid-feedback">
                    <div *ngIf="f.noOfAeu.errors.required">required</div>
                    <div *ngIf="f.noOfAeu.errors.pattern">Only numeric</div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">AVG Account Size</label>
                <div class="col-8">
                  <input id="" name="" placeholder="AVG Account Size" type="text" class="form-control"
                    formControlName="avgAccountSize" [ngClass]="{
                      'is-invalid': submitted && f.avgAccountSize.errors
                    }" />
                  <div *ngIf="submitted && f.avgAccountSize.errors" class="invalid-feedback">
                    <div *ngIf="f.avgAccountSize.errors.required">required</div>
                    <div *ngIf="f.avgAccountSize.errors.pattern">
                      Only numeric
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-4 col-form-label">Work Type</label>
                <div class="col-8">
                  <ng-select formControlName="workType" placeholder="Work Type" [(ngModel)]="selectedWorkType"
                    [ngClass]="{ 'is-invalid': submitted && f.workType.errors }">
                    <ng-option *ngFor="let item of workTypeList" [value]="item.workID">
                      {{ item.workName }}
                    </ng-option>
                  </ng-select>
                  <div *ngIf="submitted && f.workType.errors" class="invalid-feedback">
                    <div *ngIf="f.workType.errors.required">required</div>
                  </div>
                </div>
              </div>
              <div class="form-group row"></div>
            </div>
            <div class="col-8">
              <button name="submit" type="submit" class="btn btn-primary float-left RMSSubmitButton">
                Submit
              </button>
              <button name="submit" type="submit" class="btn btn-primary RMSButtonCancel" (click)="close()">
                Cancel
              </button>
            </div>
          </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<h5 *ngIf="!isData">
  No record found
</h5>

<section class="content" *ngIf="isData">
  <div class="container-fluid">
    <!-- SELECT2 EXAMPLE -->
    <div class="card card-default">
      <div class="card-header">
        <div class="card-body table-responsive p-0 text-nowrap">
          <table class="table table-bordered table-hover font14 noPad" id="tabledetail">
            <thead>
              <tr class="RMSTableHeader">
                <th *ngIf="valueRecieved=='1'">Actions</th>
                <th>Is Active</th>
                <th *ngFor="let col of fieldHeader">{{col | titlecase}}</th>
                <!-- <th class="text-left">{{ fieldHeader[2] | titlecase }}</th>
                <th>{{ fieldHeader[6] | titlecase }}</th>
                <th>{{ fieldHeader[7] | titlecase }}</th>
                <th>{{ fieldHeader[5] | titlecase }}</th>
                <th class="text-center">{{ fieldHeader[9] | titlecase }}</th>
                <th>Status</th>
                <th>{{ fieldHeader[10] | titlecase }}</th>
                <th>{{ fieldHeader[0] | titlecase }}</th>
                <th>{{ fieldHeader[8] | titlecase }}</th>
                <th>{{ fieldHeader[3] | titlecase }}</th>
                <th>{{ fieldHeader[15] | titlecase }}</th> -->
              </tr>
            </thead>
            <tbody class="RMSTableContent">
              <tr *ngFor="let item of contractList; let i = index" id="tr_{{i}}" (click)="selectRow('tr_' + i)"
                class="RmsTableRow">
                <td class="text-center" *ngIf="valueRecieved=='1'">
                  <button class="btn btn-tool" *ngIf="valueRecieved=='1'" [disabled]="!item.isActive" type="button"
                    data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                    aria-controls="collapseExample" (click)="editContractClick(item)">
                    <i class="fa fa-edit MoodysEditButton" ></i>
                  </button>
                  <button type="button" *ngIf="valueRecieved=='1'" class="btn btn-icon-toggle" data-toggle="tooltip"
                    data-placement="top" (click)="
                      deactivateContract(item)
                    ">
                    <i *ngIf="!item.isActive" class="fa fa-trash" style="color: gray;"></i>
                    <i *ngIf="item.isActive" class="fa fa-trash" style="color: rgb(196, 32, 50);"></i>
                  </button>
                  <button type="button" *ngIf="valueRecieved=='1'" class="btn btn-icon-toggle" data-toggle="modal"
                    data-target="#squarespaceModal" data-placement="top" (click)="
                      saveAsContract(item)
                    ">
                    <i class="fas fa-save" style="color: #0066cc;"></i>
                  </button>
                </td>

                <!-- <td>{{ item["contract Name"] }}</td>
                <td>{{ item["start Date"] | date }}</td>
                <td>{{ item["expiry Date"] | date }}</td>
                <td>{{ item["accounts Per Aeu"] }}</td>
                <td>{{ item["location Per Aeu"] }}</td> -->
                <td *ngIf="item.isActive" style="vertical-align: middle; text-align: center;">
                  <span class="badge badge-info activeBadge">
                    Active
                  </span>
                </td>
                <td *ngIf="!item.isActive" style="vertical-align: middle; text-align: center;">
                  <span class="badge deactiveBadge">
                    Deactive
                  </span>
                </td>
                <td *ngFor="let col of fieldHeader">{{item[col]}}</td>
                <!-- <td>{{  }}</td> -->
                <!-- <td>{{ item["project Manager"] }}</td>
                <td>{{ item["avg Account Size"] }}</td>
                <td>{{ item["work Type"] }}</td>
                <td>{{ item[fieldHeader[15]]  }}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <!--end .section-body -->
      </div>



    </div>
    <!-- /.card -->
  </div>
  <!-- /.container-fluid -->
</section>

<!-- /.content -->

<div *ngIf="openModal" class="modal fade" id="squarespaceModal" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button> -->
        <h6 class="modal-title" id="lineModalLabel">
          Save As Contract {{ contractName }} for {{ clientName }}
        </h6>
      </div>
      <div class="modal-body">
        <!-- content goes here -->
        <form [formGroup]="SaveAsContractForm" (ngSubmit)="saveAsSubmit()">
          <div class="form-group">
            <label>Contract Name</label>
            <input id="" name="" placeholder="Contract Name" type="text" required="required" class="form-control"
              formControlName="contractName" [ngClass]="{ 'is-invalid': submitted && f.contractName.errors }" />
          </div>
          <div class="form-group">
            <label>Start Date</label>
            <input placeholder="MM/dd/yyyy" type="date" class="form-control" formControlName="contractStartDate"
              [ngClass]="{
                'is-invalid': submitted && f.contractStartDate.errors
              }" />
          </div>
          <div class="form-group">
            <label>ExpiryDate</label>
            <input placeholder="MM/dd/yyyy" type="date" class="form-control" formControlName="contractExpiryDate"
              [ngClass]="{
                'is-invalid': submitted && f.contractExpiryDate.errors
              }" />
          </div>

          <button type="submit" class="btn btn-primary float-left RMSSubmitButton">
            Save As
          </button>
          <button type="button" #closebutton class="btn btn-primary RMSButtonCancel" data-dismiss="modal" role="button">
            Cancel
          </button>
        </form>
      </div>

    </div>
  </div>
</div>