import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { UploadAccountComponent } from './upload-account/upload-account.component';
import { SsUploadComponent } from './ss-upload/ss-upload.component';


const routes: Routes = [
  {
    path: '',
    component: UploadAccountComponent

  },
  {
    path: 'uploadaccount',
    component: UploadAccountComponent
  },
  {
    path: 'uploaddata',
    component: SsUploadComponent
  }];


export const ModuleRouting: ModuleWithProviders = RouterModule.forChild(routes);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UploadRoutingModule { }
